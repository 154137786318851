import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Table,
  Spinner,
  Container,
  Row,
} from "reactstrap";
import moment from "moment";
import DatePicker from "react-date-picker";
import axios from "../../customAxios";
import { environment } from "../env";
import BlockCoacheModal from "../../shared/components/BlockCoacheModal";
import { useForm } from "react-hook-form";
import swal from '@sweetalert/with-react';
import { GenerateSlotCoachModal } from "../../shared/components/GenerateSlotCoachModal";

const CoachesCalender = () => {
  const [coacheTimeSlotsList, setCoacheTimeSlotsList] = useState([]);
  const [staffBookingList, setCoacheBookingList] = useState([]);
  const [coachDetails, setCoachDetails] = useState([]);
  const [start, onChangeStart] = useState(new Date());
  const [times, setTimes] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
    "12:45 AM",
    "01:30 AM",
    "02:15 AM",
  ]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const GetCoacheBookingDetails = (startdate) => {
    setCoacheBookingList([]);
    const calenderDataPayload = {
      date: startdate,
    };
    const GetBookingDetailsUrl = `${environment.baseUrl}staffmanagement/coache-calender/`;
    axios
      .post(GetBookingDetailsUrl, calenderDataPayload)
      .then((bookingResponse) => {
        setCoacheTimeSlotsList(bookingResponse.data.totalUniqueStaffNameHeader);
        setCoacheBookingList(bookingResponse.data.bookingDetails);
      });
  };

  const GetCoachDetails = () => {
    const GetCoachDetailsURL = `${environment.baseUrl}staffmanagement/coache/`;
    axios.get(GetCoachDetailsURL).then((res) => {
      console.log('responsedata=========>',res?.data)
      setCoachDetails(res?.data);
    })
  }


  const submitDate = () => {
    let startdate = moment(start).format("YYYY-MM-DD");
    GetCoacheBookingDetails(startdate);
  };

  const OpenBlockModal = () => {
    alert("OpenBlockModal");
  }

  const onSubmit = (data) => {

    let blockDate = moment(start).format("YYYY-MM-DD");

    const UnBlockCoachPayload = {
      blockingDate: blockDate,
      blockFrom: data?.starttime,
      blockTo: data?.endtime,
      coacheId: data?.coachid,
    };


    const UnBlockCoachURL = `${environment.baseUrl}staffmanagement/unblock-coache/`;
    axios.post(UnBlockCoachURL, UnBlockCoachPayload).then((UnBlockCoachResponse) => {
      if (UnBlockCoachResponse.data.Message === "Coache is UnBlocked") {
        swal(
          {
            icon: "success",
            content: (<div>
              <h1 style={{ color: "#28a745" }}>Coach is UnBlocked</h1>
            </div>)
          }
        ).then(() => {
          submitDate();
        })
      } else {
        swal({
          icon: "error",
          content: (<div>
            <h1 style={{ color: "darkred" }}>Error</h1>
            <p>
              Error, While UnBlocking Coach
            </p>
          </div>)
        })
      }

    });

  };

  

  useEffect(() => {
    let startdate = moment().format("YYYY-MM-DD");
    GetCoacheBookingDetails(startdate);
    GetCoachDetails();
  }, []);

  console.log('coache details=======>', coachDetails);
  

  return (
    <Container className="dashboard">

      <Row className="d-flex justify-content-between">
        <Col md={3} lg={3}>
          <h3 className="page-title">Coach Booking Calender</h3>
        </Col>
        <Col md={9} lg={9}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="flex-nowrap">
               
                  <div className="inner-container">
                    <DatePicker
                      dateFormat="YYYY-MM-DD"
                      onChange={onChangeStart}
                      value={start}
                      className="custom-css-dp"
                    />
                  </div>
               
              <Col sm="3" md="3" lg="3" className="px-2">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <select name="padelcourt" className="textareaFeild" {...register("starttime", { required: true })}>
                      <option value="">Select Start Time</option>
                      {times &&
                        times.map((time) => (
                          <option value={time}>{time}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.starttime && (<span className="invalid-feedback d-flex" >Start time is required</span>)}
              </Col>
              <Col sm="3" md="3" lg="3" className="px-0">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <select name="padelcourt" className="textareaFeild" {...register("endtime", { required: true })}>
                      <option value="">Select End Time</option>
                      {times &&
                        times.map((time) => (
                          <option value={time}>{time}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.endtime && (
                  <span className="invalid-feedback d-flex">End time is required</span>
                )}
              </Col>
              <Col sm="2" md="2" lg="2" className="px-1">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <select name="padelcourt" className="textareaFeild" {...register("coachid", { required: true })}>
                      <option value="">Select CoachId</option>
                      {coachDetails &&
                        coachDetails.map((Coach) => (
                          <option value={Coach.id}>{Coach.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.coachid && (<span className="invalid-feedback d-flex" >Start time is required</span>)}
              </Col>

              <Col sm="2" md="2" lg="2">
                <div className="form__form-group">
                  <div className="inner-container">
                    <button
                      color="warning"
                      size="sm"
                      style={{
                        height: 35,
                        backgroundColor: "#FDAE00",
                        paddingRight: 20,
                        paddingLeft: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        borderRadius: 4,
                        fontSize: 15,
                        fontFamily: "sans-serif",
                        border: 0,
                      }}
                    >
                      <p style={{ color: "#fff" }}>UnBlock</p>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <span
                style={{
                  marginTop: 4,
                  fontSize: 16,
                  color: "#6d6d6d",
                  fontFamily: "sans-serif",
                  marginRight: 15,
                }}
              >
                Select Date
              </span>
              <DatePicker
                dateFormat="YYYY-MM-DD"
                onChange={onChangeStart}
                value={start}
                className="custom-css-dp"
              />
            </div>

            <div className="cal-top-submit-btn">
              <button
                color="warning"
                size="sm"
                onClick={submitDate}
                style={{
                  height: 35,
                  backgroundColor: "#FDAE00",
                  paddingRight: 20,
                  paddingLeft: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  borderRadius: 4,
                  fontSize: 15,
                  fontFamily: "sans-serif",
                  border: 0,
                }}
              >
                <p style={{ color: "#fff" }}>Submit</p>
              </button>
            </div>
          </div>
          <Table className="calendar mt-4">
            <thead>
              <tr className="d-flex">
                {coacheTimeSlotsList.map((data, index) => {
                  
                  return (
                    <th className="calender__courts" key={index}>
                      Coach - {data}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {staffBookingList.map((booking, index) => {
                return (
                  <>
                    <tr style={{ display: "flex" }} key={index}>
                      <td className="cal-headerTD calender__timeslots">
                        {booking.timeslots}
                      </td>

                      {booking.bookingData.map((datas) => {
                        return (
                          <>
                            {datas.bookingdetails === "Available" ? (
                              <BlockCoacheModal bnt="Available" color="primary" header title="Block Coach Time" coacheId={datas.coachesId} submitDate={submitDate} selectedDate={moment(start).format("YYYY-MM-DD")} />
                            ) :

                              datas.bookingdetails === "Blocked" ?
                                (<td className="calender__booked bg-danger">Blocked</td>)
                                : datas.bookingdetails === "Generate Slots" ? 
                                (<GenerateSlotCoachModal bnt="Generate Slots" color="primary" header title="Generate Slot" coacheId={datas.coachesId} coachName={datas.name} submitDate={submitDate} selectedDate={moment(start).format("YYYY-MM-DD")}  />)
                                :
                                (
                                  <td
                                    className="calender__booked"
                                    style={{
                                      backgroundColor: "red",
                                    }}
                                  >
                                    {datas.bookingdetails.bookedByFirstName}
                                    {"-"}
                                    {datas.bookingdetails.bookedByLastName}
                                    {"-"}
                                    {datas.bookingdetails.bookingId}
                                  </td>
                                )}
                          </>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};

export default CoachesCalender;
