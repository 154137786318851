import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  Spinner
} from "reactstrap";
import S3FileUpload from "react-s3";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import { AdSpaceById, UpdateAdSpaceAPI } from "../../Reuse/ApiCalls";
import AdSpace from "./AdSpace";


const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const EditAdSpace = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const { adsId } = useParams();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [pageContent, setPageContent] = useState();
  const [spinner, setSpinner] = useState(false);
  const [internalLink, setInternalLink] = useState(false);
  const [externalLink, setExternalLink] = useState(false);
  const [RedirectLink, setRedirectLink] = useState();

  const history = useHistory();
  const AdSpaceImage = watch("ads_image");


  const handleLinkSelect = (val) => {
    setInternalLink(false);
    setExternalLink(false);

    if (val === "1") {
      setInternalLink(true);
    }
    if (val === "2") {
      setExternalLink(true);
    }
  }

  useEffect(() => {
    AdSpaceById(adsId).then((adsResponse) => {
      setContent(adsResponse.content);
      reset({
        screen: adsResponse?.screen+"-"+adsResponse?.position,
        redirectlink: adsResponse?.redirectlink,
        visibility: adsResponse?.visibility
      })
      if (adsResponse.redirectlink.includes("http")) {
        setExternalLink(true);
      } else {
        setInternalLink(true);
      }

    });
  }, []);

  const onSubmit = async (AdSpaceData) => {
    setSpinner(true);
    AdSpaceData.position = parseInt(AdSpaceData.screen.split("-")[1]);
    AdSpaceData.screen = AdSpaceData.screen.split("-")[0];
    AdSpaceData.content = pageContent;
    // AdSpaceData.redirectlink = RedirectLink;
    if (!AdSpaceImage || AdSpaceImage.length <= 0) {
      UpdateAdSpaceAPI(adsId, AdSpaceData).then((adsResponse) => {
        console.log("adsResponse", adsResponse);
        if (adsResponse.Message === 'Ads is updated successfully') {
          setSpinner(false);
          swal({
            title: "Success",
            text: 'AdSpace Updated  Successfully',
            icon: "success",
            button: "Ok",
          }).then(() => {
            history.push("/ad-space-list");
          })
        }
      });
    } else {
      await S3FileUpload.uploadFile(AdSpaceImage[0], config)
        .then((data) => {
          AdSpaceData.image = data.location;
          UpdateAdSpaceAPI(adsId, AdSpaceData)
            .then((adsResponse) => {
              console.log("adsResponse", adsResponse);
              setSpinner(false);
              history.push("/ad-space-list");
            });
        })
        .catch((err) => {
          setSpinner(false);
          console.log("err", err);
        });
    }
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Add AdSpace </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="center-spinner">
                {spinner ? <Spinner className="table-fetch-spinner" /> : ''}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Screen
                        </span>
                        <div className="form__form-group-field">
                          <select
                            className="form__form-group-field"
                            name="screen"
                            style={{
                              borderRadius: 5,
                              border: "1px solid #f2f4f7",
                              color: "#646777",
                              height: 32,
                            }}
                            {...register("screen", { required: true })}
                          >
                            <option value="">Select Option</option>
                            <option value="Home-1">Home 1</option>
                            <option value="Home-2">Home 2</option>
                            <option value="Home-3">Home 3</option>
                            <option value="Profile-1">Profile 1</option>
                            <option value="BookNow-1">BookNow 1</option>
                            <option value="MyBooking-1">MyBooking 1</option>
                            <option value="Home-11">Home 11</option>
                          </select>
                        </div>
                        {errors.screen && <span className='error-msg'>This field is required</span>}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label links-container">
                          <input type="radio"
                            className="redirectRadio"
                            onChange={() => handleLinkSelect("1")}
                            checked={internalLink && "checked"} /> Internal Redirection
                          <input type="radio"
                            className="redirectRadio"
                            onChange={() => handleLinkSelect("2")}
                            checked={externalLink && "checked"} />  External Redirection
                        </span>

                        {internalLink && <div className="form__form-group-field">
                          <select className="form__form-group-field select__option"
                            name="redirectlink"
                            {...register("redirectlink", { required: true })}
                          >
                            <option value=''>Select Internal Redirection</option>
                            <option value="Home">Home</option>
                            <option value="booknow">Book Now</option>
                            <option value="ContactUsScreen">Contact Us</option>
                            <option value="packages">Packages</option>
                            <option value="offer">offer</option>
                            <option value="MyProfileScreen">Profile Page</option>
                          </select>
                        </div>}
                        {externalLink &&
                          <div className="form__form-group-field">
                            <input
                              type="text"
                              placeholder="Redirect Link"
                              name="redirectlink"
                              {...register("redirectlink", { required: true })}
                            />
                          </div>}
                        {errors.redirectlink && (
                          <span className="error-msg">Please Enter Redirect Link</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          AdSpace Image
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="file"
                            placeholder="AdSpace Image"
                            name="ads_image"
                            {...register("ads_image", { required: false })}
                          />
                        </div>
                        {errors.ads_image && <span className='error-msg'> This field is required</span>}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit" style={{ marginTop: 15 }} >
                      <div style={{ marginTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <input
                          type="checkbox"
                          placeholder="Visibility"
                          {...register("visibility", { required: false })}
                          style={{ borderRadius: 5, width: 'auto', marginTop: 15 }}
                        />
                        <span className="form__form-group-label"
                          style={{ fontSize: 15, marginLeft: 10 }}>
                          Visibility
                        </span>
                        {errors.visibility && <span className='error-msg'>This field is required</span>}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="form product-edit">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {/* Position */}
                      </span>
                      <div className="form__form-group-field">
                        <input
                          type="number"
                          style={{ borderRadius: 5 }}
                          name="position"
                          hidden="true"
                          value="0"
                          {...register("position")}
                        />
                        {/* <input
                            type="number"
                            placeholder="Position"
                            name="position"
                            {...register("position", { required: true })}
                          /> */}
                        {/* <select
                              className="form__form-group-field"
                              style={{
                                borderRadius: 5,
                                border: "1px solid #f2f4f7",
                                color: "#646777",
                                height: 32,
                              }}
                              {...register("position", { required: true })}
                            >
                              <option value="Select Option">Select Option</option>
                              <option value="1">Home 1</option>
                              <option value="2">Home 2</option>
                              <option value="3">Home 3</option>
                              <option value="1">Profile</option>
                              <option value="1">BookNow</option>
                              <option value="1">MyBooking</option>
                            </select> */}
                      </div>
                      {/* {errors.position && <span>This field is required</span>} */}
                    </div>
                  </div>
                </Row>

                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group mt-2">
                        <div className="form__form-group-field">
                          <Button type="submit" color="warning">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditAdSpace;
