import React, { useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import S3FileUpload from "react-s3";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

const config = {
  // bucketName: 'test-padel',
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "Z3DoBTbP3EgpMoCFtRJWKU5aX0qarAaFAldOSvVK",
};

const ExampleCard = () => {
  const [resources, setResources] = useState([{ resource: "" }]);
  
  //-----------------------------------------------Drop Zone Code Start From Here-------------------
  const getUploadParams = () => {
    console.log("Upload is called");
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ meta }, status) => {
    // console.log(status, meta);
  };

  const handleSubmit = (files, allFiles) => {
    let newresources = [];
    files.map((f) => f.meta)
    files.map((data)=>{
      console.log(data.file);
      S3FileUpload.uploadFile(data.file, config)
        .then((data) => {
          newresources.push({ resource: data.location });
        })
        .catch((err) => {
          console.log("Error Info: ", err);
        });
    });
    setResources(newresources);
    // console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  console.log("resources", resources);
  //-----------------------------------------------Drop Zone Code Ends From Here--------------------

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title"></div>
          <p>Your content here</p>
          <hr />
          <h2>Drop Zone</h2>
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default ExampleCard;
