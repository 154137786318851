import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Table, Button } from 'reactstrap';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { GetWalletTreansation } from '../../Reuse/ApiCalls';
import moment from 'moment';
import WalletTransactiomModal from '../../../shared/components/WalletTransactiomModal';
import './WalletTransactions.css';

const WalletTransactions = () => {
  const [walletTransactionDetails, setWalletTransactionDetails] = useState([]);

  useEffect(() => {
    GetWalletTreansation().then((response) => {
      console.log("response from wallet", response);
      setWalletTransactionDetails(response);
    })
  }, []);


  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };


  const columns = [
    {
      Header: '#',
      accessor: 'id',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: 'Recharge',
      accessor: 'recharge',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: 'Purchase',
      accessor: 'deduct',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: 'User Name',
      accessor: 'user',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: 'Time',
      accessor: 'time',
      disableGlobalFilter: true,
      width: 65,
    },    
    {
      Header: 'Status',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: 'Action',
      accessor: 'action',
      disableGlobalFilter: true,
      width: 65,
    }
  ]

  let data = [];
  for(let i=0; i<walletTransactionDetails?.length; i++){
    var item = {}
    item["id"] = (i+1).toString();
    item["amount"] = <div className="right-space">{walletTransactionDetails[i].amount}</div>;
    item["recharge"] =<div className="right-space">{ walletTransactionDetails[i].recharge === true ? "True" : "False"}</div>;
    item["deduct"] = <div className="right-space">{walletTransactionDetails[i].deduct === true ? "True" : "False"}</div>;
    item["user"] = <div className="right-space">{walletTransactionDetails[i].user.first_name}</div>;
    item["time"] = <div className="right-space">{moment(walletTransactionDetails[i].time.split('T')[1].split('.')[0], "HH:mm:ss").format("hh:mm A")}</div>;
    item["date"] = <div className="right-space">{moment(walletTransactionDetails[i].time.split('T')[0]).format("DD-MM-YYYY")}</div>;
    item["status"] = <div className="right-space">
     <div className="panding completed">
        <h5 className={walletTransactionDetails[i].transaction_status === "Pending" ? "panding" : "completed"}>{walletTransactionDetails[i].transaction_status}</h5>
      </div>
    </div>;
    item["action"] = <div className="right-space"><WalletTransactiomModal color="primary" title="Wallet Transaction" header transactionLogId = {walletTransactionDetails[i].transaction_id} /></div>
    data.push(item);
  }


  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
  };

  return (
    <Col md={12}>
          <CardBody>
            <div className="react-table__wrapper hide-table-costomizer">
              <ReactTableCustomizer
                handleClickIsSortable={handleClickIsSortable}
                handleClickWithPagination={handleClickWithPagination}
                handleClickWithSearchEngine={handleClickWithSearchEngine}
                handleClickIsResizable={handleClickIsResizable}
                isResizable={isResizable}
                isSortable={isSortable}
                withPagination={withPagination}
                withSearchEngine={withSearchEngine}
              />
            </div>
            <div className="hover-effect table-id-width">
            <ReactTableBase
              key={withSearchEngine ? 'searchable' : 'common'}
              columns={columns}
              data={data}
              tableConfig={tableConfig}
            />
            </div>
          </CardBody>
    </Col>
  )
};

export default WalletTransactions;
