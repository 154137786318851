import React from "react";
import { CardBody, Card, Col } from "reactstrap";
import { Container, Row } from "reactstrap";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import swal from "@sweetalert/with-react";
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import { AiFillSetting } from "react-icons/ai";
import axios from "../../customAxios";
import { environment } from "../env";
import parse from "html-react-parser";
import CoachLeaveModal from "../../shared/components/CoachLeaveModal";

const CoachesList = () => {
  const [coachList, setCoachList] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [coachesList, setCoachesList] = useState([]);

  let history = useHistory();

  const GetCoachList = () => {
    let coaches = [];
    const getCoachListUrl = `${environment.baseUrl}staffmanagement/coache-list/`;
    axios.get(getCoachListUrl).then((res) => {
      setCoachList(res?.data);

      res?.data.map((coache) => {
        coaches.push({value:coache.id, label:coache.name})
      });

    });
    setCoachesList(coaches);
  };

  const handleDelete = (delId) => {
    swal({
      icon: "warning",
      buttons: true,
      content: (
        <div>
          <h2 style={{ width: "100%" }}>Are you sure?</h2>
          <p>
            Once deleted,
            <br /> you will not be able to recover this Coach Details!
          </p>
        </div>
      ),
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const getDeleteStaffDataUrl = `${environment.baseUrl}staffmanagement/getcoachedetails/${delId}`;
        axios.delete(getDeleteStaffDataUrl).then((response) => {
          if (response?.data?.msg == "Data Deleted Successfully") {
            swal("Deleted Successfully!", {
              icon: "success",
            });
            setCoachList(() => coachList.filter((data) => data.id !== delId));
          } else {
            swal("Your Coach Details is safe!");
          }
        });
      } else {
        swal("Your Coach Details Details is safe!");
      }
    });
  };

  useEffect(() => {
    GetCoachList();
  }, []);

  var jsonObj = [];
  for (let i = 0; i < coachList.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["image"] = (
      <div className="right-space">
        <img
          src={coachList[i].image}
          style={{ width: 70, borderRadius: 5 }}
          alt={coachList[i].image}
        />
      </div>
    );
    item["name"] = `${coachList[i].name}`;
    item["nationality"] = (
      <div className="right-space">{coachList[i].nationality}</div>
    );

    item["languages"] = JSON.parse(coachList[i].languages).map(
      (data, index) => {
        return (
          <div className="right-space" key={index}>
            {data?.value}
          </div>
        );
      }
    );

    item["description"] = (
      <div className="right-space">
        {parse(coachList[i].description.toString())}
      </div>
    );
    item["locations"] = (
      <div className="right-space">
        {coachList[i].locations.courtName.toString()}
      </div>
    );
    item["pricing"] = (
      <div className="right-space">{coachList[i].Pricing.toString()}</div>
    );
    item["manageslots"] = (
      <div className="right-space">
        <div
          onClick={() => handleManageTimeslot(coachList[i].id)}
          className="modal-edit-btn"
        >
          <AiFillSetting style={{ fontSize: 20 }} />{" "}
        </div>
      </div>
    );

    item["link"] = (
      <div className="right-space">
        <div
          onClick={() => handleEdit(coachList[i].id)}
          className="modal-edit-btn"
        >
          <EditOutlineIcon style={{ fontSize: 20 }} />{" "}
        </div>
      </div>
    );
    item["delete"] = (
      <div className="right-space">
        <div className="modal-delete-btn">
          <DeleteOutlineIcon
            style={{ fontSize: 20 }}
            onClick={() => handleDelete(coachList[i].id)}
          />
        </div>
      </div>
    );

    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 10,
      },
      {
        Header: "Images",
        accessor: "image",
        disableGlobalFilter: true,
        width: 90,
      },
      {
        Header: "Name",
        accessor: "name",
        disableGlobalFilter: false,
        width: 80,
      },
      {
        Header: "Nationality",
        accessor: "nationality",
        disableGlobalFilter: true,
        width: 105,
      },

      {
        Header: "Languages",
        accessor: "languages",
        disableGlobalFilter: true,
        width: 105,
      },
      {
        Header: "Description",
        accessor: "description",
        disableGlobalFilter: true,
        width: 110,
      },
      {
        Header: "Locations",
        accessor: "locations",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Pricing",
        accessor: "pricing",
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: "Slots",
        accessor: "manageslots",
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 55,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 55,
      },
    ],
    rows: jsonObj,
  };

  function handleEdit(coacheId) {
    history.push(`edit-coaches/${coacheId}`);
  }

  function AddCoaches() {
    history.push(`/coaches`);
  }
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleManageTimeslot = (staffId) => {
    history.push(`/manage-coache-slots/${staffId}`);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Package Name...",
  };
  return (
    <Container>
      <Row>
        <Col md={6}>
          <h3 className="page-title">Coaches List</h3>
        </Col>
        <Col md={3}>
          <button
            className="btn btn-primary float-right add-packages"
            onClick={() => AddCoaches()}
          >
            Add Coaches
          </button>
        </Col>
        <Col md={3}>
          <CoachLeaveModal
            color="primary"
            btn={"Coach Leave"}
            header
            coachList={coachesList}
            title="Add Coach Leaves"
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardBody>
                <div className="react-table__wrapper hide-table-costomizer">
                  <ReactTableCustomizer
                    handleClickIsSortable={handleClickIsSortable}
                    handleClickWithPagination={handleClickWithPagination}
                    handleClickWithSearchEngine={handleClickWithSearchEngine}
                    handleClickIsResizable={handleClickIsResizable}
                    isResizable={isResizable}
                    isSortable={isSortable}
                    withPagination={withPagination}
                    withSearchEngine={withSearchEngine}
                  />
                </div>
                <div className="hover-effect table-id-width">
                  {coachList && coachList ? (
                    <ReactTableBase
                      key={withSearchEngine ? "searchable" : "common"}
                      columns={data?.columns}
                      data={data?.rows}
                      tableConfig={tableConfig}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CoachesList;
