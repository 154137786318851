import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import CourtEditCard from './components/CourtEditCard';

const CourtCategory = () => (
  <Container>
    {/* <Row>
      <Col md={12}>
        <h3 className="page-title">Padel Court</h3>
         <h3 className="page-subhead subhead">
          Use this elements, to Add Padel Court
        </h3> 
      </Col>
    </Row> */}
    <Row>
      <CourtEditCard />
    </Row>
  </Container>
);
export default CourtCategory;
