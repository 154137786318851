import React, { useEffect } from "react";
import ImageUploading from "react-images-uploading";
import { Alert, Button, ButtonGroup } from "reactstrap";
import S3FileUpload from "react-s3";

const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const ImageUploaderCaption = (props) => {
  const {
    maxNumber = 50,
    acceptType = ["jpeg", "jpg", "png"],
    maxFileSize = 5000000,
    setImage,
    resources,
  } = props;
    
  const [images, setImages] = React.useState([]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
    setImage(imageList);
  };

  const onError = () => {
    setImages([]);
  };

  const UploadImages = async() => {
    var UploadedImages = [];
    images.map(async (img)=>{
      if("resource" in img){
        UploadedImages.push({"resource":img.resource})
        if(images?.length === UploadedImages?.length){
          setImage(UploadedImages);
        }
      }else{
        await S3FileUpload.uploadFile(img.file, config)
        .then((data) => {
          UploadedImages.push({"resource":data.location});
          if(images?.length === UploadedImages?.length){
            setImage(UploadedImages);
          }
        })
        .catch((err) => {
          console.log("Error Info: ", err);
        });
      }
    })
    return;

    var UploadedImages = [];
    images.map(async (img)=>{
      await S3FileUpload.uploadFile(img.file, config)
      .then((data) => {
        UploadedImages.push({"resource":data.location});
        if(images.length === UploadedImages.length){
          setImage(UploadedImages);
        }
      })
      .catch((err) => {
        console.log("Error Info: ", err);
      });
    });
  };

  useEffect(() => {
    setImages(resources);
    setImage(resources);
  }, [resources])

  const handleCaptionChange = (index, event) => {
    const updatedImages = [...images];
    updatedImages[index].caption = event.target.value;
    setImages(updatedImages);
    setImage(updatedImages)
  };
  
  return (
    <div className="App w-100">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        onError={onError}
        maxNumber={maxNumber}
        acceptType={acceptType}
        maxFileSize={maxFileSize}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => (
          <>
            {errors && (
              <Alert color="danger text-start">
                <ul>
                  {errors.maxNumber && (
                    <li>Number of selected images exceed maxNumber</li>
                  )}
                  {errors.acceptType && (
                    <li>Your selected file type is not allow</li>
                  )}
                  {errors.maxFileSize && (
                    <li>Selected file size exceed maxFileSize</li>
                  )}
                </ul>
              </Alert>
            )}

            <div className="upload__image-wrapper">
              <div
                className="upload-container m-0 rounded"
                {...dragProps}
                onClick={onImageUpload}
                style={
                  isDragging
                    ? { backgroundColor: "#afafaf", color: "white" }
                    : undefined
                }
              >
                Choose a file or Drag it here
              </div>

              <div className="p-2" style={{ textAlign: "left" }}>
                {imageList?.map((image, index) => (
                  <div
                    key={index}
                    className="image-item  "
                    style={{
                      width: "139px",
                      marginRight: "10px",
                      display: "inline-block"
                    }}
                  >
                    <img
                      src={image["data_url"]}
                      alt=""
                      style={{ width: "100%" }}
                    />
                    <input
                    type="text"
                    value={image.caption || ''}
                    onChange={(e) => handleCaptionChange(index, e)}
                    placeholder="Enter Caption"
                    style={{border:'1px solid #ffc107', marginTop:5}}
                  />
                    <div className="image-item__btn-wrapper mt-1">
                      <ButtonGroup size="sm" style={{ width: "100%" }}>
                        <Button
                          color="warning"
                          className="p-1 mr-l"
                          onClick={() => onImageUpdate(index)}
                        >
                          Update
                        </Button>
                        <Button
                          color="danger"
                          className="p-1 ml-1"
                          onClick={() => onImageRemove(index)}
                        >
                          Remove
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                ))}
              </div>
              {/* {images?.length > 0 && (
                <>
                  <hr />
                  <div className="text-start p-2">
                    <Button onClick={()=>UploadImages()} color="success">
                      Upload
                    </Button>{" "}
                    <Button onClick={()=>onImageRemoveAll()} color="danger">
                      Remove All Images
                    </Button>
                  </div>
                  <pre className="text-start" id="jsonprint"></pre>
                </>
              )} */}
            </div>
          </>
        )}
      </ImageUploading>
    </div>
  );
};

export default ImageUploaderCaption;
