import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import RatingView from './components/RatingView';

const Rating = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Court Rating</h3>
      </Col>
    </Row>
    <Row>
      <RatingView />
    </Row>
  </Container>
);
export default Rating;
