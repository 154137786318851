import React from "react";
import { Spinner } from "reactstrap";

const MakingFileDownload = () => {
  return (
    <>
    <div className="spinner-patient__container">
      <Spinner className="table-fetch-spinner" />
      <span className="spinner-patient__title">Be patient... Generating Excel File</span>
    </div>
    </>
  );
};

export default MakingFileDownload;
