import React, { useEffect, useState } from 'react';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { Table } from 'reactstrap';
import { GetAdminBooking } from '../../../Reuse/ApiCalls';


const BookingDetailsTabTable = ({ bookingID }) => {
    const [details, setDetails] = useState([]);
    useEffect(() => {
        GetAdminBooking(bookingID).then((response) => {
            if (response.msg === "Data is not available") {
                setDetails({
                    customer_name: "Just Padel",
                    customer_email: "justpadel_admin@gmail.com",
                    customer_phone: "+97 1589 046 968"
                })
            } else {
                setDetails(response)
                localStorage.setItem("AdminPaymentType", response.payment_type)
            }
        })
    }, []);

    return (
        <Table responsive hover>
            <tbody className="table-trans-user-details">
                <tr>
                    <td className="first-td-width">
                        <div className="trans-client-name"><FiUser className="client-icon" /><h5>Client Name</h5></div>
                    </td>
                    <td className="client-name">
                        {details.customer_name}
                        {/* {bookingDetailsSingle?.user?.first_name === "null" ? " " : bookingDetailsSingle?.user?.first_name}{' '}
                        {bookingDetailsSingle?.user?.last_name === "null" ? " " : bookingDetailsSingle?.user?.last_name} */}
                    </td>
                </tr>
                <tr>
                    <td className="first-td-width">
                        <div className="trans-client-name"><AiOutlineMail className="client-icon" /> <h5>Email</h5></div>
                    </td>
                    <td className="client-email">{details.customer_email}</td>
                </tr>
                <tr>
                    <td className="first-td-width">
                        <div className="trans-client-name"><AiOutlinePhone className="client-icon" /><h5 >Phone</h5></div>
                    </td>
                    <td className="client-Ph-no">{details.customer_phone}</td>
                </tr>
            </tbody>
        </Table>
    )
};
export default BookingDetailsTabTable;
