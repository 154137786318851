import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Table, } from 'reactstrap';
import { AiOutlineClose, AiOutlineCheck, AiFillEye } from 'react-icons/ai';
import moment from 'moment';
import axios from '../../customAxios';
import { environment } from '../env';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { AppliedSkillLevel } from './AppliedSkillLevel';
import { VerifiedSkillLevel } from './VerifiedSkillLevel';
import { RejectedSkillLevel } from './RejectedSkillLevel';
import { RegisteredUserSkillLevel } from './RegisteredUserSkillLevel';


const VerifySkillLevel = () => {

    const [activeTab, setActiveTab] = useState("1");
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Verify Skill Level</h3>
                </Col>
            </Row>


            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <div className="tabs__wrap">
                                <Nav tabs className="all-tabs" style={{ width: "100%" }}>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                "active-tab": activeTab === "1",
                                            })}
                                            onClick={() => toggle("1")}
                                        >
                                            Applied
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                "active-tab": activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2");
                                            }}
                                        >
                                            Verified
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                "active-tab": activeTab === "3",
                                            })}
                                            onClick={() => toggle("3")}
                                        >
                                            Rejected
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                "active-tab": activeTab === "4",
                                            })}
                                            onClick={() => toggle("4")}
                                        >
                                            Registered Users
                                        </NavLink>
                                    </NavItem>

                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <>
                                            {activeTab === "1" && (
                                                <AppliedSkillLevel />
                                            )}

                                        </>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <>
                                            {activeTab === "2" && (
                                                <VerifiedSkillLevel />
                                            )}
                                        </>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <>
                                            {activeTab === "3" && (
                                                <RejectedSkillLevel />
                                            )}
                                        </>
                                    </TabPane>

                                    <TabPane tabId="4">
                                        <>
                                            {activeTab === "4" && (
                                                <RegisteredUserSkillLevel />
                                            )}
                                        </>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default VerifySkillLevel