import React, { useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  Spinner
} from "reactstrap";
import S3FileUpload from "react-s3";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import { LTOPostAPI } from "../Reuse/ApiCalls";
import swal from "@sweetalert/with-react";
import { useHistory, useParams } from "react-router-dom";


const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const CreateLTO = () => {

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [pageContent, setPageContent] = useState();
  const [spinner, setSpinner] = useState(false);


  const [btnInternalLink, setBtnInternalLink] = useState(true);
  const [btnExternalLink, setBtnExternalLink] = useState(false);
  const [btnRedirectLink, setBtnRedirectLink] = useState();
  const history = useHistory();


  const LtoImage = watch("lto_image");


  const onSubmit = async (LtoData) => {

    console.log("LtoData",LtoData);

    setSpinner(true);

    await S3FileUpload.uploadFile(LtoImage[0], config)
      .then((data) => {

        LtoData.content = pageContent;
        LtoData.button_url = btnRedirectLink;

        console.log("btnRedirectLink",btnRedirectLink);

        LtoData.image = data.location;
        LTOPostAPI(LtoData).then((newsPostResponse) => {
          console.log(newsPostResponse);
          if (newsPostResponse.Message === 'Limited Time Offer is saved successfully')
          setSpinner(false);
        swal({
          title: "Success",
          text: 'Life Time Offers saved Successfully',
          icon: "success",
          button: "Ok",
        })
          .then(() => {
            history.push('/limited_time_offers');           
          })
        })
      })
      .catch((err) => {
        console.log("Error Info: ", err);
      });
  };


   const handleBtnLinkSelect = (val)=>{
    setBtnInternalLink(false);
    setBtnExternalLink(false);

    if(val === "1"){
      setBtnInternalLink(true);
    }
    if(val === "2"){
      setBtnExternalLink(true);
    }
   }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Create Limited Time Offer</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
            <div className="center-spinner">
                {spinner ? <Spinner className="table-fetch-spinner" /> : ''}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Limited Time Offer Title
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Limited Time Offer Title"
                            name="title"
                            {...register("title", { required: true })}
                          />
                        </div>
                        {errors.title && (
                          <span className='error-msg'> Please Enter Limited Time Offer Title</span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                   {/* offers SubTitle removed */}
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Button Label
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Button Label"
                            name="button_label"
                            {...register("button_label", { required: true })}
                          />
                        </div>
                        {errors.button_label && (
                          <span className="error-msg">Please Enter Button Label</span>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label links-container">
                          <input type="radio"  
                            className="redirectRadio"
                            onChange={() => handleBtnLinkSelect("1")}
                            checked={btnInternalLink && "checked"} 
                          />Button Internal Redirection
                          <input type="radio"  
                          className="redirectRadio" 
                          onChange={() => handleBtnLinkSelect("2")}
                          checked={btnExternalLink && "checked"}/> Button External Redirection
                        </span>
                       
                        {btnInternalLink && <div className="form__form-group-field">
                           <select className="form__form-group-field select__option"
                           name="button_url"
                           onChange={ e => setBtnRedirectLink(e.target.value)}
                           >
                             <option>Select Internal Redirection</option>
                             <option value="Home">Home</option>
                             <option value="booknow">Book Now</option>
                             <option value="ContactUsScreen">Contact Us</option>
                             <option value="packages">Packages</option>
                             <option value="offer">offer</option>
                             <option value="MyProfileScreen">Profile Page</option>
                           </select>
                        </div>}
                       
                         {btnExternalLink && 
                        <div className="form__form-group-field">
                          <input
                          type="text"
                          placeholder=" Button Redirect Link"
                          name="button_url"
                          onChange={ e => setBtnRedirectLink( e.target.value)}
                        />
                        </div>}
                        {errors.button_url && (
                          <span className="error-msg">Please Enter Redirect Link</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>


                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Limited Time Offer Image
                        </span>
                        <div className="form__form-group-field">
                          <input
                            type="file"
                            placeholder="Limited Time Offer Title"
                            name="lto_image"
                            {...register("lto_image", { required: true })}
                          />
                        </div>
                        {errors.lto_image && (
                          <span className='error-msg'>Please Enter Limited Time Offer Image</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col sm={12} md={12} lg={12}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Limited Time Offer Content
                        </span>
                        <div className="form__form-group-fields">
                          <Controller
                            control={control}
                            name="lto_content"
                            render={({ field }) => (
                              <JoditEditor
                                ref={editor}
                                value={content}
                                tabIndex={1}
                                onChange={(newContent) =>
                                  setPageContent(newContent)
                                }
                              />
                            )}
                          />
                        </div>
                        {errors.lto_content && (
                          <span className='error-msg'>Please Enter  Limited Time Offer Content</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group mt-2">
                        <div className="form__form-group-field">
                          <Button type="submit" color="warning">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateLTO;
