import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { FiUser } from 'react-icons/fi'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { FcCancel, FcCheckmark } from 'react-icons/fc'
import { environment } from '../../../env'
import axios from '../../../../customAxios'
const BookingCalculations = ({ bookingDetails, bookedUsing }) => {
    const [discountAmount, setDiscountAmount] = useState(0);
    const [vatAmount, setVatAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [toBePayAmount, setToBePayAmount] = useState(0);
    const [couponData, setCouponData] = useState()
    const coachOrStaffPrice = 0;

    // ---------------- Calculation for discount admount starts here ---------
    const calculateDiscountAmount = (
        courtamount,
        addonAmount,
        couponDetails,
        coupon
    ) => {
        if (coupon === undefined) {
            return 0.0;
        }
        if (coupon === "null") {
            return 0.0;
        }
        if (coupon === "") {
            return 0.0;
        }
        if (coupon === ("null" || "" || undefined || "undefined")) {
            return 0.0;
        } else {
            if (couponDetails?.apply_for_addons) {
                return (
                    (((courtamount + addonAmount) / 100) *
                        couponDetails?.percentage_of_discount).toFixed(2)
                );
            } else {
                if (couponDetails?.percentage_of_discount === 100) {
                    return (Math.round((courtamount / 100) * couponDetails?.percentage_of_discount));
                } else {
                    return ((courtamount / 100) * couponDetails?.percentage_of_discount);
                }
            }
        }
    };
    // ---------------- Calculation for discount admount ends here -----------

    // ---------------- Calculation for vat code starts here --------------
    const VatCalculation = (courtamount, addonAmount, couponDetails, coupon, coachOrStaffPrice) => {
        if (coupon === ("null" || "" || undefined)) {
            return Math.ceil((courtamount + addonAmount + coachOrStaffPrice) * 0.05);
        } else {

            if (couponDetails?.apply_for_addons) {
                return Math.ceil(
                    (courtamount +
                        addonAmount + coachOrStaffPrice -
                        calculateDiscountAmount(
                            courtamount,
                            addonAmount,
                            couponDetails,
                            coupon
                        )) *
                    0.05
                );
            } else {
                return Math.ceil(
                    (courtamount + coachOrStaffPrice -
                        calculateDiscountAmount(
                            courtamount,
                            addonAmount,
                            couponDetails,
                            coupon
                        ) +
                        addonAmount) *
                    0.05
                );
            }
        }
    };

    // ---------------- Calculation for vat code ends here --------------
    // ---------------- Calculate total amount code starts here ---------
    const CalculateTotalAmount = (
        courtamount,
        addonamount,
        coupondetails,
        coupon,
        coachOrStaffPrice
    ) => {
        if (coupon === ("null" || "" || undefined)) {
            return (
                Math.floor(courtamount +
                    addonamount + coachOrStaffPrice +
                    VatCalculation(courtamount, addonamount, coupondetails, coupon, coachOrStaffPrice)
                ));
        } else {
            if (bookingDetails.apply_for_addons) {
                return (
                    Math.floor(courtamount +
                        addonamount + coachOrStaffPrice +
                        (VatCalculation(courtamount, addonamount, coupondetails, coupon, coachOrStaffPrice) -
                            calculateDiscountAmount(
                                courtamount,
                                addonamount,
                                coupondetails,
                                coupon
                            ))
                    ));
            } else {
                return (
                    courtamount +
                    addonamount + coachOrStaffPrice +
                    (VatCalculation(courtamount, addonamount, coupondetails, coupon, coachOrStaffPrice) -
                        calculateDiscountAmount(
                            courtamount,
                            addonamount,
                            coupondetails,
                            coupon
                        ))
                );
            }
        }
    };
    // ---------------- Calculate total amount code starts here ---------

    // ---------------- Paid to faculty calculation code starts here -------
    const PaidToFacultyCalculation = (
        courtamount,
        addonamount,
        coupondetails,
        Coupon,
        coachOrStaffPrice,
        amountPaid,
    ) => {
        return (
            CalculateTotalAmount(courtamount, addonamount, coupondetails, Coupon, coachOrStaffPrice) -
            amountPaid
        );
    };
    // ---------------- Paid to faculty calculation code ends here ---------

    const FinalCalculation = () => {
        if (bookingDetails) {
            // changes for buy one get one coupon 
            const totalCourtPrice = couponData?.scratch_card === true ? bookingDetails?.booked_slots[0].price : bookingDetails?.booked_slots.reduce((acc, booking) => acc + booking.price, 0);

            //old one changes
            // const totalCourtPrice = bookingDetails?.booked_slots.reduce((acc, booking) => acc + booking.price, 0);
            const addonPrice = bookingDetails?.booked_addons ? bookingDetails?.booked_addons.reduce((acc, addon) => acc + addon.amount, 0) : 0;
            const couponDetails = {
                percentage_of_discount: bookingDetails.coupan_applied_discount,
                apply_for_addons: bookingDetails.apply_for_addons
            }
            var discountAmount = calculateDiscountAmount(totalCourtPrice, addonPrice, couponDetails, bookingDetails?.coupan_applied);
            var VatAmount = VatCalculation(totalCourtPrice, addonPrice, couponDetails, bookingDetails?.coupan_applied, coachOrStaffPrice);
            var TotalAmount = CalculateTotalAmount(totalCourtPrice, addonPrice, couponDetails, bookingDetails?.coupan_applied, coachOrStaffPrice);
            var PayToFaculty = PaidToFacultyCalculation(totalCourtPrice, addonPrice, couponDetails, bookingDetails?.coupan_applied, coachOrStaffPrice, bookingDetails?.amount_paid ? bookingDetails?.amount_paid : 0);
            setDiscountAmount(discountAmount);
            setVatAmount(VatAmount);
            setTotalAmount(TotalAmount);
            setToBePayAmount(PayToFaculty)
        }
    };

    const GetCouponData = async (couponName) => {
        const url = `${environment.baseUrl}getcoupan/${couponName}/`
        await axios.get(url).then(res => {
            setCouponData(res?.data)
        })
    }

    useEffect(() => {
        if (couponData) {
            FinalCalculation()
        }
    }, [couponData])

    useEffect(() => {
        if (bookingDetails) {
            GetCouponData(bookingDetails?.coupan_applied)
        }
    }, [bookingDetails])

    return (
        <>
            <div className="user-datails-lhs">
                {bookingDetails?.booked_slots?.length > 0 && <h5>Current Bookings</h5>}
            </div>
            <div className="trans-total-details">
                {bookingDetails?.booked_slots?.length > 0 && (
                    <Table responsive hover>
                        <thead className="trans-total-table-header">
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookingDetails?.booked_slots?.map((data, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {`${data.main_court_name} - Court ${data.padel_court_name} (${data.date} ${data.time})`}
                                    </td>
                                    <td>{`${data?.price?.toFixed(2)} AED`}</td>
                                    <td>1</td>
                                    <td>
                                        <FcCheckmark size={25} className="fc-check" style={{ marginLeft: "10px" }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>

            <div className="user-datails-lhs">
                {bookingDetails?.cancelled_slots?.length > 0 && <h5>Logs</h5>}
            </div>
            <div className="trans-total-details">
                <Table responsive hover>
                    {bookingDetails?.cancelled_slots?.length > 0 && (
                        <thead className="trans-total-table-header">
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {bookingDetails?.cancelled_slots?.map((data, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {data?.main_court_name} - {"Court "}
                                    {data?.padel_court_name} ({data?.date + "  " + data?.time})
                                </td>
                                <td>{data?.price.toFixed(2)} AED</td>
                                <td>1</td>
                                <td>
                                    <FcCancel
                                        size={25}
                                        className="faCancel"
                                        style={{ marginLeft: "10px" }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            {/* {bookingDetails.coach_or_staff_booked && (
                <div>
                    <div className="user-datails-lhs">
                        <h5>Coach/Staff Details</h5>
                    </div>

                    <div className="innerContainer">
                        <div>
                            <img
                                src={coachStaff?.image}
                                alt="Coach/Staff Image"
                                style={{
                                    height: "80px",
                                    width: "80px",
                                    borderRadius: "70px",
                                    marginRight: "20px",
                                }}
                            />
                        </div>

                        <div style={{ alignSelf: "center" }}>
                            <text className="username">{coachStaff?.name}</text>

                            <div className="nationalty">
                                <text
                                    className="details"
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {coachStaff?.nationality}
                                    {" | "}{" "}
                                </text>
                                <text style={{ color: "#4B4B4B" }}>
                                    {coachStaff?.languages &&
                                        JSON.parse(coachStaff?.languages).map((language, index) => {
                                            return (
                                                <text
                                                    key={index}
                                                    className="details"
                                                    style={{
                                                        textTransform: "capitalize",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    {index > 0 ? ", " : " "}
                                                    {language?.value}
                                                </text>
                                            );
                                        })}
                                </text>
                            </div>
                            {
                                bookedUsing !== "app-personal-coach" &&
                                <div>
                                    <text className="pricedetails">
                                        AED {coachStaff?.Pricing.toFixed(2)}
                                    </text>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )} */}
            <div className="trans-total-amount">
                {
                    bookedUsing !== "app-personal-coach" &&
                    <div>
                        <label style={{ fontSize: 15, marginRight: "12px" }}>
                            Coach/Staff:
                        </label>{" "}
                        <label style={{ fontSize: 15 }}>
                            {coachOrStaffPrice.toFixed(2)} AED
                        </label>
                    </div>
                }
                <div>
                    <label style={{ fontSize: 15, marginRight: "12px" }}>VAT 5%: </label>{" "}
                    <label style={{ fontSize: 15 }}>{vatAmount.toFixed(2)} AED</label>
                </div>
                <div>
                    <label style={{ fontSize: 15, fontWeight: "bold" }}>
                        Total amount:
                    </label>{" "}
                    <label style={{ fontSize: 15, fontWeight: "bold" }}>
                        {Math.floor(totalAmount).toFixed(2)} AED
                    </label>
                </div>
            </div>

            <div className="trans-data-container">
                <div className="trans-data-lhs">
                    {bookingDetails?.addons === "null" ||
                        bookingDetails?.addons === null || bookingDetails?.addons == "" ? (
                        <div className="no-addons">
                            <h4>No Addons</h4>
                        </div>
                    ) : (
                        <Table hover>
                            <thead className="trans-total-table-header">
                                <tr>
                                    <th>#</th>
                                    <th>Item</th>
                                    <th>Price </th>
                                    <th>Quantity </th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookingDetails?.booked_addons?.map((data, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{data?.addonname}</td>
                                            <td>{data?.price} AED</td>
                                            <td>{data?.quantity}</td>
                                            <td>{data?.amount} AED</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    )}
                </div>
                <div className="trans-data-rhs">
                    <div className="trans-data-inner-rhs">
                        <label style={{ fontSize: 15, width: "50%" }}>
                            Points Earned:{" "}
                        </label>{" "}
                        {"  "}

                        {/* <label className="pay-result">{`${bookingDetails?.earned_loyalty_points} Points`}</label> */}
                        <label className="pay-result">{`${(
                            bookingDetails?.boooked_using == "app-personal-coach" ? 0 :
                                bookingDetails?.boooked_using == "admin"
                                    ? !bookingDetails?.promotionUsed &&
                                    bookingDetails?.packages == 0 &&
                                    bookingDetails?.coupan_applied == "" &&
                                    // !sharedCompleted?.eligibility && 
                                    !bookingDetails?.booking_category == "Coaching-Just Padel"
                                    : !bookingDetails?.promotionUsed &&
                                    bookingDetails?.packages == 0 &&
                                    (bookingDetails?.coupan_applied == "" || bookingDetails?.coupan_applied == null)
                        )
                            ? bookingDetails?.earned_loyalty_points
                            : 0
                            } Points`}</label>

                    </div>
                    <div className="trans-data-inner-rhs">
                        <label style={{ fontSize: 15, width: "50%" }}>
                            Coupon Applied:{" "}
                        </label>{" "}
                        {"  "}
                        <label className="pay-result">
                            {bookingDetails?.coupan_applied === "" || bookingDetails?.coupan_applied === null
                                ? "No"
                                : bookingDetails?.coupan_applied}
                        </label>
                    </div>

                    {couponData?.scratch_card === true && (
                        <div className="trans-data-inner-rhs">
                            <label style={{ fontSize: 15, width: "50%" }}>
                                Buy One Get One Coupon:{" "}
                            </label>{" "}
                            {"  "}
                            <label className="pay-result">
                                {couponData?.scratch_card === true
                                    ? "Yes"
                                    : "No"}
                            </label>
                        </div>
                    )}

                    <div className="trans-data-inner-rhs">
                        <label style={{ fontSize: 15, width: "50%" }}>
                            Discounted Price:{" "}
                        </label>{" "}
                        {"  "}
                        <label className="pay-result">
                            {bookingDetails?.coupan_applied === ""
                                ? "0.00"
                                : Math.ceil(discountAmount).toFixed(2)}{" "}
                            AED
                        </label>
                    </div>
                    <div className="trans-data-inner-rhs">
                        <label style={{ fontSize: 15, width: "50%" }}>
                            Packages Used:{" "}
                        </label>{" "}
                        {"  "}
                        <label className="pay-result">
                            {bookingDetails?.packages == 0
                                ? "N0"
                                : bookingDetails?.packages}
                        </label>
                    </div>
                    <div className="trans-data-inner-rhs">
                        <label style={{ fontSize: 15, width: "50%" }}>
                            Amount Status:{" "}
                        </label>{" "}
                        <label className="pay-result">
                            {"  "}
                            {bookingDetails?.cancelled ? (
                                <label className="payment-details-result">Cancelled</label>
                            ) : bookingDetails?.packages > 0 ? (
                                <label className="payment-details-result">Paid by Packages</label>
                            ) : bookingDetails?.boooked_using === "admin" ? (
                                <label className="payment-details-result">{bookingDetails?.payment_processor}</label>
                            ) : bookingDetails?.amount_percentage === 1 ? (
                                <label className="payment-details-result">Paid 100%</label>
                            ) : (
                                <label className="payment-details-result">Paid 50%</label>
                            )}
                        </label>
                    </div>

                    {bookingDetails?.payment_processor === "Pay Later" ? (
                        <>
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>
                                    Advance Paid:
                                </label>{" "}
                                <label className="pay-result">
                                    {bookingDetails?.packages > 0 ? 0 : bookingDetails?.payment_status === "Failed" ? "0.00" : Math.floor(bookingDetails.amount_paid).toFixed(2)} AED
                                </label>
                            </div>
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>
                                    To be Paid at Facility:
                                </label>{" "}
                                {"  "}
                                <label className="pay-result"> {Math.ceil(toBePayAmount).toFixed(2)} AED</label>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>
                                    Advance Paid:
                                </label>{" "}
                                <label className="pay-result">
                                    {bookingDetails?.packages > 0 ? 0 : bookingDetails?.payment_status === "Failed" ? "0.00" : Math.floor(bookingDetails?.amount_paid).toFixed(2)} AED
                                </label>
                            </div>
                            <div className="trans-data-inner-rhs">
                                <label style={{ fontSize: 15, width: "50%" }}>
                                    To be Paid at Facility:
                                </label>{" "}
                                <label className="pay-result">
                                    {bookingDetails?.packages > 0 ? 0 : bookingDetails?.payment_status === "Failed" ? "0.00" : Math.floor(toBePayAmount).toFixed(2)} AED
                                </label>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default BookingCalculations