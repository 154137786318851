import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import EditCourtCategoryEditCard from './components/EditCourtCategoryEditCard';

const EditCourtCategory = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Court Category</h3>
        <h3 className="page-subhead subhead">
          Use this elements, to Edit Court Category
        </h3>
      </Col>
    </Row>
    <Row>
      <EditCourtCategoryEditCard />
    </Row>
  </Container>
);
export default EditCourtCategory;
