/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import AddonsEditCard from './components/AddonsEditCard';

const Addons = () => {
  const [state, setstate] = useState('');

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Add Addon&apos;s</h3>
          {/* <h3 className="page-subhead subhead">
            Use this elements, to Add Addon&apos;s
          </h3> */}
        </Col>
      </Row>
      <Row>
        <AddonsEditCard />
      </Row>
    </Container>
  );
};
export default Addons;
