/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
    Col, Card, CardBody, Table, ButtonToolbar, Button, Spinner
} from 'reactstrap';
import axios from '../../../customAxios';
import { useParams } from 'react-router-dom';
import { environment } from '../../env';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';


const Timeslot = () => {
    const history = useHistory()
    const [courtName, setCourtName] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [reloadState, setReloadState] = useState(false);
    const [padelCourtName, setPadelCourtName] = useState();
    const [times] = useState(['06:00 AM', '06:45 AM', '07:30 AM', '08:15 AM',
        '09:00 AM', '09:45 AM', '10:30 AM', '11:15 AM', '12:00 PM', '12:45 PM', '01:30 PM',
        '02:15 PM', '03:00 PM', '03:45 PM', '04:30 PM', '05:15 PM', '06:00 PM', '06:45 PM',
        '07:30 PM', '08:15 PM', '09:00 PM', '09:45 PM', '10:30 PM', '11:15 PM', '12:00 AM']);
    const [times1] = useState(['12:00 AM', '12:45 AM', '01:30 AM', '02:15 AM']);
    const [timeslot, setTimeslot] = useState({
        sunday_1: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        sunday_2: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        monday_1: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        monday_2: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        tuesday_1: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        tuesday_2: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        wednesday_1: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        wednesday_2: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        thursday_1: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        thursday_2: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        friday_1: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        friday_2: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        saturday_1: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
        saturday_2: {
            openingTime: '',
            closingTime: '',
            ladiesOnly: false,
            disable: false,
        },
    });
    const { id } = useParams();

    function handleChangeOpeningTime(e, day) {
        const timeSlot = { ...timeslot };
        timeSlot[day].openingTime = e.target.value;
        setTimeslot(timeSlot);
        setReloadState(true);
    }
    function handleChangeClosingTime(e, day) {
        const timeSlot = { ...timeslot };
        timeSlot[day].closingTime = e.target.value;
        setTimeslot(timeSlot);
        setReloadState(true);
    }
    function handleChangeLadiesOnly(e, day) {
        const timeSlot = { ...timeslot };
        if (e.target.value === 'True') {
            timeSlot[day].ladiesOnly = true;
        } else {
            timeSlot[day].ladiesOnly = false;
        }
        setTimeslot(timeSlot);
        setReloadState(true);
    }
    function handleChangeDisableSlot(e, day) {
        const timeSlot = { ...timeslot };
        if (e.target.value === 'True') {
            timeSlot[day].disable = true;
        } else {
            timeSlot[day].disable = false;
        }
        setTimeslot(timeSlot);
        setReloadState(true);
    }

    const padelcourtUrl = `${environment.baseUrl}padelcourt/${id}`;

    const GetPadelCourt = async () => {
        const result = await axios(padelcourtUrl);
        setCourtName(result?.data?.maincourt?.courtName);
        setPadelCourtName(result?.data?.courtname);
    };

    const GetAval = () => {
        const manageTimeSlotUrl = `${environment.baseUrl}managetimeslot`;
        const result = axios(manageTimeSlotUrl)
            .then((response) => {
            });
    };

    const getManageTimeSlot = () => {
        const manageTimeSlotByIdURL = `${environment.baseUrl}get_managed_time_slots/${id}`;
        axios.get(manageTimeSlotByIdURL)
            .then((response) => {
                if (response?.data?.timeslotData) {
                    setTimeslot(JSON.parse(response?.data?.timeslotData)[0]);
                } else {
                    setTimeslot(timeslot);
                }

            })
    }
    useEffect(() => {
        getManageTimeSlot();
        GetPadelCourt();
    }, []);

    const handleSubmit = () => {
        setIsLoading(true)
        const Data = {
            timeslotData: [timeslot],
            courtId: parseInt(id),
        };
        const manageTimeSlotUrl = `${environment.baseUrl}managetimeslot`;

        const result = axios.post(manageTimeSlotUrl, Data)
            .then((response) => {
                if (response.data.msg === "data updated Successfully") {
                    swal({
                        title: "Success",
                        text: "Time Slots is updated successfully!",
                        icon: "success",
                        button: "Ok",
                    }).then((value) => {
                        history.push("/all_courts");
                    });
                    setIsLoading(false);
                } else {
                    swal({
                        title: "Error",
                        text: response.data,
                        icon: "error",
                        button: "Ok",
                    });
                    setIsLoading(false);
                }

            }).catch((err) => {
                swal({
                    title: "Error",
                    text: err.messgae,
                    icon: "error",
                    button: "Ok",
                }).then((value) => {
                    history.push("/all_courts");
                });
                setIsLoading(false);
            });
    };

    const reset = () => {
        console.log('reset');
    };

    return (
        <Col md={12}>
            {isLoading && (
                <div class="loader-div">
                    <span class="loader">
                        <div className="center-spinner">
                            <Spinner className="table-fetch-spinner" />
                        </div>
                    </span>
                </div>
            )}
            <Card>
                <CardBody className="form">
                    <div className="card__title">
                        <h5 className="bold-text">Timeslot for {courtName} {padelCourtName}</h5>
                        <h5 className="subhead">Example subhead {courtName} {padelCourtName}</h5>
                    </div>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>Day</th>
                                <th>Slot</th>
                                <th>Opening Time</th>
                                <th>Closing Time</th>
                                <th>Ladies Only</th>
                                <th>Disable</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><h5 className="bold-text">Sunday</h5></td>
                                <td><h5 className="bold-text">Slot 1</h5></td>
                                <td>
                                    <select
                                        className="Select-control"
                                        name="openingTime"
                                        onChange={(e) => handleChangeOpeningTime(e, 'sunday_1')}
                                        value={timeslot?.sunday_1?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        name="closingTime"
                                        onChange={(e) => handleChangeClosingTime(e, 'sunday_1')}
                                        value={timeslot?.sunday_1?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'sunday_1')} value={timeslot?.sunday_1?.ladiesOnly ? "True" : "False"}>
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'sunday_1')} value={timeslot?.sunday_1?.disable ? "True" : "False"}>
                                        <option> Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">-</h5></td>
                                <td><h5 className="bold-text">Slot 2</h5></td>
                                <td>
                                    <select
                                        className="Select-control"
                                        name="openingTime"
                                        onChange={(e) => handleChangeOpeningTime(e, 'sunday_2')}
                                        value={timeslot?.sunday_2?.openingTime}>

                                        <option value=''>Select Opening Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        name="closingTime"
                                        onChange={(e) => handleChangeClosingTime(e, 'sunday_2')}
                                        value={timeslot?.sunday_2?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'sunday_2')}
                                        value={timeslot?.sunday_2?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'sunday_2')}
                                        value={timeslot?.sunday_2?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">Monday</h5></td>
                                <td><h5 className="bold-text">Slot 1</h5></td>
                                <td>
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeOpeningTime(e, 'monday_1')}
                                        value={timeslot?.monday_1?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeClosingTime(e, 'monday_1')}
                                        value={timeslot?.monday_1?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeLadiesOnly(e, 'monday_1')}
                                        value={timeslot?.monday_1?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeDisableSlot(e, 'monday_1')}
                                        value={timeslot?.monday_1?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">-</h5></td>
                                <td><h5 className="bold-text">Slot 2</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeOpeningTime(e, 'monday_2')}
                                        value={timeslot?.monday_2?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeClosingTime(e, 'monday_2')}
                                        value={timeslot?.monday_2?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeLadiesOnly(e, 'monday_2')}
                                        value={timeslot?.monday_2?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeDisableSlot(e, 'monday_2')}
                                        value={timeslot?.monday_2?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">Tuesday</h5></td>
                                <td><h5 className="bold-text">Slot 1</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeOpeningTime(e, 'tuesday_1')}
                                        value={timeslot?.tuesday_1?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeClosingTime(e, 'tuesday_1')}
                                        value={timeslot?.tuesday_1?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'tuesday_1')}
                                        value={timeslot?.tuesday_1?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'tuesday_1')}
                                        value={timeslot?.tuesday_1?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">-</h5></td>
                                <td><h5 className="bold-text">Slot 2</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeOpeningTime(e, 'tuesday_2')}
                                        value={timeslot?.tuesday_2?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="Select-control"
                                        onChange={(e) => handleChangeClosingTime(e, 'tuesday_2')}
                                        value={timeslot?.tuesday_2?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'tuesday_2')}
                                        value={timeslot?.tuesday_2?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'tuesday_2')}
                                        value={timeslot?.tuesday_2?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">Wednesday</h5></td>
                                <td><h5 className="bold-text">Slot 1</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select className="Select-control" onChange={(e) => handleChangeOpeningTime(e, 'wednesday_1')}
                                        value={timeslot?.wednesday_1?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeClosingTime(e, 'wednesday_1')}
                                        value={timeslot?.wednesday_1?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'wednesday_1')}
                                        value={timeslot?.wednesday_1?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'wednesday_1')}
                                        value={timeslot?.wednesday_1?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">-</h5></td>
                                <td><h5 className="bold-text">Slot 2</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select className="Select-control" onChange={(e) => handleChangeOpeningTime(e, 'wednesday_2')}
                                        value={timeslot?.wednesday_2?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeClosingTime(e, 'wednesday_2')}
                                        value={timeslot?.wednesday_2?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'wednesday_2')}
                                        value={timeslot?.wednesday_2?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'wednesday_2')}
                                        value={timeslot?.wednesday_2?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">Thrusday</h5></td>
                                <td><h5 className="bold-text">Slot 1</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select className="Select-control" onChange={(e) => handleChangeOpeningTime(e, 'thursday_1')}
                                        value={timeslot?.thursday_1?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeClosingTime(e, 'thursday_1')}
                                        value={timeslot?.thursday_1?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'thursday_1')}
                                        value={timeslot?.thursday_1?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'thursday_1')}
                                        value={timeslot?.thursday_1?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">-</h5></td>
                                <td><h5 className="bold-text">Slot 2</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select className="Select-control" onChange={(e) => handleChangeOpeningTime(e, 'thursday_2')}
                                        value={timeslot?.thursday_2?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeClosingTime(e, 'thursday_2')}
                                        value={timeslot?.thursday_2?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'thursday_2')}
                                        value={timeslot?.thursday_2?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'thursday_2')}
                                        value={timeslot?.thursday_2?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">Friday</h5></td>
                                <td><h5 className="bold-text">Slot 1</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select className="Select-control" onChange={(e) => handleChangeOpeningTime(e, 'friday_1')}
                                        value={timeslot?.friday_1?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeClosingTime(e, 'friday_1')}
                                        value={timeslot?.friday_1?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'friday_1')}
                                        value={timeslot?.friday_1?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'friday_1')}
                                        value={timeslot?.friday_1?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">-</h5></td>
                                <td><h5 className="bold-text">Slot 2</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select className="Select-control" onChange={(e) => handleChangeOpeningTime(e, 'friday_2')}
                                        value={timeslot?.friday_2?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeClosingTime(e, 'friday_2')}
                                        value={timeslot?.friday_2?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'friday_2')}
                                        value={timeslot?.friday_2?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'friday_2')}
                                        value={timeslot?.friday_2?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">Saturday</h5></td>
                                <td><h5 className="bold-text">Slot 1</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select className="Select-control" onChange={(e) => handleChangeOpeningTime(e, 'saturday_1')}
                                        value={timeslot?.saturday_1?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeClosingTime(e, 'saturday_1')}
                                        value={timeslot?.saturday_1?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times1 && times1.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'saturday_1')}
                                        value={timeslot?.saturday_1?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'saturday_1')}
                                        value={timeslot?.saturday_1?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td><h5 className="bold-text">-</h5></td>
                                <td><h5 className="bold-text">Slot 2</h5></td>
                                <td>
                                    {/* <input onChange={handleChangeWeekName} /> */}
                                    <select className="Select-control" onChange={(e) => handleChangeOpeningTime(e, 'saturday_2')}
                                        value={timeslot?.saturday_2?.openingTime}
                                    >
                                        <option value=''>Select Opening Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeClosingTime(e, 'saturday_2')}
                                        value={timeslot?.saturday_2?.closingTime}
                                    >
                                        <option value=''>Select Closing Time</option>
                                        {times && times.map((time) => (
                                            <option value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeLadiesOnly(e, 'saturday_2')}
                                        value={timeslot?.saturday_2?.ladiesOnly ? "True" : "False"}
                                    >
                                        <option>Select Ladies Only</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                                <td>
                                    <select className="Select-control" onChange={(e) => handleChangeDisableSlot(e, 'saturday_2')}
                                        value={timeslot?.saturday_2?.disable ? "True" : "False"}
                                    >
                                        <option>Disable Slot</option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Col md={12} lg={12}>
                        <Card style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", marginTop: 30, }}>
                            <div className="form__half" style={{ marginRight: 30 }}>
                                <ButtonToolbar className="form__button-toolbar" >
                                    <Button color="warning" size="sm" onClick={handleSubmit}>
                                        Save
                                    </Button>
                                    <Button type="button" size="sm" onClick={() => reset()}>
                                        Reset
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </Card>
                    </Col>
                </CardBody>
            </Card>
        </Col>
    );
};
export default Timeslot;
