import React, {useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import {BiTimeFive, BiTime} from "react-icons/bi";
import {AiOutlineCalendar} from "react-icons/ai";
import {useForm, Controller} from "react-hook-form";
import {Spinner} from "reactstrap";
import {
    GetAdminBooking,
    GetMainCourt,
    GetPadelCourt,
    EditBooking,
    BookingDetailsByBookingID,
    BookingDetailsAvailableFalseByBookingID
} from "../../../Reuse/ApiCalls";
import moment from "moment";
import swal from "@sweetalert/with-react";

const BookingDetailsAdminEditForm = ({
    bookingID,
    formDatas,
    location,
    service_provider,
    booking_date,
    cancellationTime,
    toggleModal
}) => {
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState
    } = useForm({mode: "onChange"});
    const {errors, isValid} = formState;
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [details, setDetails] = useState([]);
    const [mainCourtList, setMainCourtList] = useState([]);
    const [padelCourtList, setPadelCourtList] = useState([]);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [mainCourtId, setMainCourtId] = useState();
    const [padelCourtId, setPadelCourtId] = useState();
    const [comments, setComments] = useState();
    const [bookedSlots, setBookedSlots] = useState([]);
    const [commentsData, setCommentsData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [noOfElements, setNoOfElements] = useState(2);
    const [createdOrEditedLogs, setCreatedOrEditedLogs] = useState([]);
    const [times, setTimes] = useState([
        "06:00 AM",
        "06:45 AM",
        "07:30 AM",
        "08:15 AM",
        "09:00 AM",
        "09:45 AM",
        "10:30 AM",
        "11:15 AM",
        "12:00 PM",
        "12:45 PM",
        "01:30 PM",
        "02:15 PM",
        "03:00 PM",
        "03:45 PM",
        "04:30 PM",
        "05:15 PM",
        "06:00 PM",
        "06:45 PM",
        "07:30 PM",
        "08:15 PM",
        "09:00 PM",
        "09:45 PM",
        "10:30 PM",
        "11:15 PM",
        "12:00 AM",
        "12:45 AM",
        "01:30 AM",
        "02:15 AM",
    ]);
    const loadMore = () => {
        setNoOfElements(noOfElements + noOfElements);
    };

    const loadLess = () => {
        setNoOfElements(noOfElements - 2);
    };

    const slice = commentsData ?. slice(0, noOfElements);

    useEffect(() => {
        GetAdminBooking(bookingID).then((response) => {
            if (response.msg === "Data is not available") {
                setDetails({customer_name: "Just Padel", customer_email: "justpadel_admin@gmail.com", customer_phone: "+97 1589 046 968"});
                const resetFormData = {
                    client: "Just Padel",
                    location: formDatas ?. location,
                    service_provider: formDatas ?. service_provider
                };
            } else {
                setDetails(response);
                localStorage.setItem("BookingDetailsAdminEditForm", JSON.stringify({
                    client: response ?. customer_name,
                    location: location,
                    service_provider: service_provider
                }));
            }
        });

        setTimeout(() => {
            reset(JSON.parse(localStorage.getItem("BookingDetailsAdminEditForm")));
        }, 1000);
        reset(JSON.parse(localStorage.getItem("BookingDetailsAdminEditForm")));

        GetMainCourt().then((response) => {
            setMainCourtList(response);
        });

        GetPadelCourt().then((response) => {
            const padelCourt = [];
            let padelCourts = response;
            let padelCourtFilter = padelCourts.filter(function (padelCourts) {
                return padelCourts.maincourt.id === parseInt(location);
            });
            padelCourtFilter.map((data) => {
                padelCourt.push({id: data.id, courtname: data.courtname});
            });
            setPadelCourtList(padelCourt);
        });

        BookingDetailsAvailableFalseByBookingID(bookingID).then((response) => {
            if (response ?. data ?. logs !== "") {
                if (typeof response ?. data ?. logs === "string") {
                    setCreatedOrEditedLogs(JSON.parse(response ?. data ?. logs));
                } else {
                    setCreatedOrEditedLogs(response ?. data ?. logs);
                }
                setBookedSlots(response.data.booked_slots);
                const com = response.data.comments === "" || response.data.comments === undefined ? JSON.stringify([{
                        comment: "No Comments"
                    }]) : JSON.parse(response.data.comments);
                if (typeof com === "string") {
                    setCommentsData(JSON.parse(com));
                } else {
                    setCommentsData(com);
                }
            } else {
                setCreatedOrEditedLogs([]);
            }
        });
        // BookingDetailsByBookingID(bookingID).then((response) => {
        // const com =
        //     response.data.comments === "" || response.data.comments === undefined || response.data.comments === "{}"
        //       ? JSON.stringify([{comment:"No Comments"}])
        //       : JSON.parse(response.data.comments)
        // setCommentsData(com);
        // });
    }, [location, service_provider, reset]);

    const handlePadelCourt = (value) => {
        setMainCourtId(parseInt(value));
        GetPadelCourt().then((response) => {
            const padelCourt = [];
            let padelCourts = response;
            let padelCourtFilter = padelCourts.filter(function (padelCourts) {
                return padelCourts.maincourt.id === parseInt(value);
            });
            padelCourtFilter.map((data) => {
                padelCourt.push({id: data.id, courtname: data.courtname});
            });
            setPadelCourtList(padelCourt);
        });
    };

    const onSubmit = (data) => {

        const start = moment(startDate).format("YYYY/MM/DD")+ " "+data.starttime;
        const end = moment(endDate).format("YYYY/MM/DD")+ " " +data.endtime;
        var from = new Date(Date.parse(start));
        var to = new Date(Date.parse(end));
        
        if (from > to){
          swal({
            title: "Please Select Correct Time",
            text: "End Time Should Be Bigger Than Start Time!",
            icon: "warning",
            buttons: true,
            dangerMode: true
        })
        };
        swal({
            title: "Are you sure?",
            text: "Reschedule this booking ?",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                setSpinner(true);
                let comment = [];
                if (commentsData.comment !== undefined || commentsData.comment !== "") {
                    comment = commentsData;
                }

                let commentArray = [];
                if (commentsData.length >= 2) {
                    commentArray = [
                        ...commentsData, {
                            comment: data.comment,
                            date: moment(new Date()).format("DD-MM-YYYY"),
                            time: moment(new Date().toLocaleString('en-US', {timeZone: 'Asia/Dubai'})).format("hh:mm A"),
                            user: localStorage.getItem("AdminUserName")
                        },
                    ];
                } else {
                    if (comment.length >= 1) {
                        commentArray = [
                            ... comment, {
                                comment: data.comment,
                                date: moment(new Date()).format("DD-MM-YYYY"),
                                time: moment(new Date().toLocaleString('en-US', {timeZone: 'Asia/Dubai'})).format("hh:mm A"),
                                user: localStorage.getItem("AdminUserName")
                            },
                        ];
                    } else {
                        commentArray = [{
                                comment: data.comment,
                                date: moment(new Date()).format("DD-MM-YYYY"),
                                time: moment(new Date().toLocaleString('en-US', {timeZone: 'Asia/Dubai'})).format("hh:mm A"),
                                user: localStorage.getItem("AdminUserName")
                            },];
                    }
                }

                // { comment: data.comment },


                let adminUseName = localStorage.getItem("AdminUserName");
                var time = new Date();
                const editBookingPayload = {
                    cancellation_start_date: booking_date,
                    cancellation_end_date: booking_date,
                    cancellation_start_time: cancellationTime.split("-")[0],
                    cancellation_end_time: cancellationTime.split("-")[1],
                    cancellation_maincourtid: location,
                    cancellation_padecourtid: service_provider,
                    start_date: moment(startDate).format("YYYY-MM-DD"),
                    end_date: moment(endDate).format("YYYY-MM-DD"),
                    start_time: data.starttime,
                    end_time: data.endtime,
                    customer_name: details ?. customer_name,
                    customer_email: details ?. customer_email,
                    customer_phone_no: details ?. customer_phone,
                    amount_paid_by: details ?. payment_type,
                    comments: JSON.stringify(commentArray),
                    amount: 0,
                    admin_id: 1,
                    booking_id: bookingID,
                    maincourtid: data.location ? parseInt(data.location) : location,
                    padecourtid: data.service_provider ? parseInt(data.service_provider) : service_provider,
                    log: JSON.stringify(
                        [
                            {
                                created_or_edited_by: "Edited by user " + adminUseName + "",
                                created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
                                created_or_edited_time: moment(new Date().toLocaleString('en-US', {timeZone: 'Asia/Dubai'})).format("hh:mm A")
                            },
                            ...createdOrEditedLogs,
                        ]
                    )
                };
                EditBooking(editBookingPayload).then((response) => {
                    if (response.Message === "Court is already Available") {
                        setSpinner(false);
                        swal({
                            icon: "error",
                            content: (
                                <div>
                                    <h1 style={
                                        {color: "darkred"}
                                    }>Error</h1>
                                    <p>Court is already Available</p>
                                </div>
                            )
                        }).then(() => {
                            toggleModal();
                        });
                    } else if (response.Message === "Please select equal timeslot") {
                        setSpinner(false);
                        swal({
                            icon: "error",
                            content: (
                                <div>
                                    <h1 style={
                                        {color: "darkred"}
                                    }>Error</h1>
                                    <p>Please select equal timeslot</p>
                                </div>
                            )
                        }).then(() => {
                            toggleModal();
                        });
                    } else if (response.Message === "Booking Cancelled and Rebooked!!!") {
                        setSpinner(false);
                        swal({
                            icon: "success",
                            content: (
                                <div>
                                    <h1 style={
                                        {color: "#28a745"}
                                    }>Successfully!</h1>
                                    <p>Booking Cancelled and Rebooked!!!</p>
                                </div>
                            )
                        }).then(() => {
                            toggleModal();
                        });
                    } else if (response.Message === "Court Already Booked!!!") {
                        setSpinner(false);
                        swal({
                            icon: "Warning",
                            content: (
                                <div>
                                    <h1 style={
                                        {color: "#28a745"}
                                    }>Warning!</h1>
                                    <p>There already booked court</p>
                                </div>
                            )
                        }).then(() => {
                            toggleModal();
                        });
                    } else {
                        setSpinner(false);
                        swal({
                            icon: "error",
                            content: (
                                <div>
                                    <h1 style={
                                        {color: "#28a745"}
                                    }>Error!</h1>
                                    <p>Data is not available</p>
                                </div>
                            )
                        });
                    }
                });
            } else {
                swal("Booking is not rescheduled");
            }
        });
    };

    return (
        <>
            <form onSubmit={
                handleSubmit(onSubmit)
            }>
                <div className="center-spinner">
                    {
                    spinner ? <Spinner className="table-fetch-spinner"/> : ""
                } </div>
                <div className="Booking-details-inner">
                    <div className="client-text">Client</div>
                    <div>
                        <div className="input-div">
                            <input type="text" name="client" {...register("client", { required: true })} className="booking-user-Edit-desabled" disabled/> {/* <AiOutlineSearch className="search-icon" /> */} </div>
                        {
                        errors.client && (
                            <span style={
                                {color: "#bc4040"}
                            }>
                                Client detail Is require
                            </span>
                        )
                    } </div>
                </div>


                <div className="Booking-details-inner">
                    <div className="client-text">Location</div>
                    <select className="input-div" name="location" id="" {...register("location", { required: true })}
                        onChange={
                            (e) => {
                                handlePadelCourt(e.target.value);
                            }
                        }
                        disabled="disabled">
                        <option value="">Select Location</option>
                        {
                        mainCourtList.map((data, index) => {
                            return (
                                <option value={
                                        data.id
                                    }
                                    key={index}>
                                    {
                                    data.courtName
                                } </option>
                            );
                        })
                    } </select>
                    {
                    errors.location && (
                        <span style={
                            {color: "#bc4040"}
                        }>Client detail Is require</span>
                    )
                } </div>

                <div className="Booking-details-inner">
                    <div className="client-text">
                        Service provider<span className="Require-star">*</span>
                    </div>
                    <select className="input-div" name="service_provider" {...register("service_provider", { required: true })}>
                        <option value="">Select Service Provider</option>
                        {
                        padelCourtList.map((data, index) => {
                            return (
                                <option value={
                                        data.id
                                    }
                                    key={index}>
                                    Court - {
                                    data.courtname
                                } </option>
                            );
                        })
                    } </select>
                    {
                    errors.service_provider && (
                        <span style={
                            {color: "#bc4040"}
                        }>Client detail Is require</span>
                    )
                } </div>

                <div className="Booking-details-inner">
                    <div className="client-text">Current Time</div>
                    <div className="current-timing">
                        {
                        bookedSlots && bookedSlots ?. map((data) => {
                            return (
                                <div className="inner-current-time">
                                    <div> {
                                        data ?. date
                                    }
                                        {"  "}
                                        {
                                        data ?. time.split("-")[0]
                                    } </div>
                                    <div> {
                                        data ?. date
                                    }
                                        {"  "}
                                        {
                                        data ?. time.split("-")[1]
                                    } </div>
                                </div>
                            );
                        })
                    } </div>
                </div>

                <div className="Booking-details-inner">
                    <div className="client-text">
                        Start time<span className="Require-star">*</span>
                    </div>
                    <div className="client-date">

                        <h5 className="client-date-time">
                            <AiOutlineCalendar size={17}
                                color="#2196f3"/>
                            <Controller control={control}
                                name={"startdate"}
                                render={
                                    ({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            ref
                                        }
                                    }) => (
                                        <DatePicker onChange={
                                                (date) => setStartDate(date)
                                            }
                                            onBlur={onBlur}
                                            wrapperClassName="datePicker"
                                            selected={startDate}/>
                                    )
                                }/>
                            <BiTime size={17}
                                color="#2196f3"/>
                            <select className="Select-control" name="starttime" {...register("starttime", { required: true })}>
                                <option value="">Start Time</option>
                                {
                                times && times.map((time) => {
                                    return <option value={time}>
                                        {time} </option>;
                                })
                            } </select>
                        </h5>
                        <h5 className="client-date-time"
                            style={
                                {marginLeft: 10}
                        }>
                            <AiOutlineCalendar size={17}
                                color="#2196f3"/>
                            <Controller control={control}
                                name={"enddate"}
                                render={
                                    ({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            ref
                                        }
                                    }) => (
                                        <DatePicker onChange={
                                                (date) => setEndDate(date)
                                            }
                                            onBlur={onBlur}
                                            wrapperClassName="datePicker"
                                            selected={endDate}/>
                                    )
                                }/>
                            <BiTimeFive size={17}
                                color="#2196f3"/>
                            <select className="Select-control" name="endtime" {...register("endtime", { required: true })}>
                                <option value="">
                                    End Time</option>
                                {
                                times && times.map((time) => <option value={time}>
                                    {time}</option>)
                            } </select>
                        </h5>
                    </div>
                </div>

                {/* <div className="Booking-details-inner">
          <div className="client-text">Previous comments</div>
          <div className="prev-comments">
            
              {
                  slice?.map((data, index) => {
                  return (
                      <p>{data?.comment}</p>
                  )
                  })
              }
                  <div>
                  {commentsData?.length > 2 ? <span style={{ color: '#ccc', cursor: 'pointer' }} onClick={() => loadMore()}>Read More....</span> : "" }
                  {noOfElements === 2 ? "": <span style={{ color: '#ccc', cursor: 'pointer',marginLeft:10 }} onClick={() => loadLess()}>Read Less....</span>}   
                  </div>
                
          </div> 
        </div> */}

                <div className="Booking-details-inner">
                    <div className="client-text"></div>
                    <div style={
                        {
                            display: "flex",
                            flexDirection: "column"
                        }
                    }>
                        <div className="comments">
                            <textarea style={
                                    {
                                        width: 400,
                                        height: 70,
                                        outline: 'none'
                                    }
                                }
                                name="comment"
                                placeholder="Please write proper comments here"
                                {...register("comment", { required: true })}></textarea>
                        </div>
                        {
                        errors.comment && (
                            <span style={
                                {color: "#bc4040"}
                            }>Comment Is require</span>
                        )
                    } </div>
                </div>

                <div className="Booking-details-inner"
                    style={
                        {
                            display: "flex",
                            justifyContent: "flex-start"
                        }
                }>
                    <div className="client-input">
                        <span>
                            <span style={
                                {fontWeight: "bold"}
                            }>Note:</span>
                            All bookings
                                          related to ID{" "}
                            <span style={
                                {fontWeight: "bold"}
                            }>#{bookingID}</span>
                            will be
                                          updated to new timings.
                        </span>
                    </div>
                </div>

                <div className="save-btn">
                    <button type="submit" className="save-input"
                        disabled={
                            !isValid
                    }>
                        Submit
                    </button>
                </div>
            </form>
        </>
    );
};
export default BookingDetailsAdminEditForm;
