import React, { useEffect, useState } from 'react';
import { GetAdminBooking } from '../../Reuse/ApiCalls';

const AdminUserDetails = ({ id }) => {
    const [details, setDetails] = useState();

    const GetData = async (id) =>{
        await GetAdminBooking(id).then((response) => {
            if (response.msg !== "Data is not available") {                
                setDetails(response);
            } else {
                setDetails({
                    customer_name:"Just Padel",
                    customer_email:"justpadel_admin@gmail.com",
                    customer_phone:"+971589046968"
                })
            }
        })
    }

    useEffect(() => {
        GetData(id);
    }, []);


    useEffect(() => {
        setDetails(details);
    }, [details])

    return (
        <>
            {details?.customer_name}<br />
            <span className="user-email">{details?.customer_email}</span><br />
            <span className="user-phone">{details?.customer_phone}</span>
        </>
    )
};

export default AdminUserDetails;
