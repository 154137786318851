import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import LoyalityPointCrad from './LoyalityPointCrad'
const LoyalityPointsManagement = () => {
    return (
        <Container>
            <Row md={12}>
                <Col>
                    <h3 className='page-title'>Loyality Points Management</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <LoyalityPointCrad />
                </Col>
            </Row>
        </Container>
    )
}

export default LoyalityPointsManagement