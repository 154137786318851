import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import CourtCategoryEditCard from './components/CourtCategoryEditCard';

const ListCourtCategory = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Court Category</h3>
      </Col>
    </Row>
    <Row>
      <CourtCategoryEditCard />
    </Row>
  </Container>
);
export default ListCourtCategory;
