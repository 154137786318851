import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "../../../../customAxios";
import { environment } from "../../../env";
import _ from "lodash";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  CardBody,
  Card,
  Col,
  Spinner,
  Input,
  Row,
} from "reactstrap";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";

const SplitPointComponentPlayer1 = ({ playerId, userId, email, name, phone, totalPoints }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  const [clearSearchPlayerTwo, setClearSearchPlayerTwo] = useState(true);

  // --------------------------------------------------------------------
  // ----------------- Player Second Functionality ----------------------
  // --------------------------------------------------------------------
  let playerSecValue;
  let splitPoints = watch("point");
  const getPlayerSecDetails = () => {
    const userDetailsUrl = `${environment.baseUrl}filter-user/${playerSecValue}`;
    axios.get(userDetailsUrl).then((response) => {
      reset({
        userDetails:
          response.data[0].first_name +
          " - " +
          response.data[0].email +
          " - " +
          response.data[0].phone_no,
        points: totalPoints/4,
      });
      localStorage.setItem(
        "player" + playerId,
        JSON.stringify({
          userid: response.data[0].id,
          email: response.data[0].email,
          point: totalPoints/4,
          flag: "sub",
        })
      );
      setClearSearchPlayerTwo(true);
    });
  };

  const debouncedPlayerSecDetails = _.debounce(getPlayerSecDetails, 3000);
  const PlayerSecDetails = (playerSecId) => {
    if (playerSecId.length > 1) {
      playerSecValue = playerSecId;
      debouncedPlayerSecDetails();
    }
  };
  // --------------------------------------------------------------------
  // ----------------- Player Second Functionality ----------------------
  // --------------------------------------------------------------------

  const handleClearSearchPlayerTwo = (e) => {
    e.preventDefault();
    reset({ userDetails: "" });
    localStorage.removeItem("player" + playerId);
    setClearSearchPlayerTwo(false);
  };

  const handlePointChange = (e) => {
    let palyerData = JSON.parse(localStorage.getItem("player" + playerId));
    localStorage.setItem(
      "player" + playerId,
      JSON.stringify({ ...palyerData, point: totalPoints/4 })
    );
  };

  useEffect(() => {
    reset({
        userDetails:
          name +
          " - " +
         email +
          " - " +
          phone
      });
      localStorage.setItem(
        "player" + playerId,
        JSON.stringify({
          userid: userId,
          email: email,
          point: totalPoints/4,
          flag: "sub",
        })
      );
  }, []);
  

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row md={12} lg={12} className="align-items-center justify-content-between">
          <Col md={2} lg={2}>
            <p className="font-weight-bold">Player {playerId}:</p>
          </Col>
          <Col md={8} lg={8} className="marginleft">
            <div className="row no-gutters bulk-d">
              <div className="col">
                <input
                  name="userDetails"
                  className="bulk-input"
                  type="text"
                  placeholder="search"
                  readOnly
                  {...register("userDetails", { required: true })}
                  onChange={(e) => PlayerSecDetails(e.target.value)}
                />
              </div>
              <div className="d-flex">
                <button
                  type="button"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {clearSearchPlayerTwo ? (
                    <AiOutlineCloseCircle
                      className="close"
                      // onClick={(e) => handleClearSearchPlayerTwo(e)}
                    />
                  ) : (
                    <AiOutlineSearch
                      className="search-icon"
                      style={{ marginLeft: 0, fontSize: "24px" }}
                    />
                  )}
                </button>
              </div>
            </div>
          </Col>
          <Col md={2} lg={2}>
            <div className="quantity width">
              <input type="number" {...register("point", { required: true })} defaultValue="1"  onClick={(e) => handlePointChange(e)} readOnly className="d-none" />
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default SplitPointComponentPlayer1;
