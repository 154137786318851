import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Modal, ModalBody, Row, Spinner } from "reactstrap";
import classNames from "classnames";
import { RTLProps } from "../prop-types/ReducerProps";
import { useForm } from "react-hook-form";
import moment from "moment";
import axios from "../../customAxios";
import { environment } from "../../containers/env";
import swal from "@sweetalert/with-react";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";

export const GetAvailableSlotsModal = ({
  color,
  btn,
  colored,
  header,
  title,
  maincourtId,
  padelcourtId,
  time,
  date,
  available,
  GetAvailableSlotsFun,
}) => {
  const [modal, setModal] = useState(false);
  const [start, onChangeStart] = useState(new Date(date));
  const [loading, setLoading] = useState(false);
  const [availability, setAvailable] = useState(available);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }

  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const onSubmit = async (e) => {
    setLoading(true);
    let selectDate = moment(start).format("YYYY-MM-DD");

    const UpdateAvailableSlotsPayload = {
      date: selectDate,
      maincourtId: maincourtId,
      padelcourtId: padelcourtId,
      time: time,
      available: availability,
    };
    const data = {
      maincourt: maincourtId,
      padelcourt: padelcourtId
    }

    console.log('UpdateAvailableSlotsPayload====>',UpdateAvailableSlotsPayload)



    const UpdateAvailableSlotsUrl = `${environment.baseUrl}get-available-slots`;
    const UpdateAvailableSlotsData = await axios
      .put(UpdateAvailableSlotsUrl, UpdateAvailableSlotsPayload)
      .then((res) => {
        if (res.data.msg === "Slots Updated Successfully") {
          swal({
            icon: "success",
            content: (
              <div>
                <h1 style={{ color: "#28a745" }}>Slot is Updated</h1>
              </div>
            ),
          }).then(() => {
            // toggle();
            GetAvailableSlotsFun(data,selectDate);
            setLoading(false);
          });
          setLoading(false);
        } else {
          swal({
            icon: "error",
            content: (
              <div>
                <h1 style={{ color: "darkred" }}>Error</h1>
                <p>Error, While updating Slots</p>
              </div>
            ),
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err---->", err);
        setLoading(false);
      });
    setModal(false);
  };

  const handleDropdownChange = (event) => {
    setAvailable(event.target.value);
  };

  return (
    <>
      {loading && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}

      <div className="modal-edit-btn" onClick={toggle}>
        <EditOutlineIcon style={{ fontSize: 20 }} />{" "}
        <Modal
          isOpen={modal}
          toggle={toggle}
          modalClassName={`modal ltr-support`}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              aria-label="modal__close-btn"
              type="button"
              onClick={toggle}
            />
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md="6" sm="9" lg="9">
                  <label
                    htmlFor="availability-dropdown"
                    style={{
                      margin: "0 20px 0 0 ",
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                  >
                    Make Slot Available:
                  </label>
                  <select
                    id="availability-dropdown"
                    value={availability}
                    onChange={handleDropdownChange}
                    style={{ padding: "5px", borderRadius: "5px" }}
                  >
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </Col>
                <Col md="3" sm="3" lg="3">
                  <button className="download-booking-table-xls-button center">
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

GetAvailableSlotsModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  // btn: PropTypes.string.isRequired,
  // rtl: RTLProps.isRequired,
};

GetAvailableSlotsModal.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
};
