import React, { useEffect, useState } from "react";
import {
  Col,
  ModalHeader,
  CardBody,
  Spinner,
  Row,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { environment } from "../env";
import axios from "../../customAxios";
import ReactPaginate from "react-paginate";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import ReactTableBase from "../../shared/components/table/ReactTableBase";

export const CouponRejected = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [coupanDocsData, setCoupanDocsData] = useState([]);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const toggle = () => setModal(!modal);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [pageCount, setpageCount] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const handlePageClick = async (data) => {
    var currentPage = data.selected + 1;
    setCurrentPageNo(currentPage);
    setInitialPage(data.selected);
    localStorage.setItem("CouponDocCurrentPage", currentPage);
    await GetCoupanDocs(currentPage, pageLimit);
  };

  async function handleLimitChange(e) {
    localStorage.setItem("CouponDocCurrentPageLimit", e.target.value);
    setInitialPage(0);
    setPageLimit(e.target.value);
  }

  const handleImageZoom = (imageURL) => {
    toggle();
    setSelectedImage(imageURL);
    setOpen(!open);
  };

  const GetCoupanDocs = async (page, pageLimit) => {
    setIsLoading(true);
    const CoupanDocsUrl = `${environment.baseUrl}get-src-coupon/rejected?page=${page}&page_size=${pageLimit}`;
    await axios
      .get(CoupanDocsUrl)
      .then((res) => {
        setCoupanDocsData(res.data.results);
        setpageCount(Math.ceil(res?.data?.count / pageLimit));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    GetCoupanDocs(currentPageNo, pageLimit);
  }, [currentPageNo, pageLimit]);

  var jsonObj = [];
  const startId = (currentPageNo - 1) * pageLimit + 1;
  for (let i = 0; i < coupanDocsData.length; i++) {
    var item = {};
    // item["id"] = i + 1;
    item["id"] = startId + i;
    item["front_id"] = (
      <div className="Booking-details-td-right-space">
        <img
          src={coupanDocsData[i].front_id}
          style={{
            width: 100,
            height: 100,
            borderRadius: 5,
            cursor: "pointer",
          }}
          alt={coupanDocsData[i].front_id}
          onClick={() => handleImageZoom(coupanDocsData[i].front_id)}
        />
      </div>
    );
    item["back_id"] = (
      <div className="Booking-details-td-right-space">
        <img
          src={coupanDocsData[i].back_id}
          style={{
            width: 100,
            height: 100,
            borderRadius: 5,
            cursor: "pointer",
          }}
          alt={coupanDocsData[i].back_id}
          onClick={() => handleImageZoom(coupanDocsData[i].back_id)}
        />
      </div>
    );
    item["doc_for"] = (
      <div className="Booking-details-td-right-space">
        {coupanDocsData[i]?.doc_for}
      </div>
    );
    item["user_email"] = (
      <div className="Booking-details-td-right-space">
        {coupanDocsData[i].user?.first_name}
        <br />
        {coupanDocsData[i].user?.email}
        <br />
        {coupanDocsData[i].user?.phone_no}
      </div>
    );
    item["reason"] = (
      <div className="Booking-details-td-right-space">
        {coupanDocsData[i]?.reason}
      </div>
    );

    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "Id",
        accessor: "id",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Doc Front Image",
        accessor: "front_id",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Doc Back Image ",
        accessor: "back_id",
        disableGlobalFilter: true,
        width: 100,
      },

      {
        Header: "Doc For",
        accessor: "doc_for",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Email",
        accessor: "user_email",
        disableGlobalFilter: true,
        width: 150,
      },
      {
        Header: "Reason For Rejected",
        accessor: "reason",
        disableGlobalFilter: true,
        width: 150,
      },
    ],
    rows: jsonObj,
  };

  const handleClickWithPagination = (data) => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  const tableConfig = {
    isEditable: false,
    isResizable,
    withPagination,
    manualPageSize: [10000, 20000, 30000, 40000],
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        className="custom_zoom_img"
      >
        <ModalBody className="">
          <img
            src={selectedImage}
            style={{ width: "450px", height: "600px", cursor: "pointer" }}
            alt={selectedImage}
          />
        </ModalBody>
      </Modal>

      <CardBody>
        {isLoading && (
          <div class="loader-div">
            <span class="loader">
              <div className="center-spinner">
                <Spinner className="table-fetch-spinner" />
              </div>
            </span>
          </div>
        )}

        {coupanDocsData.length > 0  ? (
          <Row style={{ marginTop: "20px" }}>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <>
                    <div className="react-table__wrapper hide-table-costomizer">
                      <ReactTableCustomizer
                        handleClickWithPagination={handleClickWithPagination}
                        handleClickIsResizable={handleClickIsResizable}
                        isResizable={isResizable}
                        withPagination={withPagination}
                      />
                    </div>
                    <div className="table-id-width  pagination-hide  hover-effect search-input-update">
                      <ReactTableBase
                        columns={data.columns}
                        data={data.rows}
                        tableConfig={tableConfig}
                      />
                    </div>
                    <div className="custom-pagination">
                      <div className="records-per-page">
                        <select
                          value={pageLimit}
                          onChange={handleLimitChange}
                          name="limit"
                          id="select-pagination"
                        >
                          {mannualLimits &&
                            mannualLimits.map((mannualLimits) => (
                              <option value={mannualLimits}>
                                {mannualLimits}
                              </option>
                            ))}
                        </select>
                        &nbsp;&nbsp;&nbsp;records per page
                      </div>
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        forcePage={initialPage}
                      />
                    </div>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <div
            className="page-title text-center text-gray"
            style={{ color: "#646777" }}
          >
            No Applicants
          </div>
        )}
      </CardBody>
    </>
  );
};
