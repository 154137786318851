import React, { useState, useMemo } from 'react'

const AddonMainCourt = ({ mainCourtList, addonMainCourt }) => {
    const [courtForAddon, setCourtForAddon] = useState([]);
    useMemo(() => {
        var addonForMainCourt = [];
        addonMainCourt.map((data)=>{
            var filtered = mainCourtList.filter((court)=> court.value == data)[0]
            addonForMainCourt.push(filtered);            
        });
        setCourtForAddon(addonForMainCourt);

    }, [addonMainCourt, mainCourtList]);

  return (
    <div>
        {
            courtForAddon?.map((court)=>{
                return(
                    <p>{court?.label}</p>
                )
            })
        }
    </div>
  )
}

export default AddonMainCourt