// export default StaffLeaveModal

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, Row, Col } from "reactstrap";
import classNames from "classnames";
import { RTLProps } from "../prop-types/ReducerProps";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import useOnClickOutside from "./Outside";
import transition from "react-element-popper/animations/transition";
import { useForm, Controller } from "react-hook-form";
import axios from "../../customAxios";
import { environment } from "../../containers/env";
import moment from "moment";
import swal from "sweetalert";

const StaffLeaveModal = ({ color, btn, colored, header, title, staffObject }) => {
  const [modal, setModal] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState();
  const today = new Date();
  const [values, setValues] = useState([today]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  const dateTimeRef = React.useRef();

  const handleDatePickerClose = useCallback(
    () => dateTimeRef.current.closeCalendar(),
    [dateTimeRef]
  );

  // if commented this line component TimePicker don't be closed on click anywhere
  useOnClickOutside(dateTimeRef, handleDatePickerClose);

  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });


  const onSubmit = (data) => {
    const leaveDateList = [];
    data.date.map((dates)=>{
      leaveDateList.push(moment(dates.toDate?.().toString()).format("YYYY-MM-DD"));
    });
    data.date = leaveDateList;
    
    const coachLeaveUrl = `${environment.baseUrl}staffmanagement/staff-leaves/`;
    axios.post(coachLeaveUrl, data).then((staffLeaveResponse)=>{
      if(staffLeaveResponse.data.code[0] === 2000){
        swal({
          title: "Success",
          text: staffLeaveResponse.data.message,
          icon: "success",
          button: "Ok",
        }).then(function () {
          toggle();
        });
      }else{
        swal({
          title: "Error",
          text: staffLeaveResponse.data.message,
          icon: "error",
          button: "Ok",
        }).then((value) => {
          toggle()
        });
      }
    })
    
  };

  return (
    <div>
      <Button color={color} onClick={toggle} className="add-packages">
        {btn}
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`modal ltr-support`}
        className={`modal-dialog--${color} ${modalClass}`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm="6" md="6" lg="6">
                <label>Select date</label>
                <Controller
                  control={control}
                  name="date"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, name, value },
                    formState: { errors },
                  }) => (
                    <DatePicker
                      ref={dateTimeRef}
                      multiple
                      value={value}
                      onChange={(date) => {
                        onChange(date);
                      }}
                      plugins={[<DatePanel />]}
                      inputClass="form-control"
                      animations={[transition({ duration: 800, from: 35 })]}
                    />
                  )}
                />
                {errors.date && <span className="error-msg">Please select date</span>}
              </Col>
              <Col sm="5" md="5" lg="5">
              <label>Select Staff</label>
                <Controller
                  control={control}
                  name="staffId"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <Select
                      defaultValue={selectedCoach}
                      value={staffObject.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                      options={staffObject}
                      placeholder={"Select Staff"}
                    />
                  )}
                />
                {errors.staffId && <span className="error-msg">Please select staff</span>}                
              </Col>
            </Row>
            <Col sm="3" md="3" lg="3" className="float-right mt-2">
              <Button
                typ="submit"
                color={color}
                className="btn btn-primary add-packages"
              >
                Submit
              </Button>
            </Col>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

StaffLeaveModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
};

StaffLeaveModal.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
};

export default StaffLeaveModal;
