/* eslint-disable no-alert */
import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import AddonBook from './components/AddonBook';

const BookingIndex = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Book Addons</h3>
        {/* <h3 className="page-subhead subhead">
          Use this elements, for Booking&apos;s Details
        </h3> */}
      </Col>
    </Row>
    <Row>
      <AddonBook />
    </Row>
  </Container>
);
export default BookingIndex;
