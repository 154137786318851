import axios from "../../customAxios";
import { environment } from "../env";

//----------------------------Function to get all user registered------------------------------//
export const GetRegisteredUser = () => {
  const registeredUserURL = `${environment.baseUrl}api/user/registration/`;
  const result = axios
    .get(registeredUserURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//----------------------------Function to get main court---------------------------------------//
export const GetMainCourt = () => {
  const mainCourtURL = `${environment.baseUrl}maincourts-admin/`;
  const result = axios
    .get(mainCourtURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//----------------------------Function to get main court---------------------------------------//
export const GetMainCourtById = (id) => {
  const mainCourtURL = `${environment.baseUrl}maincourts/${id}`;
  console.log("mainCourtURL---in--", mainCourtURL);
  const result = axios
    .get(mainCourtURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//----------------------------Function to get padel court---------------------------------------//
export const GetPadelCourt = () => {
  const padelCourtURL = `${environment.baseUrl}padelcourt`;
  const result = axios
    .get(padelCourtURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to get booking details by User ID---------------------------//
export const BookingDetailsByID = (id) => {
  const usersBookingUrl = `${environment.baseUrl}get_booking_user/${id}`;
  const result = axios(usersBookingUrl)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to get booking details by Booking ID---------------------------//
export const BookingDetailsByBookingID = async (id) => {
  const getBookingUrl = `${environment.baseUrl}courtbooking_details/${id}`;
  const result =await axios(getBookingUrl)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to get booking details by Booking ID for available True---------------------------//
export const BookingDetailsAvailableTrueByBookingID = (id) => {
  const getBookingUrl = `${environment.baseUrl}courtbooking_details_true/${id}`;
  const result = axios(getBookingUrl)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to get booking details by Booking ID for available False---------------------------//
export const BookingDetailsAvailableFalseByBookingID = (id) => {
  const getBookingUrl = `${environment.baseUrl}courtbooking_details_false/${id}`;
  const result = axios(getBookingUrl)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to get booking details by Booking ID for transaction table---------------------------//
export const BookingDetailsByBookingIDForTransaction = async (id) => {
  const getBookingUrl = `${environment.baseUrl}courtbooking_details_transactions/${id}`;
  const result = await axios(getBookingUrl)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to get booking details by Booking ID for transaction avaialble True table---------------------------//
export const BookingDetailsByBookingIDForTransactionTrue = async (id) => {
  const getBookingUrl = `${environment.baseUrl}courtbooking_details_transactions_true/${id}`;
  const result = await axios(getBookingUrl)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to get booking details by Booking ID for transaction avaialble False table---------------------------//
export const BookingDetailsByBookingIDForTransactionFalse = async (id) => {
  const getBookingUrl = `${environment.baseUrl}courtbooking_details_transactions_false/${id}`;
  const result = await axios(getBookingUrl)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to get user details by user ID---------------------------//
export const GetUserDetails = (id) => {
  const getBookingUrl = `${environment.baseUrl}user/${id}`;
  const result = axios(getBookingUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to get user details by user EMAIL---------------------------//
export const GetUserDetailsByEmail = (email) => {
  const GetUserDetailsByEmailUrl = `${environment.baseUrl}getUserDetails/${email}/`;
  const result = axios(GetUserDetailsByEmailUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//-----------------------Function to update user details by user EMAIL---------------------------//

export const UpdateUserDetails = (email, userData) => {
  const userUrl = `${environment.baseUrl}update_user_details/${email}/`
  const result = axios
    .put(userUrl, userData)
    .then((UserResponse) => {
      return UserResponse.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
}

//------------------------Function for edit booking api calls
export const EditBooking = (EditBookingPayload) => {
  const editBookingUrl = `${environment.baseUrl}editbulkbooking`;
  const result = axios
    .post(editBookingUrl, EditBookingPayload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function for bulk booking api call----------------------

export const BulkBookingApi = async (BulkBookingPayload) => {
  const bulkBookingUrl = `${environment.baseUrl}bulkbookcourts`;
  const result = await axios
    .post(bulkBookingUrl, BulkBookingPayload)
    .then((resposne) => {
      return resposne.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//---------------------------Function for cancel booking api------------------------
export const CancelBooking = (bookingId, log) => {
  const CancelBookingUrl = `${environment.baseUrl}cancelbooking`;
  const data = {
    booking_id: bookingId,
    cancelledlog: log,
  };
  console.log("Cancelled Payload", data);
  const result = axios
    .post(CancelBookingUrl, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//---------------------------Function for add promotions api------------------------
export const AddPromotionApi = (data) => {
  const addPromotionUrl = `${environment.baseUrl}promotion`;
  const result = axios
    .post(addPromotionUrl, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//---------------------------Function for add promotions api------------------------
export const GetPromotion = (data) => {
  const getPromotionUrl = `${environment.baseUrl}promotion`;
  const result = axios(getPromotionUrl, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//---------------------------Function for delete promotions api------------------------
export const DeletePromotion = (id) => {
  const deletePromotionUrl = `${environment.baseUrl}delete-promotion/${id}`;
  const result = axios
    .delete(deletePromotionUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//---------------------------Function for get main court for promotions api------------------------
export const GetMainCourtForPromotion = (id) => {
  const GetMainCourtForPromotionUrl = `${environment.baseUrl}main-court-forpromotion`;
  const result = axios(GetMainCourtForPromotionUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//---------------------------Function for add promotions v2 api------------------------
export const AddPromotionV2Api = (data) => {
  const addPromotionV2Url = `${environment.baseUrl}promotion-v2/`;
  const result = axios
    .post(addPromotionV2Url, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//---------------------------Function for add promotions v2 api------------------------
export const GetPromotionV2 = (data) => {
  const getPromotionV2Url = `${environment.baseUrl}promotion-v2/`;
  const result = axios(getPromotionV2Url, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
//---------------------------Function for add User promotions v2 list api------------------------
export const GetUserPromotionV2 = () => {
  const getUserPromotionV2Url = `${environment.baseUrl}promotion-v2-users/`;
  const result = axios.get(getUserPromotionV2Url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
//------------------------Function to get promotion v2 by id-----------
export const GetPromotionByIdV2 = (id) => {
  const GetPromotionByIdUrl = `${environment.baseUrl}promotion-v2/${id}/`;
  const result = axios
    .get(GetPromotionByIdUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to delete promotion v2 by id-----------
export const DeletePromotionV2 = (id) => {
  const deletePromotionUrl = `${environment.baseUrl}promotion-v2/${id}/`;
  const result = axios
    .delete(deletePromotionUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to put promotion by id-----------
export const UpdatePromotionV2ById = (id, data) => {
  const UpdatePromotionV2ByIdUrl = `${environment.baseUrl}promotion-v2/${id}/`;
  const result = axios
    .put(UpdatePromotionV2ByIdUrl, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//--------------------------------Function for get all Addons Data -----------------------

export const GetAddon = () => {
  const GetAddonUrl = `${environment.baseUrl}addon`;
  const result = axios(GetAddonUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to get promotion by id-----------
export const GetPromotionById = (id) => {
  const GetPromotionByIdUrl = `${environment.baseUrl}delete-promotion/${id}`;
  const result = axios
    .get(GetPromotionByIdUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to put promotion by id-----------
export const UpdatePromotionById = (id, data) => {
  const UpdatePromotionByIdUrl = `${environment.baseUrl}delete-promotion/${id}`;
  const result = axios
    .put(UpdatePromotionByIdUrl, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to get admin booking details by id-----------
export const GetAdminBookingAll = () => {
  const GetAdminBookingUrl = `${environment.baseUrl}admin_booking`;
  const result = axios
    .get(GetAdminBookingUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to get admin booking details by id-----------
export const GetAdminBooking = (id) => {
  const GetAdminBookingUrl = `${environment.baseUrl}admin_booking/${id}`;
  const result = axios
    .get(GetAdminBookingUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to get padelholic level-----------
export const GetPadelholicLevel = () => {
  const GetPadelholicLevelUrl = `${environment.baseUrl}getPadelholicsLevel`;
  const result = axios
    .get(GetPadelholicLevelUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to get wallet transaction-----------
export const GetWalletTreansation = () => {
  const GetWalletTreansationUrl = `${environment.baseUrl}wallet-transaction/`;
  const result = axios
    .get(GetWalletTreansationUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to get wallet transaction log by id-----------
export const GetWalletTreansationLogByID = (id) => {
  const GetWalletTreansationByIdUrl = `${environment.baseUrl}wallet-transaction/${id}/`;
  const result = axios
    .get(GetWalletTreansationByIdUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//------------------------Function to get wallet transaction log by id-----------
export const SearchBooking = (data, page, pageSize) => {
  const SearchBookingUrl = `${environment.baseUrl}search-courtbooking?page=${page}&page_size=${pageSize}`;
  const result = axios
    .post(SearchBookingUrl, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
//------------------------Function to update comments--------------------------
export const UpdateComment = (comments) => {
  const updateCommentsUrl = `${environment.baseUrl}update-comment`;
  const result = axios
    .post(updateCommentsUrl, comments)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

//--------------------------Function to get coupon details-----------------------
export const getCoupon = (comments) => {
  const getCouponUrl = `${environment.baseUrl}getcoupan`;
  const result = axios
    .get(getCouponUrl, comments)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const getPublicCoupon = () => {
  const getCouponUrl = `${environment.baseUrl}public_coupan`;
  const result = axios
    .get(getCouponUrl)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

// -------------------Function to get tournaments details---------------------

export const GetTournamentsPaymentDetails = () => {
  const GetTournamentsPaymentURL = `${environment.baseUrl}tournaments-filter`;
  const result = axios
    .get(GetTournamentsPaymentURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

// ----------- NEWS API Code starts here --------------------------
export const NewsPostAPI = (newsData) => {
  const NewsPostAPIURL = `${environment.baseUrl}news-api`;
  const result = axios
    .post(NewsPostAPIURL, newsData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const NewsGetAPI = (newsId) => {
  const NewsEditUrl = `${environment.baseUrl}news-api/${newsId}`;
  const result = axios
    .get(NewsEditUrl)
    .then((NewsResponse) => {
      return NewsResponse.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const UpdateNewsAPI = (newsId, newsData) => {
  const NewsUpdateUrl = `${environment.baseUrl}news-api/${newsId}`;
  const result = axios
    .put(NewsUpdateUrl, newsData)
    .then((NewsResponse) => {
      return NewsResponse.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
}
// ----------- NEWS API Code ends here --------------------------

// ----------- Packages API Code starts here ------------------------

export const PackagesPostAPI = (packageData) => {
  const PackagePostAPIURL = `${environment.baseUrl}packages-api`;
  const result = axios
    .post(PackagePostAPIURL, packageData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const PackagesGetAPI = () => {
  const PackageGetAPIURL = `${environment.baseUrl}packages-api`;
  const result = axios
    .get(PackageGetAPIURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};


export const GetPackageById = (packageId) => {
  const GetPackageByIdURL = `${environment.baseUrl}package/${packageId}`;
  const result = axios
    .get(GetPackageByIdURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const UpdatePackageById = (packageId, packageData) => {
  const GetPackageByIdURL = `${environment.baseUrl}package/${packageId}`;
  const result = axios
    .put(GetPackageByIdURL, packageData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

// ----------- Packages API Code ends here --------------------------

// ----------- ADD's API code starts here ---------------------------

export const AdSpacePostMethod = (adSpaceData) => {
  const AdSpaceURL = `${environment.baseUrl}ads`;
  const result = axios
    .post(AdSpaceURL, adSpaceData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const AdSpacesGetAPI = () => {
  const adSpaceGetAPIURL = `${environment.baseUrl}ads`;
  const result = axios
    .get(adSpaceGetAPIURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const AdSpaceById = (adSpaceId) => {
  const adSpaceByIdURL = `${environment.baseUrl}ads/${adSpaceId}`;
  const result = axios
    .get(adSpaceByIdURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const UpdateAdSpaceAPI = (adSpaceId, adSpaceData) => {
  const adSpaceUpdateURL = `${environment.baseUrl}ads/${adSpaceId}`;
  const result = axios
    .put(adSpaceUpdateURL, adSpaceData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
}

export const DeleteAdSpace = (adSpaceId) => {
  const adSpaceUpdateURL = `${environment.baseUrl}ads/${adSpaceId}`;
  const result = axios
    .delete(adSpaceUpdateURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
}

// ----------- ADD's API code ends here ---------------------------


// ----------- Limited Time Offer API Code starts here --------------------------
export const LTOPostAPI = (ltoData) => {
  const LtoPostAPIURL = `${environment.baseUrl}lto-api`;
  const result = axios
    .post(LtoPostAPIURL, ltoData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const LTOGetAPI = (offerId) => {
  const LtoEditUrl = `${environment.baseUrl}lto-api/${offerId}`;
  const result = axios
    .get(LtoEditUrl)
    .then((LTOResponse) => {
      return LTOResponse.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const LTOUpdateAPI = (offerId, ltoData) => {
  const LtoUpdateUrl = `${environment.baseUrl}lto-api/${offerId}`;
  const result = axios
    .put(LtoUpdateUrl, ltoData)
    .then((LTOResponse) => {
      return LTOResponse.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
}
// ----------- Limited Time Offer Code ends here --------------------------

export const CancelCoacheBooking = (bookingId) => {
  const cancelCoacheURL = `${environment.baseUrl}staffmanagement/cancel-coache-booking/`;
  const cancelCoachePayload = {
    "bookingId":bookingId
  };
  const result = axios
    .post(cancelCoacheURL, cancelCoachePayload)
    .then((cancelCoacheResponse) => {
      return cancelCoacheResponse.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const CancelStaffBooking = (bookingId) => {
  const cancelStaffURL = `${environment.baseUrl}staffmanagement/cancel-staff-booking/`;
  const cancelStaffPayload = {
    "bookingId":bookingId
  };
  const result = axios
    .post(cancelStaffURL, cancelStaffPayload)
    .then((cancelStaffResponse) => {
      return cancelStaffResponse.data;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
