import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CoponUsedList from './components/CoponUsedList';
import Coupons from './components/Coupons';

const ExamplePage = () => (
  <Container className="dashboard">
    
    <Row>
      <Coupons />
    </Row>
    <Row>
      <CoponUsedList />
    </Row>
  </Container>
);

export default ExamplePage;
