import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
// import ReactTableBase from '@/shared/components/table/ReactTableBase';
// import ReactTableCustomizer from '@/shared/components/table/components/ReactTableCustomizer';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';

const ResizableReactTable = ({ reactTableData }) => {
  const [isSortable, setIsSortable] = useState(false);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const newdata = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: 'First',
        accessor: 'first',
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: 'Last',
        accessor: 'last',
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: 'Handle',
        accessor: 'handle',
        disableGlobalFilter: false,
        width: 65,
        }
    ],
    data: [
      {
        'id': 1,
        'first': 'Mark',
        'last': 'Otto',
        'handle': '@mdo'
      },
      {
        'id': 2,
        'first': 'Jacob',
        'last': 'Thornton',
        'handle': '@fat'
      },
      {
        'id': 3,
        'first': 'Larry',
        'last': 'the Bird',
        'handle': '@twitter'
      },
      {
        'id': 4,
        'first': 'Mark',
        'last': 'Otto',
        'handle': '@mdo'
      },
      {
        'id': 5,
        'first': 'Jacob',
        'last': 'Thornton',
        'handle': '@fat'
      },
      {
        'id': 6,
        'first': 'Larry',
        'last': 'the Bird',
        'handle': '@twitter'
      }
    ]
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper">
            <div className="card__title">
              <h5 className="bold-text">resizable react-table</h5>
              <h5 className="subhead">Use table with&nbsp;
                <span className="red-text">resizable</span>
              </h5>
            </div>
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={reactTableData.tableHeaderData}
            data={reactTableData.tableRowsData}
            tableConfig={tableConfig}
          />
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={newdata.columns}
            data={newdata.data}
            tableConfig={tableConfig}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

ResizableReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default ResizableReactTable;
