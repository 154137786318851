import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody, Table } from "reactstrap";
import { environment } from "../env";
import axios from "../../customAxios";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";

const AdsImpressions = () => {
  const [adsData, setAdsData] = useState([]);
  const [adsGroupData, setAdsGroupData] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const GetAdsImpressionsAndClick = () => {
    const AdsImpressionsAndClickURL = `${environment.baseUrl}ads-impressions/`;
    const result = axios.get(AdsImpressionsAndClickURL).then((response) => {
      setAdsData(response.data);
    });
  };

  const GetAdsGroupImpressionsAndClick = () => {
    const AdsImpressionsAndClickURL = `${environment.baseUrl}ads-impressions-count/`;
    const result = axios.get(AdsImpressionsAndClickURL).then((response) => {
        setAdsGroupData(response.data);
    });
  };

  var jsonObj = [];
  for (let i = 0; i < adsData.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["date"] =(adsData[i].date).toString()
    item["screen"] = adsData[i].ads.screen+"-"+adsData[i].ads.position
    // item["position"] = (adsData[i].ads.position).toString()
    item["impressions"] =(adsData[i].impressions).toString()
    item["click"] = (adsData[i].click).toString()
    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Date",
        accessor: "date",
        disableGlobalFilter: false,
        width: 80,
      },
      {
        Header: "Screen",
        accessor: "screen",
        disableGlobalFilter: false,
        width: 80,
      },
    //   {
    //     Header: "Ad's Position",
    //     accessor: "position",
    //     disableGlobalFilter: false,
    //     width: 90,
    //   },
      {
        Header: "Impressions",
        accessor: "impressions",
        disableGlobalFilter: false,
        width: 80,
      },
      {
        Header: "Click",
        accessor: "click",
        disableGlobalFilter: false,
        width: 80,
      },
    ],
    rows: jsonObj,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Screen and Date...",
  };

  useEffect(() => {
    GetAdsImpressionsAndClick();
    GetAdsGroupImpressionsAndClick();
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Ads Click and Impressions</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper hide-table-costomizer">
                <ReactTableCustomizer
                  handleClickIsSortable={handleClickIsSortable}
                  handleClickWithPagination={handleClickWithPagination}
                  handleClickWithSearchEngine={handleClickWithSearchEngine}
                  handleClickIsResizable={handleClickIsResizable}
                  isResizable={isResizable}
                  isSortable={isSortable}
                  withPagination={withPagination}
                  withSearchEngine={withSearchEngine}
                />
              </div>
              <div className="hover-effect table-id-width">
                <ReactTableBase
                  key={withSearchEngine ? "searchable" : "common"}
                  columns={data.columns}
                  data={data.rows}
                  tableConfig={tableConfig}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12} className="mb-4">
          <Card>
            <CardBody className="form">
              <div className="card__title">
                <h5 className="bold-text">Ads Click and Impressions</h5>
              </div>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Screen</th>
                    <th>Impressions</th>
                    <th>Click</th>
                  </tr>
                </thead>
                <tbody>
                  {adsGroupData.map((data, index) => {
                    return (
                      <tr>
                        <td key={index}>{index + 1}</td>
                        <td key={index}>{data.ads__screen}{"-"}{data.ads__position}</td>
                        <td key={index}>{data.impressions}</td>
                        <td key={index}>{data.click}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdsImpressions;