import React, {useState,useEffect} from "react";
import { Card, CardBody, Col } from "reactstrap";
// import axios from "axios";
import axios from '../../../customAxios';
import { Link } from 'react-router-dom';
import { environment } from "../../env";
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import ReactPaginate from "react-paginate";

const ExampleCard = () => {
  const [notifyMeList, setnotifyMeList] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [pageCount, setpageCount] = useState(0);
  

  const notifyMeURL = `${environment.baseUrl}notify_me`;
  const getNotifyMe = () => {
    const result = axios.get(notifyMeURL)
    .then((response)=>{
      console.log(response.data);
      setnotifyMeList(response.data);
    })
    .catch((error)=>{
      console.log(error);
    })
  };
  useEffect(() => {
    getNotifyMe();    
  }, []);

  var jsonObj = [];
  console.log("NEw JSON ",jsonObj)
  for(let i=0; i<notifyMeList.length; i++){
    var item = {}
    item["id"] = i+1;
    item["date"] = <div className="Booking-details-td-right-space">{notifyMeList[i]?.date}</div>;
    item["timeslot"] = <div className="Booking-details-td-right-space">{notifyMeList[i]?.timeslot}</div>;
    item["user"] = <div className="Booking-details-td-right-space user-email">{notifyMeList[i]?.user?.email}</div>;
    item["name"] =<div className="Booking-details-td-right-space">{notifyMeList[i]?.user?.first_name === "null" ? '' : notifyMeList[i]?.user?.first_name}</div>;
    item["phone_no"] =  <div className="Booking-details-td-right-space user-phone">{notifyMeList[i]?.user?.phone_no === "null" ? '' : notifyMeList[i]?.user?.phone_no}</div>;
    item["main_court"] = <div className="Booking-details-td-right-space">{notifyMeList[i]?.main_court?.courtName}</div>;
     
    jsonObj.push(item);
}

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableGlobalFilter: true,
        width: 100
      },
      {
        Header: 'Timeslot',
        accessor: 'timeslot',
        disableGlobalFilter: true,
        width: 150
      },
      {
        Header: 'Email',
        accessor: "user",
        disableGlobalFilter: true,
        width: 220,
      },
      {
        Header: 'Name',
        accessor: "name",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: 'Phone No',
        accessor: "phone_no",
        disableGlobalFilter: true,
        width: 120,
      },
      {
        Header: 'Main Court',
        accessor: "main_court",
        disableGlobalFilter: true,
        width: 120,
      },
    ],
    rows: jsonObj
  };
  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };
  
  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>
          <div className="table-id-width hover-effect search-input-update">
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
          />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ExampleCard;
