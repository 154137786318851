import React, { useEffect, useState } from "react";
import { Col, CardBody, Spinner, Row, Card } from "reactstrap";
import DatePicker from "react-date-picker";
import { useForm, Controller } from "react-hook-form";
import { environment } from "../env";
import axios from "../../customAxios";
import moment from "moment";
import { GetMainCourt, GetPadelCourt } from "../Reuse/ApiCalls";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import { GetAvailableSlotsModal } from "../../shared/components/GetAvailableSlotsModal";

export const GetAvailableSlotsCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mainCourtList, setMainCourtList] = useState([]);
  const [padelCourtList, setPadelCourtList] = useState([]);
  const [getAvailableSlotsList, setAvailableSlotsList] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    GetMainCourt().then((response) => {
      setMainCourtList(response);
    });
  }, []);

  const GetAvailableSlotsFun = async (data,date) => {
    setIsLoading(true);
    const GetAvailableSlotsPayload = {
      date: date,
      maincourtId : data.maincourt,
      padelcourtId : data.padelcourt
    };

    const GetAvailableSlotsUrl = `${environment.baseUrl}get-available-slots`;
    await axios
      .post(GetAvailableSlotsUrl, GetAvailableSlotsPayload)
      .then((res) => {
        setAvailableSlotsList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = async (data) => {
    const date = moment(data.date).format("YYYY-MM-DD");
    setSelectedDate(date);
    GetAvailableSlotsFun(data,date);
  };



  const filteredMainCourt = mainCourtList.filter((data) => data.id !== 12);

  const handleMainCourt = (value) => {
    GetPadelCourt().then((response) => {
      const padelCourt = [];
      let padelCourts = response;
      let padelCourtFilter = padelCourts.filter(function (padelCourts) {
        return padelCourts.maincourt.id === parseInt(value);
      });
      padelCourtFilter.map((data) => {
        padelCourt.push({ id: data.id, courtname: data.courtname });
      });
      setPadelCourtList(padelCourt);
    });
  };

  const handlePadelCourt = (value) => {
    console.log(value)
  };

  const maincourtRegister = register("maincourt", { required: true });
  console.log('getAvailableSlotsList===>',getAvailableSlotsList)

  var jsonObj = [];
  for (let i = 0; i < getAvailableSlotsList.length; i++) {
    var item = {};
    let maincourtid = getAvailableSlotsList[i].maincourt_id;
    const maincourtname = mainCourtList.find((item) => item.id === maincourtid);
    const availability = getAvailableSlotsList[i].available ? "True" : "False";
    item["id"] = i + 1;
    item["date"] = (
      <div className="Booking-details-td-right-space">
        {getAvailableSlotsList[i]?.date}
      </div>
    );
    item["timeslot"] = (
      <div className="Booking-details-td-right-space">
        {getAvailableSlotsList[i]?.time}
      </div>
    );
    item["main_court"] = (
      <div className="Booking-details-td-right-space">
        {maincourtname.courtName}
      </div>
    );
    item["padel_court"] = (
      <div className="Booking-details-td-right-space">
        Court - {getAvailableSlotsList[i]?.padel_courtname}
      </div>
    );
    item["available"] = (
      <div className="Booking-details-td-right-space">{availability}</div>
    );

    item["actions"] = (
      <div className="right-space">
        <div
        >
         {/* <EditOutlineIcon style={{ fontSize: 20 }} />{" "} */}
          <GetAvailableSlotsModal
            bnt=""
            color="primary"
            header
            title="Update Available Slots"
            maincourtId={getAvailableSlotsList[i].maincourt_id}
            padelcourtId={getAvailableSlotsList[i].padel_court_id}
            time={getAvailableSlotsList[i].time}
            date={getAvailableSlotsList[i]?.date}
            available={getAvailableSlotsList[i].available}
            maincourtName={maincourtname.courtName}
            padelCourtName={getAvailableSlotsList[i]?.padel_courtname}
            GetAvailableSlotsFun={GetAvailableSlotsFun}
          />
        </div>
      </div>
    );
    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: "Date",
        accessor: "date",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Timeslot",
        accessor: "timeslot",
        disableGlobalFilter: true,
        width: 150,
      },

      {
        Header: "Main Court",
        accessor: "main_court",
        disableGlobalFilter: true,
        width: 170,
      },
      {
        Header: "Padel Court",
        accessor: "padel_court",
        disableGlobalFilter: true,
        width: 150,
      },
      {
        Header: "Availability",
        accessor: "available",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableGlobalFilter: true,
        width: 100,
      },
    ],
    rows: jsonObj,
  };
  const [withPagination, setWithPaginationTable] = useState(true);

  const [isResizable, setIsResizable] = useState(true);

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  const tableConfig = {
    isEditable: false,
    isResizable,

    withPagination,

    manualPageSize: [10, 20, 30, 40],
  };
  return (
    <CardBody>
      {isLoading && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={3} lg={3}>
            <select
              name="maincourt"
              className="textareaFeild"
              {...maincourtRegister}
              onChange={(e) => {
                maincourtRegister.onChange(e);
                handleMainCourt(e.target.value);
              }}
            >
              <option value="">Select Main Court</option>
              {filteredMainCourt?.map((data, index) => {
                return (
                  <option key={index} value={data.id}>
                    {data.courtName}
                  </option>
                );
              })}
            </select>
          </Col>

          <Col md={3} lg={3}>
            <select
              name="padelcourt"
              className="textareaFeild"
              {...register("padelcourt", { required: true })}
              onChange={(e) => {
                handlePadelCourt(e.target.value);
              }}
            >
              <option value="">Select Padel Court</option>
              {padelCourtList?.map((data, index) => {
                return (
                  <option key={index} value={data.id}>
                    Court - {data.courtname}
                  </option>
                );
              })}
            </select>
          </Col>

          <Col md={3} lg={3}>
            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <DatePicker
                  dateFormat="YYYY-MM-DD"
                  onChange={onChange}
                  value={value}
                  className="custom-css-dp"
                />
              )}
            />
            {errors.date && (
              <span className="invalid-feedback d-flex">Select date!</span>
            )}
          </Col>

          <Col md={3} lg={3}>
            <div className="cal-top-submit-btn">
              <button
                color="warning"
                size="sm"
                style={{
                  height: 35,
                  backgroundColor: "#FDAE00",
                  paddingRight: 20,
                  paddingLeft: 20,
                  display: "flex",
                  marginLeft: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  borderRadius: 4,
                  fontSize: 15,
                  fontFamily: "sans-serif",
                  border: 0,
                }}
              >
                <p style={{ color: "#fff" }}>Submit</p>
              </button>
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: "50px" }}>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="react-table__wrapper hide-table-costomizer">
                  <ReactTableCustomizer
                    handleClickWithPagination={handleClickWithPagination}
                    handleClickIsResizable={handleClickIsResizable}
                    isResizable={isResizable}
                    withPagination={withPagination}
                  />
                </div>
                <div className="table-id-width hover-effect search-input-update">
                  <ReactTableBase
                    columns={data.columns}
                    data={data.rows}
                    tableConfig={tableConfig}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </form>
    </CardBody>
  );
};
