import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AddCoupon from './components/AddCoupon';

const Coupon = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Coupon</h3>
      </Col>
    </Row>
    <Row>
      <AddCoupon />
    </Row>
  </Container>
);

export default Coupon;
