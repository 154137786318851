import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import GenerateSlotCard from './GenerateSlotCard';
const GenerateSlot = () => {
  return (
    <Container>
    <Row md={12}>
        <Col>
            <h3 className='page-title'>Slot Management</h3>
        </Col>
    </Row>
    <Row>
        <Col>
        <GenerateSlotCard/>
        </Col>
    </Row>
</Container>
  )
}

export default GenerateSlot