import React,{ useEffect, useState } from 'react'
import { GetAdminBooking } from '../../Reuse/ApiCalls';

const UserDetails = ({bookedCourt, booking_category}) => {
    const [details, setDetails] = useState();
    useEffect(() => {
        GetAdminBooking(bookedCourt).then((response)=>{
            
            if(response.msg === "Data is not available"){
                setDetails({
                    customer_name:"Just Padel",
                    customer_phone:"+971589046968"
                })
            }else{
                setDetails(response);
            }
        })
    }, [])
    return (
        <>
            {details?.customer_name} {" - "} {details?.customer_phone}
        </>
    )
};
export default UserDetails;
