import React, { useState, useEffect } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane, Table,
} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import { BookingDetailsByBookingID, BookingDetailsAvailableTrueByBookingID, BookingDetailsAvailableFalseByBookingID } from '../../../Reuse/ApiCalls'
import './Tab.css';
import './BookingDetailsTab.css';
import "react-datepicker/dist/react-datepicker.css";
import BookingDetailsTabTable from './BookingDetailsTabTable';
import BookingDetailsUserTabTable from './BookingDetailsUserTabTable';
import BookingDetailsAdminEditForm from './BookingDetailsAdminEditForm';
import BookingDetailsUserEditForm from './BookingDetailsUserEditForm';


const BookingDetailsTab = ({ transactionId, toggleModal }) => {

  const [bookingDetailsSingle, setBookingDetailsSingle] = useState();
  // const [transactionId, setBookingData] = useState();
  const [activeTab, setActiveTab] = useState('1');
  const [timeToPlay, setTimeToPlay] = useState();
  const [hoursToPlay, setHoursToPlay] = useState();
  const [courtBookinDate, setCourtBookinDate] = useState();
  const [bookedSlots, setBookedSlots] = useState([]);



  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };



  const StartandEndTime = (data) => {
    setCourtBookinDate(data?.booking_date)
    const startTimes = data?.booked_slots[0]?.time?.split('-')[0];
    const endsTimes = data?.booked_slots?.slice(-1)[0]?.time?.split('-')[1];
    setTimeToPlay(startTimes + "-" + endsTimes);

    // -----------------------functionality to get hours to play code starts here-------
    var startTime = moment(startTimes, "HH:mm a");
    var endTime = moment(endsTimes, "HH:mm a");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) - hours * 60;
    setHoursToPlay(hours + ' hr . ' + minutes + ' mins.')
    // -----------------------functionality to get hours to play code ends here-------
  }

  const getBookingDetails = (id) => {
    BookingDetailsAvailableFalseByBookingID(id).then((response) => {
      if (response?.data?.Message === "No data with this id!!!") {
        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data.Message === "No data with this id!!!") {
            // setLogs();
          } else {
            StartandEndTime(response?.data);
            setBookingDetailsSingle(response?.data);
          }
        })
      } else {
        StartandEndTime(response.data);
        setBookingDetailsSingle(response?.data);
      }
    })

    BookingDetailsByBookingID(id).then((response) => {
      // console.log("Response", response.data);
      // setBookingDetailsSingle(response?.data);      
      setBookedSlots(response.data.booked_slots);
    })
  };

  useEffect(() => {
    getBookingDetails(transactionId);
  }, []);

  const formData = {
    client: bookingDetailsSingle?.user?.first_name,
    location: bookingDetailsSingle?.main_court?.id,
    service_provider: bookingDetailsSingle?.padel_court?.id,
  }

  return (
    <div className="tabs__wrap">
      <Nav tabs className="all-tabs" style={{ width: 200 }}>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggle('1')}
          >
            Edit Bookings
          </NavLink>
        </NavItem>


      </Nav>
      <TabContent activeTab={activeTab}>

        <TabPane tabId="1" style={{ display: 'none' }}>
          <div className="user-datails" style={{ display: 'none' }}>
            <div className="user-datails-lhs">
              {bookingDetailsSingle?.user?.role === "customer" ?
                <BookingDetailsUserTabTable customer_name={bookingDetailsSingle?.user?.first_name} customer_email={bookingDetailsSingle?.user?.email} customer_phone={bookingDetailsSingle?.user?.phone_no} />
                : <BookingDetailsTabTable bookingID={transactionId} />}
            </div>
          </div>
        </TabPane>

        <TabPane tabId="1">
          <Table responsive hover>
            <div className="Booking-details-edit-container">
              {bookingDetailsSingle?.user?.id && bookingDetailsSingle?.user?.role === "customer" ?
                <BookingDetailsUserEditForm
                  booking_id={transactionId}
                  userId={bookingDetailsSingle?.user?.id}
                  client={bookingDetailsSingle?.user?.first_name}
                  location={bookingDetailsSingle?.main_court?.id}
                  service_provider={bookingDetailsSingle?.padel_court?.id}
                  booking_date={bookingDetailsSingle?.booking_date}
                  customer_email={bookingDetailsSingle?.user?.email}
                  customer_phone={bookingDetailsSingle?.user?.phone_no}
                  cancellationTime={timeToPlay}
                  amountpaid={bookingDetailsSingle?.amount_paid}
                  toggleModal={toggleModal}
                /> :
                <BookingDetailsAdminEditForm
                  bookingID={transactionId}
                  formDatas={formData}
                  location={bookingDetailsSingle?.main_court?.id}
                  service_provider={bookingDetailsSingle?.padel_court?.id}
                  booking_date={bookingDetailsSingle?.booking_date}
                  cancellationTime={timeToPlay}
                  toggleModal={toggleModal}
                />}
            </div>
          </Table>
        </TabPane>

      </TabContent>
    </div>
  );
};

export default BookingDetailsTab;