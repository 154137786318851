import React, { useState } from 'react'
import { Card, CardBody, Col, Container, Row, Spinner, ButtonToolbar } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import DatePicker, { Calendar } from "react-multi-date-picker";
import '../GetSlotsBookingReport/DateColor.css'
import { classNames } from 'classnames';
import axios from '../../customAxios';
import { environment } from '../env';
import swal from "sweetalert";
import S3FileUpload from "react-s3";
import ImageUploaderCaption from './ImageUploaderCaption';
import Select from "react-select";
export const AddEventTournament = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const eventMerchandiseName = watch("event_merchandise_name");
    const eventTypeList = [
        {
            "label": "Tournament",
            "value": "Tournament"
        },
        {
            "label": "Mini-Tournament",
            "value": "Mini-Tournament"
        },
        {
            "label": "League",
            "value": "League"
        },
        {
            "label": "Mix and Match",
            "value": "Mix and Match"
        },
        {
            "label": "Socials",
            "value": "Socials"
        }
    ]

    const [resources, setResources] = useState([]);
    const setImage = (imagesList) => {
        setResources(imagesList);
    };

    const config = {
        bucketName: "justpadel-courtimages",
        region: "us-east-1",
        accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
        secretAccessKey: "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",
    };

    const onSubmit = async (data) => {
        setIsLoading(true)
        let selectedDate = [];
        data.event_date.map((day) => {
            selectedDate.push(`${day.year}-${day.month.number}-${day.day}`);
        });
        data.event_date = String(selectedDate);
        let eventImg;
        await S3FileUpload.uploadFile(data.event_image[0], config)
            .then((data) => {
                eventImg = data.location;
            })
            .catch((err) => {
                console.log("Error Info: ", err);
            });
        data.event_image = eventImg;
        if (data?.event_merchandise_image) {
            let eventMerchandiseImg;

            await S3FileUpload.uploadFile(data.event_merchandise_image[0], config)
                .then((data) => {
                    eventMerchandiseImg = data.location;
                })
                .catch((err) => {
                    console.log("Error Info: ", err);
                });
            data.event_merchandise_image = eventMerchandiseImg;
        }
        data.event_registration_start_date = `${data.event_registration_start_date}`
        data.event_registration_end_date = `${data.event_registration_end_date}`
        data.event_type = data.event_type.value
        if (data?.event_merchandise_name) {
            data.event_merchandise = {
                event_merchandise_name: data.event_merchandise_name,
                event_merchandise_price: data.event_merchandise_price,
                event_merchandise_image: data.event_merchandise_image
            }
        } else {
            data.event_merchandise = []
        }
        const UploadedImages = [];
        if (resources.length > 0) {
            resources.map(async (img) => {
                if ("partner_image" in img) {
                    UploadedImages.push({ partner_image: img.partner_image, caption: img.caption });
                    if (resources?.length === UploadedImages?.length) {
                        submitEventData(data, UploadedImages);
                    }
                } else {
                    await S3FileUpload.uploadFile(img.file, config)
                        .then((datares) => {
                            UploadedImages.push({ partner_image: datares.location, caption: img.caption });
                            if (resources?.length === UploadedImages?.length) {
                                submitEventData(data, UploadedImages);
                            }
                        })
                        .catch((err) => {
                            console.log("🚀 ~ file: AddEventTournament.jsx:130 ~ resources.map ~ err:", err)
                            setIsLoading(false);
                        });
                }
            })
        } else {
            submitEventData(data, UploadedImages)
        }
    }

    const submitEventData = async (data, UploadedImages) => {
        data.event_partners = UploadedImages
        // data.event_partners = JSON.stringify(UploadedImages);
        const eventRegistrationUrl = `${environment.baseUrl}create-event-registration/`;
        await axios.post(eventRegistrationUrl, data).then((res) => {
            if (res.data.status === "success") {
                swal({
                    text: "Event Created Successfully",
                    icon: "success",
                    buttons: true,
                }).then(() => {
                    window.location = "/event-registration-tabs";
                });

                setIsLoading(false)
            } else {
                swal({
                    text: "Error.. While Creating Events",
                    icon: "error",
                    buttons: true,
                }).then(() => {
                    console.log("");
                });
                setIsLoading(false);
            }
        }).catch((err) => {
            swal({
                text: "Error.. While Creating Events",
                icon: "error",
                buttons: true,
            }).then(() => {
                console.log(err);
            });
            setIsLoading(false);
        })

    }

    const handleCancle = () => {
        window.location = '/event-tournaments-registration'
    }

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Add Events</h3>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12}>
                    {isLoading && (
                        <div class="loader-div">
                            <span class="loader">
                                <div className="center-spinner">
                                    <Spinner className="table-fetch-spinner" />
                                </div>
                            </span>
                        </div>
                    )}
                </Col>

                <Card>
                    <CardBody>
                        <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Event Name</span>
                                    <div className="form__form-group-field">
                                        <input
                                            type="text"
                                            placeholder="Event Name"
                                            {...register("event_name", { required: true })}
                                        />
                                    </div>
                                    {errors.event_name && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please Enter The Event Name, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Price</span>
                                    <div className="form__form-group-field">
                                        <input
                                            type="number"
                                            min={0}
                                            placeholder="Event Price Per Player"
                                            {...register("event_price", { required: true })}
                                        />
                                    </div>
                                    {errors.event_price && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please Enter The Event Price, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Location URL</span>
                                    <div className="form__form-group-field">
                                        <input
                                            type="text"
                                            className="form__form-group-field"
                                            {...register("event_location_url", { required: true })}
                                        />
                                    </div>
                                    {errors.event_location_url && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please add location URL, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Event Type</span>
                                    <div className="form__form-group-field">
                                        <Controller
                                            control={control}
                                            name="event_type"
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { invalid, isTouched, isDirty, error },
                                                formState,
                                            }) => (
                                                <Select
                                                    options={eventTypeList}
                                                    className="text-uppercase w-100"
                                                    placeholder="Select Event Type"
                                                    onChange={onChange}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errors.event_type && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please Enter Event-Type, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Event Merchandise Name</span>
                                    <div className="form__form-group-field">
                                        <input
                                            type="text"
                                            placeholder="Event Merchandise Name"
                                            {...register("event_merchandise_name", { required: false })}
                                        />
                                    </div>
                                    {errors.event_merchandise_name && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please Enter The Event Merchandise Name, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Event Merchandise Price</span>
                                    <div className="form__form-group-field">
                                        <input
                                            type="number"
                                            placeholder="Event Merchandise Price"
                                            min={0}
                                            {...register("event_merchandise_price", { required: eventMerchandiseName?.length > 0 ? true : false, min: 0 })}
                                        />
                                    </div>
                                    {errors.event_merchandise_price && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please Enter The Event Merchandise Price, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Upload Event Merchandise Image</span>
                                    <div className="form__form-group-field">
                                        <input
                                            type="file"
                                            className="form__form-group-field"
                                            {...register("event_merchandise_image", { required: false })}
                                        />
                                    </div>
                                    {errors.event_merchandise_image && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please Select Event Merchandise Image, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">About</span>
                                    <div className="form__form-group-field">
                                        <textarea
                                            placeholder="About"
                                            {...register("event_about", { required: true })}
                                        />
                                    </div>
                                    {errors.event_about && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please Enter About Event Content, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Event Location</span>
                                    <div className="form__form-group-field">
                                        <input
                                            type="text"
                                            placeholder="Event Location"
                                            {...register("event_location", { required: true })}
                                        />
                                    </div>
                                    {errors.event_location && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please Enter The Event Location, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group d-flex flex-column">
                                    <span className="form__form-group-label">Event Date</span>
                                    <div className="form__form-group-field">
                                        <Controller
                                            control={control}
                                            name="event_date"
                                            rules={{ required: true }} //optional
                                            render={({
                                                field: { onChange, name, value },
                                                fieldState: { invalid, isDirty },
                                                formState: { errors },
                                            }) => (
                                                <>
                                                    <DatePicker
                                                        value={value}
                                                        highlightToday
                                                        onChange={(date) => {
                                                            onChange(date);
                                                        }}
                                                        range={true}
                                                        placeholder="Select date"
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    {errors.event_date && (
                                        <ErrorMessageComponent
                                            message={
                                                "Date Is Required"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Event Registration Start Date</span>
                                    <div className="form__form-group-field">
                                        <input type="date" name="event_registration_start_date" id="" {...register("event_registration_start_date", { required: true })} />
                                    </div>
                                    {errors.event_registration_start_date && (
                                        <ErrorMessageComponent
                                            message={
                                                "Registration Start Date Is Required"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Event Registration End Date</span>
                                    <div className="form__form-group-field">
                                        <input type="date" name="event_registration_end_date" id="" {...register("event_registration_end_date", { required: true })} />
                                    </div>
                                    {errors.event_registration_end_date && (
                                        <ErrorMessageComponent
                                            message={
                                                "Registration End Date Is Required"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Upload Event Image</span>
                                    <div className="form__form-group-field">
                                        <input
                                            type="file"
                                            className="form__form-group-field"
                                            {...register("event_image", { required: true })}
                                        />
                                    </div>
                                    {errors.event_image && (
                                        <ErrorMessageComponent
                                            message={
                                                "Please Select Event Image, It Should Not be Empty"
                                            }
                                        />
                                    )}
                                </div>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">Event Partners Images</span>
                                    <div className="form__form-group-field">
                                        <Controller
                                            control={control}
                                            name="event_partners"
                                            rules={{ required: false }}
                                            render={({
                                                field: { onChange, onBlur, value, name },
                                                fieldState: { invalid, isTouched, isDirty, error },
                                                formState,
                                            }) => (
                                                <ImageUploaderCaption
                                                    setImage={setImage}
                                                    resources={resources}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <ButtonToolbar className="form__button-toolbar">
                                    <button
                                        className="justpadel-buttons-save"
                                        color="primary"
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                    <div className="justpadel-buttons-cancel" onClick={() => handleCancle()}>Cancel</div>
                                </ButtonToolbar>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    )
}

const ErrorMessageComponent = ({ message }) => {
    return (
        <div color="danger" className="form__form-group-error">
            <p style={{ color: "#dc3545" }}>
                <span className="bold-text">Warning! </span>
                {message}
            </p>
        </div>
    );
};