import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../../customAxios";
import { environment } from "../../env";
import { ErrorMessage } from "@hookform/error-message";
import JoditEditor from "jodit-react";

import { useForm } from "react-hook-form";
import swal from "sweetalert";
import S3FileUpload from 'react-s3';

const config = {
  // bucketName: 'test-padel',
  bucketName: 'justpadel-courtimages',
  region: 'us-east-1',
  accessKeyId: 'AKIAZZLUGKQZZJT3G4FX',
  secretAccessKey: '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',
};

const EditOffer = () => {
  const { register, handleSubmit, reset, formState: { errors }, } = useForm({ mode: "onBlur" });
  const [description, setDescription] = useState();
  const editor = useRef(null);
  const [offerImageUrl, setOfferImageUrl] = useState();
  const offerImageUrls = register("image", { required: true });
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [TCData, setTCData] = useState();

  const history = useHistory();
  let { id } = useParams();
    const EditDetails = () => {

    const editOfferURL = `${environment.baseUrl}getofferById/${id}/`;
    const result = axios
      .get(editOfferURL)
      .then((response) => {
        console.log("Response", response);
        setTCData(response.data.tandc);
        setDescription(response.data.description);
        reset({offer:response.data.offer,tctxt:response.data.tctxt, date:response.data.date});
      })
      .catch((error) => {
        console.log("Error" + error);
      });
  }

  useEffect(() => {
    EditDetails();
  }, []);

  const resetBack = () => {
    history.push('/offer-list');
  };

  function handleChangeImageUpload(e) {
    setButtonDisabled(true);
    S3FileUpload.uploadFile(e.target.files[0], config)
        .then((data) => {
            setOfferImageUrl(data.location);
        })
        .catch((err) => {
            console.log('Error Info: ', err);
        });
        setButtonDisabled(false);
};


  const onSubmit = (data) => {
    data.image = offerImageUrl;
    data.description = description;
    data.tandc = TCData;
    console.log("values", data);
    const editOfferURL = `${environment.baseUrl}updateofferById/${id}/`;
    axios.put(editOfferURL, data).then((response) => {
        if (response.status === 200) {
            console.log("response",response); 
            if(response.data.Message === 'Offer Updated Successfully'){
                swal({
                    title: "Success",
                    text: "Offer is Added Successfully!",
                    icon: "success",
                    button: "Ok",
                  })
                  .then((value) => {
                    history.push("/offer-list");
                  })
            }            
        }
      });
}

// useEffect(() => {
//   setTCData(TCData);
//         setDescription(description);
// }, [TCData,description]);

const Back = () => {
    history.push("/offer-list");
    reset();
}
 


  return (
    // <Col md={12}>
    //   <Card>
    //     <CardBody>
    //       <div className="card__title">
    //         <h5 style={{ color: "#acacac" }} className="bold-text">
    //           Edit Offer&apos;s Here
    //         </h5>
    //       </div>
    //       <form className="form product-edit" onSubmit={handleSubmit(handleS)}>
    //         <div className="form__half">
    //           <div className="form__form-group">
    //             <span className="form__form-group-label">offer</span>
    //             <div className="form__form-group-field">
    //               <input
    //                 type="text"
    //                 value={offerData.offer}
    //                 {...register("offer", {
    //                   required: "offer is required",
    //                 })}
    //                 className={`form-control ${errors.offer ? "is-invalid" : ""
    //                   }`}
                    
    //                 onChange={(e)=>setOfferData({...offerData, offer: e.target.value })}
    //               />
    //             </div>
    //             <p style={{ color: "tomato" }}>
    //               <ErrorMessage errors={errors} name="offer" />
    //             </p>
    //           </div>
    //         </div>
    //         <div className="form__half">
    //           <div className="form__form-group">
    //             <span
    //               className="form__form-group-label"
    //               style={{ fontSize: 14 }}
    //             >
    //               Enter Offer Description
    //             </span>
    //             <div className="form__form-group-field">
    //               {/* <input
    //                 type="text"
    //                 value={offerData.description}
    //                 style={{ borderRadius: 5 }}
    //                 className={`form-control, ${
    //                   errors.description ? "is-invalid" : ""
    //                 }`}
    //                 {...register("description", {
    //                   required: "please enter offer description",
    //                 })}
    //                 onChange={handleChange}
    //               /> */}

    //               <JoditEditor
    //                 ref={editor}
    //                 value={offerData.description}
    //                 // config={config}
    //                 tabIndex={1} // tabIndex of textarea
    //                 // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
    //                 onChange={newContent => setOfferData({ ...offerData, description: newContent })}
    //               />
    //             </div>
    //             <small style={{ color: "tomato" }}>
    //               <ErrorMessage errors={errors} name="description" />
    //             </small>
    //           </div>
    //         </div>
    //         <ButtonToolbar className="form__button-toolbar float_right">
    //           <button className='justpadel-buttons-save' type="submit">
    //             Update
    //           </button>
    //           <div className='justpadel-buttons-cancel' onClick={() => resetBack()}>
    //             Cancel
    //           </div>
    //         </ButtonToolbar>
    //       </form>
    //     </CardBody>
    //   </Card>
    // </Col>

    <Col md={12} lg={12}>
    <Card>
        <CardBody>
            <div className="card__title">
                <h5 className="bold-text" style={{ color: "#acacac" }}>
                    Insert Offer Data Here
                </h5>
            </div>
            <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
                <div className="form__half">
                    <div className="form__form-group">
                        <span className="form__form-group-label" style={{ fontSize: 14 }} >Title</span>
                        <div className="form__form-group-field">
                            <input type="text" name="offer" {...register("offer", { required: true })} />
                        </div>
                        <span>{errors.offer && <p>Please add the title of the Offer</p>}</span>
                    </div>
                    <div className="form__form-group">
                        <span className="form__form-group-label" style={{ fontSize: 14 }} >Description</span>
                        <div className="form__form-group-field">
                            <JoditEditor
                                ref={editor}
                                tabIndex={1}
                                onChange={newContent => setDescription(newContent)}
                                value={description}                                      
                            />
                        </div>
                    </div>
                </div>
                <div className="form__half">
                    <div className="form__form-group">
                        <span className="form__form-group-label" style={{ fontSize: 14 }} >Date</span>
                        <div className="form__form-group-field">
                            <input type="date" name="date" {...register("date", { required: true })} />
                        </div>
                    </div>
                    <div className="form__form-group">
                        <span className="form__form-group-label" style={{ fontSize: 14 }} >Image</span>
                        <div className="form__form-group-field">
                            <input type="file" {...offerImageUrls} onChange={(e) => {
                                handleChangeImageUpload(e);
                                offerImageUrls.onChange(e);
                            }} />
                        </div>
                        <span>{errors.offerImageUrl && <p>Please Select Images</p>}</span>
                    </div>
                </div>
                <div className="form__half">
                    <div className="form__form-group">
                        <span className="form__form-group-label" style={{ fontSize: 14 }} >Terms and Condition Text</span>
                        <div className="form__form-group-field">
                            <input type="text" name="tctxt" {...register("tctxt", { required: true })} />
                        </div>
                    </div>
                    <div className="form__form-group">
                        <span className="form__form-group-label" style={{ fontSize: 14 }} >Terms and Condition Data</span>
                        <div className="form__form-group-field">
                            {/* <input type="text" name="tandc" {...register("tandc", { required: true })} /> */}
                            <div className="form__form-group-field">
                            <JoditEditor
                                ref={editor}
                                tabIndex={1}
                                onChange={newContent => setTCData(newContent)}
                                value={TCData}                                   
                            />
                        </div>
                        </div>
                    </div>
                </div>
                <ButtonToolbar className="form__button-toolbar" style={{ marginTop: 25 }} >
                    <button className='justpadel-buttons-save' type="submit" disabled={buttonDisabled}>Save</button>
                    <div className='justpadel-buttons-cancel' onClick={() => Back()}> Cancel</div>
                </ButtonToolbar>
            </form>
        </CardBody>
    </Card>
</Col>

  );
};
export default EditOffer;
