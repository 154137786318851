import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody, Table } from "reactstrap";
import { environment } from "../env";
import axios from "../../customAxios";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import { AiFillEye } from "react-icons/ai";
import PackagesModal from "../../shared/components/PackagesModal";


const UserPackages = () => {
  const [userPackagesData, setUserPackagesData] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const GetUserPackagesData = () => {
    const UserPackagesURL = `${environment.baseUrl}user-package`;
    const result = axios.get(UserPackagesURL).then((response) => {
      setUserPackagesData(response.data);
    });
  };

  const ViewDetails = (userId) => {
    alert(userId);
  }

  var jsonObj = [];
  for (let i = 0; i < userPackagesData.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["name"] =  `${userPackagesData[i].user.first_name.toString()+ " " +userPackagesData[i].user.last_name.toString()}`;
    item["phone"] = userPackagesData[i].user.phone_no.toString();
    item["email"] = userPackagesData[i].user.email.toString();
    item["available_slots"] = userPackagesData[i].available_slots.toString();
    // item["view"] =  <div className="right-space"><div onClick={() => ViewDetails(userPackagesData[i].user.id)} className="modal-edit-btn"><AiFillEye style={{fontSize:20}}/> </div></div>;
    item["view"] = <PackagesModal title="User Package Details" header color="primary"  userId={userPackagesData[i].user.id}/>
    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Name",
        accessor: "name",
        disableGlobalFilter: false,
        width: 80,
      },
      {
        Header: "Phone No",
        accessor: "phone",
        disableGlobalFilter: false,
        width: 80,
      },
      {
        Header: "Email Id",
        accessor: "email",
        disableGlobalFilter: false,
        width: 80,
      },
      {
        Header: "Slots",
        accessor: "available_slots",
        disableGlobalFilter: false,
        width: 80,
      },
      {
        Header: 'View',
        accessor: 'view',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      }
    ],
    rows: jsonObj,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Name and Slots...",
  };

  useEffect(() => {
    GetUserPackagesData();
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Users Packages</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper hide-table-costomizer">
                <ReactTableCustomizer
                  handleClickIsSortable={handleClickIsSortable}
                  handleClickWithPagination={handleClickWithPagination}
                  handleClickWithSearchEngine={handleClickWithSearchEngine}
                  handleClickIsResizable={handleClickIsResizable}
                  isResizable={isResizable}
                  isSortable={isSortable}
                  withPagination={withPagination}
                  withSearchEngine={withSearchEngine}
                />
              </div>
              <div className="hover-effect table-id-width">
                <ReactTableBase
                  key={withSearchEngine ? "searchable" : "common"}
                  columns={data.columns}
                  data={data.rows}
                  tableConfig={tableConfig}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPackages;
