/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { useState, useEffect } from "react";
import axios from "../../../customAxios";
import { useHistory } from "react-router-dom";
import { Col, Button, ButtonToolbar, Card, CardBody } from "reactstrap";
import { environment } from "../../env";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const options = [{ value: 10, label: "All Courts" }];

const AddonsEditCard = () => {
  const history = useHistory();
  const [mainCourtList, setMainCourtList] = useState([]);
  const { register, handleSubmit, reset, control, formState } = useForm({
    mode: "onChange",
  });

  const { touchedFields, errors, isDirty, isValid } = formState;
  const touched = Object.keys(touchedFields);

  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts-list`;
    await axios.get(mainCourtUrl).then((response) => {
      setMainCourtList(
        response?.data.map((court) => ({
          label: court.courtName,
          value: court.id,
        }))
      );
    });
  };

  useEffect(() => {
    if (!localStorage.getItem("Token")) {
      alert("You are not logged In. Please LogIn");
      history.push("/");
    }
    GetMainCourt();
    reset({
      maincourt_availability: [options[0]]
    });
  }, []);

  const onSubmit = (value) => {
    let selectMainCourt = value.maincourt_availability.map(court => court.value);
    value.maincourt_availability = selectMainCourt;
    console.log("Value", value);
    
    const addonUrl = `${environment.baseUrl}addon`;
    axios
      .post(addonUrl, value)
      .then((response) => {
        if (response.data.Message === "Addons are Added Successfully") {
          swal({
            title: "Success!",
            text: "Addons are Added Successfully!",
            icon: "success",
            button: "Close",
          }).then(() => {
            history.push("/list_addons");
          });
        }
      })
      .catch((err) => {
        if (err) {
          swal({
            title: "Error!",
            text: err,
            icon: "error",
            button: "Close",
          });
        }
      });
  };

  const Back = () => {
    reset();
    history.push("/list_addons");
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 14 }}
                >
                  Enter Addons Name
                </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="maincourt_availability"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        value={value}
                        isMulti
                        options={mainCourtList}
                        className="basic-multi-select w-100"
                        components={animatedComponents}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                {errors.maincourt_availability && (
                  <span className="error-msg">Please Select Main Court</span>
                )}
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 14 }}
                >
                  Enter Addons Name
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    style={{ borderRadius: 5 }}
                    name="addonname"
                    {...register("addonname", { required: true })}
                  />
                </div>
                {errors.addonname && (
                  <span className="error-msg">Please Enter Addon Name</span>
                )}
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 14 }}
                >
                  Addon Description
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    style={{ borderRadius: 5 }}
                    name="description"
                    {...register("description", { required: true })}
                  />
                </div>
                {errors.description && (
                  <span className="error-msg">
                    Please Enter Addon Description
                  </span>
                )}
              </div>
            </div>

            <div className="form__half" style={{ marginLeft: "30px" }}>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 14, marginTop: 20 }}
                >
                  Enter Addons Price
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    style={{ borderRadius: 5 }}
                    name="price"
                    {...register("price", { required: true })}
                  />
                </div>
                {errors.price && (
                  <span className="error-msg">Please Enter Addon Price</span>
                )}
              </div>
            </div>
            <div className="form__half"></div>

            <div className="form__half" style={{ marginLeft: "30px" }}>
              <ButtonToolbar
                className="form__button-toolbar"
                style={{ marginTop: 40 }}
              >
                <button className="justpadel-buttons-save" type="submit">
                  Submit
                </button>
                <button
                  className="justpadel-buttons-cancel"
                  onClick={() => Back()}
                >
                  Cancel
                </button>
              </ButtonToolbar>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default AddonsEditCard;
