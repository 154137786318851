import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, Table, ButtonToolbar, Button } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { environment } from "../env";
import axios from "../../customAxios";
import swal from "sweetalert";

const ManageCoachesSlots = () => {
  const history = useHistory();
  const { coacheId } = useParams();
  const [reloadState, setReloadState] = useState(false);
  const [times] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
  ]);
  const [times1] = useState(["12:00 AM", "12:45 AM", "01:30 AM", "02:15 AM"]);
  const [timeslot, setTimeslot] = useState({
    sunday_1: {
      openingTime: "",
      closingTime: "",
    },
    sunday_2: {
      openingTime: "",
      closingTime: "",
    },
    monday_1: {
      openingTime: "",
      closingTime: "",
    },
    monday_2: {
      openingTime: "",
      closingTime: "",
    },
    tuesday_1: {
      openingTime: "",
      closingTime: "",
    },
    tuesday_2: {
      openingTime: "",
      closingTime: "",
    },
    wednesday_1: {
      openingTime: "",
      closingTime: "",
    },
    wednesday_2: {
      openingTime: "",
      closingTime: "",
    },
    thursday_1: {
      openingTime: "",
      closingTime: "",
    },
    thursday_2: {
      openingTime: "",
      closingTime: "",
    },
    friday_1: {
      openingTime: "",
      closingTime: "",
    },
    friday_2: {
      openingTime: "",
      closingTime: "",
    },
    saturday_1: {
      openingTime: "",
      closingTime: "",
    },
    saturday_2: {
      openingTime: "",
      closingTime: "",
    },
  });

  function handleChangeOpeningTime(e, day) {
    const timeSlot = { ...timeslot };
    timeSlot[day].openingTime = e.target.value;
    setTimeslot(timeSlot);
    setReloadState(true);
  }

  function handleChangeClosingTime(e, day) {
    const timeSlot = { ...timeslot };
    timeSlot[day].closingTime = e.target.value;
    setTimeslot(timeSlot);
    setReloadState(true);
  }

  const handleSubmit = () => {
    
    const coacheSlots = {
      coacheslots: [timeslot],
      coacheId: parseInt(coacheId),
    };

    const coacheManageTimeslots = `${environment.baseUrl}staffmanagement/coache-manage-timeslots/`;
    axios.post(coacheManageTimeslots, coacheSlots).then((response) => {
      if(response?.status  === 200){
        swal({
          title: "Success",
          text: "Coach Time Slot Added Successfully!",
          icon: "success",
          button: "Ok",
        }).then((value)=>{
          history.push("/coaches-list");
        })
      }
    });

  };

  const reset = () => {
    console.log("reset");
  };  

  const GetSlots = () => {
    const GetSlotsURL = `${environment.baseUrl}staffmanagement/coache-timeslots/${coacheId}/`;
    axios.get(GetSlotsURL).then((slotsResponse)=>{
      if(slotsResponse?.data?.slots?.replace(/'/g, '"')){
        let tSlots = JSON.parse(slotsResponse?.data?.slots?.replace(/'/g, '"'));
        setTimeslot(tSlots[0]);
      }
    })
  };

  useEffect(() => {
    GetSlots();
  }, []);
  

  return (
    <Col md={12}>
      <Card>
        <CardBody className="form">
          <div className="card__title">
            <h5 className="bold-text">Timeslot for Coach Kamlesh</h5>
          </div>
          <Table responsive hover>
            <thead>
              <tr>
                <th>Day</th>
                <th>Slot</th>
                <th>Opening Time</th>
                <th>Closing Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h5 className="bold-text">Sunday</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 1</h5>
                </td>
                <td>
                  <select
                    className="Select-control"
                    name="openingTime"
                    onChange={(e) => handleChangeOpeningTime(e, "sunday_1")}
                    value={timeslot?.sunday_1?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    name="closingTime"
                    onChange={(e) => handleChangeClosingTime(e, "sunday_1")}
                    value={timeslot?.sunday_1?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">-</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 2</h5>
                </td>
                <td>
                  <select
                    className="Select-control"
                    name="openingTime"
                    onChange={(e) => handleChangeOpeningTime(e, "sunday_2")}
                    value={timeslot?.sunday_2?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    name="closingTime"
                    onChange={(e) => handleChangeClosingTime(e, "sunday_2")}
                    value={timeslot?.sunday_2?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">Monday</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 1</h5>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "monday_1")}
                    value={timeslot?.monday_1?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "monday_1")}
                    value={timeslot?.monday_1?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">-</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 2</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "monday_2")}
                    value={timeslot?.monday_2?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "monday_2")}
                    value={timeslot?.monday_2?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">Tuesday</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 1</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "tuesday_1")}
                    value={timeslot?.tuesday_1?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "tuesday_1")}
                    value={timeslot?.tuesday_1?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">-</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 2</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "tuesday_2")}
                    value={timeslot?.tuesday_2?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "tuesday_2")}
                    value={timeslot?.tuesday_2?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">Wednesday</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 1</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "wednesday_1")}
                    value={timeslot?.wednesday_1?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "wednesday_1")}
                    value={timeslot?.wednesday_1?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">-</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 2</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "wednesday_2")}
                    value={timeslot?.wednesday_2?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "wednesday_2")}
                    value={timeslot?.wednesday_2?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">Thrusday</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 1</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "thursday_1")}
                    value={timeslot?.thursday_1?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "thursday_1")}
                    value={timeslot?.thursday_1?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">-</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 2</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "thursday_2")}
                    value={timeslot?.thursday_2?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "thursday_2")}
                    value={timeslot?.thursday_2?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">Friday</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 1</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "friday_1")}
                    value={timeslot?.friday_1?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "friday_1")}
                    value={timeslot?.friday_1?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">-</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 2</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "friday_2")}
                    value={timeslot?.friday_2?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "friday_2")}
                    value={timeslot?.friday_2?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">Saturday</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 1</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "saturday_1")}
                    value={timeslot?.saturday_1?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "saturday_1")}
                    value={timeslot?.saturday_1?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times1 &&
                      times1.map((time) => (
                        <option value={time}>{time}</option>
                      ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <h5 className="bold-text">-</h5>
                </td>
                <td>
                  <h5 className="bold-text">Slot 2</h5>
                </td>
                <td>
                  {/* <input onChange={handleChangeWeekName} /> */}
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeOpeningTime(e, "saturday_2")}
                    value={timeslot?.saturday_2?.openingTime}
                  >
                    <option value="">Select Opening Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
                <td>
                  <select
                    className="Select-control"
                    onChange={(e) => handleChangeClosingTime(e, "saturday_2")}
                    value={timeslot?.saturday_2?.closingTime}
                  >
                    <option value="">Select Closing Time</option>
                    {times &&
                      times.map((time) => <option value={time}>{time}</option>)}
                  </select>
                </td>
              </tr>
            </tbody>
          </Table>
          <Col md={12} lg={12}>
            <Card
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: 30,
              }}
            >
              <div className="form__half" style={{ marginRight: 30 }}>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="warning" size="sm" onClick={handleSubmit}>
                    Save
                  </Button>
                  <Button type="button" size="sm" onClick={() => reset()}>
                    Reset
                  </Button>
                </ButtonToolbar>
              </div>
            </Card>
          </Col>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ManageCoachesSlots;
