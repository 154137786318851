import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { environment } from "../../env";
import axios from '../../../customAxios';
import swal from 'sweetalert';
const SidebarContent = () => {
  const [role, setRole] = useState();
  const history = useHistory();
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [reload, setReload] = useState(false);
  const [newPermissions, setNewPermissions] = useState([]);

  const getRole = async () => {
    const localRole = localStorage.getItem("Role");
    const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localRole}`;

    try {
      const response = await axios.get(getRoleUrl);
      setRole(response?.data);
      setPermissions(response?.data);
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };

  const setPermissions = (roleData) => {
    const localRole = localStorage.getItem("Role");
    let newRoleId;


    if (localRole === roleData.role_name) {
      const permissions = JSON.parse(roleData.permissions);
      setNewPermissions(permissions);

      for (const permission of Object.values(permissions)) {
        for (const key of Object.keys(permission)) {
          const pageName = "calender";

          if (key === pageName) {
            newRoleId = permission[key];
          }
        }
      }
    }

    if (newRoleId === 2 || newRoleId === 3 || newRoleId === "2" || newRoleId === "3") {
      setEditing(true);
      setReload(!reload);
    }

    if (newRoleId === 3 || newRoleId === "3") {
      setDeleting(true);
      setReload(!reload);
    }
  };

  useEffect(() => {
    getRole();
  }, []);

  const logout = () => {
    localStorage.removeItem('Token');
    history.push('/log_in');
  };

  const isAppConfigPermission = newPermissions[10]?.slides === "4" && newPermissions[11]?.ratings === "4" && newPermissions[12]?.padelholic === "4" && newPermissions[13]?.users_ratings === "4";
  const isOfferDocPermission = newPermissions[19]?.offer_doc === "3" && newPermissions[14]?.coupon === "3";
  const isSkillVerifyCoachPermission = newPermissions[20]?.skill_verify_coach === "3"

  const renderAppConfigSidebar = (roleData) => {

    return (
      <SidebarCategory title="App Configuration's" icon="smartphone">
        {isSkillVerifyCoachPermission && roleData === 'coaches' && <SidebarLink title="Skill Level Verification" route="/verify-skill-level" />}

        {isOfferDocPermission && <SidebarLink title="Offer Docs" route="/offer-docs" />}
        {newPermissions[1]?.manual_bookings !== "4" && roleData !== 'manager' && <SidebarLink title="Add Bookings" route="/courtbooking" />}
        {newPermissions[11]?.ratings !== "4" && <SidebarLink title="Ratings" route="/rating" />}
        {newPermissions[12]?.padelholic !== "4" && <SidebarLink title="Padelholic" route="/listpadelholic" />}
        {newPermissions[13]?.users_ratings !== "4" && <SidebarLink title="User's Ratings" route="/user_ratings" />}
        {newPermissions[14]?.coupon !== "4" && <SidebarLink title="Coupon" route="/coupon" />}
        {isOfferDocPermission && roleData !== 'manager' && <SidebarLink title="Pages" route="/getpage" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Offers" route="/offer-list" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Miscellaneous" route="/misc-list" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Push Notification" route="/push_notification" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Promotion" route="/promotion" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="PromotionsV2" route="/promotionsv2-list" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Package List" route="/package-list" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Rewards" route="/rewards-list" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Match Categories" route="/match-categories-list" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Unsuccessful Transaction" route="/unsucess-transaction" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Coaches List" route="/coaches-list" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="CS List" route="/customer-support-list" />}
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Skill Level Verification" route="/verify-skill-level" />}        
        {newPermissions[14]?.coupon !== "4" && roleData !== 'manager' && <SidebarLink title="Event Registration" route="/event-registration-tabs" />}

      </SidebarCategory>
    )
  };


  return (
    <div className="sidebar__content">
      {role && role.role_name !== 'hattapadel' ? (
        <>
          {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.coupon === "4") && (
            <ul className="sidebar__block">

              {newPermissions[5]?.main_court !== "4" && (
                <SidebarCategory title="Dashboard" icon="chart-bars">
                  <SidebarLink title="Report" route="/report" />
                  <SidebarLink title="Bookings Report" route="/get-slotsbookingreport" />

                </SidebarCategory>
              )}

              {(newPermissions[0]?.calender !== "4" &&
                newPermissions[1]?.manual_bookings !== "4" &&
                newPermissions[2]?.booking_list !== "4" &&
                newPermissions[3]?.notify_me !== "4") && (
                  <SidebarCategory title="Bookings" icon="calendar-full">
                    {newPermissions[0]?.calender !== "4" && <SidebarLink title="Calender" route="/calender" />}
                    {newPermissions[0]?.calender !== "4" && <SidebarLink title="Calender V2" route="/calenderv2" />}
                    {newPermissions[2]?.booking_list !== "4" && <SidebarLink title="Booking's List" route="/bookings" />}
                    {newPermissions[3]?.notify_me !== "4" && <SidebarLink title="Notify Me" route="/notify_me" />}
                  </SidebarCategory>
                )}


              {newPermissions[4]?.transaction !== "4" && (
                <SidebarCategory title="Payment" icon="cart">
                  <SidebarLink title="Transaction's" route="/transactions" />
                  <SidebarLink title="Quick Pay" route="/quick-pay-details" />
                  <SidebarLink title="Gift Card" route="/e-giftcard" />
                  <SidebarLink title="Tournaments" route="/tournaments" />
                  <SidebarLink title="Kids Camp" route="/kids-summer-camp" />
                </SidebarCategory>
              )}


              {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
                <SidebarCategory title="Matchmaking" icon="file-add">
                  {newPermissions[0]?.coupon !== "4" && <SidebarLink title={`Coaches`} route="/coache-calender" />}
                  {newPermissions[0]?.coupon !== "4" && <SidebarLink title="Customer Support" route="/customer-support-calender" />}
                </SidebarCategory>
              )}


              {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
                <SidebarCategory title="Packages" icon="file-add">
                  {newPermissions[5]?.registered_user !== "4" && <SidebarLink title="Active Users" route="/user-packages" />}
                </SidebarCategory>
              )}


              {!(newPermissions[15]?.registered_user === "4" && newPermissions[16]?.create_user === "4") && (
                <SidebarCategory title="User" icon="user">
                  {newPermissions[15]?.registered_user !== "4" && <SidebarLink title="Registered Users" route="/user" />}
                  {newPermissions[15]?.registered_user !== "4" && <SidebarLink title="Loyalty Points" route="/loyalty-points-management" />}
                  {newPermissions[15]?.registered_user !== "4" && <SidebarLink title="Generate Slot" route="/generate-slot" />}
                </SidebarCategory>
              )}


              {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
                <SidebarCategory title="Location's" icon="map-marker">
                  {newPermissions[5]?.main_court !== "4" && <SidebarLink title="Main Court" route="/list_main_court" />}
                  {newPermissions[6]?.padel_court !== "4" && <SidebarLink title="Padel Court" route="/all_courts" />}
                  {newPermissions[7]?.add_on !== "4" && <SidebarLink title="Add on's" route="/list_addons" />}
                  {newPermissions[8]?.court_type !== "4" && <SidebarLink title="Court Type" route="/list_court_type" />}
                  {newPermissions[9]?.court_category !== "4" && <SidebarLink title="Court Category" route="/list_court_category" />}
                </SidebarCategory>
              )}


              {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
                <SidebarCategory title="Ads Space" icon="indent-increase">
                  {newPermissions[5]?.main_court !== "4" && <SidebarLink title="Ads Space List" route="/ad-space-list" />}
                  {newPermissions[5]?.main_court !== "4" && <SidebarLink title="Ads Analysis" route="/ads-impressions" />}
                </SidebarCategory>
              )}


              {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
                <SidebarCategory title="Homepage" icon="home">
                  {newPermissions[10]?.slides !== "4" && <SidebarLink title="Home Slides" route="/list_slides" />}
                  {newPermissions[10]?.slides !== "4" && <SidebarLink title="Home Slides V2" route="/list_slides-v2" />}
                  {newPermissions[5]?.main_court !== "4" && <SidebarLink title="Offers List" route="/limited_time_offers" />}
                  {newPermissions[5]?.main_court !== "4" && <SidebarLink title="News List" route="/news-list" />}
                </SidebarCategory>
              )}

              {isAppConfigPermission ? (isOfferDocPermission ? renderAppConfigSidebar(role.role_name) : isSkillVerifyCoachPermission ? renderAppConfigSidebar(role.role_name) : null) : renderAppConfigSidebar(role.role_name)}

              {!(newPermissions[17]?.user_role_list == "4" && newPermissions[18]?.create_user_role == "4")
                &&
                <SidebarCategory title="Manage User" icon="select">
                  {newPermissions[16]?.user_role_list !== "4" && <SidebarLink title={`Create User`} route="/create_user" />}
                  {newPermissions[16]?.user_role_list !== "4" && <SidebarLink title="Update User" route="/update-user" />}
                  {/* {newPermissions[18]?.user_role_list !== "4" ? <SidebarLink title="Create User Role's" route="/role" /> : ''}*/}
                </SidebarCategory>
              }

              {!(newPermissions[17]?.user_role_list === "4" && newPermissions[18]?.create_user_role === "4")
                &&
                <SidebarCategory title="Permissions" icon="checkmark-circle">
                  {newPermissions[18]?.user_role_list !== "4" && <SidebarLink title="Create User Role's" route="/role" />}
                  {newPermissions[17]?.user_role_list !== "4" && <SidebarLink title="User Role's List" route="/role_list" />}
                </SidebarCategory>
              }

              {!(newPermissions[17]?.user_role_list === "4" && newPermissions[18]?.create_user_role === "4")
                &&
                <SidebarCategory title="Padelgram" icon="select">
                  {newPermissions[5]?.registered_user !== "4" && <SidebarLink title="Live Post" route="/live-post" />}
                  {newPermissions[5]?.registered_user !== "4" && <SidebarLink title="Reported Post" route="/report-problem" />}
                  {newPermissions[5]?.registered_user !== "4" && <SidebarLink title="Deleted Post" route="/deleted-post" />}
                </SidebarCategory>
              }

              <SidebarLink title="Log Out" icon="exit" onClick={logout} />


            </ul>
          )}
        </>


      ) : (
        <ul className="sidebar__block">
          {newPermissions[5]?.main_court !== "4" && (
            <SidebarCategory title="Dashboard" icon="chart-bars">
              <SidebarLink title="Report" route="/report" />
            </SidebarCategory>
          )}

          {(newPermissions[0]?.calender !== "4" &&
            newPermissions[1]?.manual_bookings !== "4" &&
            newPermissions[2]?.booking_list !== "4" &&
            newPermissions[3]?.notify_me !== "4") && (
              <SidebarCategory title="Bookings" icon="calendar-full">
                {newPermissions[0]?.calender !== "4" && <SidebarLink title="Calender" route="/calender" />}
                {newPermissions[0]?.calender !== "4" && <SidebarLink title="Calender V2" route="/calenderv2" />}
                {newPermissions[2]?.booking_list !== "4" && <SidebarLink title="Booking's List" route="/bookings" />}
                {newPermissions[3]?.notify_me !== "4" && <SidebarLink title="Notify Me" route="/notify_me" />}
              </SidebarCategory>
            )}


          {newPermissions[4]?.transaction !== "4" && (
            <SidebarCategory title="Payment" icon="cart">
              <SidebarLink title="Transaction's" route="/transactions" />
              <SidebarLink title="Quick Pay" route="/quick-pay-details" />
              <SidebarLink title="Gift Card" route="/e-giftcard" />
              <SidebarLink title="Tournaments" route="/tournaments" />
              <SidebarLink title="Kids Camp" route="/kids-summer-camp" />
            </SidebarCategory>
          )}


          {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
            <SidebarCategory title="Matchmaking" icon="file-add">
              {newPermissions[0]?.coupon !== "4" && <SidebarLink title={`Coaches`} route="/coache-calender" />}
              {newPermissions[0]?.coupon !== "4" && <SidebarLink title="Customer Support" route="/customer-support-calender" />}
            </SidebarCategory>
          )}


          {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
            <SidebarCategory title="Packages" icon="file-add">
              {newPermissions[5]?.registered_user !== "4" && <SidebarLink title="Active Users" route="/user-packages" />}
            </SidebarCategory>
          )}


          {!(newPermissions[15]?.registered_user === "4" && newPermissions[16]?.create_user === "4") && (
            <SidebarCategory title="User" icon="user">
              {newPermissions[15]?.registered_user !== "4" && <SidebarLink title="Registered Users" route="/user" />}
              {newPermissions[15]?.registered_user !== "4" && <SidebarLink title="Loyalty Points" route="/loyalty-points-management" />}
              {newPermissions[15]?.registered_user !== "4" && <SidebarLink title="Generate Slot" route="/generate-slot" />}
              {newPermissions[15]?.registered_user !== "4" && <SidebarLink title="SlotsBookingReport" route="/get-slotsbookingreport" />}
            </SidebarCategory>
          )}


          {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
            <SidebarCategory title="Location's" icon="map-marker">
              {newPermissions[5]?.main_court !== "4" && <SidebarLink title="Main Court" route="/list_main_court" />}
              {newPermissions[6]?.padel_court !== "4" && <SidebarLink title="Padel Court" route="/all_courts" />}
              {newPermissions[7]?.add_on !== "4" && <SidebarLink title="Add on's" route="/list_addons" />}
              {newPermissions[8]?.court_type !== "4" && <SidebarLink title="Court Type" route="/list_court_type" />}
              {newPermissions[9]?.court_category !== "4" && <SidebarLink title="Court Category" route="/list_court_category" />}
            </SidebarCategory>
          )}


          {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
            <SidebarCategory title="Ads Space" icon="indent-increase">
              {newPermissions[5]?.main_court !== "4" && <SidebarLink title="Ads Space List" route="/ad-space-list" />}
              {newPermissions[5]?.main_court !== "4" && <SidebarLink title="Ads Analysis" route="/ads-impressions" />}
            </SidebarCategory>
          )}


          {!(newPermissions[5]?.main_court === "4" && newPermissions[6]?.padel_court === "4" && newPermissions[7]?.add_on === "4" && newPermissions[8]?.court_type === "4" && newPermissions[9]?.court_category === "4") && (
            <SidebarCategory title="Homepage" icon="home">
              {newPermissions[10]?.slides !== "4" && <SidebarLink title="Home Slides" route="/list_slides" />}
              {newPermissions[10]?.slides !== "4" && <SidebarLink title="Home Slides V2" route="/list_slides-v2" />}
              {newPermissions[5]?.main_court !== "4" && <SidebarLink title="Offers List" route="/limited_time_offers" />}
              {newPermissions[5]?.main_court !== "4" && <SidebarLink title="News List" route="/news-list" />}
            </SidebarCategory>
          )}

          {isAppConfigPermission ? (isOfferDocPermission ? renderAppConfigSidebar() : null) : renderAppConfigSidebar()}

          {!(newPermissions[17]?.user_role_list == "4" && newPermissions[18]?.create_user_role == "4")
            &&
            <SidebarCategory title="Manage User" icon="select">
              {newPermissions[16]?.user_role_list !== "4" && <SidebarLink title={`Create User`} route="/create_user" />}
              {newPermissions[16]?.user_role_list !== "4" && <SidebarLink title="Update User" route="/update-user" />}
              {/* {newPermissions[18]?.user_role_list !== "4" ? <SidebarLink title="Create User Role's" route="/role" /> : ''}*/}
            </SidebarCategory>
          }

          {!(newPermissions[17]?.user_role_list === "4" && newPermissions[18]?.create_user_role === "4")
            &&
            <SidebarCategory title="Permissions" icon="checkmark-circle">
              {newPermissions[18]?.user_role_list !== "4" && <SidebarLink title="Create User Role's" route="/role" />}
              {newPermissions[17]?.user_role_list !== "4" && <SidebarLink title="User Role's List" route="/role_list" />}
            </SidebarCategory>
          }

          {!(newPermissions[17]?.user_role_list === "4" && newPermissions[18]?.create_user_role === "4")
            &&
            <SidebarCategory title="Padelgram" icon="select">
              {newPermissions[5]?.registered_user !== "4" && <SidebarLink title="Live Post" route="/live-post" />}
              {newPermissions[5]?.registered_user !== "4" && <SidebarLink title="Reported Post" route="/report-problem" />}
              {newPermissions[5]?.registered_user !== "4" && <SidebarLink title="Deleted Post" route="/deleted-post" />}
            </SidebarCategory>
          }

        </ul>
      )}


    </div>
  );
};

SidebarContent.propTypes = {
};

export default SidebarContent;
