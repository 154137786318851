import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, ButtonToolbar, Button } from 'reactstrap';
import { environment } from '../../env';
import axios from '../../../customAxios';
import swal from '@sweetalert/with-react';

const ExampleCard = () => {
  const [deviceTokens, setDeviceTokens] = useState([]);
  const [notificationPayload, setNotificationPayload] = useState();

  const getDeviceTokenUrl = `${environment.baseUrl}get-device-token/`;

  const getDeviceToken = (payload) => {
    swal({
      title: "Please check the push notification",
      icon: "warning",
      content: (
        <div>
          <p>Title : {payload.title}</p>
          <p>Body : {payload.body}</p>
        </div>
      ),
      buttons: true,
      dangerMode: true,
    }).then((sendNotification) => {
      if (sendNotification) {
        axios(getDeviceTokenUrl)
      .then((response) => {
        setDeviceTokens(response.data)
        let arrayTokenPayload = [];
        let data = response.data;
        data.forEach((token) => {
          const pushNotificationPayload = {
            to: token.token,
            title: payload.title,
            body: payload.body,
          }
          arrayTokenPayload.push(pushNotificationPayload)
        })
        newPushNotification(arrayTokenPayload);
      })
        swal("Push notification is sent", {
          icon: "success",
        });
      } else {
        swal("Push notification is cancelled");
      }
    });
  };

  const newPushNotification = (payload) => {
    var perChunk = 99; // items per chunk
    var inputArray = payload;
    var result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])
    result.forEach((data) => {
      const pushNotificationUrl = `${environment.baseUrl}push-notification/`;
      axios.post(pushNotificationUrl, data)
        .then((response) => {
          console.log("Response of Push Notification Payload", response);
        })
        .catch((error) => {
          console.log(error);
        })
    })

  };

  const handleOnChange = event => {
    const { name, value } = event.target;
    setNotificationPayload({ ...notificationPayload, [name]: value });
  };

  const handleSubmit = () => {
    getDeviceToken(notificationPayload);
  }

  const reset = (val) => {
      document.getElementById("reset-form").reset();
  }

  return (
    <Col md={12} lg={12}>
      <CardBody>
        <form className="form product-edit" id='reset-form' encType="multipart/form-data" style={{ fontSize: 15 }}>
          <div className="form__half">
            <div className="form__form-group" style={{ marginTop: 20 }}>
              <span className="form__form-group-label">Title</span>
              <div className="form__form-group-field">
                <input type="text" onChange={handleOnChange}
                  name="title"
                  style={{ borderRadius: 5, borderColor: "#ccc" }} />
              </div>
            </div>
            <div className="form__form-group" style={{ marginTop: 20 }}>
              <span className="form__form-group-label">Body</span>
              <div className="form__form-group-field">
                <input type="text" onChange={handleOnChange}
                  name="body"
                  style={{ borderRadius: 5, borderColor: "#ccc" }} />
              </div>
            </div>
          </div>
        </form>
        <Col md={6} lg={6}>
          <ButtonToolbar className="form__button-toolbar" style={{ display: 'flex', justifyContent: "flex-end", marginTop: 25 }}>

            <button className='justpadel-buttons-cancel' onClick={reset}>
              Cancel
            </button>
            <button className='justpadel-buttons-save' onClick={() => handleSubmit()}>
              Send
            </button>
          </ButtonToolbar>
        </Col>

      </CardBody>
    </Col>
  )
};

export default ExampleCard;
