import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import UsersListCard from './components/CourtTypeEditCard';

const UsersIndex = () => {
  return(
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">User&apos;s List</h3>
      </Col>
    </Row>
    <Row>
      <UsersListCard />
    </Row>
  </Container>
);
  }
export default UsersIndex;
