import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import EditCourtTypeCard from './components/CourtTypeEditCard';

const EditCourtType = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Court Type</h3>
        <h3 className="page-subhead subhead">
          Use this elements, to Edit Court Type
        </h3>
      </Col>
    </Row>
    <Row>
      <EditCourtTypeCard />
    </Row>
  </Container>
);
export default EditCourtType;
