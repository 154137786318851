import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button,CardBody,Modal,Card, Col,} from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import { BookingDetailsByBookingID } from '../../containers/Reuse/ApiCalls';
import EditBookingTab from '../../containers/UI/Tabs/components/EditBookingTab';
import './TransationModal.css';
import  { AiOutlineEdit } from 'react-icons/ai';




const EditBookingModal = ({
    color, btn,colored, header,title,transactionId,refreshData
}) => {
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(prevState => !prevState);
    };

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    useEffect(() => {
        
    }, [])

    const ClickToRefresh = () =>{
        toggle();
        refreshData();
    }
    
    return (
        <div>
            {/* className="view-btn" */}
            <div className="modal-edit-btn"
                style={{marginTop:5, marginLeft:5}}
                 onClick={toggle}>
                 <AiOutlineEdit style={{fontSize:20}}/>
                 {}
            </div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass}` }
                style={{minWidth:750}}
            >
                <div className="modal__header" style={{backgroundColor:'#70bbfd'}}>
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody>
                        <div className="tabs tabs--justify tabs--bordered-bottom">
                            <EditBookingTab transactionId={transactionId} toggleModal={ClickToRefresh} />
                        </div>
                        </CardBody>
                    </Card>
                </Col>
                {/* <Card>
                    <CardBody>
                        {transactionId}
                    </CardBody>
                </Card> */}
            </Modal>
        </div>
    );
};

EditBookingModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired,
};

EditBookingModal.defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
};

export default EditBookingModal;

