import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal,Table } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import { GetWalletTreansationLogByID } from '../../containers/Reuse/ApiCalls';
import './WalletTransactiomModal.css';
import  { AiFillEye } from 'react-icons/ai';


const WalletTransactionModal = ({
  color, btn, title, message, colored, header, rtl,transactionLogId
}) => {
  const [modal, setModal] = useState(false);
  const [transactionLogData, setTransactionLogData] = useState();
  const [transactionLog, setTransactionLog] = useState();

  const toggle = () => {
    setModal(prevState => !prevState);
    if(!modal){
    GetWalletTreansationLogByID(transactionLogId).then((response)=>{
      console.log(JSON.parse(response.transaction_log)[0]);
      console.log(response);
      setTransactionLog(JSON.parse(response.transaction_log)[0]);
      setTransactionLogData(response);
    });
  }else{
    console.log("No fetch");
  }
  };

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  useEffect(() => {


  }, [])

  return (
    <div>
      <div className="modal-view-btn" onClick={toggle}> <AiFillEye style={{fontSize:20}}/>{btn}</div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--${color} ${modalClass}`}
        style={{maxWidth:"75%"}}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          {header ? '' : Icon}
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div className="modal__body">
        <div className="transaction-log-data">
           <div className="transaction-log-data-lhs">
              <div>
                <label className="trans-logs-lhs-text"> Payment Status:</label> {' '}
                <label className="trans-logs-rhs-text">{transactionLogData?.payment_status}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> User:</label> {' '}
                <label className="trans-logs-rhs-text">{transactionLogData?.user}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Wallet:</label> {' '}
                <label className="trans-logs-rhs-text">{transactionLogData?.wallet}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Access Code:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.access_code}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Acquirer Response Code:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.acquirer_response_code}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Amount:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.amount}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Authorization Code:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.authorization_code}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Card Holder Name:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.card_holder_name}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Card Number:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.card_number}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Command:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.command}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Currency:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.currency}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Customer Email:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.customer_email}</label>
              </div>
           </div>

           <div className="transaction-log-data-rhs">
              <div>
                <label className="trans-logs-lhs-text"> Customer IP:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.customer_ip}</label>
              </div>
             <div>
                <label className="trans-logs-lhs-text"> ECI:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.eci}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Expiry Date:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.expiry_date}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Fort ID:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.fort_id}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Language:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.language}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Merchant Identifier:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.merchant_identifier}</label>
              </div>
             <div>
                <label className="trans-logs-lhs-text"> Merchant Reference:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.merchant_reference}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Order Description:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.order_description}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Payment Option:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.payment_option}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Response Code:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.response_code}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Response Message:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.response_message}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text"> Status:</label> {' '}
                <label className="trans-logs-rhs-text">{transactionLog?.status}</label>
              </div>
              <div style={{display:'flex',justifyContent:'flex-start'}} >
                <label className="trans-logs-lhs-text"> signature:</label> {' '}
                <label className="trans-logs-rhs-text" style={{width:150,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{transactionLog?.signature}</label>
              </div>
           </div>
        </div>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" outline={colored} color={color}  onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

WalletTransactionModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
};

WalletTransactionModal.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
};

export default connect(state => ({
  rtl: state.rtl,
}))(WalletTransactionModal);
