import S3FileUpload from 'react-s3';
export const uploadCouponImage = async (file, dir) => {
    const config = {
        bucketName: 'justpadel-courtimages',
        region: 'us-east-1',
        accessKeyId: 'AKIAZZLUGKQZZJT3G4FX',
        secretAccessKey: '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',
        dirName: `${dir}`,
    };


    S3FileUpload.uploadFile(file, config)
        .then((data) => {
            console.log(data.location);
        })
        .catch((err) => {
            console.log('Error Info: ', err);
        });
}
