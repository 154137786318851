import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import MiscelleniousList from './components/MiscelleniousList';
import { Link } from 'react-router-dom';

const ExamplePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={6}>
        <h3 className="page-title">Miscellenious List</h3>
      </Col>
    </Row>
    <Row>
      <MiscelleniousList />
    </Row>
  </Container>
);

export default ExamplePage;
