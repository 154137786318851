/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState } from 'react';
import {
    Col, Container, Row,
} from 'reactstrap';
import AllCourtsEdit from './components/AllCourtsEdit';

const EditCourt = () => (
  <Container>
    {/* <Row>
      <Col md={12}>
        <h3 className="page-title">Edit Court&apos;s</h3>
        <h3 className="page-subhead subhead">
          Use this elements, for Edit Court
        </h3>
      </Col>
    </Row> */}
    <Row>
      <AllCourtsEdit />
    </Row>
  </Container>
  );
export default EditCourt;
