import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CourtCard from './components/CourtCard';

const ListIndex = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Main Court</h3>
      </Col>
    </Row>
    <Row>
      <CourtCard />
    </Row>
  </Container>
);

export default ListIndex;
