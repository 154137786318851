import React, { useState, useEffect, useRef } from "react";
import { Col, Button, ButtonToolbar, Card, CardBody, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import JoditEditor from "jodit-react";
import S3FileUpload from 'react-s3';
import axios from "../../../customAxios";
import { environment } from "../../env";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

const config = {
    // bucketName: 'test-padel',
    bucketName: 'justpadel-courtimages',
    region: 'us-east-1',
    accessKeyId: 'AKIAZZLUGKQZZJT3G4FX',
    secretAccessKey: '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',
};

const NewAddOffer = () => {

    const { register, handleSubmit, reset, formState: { errors }, } = useForm({ mode: "onBlur" });
    const [description, setDescription] = useState();
    const editor = useRef(null);
    const [offerImageUrl, setOfferImageUrl] = useState();
    const offerImageUrls = register("image", { required: true });
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [TCData, setTCData] = useState();
    const history = useHistory();
    // const offerDescription = register("offerDescription", { required: true });

    function handleChangeImageUpload(e) {
        setButtonDisabled(true);
        S3FileUpload.uploadFile(e.target.files[0], config)
            .then((data) => {
                setOfferImageUrl(data.location);
            })
            .catch((err) => {
                console.log('Error Info: ', err);
            });
            setButtonDisabled(false);
    };

    const addOfferURL = `${environment.baseUrl}addoffer`;
    useEffect(() => {
        setOfferImageUrl(offerImageUrl);
    }, [offerImageUrl]);
    

    const onSubmit = (data) => {
        data.image = offerImageUrl;
        data.description = description;
        data.tandc = TCData;
        console.log("values", data);
        axios.post(addOfferURL, data).then((response) => {
            if (response.status === 200) {
                console.log("response",response); 
                if(response.data.msg === 'offers is added successfully'){
                    swal({
                        title: "Success",
                        text: "Offer is Added Successfully!",
                        icon: "success",
                        button: "Ok",
                      })
                      .then((value) => {
                        history.push("/offer-list");
                      })
                }            
            }
          });
    }
    return (

        <Col md={12} lg={12}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text" style={{ color: "#acacac" }}>
                            Insert Offer Data Here
                        </h5>
                    </div>
                    <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 14 }} >Title</span>
                                <div className="form__form-group-field">
                                    <input type="text" name="offer" {...register("offer", { required: true })} />
                                </div>
                                <span>{errors.offer && <p>Please add the title of the Offer</p>}</span>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 14 }} >Description</span>
                                <div className="form__form-group-field">
                                    <JoditEditor
                                        ref={editor}
                                        tabIndex={1}
                                        onChange={newContent => setDescription(newContent)}
                                        // {...offerDescription}                                        
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 14 }} >Date</span>
                                <div className="form__form-group-field">
                                    <input type="date" name="date" {...register("date", { required: true })} />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 14 }} >Image</span>
                                <div className="form__form-group-field">
                                    <input type="file" {...offerImageUrls} onChange={(e) => {
                                        handleChangeImageUpload(e);
                                        offerImageUrls.onChange(e);
                                    }} />
                                </div>
                                <span>{errors.offerImageUrl && <p>Please Select Images</p>}</span>
                            </div>
                        </div>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 14 }} >Terms and Condition Text</span>
                                <div className="form__form-group-field">
                                    <input type="text" name="tctxt" {...register("tctxt", { required: true })} />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 14 }} >Terms and Condition Data</span>
                                <div className="form__form-group-field">
                                    {/* <input type="text" name="tandc" {...register("tandc", { required: true })} /> */}
                                    <div className="form__form-group-field">
                                    <JoditEditor
                                        ref={editor}
                                        tabIndex={1}
                                        onChange={newContent => setTCData(newContent)}
                                        // {...offerDescription}                                        
                                    />
                                </div>
                                </div>
                            </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar" style={{ marginTop: 25 }} >
                            <button className='justpadel-buttons-save' type="submit" disabled={buttonDisabled}>Save</button>
                            <div className='justpadel-buttons-cancel' onClick={() => reset()}> Cancel</div>
                        </ButtonToolbar>
                    </form>
                </CardBody>
            </Card>
        </Col>

    );
};

export default NewAddOffer;
