import React, { useEffect, useState, Fragment } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import TopbarMenuLink from "./TopbarMenuLink";
import { useHistory } from "react-router-dom";
import SidebarLink from "../sidebar/SidebarLink";
import moment from "moment";
import { environment } from "../../env";
import axios from "../../../customAxios";
import { GetUserDetailsByEmail, UpdateUserDetails } from "../../Reuse/ApiCalls";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [loggedUSer, setLoggedUSer] = useState();
  const [mainCourtList, setMainCourtList] = useState([]);
  const [defaultCourt, setDefaultCourt] = useState();
  const [userData, setUserData] = useState();
  const history = useHistory();
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const filteredMainCourt = mainCourtList?.filter((data) => data.id !== 12);

  const mainCourtUrl = `${environment.baseUrl}maincourts-admin/`;
  const GetMainCourt = async () => {
    const result = await axios(mainCourtUrl);
    setMainCourtList(result?.data);

    setTimeout(() => {
      let start_date = moment().format("DD-MM-YYYY");
      // getReport(start_date, result.data[0].id);
    }, 2000);
  };

  const handleDefaultMainCourt = async (e) => {
    setDefaultCourt(e.target.value);
    console.log("E", e.target.value);
    const userPayload = { ...userData, default_court: e.target.value };
    console.log("userPayload", userPayload);
    const Email = localStorage.getItem("Email");
    await UpdateUserDetails(Email, userPayload).then((response) => {
      console.log("user update", response);
      let user = JSON.parse(localStorage.getItem("loggedUserDetails"));
      user.default_court = parseInt(e.target.value);
      localStorage.setItem("loggedUserDetails", JSON.stringify(user));
    });
  };
  const GetUserDetail = async () => {
    const Email = localStorage.getItem("Email");
    await GetUserDetailsByEmail(Email).then((response) => {
      setUserData(response);
      localStorage.setItem("loggedUserDetails", JSON.stringify(response));
      setDefaultCourt(response?.default_court);
    });
  };

  useEffect(() => {
    GetUserDetail();
    setLoggedUSer(localStorage.getItem("Email"));
    GetMainCourt();
  }, []);

  const logout = () => {
    localStorage.removeItem("Token");
    history.push("/log_in");
  };

  return (
    <Fragment>
      <form>
        <div className="nav-courtContainer">
          <span className="default-title ">Default Location</span>
          <select
            className="select select-location mr-10"
            value={defaultCourt}
            onChange={handleDefaultMainCourt}
          >
            <option>Select Main Court</option>
            {filteredMainCourt &&
              filteredMainCourt.map((court, index) => (
                <option value={court.id} key={index}>{court.courtName}</option>
              ))}
          </select>
        </div>
      </form>

      <div className="topbar__profile">
        <button
          type="button"
          className="topbar__avatar"
          onClick={handleToggleCollapse}
        >
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{loggedUSer}</p>
          <DownIcon className="topbar__icon" />
        </button>

        {isCollapsed && (
          <button
            type="button"
            aria-label="button collapse"
            className="topbar__back"
            onClick={handleToggleCollapse}
          />
        )}

        <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
          <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" /> */}
            {/* <div className="topbar__menu-divider" /> */}
            <SidebarLink title="Log Out" icon="exit" onClick={logout} />
          </div>
        </Collapse>
      </div>
    </Fragment>
  );
};

export default TopbarProfile;
