import React from "react";
import { Col, Container, Row, Button } from 'reactstrap'
import PromotionsV2 from "./components/PromotionsV2";


const promotionv2index = () =>{
    return (
            <Container className="dashboard">
              <Row>
                <Col>
                  <h3 className="page-title">Promotions V2 List</h3>
                </Col>
              </Row>
              <Row>
                <PromotionsV2 />
              </Row>
            </Container>
          )
        };
 export default promotionv2index;