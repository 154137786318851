import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import PadelholicList from './components/PadelholicList';

const ListPadelholic = () => (
  <Container>
    <Row>
      <Col md={12} >
        <h3 className="page-title">Padelholic&apos;s List</h3>
      </Col>
    </Row>
    <Row>
      <PadelholicList />
    </Row>
  </Container>
);
export default ListPadelholic;