import React, { useState } from 'react'
import { Table } from 'reactstrap'
import { TfiArrowCircleDown, TfiArrowCircleRight } from 'react-icons/tfi'
import { FcCheckmark } from 'react-icons/fc';
const MatchMakingBookingCalculation = ({ bookingDetails }) => {
    console.log("🚀 ~ file: MatchMakingBookingCalculation.jsx:6 ~ MatchMakingBookingCalculation ~ bookingDetails:", bookingDetails)
    const [current, setcurrent] = useState(0);

    const handleDrop = (curr) => {
        if (curr == current) {
            setcurrent(0);
        } else {
            setcurrent(curr);
        }
    };

    const PaymentCalc = (bookedslots, matchmaking) => {
        const matchmaking_court_price = bookedslots.reduce((acc, booking) => acc + booking.price, 0);
        const addon_price = matchmaking.matchmaking_booked_addons.reduce((acc, booking) => acc + booking.amount, 0);

        const per_person_price = matchmaking_court_price / 4;
        let applied_discount_on_court = 0;
        let vat = 0;
        let total = 0;

        if (matchmaking.match_making_apply_for_addons) {
            const court_addon_amount = Math.ceil(per_person_price + addon_price);
            applied_discount_on_court = Math.ceil((court_addon_amount * matchmaking.match_making_coupan_applied_discount) / 100);
            vat = applied_discount_on_court * 0.05;
            total = Math.ceil(applied_discount_on_court + vat);
        } else {
            applied_discount_on_court = Math.ceil((per_person_price * matchmaking.match_making_coupan_applied_discount) / 100);
            const discounted_price = per_person_price - applied_discount_on_court;
            const court_addon_amount = Math.ceil(discounted_price + addon_price);
            vat = court_addon_amount * 0.05;
            total = Math.ceil(court_addon_amount + vat);
        }

        return {
            matchmaking_court_price: per_person_price,
            discounted_amount: Math.ceil(applied_discount_on_court),
            vat: Math.ceil(vat),
            addon_price: Math.ceil(addon_price),
            total: Math.ceil(total)
        };
    };

    const PaymentCalc2 = (bookedslots) => {
        console.log('bookedslots===>',bookedslots)
        const matchmaking_court_price = bookedslots.booked_slots.reduce((acc, booking) => acc + booking.price, 0);
        console.log("matchmaking_court_price===>",matchmaking_court_price)
        const addon_price =bookedslots?.booked_addons ? ( bookedslots?.booked_addons?.reduce((acc, booking) => acc + booking.amount, 0)):(0);
        console.log("addon_price===>",addon_price)

        const per_person_price = matchmaking_court_price / 4;
        let applied_discount_on_court = 0;
        let vat = 0;
        let total = 0;

        if (bookedslots.apply_for_addons) {
            const court_addon_amount = Math.ceil(per_person_price + addon_price);
            applied_discount_on_court = Math.ceil((court_addon_amount * bookedslots.coupan_applied_discount) / 100);
            vat = applied_discount_on_court * 0.05;
            total = Math.ceil(applied_discount_on_court + vat);
        } else {
            applied_discount_on_court = Math.ceil((per_person_price * bookedslots.coupan_applied_discount) / 100);
            const discounted_price = per_person_price - applied_discount_on_court;
            const court_addon_amount = Math.ceil(discounted_price + addon_price);
            vat = court_addon_amount * 0.05;
            total = Math.ceil(court_addon_amount + vat);
        }

        return {
            matchmaking_court_price: per_person_price,
            discounted_amount: Math.ceil(applied_discount_on_court),
            vat: Math.ceil(vat),
            addon_price: Math.ceil(addon_price),
            total: Math.ceil(total)
        };
    };

    return (
        <>
            {bookingDetails?.booked_slots?.length > 0 && <div className="user-datails-lhs">
                <h5>Current Bookings</h5>
            </div>}
            <div className="trans-total-details">
                {bookingDetails?.booked_slots?.length > 0 && (
                    <Table responsive hover>
                        <thead className="trans-total-table-header">
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookingDetails.booked_slots.map((data, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {`${data.main_court_name} - Court ${data.padel_court_name} (${data.date} ${data.time})`}
                                    </td>
                                    <td>{`${data.price.toFixed(2)} AED`}</td>
                                    <td>1</td>
                                    <td>
                                        <FcCheckmark size={25} className="fc-check" style={{ marginLeft: "10px" }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
            <div className="user-datails-lhs"> <h5>Match Making Users</h5> </div>
            <Table>
                <thead>
                    <th></th>
                    <th>Sr.No</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Position</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {current == 1 ? <TfiArrowCircleDown size={22} onClick={() => handleDrop(1)} style={{ cursor: 'pointer' }} /> : <TfiArrowCircleRight size={22} onClick={() => handleDrop(1)} style={{ cursor: 'pointer' }} />}
                        </td>
                        <td>
                            1
                        </td>
                        <td>
                            <img src={bookingDetails?.user?.profile_pic ? bookingDetails?.user?.profile_pic : "https://justpadel-courtimages.s3.amazonaws.com/default-profile-2.jpg"} alt='Profile Pic' style={{ height: 50, width: 50, borderRadius: 50 }} />
                        </td>
                        <td>
                            {bookingDetails.user.first_name}
                        </td>
                        <td>
                            {bookingDetails.user.email}
                        </td>
                        <td>
                            1
                        </td>
                    </tr>
                    {current == 1 && <td colSpan={9}>
                        {bookingDetails?.booked_addons?.length > 0 ?
                            <div className='px-5'>
                                <h6>Booked Addons</h6>
                                <Table className='w-100'>
                                    <thead>
                                        <th>Sr.No</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Amount</th>
                                    </thead>
                                    <tbody>
                                        {bookingDetails.booked_addons.map((addons, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{addons?.addonname}</td>
                                                    <td>{addons?.price} AED</td>
                                                    <td>{addons?.quantity}</td>
                                                    <td>{addons?.amount} AED</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div> : <div className='px-5'>
                                <div className="no-addons">
                                    <h4>No Addons</h4>
                                </div>
                            </div>}
                        {bookingDetails && <div className='px-5 w-50'>
                            <h4>Calculation</h4>
                            <div className='d-flex justify-content-between'><span>Vat 5% : </span><span>{(PaymentCalc2(bookingDetails).vat).toFixed(2)} AED</span></div>
                            <div className='d-flex justify-content-between'><span>Match Making Amount : </span><span>{(PaymentCalc2(bookingDetails).matchmaking_court_price).toFixed(2)} AED</span></div>
                            <div className='d-flex justify-content-between'><span>Addon Amount : </span><span>{(PaymentCalc2(bookingDetails).addon_price).toFixed(2)} AED</span></div>
                            <div className='d-flex justify-content-between'><span>Coupon Applied : </span><span>{bookingDetails.coupan_applied ? bookingDetails.coupan_applied : "No" }</span></div>
                            <div className='d-flex justify-content-between'><span>Coupon Discount : </span><span>{(PaymentCalc2(bookingDetails).discounted_amount).toFixed(2)} AED</span></div>
                            <div className='d-flex justify-content-between'><span>Amount Paid : </span><span>{(bookingDetails.amount_paid).toFixed(2)} AED</span></div>
                            <div className='d-flex justify-content-between'><span>Total : </span><span>{(PaymentCalc2(bookingDetails).total).toFixed(2)} AED</span></div>
                        </div>}
                    </td>}
                    {bookingDetails.match_making_users.map((mm, index) => {
                        return (
                            <React.Fragment>
                                <tr>
                                    <td>
                                        {current == (index + 2) ? <TfiArrowCircleDown size={22} onClick={() => handleDrop(index + 2)} style={{ cursor: 'pointer' }} /> : <TfiArrowCircleRight size={22} onClick={() => handleDrop(index + 2)} style={{ cursor: 'pointer' }} />}
                                    </td>
                                    <td>
                                        {index + 2}
                                    </td>
                                    <td>
                                        <img src={mm?.user?.profile_pic ? mm?.user?.profile_pic : "https://justpadel-courtimages.s3.amazonaws.com/default-profile-2.jpg" } alt='Profile Pic' style={{ height: 50, width: 50, borderRadius: 50 }} />
                                    </td>
                                    <td>
                                        {mm.user.first_name}
                                    </td>
                                    <td>
                                        {mm.user.email}
                                    </td>
                                    <td>
                                        {mm.position}
                                    </td>
                                </tr>
                                {current == (index + 2) && <td colSpan={9}>
                                    {mm.matchmaking_booked_addons.length > 0 ? <div className='px-5'>
                                        <h6>Booked Addons</h6>
                                        <Table className='w-100'>
                                            <thead>
                                                <th>Sr.No</th>
                                                <th>Name</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Amount</th>
                                            </thead>
                                            <tbody>
                                                {mm.matchmaking_booked_addons.map((addons, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{addons?.addonname}</td>
                                                            <td>{addons?.price} AED</td>
                                                            <td>{addons?.quantity}</td>
                                                            <td>{addons?.amount} AED</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div> : <div className='px-5'>
                                        <div className="no-addons">
                                            <h4>No Addons</h4>
                                        </div></div>}
                                    <div className='px-5 w-50'>
                                        <h4>Calculation</h4>
                                        <div className='d-flex justify-content-between'><span>Vat 5% : </span><span>{(PaymentCalc(bookingDetails.booked_slots, mm).vat).toFixed(2)} AED</span></div>
                                        <div className='d-flex justify-content-between'><span>Match Making Amount : </span><span>{(PaymentCalc(bookingDetails.booked_slots, mm).matchmaking_court_price).toFixed(2)} AED</span></div>
                                        <div className='d-flex justify-content-between'><span>Addon Amount : </span><span>{(PaymentCalc(bookingDetails.booked_slots, mm).addon_price).toFixed(2)} AED</span></div>
                                        <div className='d-flex justify-content-between'><span>Coupon Applied : </span><span>{mm?.coupon != 'null' ? mm?.coupon : "No"}</span></div>
                                        <div className='d-flex justify-content-between'><span>Coupon Discount : </span><span>{(PaymentCalc(bookingDetails.booked_slots, mm)?.discounted_amount).toFixed(2)} AED</span></div>
                                        <div className='d-flex justify-content-between'><span>Amount Paid : </span><span>{(mm?.amount_paid).toFixed(2)} AED</span></div>
                                        <div className='d-flex justify-content-between'><span>Total : </span><span>{(PaymentCalc(bookingDetails.booked_slots, mm)?.total).toFixed(2)} AED</span></div>
                                    </div>
                                </td>}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )
}

export default MatchMakingBookingCalculation