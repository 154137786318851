/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Card, CardBody, Spinner } from 'reactstrap';
import TotalProfitEarned from './components/TotalProfitEarned';
import TotalBookings from './components/TotalBookings';
import BookingCancels from './components/BookingCancels';
import Reservations from './components/Reservations';
import WeeklyStat from './components/WeeklyStat';
import Occupancy from './components/Occupancy';
import { RTLProps } from '../../shared/prop-types/ReducerProps';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { environment } from "../env";
import axios from '../../customAxios';

const ExamplePage = () => {
  const [reportsData, setReportsData] = useState([]);
  const [start, onChangeStart] = useState(new Date());
  const [end, onChangeEnd] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false)

  const reportUrl = `${environment.baseUrl}bookingcourtstats`;
  const [usersReportData, setUserReportData] = useState([]);
  const userReport = `${environment.baseUrl}users_reports`;

  const getUserReport = () => {
    const usersReportData = {
      start_date: moment(start).format('YYYY-MM-DD'),
      end_date: moment(end).format('YYYY-MM-DD'),
    }
    console.log(usersReportData);
    const result = axios.post(userReport, usersReportData)
      .then((response) => {
        console.log(response.data);
        setUserReportData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const getReport = () => {
    const reportData = {
      start_date: moment(start).format('YYYY-MM-DD'),
      end_date: moment(end).format('YYYY-MM-DD'),
      main_court_id: 0
    }
    console.log("Report Data", reportData);
    const result = axios.post(reportUrl, reportData)
      .then((response) => {
        console.log("Report Data", response.data);
        setReportsData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }


  const newGetBookingReportUrl = `${environment.baseUrl}userreport`;
  const newGetBookingReport = () => {
    setIsLoading(true)
    const reportData = {
      start_date: moment(start).format('YYYY-MM-DD'),
      end_date: moment(end).format('YYYY-MM-DD')
    }
    console.log("reportData", reportData);
    const result = axios.post(newGetBookingReportUrl, reportData)
      .then((response) => {
        console.log("Report Data", response.data.Analytics);
        setReportsData(response.data.Analytics);
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
      })
  }

  useEffect(() => {
    newGetBookingReport();
  }, [])
  const submitDate = () => {
    console.log("Start", moment(start).format('YYYY-MM-DD'));
    console.log("Ends", moment(end).format('YYYY-MM-DD'));
    newGetBookingReport();
  }


  const CreatedOn = () => {
    let newStart = moment(start).format('YYYY-MM-DD')
    let newEnd = moment(end).format('YYYY-MM-DD')
    const GetCreatedAtReportUrl = `${environment.baseUrl}report-created-at/${newStart}/${newEnd}`;
    axios({
      url: GetCreatedAtReportUrl,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ReportOnCreatedDate-${newStart}To${newEnd}.csv`);
      document.body.appendChild(link);
      link.click();
    });
  }

  const BookingOn = () => {
    let newStart = moment(start).format('YYYY-MM-DD')
    let newEnd = moment(end).format('YYYY-MM-DD')
    const GetCreatedAtReportUrl = `${environment.baseUrl}report-booking-on/${newStart}/${newEnd}`;
    axios({
      url: GetCreatedAtReportUrl,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ReportOnBookingDate-${newStart}To${newEnd}.csv`);
      document.body.appendChild(link);
      link.click();
    });

  }

  console.log("Response Data", reportsData);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={2}>
          <h3 className="page-title">Dashboard</h3>
        </Col>
      </Row>


      <Col md={12} lg={12}>
        {isLoading && (
          <div class="loader-div">
            <span class="loader">
              <div className="center-spinner">
                <Spinner className="table-fetch-spinner" />
              </div>
            </span>
          </div>
        )}
        <Card>
          <CardBody >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
              <div >
                <span className="page-title" style={{ fontSize: 16, marginRight: 10, color: 'gray' }}>Start Date</span>
                <DatePicker dateFormat="YYYY-MM-DD" onChange={onChangeStart} value={start} />
              </div>
              <div>
                <span className="page-title" style={{ fontSize: 16, marginRight: 10, color: 'gray' }}>End Date</span>
                <DatePicker dateFormat="YYYY-MM-DD" onChange={onChangeEnd} value={end} />
              </div>
              <div>
                <div className='justpadel-buttons-save' onClick={CreatedOn}>Created On</div>
              </div>
              <div>
                <div className='justpadel-buttons-save' onClick={BookingOn}>Booking On</div>
              </div>
              <div>
                <div onClick={submitDate} className="date-submit">Submit</div>
              </div>
            </div>
            {
              reportsData.map((data, index) => {
                return (
                  <>
                    <Row style={{ margin: "10px 0" }}>
                      <Col md={2}>
                        <h3 className="page-titles" style={{ marginTop: "10px" }}>{data.main_court__courtName}</h3>
                      </Col>
                    </Row>
                    <Row style={{ margin: "10px 0" }}>
                      <TotalProfitEarned profit={(data?.sales).toFixed(2)} />
                      <TotalBookings bookings={data?.totalBookings} />
                      <BookingCancels cancelledBookings={data?.cancelled_booking} />
                    </Row>

                  </>
                )
              })
            }
          </CardBody>
        </Card>
      </Col>
      <Row style={{ margin: "10px 0 20px 0", display: 'none' }}>
        <Reservations />
        <WeeklyStat />
        <Occupancy />
      </Row>
    </Container>
  );
};

// export default ExamplePage;
ExamplePage.propTypes = {
  rtl: RTLProps.isRequired,
};



export default ExamplePage;
