import React, { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row, Card, CardBody, Spinner,} from 'reactstrap';
import QuickPayModel from './Quickpay/QuickPayModel';
import ReactPaginate from "react-paginate";
import ReactTableBase from '../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../shared/components/table/components/ReactTableCustomizer';
import axios from '../customAxios';
import { environment } from './env';
import moment from 'moment';
import { AiFillEye } from 'react-icons/ai';
import { useForm } from "react-hook-form";



const KidsSummerCamp = () => {
  const [tournamentsPayments, setTournamentsPayments] = useState([]);
  const [transactionLog, setTransactionLog] = useState();
  const [view, setView] = useState(false);
  const [initialPage, setInitialPage] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [searchData, setSearchData] = useState(false);


  let limit;
  if (localStorage.getItem("TransactionListCurrentPageLimit") === null || undefined) {
    limit = 10;
  } else {
    limit = parseInt(localStorage.getItem("TransactionListCurrentPageLimit"))
  }

  const GetTournamentsPaymentDetails = (page, page_size) => {
    const GetTournamentsPaymentURL = `${environment.baseUrl}kids-summer-camp-filter/?page=${page}&page_size=${page_size}`;
    const result = axios.get(GetTournamentsPaymentURL).then((response) => {
      setTournamentsPayments(response.data.results);
      setpageCount(Math.ceil(response.data.count / limit));
    })
      .catch((error) => {
        return error;
      })
  }

  useEffect(() => {
    GetTournamentsPaymentDetails(1, 10);
  }, []);


  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);

  var jsonObj = [];
  for (let i = 0; i < tournamentsPayments?.length; i++) {
    var item = {}
    item["payment_id"] = <div style={{paddingLeft:10}}> {i + 1} </div>;

    item["name"] = <div className="bookingId right-space" >{tournamentsPayments[i]?.name}</div>;

    item["email"] = <div className="ceated-date right-space">{tournamentsPayments[i]?.email}</div>;

    item["age"] = <div className="ceated-date right-space">{tournamentsPayments[i]?.age}</div>;

    item["phone"] =
      <div className="right-space">
        <div className="userEmail">{tournamentsPayments[i]?.phone}</div>
      </div>;

    item["amount"] =
      <div className="right-space">
        <div className="amountPaid">{(tournamentsPayments[i]?.amount) + "AED"}
          <span className="amountPaidPayform"> / payfort</span>
        </div>
      </div>;

    item["payment_status"] =
      <div className="right-space">
        <div className="right-space text-capitalize">{tournamentsPayments[i].payment_status === "completed" ? <h3 className='amountStatusCompleted'>Completed</h3> : <h3 className='amountStatusCancelled mt-1'>Pending</h3>}</div>
      </div>;

    item["created_date"] =
      <div className="right-space">
        <div className="payment-status">{moment(tournamentsPayments[i]?.created_date.split('T')[0]).format("DD-MM-YYYY")}<br />
          {moment(tournamentsPayments[i]?.created_date.split('T')[1].split('.')[0], "HH:mm:ss").format("hh:mm A")}
        </div>
      </div>;

    item["transaction_log"] =
      <div className="right-space">
            <button  className='modal-view-btn' onClick={() => OpenModal(tournamentsPayments[i]?.transaction_log)}>
                <AiFillEye style={{ fontSize: 20,  height: "19px", width: "19px" }} />
            </button>
      </div>;

    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: 'Id',
        accessor: "id",
        disableGlobalFilter: true,
        width: 60,
      },
      {
        Header: 'Id',
        accessor: "payment_id",
        disableGlobalFilter: true,
        width: 60,
      },
      {
        Header: 'Name',
        accessor: 'name',
        disableGlobalFilter: true,
        width: 40,
      },
      {
        Header: 'Email',
        accessor: "email",
        disableGlobalFilter: true,
        width: 160,
      },
      {
        Header: 'Phone No',
        accessor: "phone",
        disableGlobalFilter: true,
        width: 160,
      },
      {
        Header: 'Age',
        accessor: 'age',
        disableGlobalFilter: true,
      },
      {
        Header: 'Amount',
        accessor: "amount",
        disableGlobalFilter: true,
        width: 110,
      },
      {
        Header: 'Date',
        accessor: "created_date",
        disableGlobalFilter: true,
        width: 110,
      },
      {
        Header: 'Payment Status',
        accessor: 'payment_status',
        disableGlobalFilter: true,
        width: 120,
      },
      {
        Header: 'Actions',
        accessor: 'transaction_log',
        disableGlobalFilter: true,
        width: 120
      },
    ],
    rows: jsonObj
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10000, 20000, 30000, 40000],
    placeholder: 'Search by Name...',
  };


  const handlePageClick = async (data) => {
    setSpinner(true);
    setTournamentsPayments([]);
    let currentPage = data.selected + 1;
    setInitialPage(data.selected);
    let newlimit = parseInt(localStorage.getItem("TransactionListCurrentPageLimit"));
    localStorage.setItem("TransactionListCurrentPage", currentPage);
    if (!searchData) {
      setSpinner(false);
      await GetTournamentsPaymentDetails(currentPage, newlimit);
    } else {
      let data = JSON.parse(localStorage.getItem("transactionSearchdata"));
      const result = axios(`${environment.baseUrl}kids-summer-camp-filter/?created_date_after=${data.created_at_after}&created_date_before=${data.created_at_before}&first_name=${data.name}&email=${data.email}&phone_no=${data.phonenumber}&age=${data.age}&payment_status=${data.payment_status}&amount=&page=${currentPage}&page_size=${newlimit}`)
        .then((response) => {
          if (response.data.count > 0) {
            setTournamentsPayments(response.data.results);
            setSpinner(false);
            setpageCount(Math.ceil(response.data.count / limit));
          } else {
            setSpinner(false);
            alert("Data is not available for search");
          }
        })
    }
  };

  const handleResetSearchForm = () => {
    reset({});
  }

  const clear = (event) => {
    event.target.value = "";
  };

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    const SearchUrl = `${environment.baseUrl}kids-summer-camp-filter/?created_date_after=${data.created_at_after}&created_date_before=${data.created_at_before}&first_name=${data.name}&email=${data.email}&phone_no=${data.phonenumber}&age=${data.age}&payment_status=${data.payment_status}&amount=`
   
    const result = axios.get(SearchUrl)
      .then((response) => {
        console.log("Response", response);
        setTournamentsPayments([]);
        setInitialPage(0);
        if (response.data.count > 0) {
          setSpinner(false);
          setTournamentsPayments(response.data.results);
          setpageCount(Math.ceil(response.data.count / limit));
        } else {
          setSpinner(false);
          setTournamentsPayments([]);
          setpageCount(Math.ceil(response.data.count / limit));
        }
      });
  };

  async function handleLimitChange(e) {
    localStorage.setItem("TransactionListCurrentPageLimit", e.target.value);
    setInitialPage(0);
  };

  const OpenModal = (transactionLogData) => {
    setView();
    setTransactionLog();
    setView(!view);
    if (transactionLogData !== "NO Transacation Log") {
      setTransactionLog(JSON.parse(transactionLogData)[0]);
    } else {
      setTransactionLog({ "Data": "No Transaction log" })
    }
  }

  const CloseModal = () => {
    setView(false);
  }

  return (
    <Fragment>
      <Row>
          <Col md={12}>
            <h3 className="page-title">Kids Summer Camp</h3>
          </Col>
        </Row>
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <QuickPayModel transactionLog={transactionLog} show={view} CloseModal={CloseModal} color="primary" header title="Tournaments" />
          <div className="react-table__wrapper hide-table-costomizer">
            <div className="card__title bookings">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="filter-fields">
                  <input type="number" className="filter-code search-booking-id" name="booking_id" placeholder="Id" {...register("booking_id")} />
                  <div className='filter-fields payment-dates'>
                    <span className='booking-date'>Payment Date</span>
                    <input type="date" name="created_at_after" className="filter-payment-date" {...register("created_at_after")} />
                    <input type="date" name="created_at_before" className="filter-payment-date" {...register("created_at_before")} />
                  </div>
                  <div className='filter-fields email-name-feilds'>
                    <input name="first_name" id="first_name" className="filter-name" placeholder="First Name" {...register("first_name")} />
                    <input name="last_name" id="last_name" className="filter-name" placeholder="Last Name" {...register("last_name")} />
                  </div>
                  {/* <div className='filter-fields email-name-feilds'>
                    <input name="last_name" id="last_name" className="filter-name" placeholder="Last Name" {...register("last_name")} />
                  </div> */}
                  <div className='filter-fields email-name-feilds'>
                    <input name="email" id="email" className="filter-client-email" placeholder="Email" {...register("email")} />
                  </div>
                  <div className='filter-fields email-name-feilds' >
                    <select {...register("payment_status")} className="filter-client-email-select-option">
                      <option value="">Payment Status</option>
                      <option value="completed">Completed</option>
                      <option value="pending">Pending</option>
                    </select>
                  </div>
                  <input list="phonenumber" name="phonenumber" id="phonenumber" className="filter-Phone-number" placeholder="Phone Number" {...register("phonenumber")} onClick={clear} />
                  <div className="btn-container">
                    <div className="clear-btn-trans" onClick={() => handleResetSearchForm()} >Clear</div>
                    <button type="submit" id="submit" className="apply-btn-trans">Apply</button>
                    <div className="center-spinner">
                    </div>
                  </div>
                </div>
              </form>
              <div className="center-spinner">
                {spinner ? <Spinner className="table-fetch-spinner" /> : ''}
              </div>
            </div>


            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withSearchEngine={withSearchEngine}
            />
          </div>

          <div className="table-id-desabled pagination-hide hover-effect hide-filtername table-top-space second-child-space">
            <ReactTableBase
              id="table-to-xls"
              key={withSearchEngine ? 'searchable' : 'common'}
              columns={data.columns}
              data={data.rows}
              tableConfig={tableConfig}
            />
          </div>

          {/* ----------------------------------------------------------------------  New Pagination code starts from here ---------------------------------------------- */}
          <div className="custom-pagination">
            <div className="records-per-page">
              <select value={localStorage.getItem("TransactionListCurrentPageLimit")} onChange={handleLimitChange} name="limit" id="select-pagination">
                {mannualLimits && mannualLimits.map((mannualLimits) => (
                  <option value={mannualLimits}>
                    {mannualLimits}
                  </option>
                ))}
              </select>&nbsp;&nbsp;&nbsp;records per page
            </div>
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={initialPage}
            />
          </div>
          {/* ----------------------------------------------------------------------  New Pagination code ends from here ---------------------------------------------- */}

        </CardBody>
      </Card>
    </Col>
    </Fragment>
  )
}

export default KidsSummerCamp