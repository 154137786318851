import React from "react";
import { Table } from "reactstrap";

const ClientDetails = ({ clientDetails }) => {
    
  return (
    <>
      <h2 className="client-info">Client Info</h2>
      <Table responsive className="client-tab-data">
        <tbody className="table-user-details">
          <tr>
            <td>
              <h5>Client Name</h5>
            </td>
            <td className="client-name">
              {clientDetails.first_name === "null"
                ? " "
                : clientDetails.first_name}{" "}
              {clientDetails.last_name === "null"
                ? " "
                : clientDetails.last_name}
            </td>
          </tr>
          <tr>
            <td>
              <h5>Email</h5>
            </td>
            <td className="client-email">
              {clientDetails.email === "null"
                ? " "
                : clientDetails.email}
            </td>
          </tr>
          <tr>
            <td>
              <h5>Phone</h5>
            </td>
            <td className="client-Ph-no">
              {clientDetails.phone_no === "null"
                ? " "
                : clientDetails.phone_no}
            </td>
          </tr>
          <tr>
            <td>
              <h5>No. of Games Played</h5>
            </td>
            <td className="client-Ph-no">
              {clientDetails.no_of_games === "null"
                ? " "
                : clientDetails.no_of_games}
            </td>
          </tr>
          <tr>
            <td>
              <h5>Padelholic Level</h5>
            </td>
            {/* <td className="client-Ph-no">{padelholicLevels?.level}</td> */}
          </tr>
          <tr>
            <td>
              <h5>Address</h5>
            </td>
            <td className="client-Ph-no">
              {clientDetails.address === "null"
                ? " "
                : clientDetails.address}
            </td>
          </tr>
          <tr>
            <td>
              <h5>Gender</h5>
            </td>
            <td className="client-Ph-no">
              {clientDetails.gender === "null"
                ? " "
                : clientDetails.gender}
            </td>
          </tr>
          <tr>
            <td>
              <h5>City</h5>
            </td>
            <td className="client-Ph-no">
              {clientDetails.city === "null"
                ? " "
                : clientDetails.city}
            </td>
          </tr>
          <tr>
            <td>
              <h5>State</h5>
            </td>
            <td className="client-Ph-no">
              {clientDetails.state === "null"
                ? " "
                : clientDetails.state}
            </td>
          </tr>
          <tr>
            <td>
              <h5>Country</h5>
            </td>
            <td className="client-Ph-no">
              {clientDetails.country === "null"
                ? " "
                : clientDetails.country}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ClientDetails;
