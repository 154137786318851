import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Button } from "reactstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "../../../customAxios";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import { environment } from "../../env";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../../shared/components/table/components/ReactTableCustomizer";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import parse from "html-react-parser";

const OfferList = () => {
  const [offer, setOffer] = useState([]);
  const [userRole, setUserRole] = useState();
  const [role, setRole] = useState();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  let history = useHistory();
  const getOfferURL = `${environment.baseUrl}getoffer`;
  const getOffer = () => {
    const result = axios
      .get(getOfferURL)
      .then((response) => {
        console.log("getoffer", response.data);
        setOffer(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRole = () => {
    const localrole = localStorage.getItem("Role");
    const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localrole}`;

    const result = axios.get(getRoleUrl).then((response) => {
      setRole(response.data);
      setPermissions(response.data);
    });
  };

  const setPermissions = (roledata) => {
    // const localrole = "admin";
    const localrole = localStorage.getItem("Role");
    let newRoleId;

    if (localrole === roledata.role_name) {
      let permission = JSON.parse(roledata.permissions);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "manual_bookings";
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
      console.log("false");
    }
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
  };

  useEffect(() => {
    getRole();
    let localrole = localStorage.getItem("Role");
    setUserRole(localStorage.getItem("Role"));
    getOffer();
  }, []);

  var jsonObj = [];
  for (let i = 0; i < offer.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["offer"] = <div className="right-space">{offer[i].offer}</div>;
    item["description"] = (
      <div className="description offer-list">{parse(offer[i].description)}</div>
    );
    if (deleteing === true) {
      item["link"] = (
        <div className="right-space">
          <div
            className="modal-edit-btn"
            onClick={() => handleEdit(offer[i].id)}
          >
            <AiOutlineEdit style={{ fontSize: 20 }} />
          </div>
        </div>
      );
      item["delete"] = (
        <div className="right-space">
          <div
            className="modal-delete-btn"
            onClick={() => handleDelete(offer[i].id)}
          >
            <AiOutlineDelete style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    } else if (editing === true) {
      item["link"] = (
        <div className="right-space">
          <div
            className="modal-edit-btn"
            onClick={() => handleEdit(offer[i].id)}
          >
            <AiOutlineEdit style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    }
    jsonObj.push(item);
  }

  const handleEdit = (id) => {
    history.push(`/edit-offer/${id}`);
  };

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Offer",
        accessor: "offer",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Description",
        accessor: "description",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 50,
      },
    ],
    rows: jsonObj,
  };
  const handleDelete = (id) => {
    const deleteOfferURL = `${environment.baseUrl}deleteoffer/${id}/`;
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const result = axios.delete(deleteOfferURL).then((response) => {
          setOffer(() => offer.filter((data) => data.id !== id));
        });
        swal("Your offer details has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your offer details is safe!");
      }
    });
  };

  const handleCreateOffer = () => {
    history.push("/add-offer");
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by offer...",
  };
  return (
    <>
      <Col
        md={12}
        lg={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <div
          className="justpadel-buttons btn-position"
          onClick={() => handleCreateOffer()}
        >
          Add offer
        </div>
      </Col>

      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="react-table__wrapper hide-table-costomizer">
              <ReactTableCustomizer
                handleClickIsSortable={handleClickIsSortable}
                handleClickWithPagination={handleClickWithPagination}
                handleClickWithSearchEngine={handleClickWithSearchEngine}
                handleClickIsResizable={handleClickIsResizable}
                isResizable={isResizable}
                isSortable={isSortable}
                withPagination={withPagination}
                withSearchEngine={withSearchEngine}
              />
            </div>

            <div className="hover-effect table-id-width">
              <ReactTableBase
                key={withSearchEngine ? "searchable" : "common"}
                columns={data.columns}
                data={data.rows}
                tableConfig={tableConfig}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default OfferList;
