import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';

const ExampleCard = () => (
  <Col md={12}>
    <Card>
      <CardBody>
        <div className="card__title">
        </div>
        <p>Your content here</p>
      </CardBody>
    </Card>
  </Col>
);

export default ExampleCard;
