import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
    Col, Button, ButtonToolbar, Card, CardBody
} from 'reactstrap';
import axios from '../../customAxios';
import { environment } from '../env';
import swal from "sweetalert";



const User = () => {
    const [roleResponse, setRoleResponse] = useState([]);

    const { register, handleSubmit, reset, formState } = useForm({ mode: 'onChange' });
    const { errors, } = formState;
    const onSubmit = (data) => {
        const resetPassword = {
            email: data.email,
            password: data.password
        }
        const UpdateUserUrl= `${environment.baseUrl}update_user_details/${data.email}/`;
        const resetPasswordUrl = `${environment.baseUrl}resetpassword`;

        const result = axios.put(UpdateUserUrl, data).then((response)=>{
            const newResult = axios.post(resetPasswordUrl, resetPassword).then((responseResetPassword)=>{
                console.log("responseResetPassword",responseResetPassword);
                if(responseResetPassword.data.Message === `Password is Updated Successfully of user ${data.email}`){
                  swal({
                    title: "Success",
                    text: "Details Updated Successfully!",
                    icon: "success",
                    button: "Close",
                  });
                  reset('');
                }
                
            })

        })
    }

    const getRoles = () => {
        const getRoleUrl = `${environment.baseUrl}role`;
        const userRoles = axios
            .get(getRoleUrl)
            .then((response) => {
                setRoleResponse(response.data);
            })
            .catch((err) => {
                console.log("Error" + err);
            });
    };

    useEffect(() => {
        getRoles();
    }, [])



    return (
        <div>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <form className="form product-edit" style={{ display: "flex", flexDirection: "column" }} onSubmit={handleSubmit(onSubmit)} >

                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Email</span>
                                    <div className="form__form-group-field">
                                        <input type="email" name='email' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("email", { required: true })} />
                                    </div>
                                    {errors.email && <span className="error-msg">Please Enter Valid Email</span>}

                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>First name</span>
                                    <div className="form__form-group-field">
                                        <input type="text" name='first_name' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("first_name", { required: true })} />
                                    </div>
                                    {errors.first_name && <span className="error-msg">Please Enter First name</span>}

                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Last name</span>
                                    <div className="form__form-group-field">
                                        <input type="text" name='last_name' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("last_name", { required: true })} />
                                    </div>
                                    {errors.last_name && <span className="error-msg">Please Enter Last name</span>}
                                </div>
                            </div>
                            <div className="form__half" style={{ paddingTop: 20 }} >
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Password</span>
                                    <div className="form__form-group-field">
                                        <input type="password" name='password' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("password", { required: true })} />
                                    </div>
                                    {errors.password && <span className="error-msg">Please Enter Password</span>}

                                </div>
                            </div>

                            <div className="form__half" style={{ paddingTop: 20 }}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Role</span>
                                    <div className="form__form-group-field">
                                        <select
                                            className="form__form-group-fields select"
                                            name='role'
                                            {...register("role", { required: true })}
                                        >
                                            <option value="">Select Role</option>
                                            {
                                                roleResponse.map((data, key) => {
                                                    return (
                                                        <option value={data.role_name}>{data.role_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    {errors.role && <span className="error-msg">Please select role</span>}
                                </div>
                            </div>
                            <Col md={6} lg={6}>
                                <Card style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", marginTop: 30, }}>
                                    <div className="form__half" style={{ marginRight: 0 }}>
                                        <ButtonToolbar className="form__button-toolbar" >
                                            <button className='justpadel-buttons-save'
                                            // onClick={handleCustomerDetails}
                                            >
                                                Submit
                                            </button>
                                            <div className='justpadel-buttons-cancel'
                                            //  onClick={reset}
                                            >
                                                Reset
                                            </div>
                                        </ButtonToolbar>
                                    </div>
                                </Card>
                            </Col>

                        </form>
                    </CardBody>
                </Card>
            </Col>
        </div>
    )
}

export default User;