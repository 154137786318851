import React from 'react';
import { ButtonGroup, Col, Container, Row, Button } from 'reactstrap';
import Promotions from './components/Promotions';

const ExamplePage = () => {

 

  return (
    <Container className="dashboard">
      <Row>
        <Col md={6}>
          <h3 className="page-title">Promotions List</h3>
        </Col>
      </Row>
      <Row>
        <Promotions />
      </Row>
    </Container>
  )
};

export default ExamplePage;
