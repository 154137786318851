import React from "react";
import { Col, Container, Row } from "reactstrap";
import { GetAvailableSlotsCard } from "./GetAvailableSlotsCard";

export const GetAvailableSlots = () => {
  return (
    <Container>
      <Row md={12}>
        <Col>
          <h3 className="page-title">Get AvailableSlots</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <GetAvailableSlotsCard />
        </Col>
      </Row>
    </Container>
  );
};
