// import React, { useState } from 'react';
// import { Field, reduxForm, Form } from 'redux-form';
// import EyeIcon from 'mdi-react/EyeIcon';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import { NavLink } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import { Alert, Button } from 'reactstrap';
// // import renderCheckBoxField from '../form/CheckBox';


// const LogInForm = ({
//   handleSubmit, errorMessage, errorMsg, fieldUser, typeFieldUser, form,
// }) => {
//   const [showPassword, setShowPassword] = useState(false);


//   const showPasswordToggle = () => {
//     setShowPassword(!showPassword);
//   };
//   return (
//     <Form className="form login-form" onSubmit={handleSubmit}>

//       <Alert
//         color="danger"
//         isOpen={!!errorMessage || !!errorMsg}
//       >
//         {errorMessage}
//         {errorMsg}
//       </Alert>
//       <div className="form__form-group">
//         <span className="form__form-group-label">{fieldUser}</span>
//         <div className="form__form-group-field">
//           <div className="form__form-group-icon">
//             <AccountOutlineIcon />
//           </div>
//           <Field
//             name="username"
//             component="input"
//             type={typeFieldUser}
//             placeholder={fieldUser}
//             className="input-without-border-radius"
//           />
//         </div>
//       </div>
//       <div className="form__form-group">
//         <span className="form__form-group-label">Password</span>
//         <div className="form__form-group-field">
//           <div className="form__form-group-icon">
//             <KeyVariantIcon />
//           </div>
//           <Field
//             name="password"
//             component="input"
//             type={showPassword ? 'text' : 'password'}
//             placeholder="Password"
//             className="input-without-border-radius"
//           />
//           <button
//             type="button"
//             className={`form__form-group-button${showPassword ? ' active' : ''}`}
//             onClick={showPasswordToggle}
//           ><EyeIcon />
//           </button>
//           <div className="account__forgot-password">
//             <NavLink to="/reset_password">Forgot a password?</NavLink>
//           </div>
//         </div>
//       </div>
//       {/* <div className="form__form-group">
//         <div className="form__form-group form__form-group-field">
//           <Field
//             name={`remember_me-${form}`}
//             component={renderCheckBoxField}
//             label="Remember me"
//           />
//         </div>
//       </div> */}
//       <div className="account__btns">
//         {
//           form === 'modal_login'
//             ? <Button className="account__btn" type="submit" color="primary">Sign In</Button>
//             : (
//                 <Button className="account__btn" type="submit" color="primary">Sign In</Button>
//             )
//         }

//         <NavLink className="btn btn-outline-primary account__btn" to="/register">Create
//           Account
//         </NavLink>
//       </div>
//     </Form>
//   );
// };

// LogInForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
//   errorMessage: PropTypes.string,
//   errorMsg: PropTypes.string,
//   fieldUser: PropTypes.string,
//   typeFieldUser: PropTypes.string,
//   form: PropTypes.string.isRequired,
// };

// LogInForm.defaultProps = {
//   errorMessage: '',
//   errorMsg: '',
//   fieldUser: 'Username',
//   typeFieldUser: 'text',
// };

// // export default connect(state => ({
// //   errorMsg: state.user.error,
// // }))(reduxForm()(LogInForm));

// export default reduxForm({
//     form: 'LogInForm' // a unique identifier for this form
// })(LogInForm);


import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import {
    Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import EyeIcon from 'mdi-react/EyeIcon';
import renderSelectField from '../../shared/components/form/Select';
import validate from './validate';
import { GetMainCourt, GetPadelCourt } from "../Reuse/ApiCalls";

const renderField = ({
    input, placeholder, type,values, meta: { touched, error },
}) => (
    <div className="form__form-group-input-wrap">
        <input {...input} placeholder={placeholder} type={type} value={values} />
        {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
);

renderField.propTypes = {
    input: PropTypes.shape().isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
};

renderField.defaultProps = {
    placeholder: '',
    meta: null,
    type: 'text',
};

const LogInForm = ({
    handleSubmit, pristine, reset, submitting,
}) => {

    const [times, setTimes] = useState(['06:00 AM', '06:45 AM', '07:30 AM', '08:15 AM',
        '09:00 AM', '09:45 AM', '10:30 AM', '11:15 AM', '12:00 PM', '12:45 PM', '01:30 PM',
        '02:15 PM', '03:00 PM', '03:45 PM', '04:30 PM', '05:15 PM', '06:00 PM', '06:45 PM',
        '07:30 PM', '08:15 PM', '09:00 PM', '09:45 PM', '10:30 PM', '11:15 PM', '12:00 AM',
        '12:45 AM', '01:30 AM', '02:15 AM']);
    const [timeSlots, setTimeSlots] = useState([]);
    const [mainCourtList, setMainCourtList] = useState([]);
    const [padelCourtList, setPadelCourtList] = useState([]);

    useEffect(() => {
        const timeslot = [];
        times.map((data) => {
            timeslot.push({ value: data, label: data });
        });
        setTimeSlots(timeslot);
        GetMainCourt().then((response) => {
            const mainCourt = [];
            response.map((data) => {
                mainCourt.push({ value: data.id, label: data.courtName });
            });
            setMainCourtList(mainCourt);
        })
    }, []);

    const handleMainCourt = (value) => {
        GetPadelCourt().then((response) => {
            const padelCourt = [];
            let padelCourts = response;
            let padelCourtFilter = padelCourts.filter(function (padelCourts) { return padelCourts.maincourt.id === value.value });
            padelCourtFilter.map((data) => {
                padelCourt.push({ value: data.id, label: data.courtname });
            });
            setPadelCourtList(padelCourt);
        })
    };




    return (
        <Col md={12} lg={12} xl={12}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        {/* <h5 className="bold-text">Bulk Booking Form</h5> */}
                    </div>
                    {/* form--vertical */}
                    <form className="form" onSubmit={handleSubmit}>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label">Start Date</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="startdate"
                                        component={renderField}
                                        type="date"
                                        
                                    />

                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">End Date</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="enddate"
                                        component={renderField}
                                        type="date"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label">Start Time</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="starttime"
                                        component={renderSelectField}
                                        type="text"
                                        options={timeSlots}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">End Time</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="endtime"
                                        component={renderSelectField}
                                        type="text"
                                        options={timeSlots}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label">Main Court</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="maincourt"
                                        component={renderSelectField}
                                        type="text"
                                        options={mainCourtList}
                                        onChange={(mainCourtList) => handleMainCourt(mainCourtList)}
                                        
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Padel Court</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="padelcourt"
                                        component={renderSelectField}
                                        type="text"
                                        options={padelCourtList}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label">Customer Name</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="customername"
                                        component={renderField}
                                        type="text"
                                        
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Customer Phone No</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="customerphoneno"
                                        component={renderField}
                                        type="number"
                                        
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label">Customer Email</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="customeremail"
                                        component={renderField}
                                        type="email"
                                        
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Amount paid by </span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="amountpaidby"
                                        component={renderSelectField}
                                        type="text"
                                        options={[
                                            { value: 'cash', label: 'Cash' },
                                            { value: 'card', label: 'Card' },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label">Comments</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="comments"
                                        component={renderField}
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label">Amount paid</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name="amountpaid"
                                        component={renderField}
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form__half">
                            <ButtonToolbar className="form__button-toolbar">
                                <Button color="primary" type="submit">Submit</Button>
                                <Button type="button" onClick={reset} disabled={pristine || submitting}>
                                    Cancel
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </Col>
    );
};

LogInForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    formdata: PropTypes.array
};

export default reduxForm({
    form: 'horizontal_form_validation', // a unique identifier for this form
    validate,
})(LogInForm);
