import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Card, CardBody, Spinner } from "reactstrap";
import { AiOutlineDelete } from "react-icons/ai";
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { environment } from '../env';
import axios from "../../customAxios";
import moment from "moment";
import LivePostClientDetailsModel from "./LivePostClientDetailsModel";
import swal from 'sweetalert';
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { useHistory, Link } from "react-router-dom";

const LivePost = () => {

  const [livePostData, setLivePostData] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [pageCount, setpageCount] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [search, setSearch] = useState(false);
  const [filterQueryValue, setFilterQueryValue] = useState();

  let limit = parseInt(localStorage.getItem("LivePostCurrentPageLimit"));
  const history = useHistory();

  const GetReportData = (page, pageSize) => {
    const UserLivePostURL = `${environment.baseUrl}padelgram/padelgram-all-post/?page=${page}&page_size=${pageSize}`;
    const result = axios.get(UserLivePostURL).then((response) => {
      setLivePostData(response.data.results);
      let limit = parseInt(localStorage.getItem("LivePostCurrentPageLimit"));
      setpageCount(Math.ceil(response?.data?.count / limit));
    });
  };


  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  var jsonObj = [];
  for (let i = 0; i < livePostData.length; i++) {
    console.log("livePostData no_of_likes", livePostData[i].no_of_likes);
    var item = {};
    item["id"] = i + 1;
    {
      (livePostData[i]?.padelgram_image).includes("mp4") || (livePostData[i]?.padelgram_image).includes("mov") ?
        item["image"] = <div style={{ paddingLeft: 10 }}><video style={{ width: '75%', borderRadius: 5 }} preload="true" controls src={livePostData[i]?.padelgram_image}></video>
        </div>
        : item["image"] = <div style={{ paddingLeft: 10, }}><img src={livePostData[i]?.padelgram_image}
          alt={livePostData[i]?.padelgram_image}
          style={{ width: '75%', borderRadius: 5 }} />
        </div>
    }
    item["created_datetime"] = <div className="Booking-details-td-right-space">
      {moment(livePostData[i]?.created_time?.split("T")[0]).format("DD-MM-YYYY")} {" "}
      {moment(livePostData[i]?.created_time?.split("T")[1], "HH:mm:ss").format("hh:mm A")}
    </div>;
    item["caption"] = livePostData[i]?.padelgram_image_comment.toString();
    item["location"] = livePostData[i]?.location.toString();
    item["visibility"] = livePostData[i]?.post_type.toString();
    item["client"] = <div>{livePostData[i]?.user?.first_name === "null" ? "" : livePostData[i]?.user?.first_name}{" "}{livePostData[i]?.user?.last_name === "null" ? "" : livePostData[i]?.user?.last_name}</div>
    item["likes"] = livePostData[i]?.no_of_likes.toString();
    item["view"] = <LivePostClientDetailsModel color="primary" header title="Client Details" btn="view" clientDetail={livePostData[i]} postId={livePostData[i].id} />;
    item["delete"] = <div className="right-space" onClick={() => DeletePost(livePostData[i]?.id)}><div className="modal-delete-btn"><AiOutlineDelete style={{ fontSize: 20 }} /></div></div>;
    jsonObj.push(item);
  }

  const DeletePost = (postId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this post again!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          const DeletePostURL = `${environment.baseUrl}padelgram/padelgram-delete-post/${postId}`;
          axios.delete(DeletePostURL).then((deletedPostResponse) => {
            if (deletedPostResponse.data.msg === "data delete successfully") {
              setLivePostData(livePostData.filter(livepost => livepost.id !== postId));
              swal("This post has been deleted", {
                icon: "success",
              });
            }
          })
        } else {
          swal("This post is safe!");
        }
      });
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Image",
        accessor: "image",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Created Date & Time",
        accessor: "created_datetime",
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: "Caption",
        accessor: "caption",
        disableGlobalFilter: false,
        width: 60,
      },
      {
        Header: "Location",
        accessor: "location",
        disableGlobalFilter: false,
        width: 60,
      },
      {
        Header: "Visibility",
        accessor: "visibility",
        disableGlobalFilter: false,
        width: 50,
      },
      {
        Header: 'Client',
        accessor: 'client',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 80,
      },
      {
        Header: 'No. Likes',
        accessor: 'likes',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 20,
      },
      {
        Header: 'View',
        accessor: 'view',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 20,
      },
      {
        Header: 'Delete',
        accessor: 'delete',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      }
    ],
    rows: jsonObj,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [100, 200, 300, 400],
    placeholder: "Search by Name and Slots...",
  };

  const handlePageClick = async (data) => {
    if (!search) {
      setSpinner(true);
      let currentPage = data.selected + 1;
      setInitialPage(data.selected);
      let newlimit = parseInt(
        localStorage.getItem("LivePostCurrentPageLimit")
      );
      localStorage.setItem("LivePostCurrentPage", currentPage);
      GetReportData(currentPage, newlimit);
    } else {
      let currentPage = data.selected + 1;
      const FilterUrl = `${environment.baseUrl}padelgram/all-user-post/?page=${currentPage}&search=${filterQueryValue}`;
      axios.get(FilterUrl).then((filterResponse) => {
        setLivePostData(filterResponse.data.results);
        let limit = parseInt(localStorage.getItem("LivePostCurrentPageLimit"));
        setpageCount(Math.ceil(filterResponse?.data?.count / limit));
      })
    }

  };


  async function handleLimitChange(e) {
    localStorage.setItem("LivePostCurrentPageLimit", e.target.value);
    setInitialPage(0);
    GetReportData(1, e.target.value);
  };

  const debounce = useCallback(
    _.debounce((_searchVal) => {
      setSearch(true);
      FilterPost(_searchVal);
    }, 500),
    []
  );

  const onSubmit = (data) => {
    if (data.length > 2) {
      setFilterQueryValue(data);
      debounce(data);
    }
    setSearch(false);
    GetReportData(1, 10);
  };

  const FilterPost = (queryValue) => {
    const FilterUrl = `${environment.baseUrl}padelgram/all-user-post/?search=${queryValue}`;
    axios.get(FilterUrl).then((filterResponse) => {
      setLivePostData(filterResponse.data.results);
      let limit = parseInt(localStorage.getItem("LivePostCurrentPageLimit"));
      setpageCount(Math.ceil(filterResponse?.data?.count / limit));
    })
  }


  useEffect(() => {
    let newlimit = parseInt(localStorage.getItem("LivePostCurrentPageLimit"));
    GetReportData(1, newlimit);
  }, []);

  const GotoUploadPost = () => {
    history.push("/upload-post")
  }

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Live Post</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <Row>
                  <Col md={6}>
                    <div className="card__title bookings">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="filter-fields">
                          <input
                            type="text"
                            name="query"
                            placeholder="Search by Name, Email, Phone No, Date ....."
                            style={{ width: "100%" }}
                            {...register("query")}
                            onChange={(e) => onSubmit(e.target.value)}
                          />
                        </div>
                      </form>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="cal-top-submit-btn justify-content-end">
                      <button
                        color="warning"
                        size="sm"
                        onClick={() => GotoUploadPost()}
                        className={"date-submit"}>
                        <p style={{ color: "#fff" }}>Upload Post</p>
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="react-table__wrapper hide-table-costomizer">
                <ReactTableCustomizer
                  handleClickIsSortable={handleClickIsSortable}
                  handleClickWithPagination={handleClickWithPagination}
                  handleClickWithSearchEngine={handleClickWithSearchEngine}
                  handleClickIsResizable={handleClickIsResizable}
                  isResizable={isResizable}
                  isSortable={isSortable}
                  withPagination={withPagination}
                  withSearchEngine={withSearchEngine}
                />
              </div>
              <div className="table-id-desabled pagination-hide hide-filtername hover-effect hide-table-costomizer second-child-space">
                <ReactTableBase
                  key={withSearchEngine ? "searchable" : "common"}
                  columns={data.columns}
                  data={data.rows}
                  tableConfig={tableConfig}
                />
              </div>

              <div className="custom-pagination">
                <div className="records-per-page">
                  <select
                    value={localStorage.getItem("LivePostCurrentPageLimit")}
                    onChange={handleLimitChange}
                    name="limit"
                    id="select-pagination"
                  >
                    {mannualLimits &&
                      mannualLimits.map((mannualLimits) => (
                        <option value={mannualLimits}>{mannualLimits}</option>
                      ))}
                  </select>
                  &nbsp;&nbsp;&nbsp;records per page
                </div>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={initialPage}
                />
              </div>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default LivePost