import { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import JoditEditor from "jodit-react";
import Select from "react-select";
import axios from "../../customAxios";
import swal from "sweetalert";
import S3FileUpload from "react-s3";
import { environment } from "../env";
import { useHistory } from "react-router-dom";

const Coaches = () => {
  const editor = useRef(null);
  const [pageContent, setPageContent] = useState();
  const [mainCourtList, setMainCourtList] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const history = useHistory();
  const config = {
    bucketName: "justpadel-courtimages",
    region: "us-east-1",
    accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
    secretAccessKey: "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",
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const ImageUrls = register("image", { required: true });

  const Languages = [
    { value: 'english', label: 'English' },
    { value: 'arabic', label: 'Arabic' },
    { value: 'spanish', label: 'Spanish' },
    { value: 'portugese', label: 'Portugese' },
    { value: 'hindi', label: 'Hindi' },
  ]
  const Nationality = [
    { value: "uae", label: "UAE" },
    { value: "india", label: "India" },
    { value: "sri lanka", label: "Sri Lanka" },
    { value: "kenya", label: "Kenya" },
    { value: "ghana", label: "Ghana" },
    { value: "philipines", label: "Philipines" },
    { value: "argentina", label: "Argentina" },
    { value: "sudan", label: "Sudan" },
  ]

  const ImageUpload = (e) => {
    setImageUrl(e?.target?.files[0]);
  }

  const onSubmit = async (data) => {

    // console.log("data------------------>", data)

    // -------------------Image Upload in S3 Bucket start's here-----------------------------

    if (imageUrl) {
      setButtonDisabled(true);
      await S3FileUpload.uploadFile(imageUrl, config)
        .then((s3Response) => {
          // console.log("s3Response?.location-----------------", s3Response?.location);

          SubmitData(s3Response?.location, data);

        })
        .catch((err) => {
          setButtonDisabled(false);
        });
    }

    // -------------------Image Upload in S3 Bucket start's here-----------------------------

    setButtonDisabled(false);
  };

  const SubmitData = (imageUrl, data) => {

    const Payload =
    {
      image: imageUrl,
      name: data?.name,
      nationality: data?.nationality,
      languages: JSON.stringify(selectedLanguages),
      description: pageContent,
      locations: parseInt(data?.location),
      Pricing: parseInt(data?.pricing),
      experiance: data?.experiance,
      signature: data?.signature,
      video_url: data?.video_url,
      intro: data?.intro
    }

    // console.log("---------", Payload);

    const PostCoacheUrl = `${environment.baseUrl}staffmanagement/coache/`;
    axios.post(PostCoacheUrl, Payload).then((res => {
      //   console.log("post response-------------",res?.data)
      if (res?.data?.code[0] == 2000) {
        swal({
          title: "Success",
          text: "Coach Created successfully!",
          icon: "success",
          button: "Ok",
        }).then((value) => {
          history.push("/coaches-list");
        });
      }
    })).catch((err) => {
      console.log("error-----------", err);
      setButtonDisabled(false);
    })
    setButtonDisabled(false);
  }

  const MainCourtsList = () => {
    const getMainCourtsListUrl = `${environment.baseUrl}maincourts`;
    axios.get(getMainCourtsListUrl).then((res) => {
      // console.log("response------------------", res?.data);
      setMainCourtList(res?.data);
    })
  }

  useEffect(() => {
    MainCourtsList();
  }, [])


  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Coaches</h3>
        </Col>
      </Row>
      <Row>
        <Col ms={12} lg={12}>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Name</span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Name"
                            name="name"
                            {...register("name", { required: true })}
                          />
                        </div>
                        {errors.name && (
                          <span className="error-msg">
                            {" "}
                            Please Enter Your Name
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Nationality
                        </span>
                        <div className="form__form-group-field">
                          <select
                            className="input1-div"
                            name="nationality"
                            {...register("nationality", {
                              required: true,
                            })}
                          >
                            <option value="">Nationality</option>
                            {Nationality?.map((data, index) => {
                              return (
                                <option value={data?.value} key={index}>
                                  {data?.label}
                                </option>
                              );
                            })}{" "}
                          </select>
                        </div>
                        {errors.nationality && (
                          <span className="error-msg">
                            {" "}
                            Please Enter Your Nationality
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col sm="6" md="6" lg="6">
                    <span className="form__form-group-label">Languages</span>
                    <Select
                      defaultValue={selectedLanguages}
                      isMulti
                      onChange={setSelectedLanguages}
                      options={Languages}
                      placeholder={"Select Languages"}
                    />
                  </Col>

                  <Col sm="6" md="6" lg="6">
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Locations</span>
                        <div className="form__form-group-field">
                          <select
                            className="input1-div"
                            name="location"
                            {...register("location", {
                              required: true,
                            })}
                          >
                            <option value="">Location</option>
                            {mainCourtList.map((data, index) => {
                              // console.log("data-------------",data);
                              return (
                                <option value={data?.id} key={index}>
                                  Court - {data?.courtName}{" "}
                                </option>
                              );
                            })}{" "}
                          </select>
                        </div>
                        {errors.location && (
                          <span className="error-msg">
                            {" "}
                            Please Enter Your Location
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Pricing</span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Pricing"
                            name="pricing"
                            {...register("pricing", { required: true })}
                          />
                        </div>
                        {errors.pricing && (
                          <span className="error-msg">
                            {" "}
                            Please Enter Pricing
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Image</span>
                        <div className="form__form-group-field">
                          <input
                            type="file"
                            placeholder="Limited Time Offer Title"
                            name="image"
                            {...ImageUrls}
                            onChange={(e) => {
                              ImageUpload(e);
                              ImageUrls.onChange(e);
                            }}
                          />
                        </div>
                        {errors.image && (
                          <span className="error-msg">Please Upload Image</span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Signature Shot</span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Signature Shot"
                            name="signature"
                            {...register("signature", { required: true })}
                          />
                        </div>
                        {errors.signature && (
                          <span className="error-msg">
                            {" "}
                            Please Enter Signature
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Experiance</span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Experiance"
                            name="experiance"
                            {...register("experiance", { required: true })}
                          />
                        </div>
                        {errors.experiance && (
                          <span className="error-msg">
                            {" "}
                            Please Enter Experiance
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>

                </Row>
                <Row>

                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Video URL</span>
                        <div className="form__form-group-field">
                          <input
                            type="text"
                            placeholder="Video URL"
                            name="video_url"
                            {...register("video_url", { required: true })}
                          />
                        </div>
                        {errors.video_url && (
                          <span className="error-msg">
                            {" "}
                            Please Enter Video URL
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">Introduction</span>
                        <div className="form__form-group-field">
                          <textarea
                            type="text"
                            placeholder="Introduction"
                            name="intro"
                            {...register("intro", { required: true })}
                          />
                        </div>
                        {errors.intro && (
                          <span className="error-msg">
                            {" "}
                            Please Enter Experiance
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="w-100">
                  <Col sm={12} md={12} lg={12}>
                    <div className="form product-edit">
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          Description
                        </span>
                        <div className="form__form-group-fields">
                          <Controller
                            control={control}
                            name="content"
                            render={({ field }) => (
                              <JoditEditor
                                ref={editor}
                                tabIndex={1}
                                onChange={(newContent) =>
                                  setPageContent(newContent)
                                }
                              />
                            )}
                          />
                        </div>
                        {errors.content && (
                          <span className="error-msg">
                            Please Enter Description Content
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} md={6} lg={6}>
                    <div className="form product-edit">
                      <div className="form__form-group mt-2">
                        <div className="form__form-group-field">
                          {!buttonDisabled ? (
                            <Button type="submit" color="warning">
                              Submit
                            </Button>
                          ) : (
                            <Button type="" color="secondary">
                              Submit
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Coaches;
