import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "../../customAxios";
import { environment } from "../env";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ButtonToolbar,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import swal from "sweetalert";

export const EditPriceConfigurations = () => {
  const id = useParams();
  const peakId = id.id;

  console.log(peakId)

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const [mainCourtList, setMainCourtList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [priceConfiguration, setPriceConfiguration] = useState([]);

  const [times, setTimes] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
    "12:45 AM",
    "01:30 AM",
    "02:15 AM",
  ]);

  const timesAsOptions = times.map((time) => ({
    value: time,
    label: time,
  }));

  const GetPriceConfigurations = (id) => {
    setIsLoading(true);
    const priceconfigurationsURL = `${environment.baseUrl}change-peak-price/${id}`;
    console.log("priceconfigurationsURL==>",priceconfigurationsURL)
    const result = axios
      .get(priceconfigurationsURL)
      .then((response) => {
        console.log(response.data.data);
        const data = response.data.data;
        setPriceConfiguration(response.data.data);
        setIsLoading(false);
        reset({
          courtName: data.courtName,
          price: data.price,
          non_peak_hours_start: data.non_peak_hours_start,
          non_peak_hours_end: data.non_peak_hours_end,
          peak_hours_price_firstslot: data.peak_hours_price_firstslot,
          peak_hours_price_secondslot: data.peak_hours_price_secondslot,
          padelcourtPrice: data.padelcourtPrice,
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    data.non_peak_hours_start = data.non_peak_hours_start.value
      ? data.non_peak_hours_start.value
      : data.non_peak_hours_start;
    data.non_peak_hours_end = data.non_peak_hours_end.value
      ? data.non_peak_hours_end.value
      : data.non_peak_hours_end;

    axios
      .put(`${environment.baseUrl}change-peak-price/${peakId}`, data)
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            button: "Ok",
          }).then(() => {
            setIsLoading(false);
            window.location.href = "/price-configurations";
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        swal({
          title: "Error",
          text: JSON.stringify(err),
          icon: "error",
          button: "Ok",
        }).then(() => {
          window.location.href = "/price-configurations";
        });
      });
  };

  useEffect(() => {
    GetPriceConfigurations(peakId);
  }, []);
  return (
    <Container>
      <Row md={12}>
        <Col>
          <h3 className="page-title w-100">{`Edit Price Configurations for ${priceConfiguration.courtName}`}</h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              {isLoading && (
                <div class="loader-div">
                  <span class="loader">
                    <div className="center-spinner">
                      <Spinner className="table-fetch-spinner" />
                    </div>
                  </span>
                </div>
              )}
              <form
                className="form product-edit"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Main Court Name
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Main Court Name"
                        disabled
                        {...register("courtName", { required: true })}
                      />
                    </div>
                    {errors.courtName && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter The Main Court Name, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Non Peak Hours Start
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="non_peak_hours_start"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => {
                          const defaultValueOption = timesAsOptions.find(
                            (option) => option.value === value
                          );
                          return (
                            <Select
                              options={timesAsOptions}
                              className=" w-100"
                              placeholder="Non Peak Hours Start"
                              onChange={onChange}
                              inputRef={ref}
                              value={defaultValueOption}
                            />
                          );
                        }}
                      />
                    </div>
                    {errors.non_peak_hours_start && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter Non Peak Hours Start, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Non Peak Hours End
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="non_peak_hours_end"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => {
                          const defaultValueOption = timesAsOptions.find(
                            (option) => option.value === value
                          );
                          return (
                            <Select
                              options={timesAsOptions}
                              className=" w-100"
                              placeholder="Non Peak Hours End"
                              onChange={onChange}
                              inputRef={ref}
                              value={defaultValueOption}
                            />
                          );
                        }}
                      />
                    </div>
                    {errors.non_peak_hours_end && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter Non Peak Hours End, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      MainCourt Price
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Main Court Price"
                        {...register("price", { required: true })}
                      />
                    </div>
                    {errors.price && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter The Main Court Price, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      PadelCourt Price
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Padel Court Price"
                        {...register("padelcourtPrice", { required: true })}
                      />
                    </div>
                    {errors.padelcourtPrice && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter The Main Court Price, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Peak Hours Price Firstslot
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Peak Hours Price Firstslot"
                        {...register("peak_hours_price_firstslot", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.peak_hours_price_firstslot && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter The Peak Hours Price Firstslot, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Peak Hours Price Secondslot
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Peak Hours Price Secondslot"
                        {...register("peak_hours_price_secondslot", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.peak_hours_price_secondslot && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter The Peak Hours Price Secondslot, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>

                  <div className="form__form-group mt-5">
                    <ButtonToolbar className="form__button-toolbar">
                      <button
                        className="justpadel-buttons-save"
                        color="primary"
                        type="submit"
                      >
                        Save
                      </button>
                      <div className="justpadel-buttons-cancel">Cancel</div>
                    </ButtonToolbar>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const ErrorMessageComponent = ({ message }) => {
  return (
    <div color="danger" className="form__form-group-error">
      <p style={{ color: "#dc3545" }}>
        <span className="bold-text">Warning! </span>
        {message}
      </p>
    </div>
  );
};
