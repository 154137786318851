/* eslint-disable no-alert */
import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import BookingCancelledListCard from './components/CourtTypeEditCard';

const BookingCancel = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Booking Details</h3>
        <h3 className="page-subhead subhead">
          Use this elements, for Booking&apos;s Details
        </h3>
      </Col>
    </Row>
    <Row>
      <BookingCancelledListCard />
    </Row>
  </Container>
);
export default BookingCancel;
