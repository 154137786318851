import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { environment } from "../../../env";
import axios from "../../../../customAxios";
import swal from "sweetalert";

const AddonsComponent = ({ bookingId, finalAddonList, closeModal, amountpaid, amountPercentage }) => {
  const inititalUserSelection = {
    addonSelection: [
      { id: 1, price: "", qty: 0, isChecked: true },
      { id: 2, price: "", qty: 0, isChecked: true },
      { id: 3, price: "", qty: 0, isChecked: true },
    ],
    vat: "1.05",
    coupon: "",
  };
  const [userSelection, setUserSelection] = useState(inititalUserSelection);
  const [totalAmountWithoutVAT, setTotalAmountWithoutVAT] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);
  const [reloadState, setReloadState] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [addonsList, setAddonsList] = useState(true);
  const [previousAddonPrice, setPreviousAddonPrice] = useState();

  function calcTotalAmount(initialPrice, selectionFinal) {
    console.log("initialPrice, selectionFinal", initialPrice, selectionFinal);
    const selections = selectionFinal ? selectionFinal : userSelection;
    setUserSelection(selections);
    let total = initialPrice ? initialPrice : totalAmount;
    // addon calculations.
    selections.addonSelection.forEach((addon) => {
      total += addon.price * addon.qty;
    });
    // coupon discount.
    // console.log("Total Amount",total);
    // console.log("VAT Applied",((total/100)*5));

    setTotalAmountWithoutVAT(total);
    setVatAmount((total / 100) * 5);
    total -= total * selections.coupon;
    // vat calc
    total *= parseFloat(selections.vat).toFixed(2);
    setFinalAmount(total.toFixed(2));

    if (initialPrice === null) {
      // console.log("Previous", total.toFixed(2));
      setPreviousAddonPrice(total.toFixed(2));
    } else {
      // console.log("Previous");
    }
    setReloadState(!reloadState);
    // setdiscountPrice(((total / 100) * couponValue*100).toFixed(2));
    // console.log("Discounted Amount",((total / 100) * couponValue*100).toFixed(2));
    setReloadState(!reloadState);
  }

  function handleCheck(id, qty) {
    setAddonsList(false);
    const addonCopy = userSelection;

    addonCopy.addonSelection.forEach((addon) => {
      if (addon.id === id) {
        addon.isChecked = !addon.isChecked;
        addon.qty = addon.isChecked ? qty + 1 : 0;
      }
    });
    setUserSelection(addonCopy);
    calcTotalAmount();
  }

  function handlePlus(id) {
    setAddonsList(false);
    const addonCopy = userSelection;
    addonCopy.addonSelection.forEach((addon) => {
      if (addon.id === id && addon.isChecked) {
        addon.qty++;
      }
    });
    setUserSelection(addonCopy);
    calcTotalAmount();
  }

  function handleMinus(id) {
    setAddonsList(false);
    const addonCopy = userSelection;
    addonCopy.addonSelection.forEach((addon) => {
      if (addon.id === id && addon.isChecked) {
        if (addon.qty <= 0) {
          addon.qty = 0;
        } else {
          addon.qty--;
        }
      }
    });
    setUserSelection(addonCopy);
    calcTotalAmount();
  }

  const addonListUrl = `${environment.baseUrl}addon`;
  const getAddons = async () => {
    const result = await axios(addonListUrl);
    return result.data;
  };

  const setUserSectionFromPreviousPage = (initialPrice) => {
    const modifiedAddon = [];
    // let previousPageSelection = '';
    // let addonsFromDb = '';
    // const tempUserSelection = userSelection.addonSelection;
    // previousPageSelection = JSON.parse(localStorage.getItem('selectionDetails'));
    // await getAddons().then((value) => {
    //   addonsFromDb = value;
    // });
    // addonsFromDb.forEach((apiAddon) => {
    //   modifiedAddon.push({
    //     id: apiAddon.id,
    //     price: apiAddon.price,
    //     qty: 0,
    //     addonname: apiAddon.addonname,
    //     description: apiAddon.description,
    //     isChecked: false,
    //   });
    // });
    finalAddonList.forEach((apiAddon) => {
      modifiedAddon.push({
        id: apiAddon.id,
        price: apiAddon.price,
        qty: apiAddon.qty,
        addonname: apiAddon.addonname,
        description: apiAddon.description,
        isChecked: true,
      });
    });

    const selectionFinal = {
      courtPrice: initialPrice,
      addonSelection: modifiedAddon,
      vat: userSelection.vat,
      coupon: userSelection.coupon,
    };
    setUserSelection(selectionFinal);
    calcTotalAmount(null, selectionFinal);
  };

  const UpdateAddons = () => {
    let addOns = [];
    userSelection.addonSelection?.map((item) => {
      if (item.isChecked) {
        addOns.push(`${item.id}-${item.qty}`);
      }
    });

    // console.log("addOns", addOns);

    const addonListUrl = `${environment.baseUrl}update-addons`;
    const UpdateAddonsData = {
      bookingId: bookingId,
      addon: addOns.join(","),
      amount_paid:amountpaid + (finalAmount - previousAddonPrice)
    };
    // console.log("UpdateAddonsData", UpdateAddonsData);
    // return;
    axios.post(addonListUrl, UpdateAddonsData).then((response) => {
      console.log("Response", response.data);
      if(response.data.Message === "Addons is updated"){
        swal({
          title: "Success!",
          text: "Addons is Booked Successfully!",
          icon: "success",
          button: "Close",
        }).then(()=>{
          closeModal();
        });
      }else{
        swal({
          title: "Error!",
          text: response.data.Message,
          icon: "error",
          button: "Close!",
        }).then(()=>{
          closeModal();
        });
      }

    });
  };

  useEffect(() => {
    setUserSectionFromPreviousPage();
  }, []);

  return (
    <>
      <div className="my-court-head">
        <h2>Add On's {bookingId}</h2>
      </div>
      <div className="addons">
        {userSelection &&
          userSelection.addonSelection.map((data) => (
            <div className="addons-content">
              <div style={{ paddingBottom: 15 }}>
                <input
                  name="isGoing"
                  type="checkbox"
                  id="add-Checkbox"
                  checked={data.isChecked}
                  onClick={() => handleCheck(data.id, data.qty)}
                />
              </div>
              <div className="addons-inner-container">
                <div className="addons-inner">
                  <div className="inner-addons">
                    <div className="rents">
                      <h2>{data.addonname}</h2>
                      <small>({data.description})</small>
                    </div>
                  </div>
                  <div className="addons-minuts">
                    <h4>AED {data.price}</h4>
                  </div>
                </div>

                <div className="addons-inner">
                  <div className="rents qty">
                    <h3>Qty.</h3>
                  </div>
                  <div className="select-minuts">
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      className="faMinus"
                      onClick={() => handleMinus(data.id, data.qty)}
                    />
                    <small>{data.qty}</small>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="faPlus"
                      onClick={() => handlePlus(data.id, data.qty)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className="total-amount">
          <div className="total-details">
            <div>Amount</div>
            <div className="amount-text">{totalAmountWithoutVAT} AED</div>
          </div>
          <div className="total-details">
            <div>VAT 5%</div>
            <div className="amount-text">{vatAmount} AED</div>
          </div>
          <div className="total-details">
            <div>Total Amount</div>
            <div className="amount-text">{finalAmount} AED</div>
          </div>
          <div className="total-details">
            <div>Send Amount</div>
            <div className="amount-text">
              {finalAmount - previousAddonPrice} AED
            </div>
          </div>
          <hr />
          <div className="total-details">
            <div>Amount Paid Previously</div>
            <div className="amount-text">
              {amountpaid} AED
            </div>
          </div>
          <div className="total-details">
            <div>Amount Percentage Previously</div>
            <div className="amount-text">
            {amountPercentage} %
            </div>            
          </div>
          <hr />
          <div className="total-details">
            <div>Final Amount</div>
            <div className="amount-text">
            {amountpaid + (finalAmount - previousAddonPrice)}
            </div>            
          </div>
        </div>
        <div className="total-details" style={{ justifyContent: 'start' }}>
          <button className="btn btn-danger" onClick={closeModal}>
            Close
          </button>
          <button
            className="btn btn-warning"
            disabled={addonsList}
            style={{ cursor: addonsList ? "not-allowed" : "pointer" }}
            onClick={UpdateAddons}
          >
            Update Addons
          </button>
        </div>
      </div>
    </>
  );
};
export default AddonsComponent;
