import React, { useState, useEffect, useCallback } from 'react'
import { Col, Card, Row, CardBody, CardTitle, ButtonToolbar, CardSubtitle, Button } from 'reactstrap'
import AsyncSelect from 'react-select/async';
import { environment } from '../env';
import _ from "lodash";
import axios from '../../customAxios';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
const LoyalityPointCrad = () => {
  const rotationAngle = '90deg';
  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [userData, setUserData] = useState([]);
  const [userLoyalityPoints, setUserLoyalityPoints] = useState();
  const [addPointForm, setAddPointForm] = useState(false);
  const handleChange = async value => {
    setSelectedValue(value);
    const PointsUrl = `${environment.baseUrl}loyaltypoint/getloyaltypoints/${value.id}`;
    await axios.get(PointsUrl).then((response) => {
      setUserLoyalityPoints(response?.data);
    });
  };


  const Tbdata = [
    {
      "color": "#e0e0e0",
      "level": "SILVER",
      "points": "30",
      "max":"49"
    },
    {
      "color": "#b79001",
      "level": "GOLD",
      "points": "50",
      "max":"99"
    },
    {
      "color": "#c0c0c0",
      "level": "PLATINUM",
      "points": "100",
      "max":"149"
    },
    {
      "color": "#000000",
      "level": "BLACK DIAMOND",
      "points": "150",
      "max":" >150"
    },
  ];
  const loadOptions = async (inputValue) => {
    if (inputValue.length > 3) {
      const FilterUserURL = `${environment.baseUrl}filter-user/${inputValue}`;
      const data = await axios.get(FilterUserURL);
      const mapped = data.data.map(element => ({ title: `${element.first_name}${"-"}${element.last_name}${"-"}${element.email}`, value: element.id, ...element }))
      return mapped;
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
  });

  const onSubmit = (data) => {
    const userpayload = {
      "userId": selectedValue.id,
      "loyaltyPoints": data.loyaltypoints,
      "loyaltyGames": data.loyaltygames
    }
    const handleLoyalityPoints = `${environment.baseUrl}loyaltypoint/loyaltypoints-handlers/`;
    const result = axios.post(handleLoyalityPoints, userpayload).then((response) => {
      if (response.data.status === "success") {

        swal({
          title: 'Success',
          text: response.data.Message,
          icon: "success",
          button: 'Ok'
        }).then(() => {
          handleChange(selectedValue)
        })
      }
      else {
        swal({
          title: "Warning",
          text: "Error while giving loyality poinst. ",
          icon: "error",
          button: "Ok",
        });
      }
    })

  };
  return (
    <>
      <Col md={12} lg={12}>
        <Card>
          <CardBody className="card-body">
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={selectedValue}
              getOptionLabel={e => e.title}
              getOptionValue={e => e.id}
              loadOptions={loadOptions}
              onChange={handleChange}
            />
            <div className='d-flex justify-content-between'>
              {Tbdata.map((data, i) => {
                return (
                  <>
                    <div className="mb-3 d-flex rounded mt-4 p-1 align-items-center shadow" style={{ width: "12rem" }}>
                      <span
                        className="border border-5 ml-2"
                        style={{ width: 26, height: 26, borderRadius: 13, backgroundColor: data.color }}>
                      </span>
                      <div className=" text-center ml-4">
                        <h5 className="card-title ml-1 mt-2">{data.level}</h5>
                        <p className="card-text">{data.points} - {data.max}</p>
                      </div>
                    </div>
                  </>
                )

              })}
            </div>

            <Row>
              <Col>

                {userLoyalityPoints &&
                  <>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <h3 className='mt-3 text-capitalize w-100'>current available loyality points</h3>
                      </Col>
                    </Row>

                    <Row >
                      <Col sm={3} md={3} lg={3}>
                        <Card
                          className='border-right border-danger rounded-0'
                        >

                          <CardBody className='p-0 text-center pt-2'>
                            <CardTitle tag="h5" className='text-capitalize'>
                              loyalty <br />available
                            </CardTitle>
                            <CardSubtitle
                              className="mb-2 text-muted"
                              tag="h6"
                            >
                              {userLoyalityPoints.loyaltyavailable}
                            </CardSubtitle>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm={3} md={3} lg={3}>
                        <Card
                          style={{

                          }}
                          className='border-right border-danger rounded-0'
                        >

                          <CardBody className='p-0 text-center pt-2'>
                            <CardTitle tag="h5" className='text-capitalize'>
                              loyalty <br /> lifetime
                            </CardTitle>
                            <CardSubtitle
                              className="mb-2 text-muted"
                              tag="h6"
                            >
                              {userLoyalityPoints.loyaltylifetime}
                            </CardSubtitle>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm={3} md={3} lg={3}>
                        <Card
                          style={{

                          }}
                          className='border-right border-danger rounded-0'
                        >

                          <CardBody className='p-0 text-center pt-2'>
                            <CardTitle tag="h5" className='text-capitalize'>
                              loyalty <br /> games
                            </CardTitle>
                            <CardSubtitle
                              className="mb-2 text-muted"
                              tag="h6"
                            >
                              {userLoyalityPoints.loyaltygames}
                            </CardSubtitle>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm={3} md={3} lg={3}>
                        <Card
                          style={{

                          }}

                        >
                          <CardBody className='p-0 text-center pt-2'>
                            <Button color="warning" onClick={() => setAddPointForm(true)}>
                              Add Points
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    {addPointForm &&
                      <form onSubmit={handleSubmit(onSubmit)} className="form product-edit justify-content-end" id="reset-form" style={{ display: "flex", flexDirection: "row" }}>
                        <div className="form__half">
                          <div className="form__form-group">
                            <span className="form__form-group-label text-capitalize" style={{ fontSize: 15 }}>add loyalty Points</span>
                            <div className="form__form-group-field">
                              <input type="number" name="loyaltypoints" {...register("loyaltypoints", { required: true })} style={{ borderRadius: 5, borderColor: "#646777" }} />
                            </div>
                            {errors.loyaltypoints && <div class="invalid-feedback d-flex">Please add loyaltypoints.</div>}
                          </div>
                        </div>
                        <div className="form__half">
                          <div className="form__form-group">
                            <span className="form__form-group-label text-capitalize" style={{ fontSize: 15 }}>add loyalty games</span>
                            <div className="form__form-group-field">
                              <input type="number" name="role" {...register("loyaltygames", { required: true })} style={{ borderRadius: 5, borderColor: "#646777" }} />
                            </div>
                            {errors.loyaltygames && <div class="invalid-feedback d-flex">Please add loyaltygames.</div>}
                          </div>
                        </div>
                        <ButtonToolbar className="form__button-toolbar justify-content-end mt-4">
                          <button type="submit" className='justpadel-buttons-save'>
                            Submit
                          </button>
                        </ButtonToolbar>
                      </form>
                    }
                  </>
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}
export default LoyalityPointCrad