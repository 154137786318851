import React, { useEffect, useState } from "react";
import { Col, CardBody, Spinner, Row, Card } from "reactstrap";
// import DatePicker from "react-date-picker";
import { useForm, Controller } from "react-hook-form";
import { environment } from "../env";
import axios from "../../customAxios";
import moment from "moment";
import { GetMainCourt, GetPadelCourt } from "../Reuse/ApiCalls";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import ReactTableBase from "../../shared/components/table/ReactTableBase";

import DatePicker, { Calendar } from "react-multi-date-picker";
import  './DateColor.css'

export const GetSlotsBookingReportCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mainCourtList, setMainCourtList] = useState([]);
  const [padelCourtList, setPadelCourtList] = useState([]);
  const [slotsReport, setSlotsReport] = useState([]);
  const [calendarType, setCalendarType] = useState('')
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const GetSlotsReportFun = async (data) => {
    setIsLoading(true);
    const GetSlotsReportPayload = {
      date: data.date,
      maincourtId: data.maincourt,
      padelcourtId: data.padelcourt,
    };

    const GetSlotsReportUrl = `${environment.baseUrl}get-slots-booking-report`;
    await axios
      .post(GetSlotsReportUrl, GetSlotsReportPayload)
      .then((res) => {
        setSlotsReport([res.data.data]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = async (data) => {
    if (calendarType === 'date' || calendarType === 'month' || calendarType === '') {
      data.date = [data.date]
    }
    let selectedDate = [];
    data.date.map((day) => {
      selectedDate.push(`${day.year}-${day.month.number}-${day.day}`);
    });
    if (calendarType === 'month') {
      data.date.map((day) => {
        selectedDate.push(`${day.year}-${day.month.number}-${day.month.length}`);
      });
    }
    data.date = selectedDate;
    GetSlotsReportFun(data);
  };

  const handleCalanderChange = (value) => {
    setValue('date', '')
    setCalendarType(value)
  }

  const filteredMainCourt = mainCourtList.filter((data) => data.id !== 12);

  const handleMainCourt = (value) => {
    GetPadelCourt().then((response) => {
      const padelCourt = [];
      let padelCourts = response;
      let padelCourtFilter = padelCourts.filter(function (padelCourts) {
        return padelCourts.maincourt.id === parseInt(value);
      });
      padelCourtFilter.map((data) => {
        padelCourt.push({ id: data.id, courtname: data.courtname });
      });
      setPadelCourtList(padelCourt);
    });
  };

  const handlePadelCourt = (value) => {
    console.log(value);
  };
  const maincourtRegister = register("maincourt", { required: true });
  const padelcourtRegister = register("padelcourt", { required: true });

  var jsonObj = [];
  for (let i = 0; i < slotsReport.length; i++) {
    var item = {};
    let maincourtid = slotsReport[i].maincourt_id;
    const maincourtname = mainCourtList.find((item) => item.id === maincourtid);
    const availability = slotsReport[i].available ? "True" : "False";
    item["id"] = i + 1;
    item["booked_slots"] = (
      <div className="Booking-details-td-right-space custom_slot_report bg-danger">
        {slotsReport[i]?.booked_slots}
      </div>
    );
    item["unbooked_slots"] = (
      <div className="Booking-details-td-right-space custom_slot_report bg-success">
        {slotsReport[i]?.unbooked_slots}
      </div>
    );
    item["total_slots"] = (
      <div className="Booking-details-td-right-space custom_slot_report bg-warning">
        {slotsReport[i]?.total_slots}
      </div>
    );

    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: "Total Slots",
        accessor: "total_slots",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Booked Slots",
        accessor: "booked_slots",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Available Slots",
        accessor: "unbooked_slots",
        disableGlobalFilter: true,
        width: 100,
      },
    ],
    rows: jsonObj,
  };

  const [withPagination, setWithPaginationTable] = useState(true);

  const [isResizable, setIsResizable] = useState(true);

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  const tableConfig = {
    isEditable: false,
    isResizable,

    withPagination,

    manualPageSize: [10, 20, 30, 40],
  };

  useEffect(() => {
    GetMainCourt().then((response) => {
      setMainCourtList(response);
    });
  }, []);

  return (
    <>
      <CardBody>
        {isLoading && (
          <div class="loader-div">
            <span class="loader">
              <div className="center-spinner">
                <Spinner className="table-fetch-spinner" />
              </div>
            </span>
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={3} lg={3}>
              <select
                name="maincourt"
                className="textareaFeild"
                {...maincourtRegister}
                onChange={(e) => {
                  maincourtRegister.onChange(e);
                  handleMainCourt(e.target.value);
                }}
              >
                <option value="">Select Main Court</option>
                {filteredMainCourt?.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.courtName}
                    </option>
                  );
                })}
              </select>
              {errors.maincourt && (
                <span className="text-danger">Please Select Maincourt*</span>
              )}
            </Col>

            <Col md={2} lg={2}>
              <select
                name="padelcourt"
                className="textareaFeild"
                {...padelcourtRegister}
                onChange={(e) => {
                  padelcourtRegister.onChange(e);
                  handlePadelCourt(e.target.value);
                }}
              >
                <option value="">Select Padel Court</option>
                <option value="all">All</option>
                {padelCourtList?.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      Court - {data.courtname}
                    </option>
                  );
                })}
              </select>
              {errors.padelcourt && (
                <span className="text-danger">Please Select Padel Court*</span>
              )}
            </Col>

            <Col md={2} lg={2}>
              <select
                name="calendar_type"
                className="textareaFeild"
                {...register("calander_type")}
                onChange={(e) => {

                  handleCalanderChange(e.target.value);
                }}
              >
                <option value="">Select Calander Type</option>
                <option value="date">Date</option>
                <option value="range">Range</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
              </select>
            </Col>

            <Col md={3} lg={3} className="d-flex flex-column">
              <Controller
                control={control}
                name="date"
                rules={{ required: true }} //optional
                render={({
                  field: { onChange, name, value },
                  fieldState: { invalid, isDirty },
                  formState: { errors },
                }) => (
                  <>
                    <DatePicker
                      value={value}
                      highlightToday
                      displayWeekNumbers={calendarType === 'week' ? true : false}
                      weekPicker={calendarType === 'week' ? true : false}
                      onlyMonthPicker={calendarType === 'month' ? true : false}
                      onChange={(date) => {
                        onChange(date);
                      }}
                      range={calendarType === 'range' ? true : false}
                      // className="orange"
                      placeholder="Select date"
                    />
                    {errors &&
                      errors[name] &&
                      errors[name].type === "required" && (
                        <span className="text-danger">
                          Please Select Date *
                        </span>
                      )}
                  </>
                )}
              />
            </Col>

            <Col md={2} lg={2}>
              <div className="cal-top-submit-btn">
                <button
                  color="warning"
                  size="sm"
                  style={{
                    height: 35,
                    backgroundColor: "#FDAE00",
                    paddingRight: 20,
                    paddingLeft: 20,
                    display: "flex",
                    marginLeft: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    borderRadius: 4,
                    fontSize: 15,
                    fontFamily: "sans-serif",
                    border: 0,
                  }}
                >
                  <p style={{ color: "#fff" }}>Submit</p>
                </button>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "50px" }}>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="react-table__wrapper hide-table-costomizer">
                    <ReactTableCustomizer
                      handleClickWithPagination={handleClickWithPagination}
                      handleClickIsResizable={handleClickIsResizable}
                      isResizable={isResizable}
                      withPagination={withPagination}
                    />
                  </div>
                  <div className="table-id-width hover-effect search-input-update">
                    <ReactTableBase
                      columns={data.columns}
                      data={data.rows}
                      tableConfig={tableConfig}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
      </CardBody>
    </>
  );
};
