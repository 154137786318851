import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import OfferList from './components/Offer-List';

const OfferListPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Offers</h3>
      </Col>
    </Row>
    <Row>
      <OfferList />
    </Row>
  </Container>
);

export default OfferListPage;
