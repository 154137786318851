import React from 'react';
import { Card, CardBody, Col, Container, Row, } from 'reactstrap';
import './CalendarSkaleton.css';
import Shimmer from "react-shimmer-effect";

const CalendarSkaleton = () => {
  return (
        <CardBody style={{padding:0}}>
          <div className='Select-location-btn'>
            <table className='Skaleton-table'>
              <tr className='Skeleton-headings' style={{display:'flex'}}>
                {Array(7).fill(0).map((_, i) => (
                  <Shimmer width={400}>
                  <th></th>
                  </Shimmer>
                  ))}
              </tr>

              {Array(15).fill(0).map((_, i) => (
                <tr style={{display:'flex'}}>
                  <Shimmer width={400}>
                    <td style={{width:'100%'}}></td>
                  </Shimmer>
                  <Shimmer width={400}>
                    <td style={{width:'100%'}}></td>
                  </Shimmer>
                  <Shimmer width={400}>
                    <td style={{width:'100%'}}></td>
                  </Shimmer>
                  <Shimmer width={400}>
                    <td style={{width:'100%'}}></td>
                  </Shimmer>
                  <Shimmer width={400}>
                    <td style={{width:'100%'}}></td>
                  </Shimmer>
                  <Shimmer width={400}>
                    <td style={{width:'100%'}}></td>
                  </Shimmer>
                  <Shimmer width={400}>
                    <td style={{width:'100%'}}></td>
                  </Shimmer>
                </tr>
              ))}
            </table>
          </div>
        </CardBody>
  )
};
export default CalendarSkaleton;