import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "../../customAxios";
import { environment } from "../env";
import swal from "sweetalert";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, ButtonToolbar,Spinner } from "reactstrap";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import { EditPriceConfigurations } from "./EditPriceConfigurations";
import { useHistory } from "react-router-dom";


const initialState = {
  pageLimits: 10,
};

function reducer(state, action) {
  switch (action.type) {
    case "pageLimits":
      return {
        ...state,
        pageLimits: parseInt(action.payload),
      };
    default:
      return {
        ...state,
      };
  }
}

export const PriceConfigurations = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const history = useHistory()

  const [priceConfigurations, setPriceConfigurations] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSortable, setIsSortable] = useState(true);

  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [priceConfigurationsPageCount, setPriceConfigurationsPageCount] = useState(0);
  const [priceConfigurationsInitialPage, setPriceConfigurationsInitialPage] = useState(0);
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [state, dispatch] = useReducer(reducer, initialState);



  const GetPriceConfigurations = (pageNo, pageLimit) => {
    dispatch({ type: "pageLimits", payload: pageLimit });
    setIsLoading(true);
    const priceconfigurationsURL = `${environment.baseUrl}change-peak-price/?page=${pageNo}&page_size=${pageLimit}`;
    const result = axios
      .get(priceconfigurationsURL)
      .then((response) => {
        console.log(response.data.results)
        setPriceConfigurations(response.data.results);
        setPriceConfigurationsPageCount(Math.ceil(response.data.count / pageLimit));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleEdit = (data) => {
    history.push(`/edit-price-configurations/${data.id}`)
  }

  var jsonObj = [];

  for (let i = 0; i < priceConfigurations?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["maincourt_name"] = <div className="right-space"> {priceConfigurations[i].courtName}</div>;
    item["maincourt_price"] = <div className="right-space"> {priceConfigurations[i].price}</div>;
    item["padelcourt_price"] = <div className="right-space"> {priceConfigurations[i].padelcourtPrice}</div>;
    item["peak_hours_price_firstslot"] = <div className="right-space"> {priceConfigurations[i].peak_hours_price_firstslot}</div>;
    item["peak_hours_price_secondslot"] = <div className="right-space"> {priceConfigurations[i].peak_hours_price_secondslot}</div>;
    item["non_peak_hours_start"] = <div className="right-space"> {priceConfigurations[i].non_peak_hours_start}</div>;
    item["non_peak_hours_end"] = <div className="right-space"> {priceConfigurations[i].non_peak_hours_end}</div>;

    item["edit"] = (
      <div className="modal-edit-btn">
      <EditOutlineIcon style={{ fontSize: 20 }}  onClick={() => handleEdit(priceConfigurations[i])}/>
      </div>
    );

    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "MainCourt",
        accessor: "maincourt_name",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Maincourt Price",
        accessor: "maincourt_price",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Padel Court Price",
        accessor: "padelcourt_price",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Peak Hours Price Firstslot",
        accessor: "peak_hours_price_firstslot",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Peak Hours Price Secondslot",
        accessor: "peak_hours_price_secondslot",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Non Peak Hours Start",
        accessor: "non_peak_hours_start",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Non Peak Hours End",
        accessor: "non_peak_hours_end",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Edit",
        accessor: "edit",
        disableGlobalFilter: true,
        width: 20,
      },
    ],
    rows: jsonObj,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10000, 20000, 30000, 40000],
    placeholder: "Search by Coupon...",
  };

  const handlePriceConfigurationsPageClick = (data) => {
    setIsLoading(true);
    setPriceConfigurationsInitialPage(data.selected);
    const priceconfigurationsURL = `${environment.baseUrl}change-peak-price/?page=${
      data.selected + 1
    }&page_size=${state.pageLimits}`;
    const result = axios
      .get(priceconfigurationsURL)
      .then((response) => {
        setPriceConfigurations(response.data.results);
        setPriceConfigurationsPageCount(
          Math.ceil(response.data.count / state.pageLimits)
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleLimitChange = (e) => {
    dispatch({ type: "pageLimits", payload: e.target.value });
    setPriceConfigurationsInitialPage(0);
    GetPriceConfigurations(1, e.target.value);
  };

  useEffect(() => {
    GetPriceConfigurations(1, state.pageLimits);
  }, []);

  //   const GetMainCourt = async () => {
  //     const mainCourtUrl = `${environment.baseUrl}maincourts-admin/`;
  //     const result = await axios(mainCourtUrl);
  //     var mainCourts = [];
  //     result.data.map((maincourt) => {
  //       mainCourts.push({ label: maincourt.courtName, value: maincourt.id });
  //     });

  //     setMainCourtList(mainCourts);
  //   };

  //   useEffect(() => {
  //     GetMainCourt();
  //   }, []);

  const onSubmit = (data) => {
    console.log("data===>", data);
  };
  return (
    <Container>
      <Row md={12}>
        <Col>
          <h3 className="page-title">Price Configurations</h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              {/* <form
                className="form product-edit"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Select Main Court
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="maincourt"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Select
                            value={value}
                            options={mainCourtList}
                            className="text-captialize w-100"
                            placeholder="Select Main Court"
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                      />
                    </div>
                    {errors.maincourt && (
                      <ErrorMessageComponent
                        message={
                          "Please Select Main Court, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Non Peak Hours Start
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="non_peak_hours_start"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Select
                            options={timesAsOptions}
                            className=" w-100"
                            placeholder="Non Peak Hours Start"
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                      />
                    </div>
                    {errors.non_peak_hours_start && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter Non Peak Hours Start, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Non Peak Hours End
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="non_peak_hours_end"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Select
                            options={timesAsOptions}
                            className=" w-100"
                            placeholder="Non Peak Hours End"
                            onChange={onChange}
                            inputRef={ref}
                          />
                        )}
                      />
                    </div>
                    {errors.non_peak_hours_end && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter Non Peak Hours End, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Price</span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Main Court Price"
                        {...register("price", { required: true })}
                      />
                    </div>
                    {errors.price && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter The Main Court Price, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Peak Hours Price Firstslot
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Peak Hours Price Firstslot"
                        {...register("peak_hours_price_firstslot", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.peak_hours_price_firstslot && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter The Peak Hours Price Firstslot, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Peak Hours Price Secondslot
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Peak Hours Price Secondslot"
                        {...register("peak_hours_price_secondslot", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.peak_hours_price_secondslot && (
                      <ErrorMessageComponent
                        message={
                          "Please Enter The Peak Hours Price Secondslot, It Should Not be Empty"
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="form__half"></div>

                <div className="form__half mt-3">
                <ButtonToolbar className="form__button-toolbar">
                <button
                  className="justpadel-buttons-save"
                  color="primary"
                  type="submit"
                >
                  Save
                </button>
                <div className="justpadel-buttons-cancel">Cancel</div>
              </ButtonToolbar>
                </div>
              </form> */}

              {isLoading && (
                <div class="loader-div">
                  <span class="loader">
                    <div className="center-spinner">
                      <Spinner className="table-fetch-spinner" />
                    </div>
                  </span>
                </div>
              )}

              <Row>
                <Col>
                  <div className="react-table__wrapper hide-table-costomizer">
                    <ReactTableCustomizer
                      handleClickIsSortable={handleClickIsSortable}
                      handleClickWithPagination={handleClickWithPagination}
                      handleClickWithSearchEngine={handleClickWithSearchEngine}
                      handleClickIsResizable={handleClickIsResizable}
                      isResizable={isResizable}
                      isSortable={isSortable}
                      withPagination={withPagination}
                      withSearchEngine={withSearchEngine}
                    />
                  </div>
                  <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                    <ReactTableBase
                      id="table-to-xls"
                      key={withSearchEngine ? "searchable" : "common"}
                      columns={data.columns}
                      data={data.rows}
                      tableConfig={tableConfig}
                    />
                  </div>
                  <div className="custom-pagination">
                    <div className="records-per-page">
                      <select
                        value={state.pageLimits}
                        onChange={handleLimitChange}
                        name="limit"
                        id="select-pagination"
                      >
                        {mannualLimits?.map((mannualLimits) => (
                          <option value={mannualLimits}>{mannualLimits}</option>
                        ))}
                      </select>
                      &nbsp;&nbsp;&nbsp;records per page
                    </div>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={priceConfigurationsPageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePriceConfigurationsPageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={priceConfigurationsInitialPage}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const ErrorMessageComponent = ({ message }) => {
  return (
    <div color="danger" className="form__form-group-error">
      <p style={{ color: "#dc3545" }}>
        <span className="bold-text">Warning! </span>
        {message}
      </p>
    </div>
  );
};
