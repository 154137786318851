import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button,CardBody,Modal,Card, Col,} from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import { BookingDetailsByBookingID } from '../../containers/Reuse/ApiCalls';
import BookingDetailsTab from '../../containers/UI/Tabs/components/BookingDetailsTab';
import './TransationModal.css';
import  { AiFillEye } from 'react-icons/ai';



const BookingDetailsModal = ({
    color, btn,colored, header, title, transactionId, cancelBoooking, userdetails, bookedUsing
}) => {
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(prevState => !prevState);
        localStorage.removeItem("AdminPaymentType");
    };
    const toggleModal = () => {
        setModal(prevState => !prevState);
    };

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });
    
    return (
        <div>
            {/* className="view-btn" */}
            <div className="modal-view-btn"
                 onClick={toggle}>
                 <AiFillEye style={{fontSize:20}}/>
                 {btn}
            </div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass}` }
                style={{minWidth:950}}
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody>
                        <div className="tabs tabs--justify tabs--bordered-bottom">
                            <BookingDetailsTab userdetails={userdetails} transactionId={transactionId} toggleModal={toggle} cancelBoooking={cancelBoooking} close={toggleModal} bookedUsing={bookedUsing} />
                        </div>
                        </CardBody>
                    </Card>
                </Col>
                {/* <Card>
                    <CardBody>
                        {transactionId}
                    </CardBody>
                </Card> */}
            </Modal>
        </div>
    );
};

BookingDetailsModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired,
};

BookingDetailsModal.defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
};

export default BookingDetailsModal;

