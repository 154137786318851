import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserDetailsCard from './components/UserDetailsCard';

const ExamplePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Booking Details</h3>
      </Col>
    </Row>
    <Row>
      <UserDetailsCard />
    </Row>
  </Container>
);

export default ExamplePage;
