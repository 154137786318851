
import React, { useState, useEffect } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane, Table, Col, Card, CardBody, Button
} from 'reactstrap';
import classnames from 'classnames';
import axios from '../../../../customAxios';
import { environment } from "../../../env";
import { useParams } from "react-router-dom";
import './TransactionTabs.css'
import { FiUser } from 'react-icons/fi';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FcCheckmark } from 'react-icons/fc';
import { FcCancel } from 'react-icons/fc';
import {
  GetAddon, BookingDetailsByBookingIDForTransactionFalse, BookingDetailsByBookingIDForTransactionTrue, BookingDetailsAvailableFalseByBookingID,
  BookingDetailsAvailableTrueByBookingID, GetPadelholicLevel, getCoupon
} from '../../../Reuse/ApiCalls';
import moment from 'moment';


const TransactionTabs = ({ transactionId }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [bookingDetailsSingle, setBookingDetailsSingle] = useState();
  const [transactionDetails, setTransactionDetails] = useState();
  const [transactionLogs, setTransactionLogs] = useState();
  const [bookedSlots, setBookedSlots] = useState([]);
  const [finalAddonList, setFinalAddonList] = useState([]);
  const [padelholicLevels, setPadelholicLevels] = useState();
  const [totalCourtAmount, setTotalCourtAmount] = useState(0);
  const [totalAddonAmount, setTotalAddonAmount] = useState(0);
  const [logs, setLogs] = useState([]);

  const [timeToPlay, setTimeToPlay] = useState();
  const [hoursToPlay, setHoursToPlay] = useState();
  const [courtBookinDate, setCourtBookinDate] = useState();
  const [createdOrEditedLogs, setCreatedOrEditedLogs] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [couponPercentage, setCouponPercentage] = useState();

  // -------------------Return state in html-----------------
  const [totalAmount, setTotalAmount] = useState();
  const [vatAmount, setVatAmount] = useState();
  const [discountedAmount, setDiscountedAmount] = useState();
  const [payFacilityAmount, setPayFacilityAmount] = useState();

  const { id } = useParams();

  const transactionLog = `${environment.baseUrl}transaction_log/${transactionId}`;
  const getTransactionLog = async () => {
    const result = await axios.get(transactionLog)
      .then((response) => {
        let responseBookingId = response?.data?.booking?.booking_id;
        getBookingDetails(responseBookingId);
        // BookingDetailsByBookingIDForTransactionFalse(response?.data?.booking?.booking_id).then((response) => {
        //   if (response.data.Message !== "No data with this id!!!") {
        //     BookingDetailsAvailableFalseByBookingID(responseBookingId).then((response) => {
        //       addonFunction(response.data);
        //       setBookedSlots(response.data.booked_slots);
        //       let TotlaCourtPrice
        //       if (response.data.booked_slots.length === 1) {
        //         TotlaCourtPrice = response.data.booked_slots[0].Price
        //         setTotalCourtAmount(TotlaCourtPrice);
        //       } else {
        //         TotlaCourtPrice = response.data.booked_slots.reduce(function (a, b) { return { Price: a.Price + b.Price }; })
        //         setTotalCourtAmount(TotlaCourtPrice?.Price);
        //       }
        //     })
        //   } else {
        //     alert("HELLO ELSE")
        //     setBookedSlots(response.data.booked_slots);
        //     let TotlaCourtPrice
        //     if (response.data.booked_slots.length === 1) {
        //       TotlaCourtPrice = response.data.booked_slots[0].Price
        //       setTotalCourtAmount(TotlaCourtPrice);
        //     } else {
        //       TotlaCourtPrice = response.data.booked_slots.reduce(function (a, b) { return { Price: a.Price + b.Price }; })
        //       setTotalCourtAmount(TotlaCourtPrice?.Price);
        //     }
        //   }

        // })
        const NoOfGamesPlayes = response?.data?.user?.no_of_games
        GetPadelholicLevel().then((response) => {
          const padelHolicLevel = parseInt(NoOfGamesPlayes);
          const padelLevels = response ?? [];
          let matchingPadelLevel = padelLevels.find(element => element.games >= padelHolicLevel);
          setPadelholicLevels(matchingPadelLevel);
          if (matchingPadelLevel == null) {
            matchingPadelLevel = padelLevels[padelLevels.length - 1];
            setPadelholicLevels(matchingPadelLevel);
          }
        })
        setTransactionDetails(response?.data);
        DiscountedPrice(response?.data?.booking?.coupan_applied);
        localStorage.setItem("amountPaid", response?.data?.booking?.amount_paid)
        setTransactionLogs(JSON.parse(response?.data?.transaction_log)[0]);

      })
      .catch((error) => {
        console.log(error);
      });

  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const StartandEndTime = (data) => {
    setCourtBookinDate(data?.booking_date)
    const startTimes = data?.booked_slots[0]?.time?.split('-')[0];
    const endsTimes = data?.booked_slots.slice(-1)[0]?.time?.split('-')[1];
    setTimeToPlay(startTimes + "-" + endsTimes);

    // -----------------------functionality to get hours to play code starts here-------
    var startTime = moment(startTimes, "HH:mm a");
    var endTime = moment(endsTimes, "HH:mm a");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) - hours * 60;
    setHoursToPlay(hours + ' hr . ' + minutes + ' mins.')
    // -----------------------functionality to get hours to play code ends here-------
  }


  const getBookingDetails = (id) => {
    BookingDetailsAvailableFalseByBookingID(id).then((response) => {

      const com =
        response.data.comments === "" || response.data.comments === undefined
          ? JSON.stringify([{ comment: "No Comments" }])
          : JSON.parse(response.data.comments);
      if (typeof (com) === 'string') {
        setCommentsData(JSON.parse(com));
      } else {
        setCommentsData(com);
      }

      if (response?.data?.Message === "No data with this id!!!") {
        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data.Message === "No data with this id!!!") {
            // setLogs();
          } else {
            StartandEndTime(response?.data);
            setBookingDetailsSingle(response?.data);
            if (typeof (response?.data?.logs) === "string") {
              setCreatedOrEditedLogs();
            } else {
              setCreatedOrEditedLogs(response?.data?.logs);
            }
          }
        })
      } else {
        StartandEndTime(response?.data);
        const unique = [];
        response?.data.booked_slots.map((x) =>
          unique.filter(
            (a) =>
              a.time == x.time &&
              a.date == x.date &&
              a.courtname == x.courtname
          ).length > 0
            ? null
            : unique.push(x)
        );
        setBookedSlots(unique);
        setBookingDetailsSingle(response?.data);

        if (typeof (response?.data?.logs) === "string") {
          let newData = JSON.parse(response?.data?.logs);
          setCreatedOrEditedLogs(newData);
          if (typeof (newData) === "string") {
            let againParse = JSON.parse(newData);
            setCreatedOrEditedLogs(againParse);
          } else {
            setCreatedOrEditedLogs(newData)
          }
        } else {
          setCreatedOrEditedLogs(response?.data?.logs)
        }
      }
    })

    BookingDetailsAvailableFalseByBookingID(id).then((response) => {
      // setBookedSlots(response.data.booked_slots);
      const unique = [];
      response?.data?.booked_slots?.map((x) =>
        unique.filter(
          (a) =>
            a.time == x.time &&
            a.date == x.date &&
            a.courtname == x.courtname
        ).length > 0
          ? null
          : unique.push(x)
      );
      if (response.data.Message !== 'No data with this id!!!') {

        let TotlaCourtPrice
        if (unique.length === 1) {
          TotlaCourtPrice = unique[0].Price
          if (response?.data?.packages > 0) {
            setTotalCourtAmount(0);
            localStorage.setItem("TotlaCourtPrice", 0);
          } else {
            setTotalCourtAmount(TotlaCourtPrice?.Price);
            localStorage.setItem("TotlaCourtPrice", TotlaCourtPrice);
          }
        } else {
          TotlaCourtPrice = unique.reduce(function (a, b) { return { Price: a.Price + b.Price }; })

          if (response?.data?.packages > 0) {
            setTotalCourtAmount(0);
            localStorage.setItem("TotlaCourtPrice", 0);
          } else {
            setTotalCourtAmount(TotlaCourtPrice?.Price);
            localStorage.setItem("TotlaCourtPrice", TotlaCourtPrice?.Price);
          }
        }

        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data?.Message === "No data with this id!!!") {
            // setLogs();
          } else {
            const unique = [];
            response?.data?.booked_slots.map((x) =>
              unique.filter(
                (a) =>
                  a.time == x.time &&
                  a.date == x.date &&
                  a.courtname == x.courtname
              ).length > 0
                ? null
                : unique.push(x)
            );
            setLogs(unique);
          }
        });
        addonFunction(response.data);

      } else {
        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data?.Message === "No data with this id!!!") {
            // setLogs();
          } else {
            const unique = [];
            response?.data?.booked_slots.map((x) =>
              unique.filter(
                (a) =>
                  a.time == x.time &&
                  a.date == x.date &&
                  a.courtname == x.courtname
              ).length > 0
                ? null
                : unique.push(x)
            );

            let TotlaCourtPrice
            if (unique?.length === 1) {
              TotlaCourtPrice = unique[0].Price
              if (response?.data?.packages > 0) {
                setTotalCourtAmount(0);
                localStorage.setItem("TotlaCourtPrice", 0);
              } else {
                setTotalCourtAmount(TotlaCourtPrice?.Price);
                localStorage.setItem("TotlaCourtPrice", TotlaCourtPrice?.Price);
              }
            } else {
              TotlaCourtPrice = unique.reduce(function (a, b) { return { Price: a.Price + b.Price }; })

              if (response?.data?.packages > 0) {
                setTotalCourtAmount(0);
                localStorage.setItem("TotlaCourtPrice", 0);
              } else {
                setTotalCourtAmount(TotlaCourtPrice?.Price);
                localStorage.setItem("TotlaCourtPrice", TotlaCourtPrice?.Price);
              }
            }
            addonFunction(response.data);
            setLogs(unique);
            if (typeof (response?.data?.logs) === "string") {
              let newData = JSON.parse(response?.data?.logs);
              setCreatedOrEditedLogs(newData);
              if (typeof (newData) === "string") {
                let againParse = JSON.parse(newData);
                setCreatedOrEditedLogs(againParse);
              } else {
                setCreatedOrEditedLogs(newData)
              }

            } else {
              setCreatedOrEditedLogs(response?.data?.logs)
            }

          }
        })
      }
    });

    // BookingDetailsAvailableTrueByBookingID(id).then((response) => {
    //   if (response?.data?.Message === "No data with this id!!!") {
    //     // setLogs();
    //   } else {
    //     setLogs(response?.data?.booked_slots);
    //   }
    // });
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    getTransactionLog();
  }, []);



  const addonFunction = async (data) => {
    try {
      if (data.addons === "null" || data.addons === null) {
        localStorage.setItem("TotlaAddonPrice", 0);
        setTotalAddonAmount(0);
        FinalCalculation(JSON.parse(localStorage.getItem("couponApplied")));
        return;
      }
      var match = data.addons;
      let seperateComma = match.split(",");
      var arrays = []
      var addonArray = []
      for (var i = 0; i < seperateComma.length; i++) {
        arrays = seperateComma[i].replace(/'/ig, "").split("-")
        let objs = {};
        objs['id'] = parseInt(arrays[0]);
        objs['quantity'] = parseInt(arrays[1]);
        addonArray.push(objs);
      }
      localStorage.setItem("addonList", JSON.stringify(addonArray));
      addonListData();
    } catch (error) {
      console.log(error);
    }

  };

  const addonListData = () => {
    GetAddon().then((response) => {
      let addonNewList = response;
      let purchaseAddonList = JSON.parse(localStorage.getItem("addonList"))
      const result = addonNewList?.map(function (el) {
        var o = Object.assign({}, el);
        const quantity = purchaseAddonList?.find(e => e.id === el.id);
        o.qty = quantity?.quantity === undefined ? 0 : quantity?.quantity;
        return o;
      });
      setFinalAddonList(result);

      let TotlaAddonPrice = 0;
      if (result.length === 0) {
        setTotalAddonAmount(TotlaAddonPrice);
        localStorage.setItem("TotlaAddonPrice", TotlaAddonPrice);
      } else if (result.length === 1) {
        TotlaAddonPrice = result[0].price * result[0].qty;
        setTotalAddonAmount(TotlaAddonPrice);

        localStorage.setItem("TotlaAddonPrice", TotlaAddonPrice);
      } else {
        TotlaAddonPrice = result.reduce(function (a, b) { return { price: a.price * a.qty + b.price * b.qty }; })
        setTotalAddonAmount(TotlaAddonPrice?.price);

        localStorage.setItem("TotlaAddonPrice", TotlaAddonPrice?.price);
      }
      FinalCalculation(JSON.parse(localStorage.getItem("couponApplied")));
    });

  };


  const DiscountedPrice = (couponCode) => {
    if (couponCode) {
      if (couponCode != "") {
        const GetCouponDetails = `${environment.baseUrl}getcoupan/${couponCode}/`;
        const couponResult = axios.get(GetCouponDetails).then((couponResponse) => {
          if (couponResponse.data) {
            setCouponPercentage(couponResponse?.data?.percentage_of_discount);
            FinalCalculation(couponResponse?.data);
            localStorage.setItem("couponApplied", JSON.stringify(couponResponse?.data));
          } else {
            setCouponPercentage(0);
          }
        });
      }
    }
  };



  // const FinalCalculation = (coupon) => {
  //   let courtAddonPrice = 0;
  //   let discountedPrice = 0;
  //   let totlaAmountsWithoutVat = 0;
  //   let newVat = 0;
  //   let newFinalAmount = 0;
  //   let advanceAmount = 0;
  //   let newCouponPercentage = 0;

  //   // ----------------------------Variable to get the data from local storage--------------------------

  //   let NewTotlaCourtPrice = parseInt(localStorage.getItem("TotlaCourtPrice"));
  //   let NewTotlaAddonPrice = parseInt(localStorage.getItem("TotlaAddonPrice"));

  //   if (coupon?.apply_for_addons) {
  //     courtAddonPrice = NewTotlaCourtPrice + NewTotlaAddonPrice;
  //     console.log("courtAddonPrice", courtAddonPrice);
  //     // setTotalAmount(NewTotlaCourtPrice + NewTotlaAddonPrice);
  //     discountedPrice = (courtAddonPrice / 100) * coupon?.percentage_of_discount;
  //     console.log("discountedPrice",discountedPrice);
  //     totlaAmountsWithoutVat = Math.ceil(courtAddonPrice - discountedPrice);
  //     console.log("totlaAmountsWithoutVat", totlaAmountsWithoutVat);
  //     setDiscountedAmount(Math.round(discountedPrice));
  //     newVat = totlaAmountsWithoutVat * 0.05;
  //     console.log("newVat", newVat);
  //     setTotalAmount(NewTotlaCourtPrice + NewTotlaAddonPrice+newVat);
  //     setVatAmount(Math.round(newVat));
  //     newFinalAmount = totlaAmountsWithoutVat + newVat;
  //     // advanceAmount = parseFloat(newFinalAmount) * parseFloat(bookingDetailsSingle?.amount_percentage);
  //     console.log(localStorage.getItem("amountPaid"));
  //     console.log("newFinalAmount",newFinalAmount);
  //     setPayFacilityAmount(Math.round(newFinalAmount) - Math.round(parseInt(localStorage.getItem("amountPaid"))));
  //   } else {
  //     newCouponPercentage = coupon?.percentage_of_discount ? coupon?.percentage_of_discount : 0;
  //     // setTotalAmount(NewTotlaCourtPrice + NewTotlaAddonPrice);
  //     discountedPrice = (NewTotlaCourtPrice / 100) * newCouponPercentage;
  //     console.log("discountedPrice",discountedPrice);
  //     totlaAmountsWithoutVat = Math.ceil(NewTotlaCourtPrice - discountedPrice) + NewTotlaAddonPrice;
  //     console.log("totlaAmountsWithoutVat", totlaAmountsWithoutVat);
  //     setDiscountedAmount(Math.round(discountedPrice));
  //     newVat = totlaAmountsWithoutVat * 0.05;
  //     console.log("newVat", newVat);
  //     setTotalAmount(Math.ceil(NewTotlaCourtPrice + NewTotlaAddonPrice+newVat));
  //     setVatAmount(Math.round(newVat));
  //     newFinalAmount = totlaAmountsWithoutVat + newVat;
  //     console.log("newFinalAmount",newFinalAmount);
  //     // advanceAmount = parseFloat(newFinalAmount) * parseFloat(bookingDetailsSingle?.amount_percentage);
  //     setPayFacilityAmount(Math.ceil(newFinalAmount) - Math.round(parseInt(localStorage.getItem("amountPaid"))));
  //   };
  // };

  // ---------------- Calculation for discount admount starts here ---------
  const calculateDiscountAmount = (courtamount, addonAmount, couponDetails, coupon) => {
    console.log(courtamount, addonAmount, couponDetails, coupon);
    if (coupon === ("null" || "" || undefined)) {
      return 0.00;
    } else {
      if (couponDetails?.apply_for_addons) {
        return ((((courtamount + addonAmount) / 100) * couponDetails?.percentage_of_discount).toFixed(2));
      } else {
        if (couponDetails?.percentage_of_discount === 100) {
          return (Math.round((courtamount / 100) * couponDetails?.percentage_of_discount));
        } else {
          return ((courtamount / 100) * couponDetails?.percentage_of_discount);
        }
      }
    }
  };
  // ---------------- Calculation for discount admount ends here -----------

  // ---------------- Calculation for vat code starts here --------------
  const VatCalculation = (courtamount, addonAmount, couponDetails, coupon) => {
    if (coupon === ("null" || "" || undefined)) {
      return Math.ceil((courtamount + addonAmount) * 0.05);
    } else {
      if (couponDetails?.apply_for_addons) {
        return Math.ceil(((courtamount + addonAmount) - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon)) * 0.05)
      } else {
        return Math.ceil(((courtamount - calculateDiscountAmount(courtamount, addonAmount, couponDetails, coupon)) + addonAmount) * 0.05)
      }
    }
  };

  // ---------------- Calculation for vat code ends here --------------

  // ---------------- Calculate total amount code starts here ---------
  const CalculateTotalAmount = (courtamount, addonamount, coupondetails, coupon) => {
    if (coupon === ("null" || "" || undefined)) {
      return Math.floor(courtamount + addonamount + VatCalculation(courtamount, addonamount, coupondetails, coupon));
    } else {
      if (couponPercentage?.apply_for_addons) {
        return Math.floor(courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon) - calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon)));
      } else {
        return courtamount + addonamount + (VatCalculation(courtamount, addonamount, coupondetails, coupon) - calculateDiscountAmount(courtamount, addonamount, coupondetails, coupon));
      }
    }
  };
  // ---------------- Calculate total amount code starts here ---------

  // ---------------- Paid to faculty calculation code starts here -------
  const PaidToFacultyCalculation = (courtamount, addonamount, coupondetails, Coupon, amountPaid) => {
    return CalculateTotalAmount(courtamount, addonamount, coupondetails, Coupon) - amountPaid;
  }
  // ---------------- Paid to faculty calculation code ends here ---------


  // const FinalCalculation = (coupon) => {
  //   let NewTotlaCourtPrice = parseInt(localStorage.getItem("TotlaCourtPrice"));
  //   let NewTotlaAddonPrice = parseInt(localStorage.getItem("TotlaAddonPrice"));

  //   if (coupon?.apply_for_addons) {
  //     courtAddonPrice = NewTotlaCourtPrice + NewTotlaAddonPrice;
  //     console.log("courtAddonPrice", courtAddonPrice);
  //     // setTotalAmount(NewTotlaCourtPrice + NewTotlaAddonPrice);
  //     discountedPrice = (courtAddonPrice / 100) * coupon?.percentage_of_discount;
  //     console.log("discountedPrice",discountedPrice);
  //     totlaAmountsWithoutVat = Math.ceil(courtAddonPrice - discountedPrice);
  //     console.log("totlaAmountsWithoutVat", totlaAmountsWithoutVat);
  //     setDiscountedAmount(Math.round(discountedPrice));
  //     newVat = totlaAmountsWithoutVat * 0.05;
  //     console.log("newVat", newVat);
  //     setTotalAmount(NewTotlaCourtPrice + NewTotlaAddonPrice+newVat);
  //     setVatAmount(Math.round(newVat));
  //     newFinalAmount = totlaAmountsWithoutVat + newVat;
  //     // advanceAmount = parseFloat(newFinalAmount) * parseFloat(bookingDetailsSingle?.amount_percentage);
  //     console.log(localStorage.getItem("amountPaid"));
  //     console.log("newFinalAmount",newFinalAmount);
  //     setPayFacilityAmount(Math.round(newFinalAmount) - Math.round(parseInt(localStorage.getItem("amountPaid"))));
  //   } else {
  //     newCouponPercentage = coupon?.percentage_of_discount ? coupon?.percentage_of_discount : 0;
  //     // setTotalAmount(NewTotlaCourtPrice + NewTotlaAddonPrice);
  //     discountedPrice = (NewTotlaCourtPrice / 100) * newCouponPercentage;
  //     console.log("discountedPrice",discountedPrice);
  //     totlaAmountsWithoutVat = Math.ceil(NewTotlaCourtPrice - discountedPrice) + NewTotlaAddonPrice;
  //     console.log("totlaAmountsWithoutVat", totlaAmountsWithoutVat);
  //     setDiscountedAmount(Math.round(discountedPrice));
  //     newVat = totlaAmountsWithoutVat * 0.05;
  //     console.log("newVat", newVat);
  //     setTotalAmount(Math.ceil(NewTotlaCourtPrice + NewTotlaAddonPrice+newVat));
  //     setVatAmount(Math.round(newVat));
  //     newFinalAmount = totlaAmountsWithoutVat + newVat;
  //     console.log("newFinalAmount",newFinalAmount);
  //     // advanceAmount = parseFloat(newFinalAmount) * parseFloat(bookingDetailsSingle?.amount_percentage);
  //     setPayFacilityAmount(Math.ceil(newFinalAmount) - Math.round(parseInt(localStorage.getItem("amountPaid"))));
  //   };
  // };

  const FinalCalculation = (coupon) => {
    let NewTotlaCourtPrice = parseInt(localStorage.getItem("TotlaCourtPrice"));
    let NewTotlaAddonPrice = parseInt(localStorage.getItem("TotlaAddonPrice"));

    setDiscountedAmount(calculateDiscountAmount(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", coupon ? coupon : "null"));
    setVatAmount(VatCalculation(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", coupon ? coupon : "null"));
    setTotalAmount(CalculateTotalAmount(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", coupon ? coupon : "null"));
    setPayFacilityAmount(PaidToFacultyCalculation(NewTotlaCourtPrice, NewTotlaAddonPrice, coupon ? coupon : "null", coupon ? coupon : "null", parseInt(localStorage.getItem("amountPaid"))));
    if (bookingDetailsSingle?.packages > 0) {
      setPayFacilityAmount(0);
    }
  }

  useEffect(() => {
    setCouponPercentage(couponPercentage);
    setTotalAddonAmount(totalAddonAmount);
    setBookingDetailsSingle(bookingDetailsSingle);
    setTotalCourtAmount(totalCourtAmount);
  }, [couponPercentage, totalAddonAmount, bookingDetailsSingle, totalCourtAmount]);

  return (
    <div className="tabs__wrap" >
      <Nav tabs className="all-tabs" style={{ width: 420 }}>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); getTransactionLog() }}
          >
            Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink style={{ width: 175 }}
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggle('2')}
          >
            Client
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink style={{ width: 175 }}
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggle('3')}
          >
            Transaction Logs
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="booking-id">
            <label style={{ fontSize: 17, fontWeight: 'bold' }}>Booking ID:</label>
            <label style={{ fontSize: 17, fontWeight: 'bold' }}>&nbsp;&nbsp;#{transactionDetails?.booking?.booking_id === "null" || "" ? " " : transactionDetails?.booking?.booking_id}</label>
          </div>
          <div className="payment-datails">
            <div className="payment-datails-lhs">
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "37%" }}>Status: </label> {'  '}
                {transactionDetails?.booking?.cancelled ? <label style={{ fontSize: 15 }}>Cancelled</label> :
                  <label style={{ fontSize: 15 }}>{(transactionDetails?.booking?.amount_percentage === 1 || transactionDetails?.booking?.amount_percentage === 100) ? "100% Paid" : transactionDetails?.booking?.amount_percentage === 0.5 ? "50% Paid" : ''}</label>
                }
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "37%" }}>Payment processor: </label> {'  '}
                <label style={{ fontSize: 15, fontWeight: 'bold' }}>Payfort</label>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "37%" }}>Payment received:  </label> {'  '}
                <label style={{ fontSize: 15, fontWeight: 'bold' }}>{transactionDetails?.booking?.amount_paid.toFixed(2)} AED</label>
              </div>
            </div>
            <div className="payment-datails-rhs">
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "25%" }}>Invoice date: </label> {'  '}
                <label style={{ fontSize: 15, fontWeight: 'bold' }}>{moment(transactionDetails?.booking?.created_at.split('T')[0]).format("DD-MM-YYYY")}{' '}{moment(transactionDetails?.booking?.created_at.split('T')[1].split('.')[0], "HH:mm:ss").format("hh:mm A")}</label>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 15, width: "25%" }}>Due date: </label> {'  '}
                <label style={{ fontSize: 15, fontWeight: 'bold' }}> {moment(transactionDetails?.booking?.booking_date).format("DD-MM-YYYY")} {transactionDetails?.booking?.timeslot.split("-")[0]}</label>
              </div>
            </div>
          </div>
          <div className="user-datails">
            <div className="user-datails-lhs">
              <Table responsive hover>
                <tbody className="table-trans-user-details">
                  <tr>
                    <td className="first-td-width">
                      <div className="trans-client-name"><FiUser className="client-icon" /><h5>Client Name</h5></div>
                    </td>
                    <td className="client-name">
                      {transactionDetails?.user?.first_name === "null" ? " " : transactionDetails?.user?.first_name}{' '}
                      {transactionDetails?.user?.last_name === "null" ? " " : transactionDetails?.user?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="first-td-width">
                      <div className="trans-client-name"><AiOutlineMail className="client-icon" /> <h5>Email</h5></div>
                    </td>
                    <td className="client-email">{transactionDetails?.user?.email === "null" ? " " : transactionDetails?.user?.email}</td>
                  </tr>
                  <tr>
                    <td className="first-td-width">
                      <div className="trans-client-name"><AiOutlinePhone className="client-icon" /><h5 >Phone</h5></div>
                    </td>
                    <td className="client-Ph-no">{transactionDetails?.user?.phone_no === "null" ? " " : transactionDetails?.user?.phone_no}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="user-datails-lhs">
              <h5>Payment received by payment system</h5>
            </div>
          </div>
          <div className="user-datails-lhs">
            {bookedSlots?.length > 0 ? <h5>Current Bookings</h5> : ""}
          </div>
          <div className="trans-total-details">
            <Table responsive hover>
              {bookedSlots?.length > 0 ?
                <thead className="trans-total-table-header">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Status</th>
                    {/* <th>Amount</th> */}
                  </tr>
                </thead>
                : <span></span>}
              <tbody>
                {
                  bookedSlots?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {/* {transactionDetails?.booking?.main_court?.courtName === "null" ? " " : transactionDetails?.booking?.main_court?.courtName} - {" "} */}
                          {data?.main_court_name} - {" "}
                          {data?.padel_court_name}
                          {" "}({data?.date + "  " + data?.time})
                        </td>
                        <td>{data.Price.toFixed(2)} AED</td>
                        <td>1</td>
                        <td> <FcCheckmark size={25} className='fc-check' style={{ marginLeft: "10px" }} /></td>
                        {/* <td>230</td> */}
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </div>
          <div className="user-datails-lhs">
            {logs?.length > 0 ? <h5>Logs</h5> : ""}
          </div>
          <div className="trans-total-details">
            <Table responsive hover>
              {logs?.length > 0 ?
                <thead className="trans-total-table-header">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Status</th>
                  </tr>
                </thead>
                : <span></span>}
              <tbody>
                {
                  logs?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {data?.main_court_name} - {" "}
                          {data.padel_court_name}
                          {" "}({data?.date + "  " + data?.time})
                        </td>
                        <td>{data.Price.toFixed(2)} AED</td>
                        <td>1</td>
                        <td><FcCancel size={25} className='faCancel' style={{ marginLeft: '10px' }} /></td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </Table>
          </div>
          <div className="trans-total-amount">
            <div>
              <label style={{ fontSize: 15 }}>VAT 5%: </label> {' '}
              {/* <label style={{ fontSize: 15 }}>{((totalCourtAmount + totalAddonAmount) / 100 * 5).toFixed(2)} AED</label> */}
              <label style={{ fontSize: 15 }}>{Math.round(vatAmount)?.toFixed(2)} AED</label>
            </div>
            <div>
              <label style={{ fontSize: 15, fontWeight: 'bold' }}>Total amount: </label> {' '}
              {/* <label style={{ fontSize: 15, fontWeight: 'bold' }}>{(totalCourtAmount + totalAddonAmount + (totalCourtAmount + totalAddonAmount) / 100 * 5).toFixed(2)} AED</label> */}
              <label style={{ fontSize: 15, fontWeight: 'bold' }}>{Math.floor(totalAmount)?.toFixed(2)} AED</label>
            </div>
          </div>

          <div className="trans-data-container">
            <div className="trans-data-lhs">
              {
                transactionDetails?.booking?.addons === "null" || transactionDetails?.booking?.addons === null ?
                  <div className="no-addons">
                    <h4>No Addons</h4>
                  </div>
                  :
                  <Table hover>
                    <thead className="trans-total-table-header">
                      <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th>Price </th>
                        <th>Quantity </th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        finalAddonList.map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data?.addonname}</td>
                              <td>{data?.price} AED</td>
                              <td>{data?.qty}</td>
                              <td>{data?.price * data?.qty} AED</td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </Table>
              }
            </div>
            <div className="trans-data-rhs">
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Coupon Applied: </label> {'  '}
                <label className="pay-result">{transactionDetails?.booking?.coupan_applied === "" ? "NO" : `(${transactionDetails?.booking?.coupan_applied})`}</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Discounted Price: </label> {'  '}
                <label className="pay-result">{Math.ceil(discountedAmount)?.toFixed(2)} AED</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Packages Used: </label> {'  '}
                <label className="pay-result">{transactionDetails?.booking?.packages == 0 ? "N0" : transactionDetails?.booking?.packages}</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Amount Paid: </label> {'  '}
                {transactionDetails?.booking?.cancelled ?
                  <label className="pay-result">Cancelled</label>
                  :
                  <label className="pay-result">{transactionDetails?.booking?.packages == 0 ? (transactionDetails?.booking?.amount_percentage === 1 || transactionDetails?.booking?.amount_percentage === 100) ? "100% Paid" : transactionDetails?.booking?.amount_percentage === 0.5 ? "50% Paid" : '' : "Paid by Packages"}</label>
                }
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>Advance Paid: </label> {'  '}
                <label className="pay-result">{Math.round(transactionDetails?.booking?.amount_paid).toFixed(2)} AED</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 15, width: "50%" }}>To be Paid at Facility:</label> {'  '}
                <label className="pay-result">{Math.floor(payFacilityAmount).toFixed(2)} AED</label>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <h2 className="client-info">Client Info</h2>
          <Table responsive className="client-tab-data">
            <tbody className="table-user-details">
              <tr>
                <td>
                  <h5>Client Name</h5>
                </td>
                <td className="client-name">
                  {transactionDetails?.user?.first_name === "null" ? " " : transactionDetails?.user?.first_name}{' '}
                  {transactionDetails?.user?.last_name === "null" ? " " : transactionDetails?.user?.last_name}
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Email</h5>
                </td>
                <td className="client-email">{transactionDetails?.user?.email === "null" ? " " : transactionDetails?.user?.email}</td>
              </tr>
              <tr>
                <td>
                  <h5>Phone</h5>
                </td>
                <td className="client-Ph-no">{transactionDetails?.user?.phone_no === "null" ? " " : transactionDetails?.user?.phone_no}</td>
              </tr>
              <tr>
                <td>
                  <h5>No. of Games Played</h5>
                </td>
                <td className="client-Ph-no">{transactionDetails?.user?.no_of_games === "null" ? " " : transactionDetails?.user?.no_of_games}</td>
              </tr>
              <tr>
                <td>
                  <h5>Padelholic Level</h5>
                </td>
                <td className="client-Ph-no">
                  {padelholicLevels?.level}
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Address</h5>
                </td>
                <td className="client-Ph-no">{transactionDetails?.user?.address === "null" ? " " : transactionDetails?.user?.address}</td>
              </tr>
              <tr>
                <td>
                  <h5>Gender</h5>
                </td>
                <td className="client-Ph-no">{transactionDetails?.user?.gender === "null" ? " " : transactionDetails?.user?.gender}</td>
              </tr>
              <tr>
                <td>
                  <h5>City</h5>
                </td>
                <td className="client-Ph-no">{transactionDetails?.user?.city === "null" ? " " : transactionDetails?.user?.city}</td>
              </tr>
              <tr>
                <td>
                  <h5>State</h5>
                </td>
                <td className="client-Ph-no">{transactionDetails?.user?.state === "null" ? " " : transactionDetails?.user?.state}</td>
              </tr>
              <tr>
                <td>
                  <h5>Country</h5>
                </td>
                <td className="client-Ph-no">{transactionDetails?.user?.country === "null" ? " " : transactionDetails?.user?.country}</td>
              </tr>
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId="3">
          <div className="trans-logs-container">
            <div className="trans-logs-container-lhs">

              <div>
                <label className="trans-logs-lhs-text">Card Number:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.card_number === "null" ? " " : transactionLogs?.card_number}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Card Holder Name:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.card_holder_name === "null" ? " " : transactionLogs?.card_holder_name}</label>
              </div>

              <div>
                <label className="trans-logs-lhs-text">Merchant Identifier:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.merchant_identifier === "null" ? " " : transactionLogs?.merchant_identifier}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Response Code</label> {' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.response_code === "null" ? " " : transactionLogs?.response_code}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Amount Paid</label> {' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.amount === "null" ? " " : parseFloat(parseFloat(transactionLogs?.amount) / 100).toFixed(2)}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Expiry Date:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.expiry_date === "null" ? " " : transactionLogs?.expiry_date}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Customer IP:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.customer_ip === "null" ? " " : transactionLogs?.customer_ip}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Language:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.language === "null" ? " " : transactionLogs?.language}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">ECI:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.eci === "null" ? " " : transactionLogs?.eci}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Fort Id:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.fort_id === "null" ? " " : transactionLogs?.fort_id}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Command:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.command === "null" ? " " : transactionLogs?.command}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Response Message:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.response_message === "null" ? " " : transactionLogs?.response_message}</label>
              </div>
            </div>

            <div className="trans-logs-container-rhs">
              <div>
                <label className="trans-logs-lhs-text">Merchant Reference:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.merchant_reference === "null" ? " " : transactionLogs?.merchant_reference}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Authorization Code:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.authorization_code === "null" ? " " : transactionLogs?.authorization_code}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Currency:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.currency === "null" ? " " : transactionLogs?.currency}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Acquirer Response Code:  </label> {' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.acquirer_response_code === "null" ? " " : transactionLogs?.acquirer_response_code}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Payment Option:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.payment_option === "null" ? " " : transactionLogs?.payment_option}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Customer Email:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.customer_email === "null" ? " " : transactionLogs?.customer_email}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Order Description:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.order_description === "null" ? " " : transactionLogs?.order_description}</label>
              </div>
              <div>
                <label className="trans-logs-lhs-text">Status:  </label>{' '}
                <label className="trans-logs-rhs-text">{transactionLogs?.status === "null" ? " " : transactionLogs?.status}</label>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default TransactionTabs;
