import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import classNames from "classnames";
import { RTLProps } from "../prop-types/ReducerProps";
import DatePicker from "react-date-picker";
import { useForm } from "react-hook-form";
import moment from "moment";
import axios from "../../customAxios";
import { environment } from "../../containers/env";
import swal from '@sweetalert/with-react';

const BlockCoacheModal = ({ color, btn, colored, header, title, coacheId, submitDate, selectedDate }) => {
  const [modal, setModal] = useState(false);
  const [start, onChangeStart] = useState(new Date(selectedDate));
  const [times, setTimes] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
    "12:45 AM",
    "01:30 AM",
    "02:15 AM",
  ]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let blockDate = moment(start).format("YYYY-MM-DD");

    const blockCoachPayload = {
      blockingDate: blockDate,
      blockFrom: data.starttime,
      blockTo: data.endtime,
      coacheId: coacheId,
    };

    const blockCoachURL = `${environment.baseUrl}staffmanagement/block-coache/`;

    axios.post(blockCoachURL, blockCoachPayload).then((blockCoachResponse)=>{
      if(blockCoachResponse.data.Message === "Coache is blocked"){
        swal(
          {
            icon: "success",
            content: (<div>
              <h1 style={{ color: "#28a745" }}>Coach is blocked</h1>
            </div>)
          }
        ).then(() => {
          toggle();
          submitDate();
        })
      }else{
        swal({
          icon: "error",
          content: (<div>
            <h1 style={{ color: "darkred" }}>Error</h1>
            <p>
              Error, While blocking Coach
            </p>
          </div>)
        })
      }
    });

  };

  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  return (
    <div className="calender__available" onClick={toggle}>
      Available
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`modal ltr-support`}
        className={`modal-dialog--${color} ${modalClass}`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm="4" md="4">
                <div className="form__form-group">
                  <span className="form__form-group-label">Date</span>
                  <div className="inner-container">
                    <DatePicker
                      dateFormat="YYYY-MM-DD"
                      onChange={onChangeStart}
                      value={start}
                      className="custom-css-dp"
                    />
                  </div>
                </div>
              </Col>
              <Col sm="4">
                <div className="form__form-group">
                  <span className="form__form-group-label">Start Time</span>
                  <div className="form__form-group-field">
                    <select
                      name="padelcourt"
                      className="textareaFeild"
                      {...register("starttime", { required: true })}
                    >
                      <option value="">Select Start Time</option>
                      {times &&
                        times.map((time) => (
                          <option value={time}>{time}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.starttime && (
                  <span className="invalid-feedback d-flex">
                    Start time is required
                  </span>
                )}
              </Col>
              <Col sm="4">
                <div className="form__form-group">
                  <span className="form__form-group-label">End Time</span>
                  <div className="form__form-group-field">
                    <select
                      name="padelcourt"
                      className="textareaFeild"
                      {...register("endtime", { required: true })}
                    >
                      <option value="">Select End Time</option>
                      {times &&
                        times.map((time) => (
                          <option value={time}>{time}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {errors.endtime && (
                  <span className="invalid-feedback d-flex">
                    End time is required
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm="9"></Col>
              <Col sm="3">
                <div className="form__form-group">
                  <span className="form__form-group-label"></span>
                  <div className="inner-container">
                    <button
                      color="warning"
                      size="sm"
                      style={{
                        height: 35,
                        backgroundColor: "#FDAE00",
                        paddingRight: 20,
                        paddingLeft: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        borderRadius: 4,
                        fontSize: 15,
                        fontFamily: "sans-serif",
                        border: 0,
                      }}
                    >
                      <p style={{ color: "#fff" }}>Block</p>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

BlockCoacheModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
};

BlockCoacheModal.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
};

export default BlockCoacheModal;
