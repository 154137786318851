import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import { BookingDetailsAvailableFalseByBookingID, UpdateComment } from '../../containers/Reuse/ApiCalls';
import moment from 'moment';
import swal from '@sweetalert/with-react';
import { useForm } from "react-hook-form";

const CommentModal = ({
    color, colored, header, title, userCommentModal, bookingId, commentRefresh
}) => {
    const [modal, setModal] = useState(false);
    const [comment, setComment] = useState();
    const [previousComments, setPreviousComments] = useState([]);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors,isValid },
      } = useForm();
    

    const toggle = () => {
        setModal(prevState => !prevState);
    };

    useEffect(() => {
        setModal(userCommentModal);
        BookingDetailsAvailableFalseByBookingID(bookingId).then((response)=>{
            if(response.data.comments === '' || response.data.comments === undefined || response.data.comments === null){
                console.log();
            }else{
                setPreviousComments(JSON.parse(response.data.comments));
            }
        })
    }, [userCommentModal]);

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    const onSubmit = (values) => {
        
        reset({});
        const adminId = localStorage.getItem("UserId");
        let NewComment
        if(typeof(previousComments) === "string"){
            NewComment = [...JSON.parse(previousComments),{comment:values.comment,date:moment(new Date()).format("DD-MM-YYYY"),time:moment(new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))).format("hh:mm A"),user:localStorage.getItem("AdminUserName")}];
        }else{
            NewComment = [...previousComments,{comment:values.comment,date:moment(new Date()).format("DD-MM-YYYY"),time:moment(new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' }))).format("hh:mm A"),user:localStorage.getItem("AdminUserName")}];            
        }
        console.log("NewComment",NewComment);
        const updateCommentPayload = {
            id:bookingId,
            comment:JSON.stringify(NewComment)
        }
        UpdateComment(updateCommentPayload).then((response)=>{
            console.log(response);
            if(response.Message === 'Comments is updated'){
                reset({});
                swal(
                    {
                      icon:"success",       
                      content: (<div>
                        <h1 style={{color:"#28a745"}}>Comment is updated sucessfully</h1>
                      </div>)          
                    }).then(() => {
                        toggle();
                        commentRefresh();
                    })
                }
        })
    }

    return (
        <div >
            {/* <Button color={color} onClick={toggle}>{btn}</Button> */}
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass}`}
               >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <div className='Modal-comment-container'>
                    <div className='inner-Modal-comment-container'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <span>Comments #{bookingId}</span>
                            <textarea type="text"  placeholder='Comments' className='comment-area' name="comment" {...register("comment", { required: true })}/>
                            {errors.comment && (<span style={{ color: "#bc4040" }}> {" "}Comment Is require *</span> )}
                            <div className='Modal-comment-btn'>
                                <button className='Modal-comment-cancel-btn' type='button' onClick={() => toggle()}>Cancel</button>
                                <button className='Modal-comment-save-btn'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

CommentModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired,
};

CommentModal.defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
};

export default CommentModal;

// export default connect(state => ({
//   rtl: state.rtl,
// }))(CommentModal);
