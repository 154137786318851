import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button,Modal } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import LogInForm from '../../containers/Forms/LogInForm';
import BulkBooking from '../../containers/Forms/BulkBooking';
import { BulkBookingApi } from '../../containers/Reuse/ApiCalls';


const ModalComponent = ({
    color, btn,colored, header,title, submitDate
}) => {
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(prevState => !prevState);
    };

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });
    
    const ToggleToRefresh = () => {
        submitDate();
        toggle();
    }

    const handleSubmit = (values) => {
        const adminId = localStorage.getItem("UserId");
        console.log("Admin ID", values);
        // const bulkbookingPayload = {
        //     start_date:values.startdate,
        //     end_date:values.enddate,
        //     start_time:values.starttime.value,
        //     end_time:values.endtime.value,
        //     maincourtid:values.maincourt.value,
        //     padecourtid:values.padelcourt.value,
        //     customer_name:values.customername,
        //     customer_email:values.customeremail,
        //     customer_phone_no:values.customerphoneno,
        //     amount_paid_by:values.amountpaidby.value,
        //     comments:values.comments,
        //     admin_id:adminId,
        //     amount:values.amountpaid,
        //     add_ons:"1-2,2-1"
        //     }

        //     BulkBookingApi(bulkbookingPayload).then((response)=>{
        //         console.log("Bulk Booking Resposne", response)
        //     })
        // console.log("bulkbookingPayload", bulkbookingPayload);
    }
    return (
        <div>
            <Button color={color} onClick={toggle}>{btn}</Button>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass}`}
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <BulkBooking ToggleToRefresh={ToggleToRefresh} />
            </Modal>
        </div>
    );
};

ModalComponent.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired,
};

ModalComponent.defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
};

export default ModalComponent;

// export default connect(state => ({
//   rtl: state.rtl,
// }))(ModalComponent);
