import React, { useState, useEffect } from "react";
import axios from "../../../customAxios";
import { environment } from "../../env";
import { Row, Card, CardBody, Col } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";

const ExampleCard = () => {
  const [mainCourtList, setMainCourtList] = useState([]);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const getMainCourt = `${environment.baseUrl}maincourts/${id}`;
    const sendGetRequest = async () => {
      try {
        const resp = await axios.get(getMainCourt);
        setMainCourtList(resp.data);
      } catch (err) {
        console.error(err);
      }
    };
    sendGetRequest();
    if (localStorage.getItem("Token")) {
    } else {
      alert("You are not logged In. Please LogIn");
      history.push("/");
    }
  }, []);

  return (
    <Row>
      <Col md={12}>
        <h3 className="page-title w-100">Main Court {mainCourtList?.courtName} Details</h3>
      </Col>

      <Col md={13}>
        <Card>
          <CardBody>
            <div className="card__title"></div>
            <table
              style={{
                padding: 13,
                borderRadius: "10px",
                borderCollapse: "inherit",
              }}
            >
              <></>
              <tbody style={{ fontSize: 15 }}>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Image</th>
                  <td>
                    <img
                      src={mainCourtList?.maincourtimage}
                      alt={mainCourtList?.maincourtimage}
                      style={{ width: 180, borderRadius: 5 }}
                    />
                  </td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>About</th>
                  <td style={{ paddingTop: 17 }}>{mainCourtList?.about}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Booking Count</th>
                  <td>{mainCourtList?.booking_count}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Court Address</th>
                  <td>{mainCourtList?.courtaddress}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Court Name</th>
                  <td>{mainCourtList?.courtName}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Court Type</th>
                  <td>{mainCourtList?.courttype}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Features </th>
                  <td>{mainCourtList?.features}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Padel Courts</th>
                  <td>{mainCourtList?.padelCourts}</td>
                </tr>
                {mainCourtList?.ladies_time_slot && (
                  <tr>
                    <th style={{ padding: 10, width: 200 }}>
                      Ladies Time Slot
                    </th>
                    <td>{mainCourtList?.ladies_time_slot}</td>
                  </tr>
                )}
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Latitude</th>
                  <td>{mainCourtList?.latitude}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Longitude</th>
                  <td>{mainCourtList?.longitude}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Price</th>
                  <td>{mainCourtList?.price?.toFixed(2)} AED</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Non Peak Hours Start</th>
                  <td>{mainCourtList?.non_peak_hours_start}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Non Peak Hours End</th>
                  <td>{mainCourtList?.non_peak_hours_end}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Peak Hours Price Firstslot</th>
                  <td>{mainCourtList?.peak_hours_price_firstslot?.toFixed(2)} AED</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 250 }}>Peak Hours Price Secondslot</th>
                  <td>{mainCourtList?.peak_hours_price_secondslot?.toFixed(2)} AED</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Rating</th>
                  <td>{mainCourtList?.rating}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Timeslot</th>
                  <td>{mainCourtList?.timeslot}</td>
                </tr>
                <tr>
                  <th style={{ padding: 10, width: 200 }}>Video</th>
                  <td>
                    <iframe
                      width="560"
                      height="315"
                      src={mainCourtList?.video_url}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                padding: 13,
                borderRadius: "10px",
                borderCollapse: "inherit",
              }}
            >
              <tr
                style={{
                  padding: 10,
                  width: 200,
                  fontWeight: "bold",
                  fontSize: "17px",
                }}
              >
                Court Images
              </tr>

              <tr style={{ display: "flex" }}>
                {mainCourtList?.maincourt_about_images &&
                  JSON.parse(mainCourtList?.maincourt_about_images).map(
                    (images, index) => {
                      return (
                        <img
                          src={images?.resource}
                          alt={images?.resource}
                          style={{
                            width: 186,
                            borderRadius: 5,
                            marginTop: "20px",
                            marginRight: "20px",
                          }}
                        />
                      );
                    }
                  )}
              </tr>
            </table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ExampleCard;
