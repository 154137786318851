import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import ListAddonsEditCard from './components/AddonsEditCard';

const ListAddons = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Addon&apos;s</h3>
      </Col>
    </Row>
    <Row>
      <ListAddonsEditCard />
    </Row>
  </Container>
);
export default ListAddons;
