import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import CourtCategoryEditCard from './components/CourtCategoryEditCard';

const CourtCategory = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Court Category</h3>
        {/* <h3 className="page-subhead subhead">
          Use this elements, To Add Court Category
        </h3> */}
      </Col>
    </Row>
    <Row>
      <CourtCategoryEditCard />
    </Row>
  </Container>
);
export default CourtCategory;
