import React from 'react';
import {
    Col, Container, Row,
} from 'reactstrap';
import EditSlideV2 from './components/EditSlideV2';

const EditSlidesV2 = () => {
    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Edit Slide</h3>
                </Col>
            </Row>
            <Row>
                <EditSlideV2 />
            </Row>
        </Container>
    )
}

export default EditSlidesV2