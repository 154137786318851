import React from 'react'
import { Table } from 'reactstrap';
import { AiOutlineMail,AiOutlinePhone } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
const BookingDetailsUserTabTable = ({customer_name,customer_email,customer_phone}) => {
    return (
        <Table responsive hover>
            <tbody className="table-trans-user-details">
                <tr>
                    <td className="first-td-width">
                        <div className="trans-client-name"><FiUser className="client-icon" /><h5>Client Name</h5></div>
                    </td>
                    <td className="client-name">
                    {customer_name}
                        {/* {bookingDetailsSingle?.user?.first_name === "null" ? " " : bookingDetailsSingle?.user?.first_name}{' '}
                        {bookingDetailsSingle?.user?.last_name === "null" ? " " : bookingDetailsSingle?.user?.last_name} */}
                    </td>
                </tr>
                <tr>
                    <td className="first-td-width">
                        <div className="trans-client-name"><AiOutlineMail className="client-icon" /> <h5>Email</h5></div>
                    </td>
                    <td className="client-email">{customer_email}</td>
                </tr>
                <tr>
                    <td className="first-td-width">
                        <div className="trans-client-name"><AiOutlinePhone className="client-icon" /><h5 >Phone</h5></div>
                    </td>
                    <td className="client-Ph-no">{customer_phone}</td>
                </tr>
            </tbody>
        </Table>
    )
};
export default BookingDetailsUserTabTable;
