/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Col, Card, CardBody,
} from 'reactstrap';
import axios from '../../../customAxios';
import { useHistory } from 'react-router-dom';
import { environment } from '../../env';

const BookingCancelledListCard = () => {
  const [allCourtType, setAllCourtType] = useState([]);
  const history = useHistory();

  const bookingCalcelUrl = `${environment.baseUrl}cancelledbookinglist`;
  const getUser = async () => {
    const result = await axios(bookingCalcelUrl);
    setAllCourtType(result.data);
  };

  useEffect(() => {
    getUser();
    if (localStorage.getItem('Token')) {
      console.log('True');
    } else {
      alert('You are not logged In. Please LogIn');
      history.push('/');
    }
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <table className="react-table resizable-table">
            <thead className="thead th">
              <tr role="row" className="react-table thead tr">
                <th colSpan="1"><span className="react-table__column-header"><span className="">#</span></span>
                </th>
                <th colSpan="1">
                  <span className="react-table__column-header"><span className="">Booking Date</span></span>
                </th>
                <th colSpan="1"><span className="react-table__column-header"><span className="">Time</span></span>
                </th>
                <th colSpan="1">
                  <span className="react-table__column-header"><span className="">Payment Status</span></span>
                </th>
              </tr>
            </thead>

            <tbody className="table table--bordered">
              {
              allCourtType.map((data, key) => (
                <tr role="row" key={data.id}>
                  <td role="cell">{key + 1}</td>
                  <td role="cell">{data.booking_date}</td>
                  <td role="cell">{data.timeslot}</td>
                  <td role="cell">{data.payment_status}</td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Col>
  );
};
export default BookingCancelledListCard;
