import React, { useState, useEffect } from "react";
import { environment } from "../../env";
import axios from "../../../customAxios";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../../shared/components/table/components/ReactTableCustomizer";
import moment from "moment";

const UserPackageTransactionDetails = ({userId}) => {
  const [packagesData, setPackagesData] = useState([]);
  const [isSortable, setIsSortable] = useState(false);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const GetPackagePurchase = async () => {
    var rawTransactions = [];
    const GetPackagePurchaseUrl = `${environment.baseUrl}package-purchase/${userId}/`;
    const result = await axios.get(GetPackagePurchaseUrl).then((response) => {
      rawTransactions = response.data.map((trans) => {
        if (trans.package !== "booking") {
          trans.package = JSON.parse(trans.package);
        }
        if (trans.purchased_using === "admin") {
          trans.transaction_log = JSON.parse(trans.transaction_log);
        }
        return trans;
      });
      setPackagesData(rawTransactions);
    });
  };

  var jsonObj3 = [];
  const data3 = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Recharged/Deduct",
        accessor: "recharged_deduct",
        disableGlobalFilter: true,
        width: 60,
      },
      {
        Header: "Information",
        accessor: "package_name",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Date Time",
        accessor: "date_time",
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Comments",
        accessor: "comments",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Packages Id",
        accessor: "packages_id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "From",
        accessor: "from",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Created By",
        accessor: "created_by",
        disableGlobalFilter: true,
        width: 65,
      },
    ],
    data: jsonObj3,
  };
  for (let i = 0; i < packagesData?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["recharged_deduct"] = (
      <div
        className={
          packagesData[i].type === "Recharge" ? "rechargePack" : "deductPack"
        }
      >
        {packagesData[i].type === "Recharge"
          ? "+" + packagesData[i]?.usedslot + " Slots"
          : "-" + packagesData[i]?.usedslot + " Slots"}
      </div>
    );
    item["package_name"] = (
      <div className="right-space">
        <div>
          {packagesData[i].type === "Recharge"
            ? `${packagesData[i]?.package[0]?.title}  (${packagesData[i]?.package[0]?.slot} Slots)`
            : "Deduct"}
        </div>
      </div>
    );
    item["date_time"] = moment(packagesData[i].purchase_at.toString()).format(
      "YYYY-MM-DD hh:mm A"
    );
    item["comments"] = (
      <div>
        {packagesData[i]?.purchased_using === "admin" &&
          packagesData[i]?.transaction_log[0]?.comment}
      </div>
    );
    item["packages_id"] = (
      <div>
        {packagesData[i].type === "Recharge"
          ? `Packages ID: ${packagesData[i].id}`
          : `Packages ID: ${packagesData[i].id}`}
      </div>
    );
    item["from"] = (
      <div>
        {packagesData[i].type == "Recharge" && packagesData[i].purchased_using == "admin" ?
         "Just Padel Team" :
         packagesData[i].type == "Deduct" && packagesData[i].purchased_using == "admin" ?
         "Just Padel Team" : 
         packagesData[i].type == "Recharge" && (packagesData[i].purchased_using == "app" || packagesData[i].purchased_using == "website") ?
         packagesData[i].id : packagesData[i].booking}
      </div>
    );
    item["payment_status"] = (
      <div>
        <div
          className={
            packagesData[i].payment_status === "completed"
              ? "payment_status-completed"
              : "payment_status-incompleted"
          }
        >
          {packagesData[i].payment_status.toString()}{" "}
        </div>
        <div className="purchase-using">{packagesData[i].purchased_using}</div>
      </div>
    );
    item["created_by"] = (
      <div>
        <div>
          {(packagesData[i].created_by === "12:45 PM" || packagesData[i].created_by === "null") ? "Just Padel" : JSON.parse(packagesData[i]?.created_by)[0].userFirstName}{" "}
        </div>
      </div>
    );
    jsonObj3.push(item);
  }

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Name...",
  };

  const DataNotFoundCourt = [
    {
      main_court: "Data Not Found",
    },
  ];


  useEffect(() => {
    GetPackagePurchase();
  }, []);

  return (
    <div className="user-packages-body">
    <ReactTableBase
      key={withSearchEngine ? "searchable" : "common"}
      columns={data3.columns}
      data={data3.data.length === 0 ? DataNotFoundCourt : data3.data}
      tableConfig={tableConfig}
    />
    </div>
  );
};

export default UserPackageTransactionDetails;
