import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import { PackagesGetAPI } from "../containers/Reuse/ApiCalls";
import ReactTableCustomizer from "../shared/components/table/components/ReactTableCustomizer";
import ReactTableBase from "../shared/components/table/ReactTableBase";
import parse from 'html-react-parser';
import { environment } from "../containers/env";
import axios from "../customAxios";
import swal from 'sweetalert'

const PackageList = () => {
  const [packages, setPackages] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [freeSlots, setFreeSlots] = useState(true);

  const history = useHistory();

  useEffect(() => {
    PackagesGetAPI().then((packagesResponse) => {
      setPackages(packagesResponse);
    });
  }, []);

  var jsonObj = [];
  for (let i = 0; i < packages.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["image"] = (
      <div className="right-space">
        <img
          src={packages[i].image}
          style={{ width: 60 }}
          alt={packages[i].image}
        />
      </div>
    );
    item["redirectUrl"] = (
      <div className="right-space">{packages[i].redirect_url}</div>
    );
    item["title"] = packages[i].title;
    item["slot"] = <div className="right-space">{packages[i].slot}</div>;
    item["price"] = <div className="right-space">{packages[i].price}</div>;
    item["description"] = <div className="right-space">{packages[i].description}</div>;
    item["offers"] = parse(packages[i].offers);

    item["link"] = (
      <div className="right-space">
        <div
          onClick={() => handleEdit(packages[i].id)}
          className="modal-edit-btn"
        >
          <EditOutlineIcon style={{ fontSize: 20 }} />{" "}
        </div>
      </div>
    );
    item["delete"] = (
      <div className="right-space">
        <div
          className="modal-delete-btn"
          onClick={() => handleDelete(packages[i].id)}
        >
          <DeleteOutlineIcon style={{ fontSize: 20 }} />
        </div>
      </div>
    );

    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Images",
        accessor: "image",
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: "Title",
        accessor: "title",
        disableGlobalFilter: false,
        width: 90,
      },
      {
        Header: "Slot",
        accessor: "slot",
        disableGlobalFilter: true,
        width: 60,
      },
      {
        Header: "Offers",
        accessor: "offers",
        disableGlobalFilter: true,
        width: 140,
      },
      {
        Header: "Description",
        accessor: "description",
        disableGlobalFilter: true,
        width: 120,
      },
      {
        Header: "Price",
        accessor: "price",
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 60,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 60,
      },
    ],
    rows: jsonObj,
  };

  function handleEdit(packageId) {
    history.push(`/packages/${packageId}`);
  }

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const handleDelete = (packageId) => {
    swal({
      title: "Are you sure?",
      text: "Once package deleted, you will not be able to recover package!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          const PackageDeleteAPIURL = `${environment.baseUrl}package/${packageId}`;
          axios.delete(PackageDeleteAPIURL).then((packageDeletedResponse) => {
            console.log(packageDeletedResponse);
            if (packageDeletedResponse.data.Message === "Package is Deleted Successfully") {
              PackagesGetAPI().then((packagesResponse) => {
                setPackages(packagesResponse);
              });
              swal("Package is deleted!", {
                icon: "success",
              });
            } else {
              swal("Error ! While deleting Package", {
                icon: "error",
              });
            }
          })


        } else {
          swal("Package is safe!");
        }
      });
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Package Name...",
  };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <h3 className="page-title">Packages List</h3>
        </Col>
        <Col md={6}>
          <Link to="package" className="btn btn-primary float-right add-packages" role="button">Add Packages</Link>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper hide-table-costomizer">
                <ReactTableCustomizer
                  handleClickIsSortable={handleClickIsSortable}
                  handleClickWithPagination={handleClickWithPagination}
                  handleClickWithSearchEngine={handleClickWithSearchEngine}
                  handleClickIsResizable={handleClickIsResizable}
                  isResizable={isResizable}
                  isSortable={isSortable}
                  withPagination={withPagination}
                  withSearchEngine={withSearchEngine}
                />
              </div>
              <div className="hover-effect table-id-width">
                {packages && packages ? (
                  <ReactTableBase
                    key={withSearchEngine ? "searchable" : "common"}
                    columns={data.columns}
                    data={data.rows}
                    tableConfig={tableConfig}
                  />
                ) : (
                  ""
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PackageList;
