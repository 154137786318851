import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Card, CardBody, Col } from "reactstrap";
import classNames from "classnames";
import { RTLProps } from "../prop-types/ReducerProps";
import moment from "moment";
import BookingDetailsTab from "../../containers/UI/Tabs/components/BookingDetailsTab";

const SampleModalComponent = ({
  color,
  btn,
  colored,
  header,
  title,
  showHide,
  bookingDetail,
  submitDate,
  prePopulatedDated,
  backgroundColor,
  bookedBy,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal((prevState) => !prevState);
  };

  useEffect(() => {
    setModal(showHide);
  }, [showHide]);

  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const handleSubmit = (values) => {
    const adminId = localStorage.getItem("UserId");
    const bulkbookingPayload = {
      statedate: values.startdate,
      enddate: values.enddate,
      starttime: values.starttime.value,
      endtime: values.endtime.value,
      maincourtid: values.maincourt.value,
      padecourtid: values.padelcourt.value,
      customername: values.customername,
      customeremail: values.customeremail,
      customerphoneno: values.customerphoneno,
      amountpaidby: values.amountpaidby.value,
      commentes: JSON.stringify([
        {
          comment: values.comment,
          date: moment(new Date()).format("DD-MM-YYYY"),
          time: moment(new Date()).format("hh:mm A"),
          user: localStorage.getItem("AdminUserName"),
        },
      ]),
      adminid: adminId,
      amount: values.amountpaid,
    };
  };
  const TabToModal = () => {
    submitDate();
    toggle();
  };
  const Close = () => {
    submitDate();
    toggle();
  };

  return (
    <>
      <td
        onClick={toggle}
        style={{
          backgroundColor: backgroundColor,
          border: ".5px solid #fff",
          color: "#f8f9fa",
          textAlign: "center",
          width: 100,
          flexGrow: 1,
          padding: 10,
          fontSize: 16,
          fontFamily: "sans-serif",
          borderRadius: 4,
          cursor: "pointer",
        }}
      >
        {" "}
        {bookedBy}{" "}
      </td>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`modal ltr-support`}
        className={`modal-dialog--${color} ${modalClass}`}
        style={{ maxWidth: 990 }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div></div>
        {/* <LogInForm onSubmit={(values) => handleSubmit(values)} formdata={bookingDetail} /> */}
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--justify tabs--bordered-bottom">
                <BookingDetailsTab
                  transactionId={bookingDetail.booking_id}
                  prePopulatedDated={prePopulatedDated}
                  toggleModal={TabToModal}
                  close={Close}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Modal>
    </>
  );
};

SampleModalComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  showHide: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
  bookingDetail: PropTypes.array,
};

SampleModalComponent.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
  showHide: false,
  bookingDetail: "",
};

export default SampleModalComponent;
