import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Card, CardBody, Table } from "reactstrap";
// import { AiFillEye } from "react-icons/ai";
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { environment } from '../../env';
import axios from "../../../customAxios";
import moment from "moment";
import DeletedPostModel from "./DeletedPostModal.jsx";
import ReactPaginate from "react-paginate";
import { useForm } from "react-hook-form";
import _ from "lodash";

const DeletedPost = () => {
  const [deletedPost, setDeletedPost] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [pageCount, setpageCount] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [initialPage, setInitialPage] = useState(0);
  const [search, setSearch] = useState(false);
  const [filterQueryValue, setFilterQueryValue] = useState();
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);

  let limit = parseInt(localStorage.getItem("BookingListCurrentPageLimit"));

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const GetDeletedPostData = (page, limit) => {
    const GetDeletedPostURL = `${environment.baseUrl}padelgram/get-deleted-post/?page=${page}&page_size=${limit}`;
    const result = axios.get(GetDeletedPostURL).then((response) => {
      setDeletedPost(response.data.results);
      setpageCount(Math.ceil(response.data.count / limit));
    });
  };


  var jsonObj = [];
  for (let i = 0; i < deletedPost.length; i++) {
    var item = {};
    item["id"] = (i + 1).toString();;
    item["postid"] = deletedPost[i].id.toString();
    {
      (deletedPost[i].padelgram_image).includes("mp4") || (deletedPost[i].padelgram_image).includes("mov") ?
        item["image"] = <div style={{ paddingLeft: 10 }}><video style={{ width: '75%', borderRadius: 5 }} preload="true" controls src={deletedPost[i].padelgram_image}></video>
        </div>
        : item["image"] = <div style={{ paddingLeft: 10 }}><img src={deletedPost[i].padelgram_image}
          alt={deletedPost[i].padelgram_image}
          style={{ width: '75%', borderRadius: 5 }} />
        </div>
    }
    item["created_datetime"] = <div className="Booking-details-td-right-space">
      {moment(deletedPost[i].created_time.split("T")[0]).format("DD-MM-YYYY")} {" "}
      {moment(deletedPost[i].created_time.split("T")[1], "HH:mm:ss").format("hh:mm A")}
    </div>;
    item["caption"] = deletedPost[i].padelgram_image_comment.toString();
    item["location"] = deletedPost[i].location.toString();
    item["visibility"] = deletedPost[i].post_type.toString();
    item["client"] = <div>{deletedPost[i].user.first_name}{" "}{deletedPost[i].user.last_name}</div>
    item["deleted_datetime"] = <div className="Booking-details-td-right-space">
      {moment(deletedPost[i].deleted_time.split(" ")[0]).format("DD-MM-YYYY")} {" "}
      {moment(deletedPost[i].deleted_time.split(" ")[1], "HH:mm:ss").format("hh:mm A")}
    </div>;
    item["view"] = <div className="right-space"><div className="modal-view-btn"><DeletedPostModel UserData={deletedPost[i]} /> </div></div>;
    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "PostID",
        accessor: "postid",
        disableGlobalFilter: false,
        width: 40,
      },
      {
        Header: "Image",
        accessor: "image",
        disableGlobalFilter: true,
        width: 90,
      },
      {
        Header: "Created Date & Time",
        accessor: "created_datetime",
        disableGlobalFilter: true,
        width: 45,
      },
      {
        Header: "Caption",
        accessor: "caption",
        disableGlobalFilter: false,
        width: 60,
      },
      {
        Header: "Location",
        accessor: "location",
        disableGlobalFilter: false,
        width: 35,
      },
      {
        Header: "Visibility",
        accessor: "visibility",
        disableGlobalFilter: false,
        width: 50,
      },
      {
        Header: 'Client',
        accessor: 'client',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 60,
      },
      {
        Header: 'Deleted Date & Time',
        accessor: 'deleted_datetime',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 60,
      },
      {
        Header: 'View',
        accessor: 'view',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      }
    ],
    rows: jsonObj,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [100, 200, 300, 400],
    placeholder: "Search by Name and Slots...",
  };

  useEffect(() => {
    GetDeletedPostData(1,10);
  }, []);

  async function handleLimitChange(e) {
    localStorage.setItem("BookingListCurrentPageLimit", e.target.value);
    let currentPage = parseInt(localStorage.getItem("BookingListCurrentPage"));
    GetDeletedPostData(currentPage, e.target.value)
    setInitialPage(0);
  }


  const handlePageClick = async (data) => {
    

    if (!search) {
        setSpinner(true);
        let currentPage = data.selected + 1;
        setInitialPage(data.selected);
        let newlimit = parseInt(
        localStorage.getItem("BookingListCurrentPageLimit")
        );
        localStorage.setItem("BookingListCurrentPage", currentPage);
        GetDeletedPostData(currentPage, newlimit)
        setSpinner(false);
      } else {
        let currentPage = data.selected + 1;
        const FilterUrl = `${environment.baseUrl}padelgram/get-deleted-post/?page=${currentPage}&search=${filterQueryValue}`;
        axios.get(FilterUrl).then((filterResponse) => {
            setDeletedPost(filterResponse.data.result);
            let limit = parseInt(localStorage.getItem("LivePostCurrentPageLimit"));
            setpageCount(Math.ceil(filterResponse?.data?.count / limit));
        })
      }

  };

  const debounce = useCallback(
    _.debounce((_searchVal) => {
      setSearch(true);
      FilterDeletedPost(_searchVal);
    }, 1000),
    []
  );

  const FilterDeletedPost = (queryValue) => {
    const FilterUrl = `${environment.baseUrl}padelgram/get-deleted-post/?search=${queryValue}`;
    axios.get(FilterUrl).then((filterResponse) => {
        setDeletedPost(filterResponse.data.results);
      let limit = parseInt(localStorage.getItem("LivePostCurrentPageLimit"));
      setpageCount(Math.ceil(filterResponse?.data?.count / limit));
    })
  };

  const onSubmit = (data) => {
    if (data.length > 2) {
      setFilterQueryValue(data);
      debounce(data);
    }
    setSearch(false);
    GetDeletedPostData(1,10);
  };


  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Deleted Post</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
            <div className="react-table__wrapper">
                <div className="card__title bookings">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="filter-fields">
                      <input
                        type="text"
                        name="query"
                        placeholder="Search by Name, Email, Phone No, Date ....."
                        style={{ width: "25%" }}
                        {...register("query")}
                        onChange={(e) => onSubmit(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="react-table__wrapper hide-table-costomizer">
                <ReactTableCustomizer
                  handleClickIsSortable={handleClickIsSortable}
                  handleClickWithPagination={handleClickWithPagination}
                  handleClickWithSearchEngine={handleClickWithSearchEngine}
                  handleClickIsResizable={handleClickIsResizable}
                  isResizable={isResizable}
                  isSortable={isSortable}
                  withPagination={withPagination}
                  withSearchEngine={withSearchEngine}
                />
              </div>
              <div className="table-id-desabled pagination-hide hide-filtername hover-effect hide-table-costomizer second-child-space">
                <ReactTableBase
                  key={withSearchEngine ? "searchable" : "common"}
                  columns={data.columns}
                  data={data.rows}
                  tableConfig={tableConfig}
                />
              </div>

              <div className="custom-pagination">
                <div className="records-per-page">
                  <select
                    value={localStorage.getItem("BookingListCurrentPageLimit")}
                    onChange={handleLimitChange}
                    name="limit"
                    id="select-pagination"
                  >
                    {mannualLimits &&
                      mannualLimits.map((mannualLimits) => (
                        <option value={mannualLimits}>{mannualLimits}</option>
                      ))}
                  </select>
                  &nbsp;&nbsp;&nbsp;records per page
                </div>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={initialPage}
                />
              </div>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default DeletedPost
