import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Table, Container, } from 'reactstrap';
import { AiOutlineClose, AiOutlineCheck, AiFillEye } from 'react-icons/ai';
import moment from 'moment';
import axios from '../../customAxios';
import { environment } from '../env';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';
import { useForm } from "react-hook-form";
import classnames from "classnames";

export const VerifiedSkillLevel = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm()

    const [skillLevel, setSkillLevel] = useState([]);
    const [isSortable, setIsSortable] = useState(true);
    const [isResizable, setIsResizable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
    const [pageCount, setPageCount] = useState(0);
    const [pageLimits, setPageLimits] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [initialPage, setInitialPage] = useState(0);
    const [searchInput, setSearchInput] = useState();


    const getVerifySkill = (page = 1, pagesize = 10) => {
        const GetSkillVerifyURL = `${environment.baseUrl}skill-verification-details/verified?page=${page}&page_size=${pagesize}`
        axios.get(GetSkillVerifyURL).then((response) => {
            setSkillLevel(response.data.results)
            setPageCount(response?.data?.count / pagesize)
        })
    };

    const onSubmit = (data) => {
        const GetSkillVerifyURL = `${environment.baseUrl}skill-verification-details/verified?query=${data.search}`;
        axios.get(GetSkillVerifyURL).then((response) => {
            setSkillLevel(response?.data?.results);
            setPageCount(response?.data?.count / pageLimits)
        })
    };

    useEffect(() => {
        getVerifySkill(1, pageLimits);
    }, []);

    var jsonObj2 = [];
    for (let i = 0; i < skillLevel?.length; i++) {
        var item = {}
        item["id"] = i + 1;
        item["username"] = skillLevel[i].user.first_name.toString();
        item["email"] = skillLevel[i].user.email.toString();
        item["phone_no"] = skillLevel[i].user.phone_no.toString();
        item["skillname"] = skillLevel[i]?.user?.skilllevel.toString();
        item["coach_name"] = skillLevel[i]?.coach?.first_name.toString();
        item["applied_date"] = <div className="right-space">{moment(skillLevel[i].applied_date).format("DD-MM-YYYY")} <br />
            {moment(skillLevel[i].applied_date.split('T')[1], "HH:mm:ss").format("hh:mm A")}
        </div>;

        // item["view"] = <button className='modal-view-btn' onClick={() => redirectToRegisteredUser(skillLevel[i].user.id)}><AiFillEye style={{ fontSize: 20, height: "19px", width: "19px" }} /></button>;
        item["verify"] = <div className="right-space">
            <button className='modal-view-btn' style={{cursor:'default'}}
            >
                {
                    skillLevel[i].isverified === true ?
                        <AiOutlineCheck style={{ fontSize: 20, height: "19px", width: "19px" }} />
                        :
                        <AiOutlineClose style={{ fontSize: 20, height: "19px", width: "19px" }} />
                }
            </button>
        </div>;
        jsonObj2.push(item);
    }

    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };

    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };

    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const tableConfig = {
        isEditable: false,
        isResizable,
        isSortable,
        withPagination: true,
        withSearchEngine: false,
        manualPageSize: [100000, 200000, 300000, 400000],
        placeholder: 'Search by Name...',
    };

    const newdata = {
        columns: [
            {
                Header: '#',
                accessor: 'id',
                disableGlobalFilter: true,
                width: 10,
            },
            {
                Header: 'Name',
                accessor: 'username',
                disableGlobalFilter: false,
                width: 70,
            },
            {
                Header: 'Email',
                accessor: 'email',
                disableGlobalFilter: false,
                width: 100,
            },
            {
                Header: 'Phone Number',
                accessor: 'phone_no',
                disableGlobalFilter: false,
                width: 70,
            },
            {
                Header: 'Verified Skill Level',
                accessor: 'skillname',
                disableGlobalFilter: true,
                width: 50,
            },
            {
                Header: 'Coach name',
                accessor: 'coach_name',
                disableGlobalFilter: true,
                width: 70,
            },
            {
                Header: 'Applied Date',
                accessor: 'applied_date',
                disableGlobalFilter: true,
                width: 50,
            },
            // {
            //     Header: 'View',
            //     accessor: 'view',
            //     disableGlobalFilter: true,
            //     width: 50,
            // },
            {
                Header: 'Verify',
                accessor: 'verify',
                disableGlobalFilter: true,
                disableSortBy: true,
                width: 30,
            }
        ],
        data: jsonObj2
    };

    const redirectToRegisteredUser = (userId) => {
        window.open(`user_details/${userId}`, '_blank');
    };

    const handleLimitChange = (e) => {
        setInitialPage(0);
        setCurrentPage(1);
        setPageLimits(parseInt(e.target.value));
        getVerifySkill(1, parseInt(e.target.value));
    };

    const handlePageClick = (page) => {
        setCurrentPage(page.selected + 1);
        getVerifySkill(page.selected + 1, pageLimits);
    };

    const handleClear = () => {
        reset()
        getVerifySkill(1, pageLimits)
    }


    return (
        <>
            <Row>
                <Col md={12} lg={12}>
                    <Card >
                        <CardBody className='mb-4'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div class="table__search">
                                    <input placeholder="Search by Name, Email, Phone No..." type="text" class={`table__search table__search-input form-control ${errors.search && 'is-invalid'}`} value={searchInput} {...register("search", { required: true })} />
                                    <button className='btn btn-sm btn-warning' type='submit'>Search</button>
                                    <button className='btn btn-sm btn-danger' type='button' onClick={() => handleClear()}>Clear</button>
                                </div>
                            </form>
                            <div className="react-table__wrapper hide-table-costomizer">
                                <ReactTableCustomizer
                                    handleClickIsSortable={handleClickIsSortable}
                                    handleClickWithPagination={handleClickWithPagination}
                                    handleClickWithSearchEngine={handleClickWithSearchEngine}
                                    handleClickIsResizable={handleClickIsResizable}
                                    isResizable={isResizable}
                                    isSortable={isSortable}
                                    withPagination={withPagination}
                                    withSearchEngine={withSearchEngine}
                                />
                            </div>

                            <div className="table-id-desabled pagination-hide hover-effect hide-filtername table-id-width">
                                <ReactTableBase
                                    key={withSearchEngine ? 'searchable' : 'common'}
                                    columns={newdata.columns}
                                    data={newdata.data}
                                    tableConfig={tableConfig}
                                />
                            </div>
                            <div className="custom-pagination">
                                <div className="records-per-page">
                                    <select
                                        value={pageLimits}
                                        onChange={handleLimitChange}
                                        name="limit"
                                        id="select-pagination"
                                    >
                                        {mannualLimits?.map((mannualLimits) => (
                                            <option value={mannualLimits}>
                                                {mannualLimits}
                                            </option>
                                        ))}
                                    </select>
                                    &nbsp;&nbsp;&nbsp;records per page
                                </div>
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={
                                        "pagination justify-content-center"
                                    }
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    forcePage={initialPage}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
