import { BookingDetailsByBookingIDForTransaction, GetAddon } from '../../Reuse/ApiCalls';
import React, { useState, useEffect } from 'react';
import './Transaction.css';

const BookingList = ({ booking_id, main_court, padel_court }) => {

  const [tableItemDetails, setTableItemDetails] = useState([]);
  const [finalAddonList, setFinalAddonList] = useState([]);
  const [noOfElements, setNoOfElements] = useState(2)
  const [isMore, setIsMore] = useState(false);



  const slice = tableItemDetails.slice(0, noOfElements);


  const loadMore = () => {
    setNoOfElements(noOfElements + noOfElements);

  }
  const loadLess = () => {
    setNoOfElements(noOfElements - 2);
  }
  const MoreOrLessToogle = () => {
    setIsMore(!isMore);
    loadMore();
    loadLess();
  }

  const getBookingDetails = (id) => {

    BookingDetailsByBookingIDForTransaction(id).then((response) => {
      addonFunction(response.data.addons);
      setTableItemDetails(response.data.booked_slots)
    })
  };

  useEffect(() => {
    getBookingDetails(booking_id);
  }, []);


  const addonFunction = async (addons) => {
    if (addons === "null" || addons === null || addons === undefined) {
      return;
    }
    var match = addons;
    let seperateComma = match.split(",");
    var arrays = []
    var addonArray = []
    for (var i = 0; i < seperateComma.length; i++) {
      arrays = seperateComma[i].replace(/'/ig, "").split("-")
      let objs = {};
      objs['id'] = parseInt(arrays[0]);
      objs['quantity'] = parseInt(arrays[1]);
      addonArray.push(objs);
    }
    GetAddon().then((response) => {
      let addonNewList = response;
      let purchaseAddonList = addonArray
      const result = addonNewList?.map(function (el) {
        var o = Object.assign({}, el);
        const quantity = purchaseAddonList?.find(e => e.id === el.id);
        o.qty = quantity?.quantity === undefined ? 0 : quantity?.quantity;
        return o;
      });
      setFinalAddonList(result);
    })
  };

  return (
    <>
      {
        slice?.map((data, index) => {
          return (
            <div>
              <h5 className="TextShouldBeThreeLines" style={{ fontSize: 12 }}>
                {data?.main_court_name}
                {' '}
                {data?.padel_court_name}
                <br />
                ({data?.date}
                {' '}
                {data?.time.split('-')[0]}{" to "}{data?.time.split('-')[1]},
                {' '}
                {data?.courtname})
              </h5>
            </div>
          )
        })
      }

      {tableItemDetails.length > 2 ? <span style={{ color: '#ccc', cursor: 'pointer' }} onClick={() => loadMore()}>Read More....</span> : ""}
      {noOfElements === 2 ? "" : <span style={{ color: '#ccc', cursor: 'pointer', marginLeft: 10 }} onClick={() => loadLess()}>Read Less....</span>}

      {
        finalAddonList.map((data, index) => {
          return (
            <div>
              {data.addonname} {" x "}
              {data.qty}
            </div>
          )
        })
      }
    </>
  )
}


export default BookingList;
