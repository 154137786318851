import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Table, Container, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { AiOutlineClose, AiOutlineCheck, AiFillEye } from 'react-icons/ai';
import moment from 'moment';
import axios from '../../customAxios';
import { environment } from '../env';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';
import { useForm, Controller } from "react-hook-form";
import classnames from "classnames";
import Select from "react-select";


export const AppliedSkillLevel = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm()

    const {
        control: control1,
        register: register1,
        formState: { errors: errors1 },
        handleSubmit: handleSubmit1,
        reset: reset1,
    } = useForm();

    const LoggedUserId = localStorage.getItem("UserId");
    const genderOptions = [
        { label: 'Men', value: 'men' },
        { label: 'Women', value: 'women' }
    ]

    const skillLevelOptions = [
        { label: 'D', value: 'D' },
        { label: 'C-', value: 'C-' },
        { label: 'C', value: 'C' },
        { label: 'C+', value: 'C+' },
        { label: 'B', value: 'B' },
        { label: 'A', value: 'A' },
    ]

    const [genderSelected, setGenderSelected] = useState('')

    const [skillLevel, setSkillLevel] = useState([]);
    const [isSortable, setIsSortable] = useState(true);
    const [isResizable, setIsResizable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
    const [pageCount, setPageCount] = useState(0);
    const [pageLimits, setPageLimits] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [initialPage, setInitialPage] = useState(0);
    const [searchInput, setSearchInput] = useState();

    const [userSkillData, setUserSkillData] = useState()
    const [modalVerify, setModalVerify] = useState(false)
    const toggleVerify = () => setModalVerify(!modalVerify)


    const getVerifySkill = (page = 1, pagesize = 10) => {
        const GetSkillVerifyURL = `${environment.baseUrl}skill-verification-details/applied?page=${page}&page_size=${pagesize}`
        axios.get(GetSkillVerifyURL).then((response) => {
            setSkillLevel(response.data.results)
            setPageCount(response?.data?.count / pagesize)
        })
    };

    const onSubmit = (data) => {
        const GetSkillVerifyURL = `${environment.baseUrl}skill-verification-details/applied?query=${data.search}`;
        axios.get(GetSkillVerifyURL).then((response) => {
            setSkillLevel(response?.data?.results);
            setPageCount(response?.data?.count / pageLimits)
        })
    };

    useEffect(() => {
        getVerifySkill(1, pageLimits);
    }, []);

    var jsonObj2 = [];
    for (let i = 0; i < skillLevel?.length; i++) {
        var item = {}
        item["id"] = i + 1;
        item["username"] = skillLevel[i].user.first_name.toString();
        item["email"] = skillLevel[i].user.email.toString();
        item["phone_no"] = skillLevel[i].user.phone_no.toString();
        item["skillname"] = skillLevel[i].skillname.toString();
        item["applied_date"] = <div className="right-space">{moment(skillLevel[i].applied_date).format("DD-MM-YYYY")} <br />
            {moment(skillLevel[i].applied_date.split('T')[1], "HH:mm:ss").format("hh:mm A")}
        </div>;

        // item["view"] = <button className='modal-view-btn' onClick={() => redirectToRegisteredUser(skillLevel[i].user.id)}><AiFillEye style={{ fontSize: 20, height: "19px", width: "19px" }} /></button>;
        

        item["actions"] = (
            <div className="right-space">
                <div style={{ margin: '10px 0 -10px 0' }}>
                    <Button
                        type="button"
                        color="warning"
                        className="custom_coupon_btn"
                        onClick={() => {
                            setUserSkillData(skillLevel[i])
                            toggleVerify()
                        }}
                    >
                        Verify
                    </Button>
                    <Button
                        type="button"
                        color="danger"
                        className="custom_coupon_btn"
                        onClick={() => {
                            setUserSkillData(skillLevel[i])
                            handleReject(skillLevel[i])
                        }}
                    >
                        Reject
                    </Button>
                </div>
            </div>
        );
        jsonObj2.push(item);
    }

    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };

    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };

    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const OpenModal = (id, fname, skillname, isverified) => {
        swal({
            title: "Are you sure?",
            text: `${fname} is eligible for ${skillname}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const VerifySkillPayload = {
                    user: id,
                    isverified: !isverified,
                    status: !isverified == true ? 'verified' : 'rejected'
                };
                const SkillVerifyURL = `${environment.baseUrl}skill-verify/`;
                axios.post(SkillVerifyURL, VerifySkillPayload).then((response) => {
                    if (response.data.status == "success") {
                        getVerifySkill();
                        swal(`${fname} is verified for ${skillname}`, {
                            icon: "success",
                        });
                    } else {
                        swal(`${fname} is not verified for ${skillname}`, {
                            icon: "error",
                        });
                    }
                })

            } else {

                const VerifySkillPayload = {
                    user: id,
                    isverified: isverified,
                    status: isverified == true ? 'verified' : 'rejected'
                };
                const SkillVerifyURL = `${environment.baseUrl}skill-verify/`;
                axios.post(SkillVerifyURL, VerifySkillPayload).then((response) => {
                    if (response.data.status == "success") {
                        getVerifySkill();
                        swal(`${fname} is not verified for ${skillname}`, {
                            icon: "success",
                        });
                    } else {
                        swal(`${fname} is not verified for ${skillname}`, {
                            icon: "error",
                        });
                    }
                })
            }
        });
    }

    const tableConfig = {
        isEditable: false,
        isResizable,
        isSortable,
        withPagination: true,
        withSearchEngine: false,
        manualPageSize: [100000, 200000, 300000, 400000],
        placeholder: 'Search by Name...',
    };

    const newdata = {
        columns: [
            {
                Header: '#',
                accessor: 'id',
                disableGlobalFilter: true,
                width: 10,
            },
            {
                Header: 'Name',
                accessor: 'username',
                disableGlobalFilter: false,
                width: 70,
            },
            {
                Header: 'Email',
                accessor: 'email',
                disableGlobalFilter: false,
                width: 100,
            },
            {
                Header: 'Phone Number',
                accessor: 'phone_no',
                disableGlobalFilter: false,
                width: 100,
            },
            {
                Header: 'Applied Skill Level',
                accessor: 'skillname',
                disableGlobalFilter: true,
                width: 70,
            },
            {
                Header: 'Applied Date',
                accessor: 'applied_date',
                disableGlobalFilter: true,
                width: 50,
            },
            // {
            //     Header: 'View',
            //     accessor: 'view',
            //     disableGlobalFilter: true,
            //     width: 50,
            // },
            {
                Header: "Actions",
                accessor: "actions",
                disableGlobalFilter: true,
                width: 100,
            }
        ],
        data: jsonObj2
    };

    const redirectToRegisteredUser = (userId) => {
        window.open(`user_details/${userId}`, '_blank');
    };

    const handleLimitChange = (e) => {
        setInitialPage(0);
        setCurrentPage(1);
        setPageLimits(parseInt(e.target.value));
        getVerifySkill(1, parseInt(e.target.value));
    };

    const handlePageClick = (page) => {
        setCurrentPage(page.selected + 1);
        getVerifySkill(page.selected + 1, pageLimits);
    };

    const onSubmitVerify = async (data) => {
        const skillVerifyURL = `${environment.baseUrl}skill-verify/`;

        const skillVerifyPayload = {
            gender: data?.gender?.value,
            ladies_only_category: data?.ladies_only_category?.value ? data?.ladies_only_category?.value : null,
            mixed_category_skill: data?.mixed_category_skill?.value ? data?.mixed_category_skill?.value : null,
            reevaluation_date: data?.reevaluation_date,
            coach: JSON.parse(LoggedUserId),
            user: userSkillData?.user?.id,
            isverified: true,
            status: 'verified'
        }

        await axios.post(skillVerifyURL, skillVerifyPayload).then((res) => {
            if (res.data.status === 'success') {
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    buttons: true,
                }).then(() => {
                    toggleVerify()
                    getVerifySkill()
                })
            }
        }).catch((err) => {
            console.log("🚀 ~ file: AppliedSkillLevel.js:337 ~ awaitaxios.post ~ err:", err)
            swal(`This User is not verified`, {
                icon: "error",
            });
        })
    }

    const handleReject = (data) => {
        swal({
            title: "Are you sure?",
            text: `This user is not eligible for ${data?.skillname}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const skillVerifyURL = `${environment.baseUrl}skill-verify/`;
                const skillVerifyPayload = {
                    coach: JSON.parse(LoggedUserId),
                    user: data?.user?.id,
                    isverified: false,
                    status: 'rejected'
                }
                await axios.post(skillVerifyURL, skillVerifyPayload).then((res) => {
                    if (res.data.status === 'success') {
                        swal({
                            title: "Success",
                            text: 'User Skill is rejected successfully',
                            icon: "success",
                            buttons: true,
                        }).then(() => {
                            getVerifySkill()
                        })
                    }
                }).catch((err) => {
                    console.log("🚀 ~ file: AppliedSkillLevel.js:337 ~ awaitaxios.post ~ err:", err)
                    swal(`This User is not verified`, {
                        icon: "error",
                    });
                })

            }
        }).catch((err) => {
            console.log("🚀 ~ file: AppliedSkillLevel.js:389 ~ handleReject ~ err:", err)
            swal(`This User is not verified`, {
                icon: "error",
            });
        })
    }

    const handleclear = () => {
        reset()
        getVerifySkill(1, pageLimits)
    }

    return (
        <>
            <Modal
                isOpen={modalVerify}
                toggle={toggleVerify}
                centered={true}
                className="custom_skill_verify_modal"
                style={{ maxWidth: "500px" }}

                size='lg'
            >
                <ModalHeader toggle={toggleVerify} style={{ alignItems: 'center' }}>Verify Skill Level</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit1(onSubmitVerify)}>

                        <div>
                            <h2 className=''>Are you Sure ?</h2>
                            <span className='' style={{ fontSize: 16 }}>This user is eligible to play in</span>
                        </div>
                        <Row>
                            <Col lg={12}>
                                <div className="form__form-group mt-2">
                                    <div className="form__form-group-field">
                                        <Controller
                                            control={control1}
                                            name="gender"
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { invalid, isTouched, isDirty, error },
                                                formState,
                                            }) => (
                                                <Select
                                                    value={value}
                                                    options={genderOptions}
                                                    className="text-uppercase w-100"
                                                    placeholder="Select Gender"
                                                    onChange={(value) => {
                                                        onChange(value)
                                                        setGenderSelected(value.value);
                                                    }}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errors1.gender && (
                                        <span className="error-msg">
                                            Please Enter Gender
                                        </span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="form__form-group mt-2">
                                    <div className="form__form-group-field">
                                        <Controller
                                            control={control1}
                                            name="mixed_category_skill"
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { invalid, isTouched, isDirty, error },
                                                formState,
                                            }) => (
                                                <Select
                                                    value={value}
                                                    options={skillLevelOptions}
                                                    className="text-uppercase w-100"
                                                    placeholder="Mixed Categories"
                                                    onChange={onChange}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errors1.mixed_category_skill && (
                                        <span className="error-msg">
                                            Please Choose Skill Level
                                        </span>
                                    )}
                                </div>
                            </Col>

                            {
                                genderSelected === 'women' && (
                                    <>
                                        <Col lg={12}>
                                            <div className="form__form-group mt-2">
                                                <div className="form__form-group-field">
                                                    <Controller
                                                        control={control1}
                                                        name="ladies_only_category"
                                                        rules={{ required: true }}
                                                        render={({
                                                            field: { onChange, onBlur, value, name, ref },
                                                            fieldState: { invalid, isTouched, isDirty, error },
                                                            formState,
                                                        }) => (
                                                            <Select
                                                                value={value}
                                                                options={skillLevelOptions}
                                                                className="text-uppercase w-100"
                                                                placeholder="Ladies Only Categories"
                                                                onChange={onChange}
                                                                inputRef={ref}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                {errors1.ladies_only_category && (
                                                    <span className="error-msg">
                                                        Please Choose Skill Level
                                                    </span>
                                                )}
                                            </div>

                                        </Col>
                                    </>
                                )
                            }
                        </Row>

                        <Row className='d-flex justify-content-between align-items-center'>
                            <Col lg={6}>
                                <div className="form__form-group d-flex flex-column align-items-start">
                                    <span className="form__form-group-label">Re evaluation Date</span>
                                    <div className="form__form-group-field">
                                        <input
                                            className='w-100 p-2'
                                            type="date"
                                            name="reevaluation_date"
                                            {...register1("reevaluation_date", { required: true })}
                                        />
                                    </div>
                                    {errors.reevaluation_date && (
                                        <span className="error-msg">
                                            Please Enter Reevaluation_date Date
                                        </span>
                                    )}
                                </div>
                            </Col>

                            <Col lg={3}>
                                <div style={{ margin: '30px 0 -30px 0' }}>
                                    <Button type="submit" color="warning" className="custom_coupon_btn">
                                        Submit
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
            </Modal>

            <Row>
                <Col md={12} lg={12}>
                    <Card >
                        <CardBody className='mb-4'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div class="table__search">
                                    <input placeholder="Search by Name, Email, Phone No..." type="text" class={`table__search table__search-input form-control ${errors.search && 'is-invalid'}`} value={searchInput} {...register("search", { required: true })} />
                                    <button className='btn btn-sm btn-warning' type='submit'>Search</button>
                                    <button className='btn btn-sm btn-danger' type='button' onClick={() => handleclear()}>Clear</button>
                                </div>
                            </form>
                            <div className="react-table__wrapper hide-table-costomizer">
                                <ReactTableCustomizer
                                    handleClickIsSortable={handleClickIsSortable}
                                    handleClickWithPagination={handleClickWithPagination}
                                    handleClickWithSearchEngine={handleClickWithSearchEngine}
                                    handleClickIsResizable={handleClickIsResizable}
                                    isResizable={isResizable}
                                    isSortable={isSortable}
                                    withPagination={withPagination}
                                    withSearchEngine={withSearchEngine}
                                />
                            </div>

                            <div className="table-id-desabled pagination-hide hover-effect hide-filtername table-id-width">
                                <ReactTableBase
                                    key={withSearchEngine ? 'searchable' : 'common'}
                                    columns={newdata.columns}
                                    data={newdata.data}
                                    tableConfig={tableConfig}
                                />
                            </div>
                            <div className="custom-pagination">
                                <div className="records-per-page">
                                    <select
                                        value={pageLimits}
                                        onChange={handleLimitChange}
                                        name="limit"
                                        id="select-pagination"
                                    >
                                        {mannualLimits?.map((mannualLimits) => (
                                            <option value={mannualLimits}>
                                                {mannualLimits}
                                            </option>
                                        ))}
                                    </select>
                                    &nbsp;&nbsp;&nbsp;records per page
                                </div>
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={
                                        "pagination justify-content-center"
                                    }
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    forcePage={initialPage}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
