import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Modal, ModalBody, Row ,Spinner} from "reactstrap";
import classNames from "classnames";
import { RTLProps } from "../prop-types/ReducerProps";
import DatePicker from "react-date-picker";
import { useForm } from "react-hook-form";
import moment from "moment";
import axios from "../../customAxios";
import { environment } from "../../containers/env";
import swal from "@sweetalert/with-react";

export const GenerateSlotStaffModal = ({
  color,
  btn,
  colored,
  header,
  title,
  staffId,
  submitDate,
  selectedDate,
}) => {
  const [modal, setModal] = useState(false);
  const [start, onChangeStart] = useState(new Date(selectedDate));
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }

  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const onSubmit = (e) => {
    setLoading(true)
    let selectDate = moment(start).format("YYYY-MM-DD");

    const GenerateSlotStaffPayload = {
      date: selectDate,
      staffId: staffId,
    };
   
    const GenerateSlotCoachURL = `${environment.baseUrl}staffmanagement/staff-available-timeslots/`;
    axios.post(GenerateSlotCoachURL, GenerateSlotStaffPayload).then((res) => {
        if (
          res.data.Message === `Slots is generated for staff on ${selectDate}`
        ) {
          swal({
            icon: "success",
            content: (
              <div>
                <h1 style={{ color: "#28a745" }}>Staff slot is generated</h1>
              </div>
            ),
          }).then(() => {
            toggle();
            submitDate();
            setLoading(false);
          });
          setLoading(false);
        } else {
          swal({
            icon: "error",
            content: (
              <div>
                <h1 style={{ color: "darkred" }}>Error</h1>
                <p>Error, While Generating Staff Slot</p>
              </div>
            ),
          });
          setLoading(false);
        }
      }).catch((err) => {
        console.log("err---->", err);
        setLoading(false);
      });
    setModal(false);
  };

  return (
    <>{loading && (
      <div class="loader-div">
        <span class="loader">
          <div className="center-spinner">
            <Spinner className="table-fetch-spinner" />
          </div>
        </span>
      </div>
    )}
    
    <div
      className="calender__booked_changes bg-warning text-dark"
      onClick={toggle}
    >
      Generate slot
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`modal ltr-support`}
        className={`modal-dialog--${color} ${modalClass}`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="12" sm="12" lg="12">
                <label>Generate Slot for Staff:</label>
              </Col>
            </Row>
            <Row>
              <Col md="9" sm="9" lg="9">
                <div className="inner-container">
                  <DatePicker
                    dateFormat="YYYY-MM-DD"
                    onChange={onChangeStart}
                    value={start}
                    className="custom-css-dp"
                  />
                </div>
              </Col>
              <Col md="3" sm="3" lg="3">
                <button className="download-booking-table-xls-button">
                  Submit
                </button>
              </Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </div>
    </>
  );
};

GenerateSlotStaffModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
};

GenerateSlotStaffModal.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
};
