import React, { useState } from "react";
import { Card, CardBody, Col, ButtonToolbar, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import S3FileUpload from "react-s3";
import axios from "../../../customAxios";
import { AdSpacePostMethod } from "../../Reuse/ApiCalls";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';

const config = {
  // bucketName: 'test-padel',
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const AdSpace = () => {
  const [spinner, setSpinner] = useState(false);
  const [internalLink, setInternalLink] = useState(true);
  const [externalLink, setExternalLink] = useState(false);
  const [RedirectLink, setRedirectLink] = useState();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const image = watch("image");

  const handleLinkSelect = (val) => {
    setInternalLink(false);
    setExternalLink(false);

    if (val === "1") {
      setInternalLink(true);
    }
    if (val === "2") {
      setExternalLink(true);
    }
  }

  const onSubmit = async (adSpaceData) => {
    setSpinner(true);
    adSpaceData.position = parseInt(adSpaceData.screen.split("-")[1]);
    adSpaceData.screen = adSpaceData.screen.split("-")[0];
    // adSpaceData.redirectlink = RedirectLink;

    await S3FileUpload.uploadFile(image[0], config)
      .then((data) => {
        if (data.location) {
          adSpaceData.image = data.location;
          console.log("adSpaceData", adSpaceData);
          AdSpacePostMethod(adSpaceData).then((adSpaceResponse) => {
            if (adSpaceResponse.Message === 'Ads is saved successfully') {
              setSpinner(false);
              swal({
                title: "Success",
                text: 'Ads is saved successfully',
                icon: "success",
                button: "Ok",
              })
                .then(() => {
                  history.push('/ad-space-list')
                  reset();
                })
            }
          });
        }
      })
      .catch((err) => {
        console.log("Error Info: ", err);
      });
  };

  const Back = () => {
    reset({
      add_url: "",
      add_image: "",
      screens: "",
      position: "",
      redirectlink:""
    });
    history.push("/ad-space-list")
  }

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="center-spinner">
            {spinner ? <Spinner className="table-fetch-spinner" /> : ''}
          </div>
          <form
            className="form product-edit"
            encType="multipart/form-data"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Add Image
                </span>
                <div className="form__form-group-field">
                  <input
                    type="file"
                    name="image"
                    style={{ borderRadius: 5 }}
                    {...register("image", { required: true })}
                  />
                </div>
                {errors.image && (
                  <span className='error-msg'>Image Is required</span>
                )}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label links-container">
                  <input type="radio"
                    className="redirectRadio"
                    onChange={() => handleLinkSelect("1")}
                    checked={internalLink && "checked"} /> Internal Redirection
                  <input type="radio"
                    className="redirectRadio"
                    onChange={() => handleLinkSelect("2")}
                    checked={externalLink && "checked"} />  External Redirection
                </span>

                {internalLink && <div className="form__form-group-field">
                  <select className="form__form-group-field select__option"
                    name="redirectlink"
                    {...register("redirectlink", { required: true })}
                  >
                    <option value=''>Select Internal Redirection</option>
                    <option value="Home">Home</option>
                    <option value="booknow">Book Now</option>
                    <option value="ContactUsScreen">Contact Us</option>
                    <option value="packages">Packages</option>
                    <option value="offer">offer</option>
                    <option value="MyProfileScreen">Profile Page</option>
                  </select>
                </div>}

                {externalLink &&
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      placeholder=" Button Redirect Link"
                      name="redirectlink"
                    {...register("redirectlink", { required: true })}
                    />
                  </div>}
                {errors.redirectlink && (
                  <span className="error-msg">Please Enter Redirect Link</span>
                )}
              </div>

              {/* <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Add Url
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    name="redirectlink"
                    style={{ borderRadius: 5 }}
                    {...register("redirectlink", { required: true })}
                  />
                </div>
                {errors.redirectlink && (
                  <span className='error-msg'>
                    Redirect Link Is require
                  </span>
                )}
              </div> */}
            </div>
            <div className="form__half" style={{ marginTop: 15 }}>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Screen
                </span>
                <div className="form__form-group-field">
                  <select
                    className="form__form-group-field"
                    style={{
                      borderRadius: 5,
                      border: "1px solid #f2f4f7",
                      color: "#646777",
                      height: 32,
                    }}
                    {...register("screen", { required: true })}
                  >
                    <option value="">Select Option</option>
                    <option value="Home-1">Home 1</option>
                    <option value="Home-2">Home 2</option>
                    <option value="Home-3">Home 3</option>
                    <option value="Profile-1">Profile 1</option>
                    <option value="BookNow-1">BookNow 1</option>
                    <option value="MyBooking-1">MyBooking 1</option>
                    <option value="Home-11">Home 11</option>
                  </select>
                </div>
                {errors.screen && (
                  <span className='error-msg'>Screen Is require</span>
                )}
              </div>
            </div>


            <div className="form__half" style={{ marginTop: 48, marginLeft: 30, }}>
              <div style={{ marginTop: 15, display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  {...register("visibility", { required: false })}
                  style={{ borderRadius: 5, width: 'auto' }}
                />
                <span
                  style={{ fontSize: 15, marginLeft: 10 }}
                >
                  Visibility
                </span>
              </div>
            </div>

            <div
              className="form__half"
              style={{ marginLeft: 30, paddingTop: 15 }}
            >
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  {/* Position */}
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    style={{ borderRadius: 5 }}
                    name="position"
                    hidden="true"
                    value="0"
                    {...register("position")}
                  />
                </div>
                {errors.position && (
                  <span className='error-msg'>Position Is required</span>
                )}
              </div>
            </div>

            <Col md={12} lg={12}>
              <Card
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: 30,
                }}
              >
                <div className="form__half" style={{ marginRight: -13 }}>
                  <ButtonToolbar className="form__button-toolbar">
                    <button className="justpadel-buttons-save" type="submit">
                      Submit
                    </button>
                    <button
                      className="justpadel-buttons-cancel"
                      onClick={() => { Back() }}
                    >
                      Cancel
                    </button>
                  </ButtonToolbar>
                </div>
              </Card>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdSpace;
