import React, { useState, useEffect } from 'react';
import { Button, ModalFooter, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import '../Quickpay/QuickpayModal.css';



const GiftCardModal = ({ transactionLog, show, CloseModal }) => {
    const [showModal, setShowModal] = useState(false);

    const ToggleHandle = () => {
        setShowModal(!showModal)
        CloseModal();
    };

    useEffect(() => {
        setShowModal(show);
    }, [show]);


    return (
        <div>
            {/* <Button
                className='modal-view-btn'
                onClick={ToggleHandle}
                style={{ background: "transparent", color: "#70BBFD", border: "1px solid #70BBFD", width: "45px" }}
            >
                <AiFillEye style={{ fontSize: 20, fill: "#70BBFD", height: "19px", width: "19px" }} />
            </Button> */}
            <Modal isOpen={showModal}
                style={{ maxWidth: '1100px' }}
                className="QuickPayModal"
            >


                <ModalHeader toggle={ToggleHandle} className="QuickPayModalHeader">
                    <span>Transaction Log Details</span>
                </ModalHeader>

                <ModalBody style={{ textAlign: "initial", padding: 50 }}>
                    {transactionLog ? transactionLog.Data === "No Transaction log" ? "No Transaction Logs" :
                        <div>
                            <div className="trans-logs-container">
                                <div className="trans-logs-container-lhs">

                                    <div>
                                        <label className="trans-logs-lhs-text">Card Number:  </label> {' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.card_number === "null" ? " " : transactionLog?.card_number}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Card Holder Name:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.card_holder_name === "null" ? " " : transactionLog?.card_holder_name}</label>
                                    </div>

                                    <div>
                                        <label className="trans-logs-lhs-text">Merchant Identifier:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.merchant_identifier === "null" ? " " : transactionLog?.merchant_identifier}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Amount Paid</label> {' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.amount === "null" ? " " : parseFloat(parseFloat(transactionLog?.amount) / 100).toFixed(2)}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Expiry Date:  </label> {' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.expiry_date === "null" ? " " : transactionLog?.expiry_date}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Customer IP:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.customer_ip === "null" ? " " : transactionLog?.customer_ip}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Language:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.language === "null" ? " " : transactionLog?.language}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">ECI:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.eci === "null" ? " " : transactionLog?.eci}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Fort Id:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.fort_id === "null" ? " " : transactionLog?.fort_id}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Command:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.command === "null" ? " " : transactionLog?.command}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Response Message:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.response_message === "null" ? " " : transactionLog?.response_message}</label>
                                    </div>
                                </div>

                                <div className="trans-logs-container-rhs">
                                    <div>
                                        <label className="trans-logs-lhs-text">Merchant Reference:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.merchant_reference === "null" ? " " : transactionLog?.merchant_reference}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Authorization Code:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.authorization_code === "null" ? " " : transactionLog?.authorization_code}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Currency:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.currency === "null" ? " " : transactionLog?.currency}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Acquirer Response Code:  </label> {' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.acquirer_response_code === "null" ? " " : transactionLog?.acquirer_response_code}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Payment Option:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.payment_option === "null" ? " " : transactionLog?.payment_option}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Customer Email:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.customer_email === "null" ? " " : transactionLog?.customer_email}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Order Description:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.order_description === "null" ? " " : transactionLog?.order_description}</label>
                                    </div>
                                    <div>
                                        <label className="trans-logs-lhs-text">Status:  </label>{' '}
                                        <label className="trans-logs-rhs-text">{transactionLog?.status === "null" ? " " : transactionLog?.status}</label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        : ""
                    }
                </ModalBody>


                <ModalFooter>
                    {/* <Button
                        color="primary"
                        onClick={ToggleHandle}
                    >
                        Do Something
                    </Button> */}
                    {' '}
                    <Button onClick={ToggleHandle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </div>

    );
};


export default GiftCardModal;

