import React, { useState } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane, Table, CardBody, Card, Col, ButtonToolbar, Button, Spinner,
} from 'reactstrap';
import classnames from 'classnames';
import BulkBooking from '../../../Forms/BulkBooking';



const Tabs = ({ TabToRefresh,prePopulatedDated,close }) => {

  const ReafreshData = () => {
    TabToRefresh()
  }
  const [activeTab, setActiveTab] = useState('1');
  return (
    <div className="tabs__wrap">
      <div className="center-spinner">
      </div>
     
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
           <BulkBooking closeModal={ReafreshData} prePopulatedDated={prePopulatedDated} close={close}/>
        </TabPane>
      </TabContent>
    </div>
  );
};


export default Tabs;
