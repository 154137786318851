import React, { useState, useEffect } from 'react'
import { Button, ModalFooter, Modal, ModalHeader, ModalBody, Col, Spinner, Row, Label } from 'reactstrap';
import { AiFillEye } from "react-icons/ai";
import classNames from 'classnames';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import ReactPaginate from 'react-paginate';
import { environment } from '../env';
import axios from '../../customAxios';





const LivePostClientDetailsModel = ({ color, header, title, colored, clientDetail, postId }) => {


  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [pageCount, setpageCount] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [mannualLimits] = useState([10, 20, 30, 40, 50]);
  const [spinner, setSpinner] = useState(false);
  const [allCourtType, setAllCourtType] = useState([]);
  const [clientDetails, setClientDetails] = useState();
  const [showModal, setShowModal] = useState(false);


  let limit = parseInt(localStorage.getItem("BookingListCurrentPageLimit"));

  const DeletePost = async (postId) => {
    setSpinner(true);
    const getLikedByUserUrl = `${environment.baseUrl}padelgram/get-post-liked-by/${postId}`;
    console.log(getLikedByUserUrl);
    const result = await axios.get(getLikedByUserUrl)
      .then((response) => {
        setClientDetails(response?.data?.results);
        setpageCount(Math.ceil(response?.data?.count / limit));
        setSpinner(false);
      });
  }

  var jsonObj = [];
  for (let i = 0; i < clientDetails?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["name"] = <div className="bookingId right-space" >{clientDetails[i]?.user?.first_name === "null" ? "" : clientDetails[i]?.user?.first_name} {" "} {clientDetails[i]?.user?.last_name === "null" ? "" : clientDetails[i]?.user?.last_name} </div>;
    item["email"] = <div className="ceated-date right-space">{clientDetails[i]?.user?.email}</div>;
    item["phone_no"] = clientDetails[i]?.user?.phone_no === "null" ? '' : clientDetails[i]?.user?.phone_no;
    // item["following"] = (clientDetails[i]?.following ? <Button size='sm' color='primary'>Following</Button> : <Button size='sm' color='primary'>Follow</Button>)
    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "User Name",
        accessor: "name",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Email",
        accessor: "email",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Phone No",
        accessor: "phone_no",
        disableGlobalFilter: true,
        width: 50,
      },
    ],
    rows: jsonObj,
  };


  const toggle = () => {
    setShowModal(!showModal);
    if (!showModal) {
      DeletePost(postId);
    }
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };


  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });


  async function handleLimitChange(e) {
    localStorage.setItem("BookingListCurrentPageLimit", e.target.value);
    let currentPage = parseInt(localStorage.getItem("BookingListCurrentPage"));
    setInitialPage(0);
  }

  useEffect(() => {
    setInitialPage(initialPage);
  }, [initialPage]);


  const tableConfig = {
    isEditable: false,
    isResizable,
    // isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Name and Slots...",
  };

  const DataNotFound = [
    {
      email: "No likes",
    },
  ];




  return (
    <div>
       {spinner && <Spinner />}
      <div className="right-space" onClick={toggle}><div className="modal-view-btn"><AiFillEye style={{ fontSize: 20 }} /></div></div>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        modalClassName={`modal ltr-support`}
        className={`modal-dialog--${color} ${modalClass}`}
        style={{ maxWidth: 700 }}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <ModalBody style={{ textAlign: "initial", padding: 50 }}>
          <Row>
            <Col sm="4" md="4" lg="4">

              {
                (clientDetail?.padelgram_image).includes("mp4") || (clientDetail?.padelgram_image).includes("mov") ?
                <video style={{ width: 130, borderRadius: 5 }} preload controls src={clientDetail?.padelgram_image}></video>
                :
                <img src={clientDetail?.padelgram_image}
                    alt={clientDetail?.padelgram_image}
                    style={{ width: 130, borderRadius: 5 }} />

              }
             
            </Col>
            <Col sm="8" md="8" lg="8">
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label>Caption</Label>
                </Col>
                <Col sm="8" md="8" lg="8">
                  <Label>{clientDetail?.padelgram_image_comment}</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label>Location</Label>
                </Col>
                <Col sm="8" md="8" lg="8">
                  <Label>{clientDetail?.location}</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label>Visibility</Label>
                </Col>
                <Col sm="8" md="8" lg="8">
                  <Label className="text-capitalize">{clientDetail?.post_type}</Label>
                </Col>
              </Row>
              <Row className='mt-4'>
                <Col sm="4" md="4" lg="4">
                  <Label>Posted By</Label>
                </Col>
                <Col sm="4" md="4" lg="4">
                  <Label>{clientDetail?.user?.first_name} {" "} {clientDetail?.user?.last_name}</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label></Label>
                </Col>
                <Col sm="4" md="4" lg="4">
                  <Label>{clientDetail?.user?.email}</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label></Label>
                </Col>
                <Col sm="5" md="5" lg="5">
                  <Label>{clientDetail?.user?.phone_no}</Label>
                </Col>
              </Row>
              <Row>
                <Col sm="4" md="4" lg="4">
                  <Label>Liked By</Label>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="react-table__wrapper hide-table-costomizer">
                <ReactTableCustomizer
                  handleClickIsSortable={handleClickIsSortable}
                  handleClickWithPagination={handleClickWithPagination}
                  handleClickWithSearchEngine={handleClickWithSearchEngine}
                  handleClickIsResizable={handleClickIsResizable}
                  isResizable={isResizable}
                  isSortable={isSortable}
                  withPagination={withPagination}
                  withSearchEngine={withSearchEngine}
                />
              </div>
              <div >
                <div className="records-per-page">
                  <select
                    value={localStorage.getItem("BookingListCurrentPageLimit")}
                    onChange={handleLimitChange}
                    name="limit"
                    id="select-pagination"
                  >
                    {mannualLimits &&
                      mannualLimits.map((mannualLimits) => (
                        <option value={mannualLimits}>{mannualLimits}</option>
                      ))}
                  </select>
                  &nbsp;&nbsp;&nbsp;records per page
                </div>
                <div className="table-id-desabled pagination-hide hide-filtername hover-effect hide-table-costomizer second-child-space">
                  <ReactTableBase
                    key={withSearchEngine ? "searchable" : "common"}
                    columns={data.columns}
                    data={data.rows.length === 0 ? DataNotFound : data.rows}
                    tableConfig={tableConfig}
                  />
                </div>

              </div>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                // onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={initialPage}
              />
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} className="cancel-btn">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
};

export default LivePostClientDetailsModel