import React, { useState } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from "react-hook-form";
import { Card, CardBody, Col, Button, ButtonToolbar, Modal } from "reactstrap";
import axios from '../../customAxios';
import { environment } from '../env';
import swal from "sweetalert";

const AddCategories = ({ color, btn, colored, header, title, GetMatchCategories }) => {

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(prevState => !prevState);
    };

    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    const { register, handleSubmit, reset, formState } = useForm({
        mode: "onChange",
    });
    const { errors } = formState;

    const onSubmit = (value) => {
        const payload = {
            "matchcategory": value.name
        }

        if (value.name) {
            const GetAddCategoryURL = `${environment.baseUrl}match-categories/`;
            axios
                .post(GetAddCategoryURL, payload)
                .then((response) => {
                    if (response.data.message === "match category is created") {
                        swal({
                            title: "Success",
                            text: "Category added Successfully",
                            icon: "success",
                            button: "Ok",
                        }).then(() => {
                            GetMatchCategories();
                            toggle();
                            reset({
                                name: "",
                            });
                        });
                    } else if (response.data.message == "Category is already exists") {
                        swal({
                            title: "Warning",
                            text: "Category is already exists",
                            icon: "warning",
                            button: "Ok",
                        }).then(() => {
                            GetMatchCategories();
                            toggle();
                        });
                    }
                })
                .catch((err) => {
                    swal({
                        title: "Error",
                        text: JSON.stringify(err),
                        icon: "error",
                        button: "Ok",
                    }).then(() => {
                        GetMatchCategories();
                        toggle();
                    });
                });
        }
    };

    return (
        <div>
            <Button color={color} onClick={toggle}>{btn}</Button>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass}`}
            >
                <div className="modal__header" style={{ backgroundColor: '#70bbfd' }}>
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <div>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form__half">
                                        <div className="form__form-group">
                                            <span className="form__form-group-label">Name</span>
                                            <div className="form__form-group-field">
                                                <input
                                                    type="text"
                                                    placeholder="Category Name"
                                                    name="name"
                                                    {...register("name", { required: true })}
                                                />
                                            </div>
                                            {errors.name && (
                                                <span className="error-msg">Please Enter Name Of Category</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form__half">
                                        <div className="form__form-group"></div>
                                    </div>
                                    <div className="form__half">
                                        <div className="form__form-group"></div>
                                    </div>

                                    <ButtonToolbar
                                        className="form__button-toolbar float_right"
                                        style={{
                                            marginLeft: "30px",
                                            margin: "auto",
                                            marginBottom: "5px",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <button
                                            className="justpadel-buttons-save"
                                            type="submit"
                                            style={{ border: "none", backgroundColor: "#1bb4f0" }}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className="justpadel-buttons-cancel"
                                            style={{ backgroundColor: "#FDAE00", border: "none" }}
                                            onClick={() => {
                                                reset({
                                                    name: "",
                                                });
                                            }}
                                        >
                                            Clear
                                        </button>
                                    </ButtonToolbar>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </Modal>
        </div>
    )
}

export default AddCategories