/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { useParams } from "react-router-dom";
import axios from '../../../customAxios';
import { environment } from "../../env";

const ViewBookingDetail = () => {
  const { id } = useParams();
  const [bookingDetails, setBookingDetails] = useState();
  const [bookedSlots, setBookedSlots] = useState([]);
  const [addonList, setAddonList] = useState([]);
  const bookingDetailsbyID = `${environment.baseUrl}courtbooking_details/${id}`;
  const bookingDetailsData = () => {
    axios.get(bookingDetailsbyID)
      .then((response) => {
        setBookingDetails(response.data);
        // setBookedSlots(JSON.parse(response?.data?.booked_slots.replace(/'/g, '"')));
        setBookedSlots(response?.data?.booked_slots);
        addonFunction(response.data);
      })
  }
  const getAddonUrl = `${environment.baseUrl}addon`
  const addonDetailsData = () => {
    axios.get(getAddonUrl)
      .then((response) => {
        localStorage.setItem("addonList", JSON.stringify(response.data));
      })
  }
  useEffect(() => {
    addonDetailsData();
    bookingDetailsData();
  }, []);


  const addonFunction = async (data) => {
    var match = data?.addons?.split(',');
    let purchaseAddonList = [];
    for (var a in match) {
      var match2 = String(match[a]).replace(/^'|'$/g, "").split(/\s*\-\s*/g);
      var obj = {};
      for (var i = 0; i < match2.length; i++) {
        obj["id"] = parseInt(match2[0]);
        obj["quantity"] = parseInt(match2[1]);
      }
      purchaseAddonList.push(obj);
    }
    let addonNewList = JSON.parse(localStorage.getItem("addonList"));
    const result = addonNewList?.map(function (el) {
      var o = Object.assign({}, el);
      const quantity = purchaseAddonList?.find(e => e.id === el.id);
      o.quantitys = quantity?.quantity;
      return o;
    });
    setAddonList(result);
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
          </div>
          <Row>
            <Col md={3}>
              <div className="card__title">
                <h3>User Details</h3>
              </div>
              <div>
                <label>User ID :  </label> <label>{bookingDetails?.user?.id === "null" || "" ? " " : bookingDetails?.user?.id}</label>
              </div>
              <div>
                <label>First Name :  </label> <label>{bookingDetails?.user?.first_name === "null" ? " " : bookingDetails?.user?.first_name}</label>
              </div>
              <div>
                <label>Last Name :  </label> <label>{bookingDetails?.user?.last_name === "null" ? " " : bookingDetails?.user?.last_name}</label>
              </div>
              <div>
                <label>Gender :  </label> <label>{bookingDetails?.user?.gender === "null" ? " " : bookingDetails?.user?.gender}</label>
              </div>
              <div>
                <label>Email :  </label> <label>{bookingDetails?.user?.email === "null" ? " " : bookingDetails?.user?.email}</label>
              </div>
              <div>
                <label>No Of Games Played :  </label> <label>{bookingDetails?.user?.no_of_games === "null" ? " " : bookingDetails?.user?.no_of_games}</label>
              </div>
              <div>
                <label>Contact No :  </label> <label>{bookingDetails?.user?.phone_no === "null" ? " " : bookingDetails?.user?.phone_no}</label>
              </div>
              <div>
                <label>Address :  </label> <label>{bookingDetails?.user?.address === "null" ? " " : bookingDetails?.user?.address}</label>
              </div>
              <div>
                <label>City :  </label> <label>{bookingDetails?.user?.city === "null" ? " " : bookingDetails?.user?.city}</label>
              </div>
              <div>
                <label>State :  </label> <label>{bookingDetails?.user?.state === "null" ? " " : bookingDetails?.user?.state}</label>
              </div>
              <div>
                <label>Country :  </label> <label>{bookingDetails?.user?.country === "null" ? " " : bookingDetails?.user?.country}</label>
              </div>
            </Col>
            <Col md={4}>
              <div className="card__title">
                <h3>Booking Details</h3>
              </div>
              <div>
                <label>Booking ID :  </label> <label>{bookingDetails?.id === "null" || "" ? " " : bookingDetails?.id}</label>
              </div>
              <div>
                <label>Booking Date :  </label> <label>{bookingDetails?.booking_date === "null" ? " " : bookingDetails?.booking_date}</label>
              </div>
              <div>
                <label>Main Court :  </label> <label>{bookingDetails?.main_court?.courtName === "null" ? " " : bookingDetails?.main_court?.courtName}</label>
              </div>
              <div>
                <label>Padel Court :  </label> <label>{bookingDetails?.padel_court?.courtname === "null" ? " " : "Court " + bookingDetails?.padel_court?.courtname}</label>
              </div>
              <div>
                <label>Addon Booked :  </label> <Row style={{flexDirection:"column"}}>{addonList?.index === 0 ? "No Booking" : addonList?.map((data) => {
                  return (
                    <Row>
                      <label>{data.addonname}  </label> - <label>  Qty :  {data.quantitys === undefined | "null" ? 0 : data.quantitys } </label>
                    </Row>
                  )
                })}</Row>
              </div>
              <div>
                <label>Amount Paid :  </label> <label>{bookingDetails?.amount_paid === "null" ? 0 : bookingDetails?.amount_paid}</label>
              </div>
              <div>
                <label>Coupon Applied :  </label> <label>{bookingDetails?.coupan_applied === "null" ? "No" : bookingDetails?.coupan_applied}</label>
              </div>
              <div>
                <label>Cancelled :  </label> <label>{bookingDetails?.booking?.cancelled === false ? "Cancelled" : "Not Cancelled"}</label>
              </div>
            </Col>
            <Col md={5}>
              <div>
                <div className="card__title">
                  <h3>Booked Slot</h3>
                </div>
                <Table responsive hover style={{ textAlign: "center" }}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Main Court</th>
                      <th>Court Name</th>
                      <th>Price</th>
                      <th>Ladies Only</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookedSlots?.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item?.date === "null" ? " " : item?.date}</td>
                        <td>{item?.time === "null" ? " " : item?.time}</td>
                        <td>{item?.main_court === "null" ? " " : item?.main_court}</td>
                        <td>{item?.courtname === "null" ? " " : "Court " + item?.courtname}</td>
                        <td>{item?.Price === "null" ? " " : item?.Price}</td>
                        <td>{item?.ladiesOnly === "null" ? " " : item?.ladiesOnly}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ViewBookingDetail;
