import React, { useState, useEffect, useReducer } from "react";
import { Card, CardBody, Col, Container, Row,Spinner } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import axios from "../../customAxios";
import { environment } from "../env";
import swal from "sweetalert";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import { OfferCardsModal } from "../../shared/components/OfferCardsModal";

const initialState = {
  pageLimits: 10,
};

function reducer(state, action) {
  switch (action.type) {
    case "pageLimits":
      return {
        ...state,
        pageLimits: parseInt(action.payload),
      };
    default:
      return {
        ...state,
      };
  }
}

export const AddOffersCard = () => {
  const { register, handleSubmit, control, reset, formState } = useForm({
    mode: "onChange",
  });
  const { touchedFields, errors } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const [offerCards, setOfferCards] = useState([]);
  const [isSortable, setIsSortable] = useState(true);

  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [offerCardsPageCount, setOfferCardsPageCount] = useState(0);
  const [couponInitialPage, setCouponInitialPage] = useState(0);
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const GetOfferCards = (pageNo, pageLimit) => {
    dispatch({ type: "pageLimits", payload: pageLimit });
    setIsLoading(true);
    const notifyMeURL = `${environment.baseUrl}coupon-card/?page=${pageNo}&page_size=${pageLimit}`;
    const result = axios
      .get(notifyMeURL)
      .then((response) => {
        // console.log(response.data.results)
        setOfferCards(response.data.results);
        setOfferCardsPageCount(Math.ceil(response.data.count / pageLimit));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const payload = {
      name: data.name,
      required: data.required === "true" ? true : false,
    };
    const offerCardUrl = `${environment.baseUrl}coupon-card/`;
    axios
      .post(offerCardUrl, payload)
      .then((response) => {
        if (response.data.status === "success") {
          swal({
            title: "Success",
            text: response.data.message,
            icon: "success",
            button: "Ok",
          }).then(() => {
            setIsLoading(false);
          });
        } else {
          swal({
            title: "Warning",
            text: response.data.message,
            icon: "warning",
            button: "Ok",
          }).then(() => {
            setIsLoading(false);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        swal({
          title: "Error",
          text: JSON.stringify(err),
          icon: "error",
          button: "Ok",
        }).then(() => {
          window.location = "/coupon-offers";
        });
      });
  };

  var jsonObj = [];

  for (let i = 0; i < offerCards?.length; i++) {
    var item = {};
    const required = offerCards[i].required ? "True" : "False";

    item["id"] = i + 1;

    item["name"] = <div className="right-space"> {offerCards[i].name}</div>;
    item["required"] = <div className="right-space"> {required}</div>;

    item["edit"] = (
      <div className="right-space">
        <OfferCardsModal
          bnt=""
          color="primary"
          header
          title="Update Offer Card"
          id={offerCards[i].id}
          cardname={offerCards[i].name}
          required={offerCards[i].required}
          GetOfferCards={GetOfferCards}
        />
      </div>
    );

   

    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Name",
        accessor: "name",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Required Status",
        accessor: "required",
        disableGlobalFilter: true,
        width: 75,
      },

      {
        Header: "Edit",
        accessor: "edit",
        disableGlobalFilter: true,
        width: 20,
      },
    ],
    rows: jsonObj,
  };

 

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10000, 20000, 30000, 40000],
    placeholder: "Search by Coupon...",
  };

  const handleOfferCardsPageClick = (data) => {
    setIsLoading(true);
    setCouponInitialPage(data.selected);
    const notifyMeURL = `${environment.baseUrl}coupon-card/?page=${
      data.selected + 1
    }&page_size=${state.pageLimits}`;
    const result = axios
      .get(notifyMeURL)
      .then((response) => {
        setOfferCards(response.data.results);
        setOfferCardsPageCount(
          Math.ceil(response.data.count / state.pageLimits)
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleLimitChange = (e) => {
    dispatch({ type: "pageLimits", payload: e.target.value });
    setCouponInitialPage(0);
    GetOfferCards(1, e.target.value);
  };

  useEffect(() => {
    GetOfferCards(1, state.pageLimits);
  }, []);

  return (
    <Container>
      <Row md={12}>
        <Col>
          <h3 className="page-title">Add Offers Card</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {isLoading && (
                <div class="loader-div">
                  <span class="loader">
                    <div className="center-spinner">
                      <Spinner className="table-fetch-spinner" />
                    </div>
                  </span>
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col lg={4}>
                    <div className="filter-fields email-name-feilds">
                      <input
                        name="name"
                        id="name"
                        className="filter-name"
                        placeholder=" Enter Card Name"
                        {...register("name")}
                      />
                      {errors.name && (
                        <span className="error-msg">
                          Please Enter Name Of Card
                        </span>
                      )}
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="filter-fields email-name-feilds">
                      <select
                        {...register("required")}
                        className="filter-client-email-select-option"
                      >
                        <option value={false}>Required Status</option>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    </div>
                  </Col>

                  <Col lg={4}>
                    <button
                      type="submit"
                      id="submit"
                      className="justpadel-buttons  border-0 float-right mt-4 "
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </form>

              <Row>
                <Col>
                  <div className="react-table__wrapper hide-table-costomizer">
                    <ReactTableCustomizer
                      handleClickIsSortable={handleClickIsSortable}
                      handleClickWithPagination={handleClickWithPagination}
                      handleClickWithSearchEngine={handleClickWithSearchEngine}
                      handleClickIsResizable={handleClickIsResizable}
                      isResizable={isResizable}
                      isSortable={isSortable}
                      withPagination={withPagination}
                      withSearchEngine={withSearchEngine}
                    />
                  </div>
                  <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                    <ReactTableBase
                      id="table-to-xls"
                      key={withSearchEngine ? "searchable" : "common"}
                      columns={data.columns}
                      data={data.rows}
                      tableConfig={tableConfig}
                    />
                  </div>
                  <div className="custom-pagination">
                    <div className="records-per-page">
                      <select
                        value={state.pageLimits}
                        onChange={handleLimitChange}
                        name="limit"
                        id="select-pagination"
                      >
                        {mannualLimits?.map((mannualLimits) => (
                          <option value={mannualLimits}>{mannualLimits}</option>
                        ))}
                      </select>
                      &nbsp;&nbsp;&nbsp;records per page
                    </div>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={offerCardsPageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handleOfferCardsPageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={couponInitialPage}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
