import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal,Table } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas';
import { GetAddon, BookingDetailsByBookingID, GetPadelholicLevel } from '../../containers/Reuse/ApiCalls';
import './InvoiceModal.css'
import axios from '../../customAxios';
import { environment } from '../../containers/env';
import { FiUser } from 'react-icons/fi';
import { AiOutlineMail, AiOutlinePhone, AiOutlineDownload } from 'react-icons/ai';
import { BsDownload } from 'react-icons/bs';
import moment from 'moment';


const InvoiceModal = ({
  color, btn, title, message, colored, header, rtl, transactionId
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(prevState => !prevState);  

    if(!modal){
      setTimeout(() => {
        printDocument(transactionDetails?.booking?.booking_id);
      }, 100);
    }

    
  };

  const [transactionDetails, setTransactionDetails] = useState();
  const [transactionLogs, setTransactionLogs] = useState();
  const [bookedSlots, setBookedSlots] = useState([]);
  const [finalAddonList, setFinalAddonList] = useState([]);
  const [padelholicLevels, setPadelholicLevels] = useState();
  const [totalCourtAmount, setTotalCourtAmount] = useState(0);
  const [totalAddonAmount, setTotalAddonAmount] = useState(0);
  
  const transactionLog = `${environment.baseUrl}transaction_log/${transactionId}`;
  const getTransactionLog = async () => {
    const result = await axios.get(transactionLog)
      .then((response) => {
        BookingDetailsByBookingID(response?.data?.booking?.booking_id).then((response) => {
          // console.log("response.data", response.data);
          addonFunction(response?.data);
          setBookedSlots(response?.data?.booked_slots);
          // console.log("response?.data?.booked_slots",response?.data?.booked_slots);
          let TotlaCourtPrice
          if(response?.data?.booked_slots === undefined){
            setTotalCourtAmount(0);
            return;
          }
          else if (response?.data?.booked_slots?.length === 1) {
            TotlaCourtPrice = response?.data?.booked_slots[0].Price
            // console.log("TotlaCourtPrice", TotlaCourtPrice);
            setTotalCourtAmount(TotlaCourtPrice);
          } else {
            TotlaCourtPrice = response?.data?.booked_slots.reduce(function (a, b) { return { Price: a.Price + b.Price }; })
            // console.log("TotlaCourtPrice", TotlaCourtPrice);
            setTotalCourtAmount(TotlaCourtPrice?.Price);
          }
        })
        // const NoOfGamesPlayes = response?.data?.user?.no_of_games
        // GetPadelholicLevel().then((response) => {
        //   const padelHolicLevel = parseInt(NoOfGamesPlayes);
        //   const padelLevels = response ?? [];
        //   let matchingPadelLevel = padelLevels.find(element => element.games >= padelHolicLevel);
        //   setPadelholicLevels(matchingPadelLevel);
        //   if (matchingPadelLevel == null) {
        //     matchingPadelLevel = padelLevels[padelLevels.length - 1];
        //     setPadelholicLevels(matchingPadelLevel);
        //   }
        // })
        setTransactionDetails(response?.data);
        setTransactionLogs(JSON.parse(response?.data?.transaction_log)[0]);
        
      })
      .catch((error) => {
        console.log(error);
      })
  };


  useEffect(() => {
    getTransactionLog();
    }, []);


  const addonFunction = async (data) => {
    if (data.addons === "null" || data.addons === null || data.addons === undefined) {
      return;
    }
    var match = data.addons;
    let seperateComma = match.split(",");
    var arrays = []
    var addonArray = []
    for (var i = 0; i < seperateComma.length; i++) {
      arrays = seperateComma[i].replace(/'/ig, "").split("-")
      let objs = {};
      objs['id'] = parseInt(arrays[0]);
      objs['quantity'] = parseInt(arrays[1]);
      addonArray.push(objs);
    }
    localStorage.setItem("addonList", JSON.stringify(addonArray));
    addonListData();

  };

  const addonListData = () => {
    GetAddon().then((response) => {
      let addonNewList = response;
      let purchaseAddonList = JSON.parse(localStorage.getItem("addonList"))
      const result = addonNewList?.map(function (el) {
        var o = Object.assign({}, el);
        const quantity = purchaseAddonList?.find(e => e.id === el.id);
        o.qty = quantity?.quantity;
        return o;
      });
      setFinalAddonList(result);
      // = result.reduce(function (a, b) { return { price: a.price * a.qty + b.price * a.qty }; })
      let TotlaAddonPrice = 0;
      if(result.length === 0){
        setTotalAddonAmount(TotlaAddonPrice);
      }else if (result.length === 1) {
        TotlaAddonPrice = result[0].price * result[0].qty;
        // console.log("TotlaAddonPrice if", TotlaAddonPrice);
        setTotalAddonAmount(TotlaAddonPrice);
      } else {
        TotlaAddonPrice = result.reduce(function (a, b) { return { price: a.price * a.qty + b.price * b.qty }; })
        // console.log("TotlaAddonPrice else", TotlaAddonPrice);
        setTotalAddonAmount(TotlaAddonPrice?.price);
      }

      // console.log("------------------------------Dummy Testing Data For Addon----------------------------------")
      // const dummyAddon = [
        
      // ];
      // console.log("Addon Result dummyAddon", dummyAddon.length);
      // let dummyAddonTotlaAddonPrice = 0;
      // if (dummyAddon.length === 0) {
      //   console.log("dummy Addon Totla Addon Price if", dummyAddonTotlaAddonPrice);
      // } else if (dummyAddon.length === 1) {
      //   dummyAddonTotlaAddonPrice = dummyAddon[0].price * dummyAddon[0].qty;
      //   console.log("dummy Addon Totla Addon Price else if", dummyAddonTotlaAddonPrice);
      // } else {
      //   dummyAddonTotlaAddonPrice = dummyAddon.reduce(function (a, b) { return { price: a.price * a.qty + b.price * a.qty }; })
      //   console.log("dummyAddonTotlaAddonPrice else", dummyAddonTotlaAddonPrice);
      // }
    })}

  const printDocument = (bookingId) => {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 20, 20, 170, 260);
        pdf.autoPrint();
        pdf.save(`#${bookingId}.pdf`);       
      })
      .catch((err)=>{
        console.log(err)
      })
      ;
  }

  let Icon;

  switch (color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  return (
    <div>
      <div className="modal-download-btn" 
      style={{marginTop:5}}
       onClick={toggle}
      >
       <BsDownload style={{fontSize:18}} />
       {btn}
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--${color} ${modalClass}`}
        // style={{minWidth:'50%'}}
         >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={toggle}
          />
          {header ? '' : Icon}
          <h4 className="text-modal  modal__title">{title}</h4>
        </div>
        <div onClick={()=>printDocument(transactionDetails?.booking?.booking_id)} style={{width:'100%',display:'flex',justifyContent:'flex-end',alignItems:'center',cursor:'pointer'}}>{btn}
          <div className="download-btn">Download</div>
         </div>
        <div className="modal__body" >
          <div id="divToPrint">

          <div className="download-booking-id">
           <div>
             <span className="provide-name">Just Padel Team</span>
           </div>
           <div>
            <label style={{ fontSize: 17, fontWeight: 'bold' }}>Booking ID:</label>
            <label style={{ fontSize: 17, fontWeight: 'bold' }}>&nbsp;&nbsp;#{transactionDetails?.booking?.booking_id === "null" || "" ? " " : transactionDetails?.booking?.booking_id}</label>
           </div>
          </div>
          <hr className="border-id-line"/>
          <div className="payment-datails">
            <div className="payment-datails-lhs">
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 14, width: "57%" }}>Status: </label> {'  '}
                <label style={{ fontSize: 14 }}>{transactionDetails?.booking?.amount_percentage === 0.5 ? "Half Paid" : "Paid"}</label>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 14, width: "57%" }}>Payment processor: </label> {'  '}
                <label style={{ fontSize: 14, fontWeight: 'bold' }}>Payfort</label>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 14, width: "57%" }}>Payment received:  </label> {'  '}
                <label style={{ fontSize: 14, fontWeight: 'bold' }}>{transactionDetails?.booking?.amount_paid.toFixed(2)} AED</label>
              </div>
            </div>
            <div className="payment-datails-rhs">
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 14, width: "37%" }}>Invoice date: </label> {'  '}
                <label style={{ fontSize: 14, fontWeight: 'bold' }}>{moment(transactionDetails?.booking?.created_at.split('T')[0]).format("DD-MM-YYYY")}{' '}{moment(transactionDetails?.booking?.created_at.split('T')[1].split('.')[0], "HH:mm:ss").format("hh:mm A")}</label>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ fontSize: 14, width: "37%" }}>Due date: </label> {'  '}
                <label style={{ fontSize: 14, fontWeight: 'bold' }}> {moment(transactionDetails?.booking?.booking_date).format("DD-MM-YYYY")} {transactionDetails?.booking?.timeslot.split("-")[0]}</label>
              </div>
            </div>
          </div>

          <div className="download-user-datails">
            <div className="download-user-datails-lhs">
              <Table responsive hover>
                <tbody className="table-trans-user-details">
                  <tr>
                    <td className="first-td-width">
                      <div className="trans-client-name"><FiUser className="client-icon" /><h5>Client Name</h5></div>
                    </td>
                    <td className="client-name">
                      {transactionDetails?.user?.first_name === "null" ? " " : transactionDetails?.user?.first_name}{' '}
                      {transactionDetails?.user?.last_name === "null" ? " " : transactionDetails?.user?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="first-td-width">
                      <div className="trans-client-name"><AiOutlineMail className="client-icon" /> <h5>Email</h5></div>
                    </td>
                    <td className="client-email">{transactionDetails?.user?.email === "null" ? " " : transactionDetails?.user?.email}</td>
                  </tr>
                  <tr>
                    <td className="first-td-width">
                      <div className="trans-client-name"><AiOutlinePhone className="client-icon" /><h5 >Phone</h5></div>
                    </td>
                    <td className="client-Ph-no">{transactionDetails?.user?.phone_no === "null" ? " " : transactionDetails?.user?.phone_no}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="download-user-datails-lhs">
              <h5>Payment received by payment system</h5>
            </div>
          </div>

          <div className="trans-total-details">
            <Table responsive hover>
              <thead className="trans-total-table-header">
                <tr style={{fontSize:13}}>
                  <th>#</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th style={{minWidth:50}}>Qty</th>
                  {/* <th>Amount</th> */}
                </tr>
              </thead>
              <tbody>

                {
                  bookedSlots && bookedSlots?.map((data, index) => {
                    return (
                      <tr key={index} style={{fontSize:12}}>
                        <td>{index + 1}</td>
                        <td style={{paddingRight:10}}>
                          {data.main_court_name} - {" "}
                          {" Court "}{data.padel_court_name}
                          {" "}({data?.date + "  " + data?.time})
                        </td>
                        <td>{data.Price.toFixed(2)} AED</td>
                        <td style={{minWidth:50, marginLeft:10}}>1</td>
                      </tr>
                    )
                  })
                }
                {/* <tr>
                  <td>0</td>
                  <td>
                    {transactionDetails?.booking?.main_court?.courtName === "null" ? " " : transactionDetails?.booking?.main_court?.courtName} - {" "}
                    {transactionDetails?.booking?.padel_court?.courtname === "null" ? " " : "Court " + transactionDetails?.booking?.padel_court?.courtname}
                     {" "} (08-11-2021 06:00 PM, 1ca9hdv7ek)
                  </td>
                  <td>	220.00 AED</td>
                  <td>1</td>
                  <td>231.00 AED</td>
                </tr> */}
              </tbody>
            </Table>
          </div>
          <div className="trans-total-amount">
            <div>
              <label style={{ fontSize: 15 }}>VAT 5%: </label> {' '}
              <label style={{ fontSize: 15 }}>{((totalCourtAmount + totalAddonAmount) / 100*5).toFixed(2)} AED</label>
            </div>
            <div>
              <label style={{ fontSize: 15, fontWeight: 'bold' }}>Total amount: </label> {' '}
              <label style={{ fontSize: 15, fontWeight: 'bold' }}>{(totalCourtAmount + totalAddonAmount + (totalCourtAmount + totalAddonAmount) / 100*5).toFixed(2)} AED</label>
            </div>
          </div>

          <div className="download-trans-data-container">
            <div className="download-trans-data-lhs">
              {
                transactionDetails?.booking?.addons === "null" || transactionDetails?.booking?.addons === null ?
                  <div className="no-addons">
                    <h4>No Addons</h4>
                  </div>
                  :
                  <Table hover>
                    <thead className="trans-total-table-header">
                      <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th>Price </th>
                        <th>Quantity </th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        finalAddonList.map((data, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data?.addonname}</td>
                              <td>{data?.price}{" "}AED</td>
                              <td>{data?.qty}</td>
                              <td>{data?.price * data?.qty}{" "}AED</td>
                            </tr>
                          )
                        })
                      }

                    </tbody>
                  </Table>
              }
            </div>
            <div className="download-trans-data-rhs">
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 14, width: "30%" }}>Coupon Applied: </label> {'  '}
                <label className="download-pay-result">{transactionDetails?.booking?.coupan_applied === "" ? "NO" : `YES (${transactionDetails?.booking?.coupan_applied})`}</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 14, width:"30%" }}>Discounted Price: </label> {'  '}
                <label className="download-pay-result">00.00 AED</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 14, width: "30%" }}>Amount Paid: </label> {'  '}
                <label className="download-pay-result">{transactionDetails?.booking?.amount_percentage === 1 ? "100%" : transactionDetails?.booking?.amount_percentage === 0.5 ? "50%" : ''}</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 14, width: "30%" }}>Advance Paid: </label> {'  '}
                <label className="download-pay-result">{transactionDetails?.booking?.amount_paid.toFixed(2)} AED</label>
              </div>
              <div className="trans-data-inner-rhs">
                <label style={{ fontSize: 14, width: "30%" }}>To be Paid at Facility:</label> {'  '}
                <label className="download-pay-result">{transactionDetails?.booking?.amount_percentage === 1 ? "0.00" : transactionDetails?.booking?.amount_paid.toFixed(2)} AED</label>
              </div>
            </div>
          </div>
          </div>
        </div>
        <ButtonToolbar className="modal__footer invoice-btn">
          <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
          <Button className="modal_ok" outline={colored} color={color} onClick={toggle}>Ok</Button>
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

InvoiceModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  btn: PropTypes.string.isRequired,
  rtl: RTLProps.isRequired,
};

InvoiceModal.defaultProps = {
  title: '',
  message: '',
  colored: false,
  header: false,
};

export default connect(state => ({
  rtl: state.rtl,
}))(InvoiceModal);
