import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Card, CardBody, Spinner } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { environment } from '../env';
import axios from '../../customAxios';
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import { AiFillEye, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import swal from 'sweetalert';
import moment from 'moment';

export const EventTournamentRegistration = () => {
    const history = useHistory()
    const [eventData, setEventData] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const GetEventData = async () => {
        setIsLoading(true)
        // const eventListUrl = `${environment.baseUrl}create-event-registration/`
        const eventListUrl = `${environment.baseUrl}current-active-event`
        await axios.get(eventListUrl).then(res => {
            setEventData(res.data.message)
            setIsLoading(false)
        })
    }
    const handleAddEvents = () => {
        history.push('/add-event-tournament')
    }
    const handleViewEvents = (id) => {
        history.push(`/view-event-tournament/${id}`)
    }
    const handleEditEvents = (id) => {
        history.push(`/edit-event-tournament/${id}`)
    }
    const handleDeleteEvent = async (id) => {
        setIsLoading(true)
        swal({
            title: "Are you sure?",
            text: "You want to delete the Event",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                const eventRegistrationUrl = `${environment.baseUrl}edit-event-registration/${id}`;
                axios.delete(eventRegistrationUrl).then((res) => {
                    if (res.data.status === "success") {
                        swal({
                            text: "Event Deleted Successfully",
                            icon: "success",
                            buttons: true,
                        }).then(() => {
                            window.location = "/event-registration-tabs";

                        });
                        setIsLoading(false)
                    } else {
                        swal({
                            text: "Error.. While Deleting Event",
                            icon: "error",
                            buttons: true,
                        })
                        setIsLoading(false);
                    }
                }).catch((err) => {
                    swal({
                        text: "Error.. While Deleting Events",
                        icon: "error",
                        buttons: true,
                    }).then(() => {
                        console.log(err);
                    });
                    setIsLoading(false);
                })
            } else {
                setIsLoading(false);
                window.location = "/event-tournaments-registration";
            }
        });
    }
    const formatDate = (date) => {
        if (date?.length > 9) {
            const date_arr = date?.split(",")
            const new_arr = date_arr?.map((newdate) => {
                return moment(newdate).format('MMM D')
            })
            return new_arr.join("-")
        } else {
            return moment(date).format('MMM D')
        }
    }

    var jsonObj = [];
    for (let i = 0; i < eventData?.length; i++) {
        var item = {};
        const formatedDate = formatDate(eventData[i]?.event_date)
        item["id"] = i + 1;
        item["event_image"] = (
            <div style={{ paddingLeft: 10 }}><img src={eventData[i].event_image} alt={eventData[i].event_image}
                style={{ width: 100, height: 100, borderRadius: 5 }} />
            </div>
        );
        item["event_name"] = (
            <div className="Booking-details-td-right-space">
                {eventData[i]?.event_name}
            </div>
        );

        item["event_location"] = (
            <div className="Booking-details-td-right-space">
                {eventData[i]?.event_location}
            </div>
        );

        item["event_price"] = (
            <div className="Booking-details-td-right-space">
                {eventData[i]?.event_price} AED
            </div>
        );

        item["event_date"] = (
            <div className="Booking-details-td-right-space">
                {formatedDate}
            </div>
        );

        item["view"] = (
            <div className="right-space">
                <div className="modal-view-btn" size="sm" onClick={() => handleViewEvents(eventData[i].event_id)}>
                    <AiFillEye style={{ fontSize: 20 }} />
                </div></div>
        );
        item["edit"] = (
            <div className="right-space">
                <div className="modal-edit-btn" size="sm"
                    onClick={() => handleEditEvents(eventData[i].event_id)}
                >
                    <AiOutlineEdit style={{ fontSize: 20 }} />
                </div></div>
        );

        // item["delete"] = (
        //     <div className="right-space">
        //         <div className="modal-delete-btn" size="sm"
        //             onClick={() => handleDeleteEvent(eventData[i].event_id)}
        //         >
        //             <AiOutlineDelete style={{ fontSize: 20 }} />
        //         </div></div>
        // );
        jsonObj.push(item);
    }

    const data = {
        columns: [
            {
                Header: "#",
                accessor: "id",
                disableGlobalFilter: true,
                width: 70,
            },
            {
                Header: "Event Image",
                accessor: "event_image",
                disableGlobalFilter: true,
                width: 100,
            },
            {
                Header: "Event Name",
                accessor: "event_name",
                disableGlobalFilter: true,
                width: 100,
            },

            {
                Header: "Event Location",
                accessor: "event_location",
                disableGlobalFilter: true,
                width: 100,
            },
            {
                Header: "Event Price",
                accessor: "event_price",
                disableGlobalFilter: true,
                width: 100,
            },
            {
                Header: "Event Date",
                accessor: "event_date",
                disableGlobalFilter: true,
                width: 100,
            },
            {
                Header: "View",
                accessor: "view",
                disableGlobalFilter: true,
                width: 50,
            },
            {
                Header: "Edit",
                accessor: "edit",
                disableGlobalFilter: true,
                width: 50,
            },
            // {
            //     Header: "Delete",
            //     accessor: "delete",
            //     disableGlobalFilter: true,
            //     width: 50,
            // },
        ],
        rows: jsonObj,
    };
    const [withPagination, setWithPaginationTable] = useState(true);

    const [isResizable, setIsResizable] = useState(true);

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };
    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };
    const tableConfig = {
        isEditable: false,
        isResizable,
        withPagination,
        manualPageSize: [10, 20, 30, 40],
    };
    useEffect(() => {
        GetEventData()
    }, [])
    return (
        <>
            <Row className='mt-2'>
                <Col md={12} lg={12}>
                    {isLoading && (
                        <div class="loader-div">
                            <span class="loader">
                                <div className="center-spinner">
                                    <Spinner className="table-fetch-spinner" />
                                </div>
                            </span>
                        </div>
                    )}
                </Col>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="react-table__wrapper hide-table-costomizer ">

                                <div className="pagination-dropdown ">
                                    <ReactTableCustomizer
                                        handleClickWithPagination={handleClickWithPagination}
                                        handleClickIsResizable={handleClickIsResizable}
                                        isResizable={isResizable}
                                        withPagination={withPagination}
                                    />
                                </div>
                            </div>
                            <div className="hover-effect table-id-desabled second-child-space">
                                <ReactTableBase
                                    columns={data.columns}
                                    data={data.rows}
                                    tableConfig={tableConfig}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
