/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  ButtonToolbar,
  Spinner,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
// import axios from "axios";
import axios from "../../../customAxios";
import { environment } from "../../env";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import swal from "sweetalert";

const EditRole = () => {
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState();
  const history = useHistory();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [pages, setPages] = useState({
    calender: "",
    manual_bookings: "",
    booking_list: "",
    notify_me: "",
    transaction: "",
    main_court: "",
    padel_court: "",
    add_on: "",
    court_type: "",
    court_category: "",
    slides: "",
    ratings: "",
    padelholic: "",
    users_ratings: "",
    coupon: "",
    registered_user: "",
    create_user: "",
    user_role_list: "",
    create_user_role: "",
    offer_doc: "",
    skill_verify_coach: "",
  });
  const { id } = useParams();
  const handleChange = (event) => {
    const { name, value } = event.target;
    const tempPages = { ...pages, [name]: value };
    setPages(tempPages);
  };

  // function handleChangeMainCourt(e) {
  //   setMainCourt(e.target.value);
  // }

  // function handlePagePadelCourt(e) {
  //   setPadelCourt(e.target.value);
  // }

  // function handleChangeRoleType(e) {
  //   setRoleType(e.target.value);
  // }

  const handleSubmitRole = () => {
    setIsLoading(true);

    var permissiondata = [
      { calender: pages.calender },
      { manual_bookings: pages.manual_bookings },
      { booking_list: pages.booking_list },
      { notify_me: pages.notify_me },
      { transaction: pages.transaction },
      { main_court: pages.main_court },
      { padel_court: pages.padel_court },
      { add_on: pages.add_on },
      { court_type: pages.court_type },
      { court_category: pages.court_category },
      { slides: pages.slides },
      { ratings: pages.ratings },
      { padelholic: pages.padelholic },
      { users_ratings: pages.users_ratings },
      { coupon: pages.coupon },
      { registered_user: pages.registered_user },
      { create_user: pages.create_user },
      { user_role_list: pages.user_role_list },
      { create_user_role: pages.create_user_role },
      { offer_doc: pages.offer_doc ? pages.offer_doc : "" },
      { skill_verify_coach: pages.skill_verify_coach ? pages.skill_verify_coach : "" }
    ];
    var per = JSON.stringify(permissiondata);
    const roleData = {
      role_name: role,
      permissions: per,
    };
    const getRoleUrl = `${environment.baseUrl}role_by_id/${id}`;
    axios.put(getRoleUrl, roleData).then((response) => {
      setIsLoading(false);
      if (response.data.msg === "Role is update successfull") {
        swal({
          title: "Success",
          text: "Role Updated Successfully",
          icon: "success",
          button: "Ok",
        })
          .then(() => {
            history.push("/role_list");
          })
      } else {
        swal({
          title: "Error",
          text: "Error while updating role!",
          icon: "error",
          button: "Ok",
        })
          .then(() => {
            history.push("/role_list");
          })
      }
    });
    // const roleUrl = `${environment.baseUrl}role`;
    // const result = axios.post(roleUrl, roleData).then((response) => {
    // });
  };

  const getRoles = () => {
    setIsLoading(true);
    const getRoleUrl = `${environment.baseUrl}role`;
    const userRoles = axios
      .get(getRoleUrl)
      .then((response) => {
        setRoles(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error" + err);
      });
  };

  const getRole = () => {
    const localrole = localStorage.getItem("Role");
    const getRoleUrl = `${environment.baseUrl}role_by_id/${id}`;
    const result = axios.get(getRoleUrl).then((response) => {
      var permissiondata = JSON.parse(response.data.permissions);

      // setPages(permissiondata);
      setRole(response.data.role_name);
      setPages({
        calender: permissiondata[0].calender,
        manual_bookings: permissiondata[1].manual_bookings,
        booking_list: permissiondata[2].booking_list,
        notify_me: permissiondata[3].notify_me,
        transaction: permissiondata[4].transaction,
        main_court: permissiondata[5].main_court,
        padel_court: permissiondata[6].padel_court,
        add_on: permissiondata[7].add_on,
        court_type: permissiondata[8].court_type,
        court_category: permissiondata[9].court_category,
        slides: permissiondata[10].slides,
        ratings: permissiondata[11].ratings,
        padelholic: permissiondata[12].padelholic,
        users_ratings: permissiondata[13].users_ratings,
        coupon: permissiondata[14].coupon,
        registered_user: permissiondata[15].registered_user,
        create_user: permissiondata[16].create_user,
        user_role_list: permissiondata[17].user_role_list,
        create_user_role: permissiondata[18].create_user_role,
        offer_doc: permissiondata[19]?.offer_doc
          ? permissiondata[19]?.offer_doc
          : "4",
        skill_verify_coach: permissiondata[20]?.skill_verify_coach
          ? permissiondata[20]?.skill_verify_coach
          : "4",
      });
    });
  };

  useEffect(() => {
    getRoles();
    getRole();
  }, []);
  const setPermissions = (roledata) => {
    // const localrole = "admin";
    const localrole = localStorage.getItem("Role");
    let newRoleId;

    if (localrole === roledata.role_name) {
      let permission = JSON.parse(roledata.permissions);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "manual_bookings";
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
      console.log("false");
    }
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
  };
  const reset = () => {
    history.push("/role_list");
  };
  return (
    <>
      <Col md={12}>
        <Card>
          <CardBody>
            {isLoading && (
              <div class="loader-div">
                <span class="loader">
                  <div className="center-spinner">
                    <Spinner className="table-fetch-spinner" />
                  </div>
                </span>
              </div>
            )}
            <form
              className="form product-edit"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="form__half">
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Role Name {id}
                  </span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      value={role}
                      name="role_name"
                      onChange={(e) => setRole(e.target.value)}
                      style={{ borderRadius: 5, borderColor: "#646777" }}
                    />
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Calender
                  </span>
                  <div className="form__form-group-field">
                    <select
                      name="calender"
                      className="select"
                      onChange={handleChange}
                      value={pages.calender}
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Manual Bookign
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.manual_bookings}
                      name="manual_bookings"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Bookign List
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.booking_list}
                      name="booking_list"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Notify Me
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.notify_me}
                      name="notify_me"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Transactions
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.transaction}
                      name="transaction"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Main Court
                  </span>
                  <div className="form__form-group-field">
                    <select
                      name="main_court"
                      className="select"
                      onChange={handleChange}
                      value={pages.main_court}
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Padel Court
                  </span>
                  <div className="form__form-group-field">
                    <select
                      name="padel_court"
                      className="select"
                      onChange={handleChange}
                      value={pages.padel_court}
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Add on's
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.add_on}
                      name="add_on"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Court Type
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.court_type}
                      name="court_type"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Court Category
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.court_category}
                      name="court_category"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Slides
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.slides}
                      name="slides"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Ratings
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.ratings}
                      name="ratings"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Padelholic
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.padelholic}
                      name="padelholic"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Users Ratings
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.users_ratings}
                      name="users_ratings"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Coupon
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.coupon}
                      name="coupon"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Registered User
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.registered_user}
                      name="registered_user"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Create User
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.create_user}
                      name="create_user"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    User Role List
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.user_role_list}
                      name="user_role_list"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Create User List
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.create_user_role}
                      name="create_user_role"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Offer Doc
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.offer_doc}
                      name="offer_doc"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form__half" style={{ marginTop: 15 }}>
                <div className="form__form-group">
                  <span
                    className="form__form-group-label"
                    style={{ fontSize: 15 }}
                  >
                    Skill Verify Coach
                  </span>
                  <div className="form__form-group-field">
                    <select
                      className="select"
                      onChange={handleChange}
                      value={pages.skill_verify_coach}
                      name="skill_verify_coach"
                    >
                      <option value="1">Read</option>
                      <option value="2">Read/Edit</option>
                      <option value="3">Read/Edit/Delete</option>
                      <option value="4">Hide</option>
                    </select>
                  </div>
                </div>
              </div>
              <Col md={12} lg={12}>
                <Card style={{ marginTop: 30 }}>
                  <div className="form__half">
                    <ButtonToolbar
                      className="form__button-toolbar"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div
                        className="justpadel-buttons-save"
                        onClick={() => handleSubmitRole()}
                      >
                        Update Role
                      </div>
                      <div
                        className="justpadel-buttons-cancel"
                        onClick={() => reset()}
                      >
                        Cancel
                      </div>
                    </ButtonToolbar>
                  </div>
                </Card>
              </Col>
            </form>
          </CardBody>
        </Card>
      </Col>
      <Col md={12} style={{ marginTop: "5px" }}>
        <Card>
          <CardBody></CardBody>
        </Card>
      </Col>
    </>
  );
};
export default EditRole;
