import React, { useEffect, useState } from 'react';
import { Col } from "reactstrap";
import axios from '../../../../customAxios';
import { environment } from '../../../env';
import { GetAdminBooking, GetUserDetailsByEmail } from '../../../Reuse/ApiCalls';

const AdminLoyaltyDeatils = (props) => {

    const [noDataAvailable, setNoDataAvailable] = useState([]);
    const [details, setDetails] = useState([]);
    const [loyaltypoints, setLoyaltypoints] = useState();
    const [loyaltyGames, setLoyaltyGames] = useState();

    const { transactionId, updateAdminBookings } = props;

    const Silver = loyaltyGames >= 1 && loyaltyGames <= 30;
    const Gold = loyaltyGames >= 31 && loyaltyGames <= 50;
    const Platinum = loyaltyGames >= 51 && loyaltyGames <= 100;
    const BlackDiamond = loyaltyGames >= 101;

    const GetAdminBookingDetails = async (BookingId) => {
        try {
           await GetAdminBooking(BookingId).then((response) => {

                if (response.msg === "Data is not available") {
                    setNoDataAvailable(response?.msg)
                } else {
                    setDetails(response);
                    GetUserDetails(response?.customer_email);
                }
            })
        } catch (error) {
            console.log("error-------------", error);
        }
    }

    const GetUserDetails = async (email) => {
      await  GetUserDetailsByEmail(email).then((res) => {
        // console.log("userDetails------------",res);
            GetLoyaltypoints(res?.id)
        })
    }

    const GetLoyaltypoints = async (id) => {
        // console.log("GetLoyaltypoints ------------------",id);
        if (id) {
            const getloyaltypointsurl = `${environment.baseUrl}loyaltypoint/getloyaltypoints/${id}`;
           await axios.get(getloyaltypointsurl).then((response) => {
                setLoyaltypoints(response?.data);
                setLoyaltyGames(response?.data?.loyaltygames)
            }).catch((err) => {
                console.log("err", err);
            })
        }
    };

    useEffect(() => {
        GetAdminBookingDetails(transactionId);
    }, []);

    useEffect(() => {
        GetAdminBookingDetails(transactionId);
    }, [updateAdminBookings]);

    return (
        <>
            {
                noDataAvailable === "Data is not available" ?
                    <Col>
                        <div className='d-flex flex-row align-items-baseline userdetails'>
                            "UnRegistered User"
                        </div>
                    </Col>

                    :

                    <Col md={12} lg={12}>
                        <div className="d-flex flex-row align-items-baseline userdetails">
                            Client:{" "}
                            <p className="font-weight-bold pl-1 pr-1">
                                {" "}
                                {`${details?.customer_name} (#${details?.booking})`}{" "}
                            </p>{" "}
                            | Current:{" "}
                            <p className="font-weight-bold pl-1 pr-1">{` ${loyaltypoints?.loyaltyavailable ?? "0"}`}</p>{" "}
                            | Reward Level:{" "}
                            <p className="font-weight-bold pl-1 pr-1">
                                {Silver
                                    ? "Silver"
                                    : Gold
                                        ? "Gold"
                                        : Platinum
                                            ? "Platinum"
                                            : BlackDiamond
                                                ? "BlackDiamond"
                                                : "Silver"}
                            </p>{" "}
                            |{" "}
                            <p className="font-weight-bold pl-1 pr-1">
                                {Silver
                                    ? `${loyaltyGames}/30  Session`
                                    : Gold
                                        ? `${loyaltyGames}/50  Session`
                                        : Platinum
                                            ? `${loyaltyGames}/100  Session`
                                            : BlackDiamond
                                                ? `${loyaltyGames}/150  Session`
                                                : `${loyaltyGames ?? "0"}/30  Session`}
                            </p>
                        </div>
                    </Col>
            }
        </>
    )
}

export default AdminLoyaltyDeatils