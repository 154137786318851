import React from "react";
import { Col, Container, Row } from "reactstrap";
import AddCouponOffer from "./AddCouponOffer";

export const CreateCouponOffer = () => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Add Coupon</h3>
        </Col>
      </Row>
      <Row>
        <AddCouponOffer />
      </Row>
    </Container>
  );
};
