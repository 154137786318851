// export default BookingDetailsModalV2

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, CardBody, Modal, Card, Col, } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import { BookingDetailsByBookingID } from '../../containers/Reuse/ApiCalls';
import BookingDetailsTab from '../../containers/UI/Tabs/components/BookingDetailsTab';
import './TransationModal.css';
import { AiFillEye } from 'react-icons/ai';
import BookingDetailsTabV2 from '../../containers/UI/Tabs/components/BookingDetailsTabV2';



const BookingDetailsModalV2 = ({
    color, btn, colored, header, title, transactionId, cancelBoooking, userdetails, prePopulatedDated, showHide
}) => {
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(prevState => !prevState);
        localStorage.removeItem("AdminPaymentType");
    };
    const toggleModal = () => {
        setModal(prevState => !prevState);
    };

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    useEffect(() => {
        setModal(showHide);
    }, [showHide]);
    return (
        <>
            <div className="modal-view-btn"
                onClick={toggle}>
                <AiFillEye style={{ fontSize: 20 }} />
                {btn}
            </div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support `}
                className={`modal-dialog--${color} ${modalClass} modal-xl`}
                style={{ minWidth: 990 }}
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody>
                            <div className="tabs tabs--justify tabs--bordered-bottom">
                                <BookingDetailsTabV2 userdetails={userdetails} transactionId={transactionId} toggleModal={toggle} cancelBoooking={cancelBoooking} close={toggleModal} prePopulatedDated={prePopulatedDated} />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Modal>
        </>
    );
};

BookingDetailsModalV2.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired,
};

BookingDetailsModalV2.defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
};

export default BookingDetailsModalV2;

