import React, { useState, useEffect } from "react";
import axios from "../../../customAxios";
import { useHistory } from "react-router-dom";
import {
  Col,
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Row,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { environment } from "../../env";
import moment from 'moment';
import swal from "@sweetalert/with-react";

const CustomerDetails = () => {
  const history = useHistory();
  const [customerName, setcustomerName] = useState();
  const [customerEmail, setcustomerEmail] = useState();
  const [customerPhone, setcustomerPhone] = useState();
  const [paymentType, setpaymentType] = useState();
  const [cartData, setCartData] = useState();
  const [additionalComments, setAdditionalComments] = useState();
  const [bookedBy, setBookedBy] = useState();
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    const cartPayload = localStorage.getItem("cart_payload");
    console.log("cartPayload", cartPayload);
    setCartData(cartPayload);
    var bookedAdminEmail = localStorage.getItem("Email");
    setBookedBy(bookedAdminEmail);
  }, []);


  const handleBookingWithCustomerDetails = (data) => {
    setSpinner(true);
    console.log("Data", data);
    // bookCourt(data);
    const cartPayload = JSON.parse(localStorage.getItem("cart_payload"));
    setCartData(cartPayload);
    var result = axios
      .post(`${environment.baseUrl}addtocart`, cartPayload)
      .then((response) => {
        console.log("Response Data Cart: ", response?.data);
        if (response.status === 200 || response.status === 201) {
          console.log("Response Data Cart: ", response?.data);
          localStorage.removeItem("cart_payload");
          bookCourt(data);
        } else {
          alert("Server error");
        }
      });
  };
  const bookCourt = async (data) => {
    console.log("bookCourt", data);
    const bookingPayload = JSON.parse(localStorage.getItem("booking_payload"));
    bookingPayload.comments = JSON.stringify([{comment: data?.additionalComments,date:moment(new Date()).format("DD-MM-YYYY"),time:moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),user:localStorage.getItem("AdminUserName")}])
    bookingPayload.booking_category = data.booking_category
    console.log("bookingPayload", bookingPayload);
    const courtBookingUrl = `${environment.baseUrl}courtbooking`;
    var result = await axios
      .post(courtBookingUrl, bookingPayload)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log("response of bookCourt", response);
          console.log("response?.data.id", response?.data?.id);
          localStorage.removeItem("booking_payload");
          console.log("Booking ID", response?.data?.id);
          // handleCustomerDetails(response?.data?.id, data);Message: "Slot not available"
          if(response.data.Message === "Slot not available"){
            setSpinner(false);
            swal({
              icon: "error",
              content: (
                <div>
                  <h1 style={{ color: "darkred" }}>Error</h1>
                  <p>Court is already booked</p>
                </div>
              ),
            });
          }else{
            handleCustomerDetails(response?.data?.id, data);
          }
        } else {
          swal({
            icon: "error",
            content: (
              <div>
                <h1 style={{ color: "darkred" }}>Error</h1>
                <p>Server Error</p>
              </div>
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCustomerDetails = (booking_id, data) => {
    const userId = localStorage.getItem("UserId");
    const customerDetails = {
      customer_name: data?.customerName,
      customer_email: data?.customerEmail,
      customer_phone: data?.customerPhone,
      payment_type: data?.paymentType,
      user: userId,
      booking: booking_id,
      additionalcomments: JSON.stringify([{comment: data?.additionalComments,date:moment(new Date()).format("DD-MM-YYYY"),time:moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),user:localStorage.getItem("AdminUserName")}]),
      sendmail:true,
      // additionalcomments: JSON.stringify([{ comment: data?.additionalComments },]),
    };
    const admonBookingUrl = `${environment.baseUrl}admin_booking`;
    const result = axios
      .post(admonBookingUrl, customerDetails)
      .then((response) => {
        console.log(response);
        setSpinner(false);
        if (response.status === 200 || response.status === 201) {
          if (response.data.msg === "court is booked") {
            swal({
              icon: "success",
              content: (
                <div>
                  <h1 style={{ color: "green" }}>Success</h1>
                  <p>Court is Booked, with booking id {booking_id}</p>
                </div>
              ),
            }).then(() => {
              history.push("/courtbooking");
            });
          } else {
            setSpinner(false);
            swal({
              icon: "error",
              content: (
                <div>
                  <h1 style={{ color: "darkred" }}>Error</h1>
                  <p>Court is already Available</p>
                </div>
              ),
            });
          }
        } else {
          setSpinner(false);
          swal({
            icon: "error",
            content: (
              <div>
                <h1 style={{ color: "darkred" }}>Error</h1>
                <p>Court is already Available</p>
              </div>
            ),
          });
        }
      })
      .catch((err) => {
        setSpinner(false);
        swal({
          icon: "error",
          content: (
            <div>
              <h1 style={{ color: "darkred" }}>Error</h1>
              <p>Error Details : {err}</p>
            </div>
          ),
        });
      });
  };
  const reset = () => {
    history.push("/addon_booking");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    
    handleBookingWithCustomerDetails(data);
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="center-spinner">
            {spinner ? <Spinner className="table-fetch-spinner" /> : ""}
          </div>
          <form className="form product-edit">
            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Customer Name
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    name="customerName"
                    {...register("customerName", { required: true })}
                    style={{ borderRadius: 5, border: "1px solid #9C9C9C" }}
                  />
                </div>
                {errors.customerName && <span style={{color:'#cc0000'}}>Customer Name Is require</span>}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Customer Email
                </span>
                <div className="form__form-group-field">
                  <input
                    type="email"
                    name="customerEmail"
                    {...register("customerEmail", { required: true })}
                    style={{ borderRadius: 5, border: "1px solid #9C9C9C" }}
                  />
                </div>
                {errors.customerEmail && <span style={{color:'#cc0000'}}>Customer Email Is require</span>}
              </div>
            </div>
            <div className="form__half" style={{ paddingTop: 20 }}>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Customer Phone
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    name="customerPhone"
                    {...register("customerPhone", { required: true })}
                    style={{ borderRadius: 5, border: "1px solid #9C9C9C" }}
                  />
                </div>
                {errors.customerPhone && (
                  <span style={{color:'#cc0000'}}>Customer Phone No Is require</span>
                )}
              </div>
            </div>
            <div
              className="form__half"
              style={{ marginLeft: 30, paddingTop: 20 }}
            >
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Payment Type
                </span>
                <div className="form__form-group-field" style={{display:'flex',flexDirection:'column'}}>
                  <select
                    className="form__form-group-fields select"
                    name="paymentType"
                    {...register("paymentType", { required: true })}
                  >
                    <option value="">Pay By</option>
                    <option value="Pay Later">Pay Later</option>
                    <option value="Paid by Cash">Paid by Cash</option>
                    <option value="Paid by Card">Paid by Card</option>
                    <option value="3rd Party App">3rd Party App</option>
                    <option value="Others">Others</option>
                    
                  </select>
                  {errors.paymentType && <span style={{color:'#cc0000'}}>Payment Type Is require</span>}
                </div>
              </div>
            </div>
            <div className="form__half" style={{ paddingTop: 20 }}>
              <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Additional Comments
                </span>
                <div className="form__form-group-field">
                  <textarea
                    type="text"
                    name="additionalComments"
                    {...register("additionalComments", { required: true })}
                    style={{ borderRadius: 5, border: "1px solid #9C9C9C" }}
                  />
                </div>
                {errors.additionalComments && (
                  <span style={{color:'#cc0000'}}>Additional Comments Is require</span>
                )}
              </div>
            </div>
            <div
              className="form__half"
              style={{ marginLeft: 30, paddingTop: 20 }}
            >
               <div className="form__form-group">
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Category
                </span>
                <div className="form__form-group-field" style={{display:'flex',flexDirection:'column'}}>
                <select
                    name="booking_category"
                    className="form__form-group-fields select"
                    {...register("booking_category", { required: true })}
                  >
                    <option value="">Select method</option>
                    <option value="Bookings">Bookings</option>
                    <option value="Events">Events</option>
                    <option value="Tournaments">Tournaments</option>
                    <option value="Maintainence">Maintainence</option>
                    <option value="Coaching">Coaching - Regular</option>
                    <option value="Coaching-Pro Tennis">Coaching - Pro Tennis</option>
                    <option value="Coaching-Just Padel">Coaching - Just Padel</option>
                    <option value="GTAP">GTAP</option>
                    <option value="Matchmaking">Matchmaking</option>
                    <option value="Others">Others</option>

                  </select>
                  {errors.paymentType && <span style={{color:'#cc0000'}}>Payment Type Is require</span>}
                </div>
              </div>
              <div className="form__form-group" style={{marginTop: "20px"}}>
                <span
                  className="form__form-group-label"
                  style={{ fontSize: 15 }}
                >
                  Booked By
                </span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    name="bookedBy"
                    value={bookedBy}
                    {...register("bookedBy")}
                    readOnly
                    style={{ borderRadius: 5, border: "1px solid #9C9C9C" }}
                  />
                </div>
                {errors.bookedBy && <span style={{color:'#cc0000'}}>Booked By Is require</span>}
              </div>
            </div>
            <Col md={12} lg={12}>
              <Card
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: 30,
                }}>
                <div className="form__half" style={{ marginRight: -13 }}>
                  <ButtonToolbar className="form__button-toolbar">
                    <div className="justpadel-buttons-save"  onClick={handleSubmit(onSubmit)}>
                      Book Now
                    </div>
                    <div className="justpadel-buttons-cancel" onClick={reset}>
                      Cancel
                    </div>
                  </ButtonToolbar>
                </div>
              </Card>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default CustomerDetails;
