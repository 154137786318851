import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../customAxios";
import { environment } from "../env";
import { Col, Button, Card, CardBody, Spinner } from "reactstrap";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import ReactTableBase from "../../shared/components/table/ReactTableBase";



const UserBookingDetailsByUserId = () => {
  const { id } = useParams();
  const history = useHistory();

  const [userDetails, setUserDetails] = useState([]);
  const [isSortable, setIsSortable] = useState(false);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const [userBookingData, setUserBookingData] = useState([]);
  const [mainCourtId, setMainCourtId] = useState('');
  const [userBookingDetails, setUserBookingDetails] = useState([]);
  const [pageCount, setpageCount] = useState(0);

  
  const getUserDetailsUrl = `${environment.baseUrl}user/${id}`;
  let data = JSON.parse(localStorage.getItem("searchdata"));
  const getBookingUrl =  `${environment.baseUrl}search-courtbooking?created_at_after=${data?.created_at_after == null ? '' : data?.created_at_after}&created_at_before=${data?.created_at_before == null ? '' : data?.created_at_before}&booking_date_after=${data?.booking_date_after == null ? '' : data?.booking_date_after}&booking_date_before=${data?.booking_date_before == null ? '' : data?.booking_date_before}&main_court=${mainCourtId == null ? '' : mainCourtId}&padel_court=${data?.padel_court == null ? '' : data?.padel_court}&booking_id=${data?.booking_id == null ? '' : data?.booking_id}&user=${userDetails?.email == null ? '' : userDetails?.email}&payment_status=${data?.payment_status == null ? '' : data?.payment_status}&page=1&page_size=10`;
  const getUserBookingUrl = `${environment.baseUrl}get_booking_user/${id}`;
  let limit = parseInt(localStorage.getItem("BookingListCurrentPageLimit"))

  const getUserBookingData = async () => {
    setSpinner(true);
    const result = await axios
      .get(getUserBookingUrl)
      .then((response) => {
        if (response) {
          setSpinner(false);
          setUserBookingData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

 
  const getUserDetails = async () => {
    const result = await axios
      .get(getUserDetailsUrl)
      .then((response) => {
        setUserDetails(response?.data);
        getUserBookingDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserBookingDetails = () => {
    const result = axios(getBookingUrl)
      .then((response) => {
        setUserBookingDetails(response?.data?.results);
        setpageCount(Math.ceil(response?.data?.count / limit));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserDetails();
    getUserBookingData();
  }, []);

  var jsonObj = [];

  const DataNotFound = [
    {
      first_name: "Data Not Found",
    },
  ];

  const DataNotFoundCourt = [
    {
      main_court: "Data Not Found",
    },
  ];

  const newdata = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Booking Date",
        accessor: "booking_date",
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Booking ID",
        accessor: "booking_id",
        disableGlobalFilter: false,
        width: 45,
      },
      {
        Header: "Time",
        accessor: "time",
        disableGlobalFilter: false,
        width: 100,
      },
      {
        Header: "Email",
        accessor: "email",
        disableGlobalFilter: false,
        width: 120,
      },
      {
        Header: "Name",
        accessor: "first_name",
        disableGlobalFilter: false,
        width: 70,
      },
      {
        Header: "Phone No",
        accessor: "phone_no",
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Main Court",
        accessor: "main_court",
        disableGlobalFilter: false,
        width: 65,
      },
      // {
      //   Header: "Padel Court",
      //   accessor: "padel_court",
      //   disableGlobalFilter: false,
      //   width: 45,
      // },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        disableGlobalFilter: true,
        width: 85,
      },
    ],
    data: jsonObj,
  };

  for (let i = 0; i < userBookingData?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["booking_date"] = userBookingData[i].booking_date.toString();
    item["booking_id"] = userBookingData[i].booking_id.toString();
    item["time"] = userBookingData[i].timeslot;
    let dd = JSON.stringify(userBookingData[i]?.booked_slots?.replace(/'/g, '"'))
    var bookedSlot = userBookingData[i]?.booked_slots?.replace(/'/g, '"');
    var NoneReplacebookedSlot = bookedSlot.replace(/None/g, 0);        
        const bookedSlotData = JSON.parse(NoneReplacebookedSlot);
        for(let j=0; j < bookedSlotData?.length; j++){
            if(bookedSlotData[j]?.main_court === bookedSlotData[j]?.main_court
              && bookedSlotData[j]?.courtName === bookedSlotData[j+1]?.courtName
              && bookedSlotData[j]?.time.split("-")[1] === bookedSlotData[j+1]?.time.split("-")[0]){
              item["time"] = ` ${bookedSlotData[0]?.time.split("-")[0]} - ${bookedSlotData[j+1]?.time.split("-")[1]}`;
            }
          } 

    item["email"] = userBookingData[i].user.email.toString();
    item["phone_no"] =
      userBookingData[i].user.phone_no === "null"
        ? ""
        : userBookingData[i].user.phone_no;
    item["payment_status"] = 
    (<div className="Booking-details-td-right-space">
        <div className="active-color-bg canceled-color-bg">
          <span className={(userBookingData[i].payment_status === "completed" && userBookingData[i].availablity === false) ? "active-color-bg" : "canceled-color-bg"}>{(userBookingData[i].availablity || userBookingData[i].cancelled) ? "Cancelled" : userBookingData[i]?.payment_status}</span><br /> 
        </div>
      </div>);
    item["first_name"] =
      userBookingData[i].user.first_name === "null"
        ? ""
        : userBookingData[i].user.first_name;
    item["main_court"] = `${userBookingData[i].main_court.courtName} - Court ${userBookingData[i].padel_court.courtname}`;
    // item["padel_court"] = "Court " + userBookingData[i].padel_court.courtname;
    jsonObj.push(item);
  }

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };

  return (
    <>
            <div className="center-spinner">
              {spinner ? <Spinner className="table-fetch-spinner" /> : ""}
            </div>
            <div className="react-table__wrapper hide-table-costomizer">
              <div className="card__title">
                <h5 className="bold-text">User Booking Details</h5>
              </div>
              <div className="pagination-hide">
                <ReactTableCustomizer
                  handleClickIsSortable={handleClickIsSortable}
                  handleClickWithPagination={handleClickWithPagination}
                  handleClickWithSearchEngine={handleClickWithSearchEngine}
                  handleClickIsResizable={handleClickIsResizable}
                  isResizable={isResizable}
                  isSortable={isSortable}
                  withPagination={withPagination}
                  withSearchEngine={withSearchEngine}
                />
              </div>
            </div>
            <div className="table-id-desabled hover-effect hide-table-costomizer">
              <ReactTableBase
                key={withSearchEngine ? "searchable" : "common"}
                columns={newdata.columns}
                data={newdata.data.length === 0 ? DataNotFound : newdata.data}
                tableConfig={tableConfig}
              />
            </div>
    </>
  );
};

export default UserBookingDetailsByUserId;
