import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import PadelholicCard from './components/PadelholicCard';

const Padelholic = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Padelholic</h3>
        <h3 className="page-subhead subhead">
          Use this elements, to Add Padelholic
        </h3>
      </Col>
    </Row>
    <Row>
      <PadelholicCard />
    </Row>
  </Container>
);
export default Padelholic;
