import React, { useState, useEffect, useReducer } from "react";
import { Row, Card, CardBody, Col, Spinner, Button } from "reactstrap";

import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import CouponModal from "../../shared/components/CouponModal";
import CouponModalEdit from "../../shared/components/CouponModalEdit";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

import moment from "moment";
import axios from "../../customAxios.js";
import { environment } from "../env";

import _ from "lodash";
import swal from "sweetalert";

const initialState = {
  pageLimits: 10,
};

function reducer(state, action) {
  switch (action.type) {
    case "pageLimits":
      return {
        ...state,
        pageLimits: parseInt(action.payload),
      };
    default:
      return {
        ...state,
      };
  }
}

const Coupons = () => {
  const [coupon, setCoupon] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [couponPageCount, setCouponPageCount] = useState(0);
  const [couponInitialPage, setCouponInitialPage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [deleteing, setDeleteing] = useState(true);
  const [editing, setEditing] = useState(true);
  const [userRole, setUserRole] = useState("admin");
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [state, dispatch] = useReducer(reducer, initialState);

  let history = useHistory();

  const GetCoupon = (pageNo, pageLimit) => {
    dispatch({ type: "pageLimits", payload: pageLimit });
    setIsLoading(true);
    const notifyMeURL = `${environment.baseUrl}get-coupon-offers/?page=${pageNo}&page_size=${pageLimit}`;
    const result = axios
      .get(notifyMeURL)
      .then((response) => {
        setCoupon(response.data.results);
        setCouponPageCount(Math.ceil(response.data.count / pageLimit));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  var jsonObj = [];

  for (let i = 0; i < coupon?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["name"] = <div className="right-space"> {coupon[i].name}</div>;
    item["title"] = <div className="right-space"> {coupon[i].title}</div>;
    item["description"] = <div className="right-space"> {coupon[i].description}</div>;

    item["percentage_of_discount"] = (
      <div className="right-space">{coupon[i].percentage_of_discount}</div>
    );
    item["coupons_alloted"] = (
      <div className="right-space">
        {coupon[i].coupons_alloted} / {coupon[i].coupons_user_limit}
      </div>
    );

    item["apply_for_addons"] = (
      <div className="right-space">
        {coupon[i].apply_for_addons ? "YES" : "NO"}
      </div>
    );
    item["coupon_type"] = (
      <div className="right-space">
        {coupon[i].coupon_type} / {coupon[i].coupon_category}
      </div>
    );
    item["coupon_remaining"] = (
      <div className="right-space">{coupon[i].coupon_remaining}</div>
    );
    const currentDate = moment(new Date(), "YYYY-MM-DD");
    const expiryDate = moment(coupon[i].expiry_date, "YYYY-MM-DD");
    item["expiry_date"] = (
      <div className="right-space">
        {coupon[i].expiry_date}
        <br />
        <div className="Booking-details-td-right-space">
          <div className="active-color-bg canceled-color-bg">
            <span
              className={
                currentDate <= expiryDate
                  ? "active-color-bg"
                  : "canceled-color-bg"
              }
            >
              {currentDate <= expiryDate ? "active" : "inactive"}
            </span>
          </div>
        </div>
      </div>
    );

    if (deleteing === true) {
      item["link"] = (
        <div className="right-space">
          <div
            className="modal-edit-btn"
            onClick={() => handleEdit(coupon[i].id)}
          >
            <AiOutlineEdit style={{ fontSize: 20 }} />
          </div>
        </div>
      );
      item["delete"] = (
        <div className="right-space">
          <div
            className="modal-delete-btn"
            onClick={() => handleDelete(coupon[i].id)}
          >
            <AiOutlineDelete style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    } else if (editing === true) {
      item["link"] = (
        <div className="right-space">
          <div
            className="modal-edit-btn"
            onClick={() => handleEdit(coupon[i].id)}
          >
            <AiOutlineEdit style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    }
    jsonObj.push(item);
  }

  const handleEdit = (id) => {
    history.push(`/edit-coupon-offers/${id}`);
  };

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Name",
        accessor: "name",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Title",
        accessor: "title",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Description",
        accessor: "description",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Percentage Of Discount",
        accessor: "percentage_of_discount",
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: "Coupons Alloted / User Limit",
        accessor: "coupons_alloted",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "For Add-ons",
        accessor: "apply_for_addons",
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: "Remaining Coupon",
        accessor: "coupon_remaining",
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: "Coupon Type / Category",
        accessor: "coupon_type",
        disableGlobalFilter: true,
        width: 90,
      },
      {
        Header: "Expiry Date / Active",
        accessor: "expiry_date",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 50,
      },
    ],
    rows: jsonObj,
  };

  const handleDelete = (id) => {
    setIsLoading(true)
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Coupon!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (userRole === "admin") {
          const deleteCouponUrl = `${environment.baseUrl}edit-coupon-offers/${id}`;
          axios
            .delete(deleteCouponUrl)
            .then((response) => {
              if (response.data.status === "success")
                swal({
                  title: "Success",
                  text: "Coupon Deleted Successfully",
                  icon: "success",
                  buttons: "Close",
                });
                setIsLoading(false)
            })
            .catch((error) => {
              setIsLoading(false)
            });
          setCoupon(() => coupon.filter((couponid) => couponid.id !== id));
        } else {
          alert("You don't have the permissions to delete data");
        }
      } else {
        swal("Your Coupon is safe!");
      }
    });
  };

  const handleAddCoupon = () => {
    history.push("/add_coupons_offer");
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10000, 20000, 30000, 40000],
    placeholder: "Search by Coupon...",
  };

  const handleCouponPageClick = (data) => {
    setIsLoading(true);
    setCouponInitialPage(data.selected);
    const notifyMeURL = `${environment.baseUrl}get-coupon-offers/?page=${
      data.selected + 1
    }&page_size=${state.pageLimits}`;
    const result = axios
      .get(notifyMeURL)
      .then((response) => {
        setCoupon(response.data.results);
        setCouponPageCount(Math.ceil(response.data.count / state.pageLimits));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleLimitChange = (e) => {
    dispatch({ type: "pageLimits", payload: e.target.value });
    setCouponInitialPage(0);
    GetCoupon(1, e.target.value);
  };

  let couponSearchValue;
  const couponSearch = (searchValue) => {
    couponSearchValue = searchValue;
    debouncedThing();
  };

  const filterCoupon = () => {
    setIsLoading(true)
    const GetCouponDetails = `${environment.baseUrl}get-coupon-offers/${couponSearchValue}/`;
    const couponResult = axios.get(GetCouponDetails).then((couponResponse) => {
      if (couponResponse.data.Message != "Coupon does not exists") {
        setCoupon([couponResponse.data]);
        setCouponPageCount(1);
        setIsLoading(false)
      } else {
        setCoupon([]);
        setCouponPageCount(0);
        setIsLoading(false)
      }
    });
  };

  const debouncedThing = _.debounce(filterCoupon, 3000);

  useEffect(() => {
    GetCoupon(1, state.pageLimits);
  }, []);

  return (
    <Row>
      <Col sm={12} md={12} lg={12}>
        <h3 className="page-title">Coupons List</h3>
      </Col>

      <Col sm={12} md={12} lg={12} className="w-100">
      {isLoading && (
        <Loader />
      )}
        <Card>
          <CardBody>
            <Row>
              <Col sm={4} md={4} lg={4}>
                <div className="form form__form-group">
                  <div className="form__form-group-field">
                    <input
                      placeholder="Search coupon here ..."
                      onChange={(e) => couponSearch(e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={4} md={4} lg={4}></Col>
              <Col sm={1} md={1} lg={1}></Col>
              <Col>
                {deleteing && (
                  <>
                    <div className="cal-top-submit-btn d-flex flex-end">
                      <Button
                        type="button"
                        color="warning"
                        onClick={() => handleAddCoupon()}
                      >
                        Add Coupon
                      </Button>
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <div className="react-table__wrapper hide-table-costomizer">
              <ReactTableCustomizer
                handleClickIsSortable={handleClickIsSortable}
                handleClickWithPagination={handleClickWithPagination}
                handleClickWithSearchEngine={handleClickWithSearchEngine}
                handleClickIsResizable={handleClickIsResizable}
                isResizable={isResizable}
                isSortable={isSortable}
                withPagination={withPagination}
                withSearchEngine={withSearchEngine}
              />
            </div>
            <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
              <ReactTableBase
                id="table-to-xls"
                key={withSearchEngine ? "searchable" : "common"}
                columns={data.columns}
                data={data.rows}
                tableConfig={tableConfig}
              />
            </div>
            <div className="custom-pagination">
              <div className="records-per-page">
                <select
                  value={state.pageLimits}
                  onChange={handleLimitChange}
                  name="limit"
                  id="select-pagination"
                >
                  {mannualLimits?.map((mannualLimits) => (
                    <option value={mannualLimits}>{mannualLimits}</option>
                  ))}
                </select>
                &nbsp;&nbsp;&nbsp;records per page
              </div>
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={couponPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handleCouponPageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={couponInitialPage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Coupons;

const Loader = () => {
  return (
    <div class="loader-div">
      <span class="loader">
        <div className="center-spinner">
          <Spinner className="table-fetch-spinner" />
        </div>
      </span>
    </div>
  );
};
