import React from "react";
import { Row, Col } from "reactstrap";
import { FaAngleDown } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import "./SalesRecord.css";
const SalesRecord = () => {
  return (
    <>
      <Row>
        <Col sm={5}>
          <div>
            <label className="booking-id">Booking ID: #75749</label>
            <div style={{ marginBottom: 5 }}>
              <span>Mina Rashid Mixed Indoor</span>
            </div>
            <div className="clientBackground">
              <label className="clientlabel">Client</label>

              <span style={{ fontSize: 17, marginTop: 5 }}>Bahaa</span>
            </div>
            <div className="clientBackground">
              <label
                 className="clientlabel"
              >
                Email
              </label>

              <span className="emailspan">
                Bahaa_Khattar@hotmail.com
              </span>
            </div>
            <div className="phonediv">
              <label className="phonelabel"
              >
                Phone#
              </label>

              <span style={{ fontSize: 17, marginTop: 5 }}>056 975 4640</span>
            </div>
          </div>
        </Col>
        <Col sm={7}>
          <Row>
            <Col
              sm={12}
              className="categorycol">
              <div className="categorydiv">
                <label className="categorylabel">
                  Category
                </label>
                <div className="courtdiv">
                  <label className="courtlabel">
                    Court Booking
                  </label>
                </div>
              </div>

              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div>
                  <label className="totalpricelabel">
                    Total Price
                  </label>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="AEDfirstdiv">
                      <label className="AEDfirstlable">
                        AED 420
                      </label>
                    </div>
                    <div className="minutesdiv">
                      <label className="minuteslabel">
                        90 M
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div>
                  <label
                   className="totalpricelabel"
                  >
                    Paid in Advance
                  </label>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                     className="AEDfirstdiv"
                    >
                      <label
                        className="AEDfirstlable"
                      >
                        AED 210
                      </label>
                    </div>
                    <div className="percentagediv" >
                      <label className="percentagelabel">
                        50%
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div>
                  <label
                    style={{
                      fontSize: 16,
                      marginTop: 3,
                      marginLeft: 10,
                    }}
                  >
                    To pay at the facility
                  </label>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                   className="AEDfirstdiv"
                    >
                      <label
                       className="AEDfirstlable"
                      >
                        AED 210
                      </label>
                    </div>
                    <div
                     className="percentagediv"
                    >
                      <label
                      className="percentagelabel"
                      >
                        50%
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          margin: 12,
          backgroundColor: "#f5f5f5",
          borderRadius: 5,
        }}
      >
        <Col sm={4}>
          <div
            style={{
              paddingTop: 30,
              paddingLeft: 30,
            }}
          >
            <span>Payment Method</span>
          </div>
          <div style={{ paddingTop: 20, paddingLeft: 25 }}>
            <span>To be paid at the facility</span>
          </div>
          <div style={{ paddingTop: 15, paddingLeft: 25, paddingBottom: 17 }}>
            <span>Addons</span>
          </div>
        </Col>
        <Col sm={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "gray",
              borderRadius: 4,
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 15,
              width: 270,
              marginLeft: 16,
              height: "19%",
            }}
          >
            <div style={{ marginTop: 3 }}>
              <span style={{ color: "white" }}>Card</span>
            </div>
            <div style={{ marginTop: 2 }}>
              <span>
                <FaAngleDown size={25} />
              </span>
            </div>
          </div>
          <Row>
            <Col
              style={{
                gap: 20,
                marginTop: 25,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: 5,
                  width: 270,
                  marginBottom: 8,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 45,
                    borderRadius: 3,
                    backgroundColor: "gray",
                    padding: "5px 18px 5px 18px",
                  }}
                >
                  <div>
                    <span style={{ color: "white" }}>
                      <FaMinus size={12} />
                    </span>
                  </div>
                  <div>
                    <span style={{ color: "white" }}>AED 210</span>
                  </div>
                  <span style={{ color: "white" }}>
                    <FaPlus size={12} />
                  </span>
                </div>
                <div
                  style={{
                    justifyContent: "space-between",
                    backgroundColor: "gray",
                    borderRadius: 4,
                    padding: "5px 12px 2px 12px",
                  }}
                >
                  <div>
                    <span style={{ color: "white", fontWeight: "bold" }}>
                      90 M
                    </span>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: 5,
                  width: 272,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 45,
                    borderRadius: 3,
                    backgroundColor: "gray",
                    padding: "5px 18px 5px 18px",
                  }}
                >
                  <div>
                    <span style={{ color: "white" }}>
                      <FaMinus size={12} />
                    </span>
                  </div>
                  <div>
                    <span style={{ color: "white" }}>AED 210</span>
                  </div>
                  <span style={{ color: "white" }}>
                    <FaPlus size={12} />
                  </span>
                </div>
                <div
                  style={{
                    justifyContent: "space-between",
                    backgroundColor: "gray",
                    borderRadius: 4,
                    padding: "5px 25px 5px 25px",
                  }}
                >
                  <div>
                    <span style={{ color: "white", fontWeight: "bold" }}>
                      2
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: 5,
                width: 240,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 24,
                  padding: "10px 43px 0px 43px",
                  borderRadius: 3,
                  backgroundColor: "gray",
                  height: 40,
                }}
              >
                <div>
                  <span style={{ color: "white", fontSize: 16 }}>Total</span>
                </div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid white",
                    height: 22,
                  }}
                ></div>
                <span style={{ color: "white", marginLeft: 8 }}>AED 273</span>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: 5,
                width: 240,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 35,
                  borderRadius: 3,
                  backgroundColor: "#4386C1",
                  height: 38,
                  alignItems: "center",
                  padding: "4px 60px 0px 60px",
                }}
              >
                <div>
                  <span
                    style={{
                      color: "white",
                      padding: "0px 28px 0px 28px",
                    }}
                  >
                    Add Note
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: 5,
                width: 240,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 35,
                  padding: "8px 62px 0px 62px",
                  borderRadius: 3,
                  backgroundColor: "#EAB70C",
                  height: 35,
                }}
              >
                <div>
                  <span
                    style={{
                      color: "white",
                      paddingLeft: 32,
                      paddingRight: 32,
                    }}
                  >
                    Submit
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={5}>
          <div>
            <label style={{ fontSize: 17, fontWeight: "Regular" }}>
              Booking ID: #45749
            </label>
            <div style={{ marginBottom: 5 }}>
              <span>Mina Rashid Mixed Indoor</span>
            </div>
            <div
              style={{
                fontSize: 17,
                display: "flex",
                gap: 45,
                backgroundColor: "#f5f5f5",
                borderRadius: 4,
                marginBottom: 12,
                width: "100%",
              }}
            >
              <label
                style={{
                  fontSize: 17,
                  fontWeight: "Bold",
                  marginTop: 5,
                  marginLeft: 10,
                }}
              >
                Client
              </label>

              <span style={{ fontSize: 17, marginTop: 5 }}>Bahaa</span>
            </div>
            <div
              style={{
                fontSize: 17,
                display: "flex",
                gap: 45,
                backgroundColor: "#f5f5f5",
                borderRadius: 4,
                marginBottom: 12,
                width: "100%",
              }}
            >
              <label
                style={{
                  fontSize: 17,
                  fontWeight: "Bold",
                  marginTop: 5,
                  marginLeft: 10,
                }}
              >
                Email
              </label>

              <span style={{ fontSize: 17, marginTop: 5, marginRight: 20 }}>
                Bahaa_Khattar@hotmail.com
              </span>
            </div>
            <div
              style={{
                fontSize: 17,
                display: "flex",
                gap: 34,
                backgroundColor: "#f5f5f5",
                borderRadius: 4,
                width: "100%",
              }}
            >
              <label
                style={{
                  fontSize: 17,
                  fontWeight: "Bold",
                  marginTop: 5,
                  marginLeft: 10,
                }}
              >
                Phone#
              </label>

              <span style={{ fontSize: 17, marginTop: 5 }}>056 975 4640</span>
            </div>
          </div>
        </Col>
        <Col sm={7}>
          <Row>
            <Col
              sm={12}
              style={{
                marginTop: 10,
                borderRadius: 8,
                padding: 10,
                backgroundColor: "#f5f5f5",
                height: "19%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginBottom: 12,
                  height: "16%",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    fontSize: 15,
                    marginTop: 4,
                    marginLeft: 10,
                  }}
                >
                  Category
                </label>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#f58133",
                    borderRadius: 4,
                    height: "30%",
                    marginLeft: 10,
                  }}
                >
                  <label
                    style={{
                      fontSize: 15,
                      marginLeft: 60,
                      marginRight: 60,
                      marginTop: 5,
                      color: "white",
                    }}
                  >
                    Coaching - just Padel
                  </label>
                </div>
              </div>

              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div>
                  <label
                    style={{
                      fontSize: 15,
                      marginTop: 5,
                      marginLeft: 10,
                    }}
                  >
                    Total Price
                  </label>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#bfc2c7",
                        borderRadius: 4,
                        marginTop: 6,
                        height: "70%",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 14,
                          padding: "4px 70px 0px 70px",
                        }}
                      >
                        AED 330
                      </label>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#484f59",
                        borderRadius: 4,
                        marginTop: 6,
                        height: "67%",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 16,
                          color: "white",
                          padding: "3px 10px 0px 10px",
                        }}
                      >
                        90 M
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div>
                  <label
                    style={{
                      fontSize: 16,
                      marginTop: 5,
                      marginLeft: 10,
                    }}
                  >
                    Paid Online
                  </label>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#bfc2c7",
                        borderRadius: 4,
                        marginTop: 6,
                        height: "70%",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 14,
                          padding: "4px 78px 0px 78px",
                        }}
                      >
                        AED 0
                      </label>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#484f59",
                        borderRadius: 4,
                        marginTop: 6,
                        height: "70%",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 16,
                          color: "white",
                          padding: "3px 17px 0px 17px",
                        }}
                      >
                        0%
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div>
                  <label
                    style={{
                      fontSize: 16,
                      marginTop: 3,
                      marginLeft: 10,
                    }}
                  >
                    To pay at the facility
                  </label>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#bfc2c7",
                        borderRadius: 4,
                        marginTop: 6,
                        height: "70%",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 14,
                          padding: "4px 70px 0px 70px",
                        }}
                      >
                        AED 330
                      </label>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#484f59",
                        borderRadius: 4,
                        marginTop: 6,
                        height: "70%",
                      }}
                    >
                      <label
                        style={{
                          fontSize: 16,
                          color: "white",
                          padding: "3px 12px 0px 12px",
                        }}
                      >
                        50%
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        style={{
          margin: 12,
          backgroundColor: "#f5f5f5",
          borderRadius: 5,
        }}
      >
        <Col sm={4}>
          <div
            style={{
              paddingTop: 30,
              paddingLeft: 30,
            }}
          >
            <span>Payment Method</span>
          </div>
          <div style={{ paddingTop: 15, paddingLeft: 25 }}>
            <span>To be paid at the facility</span>
          </div>
          <div style={{ paddingTop: 10, paddingLeft: 25, paddingBottom: 10 }}>
            <span>Caoch</span>
          </div>
          <div style={{ paddingTop: 5, paddingLeft: 25, paddingBottom: 17 }}>
            <span>Session Type</span>
          </div>
        </Col>
        <Col sm={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "gray",
              borderRadius: 4,
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 20,
              width: 270,
              marginLeft: 16,
              height: "15%",
            }}
          >
            <div style={{ marginTop: 4 }}>
              <span style={{ color: "white" }}>Card</span>
            </div>
            <div style={{ marginTop: 3 }}>
              <span>
                <FaAngleDown size={25} />
              </span>
            </div>
          </div>
          <Row>
            <Col
              style={{
                gap: 20,
                marginTop: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: 5,
                  width: 270,
                  marginBottom: 8,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 45,
                    borderRadius: 3,
                    backgroundColor: "gray",
                    padding: "5px 18px 5px 18px",
                  }}
                >
                  <div>
                    <span style={{ color: "white" }}>
                      <FaMinus size={12} />
                    </span>
                  </div>
                  <div>
                    <span style={{ color: "white" }}>AED 330</span>
                  </div>
                  <span style={{ color: "white" }}>
                    <FaPlus size={12} />
                  </span>
                </div>
                <div
                  style={{
                    justifyContent: "space-between",
                    backgroundColor: "gray",
                    borderRadius: 4,
                    padding: "5px 12px 2px 12px",
                  }}
                >
                  <div>
                    <span style={{ color: "white", fontWeight: "bold" }}>
                      90 M
                    </span>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "gray",
                  borderRadius: 4,
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 10,
                  width: 270,
                  height: "25%",
                }}
              >
                <div style={{ marginTop: 3 }}>
                  <span style={{ color: "white" }}>Alex</span>
                </div>
                <div style={{ marginTop: 2 }}>
                  <span>
                    <FaAngleDown size={25} />
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "gray",
                  borderRadius: 4,
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginTop: 6,
                  width: 270,
                  height: "25%",
                }}
              >
                <div style={{ marginTop: 3 }}>
                  <span style={{ color: "white" }}>Private</span>
                </div>
                <div style={{ marginTop: 2 }}>
                  <span>
                    <FaAngleDown size={25} />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: 5,
                width: 240,
                paddingTop: 18,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 24,
                  padding: "10px 43px 0px 43px",
                  borderRadius: 3,
                  backgroundColor: "gray",
                  height: 40,
                }}
              >
                <div>
                  <span style={{ color: "white", fontSize: 16 }}>Total</span>
                </div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid white",
                    height: 22,
                  }}
                ></div>
                <span style={{ color: "white", marginLeft: 8 }}>AED 330</span>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: 5,
                width: 240,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 35,
                  borderRadius: 3,
                  backgroundColor: "#4386C1",
                  height: 38,
                  alignItems: "center",
                  padding: "4px 60px 0px 60px",
                }}
              >
                <div>
                  <span
                    style={{
                      color: "white",
                      padding: "0px 28px 0px 28px",
                    }}
                  >
                    Add Note
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderRadius: 5,
                width: 240,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 35,
                  padding: "8px 62px 0px 62px",
                  borderRadius: 3,
                  backgroundColor: "#EAB70C",
                  height: 35,
                }}
              >
                <div>
                  <span
                    style={{
                      color: "white",
                      paddingLeft: 32,
                      paddingRight: 32,
                    }}
                  >
                    Submit
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SalesRecord;
