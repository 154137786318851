import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import axios from "../../customAxios";
import { environment } from "../env";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import swal from "sweetalert";
import AddCategories from "./AddCategories";
import EditCategories from "./EditCategories";


const CategoriesList = () => {

    const [categories, setCategories] = useState([]);
    const [isSortable, setIsSortable] = useState(true);
    const [isResizable, setIsResizable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);

    const GetMatchCategories = () => {
        const categoriesUrl = `${environment.baseUrl}match-categories/`;
        axios.get(categoriesUrl).then((response) => {
            setCategories(response?.data);
        });
    };

    var MatchCategoriesObj = [];

    for (let i = 0; i < categories.length; i++) {
        var item = {};
        item["id"] = i + 1;
        item["category"] = categories[i]?.matchcategory;
        item["edit"] = (
            <div className="right-space">
                <div>
                    <EditCategories
                        color="warning"
                        title="Edit Category"
                        header
                        categoryId={categories[i]?.id}
                        GetMatchCategories={GetMatchCategories}
                    />
                </div>
            </div>
        );
        item["delete"] = (
            <div className="right-space">
                <div
                    className="modal-delete-btn"
                    onClick={() => handleDelete(categories[i]?.id)}
                >
                    <DeleteOutlineIcon style={{ fontSize: 20 }} />
                </div>
            </div>
        );

        MatchCategoriesObj.push(item);
    }

    const data = {
        columns: [
            {
                Header: "#",
                accessor: "id",
                disableGlobalFilter: true,
                width: 65,
            },
            {
                Header: "Category",
                accessor: "category",
                disableGlobalFilter: false,
                width: 150,
            },
            {
                Header: "Edit",
                accessor: "edit",
                disableGlobalFilter: true,
                width: 20,
            },
            {
                Header: "Delete",
                accessor: "delete",
                disableGlobalFilter: true,
                width: 20,
            },
        ],
        rows: MatchCategoriesObj,
    };

    useEffect(() => {
        GetMatchCategories();
    }, []);

    const handleDelete = (id) => {

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Match Category!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {

                const GetCategoryDeleteUrl = `${environment.baseUrl}match-categories/${id}/`;
                axios.delete(GetCategoryDeleteUrl).then((response) => {
                    if (response.data.status === "success") {
                        swal({
                            title: "Success",
                            text: "Category is deleted successfully!",
                            icon: "success",
                            button: "Ok",
                        })
                        setCategories(() => categories.filter((category) => category.id !== id));
                    } else if (response.data.message === "error while deleting match category") {
                        swal({
                            title: "Error",
                            text: "error while deleting category!",
                            icon: "error",
                            button: "Ok",
                        })
                    }
                    else {

                    }
                });

            } else {
                swal("Your Match Category is safe!");
            }
        });
    };

    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };

    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };

    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const tableConfig = {
        isEditable: false,
        isResizable,
        isSortable,
        withPagination,
        withSearchEngine,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search by Category Name...",
    };

    return (
        <Container>
            <Row>
                <Col sm={6} md={6} lg={6}>
                    <h3 className="page-title">Categories List</h3>
                </Col>
                <Col sm={3} md={3} lg={3}></Col>
                <Col sm={3} md={3} lg={3}>
                    <AddCategories
                        btn="Add Categories"
                        color="warning"
                        title="Add Categories"
                        header
                        GetMatchCategories={GetMatchCategories}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12}>
                    <Card>
                        <CardBody>
                            <div className="react-table__wrapper hide-table-costomizer">
                                <ReactTableCustomizer
                                    handleClickIsSortable={handleClickIsSortable}
                                    handleClickWithPagination={handleClickWithPagination}
                                    handleClickWithSearchEngine={handleClickWithSearchEngine}
                                    handleClickIsResizable={handleClickIsResizable}
                                    isResizable={isResizable}
                                    isSortable={isSortable}
                                    withPagination={withPagination}
                                    withSearchEngine={withSearchEngine}
                                />
                            </div>
                            <div className="hover-effect table-id-width">
                                {categories && categories ? (
                                    <ReactTableBase
                                        key={withSearchEngine ? "searchable" : "common"}
                                        columns={data.columns}
                                        data={data.rows}
                                        tableConfig={tableConfig}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default CategoriesList