/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-eval */
/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-spacing */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Col, Button, Card, CardBody, Row,
} from 'reactstrap';
import axios from '../../../customAxios';
import { useHistory } from 'react-router-dom';
import { environment } from '../../env';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { AiOutlineDelete } from "react-icons/ai";
import './AddonsBook.css'


const AddonBook = (props) => {
  const inititalUserSelection = {
    addonSelection: [
      {id: 1, price: '', qty: 0, isChecked: false},
      {id: 2, price: '', qty: 0, isChecked: false},
      {id: 3, price: '', qty: 0, isChecked: false},
    ],
    vat: '1.05',
    coupon: '',
  };

  const [state, setstate] = useState([]);
  const [addonList, setAddonList] = useState([]);
  const [amount, setAmount] = useState(250);
  const [finalAmount, setFinalAmount] = useState();
  const [userSelection, setUserSelection] = useState(inititalUserSelection);
  const [reloadState, setReloadState] = useState(false);
  const [userCartDetails, setUserCartDetails] = useState([
    {
			"court": "",
		}
  ]);
  const [totalAmount, setTotalAmount] =useState(0);
  const [couponCode, setCouponCode] = useState('');
  const [couponCodeValidationMsg, setCouponCodeValidationMsg] = useState('');
  const [couponValue, setCouponValue] = useState(0);
  const [discountPrice, setdiscountPrice] = useState();
  const [totalAmountWithoutVAT, setTotalAmountWithoutVAT] = useState();
  const [vatAmount, setVatAmount] = useState();
  const [applyForAddon, setApplyForAddon] = useState(false);
  
  const addonListUrl = `${environment.baseUrl}addon`;
  const history = useHistory();
  const getAddons = async () => {
    const result = await axios(addonListUrl);
    setAddonList(result.data);
    return result.data;
  };

  // function calcTotalAmount(initialPrice, selectionFinal) {
  //   const selections = selectionFinal ? selectionFinal : userSelection;
  //   setUserSelection(selections);
  //   let total = initialPrice? initialPrice :  totalAmount;
  //   // addon calculations.
  //   console.log("totalAmount - 1",totalAmount);
  //   selections.addonSelection.forEach((addon) => {
  //     total += (addon.price * addon.qty);
  //   });
  //   // coupon discount.
  //   // console.log("Total Amount",total);
  //   // console.log("VAT Applied",((total/100)*5));

  //   setTotalAmountWithoutVAT(total);
  //   setVatAmount(((total/100)*5));
  //   total -= (total * (selections.coupon));
  //   // vat calc
  //   total *= parseFloat(selections.vat).toFixed(2);

  //   setFinalAmount(total.toFixed(2));
  //   setReloadState(!reloadState);
  //   // setdiscountPrice(((total / 100) * couponValue*100).toFixed(2));
  //   // console.log("Discounted Amount",((total / 100) * couponValue*100).toFixed(2));
  //   setReloadState(!reloadState);
  // }

  function calcTotalAmount(initialPrice, selectionFinal) {
    const selections = selectionFinal ? selectionFinal : userSelection;
    setUserSelection(selections);
    let total = initialPrice? initialPrice :  totalAmount;
    //---------------------------------NEW CALCULATION------------------------------
    let newTotalAmount = initialPrice? initialPrice :  totalAmount;
      //-------------------------For Addons---------------------
      let newAddonTotal = 0;
      selections.addonSelection.forEach((addon) => {newAddonTotal += (addon.price * addon.qty);});
  
      if(applyForAddon){
        let courtAddonPrice = parseFloat(newTotalAmount+newAddonTotal);
        let discountedPrice = parseFloat((courtAddonPrice)*selections.coupon);
        setdiscountPrice(Math.ceil(discountedPrice));
        let totlaAmountsWithoutVat = parseFloat(courtAddonPrice-discountedPrice);
        setTotalAmountWithoutVAT(Math.ceil(courtAddonPrice));
        let newVat = parseFloat(totlaAmountsWithoutVat*0.05);
        setVatAmount(Math.ceil(newVat));
        let newFinalAmount = parseFloat(totlaAmountsWithoutVat+newVat);
        setFinalAmount(Math.ceil(newFinalAmount));
      }else{
        let courtAddonPriceFalse = parseFloat(newTotalAmount+newAddonTotal);
        let discountedPrice = parseFloat((newTotalAmount)*selections.coupon);
        setdiscountPrice(Math.ceil(discountedPrice));
        let totlaAmountsWithoutVat = parseFloat((newTotalAmount-discountedPrice)+newAddonTotal);
        setTotalAmountWithoutVAT(Math.ceil(totlaAmountsWithoutVat));
        let newVat = parseFloat(totlaAmountsWithoutVat*0.05);
        setVatAmount(Math.ceil(newVat));
        let newFinalAmount = parseFloat(totlaAmountsWithoutVat+newVat);
        setFinalAmount(Math.ceil(newFinalAmount));
      }

    //---------------------------------NEW CALCULATION------------------------------
    // addon calculations.
    // selections.addonSelection.forEach((addon) => {
    //   total += (addon.price * addon.qty);
    // });
    // coupon discount.
    // console.log("Total Amount",total);
    // console.log("VAT Applied",((total/100)*5));

    // setTotalAmountWithoutVAT(total);
    // setVatAmount(((total/100)*5));
    // total -= (total * (selections.coupon));
    // vat calc
    // total *= parseFloat(selections.vat).toFixed(2);

    // setFinalAmount(total.toFixed(2));
    setReloadState(!reloadState);
    // setdiscountPrice(((total / 100) * couponValue*100).toFixed(2));
    // console.log("Discounted Amount",((total / 100) * couponValue*100).toFixed(2));
    setReloadState(!reloadState);
  }

  /* eslint-disable no-param-reassign */
  function handleCheck(id) {
    const addonCopy = userSelection;
    addonCopy.addonSelection.forEach((addon) => {
      if (addon.id === id) {
        addon.isChecked = !addon.isChecked;
        addon.qty = addon.isChecked ? 1 : 0;
      }
    });
    setUserSelection(addonCopy);
    calcTotalAmount();
  }

  function handlePlus(id) {
    const addonCopy = userSelection;
    addonCopy.addonSelection.forEach((addon) => {
      if (addon.id === id && addon.isChecked) {
        addon.qty++;
      }
    });
    setUserSelection(addonCopy);
    calcTotalAmount();
  }

  function handleMinus(id) {
    const addonCopy = userSelection;
    addonCopy.addonSelection.forEach((addon) => {
      if (addon.id === id && addon.isChecked) {
        addon.qty--;
      }
    });
    setUserSelection(addonCopy);
    calcTotalAmount();
  }

  function callCouponValidate(cCode, userSelectionParam) {
    try {
      const userId = localStorage.getItem('UserId');
      if (cCode !== '') {
        const validateCouponUrl = `${environment.baseUrl}validate_coupon`;
        let userCartCourt = localStorage.getItem("userCourtCartList")
        const data = {
          name: cCode,
          slot: parseInt(localStorage.getItem("slotLength")),
          user: userId,
          bookedUsing:"admin",
          courtlists:userCartCourt.split(","),

        }
        const result =  axios.post(validateCouponUrl, data)
          .then((response) => {
            setApplyForAddon(response.data.apply_for_addons);
            if(response?.data?.percentage_of_discount) {
              setUserSelection({...userSelectionParam, coupon:(response?.data?.percentage_of_discount /100)});              
            } else {
              setCouponCodeValidationMsg(response?.data?.msg);
            }            
          },
          (err) => {
            setCouponCodeValidationMsg("Coupon entered is not valid.");
          })
             
      }
    } catch (error) {
      setCouponCodeValidationMsg("Error processing your Request.");
    }
  }

  function handleCoupanCodeChange(event) {
    setCouponCode(event.target.value);
    debounce(event.target.value, userSelection);
  };

  const debounce = useCallback(
    _.debounce((_searchVal, userSelectionParam) => {
      callCouponValidate(_searchVal, userSelectionParam);

      // send the server request here		
    }, 500),
    []
  );

  const applyCouponCode = async () => {
    debounce(couponCode, userSelection);
    if(userSelection.coupon) {
      setCouponValue(userSelection?.coupon);
      setCouponCodeValidationMsg("Discount applied successfully!!");
      calcTotalAmount();     
    }
  }
  const removeCouponCode = async () => {
    let userSelectionCopy = userSelection;
    userSelectionCopy.coupon = 0;
    setUserSelection(userSelectionCopy);
    setCouponValue(0);
    setCouponCode("");
    setCouponCodeValidationMsg("");
    calcTotalAmount();
  }

  const getUserCartDetails = async () => {
    const userId =  localStorage.getItem('UserId');
    const userCartUrl = `${environment.baseUrl}get_cart_user/${userId}`;
    console.log(userCartUrl);
    const result = await axios(userCartUrl)
      .then((response) => {
        const cartTimeslots = JSON.parse(response?.data?.data[0].timeslot.replace(/'/g, '"'));
        console.log("Response of Data", cartTimeslots);
        let userCourtCartList = [];
        cartTimeslots.map((data)=>{
          userCourtCartList.push(parseInt(data.maincourt))
        });
        let uniqueUserCourtCartList = [...new Set(userCourtCartList)];
        localStorage.setItem("userCourtCartList", uniqueUserCourtCartList)
        localStorage.setItem("slotLength",cartTimeslots.length);
        if(cartTimeslots.length > 0) {
          const totalAmount = cartTimeslots.reduce((a, b) => ({ price: a.price + b.price }));
          setTotalAmount(totalAmount.price);
          setUserCartDetails(cartTimeslots);
          setReloadState(!reloadState);
          setUserSectionFromPreviousPage(totalAmount.price);
        }
      });
  };

  const setUserSectionFromPreviousPage = async (initialPrice) => {
    const modifiedAddon = [];
    let previousPageSelection = '';
    let addonsFromDb = '';
    const tempUserSelection = userSelection.addonSelection;
    previousPageSelection = JSON.parse(localStorage.getItem('selectionDetails'));
    await getAddons().then((value) => {
      addonsFromDb = value;
    });
    addonsFromDb.forEach((apiAddon) => {
      modifiedAddon.push({
        id: apiAddon.id,
        price: apiAddon.price,
        qty: 0,
        addonname: apiAddon.addonname,
        description: apiAddon.description,
        isChecked: false,
      });
    });
    const selectionFinal = {
      courtPrice: initialPrice,
      addonSelection: modifiedAddon,
      vat: userSelection.vat,
      coupon: userSelection.coupon,
    };
    setUserSelection(selectionFinal);
    setReloadState(!reloadState);
    calcTotalAmount(initialPrice, selectionFinal);
  };


  const onDeleteCourt = (mId, pdId, ts, dt, ct) => {
    let existingCartDetails = userCartDetails;
    const userId =  localStorage.getItem('UserId');
    if (ct !== "child") {
      existingCartDetails = existingCartDetails.filter((s)  =>{
        return s.maincourt !== mId;
      }
      )
    } else {
    existingCartDetails = existingCartDetails.filter((s) => {
        return !(s.maincourt == mId && s.court == pdId && s.timeslot == ts && s.booking_date == dt);
    });
  }
    setUserCartDetails(existingCartDetails);
    localStorage.setItem("slotLength",existingCartDetails.length);
      try {
        const deleteCartPayload = {
          "main_court_id": mId,
          "court_id": pdId,
          "court_name": pdId,
          "timeslot": ts,
          "date": dt,
          "price": "0",
          "selected_slot": JSON.stringify(existingCartDetails),
          "user": userId
        }
        const addToCartUrl =  `${environment.baseUrl}addtocart`;
        const result = axios.post(addToCartUrl, deleteCartPayload)
          .then((response) => {
            // console.log("response: ", response);
            alert("Selected court deleted!");
            if(existingCartDetails.length !== 0) {
              // setTotalAmount(existingCartDetails.reduce((a, b) => ({ price: a?.price  + b?.price })));
              // console.log("totalAmount - 2",totalAmount);
              let pprice = existingCartDetails.reduce((a, b) => ({ price: a?.price  + b?.price }))
              // console.log("totalAmount - 3",pprice.price);
              setTotalAmount(pprice.price);
            } else {
              setTotalAmount(0);
            }
            setReloadState(!reloadState);
          }, (error) => {
            console.log("Promise error: ", error);
          })
      } catch (error) {
        console.log("add to cart error: ", error);
      }
    };

  useEffect(() => {
    getUserCartDetails();
  }, []);

  useEffect(() => {
    setTotalAmount(totalAmount);
    calcTotalAmount();
  }, [totalAmount])

  

  const addToCartApiPayload = () => {
    const userId =  localStorage.getItem('UserId');
    const payload = {
      "court_id": userCartDetails[0].court,
      "date": userCartDetails[0].booking_date,
      "price": finalAmount,
      "selected_slot": JSON.stringify([]), //emptying cart.
      "user": userId
    }
    let addOns = []
    userSelection.addonSelection?.map((item) => {
      if (item.isChecked) {
        addOns.push(`${item.id}-${item.qty}`)
      }
    })
    if (addOns?.length > 0) {
      payload['addon'] = addOns.join(',')
    }
    // console.log("===============================================================================")
    // console.log("cart payload", payload);
    localStorage.setItem("cart_payload", JSON.stringify(payload));
    // console.log("===============================================================================")
    // storebooking cart payload.
    bookCourt();
  }

  const bookCourt = () => {
    const userId =  localStorage.getItem('UserId');
    console.log("userCartDetails : ", userCartDetails)
    let amountPaid;
    if(finalAmount === "0.00"){
      amountPaid = 0;
      // console.log("Amount Paid 1", amountPaid);
    } else {
      amountPaid = finalAmount;
      // console.log("Amount Paid 2", amountPaid);
    }
    const payload = {
      "slots": userCartDetails,
      "user": userId,
      "coupon_code": couponCode,
      "amount_paid": amountPaid,
      "payment_status":"completed",
    }
    let addOns = []
    userSelection.addonSelection?.map((item) => {
      if (item.isChecked) {
        addOns.push(`${item.id}-${item.qty}`)
      }
    })
    if (addOns?.length > 0) {
      payload['addon'] = addOns.join(',')
    }
    // console.log("Booking payload", payload);
    localStorage.setItem("booking_payload", JSON.stringify(payload));
  }
  const handleCustomerDetailsPage = () => {
    addToCartApiPayload();
    history.push('/customer_details');
  };
  const handleBackButton = () => {
    history.push('/courtbooking');
  };

  return (
    <Col md={12} lg={12}>
      <Card>
      {/* ======================== */}
      <CardBody>
          {/* <Row>
            <Col md={12} lg={12} style={{ display:'flex',justifyContent:'center',alignItems:'flex-start'}}>
            <Col md={6} lg={6}>
              <Card>
                <div>
                  <h5 style={{ fontFamily: 'Helvetica, sans-serif', fontSize: 18, color: '#080A9F' }}> Just Padel - My Cart</h5>
                </div>
                <CardBody style={{border:'1px solid #ccc', marginTop: 10}}>
                  { userCartDetails[0]?.court ?
                    userCartDetails && userCartDetails.map((addToCartPayload) => (
                    <div className="addon">
                      <div className="addon-details">
                        <h6 style={{fontFamily:"Verdana, sans-serif", fontSize:15 }}>{addToCartPayload?.maincourt_name} - Court {addToCartPayload?.court}</h6>
                        <span style={{fontSize: '10px', fontFamily:"Verdana, sans-serif",}}> {addToCartPayload?.booking_date}</span>
                        <span style={{fontSize: '10px', fontFamily:"Verdana, sans-serif",}}> {addToCartPayload?.timeslot}</span>
                        <span style={{fontSize: '10px', fontFamily:"Verdana, sans-serif",}}> {`AED ${addToCartPayload?.price}`}</span>
                      </div>
                      <div className="addon-qty">
                         <button 
                         class="cart__table-btn" 
                         type="button"  
                         onClick={() => onDeleteCourt(addToCartPayload?.maincourt, addToCartPayload?.court, addToCartPayload?.timeslot, addToCartPayload?.booking_date)}
                         style={{backgroundColor:'#FF4545',borderWidth:0,color:'#fff',padding:5,borderRadius:5,fontFamily:'sans-serif',alignItems:'center',justifyContent:'center',display:'flex'}}>
                         <svg class="mdi-icon " width="24" height="24" fill="#FFF" 
                         viewBox="0 0 24 24"><path d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8.46,11.88L9.87,10.47L12,12.59L14.12
                         ,10.47L15.53,11.88L13.41,14L15.53,16.12L14.12,17.53L12,15.41L9.88,17.53L8.47,16.12L10.59,14L8.46,11.88M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z">
                         </path></svg> <span style={{marginTop:3,paddingRight:5,}}>Remove</span> 
                         </button>
                      </div>
                    </div>
                  ))
                  :
                  <div className="addon">
                    <div className="addon-details">
                      <h6 style={{ fontFamily: "Verdana, sans-serif", fontSize: 15 }}>No court added to cart.</h6>
                    </div>
                  </div>
                }
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={6} >
              <Card style={{display:'flex', justifyContent:'flex-start',alignItems:'flex-start'}}>
                <div>
                  <h5 style={{ fontFamily: 'Helvetica, sans-serif', fontSize: 18, color:'#080A9F' }}>Apply Coupon Code</h5>
                </div>
                    <form className="form product-edit" encType="multipart/form-data">
                      <div className="form__halfs">
                        <div className="form__form-group" >
                          <div className="flex-fields" style={{display:"flex", flexDirection:"column", marginTop:10}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:'space-between'}}> 
                              <input type="text" placeholder="Coupan Code" value={couponCode} onChange={handleCoupanCodeChange}  style={{margin: '1px 0px',borderRadius:5,border:"1px solid #c5c2c2",width:'24vw' }} />
                              
                              {!couponValue ? <Button
                                color="black"
                                size={45}
                                style={{borderRadius:5,border:"1px solid #c5c2c2",width:'8vw',padding:6,marginLeft:5}}
                                mode="contained"
                                uppercase={false}
                                color="#4b4b4b"
                                onClick={applyCouponCode}
                              >
                                Apply
                              </Button>
                                : <Button
                                  color="black"
                                  size={45}
                                  style={{borderRadius:5,border:"1px solid #c5c2c2",width:'8vw',padding:6,marginLeft:5}}
                                  mode="contained"
                                  uppercase={false}
                                  color="#4b4b4b"
                                  onClick={removeCouponCode}
                                >
                                  Remove
                                </Button>
                              }                              
                             </div>
                             <div>
                             { couponCodeValidationMsg ?
                                <div className="form__form-group-label" style={{fontSize:15, }}>{couponCode} - {couponCodeValidationMsg}</div>
                              : ""}
                             </div>
                          </div>
                        </div>
                      </div>
                    </form>
              </Card>
              <Card>
                <div>
                  <h5 style={{ fontFamily: 'Helvetica, sans-serif', fontSize: 18, color: '#080A9F',marginTop:20 }}>Total Amount</h5>
                </div>
                <CardBody style={{display:"flex",flexDirection:"column", padding:'10px 0',border:'1px solid #ccc',borderRadius:7,marginTop:15,justifyContent:'center' }}>
                  <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:'space-between',padding:'5px 25px'}}>
                    <h5 style={{ fontFamily:"Verdana, sans-serif"}}>Amount </h5>
                    <h5 style={{fontFamily:"Verdana, sans-serif"}}  className="dashboard__total-stat timeline__title">AED {totalAmountWithoutVAT}</h5>
                  </div>
                  <div style={{display:"flex", flexDirection:"row",alignItems:"center",justifyContent:'space-between',padding:'5px 25px'}}>
                    <h5 style={{fontFamily:"Verdana, sans-serif"}}>VAT 5%</h5>
                    <h5 style={{ fontFamily:"Verdana, sans-serif"}} className="dashboard__total-stat timeline__title">AED {vatAmount}</h5>
                  </div>
                  <div style={{display:"flex", flexDirection:"row",alignItems:"center",justifyContent:'space-between',padding:'5px 25px'}}>
                    <h5 style={{fontFamily:"Verdana, sans-serif"}}>Total Amount</h5>
                    <h5 style={{ fontFamily:"Verdana, sans-serif"}} className="dashboard__total-stat timeline__title">AED {finalAmount}</h5>
                  </div>
                  <div style={{display:"flex", flexDirection:"row",alignItems:"center",justifyContent:'space-between',padding:'5px 25px'}}>
                    <h5 style={{fontFamily:"Verdana, sans-serif"}}>discounted price</h5>
                    <h5 style={{ fontFamily:"Verdana, sans-serif"}} className="dashboard__total-stat timeline__title">AED {(totalAmountWithoutVAT+vatAmount-finalAmount).toFixed(2)}</h5>
                 </div>
                </CardBody>
              </Card>
            </Col> 
            </Col>

            <Col md={12} lg={12} style={{ display:'flex',justifyContent:'center'}}>
            <Col md={6} lg={6}>
              <Card>
                <div>
                  <h5 style={{ fontFamily: 'Helvetica, sans-serif', fontSize: 18, color: '#080A9F',marginTop:20 }}>Add On's</h5>
                </div>
                <CardBody style={{border:'1px solid #ccc', marginTop: 10}}>
                  { 
                  userSelection && userSelection.addonSelection.map((data) => (
                    <div className="addon">
                      <div className="check-box">
                        <input type="checkbox" onClick={() => handleCheck(data.id)}/>
                      </div>
                      <div className="addon-details">
                        <h6 style={{fontFamily:"Helvetica, sans-serif", fontSize:14 }}>{data.addonname}</h6>
                        <span style={{fontSize: '10px', fontFamily:"Helvetica, sans-serif"}}>({data.description})</span>
                        <h6>Qty.</h6>
                      </div>
                      <div className="addon-qty">
                        <h6>AED {data.price}</h6>
                        <span><p> </p></span>
                        <div className="qty-btn">
                        <div className="timeline__icon file" style={{margin: '0px 5px' }} onClick={() => handleMinus(data.id)}><span className="lnr lnr-circle-minus" /></div>
                           <h6>{data.qty}</h6>
                        <div className="timeline__icon file" style={{margin: '0px 5px' }} onClick={() => handlePlus(data.id)}><span className="lnr lnr-plus-circle" /></div>
                        
                        </div>
                      </div>
                    </div>
                  ))
                }
                </CardBody>
              </Card>
              </Col>
              <Col md={6} lg={6} style={{display:"flex",justifyContent:"flex-end",alignItems:'flex-end'}}>
                <Card style={{display:"flex",justifyContent:"flex-end",alignItems:'flex-end', flexDirection:'row',}}>
                    <Button color="warning" size="sm" style={{marginBottom:0}} onClick={handleBackButton}>Back</Button>
                    <Button color="warning" disabled={!userCartDetails[0]?.court} style={{marginBottom:0}} size="sm" onClick={handleCustomerDetailsPage}>Next</Button>
                </Card>
              </Col>
            </Col>
          </Row> */}

                {/* ************************************* */}
      <div className="calender-Container">
        <div className="inner-calender-addons">
          <div className="inner-lhs">
           <div className="inner-width-container">
           <div className="my-court-head">
              <h2>Just Padel - My Cart</h2>
            </div>
            <div className="my-court">
              {userCartDetails[0]?.court ?
                userCartDetails && userCartDetails.map((addToCartPayload) => (
                  <div className="myCourt-inner-container">
                    <div className="myCourt-details" >
                      <h5 className="court-numbers">{addToCartPayload?.maincourt_name} - Court {addToCartPayload?.court}</h5>
                      <h5 class="court-timing">{addToCartPayload?.booking_date} | {addToCartPayload?.timeslot}</h5>
                    </div>
                    <div className="myCourt-details" >
                      <h5 class="court-timing-amount">{`AED ${addToCartPayload?.price}`}</h5>
                    </div>
                    <div>
                      <button className="remove-btn" onClick={() => onDeleteCourt(addToCartPayload?.maincourt, addToCartPayload?.court, addToCartPayload?.timeslot, addToCartPayload?.booking_date, addToCartPayload?.court_type)}><AiOutlineDelete className="faTrashAlt"/></button>
                    </div>
                  </div>
                ))
                :
                <div className="addon">
                  <div className="addon-details">
                    <h6 style={{ fontFamily: "Verdana, sans-serif", fontSize: 15 }}>No court added to cart.</h6>
                  </div>
                </div>
              }
            </div>

            <div className="my-court-head">
              <h2>Add On's</h2>
            </div>
            <div className="addons">
              {
                userSelection && userSelection.addonSelection.map((data) => (
                  <div className="addons-content">
                  <div style={{paddingBottom:15}}>
                      <input name="isGoing" type="checkbox" id="add-Checkbox" onClick={() => handleCheck(data.id)}/>
                  </div>
                    <div className="addons-inner-container">
                    <div className="addons-inner">
                      <div className="inner-addons">
                        <div className="rents">
                          <h2>{data.addonname}</h2>
                          <small>({data.description})</small>
                        </div>
                      </div>
                      <div className="addons-minuts">
                        <h4>AED {data.price}</h4>
                      </div>
                    </div>

                    <div className="addons-inner">
                       <div className="rents qty">
                        <h3>Qty.</h3>
                       </div>
                       <div className="select-minuts">
                        <FontAwesomeIcon icon={faMinusCircle} className="faMinus" onClick={() => handleMinus(data.id)} />
                        <small>{data.qty}</small>
                        <FontAwesomeIcon icon={faPlusCircle} className="faPlus" onClick={() => handlePlus(data.id)} />
                      </div>
                    </div>
                    </div>
                  </div>
                ))
              }
            </div>
           </div>
          </div>

          <div className="inner-rhs">
            <div className="inner-width-container">
              <div className="my-court-head-rhs">
                <h2>Coupon Code</h2>
              </div>
              <div className="coupan-input-container">
               <h5>Coupon Code</h5>
               <div className="coupan-input">
                <input type="text" placeholder="E.g ABHF4354" value={couponCode} onChange={handleCoupanCodeChange} readOnly={couponValue} />
                {!couponValue ? <button onClick={applyCouponCode}> Apply </button> : <button onClick={removeCouponCode}>Remove</button>}
               </div>
               </div>
              <div>
                {couponCodeValidationMsg ?
                  <div className="form__form-group-label" style={{ fontSize: 15, }}>{couponCode} - {couponCodeValidationMsg}</div>
                  : ""}
              </div>
              <div className="my-court-head-rhs">
                <h2>Total Amount</h2>
              </div>
              <div className="total-amount">
                <div className="total-details">
                  <div>Amount</div>
                  <div className="amount-text">{totalAmountWithoutVAT?.toFixed(2)} AED</div>
                </div>
                <div className="total-details">
                  <div>VAT 5%</div>
                  <div className="amount-text">{vatAmount?.toFixed(2)} AED</div>
                </div>
                <div className="total-details">
                  <div>Total Amount</div>
                  <div className="amount-text">{finalAmount?.toFixed(2)} AED</div>
                </div>
                <div className="total-details">
                  <div>Discounted price</div>
                  <div className="amount-text">{discountPrice?.toFixed(2)} AED</div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
         <div className="total-Amount">
          <div>
            <h2>Total Payable : {finalAmount} AED</h2>
          </div>
           <div className="payment-btn">
              <button className="payment" onClick={handleCustomerDetailsPage}>Proceed to Payment</button>
              <button className="cancel" onClick={handleBackButton} >Cancel</button>
            </div>
         </div>
      </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default AddonBook;
