import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row, Card, CardBody, Table } from "reactstrap";
import { AiFillEye } from "react-icons/ai";
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { environment } from '../../env';
import axios from "../../../customAxios";
import moment from "moment";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import Reportmodal from "./Reportmodal";




const ReportPage = () => {
  const [reportData, setReportData] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [pageCount, setpageCount] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [search, setSearch] = useState(false);
  const [filterQueryValue, setFilterQueryValue] = useState();
  // const [livePostData, setLivePostData] = useState([]);


  let limit = parseInt(localStorage.getItem("DeletedCurrentPageLimit"));

  const GetReportData = (page, pageSize) => {
    const UserReportURL = `${environment.baseUrl}padelgram/report/?page=${page}&page_size=${pageSize}`;
    const result = axios.get(UserReportURL).then((response) => {
      console.log("ReportData", response.data.results)
      setReportData(response.data.results);
      let limit = parseInt(localStorage.getItem("DeletedCurrentPageLimit"));
      setpageCount(Math.ceil(response?.data?.count / limit));
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();


  var jsonObj = [];
  for (let i = 0; i < reportData.length; i++) {
    var item = {};
    item["id"] = (i + 1).toString();
    item["postid"] = reportData[i].postid.id.toString();
    {
      (reportData[i].postid.padelgram_image).includes("mp4") || (reportData[i].postid.padelgram_image).includes("mov") ?
        item["image"] = <div style={{ paddingLeft: 10 }}><video style={{ width: '75%', borderRadius: 5 }} preload="true" controls src={reportData[i].postid.padelgram_image}></video>
        </div>
        :
        item["image"] = <div style={{ paddingLeft: 10 }}><img src={reportData[i].postid.padelgram_image} alt={reportData[i].postid.padelgram_image}
          style={{ width: '75%', borderRadius: 5 }} />
        </div>;
    }
    item["reported_datetime"] = <div className="Booking-details-td-right-space">
      {moment(reportData[i].reported_datetime.split("T")[0]).format("DD-MM-YYYY")} {" "}
      {moment(reportData[i].reported_datetime.split("T")[1], "HH:mm:ss").format("hh:mm A")}
    </div>;
    item["caption"] = reportData[i].postid.padelgram_image_comment.toString();
    item["location"] = reportData[i].postid.location.toString();
    item["visibility"] = reportData[i].postid.post_type.toString();
    item["reported_user_name"] = <div>{reportData[i].postid.user.first_name}{" "}{reportData[i].postid.user.last_name}</div>
    item["reported_reason"] = reportData[i].report_reason.toString();
    item["view"] = <div className="right-space"><div className="modal-view-btn"><Reportmodal UserData={reportData[i]} /> </div></div>;
    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: false,
        width: 25,
      },
      {
        Header: "PostID",
        accessor: "postid",
        disableGlobalFilter: false,
        width: 40,
      },
      {
        Header: "Image",
        accessor: "image",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "Reported Date & Time",
        accessor: "reported_datetime",
        disableGlobalFilter: true,
        width: 60,
      },
      {
        Header: "Caption",
        accessor: "caption",
        disableGlobalFilter: false,
        width: 60,
      },
      {
        Header: "Location",
        accessor: "location",
        disableGlobalFilter: false,
        width: 40,
      },
      {
        Header: "Visibility",
        accessor: "visibility",
        disableGlobalFilter: false,
        width: 50,
      },
      {
        Header: 'Reported User Name',
        accessor: 'reported_user_name',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 45,
      },
      {
        Header: 'Reported Reason',
        accessor: 'reported_reason',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 45,
      },
      {
        Header: 'View',
        accessor: 'view',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      }
    ],
    rows: jsonObj,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [100, 200, 300, 400],
    placeholder: "Search by Name and Slots...",
  };

  const handlePageClick = async (data) => {
    if (!search) {
      setSpinner(true);
      let currentPage = data.selected + 1;
      setInitialPage(data.selected);
      let newlimit = parseInt(
        localStorage.getItem("DeletedCurrentPageLimit")
      );
      localStorage.setItem("LivePostCurrentPage", currentPage);
      GetReportData(currentPage, newlimit);
    } else {
      let currentPage = data.selected + 1;
      const FilterUrl = `${environment.baseUrl}padelgram/report/?page=${currentPage}&search=${filterQueryValue}`;
      axios.get(FilterUrl).then((filterResponse) => {
        setReportData(filterResponse.data.results);
        let limit = parseInt(localStorage.getItem("DeletedCurrentPageLimit"));
        setpageCount(Math.ceil(filterResponse?.data?.count / limit));
      })
    }

  };


  async function handleLimitChange(e) {
    localStorage.setItem("DeletedCurrentPageLimit", e.target.value);
    setInitialPage(0);
    GetReportData(1, e.target.value);
  };

  const debounce = useCallback(
    _.debounce((_searchVal) => {
      setSearch(true);
      FilterPost(_searchVal);
    }, 500),
    []
  );

  const onSubmit = (data) => {
    if (data.length > 2) {
      setFilterQueryValue(data);
      debounce(data);
    }
    setSearch(false);
    GetReportData(1, 10);
  };

  const FilterPost = (queryValue) => {
    const FilterUrl = `${environment.baseUrl}padelgram/report/?search=${queryValue}`;
    axios.get(FilterUrl).then((filterResponse) => {
      setReportData(filterResponse.data.results);
      let limit = parseInt(localStorage.getItem("DeletedCurrentPageLimit"));
      setpageCount(Math.ceil(filterResponse?.data?.count / limit));
    })
  };

  useEffect(() => {
    let newlimit = parseInt(localStorage.getItem("DeletedCurrentPageLimit"));
    GetReportData(1, newlimit);
  }, []);



  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper">
                <div className="card__title bookings">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="filter-fields">
                      <input
                        type="text"
                        name="query"
                        placeholder="Search by Name, Email, Phone No, Date ....."
                        style={{ width: "25%" }}
                        {...register("query")}
                        onChange={(e) => onSubmit(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="react-table__wrapper hide-table-costomizer">
                <ReactTableCustomizer
                  handleClickIsSortable={handleClickIsSortable}
                  handleClickWithPagination={handleClickWithPagination}
                  handleClickWithSearchEngine={handleClickWithSearchEngine}
                  handleClickIsResizable={handleClickIsResizable}
                  isResizable={isResizable}
                  isSortable={isSortable}
                  withPagination={withPagination}
                  withSearchEngine={withSearchEngine}
                />
              </div>
              <div className="table-id-desabled pagination-hide hide-filtername hover-effect hide-table-costomizer second-child-space">
                <ReactTableBase
                  key={withSearchEngine ? "searchable" : "common"}
                  columns={data.columns}
                  data={data.rows}
                  tableConfig={tableConfig}
                />
              </div>

              <div className="custom-pagination">
                <div className="records-per-page">
                  <select
                    value={localStorage.getItem("DeletedCurrentPageLimit")}
                    onChange={handleLimitChange}
                    name="limit"
                    id="select-pagination"
                  >
                    {mannualLimits &&
                      mannualLimits.map((mannualLimits) => (
                        <option value={mannualLimits}>{mannualLimits}</option>
                      ))}
                  </select>
                  &nbsp;&nbsp;&nbsp;records per page
                </div>

                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={initialPage}
                />
              </div>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default ReportPage
