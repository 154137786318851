import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import LogInForm from '../../containers/Forms/LogInForm';
import BulkBooking from '../../containers/Forms/BulkBooking';
import { BulkBookingApi } from '../../containers/Reuse/ApiCalls';
import { Card, CardBody, Col, ButtonToolbar } from "reactstrap";
import { useForm } from "react-hook-form";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input'
import "./InvoiceModal.css";
import axios from '../../customAxios';
import { environment } from '../../containers/env';
import swal from "sweetalert";

const EditUserModal = ({
    color, btn, colored, header, title, ShowDetails, name, email, phone_no
}) => {
    const [modal, setModal] = useState(false);
    const [countryCode, setCountryCode] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [value, setValue] = useState();
    const { register, handleSubmit, watch, formState, reset } = useForm();
    const { touchedFields, errors, isDirty, isValid } = formState;
    const toggle = () => {
        setModal(prevState => !prevState);
        setValue(phone_no);
        if (!modal) {
            reset({
                name: name,
                email: email,
                phone_no: phone_no
            })
        }
    };

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    const ToggleToRefresh = () => {
        toggle();
    };

    const onSubmit = (data) => {

        let ValidPhoneNumber;
        if(countryCode || phoneNumber){
            let newCountryCode = countryCode.split(' ')[0];
            let newPhoneNumber = phoneNumber.split(newCountryCode)[1];
            ValidPhoneNumber = newCountryCode + '-' + newPhoneNumber;
        }else{
            ValidPhoneNumber = data.phone_no;
        }

        const RegistrationData = {
            email: data.email,
            first_name: data.name,
            phone_no: ValidPhoneNumber,
        };

        const RegistrationURL = `${environment.baseUrl}update_user_details/${data.email}/`;

        const result = axios.put(RegistrationURL, RegistrationData)
            .then((registrationResponse) => {                
                if (registrationResponse.data.Message === 'User Updated Successfully') {
                    swal({
                        title: "Success",
                        text: "Update Successful",
                        icon: "success",
                        button: "Close",
                    })
                        .then(() => {
                            ShowDetails(data.email, data.name, ValidPhoneNumber)
                            toggle()
                        })
                }
            });

    }


    return (
        <div>
            <Button color={color} className="bulk-button" onClick={toggle}>{btn}</Button>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass}`}
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title" style={{ fontWeight: "400" }}>Edit client</h4>
                    <h4 className="text-modal  modal__title" style={{ marginTop: "10px", fontSize: 15, marginBottom: -10 }}>Details</h4>
                </div>

                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <label htmlFor="name" className='add-client-label' >Name <span style={{ color: "red" }}>*</span></label>
                                    <input id='name' type="text" name="name" className='add-client-input' {...register("name", { required: true })} />
                                </div>

                                <div>
                                    <label htmlFor="email" className='add-client-label' >Email <span style={{ color: "red" }}>*</span></label>
                                    <input id='email' type="email" disabled name="email" className='add-client-input'  {...register("email", { required: true })} />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <label htmlFor="phone_no" className='add-client-label'>Phone Number <span style={{ color: "red" }}>*</span></label>
                                    <PhoneInput
                                        defaultCountry="AE"
                                        name="phone_no"
                                        id="phone_no"
                                        required
                                        value={value}
                                        className="add-client-phone"
                                        onChange={(text) => {
                                            setCountryCode(formatPhoneNumberIntl(text));
                                            setPhoneNumber(text);
                                        }}
                                    />
                                </div>

                                <div className="btn-cust-css">
                                    <ButtonToolbar className="form__button-toolbar " style={{ marginBottom: "40ppx" }}>
                                        <Button type="button" size="sm" onClick={toggle}>
                                            Cancel
                                        </Button>
                                        <Button color="primary" size="sm" type="submit">
                                            Save
                                        </Button>
                                    </ButtonToolbar>
                                </div>

                            </form>
                        </CardBody>
                    </Card>
                </Col>

            </Modal>
        </div>
    );
};

EditUserModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired,
};

EditUserModal.defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
};

export default EditUserModal;

// export default connect(state => ({
//   rtl: state.rtl,
// }))(AddUserModal);
