import React, { useEffect, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Table,
  Spinner,
  Container,
  Row,
} from "reactstrap";
import moment from "moment";
import DatePicker from "react-date-picker";

import axios from "../../customAxios";
import { environment } from "../env";
import BookedDetail from "./BookedDetail";
import "./Calenderv2.css";
import AvailableModal from "../../shared/components/AailableModal";
import SampleModalComponent from "../../shared/components/SampleModalComponent";
import CalendarSkaleton from "../Skaleton/CalendarSkaleton";
import BookingDetailsModalV2 from "../../shared/components/BookingDetailsModalV2";
import {
  CancelBooking,
  GetMainCourt,
  GetPadelCourt,
  GetRegisteredUser,
  CancelStaffBooking,
  CancelCoacheBooking,
} from "../Reuse/ApiCalls";
import swal from "@sweetalert/with-react";



const Calenderv2 = () => {
  const [courts, setCourts] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [mainCourtId, setMainCourtId] = useState(2);
  const [mainCourtList, setMainCourtList] = useState([]);
  const [start, onChangeStart] = useState(new Date());
  const [showAvailableModal, setshowAvailableModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [prePopulatedDated, setPrePopulatedDated] = useState();
  const [showModal, setShowModal] = useState(false);
  const [bookedSlotDetails, setBookedSlotDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const ColorDetails = [
    {
      category: "Bookings",
      color: "#e15f40",
    },
    {
      category: "Events",
      color: "#686de0",
    },
    {
      category: "Tournaments",
      color: "#3868d6",
    },
    {
      category: "Maintenance",
      color: "#dd3444",
    },
    {
      category: "Coaching - Regular",
      color: "#f0932b",
    },
    {
      category: "Coaching - Pro Tennis",
      color: "#ffbe76",
    },
    {
      category: "Coaching - Just Padel",
      color: "#f9ca24",
    },
    {
      category: "GTAP",
      color: "#22a6b3",
    },
    {
      category: "Matchmaking",
      color: "#30336b",
    },
    {
      category: "Others",
      color: "#6D214F",
    },
    {
      category : "SplitPay",
      color : "#33e019"
    }
  ];

  const GetBookingDetails = (startdate, maincourtid) => {
    setBookingDetails([]);

    const calenderDataPayload = {
      start_date: startdate,
      end_date: startdate,
      maincourtId: maincourtid,
    };

    const GetBookingDetailsUrl = `${environment.baseUrl}calenderv2`;
    axios
      .post(GetBookingDetailsUrl, calenderDataPayload)
      .then((bookingResponse) => {
        setCourts(bookingResponse.data.courts);
        setBookingDetails(bookingResponse.data.bookingdetails);
        setSpinner(false);
      });
  };

  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts`;
    const result = await axios(mainCourtUrl);
    setMainCourtList(result.data.filter((data) => data.id !== 12));
  };

  function handleSelectMainCourt(e) {
    setMainCourtId(e.target.value);
  }

  const AvailableSlot = (timeslot, padelcourt) => {
    let prePopulateData = {
      main_court_id: mainCourtId,
      padelcourtId: padelcourt,
      startTime: timeslot.split("-")[0],
      endTime: timeslot.split("-")[1],
      startDate: moment(start).format("yyyy-MM-DD"),
      endDate: moment(start).format("yyyy-MM-DD"),
    };

    setPrePopulatedDated(prePopulateData);
    setshowAvailableModal(!showAvailableModal);
  };

  const BookedModal = (timeslot, padelcourt, BookingData) => {
    setIsLoading(true)
    let prePopulateData = {
      main_court_id: mainCourtId,
      padelcourtId: padelcourt,
      startTime: timeslot.split("-")[0],
      endTime: timeslot.split("-")[1],
      startDate: moment(start).format("yyyy-MM-DD"),
      endDate: moment(start).format("yyyy-MM-DD"),
    };

    let item = {
      maincourtId: BookingData.bookingdetails.maincourtId,
      booking_id: BookingData.bookingdetails.booking_id,
      id: BookingData.padelcourt,
      name: BookingData.bookingdetails.name,
      user: BookingData.bookingdetails.user,
      booking_category: BookingData.bookingdetails.booking_category,
      username: BookingData.bookingdetails.userDetails.username,
      email: BookingData.bookingdetails.userDetails.email,
      phone_no: BookingData.bookingdetails.userDetails.phone_no,
      payment_type: BookingData.bookingdetails.userDetails.payment_type,
      additionalcomments:
        BookingData.bookingdetails.userDetails.additionalcomments,
    };

    setBookedSlotDetails(item);
    setPrePopulatedDated(prePopulateData);
    setShowModal(!showModal);
    setIsLoading(false)
  };

  const submitDate = () => {
    setSpinner(true);
    GetBookingDetails(
      moment(start).format("DD-MM-YYYY"),
      parseInt(mainCourtId)
    );
  };

  const CancelBookings = (bookingid, logs) => {
    let newLog;
    if (logs === "") {
      let AdminUserName = localStorage.getItem("AdminUserName");
      newLog = JSON.stringify([
        {
          created_or_edited_by: "Cancelled by user " + AdminUserName + "",
          created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
          created_or_edited_time: moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),
        },
      ]);
    } else {
      const prevLog = JSON.parse(logs);
      let AdminUserName = localStorage.getItem("AdminUserName");
      newLog = JSON.stringify([
        {
          created_or_edited_by: "Cancelled by user " + AdminUserName + "",
          created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
          created_or_edited_time: moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),
        },
        ...prevLog,
      ]);
    }
    swal({
      title: "Are you sure?",
      text: "You want to Cancel the Booking",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        CancelStaffBooking(bookingid);
        CancelCoacheBooking(bookingid);
        CancelBooking(bookingid, newLog).then((response) => {
          if (response.Message === "Booking Cancelled!!!") {
            swal({
              icon: "success",
              content: (
                <div>
                  <h1>Booking Cancelled</h1>
                </div>
              ),
            }).then(() => {
              // refreshDetailsData();
            });
          }
        });
      } else {
        swal("Booking is not cancelled");
      }
    });
  };



  useEffect(() => {
    setSpinner(true);
    let user = JSON.parse(localStorage.getItem("loggedUserDetails"));
    let startdate = moment().format("DD-MM-YYYY");
    GetMainCourt();
    GetBookingDetails(startdate, user?.default_court);
  }, []);



  return (
    <Container className="dashboard">
      {isLoading && (
        <Loader />
      )}
      <Row>
        <Col md={12} lg={12}>
          <h3 className="page-title">Calender</h3>
        </Col>
      </Row>
      <Col md={12} lg={12}>
        {/* <SampleModalComponent
          showHide={showModal}
          bookingDetail={bookedSlotDetails}
          prePopulatedDated={prePopulatedDated}
          submitDate={submitDate}
          header
          color="primary"
          title="Booking Information"
          btn="Edit Booking"
        /> */}

        <BookingDetailsModalV2
          color="primary"
          header
          title="Booking Details"
          userdetails={bookedSlotDetails?.user}
          transactionId={bookedSlotDetails?.booking_id}
          cancelBoooking={CancelBookings}
          showHide={showModal}
          prePopulatedDated={
            prePopulatedDated
          }
        // prePopulatedDated={{
        //   main_court_id: bookedSlotDetails.mainCourtId,
        //   padelcourtId: bookedSlotDetails.id,
        //   startTime: bookedSlotDetails.timeSlots.split("-")[0],
        //   endTime: bookedSlotDetails.timeSlots.split("-")[1],
        //   startDate: moment(start).format("yyyy-MM-DD"),
        //   endDate: moment(start).format("yyyy-MM-DD"),
        // }}
        />





        <Card>
          <CardBody>
            <div className="top-container">
              <div style={{ display: "flex" }}>
                <div className="inner-container">
                  <span className="page-title">Select Location </span>
                  <select
                    className="select select-location"
                    value={mainCourtId}
                    onChange={handleSelectMainCourt}
                  >
                    <option>Select Main Court</option>
                    {mainCourtList &&
                      mainCourtList.map((court) => (
                        <option value={court.id}>{court.courtName}</option>
                      ))}
                  </select>
                </div>
                <div className="inner-container">
                  <span className="page-title">Select Date</span>
                  <DatePicker
                    dateFormat="YYYY-MM-DD"
                    onChange={onChangeStart}
                    value={start}
                    className="custom-css-dp"
                  />
                </div>
              </div>
              <div className="submit-btn-container">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      marginLeft: "50%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  ></div>
                </div>
                <div className="cal-top-submit-btn">
                  <button
                    color="warning"
                    size="sm"
                    disabled={
                      mainCourtId === "Select Main Court" ? "disabled" : ""
                    }
                    onClick={submitDate}
                    className={
                      mainCourtId === "Select Main Court"
                        ? "date-submit-disable"
                        : "date-submit"
                    }
                  >
                    <p style={{ color: "#fff" }}>Submit</p>
                  </button>
                </div>
              </div>
            </div>
            {spinner ? (
              <CalendarSkaleton />
            ) : (
              <Table className="calendar">
                <thead>
                  <tr className="d-flex">
                    {courts.map((data, index) => {
                      return (
                        <th className="calender__courts" key={index}>
                          {data}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {bookingDetails.map((booking, index) => {
                    return (
                      <>
                        <tr style={{ display: "flex" }} key={index}>
                          <td className="cal-headerTD calender__timeslots">
                            {booking.timeslots}
                          </td>

                          {booking.bookingData.map((datas) => {
                            return (
                              <>
                                {datas.bookingdetails === "Available" ? (
                                  // <td
                                  //   className="calender__available"
                                  //   onClick={() =>
                                  //     AvailableSlot(
                                  //       booking.timeslots,
                                  //       datas.padelcourt
                                  //     )
                                  //   }
                                  // >
                                  //   <AvailableModal
                                  //     submitDate={submitDate}

                                  //     color="primary"
                                  //     title="Quick Booking"
                                  //     header
                                  //     prePopulatedDated={prePopulatedDated}

                                  //   />
                                  // </td>
                                  <>
                                    <AvailableModal
                                      submitDate={submitDate}
                                      color="primary"
                                      title="Quick Booking"
                                      header
                                      prePopulatedDated={{
                                        main_court_id: mainCourtId,
                                        padelcourtId: datas.padelcourt,
                                        startTime: booking?.timeslots.split("-")[0],
                                        endTime: booking?.timeslots.split("-")[1],
                                        startDate: moment(start).format("yyyy-MM-DD"),
                                        endDate: moment(start).format("yyyy-MM-DD"),
                                      }}
                                    />
                                  </>

                                ) : (
                                  <td
                                    className="calender__booked"
                                    onClick={() =>
                                      BookedModal(
                                        booking.timeslots,
                                        datas.padelcourt,
                                        datas
                                      )
                                    }
                                    style={{
                                      backgroundColor:
                                        datas.bookingdetails.backgroundColor,
                                    }}
                                  >
                                    <BookedDetail
                                      details={datas.bookingdetails.userDetails}
                                    />
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <div className="d-flex flex-wrap">
              {ColorDetails.map((colorData, index) => {
                return (
                  <div key={index} className="rounded-flex">
                    <div
                      className="div-round"
                      style={{ backgroundColor: `${colorData.color}` }}
                    ></div>
                    <span className="ml-1" style={{ marginTop: "2px" }}>
                      {colorData.category}
                    </span>
                  </div>
                );
              })}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

export default Calenderv2;

const Loader = () => {
  return (
    <div class="loader-div">
      <span class="loader">
        <div className="center-spinner">
          <Spinner className="table-fetch-spinner" />
        </div>
      </span>
    </div>
  );
};
