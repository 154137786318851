import React from 'react'

const UserDetails = ({first_name,email,phone}) => {
    return (
        <>
            {first_name}<br />
            <span className="user-email">{email}</span><br />
            <span className="user-phone">{phone}</span>  
        </>
    )
};

export default UserDetails;
