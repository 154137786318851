import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import PadelholicCard from './components/PadelholicCard';

const EditPadelholic = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Edit Padelholic</h3>
      </Col>
    </Row>
    <Row>
      <PadelholicCard />
    </Row>
  </Container>
);
export default EditPadelholic;
