import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Role from './components/EditRole';

const RolePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Edit Role</h3>
      </Col>
    </Row>
    <Row>
      <Role />
    </Row>
  </Container>
);

export default RolePage;
