import React, { useState, useEffect } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  CardBody,
  Card,
  Col,
  Spinner,
  Input,
  Row,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import {
  BookingDetailsByBookingID,
  GetAddon,
  BookingDetailsAvailableTrueByBookingID,
  BookingDetailsAvailableFalseByBookingID,
  getCoupon,
  CancelBooking,
  CancelStaffBooking,
  CancelCoacheBooking,
} from "../../../Reuse/ApiCalls";
import "./Tab.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { RiUser3Line, RiFileEditLine, RiPencilLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { FaRegComments } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import "./BookingDetailsTab.css";
import "react-datepicker/dist/react-datepicker.css";
import BookingDetailsTabTable from "./BookingDetailsTabTable";
import BookingDetailsUserTabTable from "./BookingDetailsUserTabTable";
import BookingDetailsAdminEditForm from "./BookingDetailsAdminEditForm";
import BookingDetailsUserEditForm from "./BookingDetailsUserEditForm";
import BulkBooking from "../../../Forms/BulkBooking";
import CommentModal from "../../../../shared/components/CommentModal";
import { render } from "@testing-library/react";
import AddonsComponent from "./AddonsComponent";
import swal from "@sweetalert/with-react";
import { environment } from "../../../env";
import axios from "../../../../customAxios";
import { AiOutlineSearch, AiOutlineCloseCircle } from "react-icons/ai";
import _ from "lodash";
import SplitPointComponent from "./SplitPointComponent";
import SplitPointComponentPlayer1 from "./SplitPointComponentPlayer1";
import AdminLoyaltyDeatils from "./AdminLoyaltyDeatils";
import SalesRecord from "./SalesRecord";
import BookingTransactionTab from './BookingTransactionTab';
import BookingDetailTransactionTabPane from "./BookingDetailTransactionTabPane";

const BookingDetailsTab = ({
  transactionId,
  prePopulatedDated,
  toggleModal,
  bookedUsing,
  closeModal,
  userdetails,
}) => {

  const [bookingDetailsSingle, setBookingDetailsSingle] = useState();

  // const [transactionId, setBookingData] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [timeToPlay, setTimeToPlay] = useState();
  const [hoursToPlay, setHoursToPlay] = useState();
  const [courtBookinDate, setCourtBookinDate] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [bookedSlots, setBookedSlots] = useState([]);
  const [totalCourtAmount, setTotalCourtAmount] = useState(0);
  const [totalAddonAmount, setTotalAddonAmount] = useState(0);
  const [finalAddonList, setFinalAddonList] = useState([]);
  const [logs, setLogs] = useState([]);
  const [createdOrEditedLogs, setCreatedOrEditedLogs] = useState([]);
  const [noOfElements, setNoOfElements] = useState(1);
  const [showCommentModal, setshowCommentModal] = useState(false);
  const [showAllCommen, setshowAllComment] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [couponPercentage, setCouponPercentage] = useState();
  const [clickAddonView, setClickAddonView] = useState(false);
  const [loyaltypoint, setLoyaltypoints] = useState();
  const [playedGames, setPlayedGames] = useState();
  const [updateAdminBookings, setUpdateAdminBookings] = useState(false);
  const [adminBookingUserDetails, setAdminBookingUserDetails] = useState();
  const [bookingDetailsNew, setBookingDetailsNew] = useState()

  // -------------------Return state in html-----------------
  const [totalAmount, setTotalAmount] = useState();
  const [vatAmount, setVatAmount] = useState();
  const [discountedAmount, setDiscountedAmount] = useState();
  const [payFacilityAmount, setPayFacilityAmount] = useState();
  const [spinner, setSpinner] = useState(false);
  const [splitError, setSplitError] = useState(false);
  const [pointSplitable, setPointSplitable] = useState(false);
  const [promotionUsed, setPromotionUsed] = useState(false);
  const [sharedCompleted, setSharedCompleted] = useState();

  // --- states for split points ---
  const [playerOneData, setPlayerOneData] = useState();
  const [playerTwoData, setPlayerTwoData] = useState();
  const [playerThreeData, setPlayerThreeData] = useState();
  const [playerFourData, setPlayerFourData] = useState();
  const [clearSearchPlayerTwo, setClearSearchPlayerTwo] = useState(false);
  const [viewSplitPoints, setViewSplitPoints] = useState(false);

  // ------------------------FOR COACHES AND STAFF ------------------------------

  const [coachStaff, setCoachStaff] = useState();
  const [coachOrStaffPrice, setCoachOrStaffPrice] = useState(0);
  const [noOfSlots, setNoOfSlots] = useState(0);

  const LoggedUserId = localStorage.getItem("UserId");
  const ClickShow = () => {
    setshowAllComment(!showAllCommen);
  };

  const ShowModal = () => {
    setshowCommentModal(!showCommentModal);
  };

  const slice = commentsData?.slice(0, -1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setSpinner(false);
  };

  const StartandEndTime = (data) => {
    setCourtBookinDate(data?.booking_date);
    const startTimes = data?.booked_slots[0]?.time?.split("-")[0];
    const endsTimes = data?.booked_slots.slice(-1)[0]?.time?.split("-")[1];
    setTimeToPlay(startTimes + "-" + endsTimes);



    // -----------------------functionality to get hours to play code starts here-------
    var startTime = moment(startTimes, "HH:mm a");
    var endTime = moment(endsTimes, "HH:mm a");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) - hours * 60;
    setHoursToPlay(hours + " hr . " + minutes + " mins.");
    // -----------------------functionality to get hours to play code ends here-------
  };


  const GetBookingDetailsNew = () => {
    const bookingUrl = `${environment.baseUrl}courtbooking-details/`;
    axios.post(bookingUrl, { bookingId: transactionId }).then((response) => {
      setBookingDetailsNew(response.data.booking_details);
    })
  };

  const getBookingDetails = (id) => {
    BookingDetailsAvailableFalseByBookingID(id).then((response) => {
      const com =
        response.data.comments === "" || response.data.comments === undefined
          ? JSON.stringify([{ comment: "No Comments" }])
          : JSON.parse(response.data.comments);
      if (typeof com === "string") {
        setCommentsData(JSON.parse(com));
      } else {
        setCommentsData(com);
      }

      if (response?.data?.Message === "No data with this id!!!") {
        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data.Message === "No data with this id!!!") {
            // setLogs();
          } else {
            StartandEndTime(response?.data);
            setBookingDetailsSingle(response?.data);
            if (typeof response?.data?.logs === "string") {
              setCreatedOrEditedLogs();
            } else {
              setCreatedOrEditedLogs(response?.data?.logs);
            }
          }
        });
      } else {
        StartandEndTime(response?.data);
        const unique = [];
        response?.data.booked_slots.map((x) =>
          unique.filter(
            (a) =>
              a.time == x.time && a.date == x.date && a.courtname == x.courtname
          ).length > 0
            ? null
            : unique.push(x)
        );
        setBookedSlots(unique);
        GetCoachAndStaffDetails(unique?.length);
        setNoOfSlots(unique?.length);
        setBookingDetailsSingle(response?.data);
        setSpinner(true);
        if (response?.data?.logs !== "") {
          if (typeof response?.data?.logs === "string") {
            let newData = JSON.parse(response?.data?.logs);
            setCreatedOrEditedLogs(newData);
            setSpinner(false);
            if (typeof newData === "string") {
              let againParse = JSON.parse(newData);
              setCreatedOrEditedLogs(againParse);
              setSpinner(false);
            } else {
              setCreatedOrEditedLogs(newData);
              setSpinner(false);
            }
          } else {
            setCreatedOrEditedLogs(response?.data?.logs);
            setSpinner(false);
          }
        } else {
          setSpinner(false);
        }
      }
    });

    BookingDetailsAvailableFalseByBookingID(id).then((response) => {
      // setBookedSlots(response.data.booked_slots);
      if (response.data.Message !== "No data with this id!!!") {
        addonFunction(response.data);
        let TotlaCourtPrice;
        if (response?.data?.booked_slots?.length === 1) {
          TotlaCourtPrice = response?.data?.booked_slots[0].Price;
          if (response?.data?.packages > 0) {
            setTotalCourtAmount(0);
          } else {
            setTotalCourtAmount(TotlaCourtPrice);
          }
        } else {
          const unique = [];
          response?.data.booked_slots.map((x) =>
            unique.filter(
              (a) =>
                a.time == x.time &&
                a.date == x.date &&
                a.courtname == x.courtname
            ).length > 0
              ? null
              : unique.push(x)
          );
          TotlaCourtPrice = unique.reduce(function (a, b) {
            return { Price: a.Price + b.Price };
          });
          if (response?.data?.packages > 0) {
            setTotalCourtAmount(0);
          } else {
            setTotalCourtAmount(TotlaCourtPrice?.Price);
          }
        }

        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data?.Message === "No data with this id!!!") {
            // setLogs();
          } else {
            setLogs(response?.data?.booked_slots);
          }
        });
      } else {
        BookingDetailsAvailableTrueByBookingID(id).then((response) => {
          if (response?.data?.Message === "No data with this id!!!") {
            // setLogs();
          } else {
            addonFunction(response.data);
            let TotlaCourtPrice;
            if (response?.data?.booked_slots?.length === 1) {
              TotlaCourtPrice = response?.data?.booked_slots[0].Price;
              if (response?.data?.packages > 0) {
                setTotalCourtAmount(0);
              } else {
                setTotalCourtAmount(TotlaCourtPrice);
              }
            } else {
              const unique = [];
              response?.data?.booked_slots.map((x) =>
                unique.filter(
                  (a) =>
                    a.time == x.time &&
                    a.date == x.date &&
                    a.courtname == x.courtname
                ).length > 0
                  ? null
                  : unique.push(x)
              );
              TotlaCourtPrice = unique.reduce(function (a, b) {
                return { Price: a.Price + b.Price };
              });
              if (response?.data?.packages > 0) {
                setTotalCourtAmount(0);
              } else {
                setTotalCourtAmount(TotlaCourtPrice?.Price);
              }
            }
            setLogs(response?.data?.booked_slots);
            if (response?.data?.logs !== "") {
              if (typeof response?.data?.logs === "string") {
                let newData = JSON.parse(response?.data?.logs);
                setCreatedOrEditedLogs(newData);
                if (typeof newData === "string") {
                  let againParse = JSON.parse(newData);
                  setCreatedOrEditedLogs(againParse);
                } else {
                  setCreatedOrEditedLogs(newData);
                }

                const com =
                  response.data.comments === "" ||
                    response.data.comments === undefined
                    ? JSON.stringify([{ comment: "No Comments" }])
                    : JSON.parse(response.data.comments);
                if (typeof com === "string") {
                  setCommentsData(JSON.parse(com));
                } else {
                  setCommentsData(com);
                }
              } else {
                setCreatedOrEditedLogs(response?.data?.logs);
              }
            } else {
              setCreatedOrEditedLogs([]);
            }
          }
        });
      }
    });

    // BookingDetailsAvailableTrueByBookingID(id).then((response) => {
    //   if (response?.data?.Message === "No data with this id!!!") {
    //     // setLogs();
    //   } else {
    //     setLogs(response?.data?.booked_slots);
    //   }
    // });
  };

  const addonFunction = async (data) => {
    if (data?.addons === "null" || data?.addons === null) {
      setTotalAddonAmount(0);
      return setTotalAddonAmount(0);
    }
    var match = data?.addons;
    let seperateComma = match?.split(",");
    var arrays = [];
    var addonArray = [];
    for (var i = 0; i < seperateComma?.length; i++) {
      arrays = seperateComma[i].replace(/'/gi, "").split("-");
      let objs = {};
      objs["id"] = parseInt(arrays[0]);
      objs["quantity"] = parseInt(arrays[1]);
      addonArray.push(objs);
    }
    localStorage.setItem("addonList", JSON.stringify(addonArray));
    addonListData();
    DiscountedPrice();
  };

  const addonListData = () => {
    GetAddon().then((response) => {
      let addonNewList = response;
      let purchaseAddonList = JSON.parse(localStorage.getItem("addonList"));
      const result = addonNewList?.map(function (el) {
        var o = Object.assign({}, el);
        const quantity = purchaseAddonList?.find((e) => e.id === el.id);
        o.qty = quantity?.quantity === undefined ? 0 : quantity?.quantity;
        return o;
      });
      setFinalAddonList(result);
      let TotlaAddonPrice = 0;
      if (result.length === 0) {
        setTotalAddonAmount(TotlaAddonPrice);
      } else if (result.length === 1) {
        TotlaAddonPrice = result[0].price * result[0].qty;
        setTotalAddonAmount(TotlaAddonPrice);
      } else {
        TotlaAddonPrice = result.reduce(function (a, b) {
          return a + b.price * b.qty;
        }, 0);
        setTotalAddonAmount(parseInt(TotlaAddonPrice));
      }
    });
  };

  const GetPromotionBooking = (bookingId) => {
    const GetPromotionBookingUrl = `${environment.baseUrl}promotion-v2-used/${bookingId}/`;
    axios.get(GetPromotionBookingUrl).then((response) => {
      setPromotionUsed(response.data);
    })
  };

  useEffect(() => {
    VerifyAdminLoyaltyPointShare(transactionId)
    getBookingDetails(transactionId);
    DiscountedPrice();
    GetPromotionBooking(transactionId);
    getAdminBookedUser();
    PointSplitable();
  }, []);

  useEffect(() => {
    GetBookingDetailsNew()
  }, [transactionId]);

  const CommentRefresh = () => {
    getBookingDetails(transactionId);
    DiscountedPrice();
    GetCoachAndStaffDetails(noOfSlots);
  };

  const formData = {
    client: `${bookingDetailsSingle?.user?.first_name} ${bookingDetailsSingle?.user?.last_name}`,
    location: bookingDetailsSingle?.main_court?.id,
    service_provider: bookingDetailsSingle?.padel_court?.id,
  };

  const DiscountedPrice = () => {
    if (bookingDetailsSingle?.coupan_applied) {
      if (bookingDetailsSingle?.coupan_applied != "") {
        const GetCouponDetails = `${environment.baseUrl}getcoupan/${bookingDetailsSingle?.coupan_applied}/`;
        const couponResult = axios.get(GetCouponDetails).then((couponResponse) => {
          if (couponResponse.data) {
            FinalCalculation(couponResponse?.data);
            setCouponPercentage(couponResponse?.data?.percentage_of_discount);
          }
        });
      } else {
        setCouponPercentage(0);
        FinalCalculation();
      }
    } else {
      setCouponPercentage(0);
      FinalCalculation();
    }
  };

  const handleAddons = () => {
    setClickAddonView(true);
  };

  const CancelBookings = (bookingid, logs) => {
    let AdminUserName = localStorage.getItem("AdminUserName");
    let newLog = JSON.stringify([
      {
        created_or_edited_by: "Cancelled by user " + AdminUserName + "",
        created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
        created_or_edited_time: moment(
          new Date().toLocaleString("en-US", { timeZone: "Asia/Dubai" })
        ).format("hh:mm A"),
      },
      ...logs,
    ]);
    swal({
      title: "Are you sure?",
      text: "You want to Cancel the Booking",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setSpinner(true);
        CancelStaffBooking(bookingid);
        CancelCoacheBooking(bookingid);
        CancelBooking(bookingid, newLog).then((response) => {
          setSpinner(true);
          if (response.Message === "Booking Cancelled!!!") {
            swal({
              icon: "success",
              content: (
                <div>
                  <h1>Booking Cancelled</h1>
                </div>
              ),
            }).then(() => {
              setSpinner(false);
              toggleModal();
              CommentRefresh();
            });
          }
        });
      } else {
        setSpinner(false);
        swal("Booking is not cancelled");
      }
    });
  };

  const totalPrice = parseInt(
    totalCourtAmount +
    totalAddonAmount +
    ((totalCourtAmount + totalAddonAmount) / 100) * 5
  ).toFixed(2);

  // ---------------- Calculation for discount admount starts here ---------
  const calculateDiscountAmount = (
    courtamount,
    addonAmount,
    couponDetails,
    coupon
  ) => {
    if (coupon === undefined) {
      return 0.0;
    }
    if (coupon === "null") {
      return 0.0;
    }
    if (coupon === "") {
      return 0.0;
    }
    if (coupon === ("null" || "" || undefined || "undefined")) {
      return 0.0;
    } else {
      if (couponDetails?.apply_for_addons) {
        return Math.ceil(
          (((courtamount + addonAmount) / 100) *
            couponDetails?.percentage_of_discount).toFixed(2)
        );
      } else {
        return Math.ceil((courtamount / 100) * couponDetails?.percentage_of_discount);
      }
    }
  };
  // ---------------- Calculation for discount admount ends here -----------

  // ---------------- Calculation for vat code starts here --------------
  const VatCalculation = (courtamount, addonAmount, couponDetails, coupon, coachOrStaffPrice) => {
    if (coupon === ("null" || "" || undefined)) {
      return Math.ceil((courtamount + addonAmount + coachOrStaffPrice) * 0.05);
    } else {

      if (couponDetails?.apply_for_addons) {
        return Math.ceil(
          (courtamount +
            addonAmount + coachOrStaffPrice -
            calculateDiscountAmount(
              courtamount,
              addonAmount,
              couponDetails,
              coupon
            )) *
          0.05
        );
      } else {

        return Math.ceil(
          (courtamount + coachOrStaffPrice -
            calculateDiscountAmount(
              courtamount,
              addonAmount,
              couponDetails,
              coupon
            ) +
            addonAmount) *
          0.05
        );
      }
    }
  };

  // ---------------- Calculation for vat code ends here --------------

  // ---------------- Calculate total amount code starts here ---------
  const CalculateTotalAmount = (
    courtamount,
    addonamount,
    coupondetails,
    coupon,
    coachOrStaffPrice
  ) => {
    if (coupon === ("null" || "" || undefined)) {
      return (
        courtamount +
        addonamount + coachOrStaffPrice +
        VatCalculation(courtamount, addonamount, coupondetails, coupon, coachOrStaffPrice)
      );
    } else {
      if (couponPercentage?.apply_for_addons) {
        return (
          courtamount +
          addonamount + coachOrStaffPrice +
          (VatCalculation(courtamount, addonamount, coupondetails, coupon, coachOrStaffPrice) -
            calculateDiscountAmount(
              courtamount,
              addonamount,
              coupondetails,
              coupon
            ))
        );
      } else {
        return (
          courtamount +
          addonamount + coachOrStaffPrice +
          (VatCalculation(courtamount, addonamount, coupondetails, coupon, coachOrStaffPrice) -
            calculateDiscountAmount(
              courtamount,
              addonamount,
              coupondetails,
              coupon
            ))
        );
      }
    }
  };
  // ---------------- Calculate total amount code starts here ---------

  // ---------------- Paid to faculty calculation code starts here -------
  const PaidToFacultyCalculation = (
    courtamount,
    addonamount,
    coupondetails,
    Coupon,
    coachOrStaffPrice,
    amountPaid,
  ) => {
    return (
      CalculateTotalAmount(courtamount, addonamount, coupondetails, Coupon, coachOrStaffPrice) -
      amountPaid
    );
  };
  // ---------------- Paid to faculty calculation code ends here ---------

  // const FinalCalculation = (coupon) => {

  //   let courtAddonPrice = 0;
  //   let discountedPrice = 0;
  //   let totlaAmountsWithoutVat = 0;
  //   let newVat = 0;
  //   let newFinalAmount = 0;
  //   let advanceAmount = 0;
  //   let newCouponPercentage = 0;

  //   if (coupon?.apply_for_addons) {
  //     courtAddonPrice = totalCourtAmount + totalAddonAmount;
  //     discountedPrice = Math.round((courtAddonPrice / 100) * coupon?.percentage_of_discount);
  //     totlaAmountsWithoutVat = courtAddonPrice - discountedPrice;
  //     setDiscountedAmount(discountedPrice);
  //     newVat = Math.round(totlaAmountsWithoutVat * 0.05);
  //     setVatAmount(newVat);
  //     setTotalAmount(CalculateTotalAmount(totalCourtAmount, totalAddonAmount, coupon, coupon));
  //     newFinalAmount = totlaAmountsWithoutVat + newVat;
  //     setPayFacilityAmount(PaidToFacultyCalculation(totalCourtAmount, totalAddonAmount, coupon, coupon, bookingDetailsSingle?.amount_paid));
  //     if(bookingDetailsSingle?.packages>0){
  //       setPayFacilityAmount(0);
  //     }
  //     setSpinner(false);
  //   } else {
  //     newCouponPercentage = coupon?.percentage_of_discount ? coupon?.percentage_of_discount : 0;
  //     discountedPrice = (totalCourtAmount / 100) * newCouponPercentage;
  //     totlaAmountsWithoutVat = (totalCourtAmount - discountedPrice) + totalAddonAmount;
  //     setDiscountedAmount(discountedPrice);
  //     newVat = Math.round(totlaAmountsWithoutVat * 0.05);
  //     setTotalAmount(totalCourtAmount + totalAddonAmount + newVat);
  //     setVatAmount(Math.round(newVat));
  //     newFinalAmount = totlaAmountsWithoutVat + newVat;
  //     setPayFacilityAmount(Math.round(newFinalAmount) - bookingDetailsSingle?.amount_paid);
  //     if(bookingDetailsSingle?.packages>0){
  //       setPayFacilityAmount(0);
  //     }
  //     setSpinner(false);
  //   }
  // }

  const FinalCalculation = (coupon) => {

    setPayFacilityAmount();
    setDiscountedAmount(
      calculateDiscountAmount(
        totalCourtAmount,
        totalAddonAmount,
        coupon,
        coupon
      )
    );

    setVatAmount(
      VatCalculation(totalCourtAmount, totalAddonAmount, coupon, coupon, coachOrStaffPrice)
    );
    setTotalAmount(
      CalculateTotalAmount(totalCourtAmount, totalAddonAmount, coupon, coupon, coachOrStaffPrice)
    );
    setPayFacilityAmount(
      PaidToFacultyCalculation(
        totalCourtAmount,
        totalAddonAmount,
        coupon,
        coupon,
        coachOrStaffPrice,
        bookingDetailsSingle?.amount_paid
          ? bookingDetailsSingle?.amount_paid
          : 0
      )
    );
    if (bookingDetailsSingle?.packages > 0) {
      setPayFacilityAmount(0);
    }
    setSpinner(false);
  };

  const loyaltypoints = () => {
    const getloyaltypointsurl = `${environment.baseUrl}loyaltypoint/getloyaltypoints/${bookingDetailsSingle?.user?.id}`;
    axios.get(getloyaltypointsurl).then((response) => {
      PlayerOneDetails(bookingDetailsSingle?.user?.id);
      setLoyaltypoints(response?.data);
      setPlayedGames(response?.data?.loyaltygames);
    });
  };

  const Silver =
    loyaltypoint?.loyaltygames >= 1 && loyaltypoint?.loyaltygames <= 30;
  const Gold =
    loyaltypoint?.loyaltygames >= 31 && loyaltypoint?.loyaltygames <= 50;
  const Platinum =
    loyaltypoint?.loyaltygames >= 51 && loyaltypoint?.loyaltygames <= 100;
  const BlackDiamond = loyaltypoint?.loyaltygames >= 101;

  const PlayerOneDetails = (playerOneId) => {
    const userDetailsUrl = `${environment.baseUrl}filter-user/${playerOneId}`;
    axios.get(userDetailsUrl).then((response) => {
      setPlayerOneData(response.data[0]);
    });
  };

  const PointSplitable = () => {
    const PointSplitableUrl = `${environment.baseUrl}loyaltypoint/point-splitable/${transactionId}/`;
    axios.get(PointSplitableUrl).then((response) => {
      setPointSplitable(response?.data?.splitable);

      // if (response?.data?.splitable) {
      //   setViewSplitPoints(true);
      // } else {
      //   setViewSplitPoints(false);
      // }

    })
  };

  // --------------------------------------------------------------------
  // ----------------- Player Second Functionality ----------------------
  // --------------------------------------------------------------------
  let playerSecValue;
  const getPlayerSecDetails = () => {
    const userDetailsUrl = `${environment.baseUrl}filter-user/${playerSecValue}`;
    axios.get(userDetailsUrl).then((response) => {
      setPlayerTwoData(response.data[0].first_name);
      setClearSearchPlayerTwo(true);
    });
  };

  const debouncedPlayerSecDetails = _.debounce(getPlayerSecDetails, 3000);
  const PlayerSecDetails = (playerSecId) => {
    if (playerSecId.length > 1) {
      playerSecValue = playerSecId;
      debouncedPlayerSecDetails();
    }
  };
  // --------------------------------------------------------------------
  // ----------------- Player Second Functionality ----------------------
  // --------------------------------------------------------------------

  // --------------------------------------------------------------------
  // ----------------- Player Third Functionality -----------------------
  // --------------------------------------------------------------------

  let playerThiValue;
  const getPlayerthiDetails = () => {
    const userDetailsUrl = `${environment.baseUrl}filter-user/${playerThiValue}`;
    axios.get(userDetailsUrl).then((response) => {
      setPlayerThreeData(response.data[0]);
    });
  };

  const debouncedPlayerThiDetails = _.debounce(getPlayerthiDetails, 3000);
  const PlayerThirDetails = (playerSecId) => {
    if (playerSecId.length > 0) {
      playerThiValue = playerSecId;
      debouncedPlayerThiDetails();
    }
  };
  // --------------------------------------------------------------------
  // ----------------- Player Third Functionality -----------------------
  // --------------------------------------------------------------------

  // --------------------------------------------------------------------
  // ----------------- Player Fourth Functionality -----------------------
  // --------------------------------------------------------------------
  let playerFouValue;
  const getPlayerfouDetails = () => {
    const userDetailsUrl = `${environment.baseUrl}filter-user/${playerFouValue}`;
    axios.get(userDetailsUrl).then((response) => {
      setPlayerFourData(response.data[0]);
    });
  };

  const debouncedPlayerFouDetails = _.debounce(getPlayerfouDetails, 3000);
  const PlayerFouDetails = (playerSecId) => {
    if (playerSecId.length > 0) {
      playerFouValue = playerSecId;
      debouncedPlayerFouDetails();
    }
  };
  // --------------------------------------------------------------------
  // ----------------- Player Fourth Functionality -----------------------
  // --------------------------------------------------------------------

  const handleClearSearchPlayerTwo = (e) => {
    e.preventDefault();
    setPlayerTwoData();
    setClearSearchPlayerTwo(false);
  };

  const submitSplitPoint = (totalSharePoints) => {
    setSplitError(false);
    const player1Data = JSON.parse(localStorage.getItem("player1"));
    const player2Data = JSON.parse(localStorage.getItem("player2"));
    const player3Data = JSON.parse(localStorage.getItem("player3"));
    const player4Data = JSON.parse(localStorage.getItem("player4"));

    let combineSplitPoints = [
      player1Data,
      player2Data,
      player3Data,
      player4Data,
    ];

    combineSplitPoints = combineSplitPoints.filter((x) => x);

    let totalProvidedPoints = combineSplitPoints.reduce(function (
      accumulator,
      curValue
    ) {
      return accumulator + curValue.point;
    },
      0);

    if (totalProvidedPoints > totalSharePoints) {
      setSplitError(true);
      return;
    }

    let totalPointsExceptP1 = totalProvidedPoints - player1Data.point;
    combineSplitPoints[0].point = totalPointsExceptP1;
    combineSplitPoints[0].bookingId = transactionId;

    const splitPointUrl = `${environment.baseUrl}loyaltypoint/split-loyaltypoint/`;
    axios.post(splitPointUrl, combineSplitPoints).then((response) => {
      localStorage.removeItem("player1");
      localStorage.removeItem("player2");
      localStorage.removeItem("player3");
      localStorage.removeItem("player4");
      swal({
        icon: "success",
        content: (
          <div>
            <h3 className="w-100">
              Points are sucessfully shared/splited between players
            </h3>
          </div>
        ),
      }).then(() => {
        setSpinner(false);
        toggleModal();
      });
    });
  };

  const submitSplitPointAdmin = (totalSharePoints) => {
    setSplitError(false);
    const player1Data = JSON.parse(localStorage.getItem("player1"));
    const player2Data = JSON.parse(localStorage.getItem("player2"));
    const player3Data = JSON.parse(localStorage.getItem("player3"));
    const player4Data = JSON.parse(localStorage.getItem("player4"));

    let combineSplitPoints = [
      player1Data,
      player2Data,
      player3Data,
      player4Data,
    ];

    combineSplitPoints = combineSplitPoints.filter((x) => x);

    let totalProvidedPoints = combineSplitPoints.reduce(function (
      accumulator,
      curValue
    ) {
      return accumulator + curValue.point;
    },
      0);

    if (totalProvidedPoints > totalSharePoints) {
      setSplitError(true);
      return;
    }

    combineSplitPoints[0].bookingId = transactionId;
    combineSplitPoints[0].adminId = bookingDetailsSingle?.user?.id;

    const splitPointUrl = `${environment.baseUrl}loyaltypoint/split-loyaltypoint/`;
    axios.post(splitPointUrl, combineSplitPoints).then((response) => {
      localStorage.removeItem("player1");
      localStorage.removeItem("player2");
      localStorage.removeItem("player3");
      localStorage.removeItem("player4");
      swal({
        icon: "success",
        content: (
          <div>
            <h3 className="w-100">Points are sucessfully shared/splited between players</h3>
          </div>
        ),
      }).then(() => {
        setSpinner(false);
        toggleModal();
      });
    });
  };
  // ----------------------------------------------------------Sharing Admin LoyaltyPoints----------------------------------------------------------------------------------

  const getAdminBookedUser = async () => {
    const getUserIdUrl = `${environment.baseUrl}admin_booking/${transactionId}`;
    await axios.get(getUserIdUrl).then(async (response) => {
      if (response.data.msg !== "Data is not available") {
        const getUserIdUrl = `${environment.baseUrl}filter-user/${response?.data?.customer_email}`;
        await axios.get(getUserIdUrl).then(async (res) => {
          setAdminBookingUserDetails(res?.data[0]);
        })
      }
    })
  };

  const ShareAdminLoyaltyPoints = async () => {

    const getUserIdUrl = `${environment.baseUrl}admin_booking/${transactionId}`;
    await axios.get(getUserIdUrl).then(async (response) => {

      const getUserIdUrl = `${environment.baseUrl}filter-user/${response?.data?.customer_email}`;
      await axios.get(getUserIdUrl).then(async (res) => {
        setAdminBookingUserDetails(res?.data[0]);


        const payload = {
          "to": res?.data[0]?.id,
          "from": 1,
          "points": bookedSlots?.length * 12,
          "bookingId": transactionId
        }

        const GetShareLoyaltyPointsUrl = `${environment.baseUrl}loyaltypoint/loyaltyshare/`;

        const result = await axios.post(GetShareLoyaltyPointsUrl, payload).then((response) => {
          if (response.data.Message === "loyaltypoints shared successfully") {
            UpdateGameAfterShare(res?.data[0]?.id);
          }
          else {
            console.log("Error whiile sharing points");
          }
        })
      })
      return;
    })
  };

  const UpdateGameAfterShare = async (userId) => {
    const UpdateGameAfterShareUrl = `${environment.baseUrl}loyaltypoint/update-loyaltygames-admin-share/`;
    const payload = {
      "userId": userId,
      "slots": bookedSlots?.length,
    };
    const result = await axios.post(UpdateGameAfterShareUrl, payload)
  }

  const SplitPoints = () => {
    swal({
      title: `Eligible Points to Earn: ${bookedSlots?.length * 12}`,
      text: `Booking ID: #${transactionId}`,
      content: (
        <div>
          <p>Click YES to send the points to the customer</p>
        </div>
      ),
      icon: "warning",
      // buttons: true,
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((data) => {
      if (data) {
        setViewSplitPoints(true);
        ShareAdminLoyaltyPoints();
        ShareAdminLoyalty();
        swal({
          title: "Points sent successfully",
          icon: "success",
          // buttons: true,
          buttons: ["View Client Details", "Close"],
          // dangerMode: true,
        }).then((condition) => {
          if (condition) {
            VerifyAdminLoyaltyPointShare(transactionId);
            setUpdateAdminBookings(true);
            loyaltypoints();
            PointSplitable();
            setActiveTab("7");
          } else {
            setActiveTab("3");
          }
        })

      } else {
        swal({
          content:
            <div>
              <h4>LoyaltyPoints not shared</h4>
            </div>
        });
      }
    });
  };


  const ShareAdminLoyalty = async () => {
    const ShareAdminLoyaltyURL = `${environment.baseUrl}loyaltypoint/share-admin-loyalty/`;
    const payload = { "bookingId": transactionId }
    const result = await axios.post(ShareAdminLoyaltyURL, payload)
  };

  const VerifyAdminLoyaltyPointShare = async (transactionId) => {
    const ShareAdminLoyaltyURL = `${environment.baseUrl}loyaltypoint/verify-share-admin-loyalty/${transactionId}/`;
    const result = await axios.get(ShareAdminLoyaltyURL).then((sharedResponse) => {
      setSharedCompleted(sharedResponse.data);
    })
  };

  const GetCoachAndStaffDetails = async (slots) => {

    const Payload = {
      "bookingId": `${transactionId}`
    }
    const getCoachAndStaffDetailsURL = `${environment.baseUrl}staffmanagement/player-booking/`;
    await axios.post(getCoachAndStaffDetailsURL, Payload).then((response) => {
      if (response?.data[0]?.coaches) {
        setCoachStaff(response?.data[0]?.coaches);
        setCoachOrStaffPrice(response?.data[0]?.coaches?.Pricing * slots);
      } else if (response?.data[0]?.staffid) {
        setCoachStaff(response?.data[0]?.staffid);
        setCoachOrStaffPrice(response?.data[0]?.staffid?.Pricing * slots);
      } else {

      }
    })
  };


  return (
    <>
      {spinner && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}

      <div className="tabs__wrap">
        <Nav tabs className="all-tabs" style={{ width: "100%" }}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggle("1")}
            >
              Bookings
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ width: 150 }}
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
                DiscountedPrice();
                GetCoachAndStaffDetails(noOfSlots);
              }}
            >
              Transactions
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggle("3")}
            >
              Client
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggle("4")}
            >
              Edit
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ width: 150 }}
              className={classnames({ active: activeTab === "5" })}
              onClick={() => toggle("5")}
            >
              Bulk Booking
            </NavLink>
          </NavItem>
          {/* <NavItem onClick={() => handleAddons()}>
            <NavLink style={{ width: 130 }}
              className={classnames({ active: activeTab === '6' })}
              onClick={() => toggle('6')}
            >
              Add ons
            </NavLink>
          </NavItem> */}
          <NavItem onClick={() => [loyaltypoints(), PointSplitable()]}>
            <NavLink
              style={{ width: 180 }}
              className={classnames({ active: activeTab === "7" })}
              onClick={() => toggle("7")}
            >
              Share/Split Points
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              style={{ width: 180 }}
              className={classnames({ active: activeTab === "8" })}
              onClick={() => toggle("8")}
            >
              Sales Record
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="detail-modal-container">
              <div className="inner-modal-container">
                <div className="container-lhs">
                  <div className="time-date-container">
                    <div className="court-name">
                      <div className="court-name-edit">
                        <RiUser3Line className="user-icon" />
                        <h5 className="court-name-text">
                          {bookingDetailsSingle?.main_court?.courtName}-Court{" "}
                          {bookingDetailsSingle?.padel_court?.courtname}
                        </h5>
                        <FiEdit className="user-edit-icon" />
                      </div>
                    </div>

                    <div className="calender-time-date">
                      <div className="calender-icon">
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="cal-icon"
                        />
                        <h4>{bookingDetailsSingle?.booking_date}</h4>
                      </div>
                      <h4>{timeToPlay}</h4>
                    </div>
                    <div className="calender-time-date">
                      <div className="calender-icon">
                        <FontAwesomeIcon
                          icon={faHourglassHalf}
                          className="cal-icon"
                        />
                        <h4 className="cal-time">{hoursToPlay}</h4>
                      </div>
                      <h4>( Service duration: 45 mins. )</h4>
                    </div>
                  </div>
                </div>
                <div className="container-rhs">
                  <div className="payment-details">
                    <label className="payment-details-text">
                      Invoice date:{" "}
                    </label>{" "}
                    {"  "}
                    <label className="payment-details-result">
                      {moment(
                        bookingDetailsSingle?.created_at.split("T")[0]
                      ).format("DD-MM-YYYY")}{" "}
                      {moment(
                        bookingDetailsSingle?.created_at
                          .split("T")[1]
                          .split(".")[0],
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </label>
                  </div>
                  <div className="payment-details">
                    <label className="payment-details-text">Due date: </label>{" "}
                    {"  "}
                    <label className="payment-details-result">
                      {" "}
                      {moment(bookingDetailsSingle?.booking_date).format(
                        "DD-MM-YYYY"
                      )}{" "}
                      {bookingDetailsSingle?.timeslot.split("-")[0]}
                    </label>
                  </div>
                  <div className="payment-details">
                    <label className="payment-details-text">Status: </label>{" "}
                    {"  "}
                    {bookingDetailsSingle?.cancelled ? (
                      <label className="payment-details-result">
                        Cancelled
                      </label>
                    ) : (
                      <label className="payment-details-result">
                        {bookingDetailsSingle?.packages == 0
                          ? localStorage.getItem("AdminPaymentType") != null
                            ? localStorage.getItem("AdminPaymentType")
                            : bookingDetailsSingle?.amount_percentage === 1 ||
                              bookingDetailsSingle?.amount_percentage === 100
                              ? "Paid 100%"
                              : bookingDetailsSingle?.amount_percentage == 2 ? "Pay Later" : "Paid 50%"
                          : "Paid by Packages"}
                      </label>
                    )}
                  </div>
                  <div className="payment-details">
                    <label className="payment-details-text">
                      Payment received:{" "}
                    </label>{" "}
                    {"  "}
                    <label className="payment-details-result">
                      {bookingDetailsSingle?.payment_status === "Failed"
                        ? "0.00"
                        : bookingDetailsSingle?.amount_paid.toFixed(2)}{" "}
                      AED
                    </label>
                  </div>
                  <div className="payment-details">
                    <label className="payment-details-text">Platform:</label>{" "}
                    {"  "}
                    <label
                      className="payment-details-result"
                      style={{ textTransform: "capitalize" }}
                    >
                      {" "}
                      {bookingDetailsSingle?.boooked_using}
                    </label>
                  </div>
                  <div className="payment-details">
                    <label className="payment-details-text">Category:</label>{" "}
                    {"  "}
                    <label
                      className="payment-details-result"
                      style={{ textTransform: "capitalize" }}
                    >
                      {bookingDetailsSingle?.booking_category}{" "}
                    </label>
                  </div>
                  {/* <div className="payment-details">
                    <FaMoneyBillWave className="money-icon" />
                    <h5 className="court-money-text">price {bookingDetailsSingle?.main_court?.price.toFixed(2)}AED</h5>
                  </div> */}
                </div>
              </div>
            </div>
            {(promotionUsed && bookedSlots.length > 0) && <div className="d-flex">
              <img src="https://padelgram-media.s3.ap-south-1.amazonaws.com/promotion-verified.png" alt=" Promotion Used Image" style={{ height: '25px', width: '25px' }} />
              <label style={{ fontSize: 17, fontWeight: "bold", marginLeft: "10px" }}>Promotions Used</label>
            </div>}

            {coachStaff && (
              <div style={{ marginBottom: "15px" }}>
                <div className="user-datails-lhs">
                  <h5>Coach/Staff Details</h5>
                </div>

                <div className="innerContainer">
                  <div>
                    <img
                      src={coachStaff?.image}
                      alt="Coach/Staff Image"
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "70px",
                        marginRight: "20px",
                      }}
                    />
                  </div>

                  <div style={{ alignself: "center" }}>
                    <text className="username">{coachStaff?.name}</text>

                    <div className="nationalty">
                      <text
                        className="details"
                        style={{ textTransform: "capitalize" }}
                      >
                        {coachStaff?.nationality}
                        {" | "}{" "}
                      </text>
                      <text style={{ color: "#4B4B4B" }}>
                        {coachStaff?.languages &&
                          JSON.parse(coachStaff?.languages).map((language, index) => {
                            return (
                              <text
                                key={index}
                                className="details"
                                style={{
                                  textTransform: "capitalize",
                                  marginLeft: "3px",
                                }}
                              >
                                {index > 0 ? ", " : " "}
                                {language?.value}
                              </text>
                            );
                          })}
                      </text>
                    </div>

                    {
                      bookedUsing !== "app-personal-coach" &&
                      <div>
                        <text className="pricedetails">
                          AED {coachStaff?.Pricing.toFixed(2)}
                        </text>
                      </div>
                    }
                  </div>
                </div>
              </div>
            )}

            <div className="booking-id">
              <label style={{ fontSize: 17, fontWeight: "bold" }}>
                Booking ID:
              </label>
              <label style={{ fontSize: 17, fontWeight: "bold" }}>
                &nbsp;&nbsp;#{transactionId}
              </label>
            </div>

            <div className="comment-container">
              <div className="comment-container-inner">
                {createdOrEditedLogs?.length > 0
                  ? createdOrEditedLogs &&
                  createdOrEditedLogs?.map((data, index) => {
                    return (
                      <>
                        <div className="user-comments">
                          <RiFileEditLine className="editFile-comments-icon" />
                          <div className="edited-user">
                            <span>{data?.created_or_edited_by}</span>
                            <span>
                              {data?.created_or_edited_date}{" "}
                              {data?.created_or_edited_time}
                            </span>
                          </div>
                        </div>
                        {index === createdOrEditedLogs.length - 1 ? (
                          ""
                        ) : (
                          <hr className="edit-middle-line" />
                        )}
                      </>
                    );
                  })
                  : ""}
              </div>

              {commentsData?.slice(-1)?.map((data, index) => {
                return (
                  <div className="commentData">
                    <div className="commentData-lhs">
                      <FaRegComments className="comment-Icon" />
                      <p>{data?.comment}</p>
                    </div>
                    <div className="commentData-rhs">
                      <p style={{ marginTop: 5, marginRight: 5 }}>
                        {data?.date}
                      </p>
                      <p style={{ marginRight: 5 }}>{data?.time}</p>
                      <p> {data?.user ? "- Created by " + data?.user : ""}</p>
                    </div>
                  </div>
                );
              })}
              {showAllCommen ? (
                <div className="show-hide-container">
                  {slice?.reverse().map((data, index) => {
                    return (
                      <div className="commentData">
                        <div className="commentData-lhs">
                          <FaRegComments className="comment-Icon" />
                          <p>{data?.comment}</p>
                        </div>
                        <div className="commentData-rhs">
                          <p style={{ marginTop: 5, marginRight: 5 }}>
                            {data?.date}
                          </p>
                          <p style={{ marginRight: 5 }}>{data?.time}</p>
                          <p>
                            {" "}
                            {data?.user ? "- Created by " + data?.user : ""}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              <div className="show-all-edits">
                <AiOutlineEye className="eye-icon" />
                {showAllCommen ? (
                  <span className="show-all-text" onClick={() => ClickShow()}>
                    Hide All Comments
                  </span>
                ) : (
                  <span className="show-all-text" onClick={() => ClickShow()}>
                    Show All Comments
                  </span>
                )}
                {bookingDetailsSingle?.cancelled ? (
                  ""
                ) : (
                  <>
                    {" "}
                    <RiPencilLine className="pencil-icon" />{" "}
                    <span className="show-all-text" onClick={ShowModal}>
                      Comments
                    </span>{" "}
                  </>
                )}
              </div>
              <CommentModal
                userCommentModal={showCommentModal}
                bookingId={transactionId}
                commentRefresh={CommentRefresh}
                header
                color="primary"
                title="Booking Comment"
              />
            </div>
            <Col md={12} lg={12}>
              <div className="buttons-containerr">
                <div>
                  {
                    bookingDetailsSingle?.boooked_using == "admin" && bookingDetailsSingle?.booking_category == "Bookings" ? sharedCompleted?.eligibility ? <button className="btn btn-success"
                      onClick={() => SplitPoints()}>
                      Complete
                    </button> : <button className="btn btn-success">
                      Completed
                    </button>
                      : ''
                  }
                  {/* {pointSplitable ?
                    (bookingDetailsSingle?.boooked_using == "admin" &&
                    <button className="btn btn-success"
                      onClick={() => SplitPoints()}>
                      Complete
                    </button>)
                    :
                    (bookingDetailsSingle?.boooked_using == "admin" &&
                    <button className="btn btn-success">
                      Completed
                    </button>)
                  } */}
                </div>
                <div>
                  <button className="btn-close" onClick={toggleModal}>
                    Close
                  </button>
                  <button
                    className="btn-cancel-booking"
                    onClick={() =>
                      CancelBookings(transactionId, createdOrEditedLogs)
                    }
                  >
                    Cancel Booking
                  </button>
                  <button className="btn-edit" onClick={() => toggle("4")}>
                    Edit
                  </button>
                </div>
              </div>
            </Col>
          </TabPane>
          <TabPane tabId="2">
            {/* <BookingTransactionTab
              bookingId={transactionId}
              toggleModal={toggleModal}
              toggle={toggle}
              bookingDetails={bookingDetailsSingle}
              cancelledBookingDetails={logs}
              currentBookingDetails={bookedSlots}
              logs={createdOrEditedLogs}
              comments={commentsData}
              bookedUsing={bookedUsing}
            /> */}
            <BookingDetailTransactionTabPane bookingDetails={bookingDetailsNew} bookingId={transactionId} toggle={toggleModal} cancelBoooking={CancelBookings} toggletab={toggle} bookedUsing={bookingDetailsNew?.bookedUsing} />
          </TabPane>
          <TabPane tabId="3">
            <h2 className="client-info">Client Info</h2>
            {bookingDetailsSingle?.user?.role === "customer" ? (
              <BookingDetailsUserTabTable
                customer_name={bookingDetailsSingle?.user?.first_name}
                customer_email={bookingDetailsSingle?.user?.email}
                customer_phone={bookingDetailsSingle?.user?.phone_no}
              />
            ) : (
              <BookingDetailsTabTable bookingID={transactionId} />
            )}
          </TabPane>
          <TabPane tabId="4">
            <Table responsive hover>
              <div className="Booking-details-edit-container">
                {bookingDetailsSingle?.user?.role === "customer" ? (
                  <BookingDetailsUserEditForm
                    booking_id={transactionId}
                    userId={bookingDetailsSingle?.user?.id}
                    client={bookingDetailsSingle?.user?.first_name}
                    location={bookingDetailsSingle?.main_court?.id}
                    service_provider={bookingDetailsSingle?.padel_court?.id}
                    booking_date={bookingDetailsSingle?.booking_date}
                    customer_email={bookingDetailsSingle?.user?.email}
                    customer_phone={bookingDetailsSingle?.user?.phone_no}
                    cancellationTime={timeToPlay}
                    amountpaid={bookingDetailsSingle?.amount_paid}
                    toggleModal={toggleModal}
                  />
                ) : (
                  <BookingDetailsAdminEditForm
                    bookingID={transactionId}
                    formDatas={formData}
                    location={bookingDetailsSingle?.main_court?.id}
                    service_provider={bookingDetailsSingle?.padel_court?.id}
                    booking_date={bookingDetailsSingle?.booking_date}
                    cancellationTime={timeToPlay}
                    toggleModal={toggleModal}
                  />
                )}
              </div>
            </Table>
          </TabPane>
          <TabPane tabId="5">
            <BulkBooking prePopulatedDated={prePopulatedDated} />
          </TabPane>
          <TabPane tabId="6">
            <h2 className="client-info">Add ons Booking</h2>
            {clickAddonView ? (
              <AddonsComponent
                bookingId={transactionId}
                addonsData={bookingDetailsSingle?.addons}
                finalAddonList={finalAddonList}
                closeModal={toggleModal}
                amountpaid={bookingDetailsSingle?.amount_paid}
                amountPercentage={bookingDetailsSingle?.amount_percentage}
              />
            ) : (
              "No"
            )}
          </TabPane>
          <TabPane tabId="7">
            <div className="detail-modal-container">
              <div className="inner-modal-container">
                <div className="container-lhs">
                  <div className="time-date-container">
                    <div className="calender-time-date">
                      <div className="calender-icon">
                        {/* <h4>{"Booking ID: #86774"}</h4> */}
                        <h4>{`Booking ID: #${transactionId}`}</h4>
                      </div>
                    </div>

                    <div className="calender-time-date">
                      <div className="calender-icon">
                        <h4>
                          {`Rewards Earned: ${(bookedUsing == "app-personal-coach" ? 0 : bookingDetailsSingle?.boooked_using == "admin" ? (!promotionUsed && bookingDetailsSingle?.packages == 0 && bookingDetailsSingle?.coupan_applied == "" && !sharedCompleted?.eligibility)
                            :
                            (!promotionUsed && bookingDetailsSingle?.packages == 0 && bookingDetailsSingle?.coupan_applied == ""))
                            ? bookedSlots?.length * 12
                            : 0
                            } Points`}
                        </h4>
                      </div>
                    </div>

                    <div className="calender-time-date">
                      <div className="calender-icon">
                        <h4>{`No. of Session: ${bookedSlots?.length} Sessions`}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-rhs">
                  <div className="payment-details">
                    <label className="payment-details-text">
                      Invoice date:{" "}
                    </label>{" "}
                    {"  "}
                    <label className="payment-details-result">
                      {moment(
                        bookingDetailsSingle?.created_at.split("T")[0]
                      ).format("DD-MM-YYYY")}{" "}
                      {moment(
                        bookingDetailsSingle?.created_at
                          .split("T")[1]
                          .split(".")[0],
                        "HH:mm:ss"
                      ).format("hh:mm A")}
                    </label>
                  </div>
                  <div className="payment-details">
                    <label className="payment-details-text">Due date: </label>{" "}
                    {"  "}
                    <label className="payment-details-result">
                      {" "}
                      {moment(bookingDetailsSingle?.booking_date).format(
                        "DD-MM-YYYY"
                      )}{" "}
                      {bookingDetailsSingle?.timeslot.split("-")[0]}
                    </label>
                  </div>
                  <div className="payment-details">
                    <label className="payment-details-text">Status: </label>{" "}
                    {"  "}
                    {bookingDetailsSingle?.cancelled ? (
                      <label className="payment-details-result">
                        Cancelled
                      </label>
                    ) : (
                      <label className="payment-details-result">
                        {bookingDetailsSingle?.packages == 0
                          ? localStorage.getItem("AdminPaymentType") != null
                            ? localStorage.getItem("AdminPaymentType")
                            : bookingDetailsSingle?.amount_percentage === 1 ||
                              bookingDetailsSingle?.amount_percentage === 100
                              ? "Paid 100%"
                              : bookingDetailsSingle?.amount_percentage == 2 ? "Pay Later" : "Paid 50%"
                          : "Paid by Packages"}
                      </label>
                    )}
                  </div>
                  <div className="payment-details">
                    <label className="payment-details-text">
                      Payment received:{" "}
                    </label>{" "}
                    {"  "}
                    <label className="payment-details-result">
                      {bookingDetailsSingle?.payment_status === "Failed"
                        ? "0.00"
                        : bookingDetailsSingle?.amount_paid.toFixed(2)}{" "}
                      AED
                    </label>
                  </div>
                  <div className="payment-details">
                    <label className="payment-details-text">
                      Booking made via:
                    </label>{" "}
                    {"  "}
                    <label
                      className="payment-details-result"
                      style={{ textTransform: "capitalize" }}
                    >
                      {" "}
                      {bookingDetailsSingle?.boooked_using}
                    </label>
                  </div>
                </div>
              </div>
              <Col md={12} lg={12}>
                <AdminLoyaltyDeatils transactionId={transactionId} updateAdminBookings={updateAdminBookings} />
              </Col>

              {
                !promotionUsed && bookingDetailsSingle?.packages == 0 && bookingDetailsSingle?.coupan_applied == "" && bookingDetailsSingle?.booking_category == "Bookings" &&
                (<span className="border-bottom border-2 mt-3 mr-3 mb-3"></span>)
              }
              {
                !promotionUsed && bookingDetailsSingle?.packages == 0 && bookingDetailsSingle?.coupan_applied == "" && pointSplitable && bookingDetailsSingle?.booking_category == "Bookings" &&
                bookedUsing !== "app-personal-coach" &&
                (
                  <Col md={12} lg={12}>
                    <div className="d-flex flex-row align-items-baseline">
                      <p className="font-weight-bold pr-2">
                        Share points with Friends:
                      </p>{" "}
                      <p className="font-weight-bold Eligible pr-2">{`(Eligible to Split: ${bookedSlots?.length * 12
                        })`}</p>
                      <div>
                        <p className="edit">Edit</p>
                      </div>
                    </div>
                  </Col>
                )
              }
            </div>
            {
              !promotionUsed && bookingDetailsSingle?.packages == 0 && bookingDetailsSingle?.coupan_applied == "" && bookingDetailsSingle?.booking_category == "Bookings" && bookedUsing !== "app-personal-coach" &&

              (
                (bookingDetailsSingle?.boooked_using == "admin" ? (loyaltypoint?.loyaltyavailable > 0 && pointSplitable && !sharedCompleted.eligibility) : (loyaltypoint?.loyaltyavailable > 0 && pointSplitable)) ? (

                  <>
                    {bookingDetailsSingle?.boooked_using !== "admin" ? (
                      <Col md={12} lg={12} className="mt-2">
                        {bookingDetailsSingle && (
                          <SplitPointComponentPlayer1
                            playerId={1}
                            userId={bookingDetailsSingle?.user?.id}
                            name={bookingDetailsSingle?.user?.first_name}
                            email={bookingDetailsSingle?.user?.email}
                            phone={bookingDetailsSingle?.user?.phone_no}
                            totalPoints={bookedSlots?.length * 12}
                          />
                        )}
                      </Col>
                    ) : (
                      <Col md={12} lg={12} className="mt-2">
                        <SplitPointComponentPlayer1
                          playerId={1}
                          userId={adminBookingUserDetails?.id}
                          name={adminBookingUserDetails?.first_name}
                          email={adminBookingUserDetails?.email}
                          phone={adminBookingUserDetails?.phone_no}
                          totalPoints={bookedSlots?.length * 12}
                        />
                      </Col>
                    )}
                    <Col md={12} lg={12} className="mt-2">
                      <SplitPointComponent
                        playerId={2}
                        totalPoints={bookedSlots?.length * 12}
                      />
                    </Col>
                    <Col md={12} lg={12} className="mt-2">
                      <SplitPointComponent
                        playerId={3}
                        totalPoints={bookedSlots?.length * 12}
                      />
                    </Col>
                    <Col md={12} lg={12} className="mt-2">
                      <SplitPointComponent
                        playerId={4}
                        totalPoints={bookedSlots?.length * 12}
                      />
                    </Col>
                    <Col md={12} lg={12} className="mt-2 d-flex ">
                      {/* <Row> */}
                      <Col md={8} lg={8}>
                        <p className="text-danger">
                          {splitError &&
                            "You can't split point, point is exceed the earned points"}
                        </p>
                      </Col>
                      <Col
                        md={4}
                        lg={4}
                        className="mt-2 d-flex justify-content-end"
                      >
                        {bookingDetailsSingle?.boooked_using !== "admin" ? (
                          <button
                            className="btn btn-warning "
                            onClick={() =>
                              submitSplitPoint(bookedSlots?.length * 12)
                            }
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            className="btn btn-warning "
                            onClick={() =>
                              submitSplitPointAdmin(bookedSlots?.length * 12)
                            }
                          >
                            Submit
                          </button>
                        )}
                      </Col>
                      {/* </Row> */}
                    </Col>
                  </>
                ) : (
                  <Col md={12} lg={12} className="mt-2">
                    <h3 className="text-danger w-100">
                      {/* {bookingDetailsSingle?.boooked_using == "admin" && viewSplitPoints ? (loyaltypoint?.loyaltyavailable <= 0 ? "You're current point is less, you can't share points." : "The Points are already splited/shared.") : "Point is not provided to user"} */}
                      {
                        bookingDetailsSingle?.boooked_using == "admin" ?
                          (!sharedCompleted?.eligibility ? loyaltypoint?.loyaltyavailable <= 0 ? "You're current point is less, you can't share points." : "The Points are already splited/shared." : "Point is not provided to user")
                          :
                          (loyaltypoint?.loyaltyavailable <= 0 ? "You're current point is less, you can't share points." : "The Points are already splited/shared.")

                      }

                    </h3>
                  </Col>
                ))
            }
          </TabPane>
          {/* <TabPane tabId="8">
          <SalesRecord />
          </TabPane> */}
        </TabContent>
      </div>
    </>
  );
};

export default BookingDetailsTab;
