/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Col, Row, Card, CardBody, Button,
} from 'reactstrap';
import axios from '../../customAxios';
import { useHistory } from 'react-router-dom';
import { environment } from '../env';
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import { AiFillEye, AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import moment from 'moment';
import GiftCardModal from './GiftCardModal';
import ReceiverDetails from './ReceiverDetails';



const GiftCard = () => {
    const [giftCardData, setGiftCardData] = useState([]);
    const [isSortable, setIsSortable] = useState(true);
    const [isResizable, setIsResizable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [view, setView] = useState(false);
    const [transactionLog, setTransactionLog] = useState();

    const giftcardDetailsURL = `${environment.baseUrl}giftcard/`;
    const history = useHistory();

    const getGiftCardData = async () => {
        const result = await axios(giftcardDetailsURL);
        setGiftCardData(result.data);

    };

    useEffect(() => {
        getGiftCardData();
        if (localStorage.getItem('Token')) {
        } else {
            alert('You are not logged In. Please LogIn');
            history.push('/');
        }
    }, []);


    var jsonObj2 = [];
    for (let i = 0; i < giftCardData.length; i++) {
        var item = {}
        item["id"] = i + 1;
        item["sender_name"] = giftCardData[i].name === "null" ? '' : giftCardData[i].sender_name;
        item["receiver_name"] = (
            <div className="Booking-details-td-right-space">
                <ReceiverDetails name={giftCardData[i].receiver_name}
                    email={giftCardData[i].receiver_email}
                    phone={giftCardData[i].phone} />
            </div>
        )
        item["receiver_email"] = giftCardData[i].receiver_email;
        item["select_gift_type"] = (giftCardData[i].select_gift_type).split("-")[0];
        item["quantity"] = giftCardData[i].quantity.toString();
        item["total_amount"] = giftCardData[i].total_amount.toString();
        item["created_date"] = <div className="right-space">{moment(giftCardData[i].created_date).format("DD-MM-YYYY")} <br />
            {moment(giftCardData[i].created_date.split('T')[1], "HH:mm:ss").format("hh:mm A")}
        </div>;
        item["payment_status"] = <div className="right-space text-capitalize">{giftCardData[i].payment_status === "completed" ? <h3 className='amountStatusCompleted'>Completed</h3> : <h3 className='amountStatusCancelled mt-1'>Pending</h3>}</div>;
        item["view"] = <div className="right-space">

            <button className='modal-view-btn'>
                <AiFillEye style={{ fontSize: 20, height: "19px", width: "19px" }} />
            </button>
        </div>;
        item["view"] = <div className="right-space">

            <button className='modal-view-btn' onClick={() => OpenModal(giftCardData[i].transaction_log)}>
                <AiFillEye style={{ fontSize: 20, height: "19px", width: "19px" }} />
            </button>
        </div>;
        jsonObj2.push(item);
    }

    const handleViewDetails = (id) => {
        history.push(`user_detailss/${id}`);
    }

    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };

    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };

    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const tableConfig = {
        isEditable: false,
        isResizable,
        isSortable,
        withPagination,
        withSearchEngine,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by Name...',
    };

    const newdata = {
        columns: [
            {
                Header: '#',
                accessor: 'id',
                disableGlobalFilter: true,
                width: 10,
            },
            {
                Header: 'Date',
                accessor: 'created_date',
                disableGlobalFilter: true,
                width: 65,
            },
            {
                Header: 'Sender',
                accessor: 'sender_name',
                disableGlobalFilter: false,
                width: 70,
            },
            {
                Header: 'Receiver',
                accessor: 'receiver_name',
                disableGlobalFilter: true,
                width: 100,
            },
            {
                Header: 'Type',
                accessor: 'select_gift_type',
                disableGlobalFilter: false,
                width: 60,
            },
            {
                Header: 'Qty',
                accessor: 'quantity',
                disableGlobalFilter: false,
                width: 40,
            },
            {
                Header: 'Amount',
                accessor: 'total_amount',
                disableGlobalFilter: false,
                width: 70,
            },
            {
                Header: 'Payment Status',
                accessor: 'payment_status',
                disableGlobalFilter: true,
                disableSortBy: true,
                width: 70,
            },
            {
                Header: 'View',
                accessor: 'view',
                disableGlobalFilter: true,
                disableSortBy: true,
                width: 30,
            }

        ],
        data: jsonObj2
    };

    const OpenModal = (transactionLogData) => {
        setView();
        setTransactionLog();
        setView(!view);
        if (transactionLogData !== "no transaction log") {
            setTransactionLog(JSON.parse(transactionLogData)[0]);
        } else {
            setTransactionLog({ "Data": "No Transaction log" })
        }
    }
    const CloseModal = () => {
        setView(false);
    }

    return (
        <Fragment>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Gift Card</h3>
                </Col>
            </Row>
            <Col md={12} lg={12}>
                <GiftCardModal transactionLog={transactionLog} show={view} CloseModal={CloseModal} color="primary" header title="Transaction Details" />
                <Card>
                    <CardBody>
                        <div className="react-table__wrapper hide-table-costomizer">
                            <ReactTableCustomizer
                                handleClickIsSortable={handleClickIsSortable}
                                handleClickWithPagination={handleClickWithPagination}
                                handleClickWithSearchEngine={handleClickWithSearchEngine}
                                handleClickIsResizable={handleClickIsResizable}
                                isResizable={isResizable}
                                isSortable={isSortable}
                                withPagination={withPagination}
                                withSearchEngine={withSearchEngine}
                            />
                        </div>

                        <div className="hover-effect table-id-width">
                            <ReactTableBase
                                key={withSearchEngine ? 'searchable' : 'common'}
                                columns={newdata.columns}
                                data={newdata.data}
                                tableConfig={tableConfig}
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}

export default GiftCard