import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import ExamplePageOne from '../Example';
import ExamplePageTwo from '../ExampleTwo/index';
import MainCourt from '../MainCourt/index';
import CourtType from '../CourtType/index';
import CourtCategory from '../CourtCategory';
import Court from '../Court';
import Addons from '../Addons';
import AllCourts from '../AllCourts';
import AllCourtsEdit from '../AllCourtsEdit';
import EditCourtTypeCard from '../EditCourtType';
import ListCourtTypeCard from '../ListCourtType';
import EditCourtCategory from '../EditCourtCategory';
import EditAddons from '../EditAddons';
import ListAddons from '../ListAddons';
import ListIndex from '../ListMainCourt';
import EditMainCourtIndex from '../EditMainCourt';
import UsersIndex from '../Users';
import BookingIndex from '../Booking Details';
import ListCourtCategory from '../ListCourtCategory';
import BookingCancel from '../Booking Cancellation Details';
import Rating from '../Rating';
import ListSlides from '../ListSlides';
import Slides from '../Slides';
import EditSlides from '../EditSlides';
import Padelholic from '../Padelholic';
import ListPadelholic from '../ListPadelholic';
import EditPadelholic from '../EditPadelholic';
import ManageTimeSlot from '../ManageTimeSlot';
import CourtBooking from '../CourtBooking';
import CourtBookingSingle from '../CourtBookingSingle';
import AddonsBooking from '../AddonsBooking';
import CustomerDetails from '../CustomerDetails';
import Role from '../Role';
import CreateUser from '../CreateUser';
import Calender from '../Calender';
import NotifyMe from '../NotifyMe';
import Transactions from '../Transactions';
import AllCourtsViewDetails from '../AllCourtsViewDetails';
import MainCourtsViewDetails from '../MainCourtsViewDetails';
import Coupon from '../Coupon';
import UserRatings from '../UserRating/';
import CreateCoupon from '../CreateCoupon';
import EditCoupon from '../EditCoupon';
import EditRole from '../EditRole';
import RoleLists from '../RoleList/components/RoleLists';
import Report from '../Report';
import UserDetails from '../UserDetails';
import Refund from '../Refund';
import Table from '../DataTable';
import Resize from '../ResizableTable';
import Getpage from '../GetPage/GetPage';
import AddPage from '../GetPage/AddPage';
import EditPage from '../GetPage/EditPage';
import TransactionDetails from '../TransactionDetails';
import ViewBookingDetails from '../ViewBookingDetails';
import AddOffer from '../AddOffer';
import EditOffer from '../EditOffer';
import OfferList from '../OfferList';
import Miscellenious from '../Miscellenious';
import MiscelleniousList from '../MiscelleniousList';
import MiscelleniousEdit from '../MiscelleniousEdit';
import PushNotification from '../PushNotification';
import Promotions from '../Promotions';
import Package from '../../Package/Package';
import AddPromotions from '../AddPromotions';
import EditPromotion from '../EditPromotion';
import WalletTransactions from '../WalletTransaction';
import NewAddOffer from '../AddOffer/components/NewAddOffer';
import User from '../User/User';
import Quickpay from '../Quickpay/Quickpay';
import QuickPayDetails from '../Quickpay/QuickPayDetails';
import Tournaments from '../Tournaments/Tournaments';
import EmailConfiguration from '../ResetPassword/EmailConfiguration';
import OTPVerification from '../ResetPassword/OTPVerification';
import ResetPassword from '../ResetPassword/ResetPassword';
import AdSpace from '../AdSpace'
import AdSpaceList from '../AdSpace/components/AdSpace-list';
import EditAdSpace from '../AdSpace/components/EditAdSpace';
import CreateNews from '../News/CreateNews';
import GetNews from '../News/GetNews';
import PackageList from '../../Package/PackageList';
import EditPackages from '../../Package/EditPackages';
import EditNews from '../News/EditNews';
import ListLTO from '../LimitedTimeOffer/ListLTO';
import CreateLTO from '../LimitedTimeOffer/CreateLTO';
import EditLTO from '../LimitedTimeOffer/EditLTO';
import UserDetailsCard from '../UserDetails/components/UserDetailsCard';
import ExamplePage from '../UserDetails';
import AdsImpressions from '../AdsImpressions/AdsImpressions';
import UserPackages from '../UserPackages/UserPackages';
import DownloadExcel from '../Booking Details/components/DownloadExcel';
import CalendarSkaleton from '../Skaleton/CalendarSkaleton';
import LivePost from '../Padelgram/LivePost';
import ReportProblem from '../Padelgram/Report';
import DeletedPost from '../Padelgram/DeletedPost/DeletedPost';
import BulkPromo from '../AddPromotions/components';
import KidsSummerCamp from '../KidsSummerCamp';
import Calenderv2 from '../Calenderv2/Calenderv2';
import CreateRewards from '../Rewards/CreateRewards';
import RewardsList from '../Rewards/RewardsList';
import EditRewards from '../Rewards/EditRewards';
import PromotionsV2 from '../PromotionsV2/components/PromotionsV2';
import promotionv2index from '../PromotionsV2';
import PromotionV2List from '../PromotionsV2/PromotionV2List';
import Unsuccess from '../UnsuccessfulTransactions/Unsuccess';
import EditPromotionV2 from '../PromotionsV2/EditPromotionV2'; 
import Coaches from '../Coaches/Coaches';
import CoachesList from '../Coaches/CoachesList';
import EditCoaches from '../Coaches/EditCoaches';
import Staff from '../Staff/Staff';
import StaffList from '../Staff/StaffList';
import EditStaff from '../Staff/EditStaff';
import ManageStaffSlots from '../Staff/ManageStaffSlots';
import StaffCalender from '../Staff/StaffCalender';
import ManageCoachesSlots from '../Coaches/ManageCoachesSlots';
import CoachesCalender from '../Coaches/CoachesCalender';
import GiftCard from '../GiftCard/GiftCard';
import LoyalityPointsManagement from '../LoyalityPoints/LoyalityPointsManagement';
import GenerateSlot from '../GenerateSlots/GenerateSlot';
import CategoriesList from '../MatchCategories/CategoriesList';
import UploadPost from '../Padelgram/UploadPost';
import VerifySkillLevel from '../VerifySkillLevel/VerifySkillLevel';
import List_SlidesV2 from '../ListSlidesV2';
import AddSlidesV2 from '../AddSlidesV2';
import EditSlidesV2 from '../EditSlideV2';
import { GetAvailableSlots } from '../GetAvailableSlots/GetAvailableSlots';
import { GetDoubleBookings } from '../GetDoubleBookings/GetDoubleBookings';
import { DoubleBookingList } from '../GetDoubleBookings/DoubleBookingList';
import { CoupanDocs } from '../CoupanDocs/CoupanDocs';
import { CouponOffers } from '../CouponOffers/CouponOffers';
import { CreateCouponOffer } from '../CouponOffers/CreateCouponOffer';
import { EditCouponOffer } from '../CouponOffers/EditCouponOffer';
import { AddOffersCard } from '../Cards/AddOffersCard';
import { PriceConfigurations } from '../PriceConfigurations/PriceConfigurations';
import { EditPriceConfigurations } from '../PriceConfigurations/EditPriceConfigurations';
import { CouponDocsStatus } from '../CouponDocsStatus/CouponDocsStatus';
import { GetSlotsBookingReport } from '../GetSlotsBookingReport/GetSlotsBookingReport';
import { EventTournamentRegistration } from '../EventTournamentRegistration/EventTournamentRegistration';
import { AddEventTournament } from '../EventTournamentRegistration/AddEventTournament';
import { EditEventTournament } from '../EventTournamentRegistration/EditEventTournament';
import { ViewEventTournament } from '../EventTournamentRegistration/ViewEventTournament';
import { EventRegistrationTabs } from '../EventTournamentRegistration/EventRegistrationTabs';

//components/Promotions
const Pages = () => (
  <Switch>
    <Route path="/one" component={ExamplePageOne} />
    <Route path="/two" component={ExamplePageTwo} />
    <Route path="/main_court" component={MainCourt} />
    <Route path="/list_main_court" component={ListIndex} />
    <Route path="/edit_main_court/:id" component={EditMainCourtIndex} />
    <Route path="/court_type" component={CourtType} />
    <Route path="/court_category" component={CourtCategory} />
    <Route path="/court_court" component={Court} />
    <Route path="/court_addons" component={Addons} />
    <Route path="/all_courts" component={AllCourts} />
    <Route path="/edit_addons/:id" component={EditAddons} />
    <Route path="/list_addons" component={ListAddons} />
    <Route path="/edit_courts/:id" component={AllCourtsEdit} />
    <Route path="/edit_court_type/:id" component={EditCourtTypeCard} />
    <Route path="/list_court_type" component={ListCourtTypeCard} />
    <Route path="/edit_court_category/:id" component={EditCourtCategory} />
    <Route path="/user" component={UsersIndex} />
    <Route path="/bookings" component={BookingIndex} />
    <Route path="/bookings_details/:id" component={ViewBookingDetails} />
    <Route path="/list_court_category" component={ListCourtCategory} />
    <Route path="/booking_cancel" component={BookingCancel} />
    <Route path="/slides" component={Slides} />
    <Route path="/list_slides" component={ListSlides} />
    <Route path="/edit_slides/:id" component={EditSlides} />
    <Route path="/list_slides-v2" component={List_SlidesV2} />
    <Route path="/add_slides-v2" component={AddSlidesV2} />
    <Route path="/edit_slides-v2/:id" component={EditSlidesV2} />
    <Route path="/rating" component={Rating} />
    <Route path="/padelholic" component={Padelholic} />
    <Route path="/listpadelholic" component={ListPadelholic} />
    <Route path="/editpadeholic/:id" component={EditPadelholic} />
    <Route path="/managetimeslot/:id" component={ManageTimeSlot} />
    <Route path="/courtbooking" component={CourtBooking} />
    <Route path="/book_court/:id" component={CourtBookingSingle} />
    <Route path="/addon_booking" component={AddonsBooking} />
    <Route path="/customer_details" component={CustomerDetails} />
    <Route path="/role" component={Role}/>
    <Route path="/create_user" component={CreateUser} />
    <Route path="/calender" component={Calender} />
    <Route path="/notify_me" component={NotifyMe} />
    <Route path="/transactions" component={Transactions} />
    <Route path="/unsucess-transaction" component={Unsuccess} />
    <Route path="/transactions_details/:id" component={TransactionDetails} />
    <Route path="/all_courts_view_details/:id" component={AllCourtsViewDetails} />
    <Route path="/main_courts_view_details/:id" component={MainCourtsViewDetails} />
    <Route path="/coupon" component={Coupon} />
    <Route path='/coupon-offers' component ={CouponOffers} />
    <Route path="/add_coupons" component={CreateCoupon} />
    <Route path="/add_coupons_offer" component ={CreateCouponOffer} />
    <Route path="/edit_coupons/:id" component={EditCoupon} />
    <Route path="/edit-coupon-offers/:id" component={EditCouponOffer} />
    <Route path="/user_ratings" component={UserRatings} />
    <Route path="/edit_role/:id" component={EditRole} />
    <Route path="/role_list" component={RoleLists} />
    <Route path="/report" component={Report} />
    <Route path="/user_details/:id" component={ExamplePage} />
    <Route path="/refund" component={Refund} />
    <Route path="/table" component={Table} />
    <Route path="/resize" component={Resize} />
    <Route path="/getpage" component={Getpage} />
    <Route path="/edit_page/:name" component={EditPage} />
    <Route path="/Add_page/" component={AddPage} />
    <Route path='/offer-list' component={OfferList} />
    <Route path='/add-offer' component={AddOffer} />
    <Route path='/edit-offer/:id' component={EditOffer} />
    <Route path="/misc" component={Miscellenious} />
    <Route path="/misc-list" component={MiscelleniousList} />
    <Route path="/misc-edit/:id" component={MiscelleniousEdit} />
    <Route path="/push_notification" component={PushNotification} />
    <Route path="/promotion" component={Promotions} />
    <Route path="/package" component={Package} />
    <Route path="/package-list" component={PackageList} />
    <Route path="/packages/:packageId" component={EditPackages} />
    <Route path="/add-promotions" component={AddPromotions} />
    <Route path="/edit-promotions/:id" component={EditPromotion} />
    <Route path="/wallet-transaction" component={WalletTransactions} />
    <Route path="/newoffer" component={NewAddOffer} />
    <Route path="/update-user" component={User} />
    <Route path="/quick-pay" component={Quickpay} />
    <Route path="/quick-pay-details" component={QuickPayDetails} />
    <Route path="/tournaments" component={Tournaments} />
    <Route path="/ad-space" component={AdSpace} />
    <Route path="/ad-space-list" component={AdSpaceList} />
    <Route path="/edit-adSpace/:adsId" component={EditAdSpace} />
    <Route path="/create-news" component={CreateNews} />
    <Route path="/news-list" component={GetNews} />
    <Route path="/edit-news/:newsId" component={EditNews} />
    <Route path="/limited_time_offers" component={ListLTO} />
    <Route path="/create_limited_time_offers" component={CreateLTO} />
    <Route path="/edit_limited_time_offers/:offerId" component={EditLTO} />
    <Route path="/ads-impressions" component={AdsImpressions} />
    <Route path="/user-packages" component={UserPackages} />
    <Route path="/download-excel" component={DownloadExcel} />
    <Route path="/CalendarSkaleton" component={CalendarSkaleton} />
    <Route path="/live-post" component={LivePost} />
    <Route path="/report-problem" component={ReportProblem} />
    <Route path="/deleted-post" component={DeletedPost} />
    <Route path="/bulk-promotion" component={BulkPromo} />
    <Route path="/kids-summer-camp" component={KidsSummerCamp} />
    <Route path="/calenderv2" component={Calenderv2} />
    <Route path="/rewards-list" component={RewardsList} />
    <Route path="/add-rewards" component={CreateRewards} />
    <Route path="/edit-rewards/:rewardId" component={EditRewards} />
    <Route path="/promotionsv2" component={PromotionsV2} />
    <Route path="/promotionsv2-list" component={PromotionV2List} />
    <Route path="/editpromotionsv2/:id" component={EditPromotionV2} />
    <Route path="/promotionv2index" component={promotionv2index} />
    <Route path="/coaches" component={Coaches} />
    <Route path="/coaches-list" component={CoachesList} />
    <Route path="/edit-coaches/:coachId" component={EditCoaches} />
    <Route path="/customer-support" component={Staff} />
    <Route path="/customer-support-list" component={StaffList} />
    <Route path="/edit-customer-support/:staffId" component={EditStaff} />
    <Route path="/manage-staff-slots/:staffId" component={ManageStaffSlots} />
    <Route path="/customer-support-calender" component={StaffCalender} />
    <Route path="/manage-coache-slots/:coacheId" component={ManageCoachesSlots} />
    <Route path="/coache-calender" component={CoachesCalender} />
    <Route path="/e-giftcard" component={GiftCard} />
    <Route path="/loyality-points-management" component={LoyalityPointsManagement} />
    <Route path="/generate-slot" component={GenerateSlot} />
    <Route path="/get-availableslots" component= { GetAvailableSlots} />
    <Route path="/get-doublebookings" component={ GetDoubleBookings } />
    <Route path='/doublebooking-list' component= { DoubleBookingList} />
    <Route path="/get-slotsbookingreport" component= {GetSlotsBookingReport} />
    <Route path="/match-categories-list" component={CategoriesList} />
    <Route path="/upload-post" component={UploadPost} />
    <Route path="/verify-skill-level" component={VerifySkillLevel} />
    <Route path="/offer-docs" component={CouponDocsStatus} />
    <Route path="/cards" component={AddOffersCard} />
    <Route path="/price-configurations" component={PriceConfigurations} />
    <Route path ="/edit-price-configurations/:id" component={EditPriceConfigurations} />
    <Route path = '/event-registration-tabs' component={EventRegistrationTabs}/>
    <Route path = '/event-tournaments-registration' component={EventTournamentRegistration}/>
    <Route path = '/add-event-tournament' component={AddEventTournament}/>
    <Route path = '/edit-event-tournament/:id' component={EditEventTournament}/>
    <Route path = '/view-event-tournament/:id' component={ViewEventTournament}/>
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="" component={Pages} />
    </div>
  </div>
);


const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <Route exact path="/verify_email" component={EmailConfiguration} />
        <Route exact path="/verify_otp" component={OTPVerification} />
        <Route exact path="/reset_password" component={ResetPassword} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
