/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
// import axios from "axios";
import axios from '../../../customAxios';
import { Col, Button, Card, CardBody, Row } from "reactstrap";

import { useHistory, Link } from "react-router-dom";
import { environment } from "../../env";
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import  { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';

const PadelholicList = () => {
  const [padelholicData, setPadelholicData] = useState([]);
  const [roleId, setRoleId] = useState();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [role, setRole] = useState();
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const padelholicUrl = `${environment.baseUrl}getPadelholicsLevel`;
  const history = useHistory();
  const getAddons = async () => {
    const result = await axios(padelholicUrl);
    console.log("color Colde",result.data);
    setPadelholicData(result.data);
  };

  const getRole = () => {
    const localrole = localStorage.getItem("Role");
    const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localrole}`;
    const result = axios.get(getRoleUrl).then((response) => {
      setRole(response.data);
      setPermissions(response.data);
    });
  };

  useEffect(() => {
    getRole();
    if (localStorage.getItem("Token")) {
      console.log("True");
    } else {
      alert("You are not logged In. Please LogIn");
      history.push("/");
    }
    getAddons();
  }, []);

  const setPermissions = (roledata) => {
    const localrole = localStorage.getItem("Role");
    let newRoleId;
    console.log(roledata.role_name);

    if (localrole === roledata.role_name) {
      console.log(roledata.permissions);
      let permission = JSON.parse(roledata.permissions);
      console.log(permission);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "padelholic"
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
      console.log("false");
    }
    console.log("Role Id", newRoleId);
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
    console.log(editing);
  };

  var jsonObj = [];
  
  console.log("NEw JSON ",jsonObj);
  for(let i=0; i<padelholicData.length; i++){
    var item = {}
    item["id"] = i+1;
    item["level"] = <div>{padelholicData[i].level}</div>;
    item["color_code"] = <div>{padelholicData[i].color_code}</div>;
    item["games"] = <div>{padelholicData[i].games}</div>;
    if(deleteing === true){
      item["link"] = <div className="right-space"><div className="modal-edit-btn" onClick={() => handleEdit(padelholicData[i].id)} ><AiOutlineEdit  style={{fontSize:20}}/></div></div>;
    // item["delete"] = <div className="right-space"><div className="modal-delete-btn" onClick={()=>handleDelete(padelholicData[i].id)}><AiOutlineDelete  style={{fontSize:20}} /></div></div>;
    }else if(editing === true){
      item["link"] = <div className="right-space"><div className="modal-edit-btn" onClick={() => handleEdit(padelholicData[i].id)} ><AiOutlineEdit  style={{fontSize:20}}/></div></div>;
    }
    jsonObj.push(item);
}

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Level",
        accessor: "level",
        disableGlobalFilter: true,
        width: 150,
      },
      {
        Header: "Games",
        accessor: "games",
        disableGlobalFilter: true,
        width: 150,
      },
      {
        Header: "Color Code",
        accessor: "color_code",
        disableGlobalFilter: true,
        width: 150,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 100,
      },
      // {
      //   Header: "Delete",
      //   accessor: "delete",
      //   disableGlobalFilter: true,
      //   width: 270,
      // },
    ],
    rows: jsonObj,
  };

  const handleDelete = (id) => {
    const padelholicDeleteUrl = `${environment.baseUrl}editpadelholicslevel/${id}`;
    axios.delete(padelholicDeleteUrl).then((response) => {
      console.log(response);
    });
    setPadelholicData(() => padelholicData.filter((data) => data.id !== id));
  };

  function handleEdit(editId) {
    history.push(`/editpadeholic/${editId}`);
  }

  const handleAddPadelholic = () => {
    history.push("/padelholic");
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };
  
  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };
  
  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };
  
  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };
  
  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };
  return (
    <>
     <Col md={12} lg={12}
        style={{
              justifyContent:'flex-end',
              display:'flex',
              alignItems:'flex-end',
              }}>
          {deleteing ? (
            <div className="justpadel-buttons btn-position" onClick={() => handleAddPadelholic()}>Add Padelholic</div>
          ) : (
            ""
          )}
        </Col>
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>
          
          <div className="hover-effect table-id-width">
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
          />
          </div>
        </CardBody>
      </Card>
    </Col>
    </>
  );
};
export default PadelholicList;
