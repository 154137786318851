import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import axios from '../../../customAxios';
import { environment } from "../../env";
import { useHistory, useParams } from "react-router-dom";

const ExampleCard = () => {
  const [inputValues, setInputValues] = useState();
  const history = useHistory();
  const { id } = useParams();

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const miscURL = `${environment.baseUrl}edit_misc/${id}`;

  const handleSubmit = () => {
    const result = axios
      .put(miscURL, inputValues)
      .then((response) => {
        console.log("Response", response.data);
        history.push("/misc-list");
      });
  };

  const getMiscelleniousList = () => {
    axios.get(miscURL)
      .then((response) => {
        setInputValues(response.data);
      });
  };

  useEffect(() => {
    getMiscelleniousList();
  }, []);
  return(
    <Col md={12}>
    <Card>
      <CardBody>
        <p>Your content here {id} </p>
        <form className="form">
          <div className="form__form-group">
            <span className="form__form-group-label">Task</span>
            <div className="form__form-group-field">
              <input type="text" name="task" id="task" value={inputValues?.task} onChange={handleOnChange} />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Value</span>
            <div className="form__form-group-field">
              <input type="text" name="value" id="value" value={inputValues?.value} onChange={handleOnChange} />
            </div>
          </div>
          <div className="form__form-group form__form-group--forgot">
            <span className="form__form-group-label">Slug</span>
            <div className="form__form-group-field">
              <input type="text" name="slug" id="slug" value={inputValues?.slug} onChange={handleOnChange} />
            </div>
          </div>
          <div className="account__btns register__btns" style={{width:'100%',display:'flex',justifyContent:'flex-end',marginBottom:10}}>
            <div className='justpadel-buttons-save' onClick={() => handleSubmit()}>
              Submit
            </div>
          </div>
        </form>
      </CardBody>
    </Card>
  </Col>
)};

export default ExampleCard;
