import axios from '../../../customAxios';
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';

const Refund = () => {
  const [refundData, setRefundData] = useState();
  useEffect(() => {
    var modifiedRefundData = localStorage.getItem("Parameters");
    setRefundData(JSON.parse(modifiedRefundData));
  }, [])

  console.log("refundData", refundData);
  console.log("refundData?.customer_email",refundData?.customer_email);
  return (
  <Col md={12}>
    <Card>
      <CardBody>
        <h1>Hii</h1>
      <form action="https://checkout.payfort.com/FortAPI/paymentPage" method='post' name='frm'>
    
    <input type='hidden' name='currency' value='AED' />
  
    <input type='hidden' name='amount' value={`${250*100}`} />
  
    <input type='hidden' name='customer_email' value={`${refundData?.customer_email}`} />
  
    <input type='hidden' name='command' value='REFUND' />
  
    <input type='hidden' name='language' value='en' />
  
    <input type='hidden' name='merchant_identifier' value='TXGKcfsS' />
  
    <input type='hidden' name='access_code' value='9NKhRXOyuU6C1vrXB4rL' />
  
    <input type='hidden' name='signature' value={`${refundData?.signature}`} />
  
    <input type='hidden' name='merchant_reference' value={`${refundData?.merchant_reference}`} />
  
    <input type='hidden' name='order_description' value={`${refundData?.order_description}`} />

    <input type='hidden' name='return_url' value='https://api.justpadelae.com/payment_return/' />

  </form>
      </CardBody>
    </Card>
  </Col>
);
};

export default Refund;
