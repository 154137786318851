import React from 'react';
import {
  Col, Container, Row
} from 'reactstrap';
import AddSlideV2 from './components/AddSlideV2';


const AddSlidesV2 = () => {
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Add Slide&apos;s V2</h3>
        </Col>
      </Row>
      <Row>
        <AddSlideV2 />
      </Row>
    </Container>
  )
}

export default AddSlidesV2