import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import CourtTypeCard from './components/CourtTypeEditCard';

const CourtType = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Court Type</h3>
        {/* <h3 className="page-subhead subhead">
          Use this elements, to Add Court Type
        </h3> */}
      </Col>
    </Row>
    <Row>
      <CourtTypeCard />
    </Row>
  </Container>
);
export default CourtType;
