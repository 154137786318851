import React, { useEffect, useState } from "react";
import {
  Col,
  ModalHeader,
  CardBody,
  Spinner,
  Row,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { environment } from "../env";
import axios from "../../customAxios";
import ReactPaginate from "react-paginate";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import moment from "moment";

export const CouponApplied = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [coupanDocsData, setCoupanDocsData] = useState([]);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const toggle = () => setModal(!modal);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [pageCount, setpageCount] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { register, handleSubmit, control, reset, formState } = useForm({
    // mode: "onChange",
  });
  const { touchedFields, errors } = formState;

  const [rejectData, setRejectData] = useState();
  const [modalReject, setModalReject] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const toggleReject = () => setModalReject(!modalReject);

  const {
    register: register1,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
    reset: reset1,
    setValue
  } = useForm({
    // mode: "onBlur",
  });

  const [approvedData, setApprovedData] = useState();
  const [modalApproved, setModalApproved] = useState(false);
  const [openApproved, setOpenApproved] = useState(false);
  const toggleApproved = () => setModalApproved(!modalApproved);

  const handlePageClick = async (data) => {
    var currentPage = data.selected + 1;
    setCurrentPageNo(currentPage);
    setInitialPage(data.selected);
    localStorage.setItem("CouponDocCurrentPage", currentPage);
    await GetCoupanDocs(currentPage, pageLimit);
  };

  async function handleLimitChange(e) {
    localStorage.setItem("CouponDocCurrentPageLimit", e.target.value);
    setInitialPage(0);
    setPageLimit(e.target.value);
  }

  const handleImageZoom = (imageURL) => {
    toggle();
    setSelectedImage(imageURL);
    setOpen(!open);
  };

  const handleUpdateDoc = async (data) => {
    setIsLoading(true);
    const approvedUrl = `${environment.baseUrl}approved-src-coupon/`;

    const approvedPayload = {
      doc_for: data.doc_for,
      approved: data.reject ? false : true,
      user: data.user.id,
      ...(data.expiry_date ? { expiry_date: data.expiry_date } : {}),
      ...(data.percentage_of_discount
        ? { percentage_of_discount: data.percentage_of_discount }
        : {}),
      ...(data.reason ? { reason: data.reason } : {}),
    };
    await axios
      .post(approvedUrl, approvedPayload)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === "success") {
          reset()
          swal({
            title: "Success",
            text: res.data.message,
            icon: "success",
            button: "Ok",
          }).then((res) =>
            GetCoupanDocs(currentPageNo ? currentPageNo : 1, pageLimit)
          );
        } else if (res.data.status === "reject") {
          swal({
            title: "Reject",
            text: res.data.message,
            icon: "error",
            button: "Ok",
          }).then((res) =>
            GetCoupanDocs(currentPageNo ? currentPageNo : 1, pageLimit)
          );
        } else {
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Ok",
          }).then((res) =>
            GetCoupanDocs(currentPageNo ? currentPageNo : 1, pageLimit)
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const GetCoupanDocs = async (page, pageLimit) => {
    setIsLoading(true);
    const CoupanDocsUrl = `${environment.baseUrl}get-src-coupon/applied?page=${page}&page_size=${pageLimit}`;
    await axios
      .get(CoupanDocsUrl)
      .then((res) => {
        setCoupanDocsData(res.data.results);
        setpageCount(Math.ceil(res?.data?.count / pageLimit));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleReject = (data) => {
    setRejectData(data);
    toggleReject();
    setOpenReject(!openReject);
  };

  const onSubmitReject = (data) => {
    const rejectPayload = {
      ...rejectData,
      reason: data.reason,
    };
    handleUpdateDoc(rejectPayload);
    setValue("reason", "")
    toggleReject();
  };

  const handleApproved = (data) => {
    setApprovedData(data);
    toggleApproved();
    setOpenApproved(!openApproved);
  };

  const onSubmit = (data) => {
    const approvedPayload = {
      ...approvedData,
      expiry_date: data.expiry_date,
      percentage_of_discount: data.percentage_of_discount,
    };
    handleUpdateDoc(approvedPayload);
    toggleApproved();
  };

  useEffect(() => {
    GetCoupanDocs(currentPageNo, pageLimit);
  }, [currentPageNo, pageLimit]);

  var jsonObj = [];
  const startId = (currentPageNo - 1) * pageLimit + 1;
  for (let i = 0; i < coupanDocsData.length; i++) {
    var item = {};
    // item["id"] = i + 1;
    item["id"] = startId + i;
    item["front_id"] = (
      <div className="Booking-details-td-right-space">
        <img
          src={coupanDocsData[i].front_id}
          style={{
            width: 100,
            height: 100,
            borderRadius: 5,
            cursor: "pointer",
          }}
          alt={coupanDocsData[i].front_id}
          onClick={() => handleImageZoom(coupanDocsData[i].front_id)}
        />
      </div>
    );
    item["back_id"] = (
      <div className="Booking-details-td-right-space">
        <img
          src={coupanDocsData[i].back_id}
          style={{
            width: 100,
            height: 100,
            borderRadius: 5,
            cursor: "pointer",
          }}
          alt={coupanDocsData[i].back_id}
          onClick={() => handleImageZoom(coupanDocsData[i].back_id)}
        />
      </div>
    );
    item["doc_for"] = (
      <div
        className="Booking-details-td-right-space"
      >
        {coupanDocsData[i]?.doc_for}
      </div>
    );
    item["comment"] = (
      <div
        className="Booking-details-td-right-space"
      >
        {coupanDocsData[i]?.comments}
      </div>
    );

    item["applied_date"] = coupanDocsData[i].applied_date ? (
      <div className="Booking-details-td-right-space">
        {coupanDocsData[i]?.applied_date?.split("T")[0]}<br />
        {moment(coupanDocsData[i]?.applied_date).format('HH:mm')}
      </div>
    ) : (<></>);
  
  item["user_email"] = (
    <div className="Booking-details-td-right-space">
      {coupanDocsData[i].user?.first_name}<br />
      {coupanDocsData[i].user?.email}<br />
      {coupanDocsData[i].user?.phone_no}

    </div>
  );
  item["actions"] = (
    <div className="right-space">
      <div>
        <Button
          type="button"
          color="warning"
          className="custom_coupon_btn"
          onClick={() => handleApproved(coupanDocsData[i])}
        >
          Approve
        </Button>
        <Button
          type="button"
          color="danger"
          className="custom_coupon_btn"
          onClick={() =>
            handleReject({ ...coupanDocsData[i], reject: "reject" })
          }
        >
          Reject
        </Button>
      </div>
    </div>
  );
  jsonObj.push(item);
}

// var columns =[]
const data = {
  columns: [
    {
      Header: "Id",
      accessor: "id",
      disableGlobalFilter: true,
      width: 50,
    },
    {
      Header: "Doc Front Image",
      accessor: "front_id",
      disableGlobalFilter: true,
      width: 100,
    },
    {
      Header: "Doc Back Image ",
      accessor: "back_id",
      disableGlobalFilter: true,
      width: 100,
    },

    {
      Header: "Doc For",
      accessor: "doc_for",
      disableGlobalFilter: true,
      width: 100,
    },
    {
      Header: "Comment",
      accessor: "comment",
      disableGlobalFilter: true,
      width: 100,
    },
    {
      Header: "Applied Date",
      accessor: "applied_date",
      disableGlobalFilter: true,
      width: 100,
    },
    {
      Header: "Email",
      accessor: "user_email",
      disableGlobalFilter: true,
      width: 150,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableGlobalFilter: true,
      width: 150,
    }
  ],
  rows: jsonObj,
};



const handleClickWithPagination = (data) => {
  setWithPaginationTable(!withPagination);
};
const handleClickIsResizable = () => {
  setIsResizable(!isResizable);
};
const tableConfig = {
  isEditable: false,
  isResizable,
  withPagination,
  manualPageSize: [10000, 20000, 30000, 40000],
};

return (
  <>
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered={true}
      className="custom_zoom_img"
    >
      <ModalBody className="">
        <img
          src={selectedImage}
          style={{ width: "450px", height: "600px", cursor: "pointer" }}
          alt={selectedImage}
        />
      </ModalBody>
    </Modal>

    <Modal
      isOpen={modalApproved}
      toggle={toggleApproved}
      centered={true}
      className="custom_reject_modal"
      size="lg"
    >
      <ModalHeader toggle={toggleApproved}>Approved Doc</ModalHeader>
      <ModalBody className="">
        <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Expiry Date</span>
                <div className="form__form-group-field">
                  <input
                    type="date"
                    name="expiry_date"
                    {...register("expiry_date", { required: true })}
                  />
                </div>
                {errors.expiry_date && (
                  <span className="error-msg">
                    Please Enter Coupons Expiry Date
                  </span>
                )}
              </div>
            </Col>

            <Col lg={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Percentage of Discount
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Percentage of Discount"
                    name="percentage_of_discount"
                    {...register("percentage_of_discount", {
                      required: true,
                      min: 0,
                      max: 100,
                    })}
                  />
                </div>
                {errors.percentage_of_discount?.type === "max" && (
                  <div className="error-msg">
                    {" "}
                    <BsFillExclamationTriangleFill className="error-icon" />
                    Please give discount between 0-100
                  </div>
                )}
                {errors.percentage_of_discount?.type === "required" && (
                  <span className="error-msg">
                    Please Enter Percentage of Discount
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <div class="col-12 d-flex justify-content-end mt-3">
            <Button type="submit" color="warning" className="custom_coupon_btn">
              Submit
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>

    <Modal
      isOpen={modalReject}
      toggle={toggleReject}
      centered={true}
      className="custom_reject_modal"
      size="lg"
    >
      <ModalHeader toggle={toggleReject}>Reject Reason</ModalHeader>
      <ModalBody className="">
        <form
          className="form product-edit"
          onSubmit={handleSubmit1(onSubmitReject)}
        >
          <div className="col-12">
            <div className="form__form-group">
              {/* <span className="form__form-group-label">Name</span> */}
              <div className="form__form-group-field">
                <input
                  type="text"
                  placeholder="Enter Reject Reason"
                  name="reason"
                  {...register1("reason", { required: true })}
                />
              </div>
              {errors1.reason && (
                <span className="error-msg">
                  Please Enter Reason for Rejecting
                </span>
              )}
            </div>
          </div>
          <div class="col-12 d-flex justify-content-end mt-3">
            <Button type="submit" color="warning" className="custom_coupon_btn">
              Submit
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>

    <CardBody>
      {isLoading && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}

      {coupanDocsData.length > 0 ? (
        <Row style={{ marginTop: "20px" }}>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <>
                  <div className="react-table__wrapper hide-table-costomizer">
                    <ReactTableCustomizer
                      handleClickWithPagination={handleClickWithPagination}
                      handleClickIsResizable={handleClickIsResizable}
                      isResizable={isResizable}
                      withPagination={withPagination}
                    />
                  </div>
                  <div className="table-id-width  pagination-hide  hover-effect search-input-update">
                    <ReactTableBase
                      columns={data.columns}
                      data={data.rows}
                      tableConfig={tableConfig}
                    />
                  </div>
                  <div className="custom-pagination">
                    <div className="records-per-page">
                      <select
                        value={pageLimit}
                        onChange={handleLimitChange}
                        name="limit"
                        id="select-pagination"
                      >
                        {mannualLimits &&
                          mannualLimits.map((mannualLimits) => (
                            <option value={mannualLimits}>
                              {mannualLimits}
                            </option>
                          ))}
                      </select>
                      &nbsp;&nbsp;&nbsp;records per page
                    </div>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={initialPage}
                    />
                  </div>
                </>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <div
          className="page-title text-center text-gray"
          style={{ color: "#646777" }}
        >
          No Applicants
        </div>
      )}
    </CardBody>
  </>
);
};
