import React from 'react'
import { Col, Container, Row } from "reactstrap";
import { GetSlotsBookingReportCard } from './GetSlotsBookingReportCard';


export const GetSlotsBookingReport = () => {
    return (
        <Container>
          <Row md={12}>
            <Col>
              <h3 className="page-title">Get Slots Bookings Report</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <GetSlotsBookingReportCard />
            </Col>
          </Row>
        </Container>
      );
}
