import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Col, Button, ButtonToolbar, Card, CardBody } from 'reactstrap'
import axios from '../../customAxios';
import { environment } from '../env';
import swal from "sweetalert";


const Quickpay = () => {
    const [signature, setSignature] = useState();
    const [id, setId] = useState();
    const { register, handleSubmit, reset, watch, formState } = useForm({ mode: 'onChange' });
    const { errors } = formState;
    const onSubmit = (data) => {
        const QuickPaymentURL =  `${environment.baseUrl}quicks-pay`;
        const result = axios.post(QuickPaymentURL, data)
        .then((quickPaymentResponse)=>{
            setId(quickPaymentResponse.data.Id);
            payments(quickPaymentResponse.data.Id);
        })
        // payments();
    };

    const name = watch("name");
    const email = watch("email");
    const amount = watch("amount");

    const payments = (ids) => {
        const genSignatureUrl = `${environment.baseUrl}checkout`;
        const params = JSON.stringify({
            "currency": "AED",
            "amount": `${amount * 100}`,
            "customer_email": `${email}`,
            "language": "en",
            "order_description": `eventPays${ids}`,
            //"order_description": "Mina Rashid 4",
            // "merchant_reference": `${name}`,
            "merchant_reference": `${ids}`,
            //Testing payment gateway starts here
            "access_code":'MTkAuRmgVl270B82kqey',
            "merchant_identifier":'fc9fe2bb',
            "parse_phrase":'51MO1sxGDZPtHyGATVpuyG!@',
            //Testing payment gateway end here
            "command": "PURCHASE",
            "return_url": "http://127.0.0.1:8000/quicks-pay-payment-return"
        });
        const userToken = localStorage.getItem('Token');

        axios.post(genSignatureUrl, params, {
            "headers": {
                "content-type": "application/json",
                'Authorization': 'Bearer ' + userToken
            },
        })
            .then(function (data) {
                if (data.status === 200 || data.status === 201) {
                    console.log("Signature", data?.data.signature);
                    setSignature(data?.data.signature);
                    
                    setTimeout(() => {
                        // document.getElementById("load_payment").submit();
                        PaymentLoad();
                    }, 2000);
                } else {
                    alert('Server error')

                }
            })
            .catch(function (error) {
                console.log(error)

            });
    };

    const PaymentLoad = () => {
        console.log("payment Loaded");
        document.getElementById("load_payment").submit();
    }

   

    return (
        <div>
            <Col md={12} lg={12}>
                <Card>
                    <CardBody>
                        <form className="form product-edit" style={{ display: "flex", flexDirection: "column" }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Name</span>
                                    <div className="form__form-group-field">
                                        <input type="text" name='name' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("name", { required: true })} />
                                    </div>
                                    {errors.name && <span className="error-msg">Please Enter  Name</span>}
                                </div>
                            </div>
                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Email</span>
                                    <div className="form__form-group-field">
                                        <input type="email" name='email' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("email", { required: true })} />
                                    </div>
                                    {errors.email && <span className="error-msg">Please Enter Valid Email</span>}

                                </div>
                            </div>

                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Phone Number</span>
                                    <div className="form__form-group-field">
                                        <input type="number" name='phone' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("phone", { required: true })} />
                                    </div>
                                    {errors.phone && <span className="error-msg">Please Enter Valid Phione Number</span>}

                                </div>
                            </div>

                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Subject</span>
                                    <div className="form__form-group-field">
                                        <input type="text" name='subject' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("subject", { required: true })} />
                                    </div>
                                    {errors.subject && <span className="error-msg">Please Enter Subject</span>}

                                </div>
                            </div>

                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Amount</span>
                                    <div className="form__form-group-field">
                                        <input type="number" name='amount' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("amount", { required: true })} />
                                    </div>
                                    {errors.amount && <span className="error-msg">Please Enter Amount</span>}

                                </div>
                            </div>

                            <div className="form__half">
                                <div className="form__form-group">
                                    <span className="form__form-group-label" style={{ fontSize: 15 }}>Additional Comments</span>
                                    <div className="form__form-group-field">
                                        <input type="text" name='additional_comments' style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} {...register("additional_comments", { required: true })} />
                                    </div>
                                    {errors.additional_comments && <span className="error-msg">Please Enter Comments</span>}

                                </div>
                            </div>

                            <Col md={6} lg={6}>
                                <Card style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", marginTop: 30, }}>
                                    <div className="form__half" style={{ marginRight: 0 }}>
                                        <ButtonToolbar className="form__button-toolbar" >
                                            <button className='justpadel-buttons-save'
                                                type='submit'
                                            >
                                                Submit
                                            </button>
                                            <div className='justpadel-buttons-cancel'
                                            >
                                                Reset
                                            </div>
                                        </ButtonToolbar>
                                    </div>
                                </Card>
                            </Col>

                        </form>
                    </CardBody>
                </Card>
            </Col>
            {signature ?
                <form action="https://sbcheckout.payfort.com/FortAPI/paymentPage" method='post' name='frm' target="_self" id="load_payment" >

                    <input type='text' name='currency' value='AED' />

                    <input type='text' name='amount' value={amount * 100} />

                    <input type='text' name='customer_email' value={email} />

                    <input type='text' name='command' value='PURCHASE' />

                    <input type='text' name='language' value='en' />

                    <input type='text' name='merchant_identifier' value='fc9fe2bb' />

                    <input type='text' name='access_code' value='MTkAuRmgVl270B82kqey' />

                    <input type='text' name='signature' value={signature} />

                    <input type='text' name='merchant_reference' value={id} />

                    <input type='text' name='order_description' value={`eventPays${id}`} />

                    <input type='text' name='return_url' value='http://127.0.0.1:8000/quicks-pay-payment-return' />

                </form>
                : ''}
        </div>
    )
}

export default Quickpay;