import React from 'react'
import { Col, Container, Row, Text } from 'reactstrap';
import ReportPage from './ReportPage';

const ReportProblem = () => {
  return (
    <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Report</h3>
      </Col>
    </Row>
    <Row>
        <ReportPage />
    </Row>
    
  </Container>
  )
}

export default ReportProblem