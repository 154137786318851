import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, CardBody, Modal, Card, Col, } from 'reactstrap';
import classNames from 'classnames';
import { RTLProps } from '../prop-types/ReducerProps';
import { BulkBookingApi } from '../../containers/Reuse/ApiCalls';
// import { BulkBookingApi } from '../../containers/Reuse/ApiCalls';
import AvailableTab from '../../containers/UI/Tabs/components/AvailableTab';
import User from '../../containers/User/User';





const AvailableModal = ({
    color, btn, colored, header, title, transactionId, availableShowHide, submitDate, prePopulatedDated
}) => {
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(prevState => !prevState);
    };
    useEffect(() => {
        setModal(availableShowHide);
    }, [availableShowHide])

    let Icon;

    switch (color) {
        case 'primary':
            Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
            break;
        case 'success':
            Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
            break;
        case 'warning':
            Icon = <span className="lnr lnr-flag modal__title-icon" />;
            break;
        case 'danger':
            Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
            break;
        default:
            break;
    }
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });

    const TabToRefresh = () => {
        toggle();
        submitDate();
    }

    return (
        <>
            <td className="AvailableSlot" onClick={toggle}> Available </td>
            <Modal
                isOpen={modal}
                toggle={toggle}
                modalClassName={`modal ltr-support`}
                className={`modal-dialog--${color} ${modalClass}`}
                style={{ maxWidth: 950 }}
            >
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">{title}</h4>
                </div>
                <Col md={12} lg={12} xl={12}>
                    <Card>
                        <CardBody>
                            <div className="tabs tabs--justify tabs--bordered-bottom">
                                <AvailableTab TabToRefresh={TabToRefresh} prePopulatedDated={prePopulatedDated} close={toggle}/>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Modal>
        </>
    );
};

AvailableModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    color: PropTypes.string.isRequired,
    colored: PropTypes.bool,
    header: PropTypes.bool,
    btn: PropTypes.string.isRequired,
    rtl: RTLProps.isRequired,
};

AvailableModal.defaultProps = {
    title: '',
    message: '',
    colored: false,
    header: false,
};

export default AvailableModal;

// export default connect(state => ({
//   rtl: state.rtl,
// }))(ModalComponent);
