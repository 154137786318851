/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardBody, Button, Row , Spinner} from "reactstrap";
import axios from "../../../customAxios";
import { useHistory } from "react-router-dom";
import { environment } from "../../env";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../../shared/components/table/components/ReactTableCustomizer";
import { AiFillEye, AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import "./User.css";
import ReactPaginate from "react-paginate";
import _ from "lodash";

const UsersListCard = ({ control }) => {
  const [userData, setUserData] = useState([]);
  const [initialPage, setInitialPage] = useState(0);
  const [mannualLimits, setMannualLimits] = useState([
    10, 20, 30, 40, 50, 100, 200, 500, 1000,
  ]);
  const [pageCount, setpageCount] = useState(0);

  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [search, setSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory();

  let newValue;

  const getUser = async () => {
    setIsLoading(true)
    let page = parseInt(localStorage.getItem("RegisteredUserPage"));
    let pageSize = parseInt(localStorage.getItem("RegisteredUserPageSize"));
    const usersUrl = `${environment.baseUrl}api/user/registration-filter/?page=${page}&page_size=${pageSize}`;
    const result = await axios(usersUrl).then((response) => {
      setpageCount(Math.ceil(response.data.count / pageSize));
      setUserData(response.data.results);
      setIsLoading(false)
    });
  };

  var UserDataObject = [];
  for (let i = 0; i < userData.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["email"] = userData[i].email.toString();
    item["first_name"] =
      userData[i].first_name === "null"
        ? ""
        : userData[i].first_name.toString();
    item["phone_no"] = (
      <div className="right-space user-phone">
        {userData[i].phone_no === "null" ? "" : userData[i].phone_no}
      </div>
    );
    item["source"] = <div className="right-space">{userData[i].source}</div>;
    item["role"] = <div className="right-space">{userData[i].role}</div>;
    item["created"] = (
      <div className="right-space">{userData[i].registration_date}</div>
    );
    item["view"] = (
      <div className="right-space">
        <div
          className="modal-view-btn"
          onClick={() => handleViewDetails(userData[i].id)}
        >
          <AiFillEye style={{ fontSize: 20 }} />
        </div>
      </div>
    );
    item["activate"] = (
      <div className="right-space">
        {userData[i].active ? (
          <div className="check-activated">
            <AiOutlineCheck
              onClick={() => handleActivate(userData[i].id, userData[i].active)}
              style={{ fontSize: 20 }}
            />
          </div>
        ) : (
          <div className="cross-activate">
            <AiOutlineClose
              onClick={() => handleActivate(userData[i].id, userData[i].active)}
              style={{ fontSize: 20 }}
            />
          </div>
        )}
      </div>
    );
    UserDataObject.push(item);
  }

  const RegisteredUserData = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Customer Name",
        accessor: "first_name",
        disableGlobalFilter: false,
        width: 105,
      },
      {
        Header: "Email",
        accessor: "email",
        disableGlobalFilter: false,
        width: 150,
      },
      {
        Header: "Phone No",
        accessor: "phone_no",
        disableGlobalFilter: true,
        width: 90,
      },
      {
        Header: "Source",
        accessor: "source",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Role",
        accessor: "role",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Create Date",
        accessor: "created",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "View",
        accessor: "view",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
      {
        Header: "Action",
        accessor: "activate",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
    ],
    data: UserDataObject,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [100000, 200000, 300000, 400000],
    placeholder: "Search by Name...",
  };

  const handleViewDetails = (id) => {
    history.push(`user_details/${id}`);
  };

  const handleActivate = (id, activate) => {
    const activateUserURL = `${environment.baseUrl}activate/`;
    const data = {
      id: id,
      active: !activate,
    };

    axios.post(activateUserURL, data).then((response) => {
      alert(response.data.message);
      getUser();
    });
  };

  async function handleLimitChange(e) {
    localStorage.setItem("RegisteredUserPageSize", e.target.value);
    setInitialPage(0);
    await getUser();
  }

  useEffect(() => {
    localStorage.setItem("RegisteredUserPage", 1);
    localStorage.setItem("RegisteredUserPageSize", 10);
    getUser();
    if (localStorage.getItem("Token")) {
    } else {
      alert("You are not logged In. Please LogIn");
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setInitialPage(initialPage);
  }, [initialPage]);

  const HandlePagination = async (data) => {
    setUserData([]);
    let currentPage = data.selected + 1;
    localStorage.setItem("RegisteredUserPage", currentPage);
    getUser();
  };



  const [ userSearchValue, setUserSearchValue] = useState()
  const userSearch = (searchValue) => {
    setUserSearchValue(searchValue)
    // userSearchValue = searchValue;
    if (searchValue.length <= 0) {
      debouncedThing();
    }
  };



  const getData = () => {
    setIsLoading(true)
    const FilterUserURL = `${environment.baseUrl}filter-user/${userSearchValue}`;
    const result = axios.get(FilterUserURL).then((filterUserResponse) => {
      let pageSize = parseInt(localStorage.getItem("RegisteredUserPageSize"));
      setpageCount(Math.ceil(10 / pageSize));
      setUserData(filterUserResponse.data);
      setIsLoading(false)
    });
  };

  const clearData = () => {
    setUserSearchValue("")  
    debouncedThing(); 
  }

  const debouncedThing = _.debounce(getUser, 1000);

  

  // will cancel the execution of thing if executed before 1 second
  debouncedThing.cancel();

  // const FilterUserList = async (value) => {
  //   if (value.length > 0) {
  //     newValue = value;
  //     debouncedThing();
  //   } else {
  //     getUser();
  //   }
  //   // setUserData();
  //   setSearch(false);
  // };

  return (
    <Col md={12} lg={12} className="mb-4">
      <Card>
        <ReactTableCustomizer
          handleClickIsSortable={handleClickIsSortable}
          handleClickWithPagination={handleClickWithPagination}
          handleClickWithSearchEngine={handleClickWithSearchEngine}
          handleClickIsResizable={handleClickIsResizable}
          isResizable={isResizable}
          isSortable={isSortable}
          withPagination={withPagination}
          withSearchEngine={withSearchEngine}
        />
        <CardBody>

          {isLoading && (
            <div class="loader-div">
              <span class="loader">
                <div className="center-spinner">
                  <Spinner className="table-fetch-spinner" />
                </div>
              </span>
            </div>
          )}


          <form className="form">
            <div className="form__half">
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <input
                    type="text"
                    value={userSearchValue}
                    // onChange={(e) => FilterUserList(e.target.value)}
                    onChange={(e) => userSearch(e.target.value)}

                    placeholder="Search by name, emailm and phone no"
                  />
                  <div className="mx-5 w-100">
                    <Button
                      color="warning"
                      className="px-3 py-1"
                      onClick={() => getData()}
                    >
                      search
                    </Button>
                    <Button
                      color="warning"
                      className="px-3 py-1"
                      onClick={() => clearData()}
                    >
                      clear
                    </Button>
                  </div>


                </div>
              </div>
            </div>
          </form>

          <div className="table-id-desabled pagination-hide hide-filtername hover-effect hide-table-costomizer second-child-space">
            <ReactTableBase
              key={withSearchEngine ? "searchable" : "common"}
              columns={RegisteredUserData.columns}
              data={RegisteredUserData.data}
              tableConfig={tableConfig}
            />
          </div>

          <div className="custom-pagination">
            <div className="records-per-page">
              <select
                value={localStorage.getItem("RegisteredUserPageSize")}
                onChange={handleLimitChange}
                name="limit"
                id="select-pagination"
              >
                {mannualLimits &&
                  mannualLimits.map((mannualLimits) => (
                    <option value={mannualLimits}>{mannualLimits}</option>
                  ))}
              </select>
              &nbsp;&nbsp;&nbsp;records per page
            </div>

            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={HandlePagination}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              forcePage={initialPage}
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default UsersListCard;
