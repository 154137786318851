import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
} from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import { environment } from "../env";
import axios from "../../customAxios";
import ReactTableBase from "../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import swal from "sweetalert";

const RewardsList = () => {
  const [rewards, setRewards] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const history = useHistory();

  const GetRewards = () => {
    const rewardsUrl = `${environment.baseUrl}rewards/`;
    axios.get(rewardsUrl).then((response) => {
      setRewards(response?.data);
    });
  };

  var rewardsObj = [];

  for (let i = 0; i < rewards.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["image"] = (
      <div className="right-space">
        <img
          src={rewards[i]?.image}
          style={{ width: 60 }}
          alt={rewards[i]?.image}
        />
      </div>
    );
    item["title"] = rewards[i]?.title;
    item["sessions"] = <div className="right-space">{rewards[i]?.sessions}</div>;
    item["description"] = (
      <div className="right-space">{rewards[i]?.description}</div>
    );
    item["bgcolor"] = (
      <div style={{display:'flex', alignItems:'center'}}>
        <div style={{backgroundColor:rewards[i]?.bgcolor, height:25, width:25, marginRight:10, borderRadius:'50%'}}></div>
        <div className="right-space">{rewards[i]?.bgcolor}</div>
      </div>
    );
    item["link"] = (
      <div className="right-space">
        <div
          onClick={() => handleEdit(rewards[i]?.id)}
          className="modal-edit-btn"
        >
          <EditOutlineIcon style={{ fontSize: 20 }} />{" "}
        </div>
      </div>
    );
    item["delete"] = (
      <div className="right-space">
        <div
          className="modal-delete-btn"
          onClick={() => handleDelete(rewards[i]?.id)}
        >
          <DeleteOutlineIcon style={{ fontSize: 20 }} />
        </div>
      </div>
    );

    rewardsObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Images",
        accessor: "image",
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: "Title",
        accessor: "title",
        disableGlobalFilter: false,
        width: 90,
      },
      {
        Header: "Sessions",
        accessor: "sessions",
        disableGlobalFilter: true,
        width: 60,
      },
      {
        Header: "Description",
        accessor: "description",
        disableGlobalFilter: true,
        width: 120,
      },
      {
        Header: "Background Color",
        accessor: "bgcolor",
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 40,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 50,
      },
    ],
    rows: rewardsObj,
  };

  useEffect(() => {
    GetRewards();
  }, []);

  function handleEdit(packageId) {
    history.push(`/edit-rewards/${packageId}`);
  };

  const handleDelete = (id) => {
    const rewardsDeleteUrl = `${environment.baseUrl}rewards/${id}/`;
    axios.delete(rewardsDeleteUrl).then((response) => {
      if (response.data.status === "success") {
        swal({
          title: "Success",
          text: "Rewards is deleted successfully!",
          icon: "success",
          button: "Ok",
        })
        setRewards(() => rewards.filter((reward) => reward.id !== id));
      }else{
        if (response.data.status === "success") {
          swal({
            title: "Error",
            text: "error while deleting reward!",
            icon: "error",
            button: "Ok",
          })
        }
      }
    });
    
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search by Package Name...",
  };

  return (
    <Container>
      <Row>
        <Col md={6}>
          <h3 className="page-title">Rewards List</h3>
        </Col>
        <Col md={6}>
          <Link
            to="add-rewards"
            className="btn btn-primary float-right add-packages"
            role="button"
          >
            Add Rewards
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="react-table__wrapper hide-table-costomizer">
                <ReactTableCustomizer
                  handleClickIsSortable={handleClickIsSortable}
                  handleClickWithPagination={handleClickWithPagination}
                  handleClickWithSearchEngine={handleClickWithSearchEngine}
                  handleClickIsResizable={handleClickIsResizable}
                  isResizable={isResizable}
                  isSortable={isSortable}
                  withPagination={withPagination}
                  withSearchEngine={withSearchEngine}
                />
              </div>
              <div className="hover-effect table-id-width">
                {rewards && rewards ? (
                  <ReactTableBase
                    key={withSearchEngine ? "searchable" : "common"}
                    columns={data.columns}
                    data={data.rows}
                    tableConfig={tableConfig}
                  />
                ) : (
                  ""
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RewardsList;
