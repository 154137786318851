import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import ListCourtTypeCard from './components/CourtTypeEditCard';

const ListCourtType = () => (
  <Container>
    <Row>
      <Col md={12} lg={12}>
        <h3 className="page-title">Add Court</h3>
      </Col>
    </Row>
    <Row>
      <ListCourtTypeCard />
    </Row>
  </Container>
);
export default ListCourtType;
