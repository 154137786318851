import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import EditAddonsEditCard from './components/AddonsEditCard';

const EditAddons = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Addon&apos;s</h3>
        <h3 className="page-subhead subhead">
          Use this elements, for Editing Addon&apos;s
        </h3>
      </Col>
    </Row>
    <Row>
      <EditAddonsEditCard />
    </Row>
  </Container>
);
export default EditAddons;
