/* eslint-disable no-sequences */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { useState, useEffect } from "react";
import { Col, Button, Card, CardBody, Spinner, Progress } from "reactstrap";
// import axios from "axios";
import axios from "../../../customAxios";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import BookingDetailsModal from "../../../shared/components/BookingDetailsModal";
import EditBookingModal from "../../../shared/components/EditBookingModal";
import { environment } from "../../env";
import ReactPaginate from "react-paginate";
import "./BookinkDetails.css";
import "./style.css";
import {
  CancelBooking,
  GetMainCourt,
  GetPadelCourt,
  GetRegisteredUser,
  CancelStaffBooking,
  CancelCoacheBooking,
} from "../../Reuse/ApiCalls";
import AdminUserDetails from "./AdminUserDetails";
import UserDetails from "./UserDetails";
import { useForm } from "react-hook-form";
import { MdCancel } from "react-icons/md";
import swal from "@sweetalert/with-react";
import MakingFileDownload from "./MakingFileDownload";
import BookingDetailsModalV2 from "../../../shared/components/BookingDetailsModalV2";

const UsersListCard = () => {
  const [allCourtType, setAllCourtType] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [signature, setSignature] = useState({});
  const [pyamentHtml, setPyamentHtml] = useState();
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [serverTime, setServerTime] = useState();
  const [pageCount, setpageCount] = useState(0);
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [mainCourt, setMainCourt] = useState([]);
  const [initialPage, setInitialPage] = useState(0);
  const [padelCourtList, setPadelCourtList] = useState();
  const [registerdUser, setRegisterdUser] = useState([]);
  const [mainCourtId, setMainCourtId] = useState("");
  const [userId, setUserId] = useState("");
  const [searchData, setSearchData] = useState(false);
  //---------------------------------------------------------state for spinner----------------------
  const [spinner, setSpinner] = useState(false);
  const LoggedUserId = localStorage.getItem("UserId");
  const [excelProgress, setExcelProgress] = useState(0);
  const [downloadFile, setDownloadFile] = useState(false);
  const [currectUserRole, setCurrectUserRole] = useState()

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();


  const filteredMainCourt = mainCourt.filter((data) => data.id !== 12);

  let limit = parseInt(localStorage.getItem("BookingListCurrentPageLimit"));

  const getUser = async (page, limit) => {
    setIsLoading(true)
    const usersUrl = `${environment.baseUrl}courtbooking?page=${page}&page_size=${limit}`;
    const result = await axios(usersUrl);
    if (result.data.Data === "Data Not Found") {
      alert("Data Not Found");
      return;
    }
    setAllCourtType(result.data.Data);
    // console.log(result.data.Data);
    // console.log("Count", result.data.count)-------
    setpageCount(Math.ceil(result.data.count / limit));
    // console.log("Result Data",result.data.Data);
    // var b = result.data.Data.replace(/'/g, '"');
    // let bs = JSON.stringify(result.data.Data);
    // let dd = JSON.parse(bs);
    // const data =  JSON.parse(dd);
    // console.log("Data", data);
    setServerTime(result.data.current_date_time);
    setIsLoading(false)
  };
  const fileteredMainCourt = mainCourt.filter((data) => data.id !== 12);

  const refreshDetailsData = () => {
    getUser(1, parseInt(localStorage.getItem("BookingListCurrentPageLimit")));
  };

  const PaymentStatus = (availability, cancelled, payment_status) => {
    if (payment_status === "Failed") {
      return "Timeout"
    } else if (availability || cancelled) {
      return "Cancelled"
    } else {
      return payment_status
    }
  }

  var jsonObj = [];
  for (let i = 0; i < allCourtType.length; i++) {
    var item = {};
    item["id"] = allCourtType[i].id.toString();
    item["booking_id"] = (
      <div className="Booking-details-td-left-space">
        {allCourtType[i].booking_id}
      </div>
    );

    item["created_date"] = (
      <div className="Booking-details-td-right-space">
        {allCourtType[i]?.created_at.split("T")[0]}{" "}
        {moment(allCourtType[i]?.created_at.split("T")[1], "HH:mm:ss").format(
          "hh:mm A"
        )}
      </div>
    );

    item["booking_date"] = (
      <div className="Booking-details-td-right-space">
        {allCourtType[i]?.booking_date} <br />
        {allCourtType[i]?.timeslot}
      </div>
    );

    item["service_provider"] = (
      <div className="Booking-details-td-right-space">
        {allCourtType[i]?.main_court.courtName + " -"}
        <br />
        {"COURT " + allCourtType[i]?.padel_court?.courtname}
      </div>
    );

    item["client"] = (
      <div className="Booking-details-td-right-space">
        {allCourtType[i]?.user?.role !== "customer" ? (
          <AdminUserDetails id={allCourtType[i]?.booking_id} />
        ) : (
          <UserDetails
            first_name={allCourtType[i]?.user?.first_name}
            email={allCourtType[i].user.email}
            phone={allCourtType[i].user.phone_no}
          />
        )}
      </div>
    );

    item["status"] = (
      <div className="Booking-details-td-right-space">
        <div className="active-color-bg canceled-color-bg">
          <span
            className={
              (allCourtType[i].payment_status === "Completed" ||
                allCourtType[i].payment_status === "completed") &&
                allCourtType[i].availability === false
                ? "active-color-bg"
                : "canceled-color-bg"
            }
          >
            {PaymentStatus(allCourtType[i].availability, allCourtType[i].cancelled, allCourtType[i]?.payment_status)}
            {/* {
            allCourtType[i]?.payment_status === "Failed" ? "Timeout" : 
            (allCourtType[i].availability || allCourtType[i].cancelled)
              ? "Cancelled"
              : allCourtType[i]?.payment_status} */}
          </span>
          <br />
        </div>
        <span
          className={
            allCourtType[i].user.role !== "customer" ? "admin-bg" : "app-bg"
          }
        >
          {allCourtType[i].boooked_using}
        </span>
      </div>
    );

    item["view"] = (
      <div style={{ display: "flex" }}>
        <BookingDetailsModal
          color="primary"
          header
          title="Booking Details"
          userdetails={allCourtType[i]?.user}
          transactionId={allCourtType[i]?.booking_id}
          bookedUsing={allCourtType[i].boooked_using}
          cancelBoooking={() =>
            CancelBookings(allCourtType[i].booking_id, allCourtType[i].logs)
          }
        />
        {allCourtType[i]?.availability ? (
          ""
        ) : (
          <div
            className="modal-delete-btn"
            style={{ marginTop: 5, marginLeft: 5 }}
            onClick={() =>
              CancelBookings(allCourtType[i].booking_id, allCourtType[i].logs)
            }
          >
            <MdCancel style={{ fontSize: 17 }} />
          </div>
        )}
        <EditBookingModal
          color="primary"
          header
          title="Edit Booking"
          transactionId={allCourtType[i]?.booking_id}
          refreshData={refreshDetailsData}
        />
      </div>
    );

    var src_str = allCourtType[i].timeslot;
    var str_spl = src_str.split("-", 1);
    var d1 = new Date(allCourtType[i].booking_date + " " + src_str);
    var d2 = new Date(serverTime);
    var hours = (d2 - d1) / 36e5;
    var timeStart = new Date(d1).getTime();
    var timeEnd = new Date(d2).getTime();
    var hourDiff = timeEnd - timeStart;
    var minDiff = hourDiff / 60 / 1000; //in minutes
    var hDiff = hourDiff / 3600 / 1000;
    var hrr = Math.floor(hDiff);
    var minn = minDiff - 60 * hrr;
    var validateTime = hrr + ":" + minn;
    // console.log(allCourtType[i].availability)
    // if(allCourtType[i].availability === true){
    //   item["cancel"] = <Button className="icon" size="sm" color="danger"><p>Cancelled</p></Button>
    //   item["refund"] = <Button className="icon" size="sm"  onClick={()=>handleRefund(allCourtType[i].id, allCourtType[i].amount_paid, allCourtType[i].user.email, allCourtType[i].main_court.courtName,allCourtType[i].padel_court.courtname)}>Refund</Button>;
    //   // if(validateTime < 48+":"+0){
    //   //   item["refund"] = <Button className="icon" size="sm" disabled>Refund</Button>;
    //   // }else {
    //   //   item["refund"] = <Button className="icon" size="sm" onClick={()=>handleRefund(allCourtType[i].id, allCourtType[i].amount_paid, allCourtType[i].user.email, allCourtType[i].main_court.courtName,allCourtType[i].padel_court.courtname)}>Refund</Button>;
    //   // }
    // }
    // else{
    //   if(allCourtType[i].payment_status === "Pending"){
    //     item["cancel"] = <Button className="icon" size="sm" color="danger" disabled  onClick={()=>handleCancel(allCourtType[i].booking_id,allCourtType[i].booking_date,allCourtType[i].timeslot,allCourtType[i].padel_court.courtname)}>Cancel</Button>;
    //   } else {
    //     item["cancel"] = <Button className="icon" size="sm" color="danger"  onClick={()=>handleCancel(allCourtType[i].booking_id,allCourtType[i].booking_date,allCourtType[i].timeslot,allCourtType[i].padel_court.courtname)}>Cancel</Button>;
    //   }
    //   // item["refund"] = <Button className="icon" size="sm" disabled>Refund</Button>;
    //   item["refund"] = <Button className="icon" size="sm" disabled onClick={()=>handleRefund(allCourtType[i].id, allCourtType[i].amount_paid, allCourtType[i].user.email, allCourtType[i].main_court.courtName,allCourtType[i].padel_court.courtname)}>Refund</Button>;
    // }
    jsonObj.push(item);
  }

  const data = {
    columns: [
      {
        Header: "id",
        accessor: "id",
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "ID",
        accessor: "booking_id",
        disableGlobalFilter: true,
        width: 45,
      },
      {
        Header: "Created Date",
        accessor: "created_date",
        disableGlobalFilter: true,
        width: 140,
      },
      {
        Header: "Booking Date",
        accessor: "booking_date",
        disableGlobalFilter: true,
        width: 140,
      },

      {
        Header: "Service Provider",
        accessor: "service_provider",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Client",
        accessor: "client",
        disableGlobalFilter: true,
        width: 180,
      },
      {
        Header: "Payment Status",
        accessor: "status",
        disableGlobalFilter: true,
        width: 140,
      },

      {
        Header: "View Details",
        accessor: "view",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 150,
      },
      // {
      //   Header: 'Cancel',
      //   accessor: 'cancel',
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      //   width: 120,
      // },
      // {
      //   Header: 'Refund',
      //   accessor: 'refund',
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      //   width: 70,
      // }
    ],
    rows: jsonObj,
  };

  const CancelBookings = (bookingid, logs) => {
    let newLog;
    if (logs === "") {
      let AdminUserName = localStorage.getItem("AdminUserName");
      newLog = JSON.stringify([
        {
          created_or_edited_by: "Cancelled by user " + AdminUserName + "",
          created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
          created_or_edited_time: moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),
        },
      ]);
    } else {
      const prevLog = JSON.parse(logs);
      let AdminUserName = localStorage.getItem("AdminUserName");
      newLog = JSON.stringify([
        {
          created_or_edited_by: "Cancelled by user " + AdminUserName + "",
          created_or_edited_date: moment(new Date()).format("YYYY-MM-DD"),
          created_or_edited_time: moment(new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' })).format("hh:mm A"),
        },
        ...prevLog,
      ]);
    }
    swal({
      title: "Are you sure?",
      text: "You want to Cancel the Booking",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        CancelStaffBooking(bookingid);
        CancelCoacheBooking(bookingid);
        CancelBooking(bookingid, newLog).then((response) => {
          if (response.Message === "Booking Cancelled!!!") {
            swal({
              icon: "success",
              content: (
                <div>
                  <h1>Booking Cancelled</h1>
                </div>
              ),
            }).then(() => {
              refreshDetailsData();
            });
          }
        });
      } else {
        swal("Booking is not cancelled");
      }
    });
  };

  const DataNotFound = [
    {
      service_provider: "Data Not Found",
    },
  ];

  const handleRefund = (id, amt, email, main_court, padel_court) => {
    var court = main_court + " " + padel_court;
    const genSignatureUrl = `${environment.baseUrl}checkout`;

    const params = {
      currency: "AED",
      // "amount": `${amt * 100}`,
      amount: `${amt * 100}`,
      customer_email: `${email}`,
      language: "en",
      order_description: `${court}`,
      //"order_description": "Mina Rashid 4",
      access_code: "MTkAuRmgVl270B82kqey",
      merchant_identifier: "fc9fe2bb",
      parse_phrase: "51MO1sxGDZPtHyGATVpuyG!@",
      merchant_reference: `${id}`,
      command: "REFUND",
      return_url: "https://api.justpadelae.com/payment_return/",
    };

    localStorage.setItem("Parameters", JSON.stringify(params));
    axios
      .post(genSignatureUrl, params)
      .then(function (data) {
        if (data.status === 200 || data.status === 201) {
          setIsLoading(false);
          setSignature(data?.data);
          setPyamentHtml(data?.data);
          localStorage.setItem("RefundSignature", data?.data.signature);
        } else {
          alert("Server error");
        }
      })
      .catch(function (error) {
        setIsLoading(false);
      });
    const sign = localStorage.getItem("RefundSignature");
    const dataSend = {
      paramsData: params,
      signatureIs: sign,
    };
    if (signature) {
      history.push("/refund");
      axios.post("http://127.0.0.1:8000/refund/", dataSend).then((response) => {
        // console.log("Response Comes from refund api", response);
      });
    } else {
      alert("Error...While generating the signature");
    }
  };

  useEffect(() => {
    const Role = localStorage.getItem("Role");
    setCurrectUserRole(Role)
    localStorage.setItem("BookingListCurrentPageLimit", 10);
    localStorage.setItem("BookingListCurrentPage", 1);
    // getUser(1, 10);BookingListCurrentPage
    getUser(1, parseInt(localStorage.getItem("BookingListCurrentPageLimit")));
    if (localStorage.getItem("Token")) {
      // console.log("");
    } else {
      alert("You are not logged In. Please LogIn");
      history.push("/");
    }

    GetMainCourt().then((response) => {
      // console.log("mainCourt", response);
      setMainCourt(response);
    });

    GetRegisteredUser().then((response) => {
      setRegisterdUser(response);
    });

  }, []);

  const handelView = (id) => {
    history.push(`/bookings_details/${id}`);
  };

  const handleCancel = (courtTypeId, bookingDate, TimeSlot, CourtId) => {
    const CancelBookingUrl = `${environment.baseUrl}cancelbooking`;

    const data = {
      booking_id: courtTypeId,
      booking_date: bookingDate,
      timeslot: TimeSlot,
      court: CourtId,
    };

    axios.post(CancelBookingUrl, data).then((response) => {
      // console.log("Response", response)
    });
    setAllCourtType(() =>
      allCourtType.filter((todo) => todo.id !== courtTypeId)
    );
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [100, 200, 300, 400],
    placeholder: "Search by Name...",
  };

  async function handleLimitChange(e) {
    localStorage.setItem("BookingListCurrentPageLimit", e.target.value);
    let currentPage = parseInt(localStorage.getItem("BookingListCurrentPage"));
    setInitialPage(0);
    await getUser(1, parseInt(e.target.value));
  }

  useEffect(() => {
    setInitialPage(initialPage);
  }, [initialPage]);

  function mainCourtChangeHandler(e) {
    const filteredMainCourt = mainCourt?.filter(
      (item) => item["courtName"] === e.target.value
    );
    setMainCourtId(filteredMainCourt[0]?.id);
    GetPadelCourt().then((response) => {
      const padelCourt = [];
      let padelCourts = response;
      let padelCourtFilter = padelCourts.filter(function (padelCourts) {
        return padelCourts.maincourt.id === filteredMainCourt[0]?.id;
      });
      padelCourtFilter.map((data) => {
        padelCourt.push({ id: data.id, courtname: data.courtname });
      });
      setPadelCourtList(padelCourt);
    });
  }

  function handleEmailChange(e) {
    const filteredEmail = registerdUser?.filter(
      (item) => item["email"] === e.target.value
    );
    setUserId(filteredEmail[0]?.id);
  }

  const onSubmit = (data) => {
    setSpinner(true);
    setAllCourtType([]);
    // if (data.email) {
    //   console.log(data.email)
    //   axios.get(`${environment.baseUrl}admin-booking-email/${data.email}`).then((response) => {
    //     setAllCourtType(response?.data?.data)
    //   })
    // }
    localStorage.setItem("BookingListCurrentPage", 1);
    localStorage.setItem("searchdata", JSON.stringify(data));
    setSearchData(true);
    let page = 1;
    let pageSize = parseInt(
      localStorage.getItem("BookingListCurrentPageLimit")
    );
    const Role = localStorage.getItem("Role");
    let SearchUrl;
    if (Role === "admin") {
      SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${page}&page_size=${pageSize}`;
    } else if (Role === "brightlearner") {
      SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=12&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${page}&page_size=${pageSize}`;
    } else if (Role === "brightonadmin") {
      SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=11&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${page}&page_size=${pageSize}`;
    } else if (Role === "alnoor") {
      SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=13&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${page}&page_size=${pageSize}`;
    } else {
      SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${page}&page_size=${pageSize}`;
    }
    // const SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${page}&page_size=${pageSize}`;
    const result = axios.get(SearchUrl).then((response) => {
      setInitialPage(0);
      if (response?.data?.count > 0) {
        setSpinner(false);
        // if (data.email) {
        //   setAllCourtType([...allCourtType, response?.data?.results]);
        // } else {
        //   setAllCourtType(response?.data?.results);
        // }
        setAllCourtType(response?.data?.results);
        setpageCount(Math.ceil(response?.data?.count / limit));
      } else {
        setSpinner(false);
        setAllCourtType([]);
        setpageCount(Math.ceil(response?.data?.count / limit));
      }
    });
  };

  const handlePageClick = async (data) => {
    setSpinner(true);
    setAllCourtType([]);
    let currentPage = data.selected + 1;
    setInitialPage(data.selected);
    let newlimit = parseInt(
      localStorage.getItem("BookingListCurrentPageLimit")
    );
    localStorage.setItem("BookingListCurrentPage", currentPage);
    if (!searchData) {
      await getUser(currentPage, newlimit);
    } else {
      let data = JSON.parse(localStorage.getItem("searchdata"));
      const Role = localStorage.getItem("Role");
      let SearchUrl;
      if (Role === "admin") {
        SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${currentPage}&page_size=${newlimit}`;
      } else if (Role === "brightlearner") {
        SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=12&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${currentPage}&page_size=${newlimit}`;
      } else if (Role === "brightonadmin") {
        SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=11&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${currentPage}&page_size=${newlimit}`;
      } else if (Role === "alnoor") {
        SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=13&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${currentPage}&page_size=${newlimit}`;
      } else {
        SearchUrl = `${environment.baseUrl}search-courtbooking?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${currentPage}&page_size=${newlimit}`;
      }

      const result = axios(SearchUrl).then((response) => {
        if (response?.data?.count > 0) {
          setSpinner(false);
          setAllCourtType(response?.data?.results);
          setpageCount(Math.ceil(response?.data?.count / limit));
        } else {
          alert("Data is not available for search");
          setSpinner(false);
        }
      });
    }
    setSpinner(false);
  };

  const handleResetSearchForm = () => {
    reset({});
    setSearchData(false);
    localStorage.removeItem("searchdata");
    getUser(1, parseInt(localStorage.getItem("BookingListCurrentPageLimit")));
  };

  const clear = (event) => {
    event.target.value = "";
  };

  const downloadExcel = () => {
    let newlimit = parseInt(
      localStorage.getItem("BookingListCurrentPageLimit")
    );
    let currentPage = parseInt(localStorage.getItem("BookingListCurrentPage"));
    let data = JSON.parse(localStorage.getItem("searchdata"));

    let GetBookingList;
    if (searchData) {
      GetBookingList = `${environment.baseUrl}court-booking-report-search?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${data.email}&payment_status=${data.payment_status}&page=${currentPage}&page_size=${newlimit}`;
    } else {
      GetBookingList = `${environment.baseUrl}court-booking-report?page=${currentPage}&page_size=${newlimit}`;
    }

    axios({
      url: GetBookingList,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `BookingList-${currentPage}To${newlimit}.csv`
      );
      document.body.appendChild(link);
      link.click();
    });
    //console.log(`${environment.baseUrl}search-court-booking-report?created_at_after=${data.created_at_after}&created_at_before=${data.created_at_before}&booking_date_after=${data.booking_date_after}&booking_date_before=${data.booking_date_before}&main_court=${mainCourtId}&padel_court=${data.padel_court}&booking_id=${data.booking_id}&user=${userId}&payment_status=${data.payment_status}&page=${currentPage}&page_size=${newlimit}`)
  };

  const DownloadAllBookingsReport = () => {
    const win = window.open("/download-excel", "_blank");
    win.focus();
    return;
    setExcelProgress(0);
    setDownloadFile(true);
    const DownloadAllBookingsReportUrl = `${environment.baseUrl}all-court-booking-report`;
    axios({
      url: DownloadAllBookingsReportUrl,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setExcelProgress(percentCompleted);
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `JustPadelCourtBookingDetails.csv`);
      document.body.appendChild(link);
      link.click();
      setDownloadFile(false);
    }).catch(function (error) {
      console.log('Error', error.message);
    });
  };

  return (
    <>
      <Col md={12} lg={12}>
        {isLoading && (
          <div class="loader-div">
            <span class="loader">
              <div className="center-spinner">
                <Spinner className="table-fetch-spinner" />
              </div>
            </span>
          </div>
        )}
        <Card>
          <CardBody>
            <div className="react-table__wrapper">
              <div className="card__title bookings">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="filter-fields">
                    <input
                      type="number"
                      className="filter-code"
                      name="booking_id"
                      placeholder="Id"
                      {...register("booking_id")}
                    />
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        width: "13.5%",
                        marginRight: 7,
                      }}
                    >
                      {/* <input type="date" name="created_at_after" className="filter-created" {...register("created_at_after")} placeholder="From" /> */}
                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        {...register("created_at_after")}
                        name="created_at_after"
                        className="filter-created"
                        placeholder="From"
                      />

                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        name="created_at_before"
                        className="filter-created"
                        {...register("created_at_before")}
                        style={{ marginTop: 6 }}
                        placeholder="To"
                      />
                    </div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        width: "13.5%",
                        marginRight: 7,
                      }}
                    >
                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        className="filter-date"
                        name="booking_date_after"
                        {...register("booking_date_after")}
                        placeholder="From"
                      />

                      <input
                        type="text"
                        onFocus={(e) => (e.currentTarget.type = "date")}
                        onBlur={(e) => (e.currentTarget.type = "text")}
                        className="filter-date"
                        name="booking_date_before"
                        {...register("booking_date_before")}
                        placeholder="To"
                        style={{ marginTop: 6 }}
                      />
                    </div>
                    <input
                      list="main_courts"
                      name="main_court"
                      id="main_court"
                      className="filter-service"
                      placeholder="Main Court"
                      {...register("main_court")}
                      onChange={mainCourtChangeHandler}
                      onClick={clear}
                    />
                    <datalist id="main_courts">
                      {filteredMainCourt.map((data) => {
                        return (
                          <option data-value={data.id}>{data.courtName}</option>
                        );
                      })}
                    </datalist>
                    <select
                      className="filter-service-provider"
                      name="padel_court"
                      {...register("padel_court")}
                    >
                      <option value="">Padel Court </option>
                      {padelCourtList?.map((data) => {
                        return (
                          <option value={data.id}>{data.courtname}</option>
                        );
                      })}
                    </select>
                    <input
                      list="emails"
                      type="email"
                      name="email"
                      id="email"
                      className="filter-client"
                      placeholder="Email"
                      {...register("email")}
                      onChange={handleEmailChange}
                    />
                    <datalist id="emails">
                      {registerdUser.map((data) => {
                        return (
                          <option data-value={data.id}>{data.email}</option>
                        );
                      })}
                    </datalist>
                    <input
                      list="payment_status"
                      name="payment_status"
                      id="payment"
                      className="filter-service"
                      placeholder="Payment Status"
                      {...register("payment_status")}
                    />
                    <datalist id="payment_status">
                      <option value="">Select Payment Status</option>
                      <option data-value="Completed">Completed</option>
                      <option data-value="Pending">Pending</option>
                      <option data-value="Failed">Failed</option>
                    </datalist>
                    <div className="btns-container">
                      <div
                        className="clear-btn"
                        onClick={() => handleResetSearchForm()}
                      >
                        Clear
                      </div>
                      <button
                        className="apply-btn"
                        type="submit"
                      // onClick={handleSubmit(onSubmit)}
                      >
                        Apply
                      </button>
                      <div className="center-spinner">
                        {spinner ? (
                          <Spinner className="table-fetch-spinner" />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              {(currectUserRole == "admin" || currectUserRole == "manager") && <div className="dowloadbtn">
                <div>
                  <div className="download-booking-table-xls-button-all" color="warning" onClick={() => DownloadAllBookingsReport()}>
                    Download Booking Report
                  </div>
                </div>
                {downloadFile ? <MakingFileDownload /> : null}
                <div className="download-booking-table-xls-button" onClick={() => downloadExcel()}>
                  Download as XLS
                </div>
              </div>}

              <div className="table-id-desabled pagination-hide hide-filtername hover-effect hide-table-costomizer second-child-space">
                <ReactTableBase
                  id="table-to-xls"
                  responsive={true}
                  key={withSearchEngine ? "searchable" : "common"}
                  columns={data.columns}
                  // data={DataNotFound}
                  // {data.rows.length === 0 ? data={DataNotFound} : data={data.rows} }
                  data={data.rows.length === 0 ? DataNotFound : data.rows}
                  // data={data.rows}
                  tableConfig={tableConfig}
                />
              </div>
            </div>
            <div className="custom-pagination">
              <div className="records-per-page">
                <select
                  value={localStorage.getItem("BookingListCurrentPageLimit")}
                  onChange={handleLimitChange}
                  name="limit"
                  id="select-pagination"
                >
                  {mannualLimits &&
                    mannualLimits.map((mannualLimits) => (
                      <option value={mannualLimits}>{mannualLimits}</option>
                    ))}
                </select>
                &nbsp;&nbsp;&nbsp;records per page
              </div>

              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={initialPage}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
export default UsersListCard;
