/* eslint-disable */
const validate = (values) => {
    const errors = {};
    // if (!values.username) {
    //   errors.username = 'Username field shouldn’t be empty';
    // }
    // if (!values.email) {
    //   errors.email = 'Email field shouldn’t be empty';
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    //   errors.email = 'Invalid email address';
    // }
    // if (!values.url) {
    //   errors.url = 'Url field shouldn’t be empty';
    // } else if (!/^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
    //   .test(values.url)) {
    //   errors.url = 'Invalid url';
    // }
    // if (!values.password) {
    //   errors.password = 'Password field shouldn’t be empty';
    // } else if (values.password !== 'dragon') {
    //   errors.password = 'The password is incorrect';
    // }
    if (!values.startdate) {
        errors.startdate = 'Please select start date';
    }
    if (!values.enddate) {
        errors.enddate = 'Please select end date';
    }
    if (!values.starttime) {
        errors.starttime = 'Please select Start Time';
    }
    if (!values.endtime) {
        errors.endtime = 'Please select End Time';
    }
    if (!values.maincourt) {
        errors.maincourt = 'Please select Main Court';
    }
    if (!values.padelcourt) {
        errors.padelcourt = 'Please select Padel Court';
    }
    if (!values.customername) {
        errors.customername = 'Please Enter the customer name';
    }
    if (!values.maincourt) {
        errors.maincourt = 'Please Select the main court';
    }
    if (!values.customeremail) {
      errors.customeremail = 'Email field shouldn’t be empty';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.customeremail)) {
      errors.customeremail = 'Invalid email address';
    }
    if(!values.customerphoneno) {
        errors.customerphoneno = 'Contact field shouldn’t be empty';
    }else if(values.customerphoneno.length<10){
        errors.customerphoneno = 'Contact field should contains 10 digits'
    }else if(values.customerphoneno.length>10){
        errors.customerphoneno = 'Contact field should not contains more than 10 digits'
    }
    if (!values.amountpaidby) {
        errors.amountpaidby = 'Please Select the amount payment method';
    }
    if (!values.comments) {
        errors.comments = 'Comments field shouldn’t be empty';
    }
    if (!values.amountpaid) {
        errors.amountpaid = 'Amount field shouldn’t be empty';
    }

    return errors;
};

export default validate;
