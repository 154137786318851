import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from "../../customAxios";
import { environment } from "../env";
import { Col, Card, CardBody, Spinner } from "reactstrap";
import ReactTableCustomizer from "../../shared/components/table/components/ReactTableCustomizer";
import ReactTableBase from "../../shared/components/table/ReactTableBase";

const UserBookingDetailsByEmail = () => {

  const [isSortable, setIsSortable] = useState(false);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [adminSpinner, setAdminSpinner] = useState(false);
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const [userAdminBookingData, setUserAdminBookingData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);


  const { id } = useParams();
  const getUserDetailsUrl = `${environment.baseUrl}user/${id}`;

  const getUserAdminBookingData = async (email) => {
    setAdminSpinner(true)
    const getUserAdminBookingUrl = `${environment.baseUrl}admin-booking-email/${email}`;

    const result = await axios.get(getUserAdminBookingUrl)
      .then((response) => {
        if (response) {
          setAdminSpinner(false)
          setUserAdminBookingData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  const getUserDetails = async () => {
    const result = await axios
      .get(getUserDetailsUrl)
      .then((response) => {
        setUserDetails(response?.data);
        getUserAdminBookingData(response?.data?.email);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    getUserDetails();
  }, [])

  var jsonObj1 = [];

  const DataNotFound = [
    {
      first_name: "Data Not Found",
    },
  ];

  const DataNotFoundCourt = [
    {
      main_court: "Data Not Found",
    },
  ];

  const newdata1 = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Booking Date',
        accessor: 'booking_date',
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: 'Booking ID',
        accessor: 'booking_id',
        disableGlobalFilter: false,
        width: 45,
      },
      {
        Header: 'Time',
        accessor: 'time',
        disableGlobalFilter: false,
        width: 100,
      },
      {
        Header: 'Email',
        accessor: "email",
        disableGlobalFilter: false,
        width: 120,
      },
      {
        Header: 'Name',
        accessor: 'first_name',
        disableGlobalFilter: false,
        width: 70,
      },
      {
        Header: 'Phone No',
        accessor: 'phone_no',
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: 'Main Court',
        accessor: 'main_court',
        disableGlobalFilter: false,
        width: 65,
      },
      // {
      //   Header: 'Padel Court',
      //   accessor: 'padel_court',
      //   disableGlobalFilter: false,
      //   width: 45,
      // },
      {
        Header: 'Payment Status',
        accessor: 'payment_status',
        disableGlobalFilter: true,
        width: 85,
      },
    ],
    data: jsonObj1
  };
  for (let i = 0; i < userAdminBookingData?.length; i++) {
    var item1 = {}
    item1["id"] = i + 1;
    item1["booking_date"] = (userAdminBookingData[i].booking_date).toString();
    item1["booking_id"] = (userAdminBookingData[i].booking_id).toString();
    item1["time"] = (userAdminBookingData[i]?.timeslot);


    if (userAdminBookingData[i]?.booked_slots?.length > 1) {
      for (let j = 0; j < userAdminBookingData[i].booked_slots.length; j++) {
        if (userAdminBookingData[i]?.booked_slots[j]?.main_court === userAdminBookingData[i]?.booked_slots[j + 1]?.main_court
          && userAdminBookingData[i]?.booked_slots[j]?.main_court_name === userAdminBookingData[i]?.booked_slots[j + 1]?.main_court_name
          && userAdminBookingData[i]?.booked_slots[j]?.time.split("-")[1] === userAdminBookingData[i]?.booked_slots[j + 1]?.time.split("-")[0]) {
          item1["time"] = ` ${userAdminBookingData[i]?.booked_slots[0]?.time.split("-")[0]} - ${userAdminBookingData[i]?.booked_slots[j + 1]?.time.split("-")[1]}`;
        }
      }
    }

    item1["email"] = (userAdminBookingData[i].user.email).toString();
    item1["phone_no"] = userAdminBookingData[i].user.phone_no === "null" ? '' : userAdminBookingData[i].user.phone_no;
    item1["payment_status"] =
      (<div className="Booking-details-td-right-space">
        <div className="active-color-bg canceled-color-bg">
          <span className={(userAdminBookingData[i].payment_status === "completed" && userAdminBookingData[i].availability === false) ? "active-color-bg" : "canceled-color-bg"}>{(userAdminBookingData[i].availability || userAdminBookingData[i].cancelled) ? "Cancelled" : userAdminBookingData[i]?.payment_status}</span><br />
        </div>
        {/* <span
          className={
            userAdminBookingData[i].user.role !== "customer" ? "admin-bg" : "app-bg"
          }
        >
          {userAdminBookingData[i]?.boooked_using}
        </span> */}
      </div>);
    item1["first_name"] = userAdminBookingData[i].user.first_name === "null" ? '' : userAdminBookingData[i].user.first_name;
    item1["main_court"] = `${userAdminBookingData[i].main_court.courtName} - Court ${userAdminBookingData[i].padel_court.courtname}`;
    // item1["padel_court"] = "Court "+userAdminBookingData[i].padel_court.courtname;
    jsonObj1.push(item1);
  }


  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by Name...',
  };

  return (
    <>
      <div className="center-spinner">
        {adminSpinner ? <Spinner className="table-fetch-spinner" /> : ''}
      </div>
      <div className="react-table__wrapper hide-table-costomizer">
        <div className="card__title">
          <h5 className="bold-text">Admin Booking Details</h5>
        </div>
        <div className="pagination-hide">
          <ReactTableCustomizer
            handleClickIsSortable={handleClickIsSortable}
            handleClickWithPagination={handleClickWithPagination}
            handleClickWithSearchEngine={handleClickWithSearchEngine}
            handleClickIsResizable={handleClickIsResizable}
            isResizable={isResizable}
            isSortable={isSortable}
            withPagination={withPagination}
            withSearchEngine={withSearchEngine}
          />
        </div>
      </div  >
      <div className="table-id-desabled  hover-effect hide-table-costomizer">
        <ReactTableBase
          key={withSearchEngine ? 'searchable' : 'common'}
          columns={newdata1.columns}
          data={newdata1.data.length === 0 ? DataNotFound : newdata1.data}
          tableConfig={tableConfig}
        />
      </div>
    </>
  )
}

export default UserBookingDetailsByEmail