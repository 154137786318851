import React from 'react';
import {
  Col, Container, Row
} from 'reactstrap';
import SlidesListV2 from './components/SlidesListV2';


const List_SlidesV2 = () => {
  return (
    <Container>
      <Row>
        <Col md={10}>
          <h3 className="page-title">Slides V2</h3>
        </Col>
      </Row>
      <Row>
        <SlidesListV2 />
      </Row>
    </Container>
  )
}

export default List_SlidesV2