// export default BookingDetailsTabV2

import React, { useState, useEffect } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  CardBody,
  Card,
  Col,
  Spinner,
  Input,
  Row,
} from "reactstrap";
import classnames from "classnames";
import BookingDetailsUserEditForm from "./BookingDetailsUserEditForm";

import AddonsComponent from "./AddonsComponent";

import "./Tab.css";

import "./BookingDetailsTab.css";
import BookingDetailTabPane from "./BookingDetailTabPane";
import BookingDetailTransactionTabPane from "./BookingDetailTransactionTabPane";
import { environment } from "../../../env";
import axios from "../../../../customAxios";
import BookingDetailsUserTabTable from "./BookingDetailsUserTabTable";
import BookingDetailsTabTable from "./BookingDetailsTabTable";
import moment from 'moment';
import BulkBooking from "../../../Forms/BulkBooking";
import BookingDetailsV2SplitShareTab from "./BookingDetailsV2SplitShareTab";
import swal from "sweetalert";




const BookingDetailsTabV2 = ({ transactionId, toggleModal, cancelBoooking, prePopulatedDated, bookedUsing }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [bookingDetails, setBookingDetails] = useState();
  const [displayComment, setDisplayComment] = useState([{ comment: "No Comment" }]);

  const [timeToPlay, setTimeToPlay] = useState();
  const [hoursToPlay, setHoursToPlay] = useState();
  const [promotionUsed, setPromotionUsed] = useState(false);
  const [sharedCompleted, setSharedCompleted] = useState();
  const [bookedSlots, setBookedSlots] = useState([]);
  const [updateAdminBookings, setUpdateAdminBookings] = useState(false);
  const [loyaltypoint, setLoyaltypoints] = useState();
  const [playedGames, setPlayedGames] = useState();
  const [pointSplitable, setPointSplitable] = useState(false);
  const [finalAddonList, setFinalAddonList] = useState([]);
  const [clickAddonView, setClickAddonView] = useState(false);


  const [isLoading, setIsLoading] = useState(false);

  const [spinner, setSpinner] = useState(false);
  const [splitError, setSplitError] = useState(false);
  const [adminBookingUserDetails, setAdminBookingUserDetails] = useState();


  // --- states for split points ---
  const [playerOneData, setPlayerOneData] = useState();
  const [playerTwoData, setPlayerTwoData] = useState();
  const [playerThreeData, setPlayerThreeData] = useState();
  const [playerFourData, setPlayerFourData] = useState();
  const [clearSearchPlayerTwo, setClearSearchPlayerTwo] = useState(false);
  const [viewSplitPoints, setViewSplitPoints] = useState(false);



  // const getAdminBookedUser = async () => {
  //   const getUserIdUrl = `${environment.baseUrl}admin_booking/${transactionId}`;
  //   await axios.get(getUserIdUrl).then(async (response) => {
  //     if (response.data.msg !== "Data is not available") {
  //       const getUserIdUrl = `${environment.baseUrl}filter-user/${response?.data?.customer_email}`;
  //       await axios.get(getUserIdUrl).then(async (res) => {
  //         setAdminBookingUserDetails(res?.data[0]);
  //       })
  //     }
  //   })
  // };



    const ShareAdminLoyaltyPoints = async () => {

      const getUserIdUrl = `${environment.baseUrl}admin_booking/${transactionId}`;
      await axios.get(getUserIdUrl).then(async (response) => {
  
        const getUserIdUrl = `${environment.baseUrl}filter-user/${response?.data?.customer_email}`;
        await axios.get(getUserIdUrl).then(async (res) => {
          setAdminBookingUserDetails(res?.data[0]);
  
  
          const payload = {
            "to": res?.data[0]?.id,
            "from": 1,
            "points": bookedSlots?.length * 12,
            "bookingId": transactionId
          }
  
          const GetShareLoyaltyPointsUrl = `${environment.baseUrl}loyaltypoint/loyaltyshare/`;
  
          const result = await axios.post(GetShareLoyaltyPointsUrl, payload).then((response) => {
            if (response.data.Message === "loyaltypoints shared successfully") {
              UpdateGameAfterShare(res?.data[0]?.id);
            }
            else {
              console.log("Error whiile sharing points");
            }
          })
        })
        return;
      })
  };

  const ShareAdminLoyalty = async () => {
    const ShareAdminLoyaltyURL = `${environment.baseUrl}loyaltypoint/share-admin-loyalty/`;
    const payload = { "bookingId": transactionId }
    const result = await axios.post(ShareAdminLoyaltyURL, payload)
  };


  const UpdateGameAfterShare = async (userId) => {
    const UpdateGameAfterShareUrl = `${environment.baseUrl}loyaltypoint/update-loyaltygames-admin-share/`;
    const payload = {
      "userId": userId,
      "slots": bookedSlots?.length,
    };
    const result = await axios.post(UpdateGameAfterShareUrl, payload)
  }

  const StartandEndTime = (data) => {
    const startTimes = data?.booked_slots[0]?.time?.split("-")[0];
    const endsTimes = data?.booked_slots.slice(-1)[0]?.time?.split("-")[1];

    setTimeToPlay(startTimes + "-" + endsTimes);

    // -----------------------functionality to get hours to play code starts here-------
    var startTime = moment(startTimes, "HH:mm a");
    var endTime = moment(endsTimes, "HH:mm a");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) - hours * 60;
    setHoursToPlay(hours + " hr . " + minutes + " mins.");
    // -----------------------functionality to get hours to play code ends here-------
  };


  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const GetBookingDetails = () => {
    setIsLoading(true)
    const bookingUrl = `${environment.baseUrl}courtbooking-details/`;
    axios.post(bookingUrl, { bookingId: transactionId }).then((response) => {
      console.log("🚀 ~ file: BookingDetailsTabV2.jsx:162 ~ GetBookingDetails ~ bookingUrl:", bookingUrl)
      console.log("response.data.booking_detailsasfsdfdfadfdf====>",response.data.booking_details)

      setBookingDetails(response.data.booking_details);
      StartandEndTime(response.data.booking_details);
      const unique = [];
      response?.data?.booking_details?.booked_slots.map((x) =>
        unique.filter(
          (a) =>
            a.time == x.time && a.date == x.date && a.courtname == x.courtname
        ).length > 0
          ? null
          : unique.push(x)
      );
      setBookedSlots(unique);
      if (response.data.booking_details.comments == "") {
        setDisplayComment([{ comment: "No Comment" }])
      } else {
        commentData(response.data.booking_details.comments);
      }
      setIsLoading(false)
    })
  };

  const GetSplitpayDetails = () => {
    const splitpayUrl = `${environment.baseUrl}split-between-users/${transactionId}` 
    console.log("🚀 ~ file: BookingDetailsTabV2.jsx:188 ~ GetSplitpayDetails ~ splitpayUrl:", splitpayUrl)
    axios.get(splitpayUrl).then((response)=> {
      console.log("🚀 ~ file: BookingDetailsTabV2.jsx:188 ~ axios.get ~ response:", response)
      
    }).catch(error => console.log(error))

  }

  // const GetPromotionBooking = (bookingId) => {
  //   const GetPromotionBookingUrl = `${environment.baseUrl}promotion-v2-used/${bookingId}/`;
  //   axios.get(GetPromotionBookingUrl).then((response) => {
  //     setPromotionUsed(response.data);
  //   })
  // };

  // const VerifyAdminLoyaltyPointShare = async (transactionId) => {
  //   const ShareAdminLoyaltyURL = `${environment.baseUrl}loyaltypoint/verify-share-admin-loyalty/${transactionId}/`;
  //   const result = await axios.get(ShareAdminLoyaltyURL).then((sharedResponse) => {
  //     setSharedCompleted(sharedResponse.data);
  //   })
  // };

  const loyaltypoints = () => {  
    if( bookingDetails?.boooked_using !== 'admin') {
      const getloyaltypointsurl = `${environment.baseUrl}loyaltypoint/getloyaltypoints/${bookingDetails?.user?.id}`;
    axios.get(getloyaltypointsurl).then((response) => {
      PlayerOneDetails(bookingDetails?.user?.id);
      setLoyaltypoints(response?.data);
      setPlayedGames(response?.data?.loyaltygames);
    });
    } 
  };

  const PlayerOneDetails = (playerOneId) => {
    const userDetailsUrl = `${environment.baseUrl}filter-user/${playerOneId}`;
    axios.get(userDetailsUrl).then((response) => {
      setPlayerOneData(response.data[0]);
    });
  };

  // const PointSplitable = () => {
  //   const PointSplitableUrl = `${environment.baseUrl}loyaltypoint/point-splitable/${transactionId}/`;
  //   axios.get(PointSplitableUrl).then((response) => {
  //     setPointSplitable(response?.data?.splitable);
  //   })
  // };

  const SplitPoints = () => {
    swal({
      title: `Eligible Points to Earn: ${bookedSlots?.length * 12}`,
      text: `Booking ID: #${transactionId}`,
      content: (
        <div>
          <p>Click YES to send the points to the customer</p>
        </div>
      ),
      icon: "warning",
      // buttons: true,
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((data) => {
      if (data) {
        setViewSplitPoints(true);
        ShareAdminLoyaltyPoints();  
        ShareAdminLoyalty();        
        swal({
          title: "Points sent successfully",
          icon: "success",
          // buttons: true,
          buttons: ["View Client Details", "Close"],
          // dangerMode: true,
        }).then((condition) => {
          if (condition) {
            // VerifyAdminLoyaltyPointShare(transactionId);
            setUpdateAdminBookings(true);
            loyaltypoints();
            // PointSplitable();
            setActiveTab("7");
          } else {
            setActiveTab("3");
          }
        })

      } else {
        swal({
          content:
            <div>
              <h4>LoyaltyPoints not shared</h4>
            </div>
        });
      }
    });
  };

  const commentData = (comment) => {
    var commentdata = JSON.parse(comment);
    if (typeof commentdata === "string") {
      setDisplayComment(JSON.parse(commentdata))
    }
    else {
      if (typeof commentdata == "string") {
        setDisplayComment(JSON.parse(commentdata))
      } else {
        setDisplayComment(commentdata)
      }
    }
  };


  const submitSplitPoint = (totalSharePoints) => {
    setSplitError(false);
    const player1Data = JSON.parse(localStorage.getItem("player1"));
    const player2Data = JSON.parse(localStorage.getItem("player2"));
    const player3Data = JSON.parse(localStorage.getItem("player3"));
    const player4Data = JSON.parse(localStorage.getItem("player4"));

    let combineSplitPoints = [
      player1Data,
      player2Data,
      player3Data,
      player4Data,
    ];

    combineSplitPoints = combineSplitPoints.filter((x) => x);

    let totalProvidedPoints = combineSplitPoints.reduce(function (
      accumulator,
      curValue
    ) {
      return accumulator + curValue.point;
    },
      0);

    if (totalProvidedPoints > totalSharePoints) {
      setSplitError(true);
      return;
    }

    let totalPointsExceptP1 = totalProvidedPoints - player1Data.point;
    combineSplitPoints[0].point = totalPointsExceptP1;
    combineSplitPoints[0].bookingId = transactionId;

    const splitPointUrl = `${environment.baseUrl}loyaltypoint/split-loyaltypoint/`;
    axios.post(splitPointUrl, combineSplitPoints).then((response) => {
      localStorage.removeItem("player1");
      localStorage.removeItem("player2");
      localStorage.removeItem("player3");
      localStorage.removeItem("player4");
      swal({
        icon: "success",
        content: (
          <div>
            <h3 className="w-100">
              Points are sucessfully shared/splited between players
            </h3>
          </div>
        ),
      }).then(() => {
        setSpinner(false);
        toggleModal();
      });
    });
  };

  const submitSplitPointAdmin = (totalSharePoints) => {
    setSplitError(false);
    const player1Data = JSON.parse(localStorage.getItem("player1"));
    const player2Data = JSON.parse(localStorage.getItem("player2"));
    const player3Data = JSON.parse(localStorage.getItem("player3"));
    const player4Data = JSON.parse(localStorage.getItem("player4"));

    let combineSplitPoints = [
      player1Data,
      player2Data,
      player3Data,
      player4Data,
    ];

    combineSplitPoints = combineSplitPoints.filter((x) => x);

    let totalProvidedPoints = combineSplitPoints.reduce(function (
      accumulator,
      curValue
    ) {
      return accumulator + curValue.point;
    },
      0);

    if (totalProvidedPoints > totalSharePoints) {
      setSplitError(true);
      return;
    }

    combineSplitPoints[0].bookingId = transactionId;
    combineSplitPoints[0].adminId = bookingDetails?.user?.id;

    const splitPointUrl = `${environment.baseUrl}loyaltypoint/split-loyaltypoint/`;
    axios.post(splitPointUrl, combineSplitPoints).then((response) => {
      localStorage.removeItem("player1");
      localStorage.removeItem("player2");
      localStorage.removeItem("player3");
      localStorage.removeItem("player4");
      swal({
        icon: "success",
        content: (
          <div>
            <h3 className="w-100">Points are sucessfully shared/splited between players</h3>
          </div>
        ),
      }).then(() => {
        setSpinner(false);
        toggleModal();
      });
    });
  };

 const GetAllBookingDetailsAdmin = async (bookingId) => {
  setIsLoading(true)
  const url = `${environment.baseUrl}all_bookingdetails_admin/${bookingId}`
  await axios.get(url).then((response) => {

    //promotion v2 use api
    setPromotionUsed(response.data.promotion);

    //admin-booking api
    if (response.data.adminBooking !== "Data is not available") {
      const getUserIdUrl = `${environment.baseUrl}filter-user/${response?.data?.adminBooking?.customer_email}`;
      axios.get(getUserIdUrl).then(async (res) => {
        setAdminBookingUserDetails(res?.data[0]);
      })
    }

    // loyaltypoint/verify-share-admin-loyalty/   api
    setSharedCompleted(response.data.adminLoyalty);

    //loyaltypoint/point-splitable  api
    setPointSplitable(response?.data?.splitable);
    setIsLoading(false)
    
  })
 }

  useEffect(() => {
    if (transactionId) {
      GetBookingDetails();
      GetSplitpayDetails();
      // GetPromotionBooking(transactionId);  
      // VerifyAdminLoyaltyPointShare(transactionId)  
      // getAdminBookedUser(); 
      GetAllBookingDetailsAdmin(transactionId)
    }

  }, [transactionId])


  return (
    <>
    {isLoading && (
        <Loader />
      )}
      <div className="tabs__wrap">
        <Nav tabs className="all-tabs w-100" >
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggle("1")}
            >
              Bookings
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Transactions
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggle("3")}
            >
              Client
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => toggle("4")}
            >
              Edit
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => toggle("5")}
            >
              Bulk Booking
            </NavLink>
          </NavItem>
          <NavItem onClick={() => loyaltypoints()} >
            <NavLink
              className={classnames({ active: activeTab === "7" })}
              onClick={() => toggle("7")}
            >
              Share/Split Points
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <BookingDetailTabPane bookingId={transactionId} toggle={toggleModal} cancelBoooking={cancelBoooking} toggletab={toggle} bookingDetails={bookingDetails} displayComment={displayComment} GetBookingDetails={GetBookingDetails} SplitPoints={SplitPoints} />
          </TabPane>
          <TabPane tabId="2">
            {activeTab === "2" && <BookingDetailTransactionTabPane bookingDetails={bookingDetails} bookingId={transactionId} toggle={toggleModal} cancelBoooking={cancelBoooking} toggletab={toggle} bookedUsing={bookingDetails.bookedUsing} />}
          </TabPane>
          <TabPane tabId="3">
            <h2 className="client-info">Client Info</h2>
            <BookingDetailsUserTabTable
              customer_name={bookingDetails?.user?.first_name}
              customer_email={bookingDetails?.user?.email}
              customer_phone={bookingDetails?.user?.phone_no}
            />
          </TabPane>
          <TabPane tabId="4">
            <Table responsive hover>
              <div className="Booking-details-edit-container">

                <BookingDetailsUserEditForm
                  booking_id={transactionId}
                  userId={bookingDetails?.user?.id}
                  client={bookingDetails?.user?.first_name}
                  location={bookingDetails?.main_court?.id}
                  service_provider={bookingDetails?.padel_court?.id}
                  booking_date={bookingDetails?.booking_date}
                  customer_email={bookingDetails?.user?.email}
                  customer_phone={bookingDetails?.user?.phone_no}
                  cancellationTime={timeToPlay}
                  amountpaid={bookingDetails?.amount_paid}
                  toggleModal={toggleModal}
                />

              </div>
            </Table>
          </TabPane>
          <TabPane tabId="5">
            <BulkBooking prePopulatedDated={prePopulatedDated} />
          </TabPane>
          <TabPane tabId="6">
            <h1>Add ons Booking {transactionId}</h1>
            {clickAddonView ? (
              <AddonsComponent
                bookingId={transactionId}
                addonsData={bookingDetails?.addons}
                finalAddonList={finalAddonList}
                closeModal={toggleModal}
                amountpaid={bookingDetails?.amount_paid}
                amountPercentage={bookingDetails?.amount_percentage}
              />
            ) : (
              "No"
            )}
          </TabPane>
          <TabPane tabId="7">

            <BookingDetailsV2SplitShareTab transactionId={transactionId} bookedUsing={bookedUsing} bookingDetails={bookingDetails} promotionUsed={promotionUsed} sharedCompleted={sharedCompleted} bookedSlots={bookedSlots} updateAdminBookings={updateAdminBookings} loyaltypoint={loyaltypoint} pointSplitable={pointSplitable} splitError={splitError} submitSplitPoint={submitSplitPoint} submitSplitPointAdmin={submitSplitPointAdmin} adminBookingUserDetails={adminBookingUserDetails} />

          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default BookingDetailsTabV2;

const Loader = () => {
  return (
    <div class="loader-div">
      <span class="loader">
        <div className="center-spinner">
          <Spinner className="table-fetch-spinner" />
        </div>
      </span>
    </div>
  );
};