/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
// import axios from "axios";
import axios from '../../../customAxios';
import { Col, Button, Card, CardBody, Row } from "reactstrap";
import { environment } from "../../env";

import { useHistory, Link } from "react-router-dom";
import EditOutlineIcon from 'mdi-react/EditOutlineIcon';
import DeleteOutlineIcon from 'mdi-react/DeleteOutlineIcon'
import SettingsIcon from 'mdi-react/SettingsIcon';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import  { AiFillEye, AiOutlineEdit, AiOutlineDelete,AiFillSetting } from 'react-icons/ai';

const CourtList = () => {
  const [allCourts, setAllCourts] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [role, setRole] = useState();
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const history = useHistory();

  const getMainCourt = `${environment.baseUrl}padelcourt`;
  const sendGetRequest = async () => {
    try {
      const resp = await axios.get(getMainCourt);
      setAllCourts(resp.data);
    } catch (err) {
      console.error(err);
    }
  };

  function handleEdit(id) {
    history.push(`/edit_courts/${id}`);
  }

  const getRole = () => {
    const localrole = localStorage.getItem('Role');
    const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localrole}`;
    // const getRoleUrl = `${environment.baseUrl}role_by_rolename/manager`;
    const result = axios.get(getRoleUrl).then((response) => {
      setRole(response.data);
      setPermissions(response.data);
    });
  };

  const setPermissions = (roledata) => {
    // const localrole = "manager";
    const localrole = localStorage.getItem('Role');
    let newRoleId;

    if (localrole === roledata.role_name) {
      let permission = JSON.parse(roledata.permissions);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "padel_court"
          if (e === page_name) {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
      console.log("");
    }
    
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
    
  };

  useEffect(() => {
    getRole();
    sendGetRequest();
    if (localStorage.getItem("Token")) {
      console.log("");
    } else {
      alert("You are not logged In. Please LogIn");
      history.push("/");
    }
  }, []);

  var jsonObj = [];
  
  
  for(let i=0; i<allCourts.length; i++){
    var item = {}
    item["image"] = <div style={{paddingLeft:10}}><img src={allCourts[i].courtimage} alt={allCourts[i].courtimage} style={{width:150,borderRadius:5}}/></div>
    item["courtname"] = <div className="right-space">{"Court "+ allCourts[i].courtname}</div>;
    item["maincourt"] = <div className="right-space">{allCourts[i].maincourt.courtName}</div>;
    item["view"] = <div className="right-space"><div className="modal-view-btn" size="sm" onClick={()=>handleViewDetails(allCourts[i].id)}><AiFillEye style={{fontSize:20}}/></div></div>;
    if(deleteing === true){
      item["edit"] = <div className="right-space"><div className="modal-edit-btn" size="sm" onClick={()=>handleEdit(allCourts[i].id)}><AiOutlineEdit style={{fontSize:20}}/></div></div>;
      // item["delete"] = <div className="right-space"><div className="modal-delete-btn" size="sm" onClick={()=>handleDelete(allCourts[i].id)}><AiOutlineDelete style={{fontSize:20}} /></div></div>;
      item["manage_timeslot"] = <div className="right-space"> <div className="modal-manage-btn" size="sm" onClick={()=>handleManageTimeslot(allCourts[i].id)}><AiFillSetting style={{fontSize:20}}/></div></div>;
    }
    else if (editing === true){
      item["edit"] = <div className="right-space"><div className="modal-edit-btn" size="sm" onClick={()=>handleEdit(allCourts[i].id)}><AiOutlineEdit style={{fontSize:20}}/></div></div>;
      item["manage_timeslot"] = <div className="right-space"><div className="modal-manage-btn" size="sm" onClick={()=>handleManageTimeslot(allCourts[i].id)}><AiFillSetting style={{fontSize:20}}/></div></div>;
    }
    jsonObj.push(item);
}

  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Image",
        accessor: "image",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 70,
      },
      {
        Header: "Name",
        accessor: "courtname",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Main Court",
        accessor: "maincourt",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "View Details",
        accessor: "view",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
      {
        Header: "Edit",
        accessor: "edit",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
      // {
      //   Header: "Delete",
      //   accessor: "delete",
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      //   width: 30,
      // },
      {
        Header: "Manage Timeslots",
        accessor: "manage_timeslot",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 30,
      },
    ],
    rows: jsonObj,
  };

  const handleDelete = (id) => {
    const deleteMainCourt = `${environment.baseUrl}padelcourt/${id}`;
    axios.delete(deleteMainCourt)
    setAllCourts(() => allCourts.filter((court) => court.id !== id));
  };

  const handleManageTimeslot = (id) => {
    history.push(`/managetimeslot/${id}`);
  };
  const handleViewDetails = (id) => {
    history.push(`/all_courts_view_details/${id}`);
  };

  const handleAddPadelCourt = () => {
    history.push("/court_court");
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
  };

  return (
    <>
        <Col md={12} lg={12} 
        style={{
          justifyContent:'flex-end',
          display:'flex',
          alignItems:'flex-end',
          }}>
          {deleteing ? (
            <div className="justpadel-buttons btn-position" onClick={() => handleAddPadelCourt()}>Add Padel Court</div>
          ) : (
            ""
          )}
        </Col>
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
          </div>
          <div className="table-id-desabled hover-effect second-child-space">
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
          />
          </div>
          
        </CardBody>
      </Card>
    </Col>
    </>
  );
};
export default CourtList;
