import React, { useState, useEffect } from "react";
import { Col, Button, ButtonToolbar, Card, CardBody, Spinner } from "reactstrap";
import axios from "../../../customAxios";
import S3FileUpload from "react-s3";
import { useHistory } from "react-router-dom";
import { environment } from "../../env";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import ImageUploader from "../../MainCourt/components/ImageUploader";
import swal from "sweetalert";

const config = {
  bucketName: "justpadel-courtimages",
  region: "us-east-1",
  accessKeyId: "AKIAZZLUGKQZZJT3G4FX",
  secretAccessKey: "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",
};

const CourtEditCard = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  
  const [mainCourtList, setMainCourtList] = useState();
  const [courtCategoryList, setCourtCategoryList] = useState();
  const [courtTypeList, setCourtTypeList] = useState([]);
  const [padelCourtImage, setPadelCourtImage] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  
  const boolenaOptions = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];
  
  const GetCategory = async () => {
    const categoryUrl = `${environment.baseUrl}category/`;
    const result = await axios(categoryUrl);
    
    var categoriesList = [];
    result.data.map((categories) => {
      categoriesList.push({
        label: categories.courtcategory,
        value: categories.id,
      });
    });
    setCourtCategoryList(categoriesList);
  };
  
  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts-admin/`;
    const result = await axios(mainCourtUrl);
    var mainCourts = [];
    result.data.map((maincourt) => {
      mainCourts.push({ label: maincourt.courtName, value: maincourt.id });
    });
    
    setMainCourtList(mainCourts);
  };
  
  const GetCourtType = async () => {
    const courtTypeUrl = `${environment.baseUrl}courttypes`;
    const result = await axios(courtTypeUrl);
    var courtType = [];
    result.data.map((courtTypes) => {
      courtType.push({
        label: courtTypes.courttype.toUpperCase(),
        value: courtTypes.id,
      });
    });
    setCourtTypeList(courtType);
  };
  
  const setImage = (imagesList) => {
    if(imagesList?.length>0){
      setImageError(false);
    }else{
      setImageError(true);
    }
    setPadelCourtImage(imagesList);
  };

  const onSubmit = async (formData) => { 
    setIsLoading(true);   
    if(padelCourtImage.length<=0){
      setImageError(true);
      setIsLoading(false);
      return
    }
    
    var UploadedImages = [];
    padelCourtImage.map(async (img)=>{
      if("resource" in img){
        UploadedImages.push({"resource":img.resource})
        if(padelCourtImage?.length === UploadedImages?.length){
          console.log("if condition");
          submitPadelCourt(formData, UploadedImages);
        }
      }else{
        await S3FileUpload.uploadFile(img.file, config)
        .then((data) => {
          UploadedImages.push({"resource":data.location});
          if(padelCourtImage?.length === UploadedImages?.length){
            console.log("else condition");
            submitPadelCourt(formData, UploadedImages);
          }
        })
        .catch((err) => {
          console.log("Error Info: ", err);
          setIsLoading(false);
        });
      }
    });
  };

  const submitPadelCourt = (formData, images) => {
    formData.courtcategory = formData.courtcategory.value;
    formData.enable_free_slot = formData.enable_free_slot.value;
    formData.ladies_only = formData.ladies_only.value;
    formData.courtType = formData.courtType.value;
    formData.maincourt = formData.maincourt.value;
    formData.courtimage = images[0].resource;

    const padelCourtUrl = `${environment.baseUrl}padelcourt`;

    axios
      .post(padelCourtUrl, formData)
      .then((response) => {
        if (response.data.Message == "Court Added Successfully") {
          swal({
            title: "Success",
            text: "Padel Courts are added successfully!",
            icon: "success",
            button: "Ok",
          }).then((value) => {
            history.push("/all_courts");
          });
          setIsLoading(false);
        } else {
          swal({
            title: "Error",
            text: response.data,
            icon: "error",
            button: "Ok",
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: err.messgae,
          icon: "error",
          button: "Ok",
        }).then((value) => {
          history.push("/all_courts");
        });
        setIsLoading(false);
      });
  };
  
  const mainCourtName = watch("maincourt");
  const padelCourtName = watch("courtname");
  const aboutPadelCourt = watch("about");
  
  useEffect(() => {
    GetCategory();
    GetMainCourt();
    GetCourtType();
  }, []);

  return (
    <>
      <Col md={12}>
        <h3 className="page-title w-100">
          {padelCourtName && "Add"} Padel Court {padelCourtName}{" "}
          {mainCourtName && `for ${mainCourtName.label}`}
        </h3>
      </Col>
      <Col md={12} lg={12}>
        {isLoading && (
          <div class="loader-div">
            <span class="loader">
              <div className="center-spinner">
                <Spinner className="table-fetch-spinner" />
              </div>
            </span>
          </div>
        )}
        <Card>
          <CardBody>
            <form
              className="form product-edit"
              encType="multipart/form-data"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Enter Court Name
                  </span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      placeholder="Enter Court Name"
                      {...register("courtname", { required: true })}
                    />
                  </div>
                  {errors.courtname && (
                    <ErrorMessageComponent
                      message={
                        "Please Enter Court Name, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Enter Court Display Name
                  </span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      placeholder="Enter Court Display Name"
                      {...register("display_name", { required: true })}
                    />
                  </div>
                  {errors.display_name && (
                    <ErrorMessageComponent
                      message={
                        "Please Enter Court Display Name, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Select Main Court
                  </span>
                  <div className="form__form-group-field">
                    <Controller
                      control={control}
                      name="maincourt"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <Select
                          value={value}
                          options={mainCourtList}
                          className="text-captialize w-100"
                          placeholder="Select Main Court"
                          onChange={onChange}
                          inputRef={ref}
                        />
                      )}
                    />
                  </div>
                  {errors.maincourt && (
                    <ErrorMessageComponent
                      message={
                        "Please Select Main Court, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Select Court Category
                  </span>
                  <div className="form__form-group-field">
                    <Controller
                      control={control}
                      name="courtcategory"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <Select
                          value={value}
                          options={courtCategoryList}
                          className="text-captialize w-100"
                          placeholder="Select Court Category"
                          onChange={onChange}
                          inputRef={ref}
                        />
                      )}
                    />
                  </div>
                  {errors.courtcategory && (
                    <ErrorMessageComponent
                      message={
                        "Please Select Court Category, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Enter Address</span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      placeholder="Enter Padel Court Address"
                      {...register("courtaddress", { required: true })}
                    />
                  </div>
                  {errors.courtaddress && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Address, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Enter Latitude</span>
                  <div className="form__form-group-field">
                    <input
                      type="number"
                      placeholder="Enter Padel Court Latitude"
                      step="any"
                      {...register("latitude", { required: true })}
                    />
                  </div>
                  {errors.latitude && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Latitude, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Enter Longitude
                  </span>
                  <div className="form__form-group-field">
                    <input
                      type="number"
                      placeholder="Enter Padel Court Longitude"
                      step="any"
                      {...register("longitude", { required: true })}
                    />
                  </div>
                  {errors.latitude && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Longitude, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Opening and Closing Time
                  </span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      placeholder="Enter Padel Court Opening and Closing Time"
                      {...register("timeslot", { required: true })}
                    />
                  </div>
                  {errors.latitude && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Opening and Closing Time, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Enter Court Price
                  </span>
                  <div className="form__form-group-field">
                    <input
                      type="number"
                      placeholder="Enter Padel Court Price"
                      {...register("price", { required: true })}
                    />
                  </div>
                  {errors.latitude && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Price, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Enter Additional Slot Price
                  </span>
                  <div className="form__form-group-field">
                    <input
                      type="number"
                      placeholder="Enter Additional Slot Price"
                      {...register("additional_slot_price", { required: true })}
                    />
                  </div>
                  {errors.latitude && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Additional Slot Price, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
              </div>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Something About Court
                  </span>
                  <div className="form__form-group-field">
                    <textarea
                      className="rounded"
                      placeholder="Something About Padel Court"
                      rows="10"
                      cols="50"
                      style={{ height: "110px" }}
                      {...register("about", { required: true, maxLength: 150 })}
                    />
                  </div>
                  {errors.about && errors.about.type === "required" && (
                    <ErrorMessageComponent
                      message={
                        "Please Write Something About Padel Court, It Should Not be Empty"
                      }
                    />
                  )}
                  {aboutPadelCourt?.length > 150 && (
                    <ErrorMessageComponent message={"Max length exceeded"} />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Enter Raitings</span>
                  <div className="form__form-group-field">
                    <input
                      type="number"
                      min="1"
                      max="5"
                      placeholder="Enter Padel Court Rating"
                      {...register("rating", { required: true })}
                    />
                  </div>
                  {errors.latitude && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Rating, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Enable Free Slot
                  </span>
                  <div className="form__form-group-field">
                    <Controller
                      control={control}
                      name="enable_free_slot"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <Select
                          value={value}
                          options={boolenaOptions}
                          className="text-captialize w-100"
                          placeholder="Enable Free Slots"
                          onChange={onChange}
                          inputRef={ref}
                        />
                      )}
                    />
                  </div>
                  {errors.enable_free_slot && (
                    <ErrorMessageComponent
                      message={
                        "Please Select Enable Free Slots, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Ladies Only</span>
                  <div className="form__form-group-field">
                    <Controller
                      control={control}
                      name="ladies_only"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <Select
                          value={value}
                          options={boolenaOptions}
                          className="text-captialize w-100"
                          placeholder="Ladies Only"
                          onChange={onChange}
                          inputRef={ref}
                        />
                      )}
                    />
                  </div>
                  {errors.ladies_only && (
                    <ErrorMessageComponent
                      message={
                        "Please Select Padel Court Ladies Only Type, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Ladies Time Slot
                  </span>
                  <div className="form__form-group-field">
                    <input
                      type="text"
                      placeholder="Enter Timing For Ladies Court"
                      {...register("ladies_time_slot", { required: true })}
                    />
                  </div>
                  {errors.ladies_time_slot && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Ladies Time Slot, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Court Type</span>
                  <div className="form__form-group-field">
                    <Controller
                      control={control}
                      name="courtType"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <Select
                          value={value}
                          options={courtTypeList}
                          className="text-captialize w-100"
                          placeholder="Court Time"
                          onChange={onChange}
                          inputRef={ref}
                        />
                      )}
                    />
                  </div>
                  {errors.courtType && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Longitude, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label mb-1">
                    Select Court Image
                  </span>
                  <div className="form__form-group-field">
                    <ImageUploader setImage={setImage} />
                  </div>
                  {imageError && (
                    <ErrorMessageComponent
                      message={
                        "Please Add Padel Court Image, It Should Not be Empty"
                      }
                    />
                  )}
                </div>
                <ButtonToolbar
                  className="form__button-toolbar"
                  style={{ marginTop: 25 }}
                >
                  <button
                    type="submit"
                    className="justpadel-buttons-save"
                    color="primary"
                  >
                    Save
                  </button>
                  <div className="justpadel-buttons-cancel">Cancel</div>
                </ButtonToolbar>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default CourtEditCard;

const ErrorMessageComponent = ({ message }) => {
  return (
    <div color="danger" className="form__form-group-error">
      <p style={{ color: "#dc3545" }}>
        <span className="bold-text">Warning! </span>
        {message}
      </p>
    </div>
  );
};
