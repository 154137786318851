import React from 'react'

const ReceiverDetails = ({name,email,phone}) => {
    return (
        <>
            {name}<br />
            <span className="user-email">{email}</span><br />
            <span className="user-phone">{phone}</span>
        </>
    )
};

export default ReceiverDetails;
