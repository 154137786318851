import React, { useState, useEffect } from "react";
import { Card, CardBody, ButtonToolbar, Col, Container, Row, Spinner } from "reactstrap";
import axios from "../../customAxios";
import { environment } from "../env";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { uploadCouponImage } from "../../Utils/AWSBucket/AWSConfiguration";

const animatedComponents = makeAnimated();

export const EditCouponOffer = () => {
  const id = useParams();
  const couponId = id.id;

  const [couponData, setCouponData] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [isLoading, setIsLoading] = useState(false);


  const { register, handleSubmit, reset, formState, control } = useForm({
    mode: "onChange",
  });
  const { touchedFields, errors, isDirty, isValid } = formState;
  const [errorMessage, setErrorMessage] = useState();
  const [mainCourtList, setMainCourtList] = useState([]);

  const GetCouponDetails = (couponId) => {
    setIsLoading(true)
    const editCouponUrl = `${environment.baseUrl}edit-coupon-offers/${couponId}`;
    axios
      .get(editCouponUrl)
      .then((response) => {
        var data = response?.data?.data;
        data.apply_for_addons = data.apply_for_addons.toString();
        setCouponData(data);
        setSelectedImage(data.image);
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log("Error" + error);
      });
  };

  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts-list`;
    await axios.get(mainCourtUrl).then((response) => {
      setMainCourtList(
        response?.data.map((court) => ({
          label: court.courtName,
          value: court.id,
        }))
      );
    });
  };

  useEffect(() => {
    GetMainCourt();
    GetCouponDetails(couponId);
  }, []);

  const onSubmit = async (value) => {
    setIsLoading(true);
    let selectMainCourt = value.maincourt_availability.map(
      (court) => court.value
    );
    value.maincourt_availability = selectMainCourt;
    value.coupon_for = couponData.coupon_for;

    if (value.image[0]) {
      await uploadCouponImage(value.image[0], "offer_coupon");
      const uploadURL = `https://justpadel-courtimages.s3.amazonaws.com/offer_coupon/${value.image[0].name}`;
      value.image = uploadURL;
    } else {
      value.image = selectedImage;
    }

    if (value.coupons_user_limit <= value.coupons_alloted) {
      const couponUrl = `${environment.baseUrl}edit-coupon-offers/${couponId}`;
      axios.put(couponUrl, value).then((response) => {
        if (response.data.status === "success") {
          swal({
            title: "Success",
            text: "Coupon Updated Successfully",
            icon: "success",
            button: "Close",
          }).then(() => {
            setIsLoading(false)
            window.location.href = "/coupon-offers";
          });
        }
      });
    } else {
        setIsLoading(false)
      setErrorMessage("User limit exceeded the coupon Alloted limit.");
    }
  };

  useEffect(() => {
    setCouponData(couponData);
  }, [couponData]);

  useEffect(() => {
    if (couponData) {
      var maincourt = [];
      couponData?.maincourt_availability.map((courtIs) => {
        maincourt.push(
          mainCourtList.filter((court) => court.value == courtIs)[0]
        );
      });

      reset({
        maincourt_availability: maincourt,
        name: couponData?.name,
        percentage_of_discount: couponData?.percentage_of_discount,
        coupons_user_limit: couponData?.coupons_user_limit,
        coupons_alloted: couponData?.coupons_alloted,
        apply_for_addons: couponData?.apply_for_addons,
        coupon_type: couponData?.coupon_type,
        coupon_category: couponData?.coupon_category,
        expiry_date: couponData?.expiry_date,
        applicablefor: couponData?.applicablefor,
        title: couponData?.title,
        description: couponData?.description,
      });
    }
  }, [mainCourtList, couponData]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Edit Coupon</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
        {isLoading && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}
          <Card>
            <CardBody>
              <form
                className="form product-edit"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form__half">
                  <div className="form__form-group">
                    <span
                      className="form__form-group-label"
                      style={{ fontSize: 15 }}
                    >
                      Previous Image
                    </span>
                    <div className="form__form-group-field">
                      <img
                        src={selectedImage}
                        alt={selectedImage}
                        style={{ borderRadius: 5, width: 312 }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group ">
                    <span className="form__form-group-label">
                      Select Main Court
                    </span>
                    <div className="form__form-group-field">
                      <Controller
                        control={control}
                        name="maincourt_availability"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <Select
                            value={value}
                            isMulti
                            options={mainCourtList}
                            className="basic-multi-select w-100"
                            components={animatedComponents}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                    {errors.maincourt_availability && (
                      <span className="error-msg">
                        Please Select Main Courts
                      </span>
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Name</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Coupon Name"
                        name="name"
                        {...register("name", { required: true })}
                      />
                    </div>
                    {errors.name && (
                      <span className="error-msg">
                        Please Enter Name Of Coupon
                      </span>
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Percentage of Discount
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Percentage of Discount"
                        name="percentage_of_discount"
                        {...register("percentage_of_discount", {
                          required: true,
                          min: 0,
                          max: 100,
                        })}
                      />
                    </div>
                    {errors.percentage_of_discount?.type === "max" && (
                      <div className="error-msg">
                        {" "}
                        <BsFillExclamationTriangleFill className="error-icon" />
                        Please give discount between 0-100
                      </div>
                    )}
                    {errors.percentage_of_discount?.type === "required" && (
                      <span className="error-msg">
                        Please Enter Percentage of Discount
                      </span>
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Select Coupon Type
                    </span>
                    <div className="form__form-group-field border p-1 rounded-2">
                      <select
                        className="select-coupon"
                        style={{
                          fontSize: "13px",
                          fontWeight: "250",
                          width: "700px",
                          borderColor: "white",
                          outline: "none",
                        }}
                        name="coupon_type"
                        id="coupon_type"
                        {...register("coupon_type")}
                      >
                        <option
                          style={{
                            fontSize: "13px",
                            borderColor: "rgb(132, 132, 132)",
                          }}
                          value=""
                        >
                          Select Court Type
                        </option>
                        <option
                          style={{
                            fontSize: "13px",
                            borderColor: "rgb(132, 132, 132)",
                          }}
                          value="persession"
                        >
                          Per Session
                        </option>
                        <option
                          style={{
                            fontSize: "13px",
                            borderColor: "rgb(132, 132, 132)",
                          }}
                          value="perbooking"
                        >
                          Per Booking
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Expiry Date</span>
                    <div className="form__form-group-field">
                      <input
                        type="date"
                        name="expiry_date"
                        {...register("expiry_date", { required: true })}
                      />
                    </div>
                    {errors.expiry_date && (
                      <span className="error-msg">
                        Please Enter Coupons Expiry Date
                      </span>
                    )}
                  </div>
                </div>

                <div className="form__half mt-5">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Title</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Title"
                        name="title"
                        {...register("title", { required: true })}
                      />
                    </div>
                    {errors.title && (
                      <span className="error-msg">
                        Please Enter Title Of Coupon
                      </span>
                    )}
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Description</span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        placeholder="Description"
                        name="description"
                        {...register("description", { required: true })}
                      />
                    </div>
                    {errors.description && (
                      <span className="error-msg">
                        Please Enter Description Of Coupon
                      </span>
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Upload Image</span>
                    <div className="form__form-group-field">
                      <input
                        type="file"
                        className="form__form-group-field"
                        {...register("image", { required: false })}
                      />
                    </div>
                    {errors.image && (
                      <span className="error-msg">
                        Please Upload image Of Coupon
                      </span>
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Apply For Addons
                    </span>
                    <div className="form__form-group-field py-1">
                      <div
                        className="form__form-group-field"
                        style={{ justifyContent: "center" }}
                      >
                        <label
                          htmlFor="Yes"
                          style={{ marginBottom: "0", marginRight: "-120px" }}
                        >
                          Yes
                        </label>

                        <input
                          {...register("apply_for_addons")}
                          type="radio"
                          name="apply_for_addons"
                          value="true"
                          id="apply_for_addons"
                          style={{ marginRight: "40px", height: "20px" }}
                        />
                      </div>
                      <div
                        className="form__form-group-field"
                        style={{ justifyContent: "center" }}
                      >
                        <label
                          htmlFor="No"
                          style={{ marginBottom: "0", marginRight: "-120px" }}
                        >
                          No
                        </label>

                        <input
                          {...register("apply_for_addons")}
                          type="radio"
                          name="apply_for_addons"
                          value="false"
                          id="apply_for_addons"
                          style={{ marginRight: "40px", height: "20px" }}
                        />
                      </div>
                    </div>
                    {errors.apply_for_addons && (
                      <span>Please check the box</span>
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Coupons Allocated
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Coupon Allocated"
                        name="coupons_alloted"
                        {...register("coupons_alloted", { required: true })}
                      />
                    </div>
                    {errors.coupons_alloted && (
                      <span className="error-msg">
                        Please Enter Coupons Allocated
                      </span>
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">User Limit</span>
                    <div className="form__form-group-field">
                      <input
                        type="number"
                        placeholder="Coupon User Limit"
                        name="coupons_user_limit"
                        {...register("coupons_user_limit", { required: true })}
                      />
                    </div>
                    {errors.coupons_user_limit && (
                      <span className="error-msg">Please Enter User Limit</span>
                    )}
                    {errorMessage ? (
                      <div className="error-msg">{errorMessage}</div>
                    ) : (
                      " "
                    )}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Select Coupon Type
                    </span>
                    <div className="form__form-group-field border p-1 rounded-2">
                      <select
                        className="select-coupon"
                        style={{
                          fontSize: "13px",
                          fontWeight: "250",
                          width: "700px",
                          borderColor: "white",
                        }}
                        name="coupon_category"
                        id="coupon_category"
                        {...register("coupon_category")}
                      >
                        <option
                          style={{
                            fontSize: "13px",
                            borderColor: "rgb(132, 132, 132)",
                          }}
                          value=""
                        >
                          Select Court Type
                        </option>
                        <option
                          style={{
                            fontSize: "13px",
                            borderColor: "rgb(132, 132, 132)",
                          }}
                          value="public"
                        >
                          Public
                        </option>
                        <option
                          style={{
                            fontSize: "13px",
                            borderColor: "rgb(132, 132, 132)",
                          }}
                          value="private"
                        >
                          Private
                        </option>
                      </select>
                    </div>
                    {/* {errors.coupons_type && <span className="error-msg">Please Select Coupon Type</span>}
                {errorMessage ? <div className="error-msg">{errorMessage}</div> : " "} */}
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Applicable For
                    </span>
                    <div className="form__form-group-field border p-1 rounded-2">
                      <select
                        className="select-coupon"
                        style={{
                          fontSize: "13px",
                          fontWeight: "250",
                          width: "700px",
                          borderColor: "white",
                        }}
                        name="applicablefor"
                        id="applicablefor"
                        {...register("applicablefor", { required: true })}
                      >
                        <option
                          style={{
                            fontSize: "13px",
                            borderColor: "rgb(132, 132, 132)",
                          }}
                          value=""
                        >
                          Select Coupon Applicable For
                        </option>
                        <option
                          style={{
                            fontSize: "13px",
                            borderColor: "rgb(132, 132, 132)",
                          }}
                          value="app"
                        >
                          App
                        </option>
                        <option
                          style={{
                            fontSize: "13px",
                            borderColor: "rgb(132, 132, 132)",
                          }}
                          value="all"
                        >
                          All
                        </option>
                      </select>
                    </div>
                    {errors.applicablefor && (
                      <span className="error-msg">
                        Please Select Coupon Apply For
                      </span>
                    )}
                  </div>
                </div>

                <div className="form__half"></div>
                <div className="form__half">
                  <ButtonToolbar
                    className="form__button-toolbar float_right"
                    style={{
                      marginLeft: "30px",
                      margin: "auto",
                      marginBottom: "5px",
                      marginTop: "10px",
                    }}
                  >
                    <button
                      className="justpadel-buttons-save"
                      type="submit"
                      style={{ border: "none", backgroundColor: "#1bb4f0" }}
                    >
                      Save
                    </button>
                    <button
                      className="justpadel-buttons-cancel"
                      style={{ backgroundColor: "#FDAE00", border: "none" }}
                      onClick={() => {
                        reset({
                          name: "",
                          percentage_of_discount: "",
                          coupons_user_limit: "",
                          coupons_alloted: "",
                        });
                      }}
                    >
                      Clear
                    </button>
                  </ButtonToolbar>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
