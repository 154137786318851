import React from 'react';
import {
  Col, Container, Row, Button
} from 'reactstrap';
import Slides from './components/Slides';

const AddSlides = () => {
return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Add Slide&apos;s</h3>
      </Col>
    </Row>
    <Row>
      <Slides />
    </Row>
  </Container>
)};
export default AddSlides;
