import React, { useState, useEffect } from 'react';
import {
    Col, ButtonToolbar, Card, CardBody,
} from 'reactstrap';
import axios from '../../../customAxios';
import S3FileUpload from 'react-s3';
import { useHistory } from 'react-router-dom';
import { environment } from '../../env';
import swal from "sweetalert";

//** Working one */ justpadel aws
const config = {
    // bucketName: 'test-padel',
    bucketName: 'justpadel-courtimages',
    region: 'us-east-1',
    accessKeyId: 'AKIAZZLUGKQZZJT3G4FX',
    secretAccessKey: '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',
};

const AddSlideV2 = () => {

    const [titles, setTitles] = useState();
    const [subTitle, setSubTitle] = useState();
    const [redirectUrl, setRedirectUrl] = useState();
    const [courtImage, setCourtImage] = useState();
    const [courtImageUrl, setCourtImageUrl] = useState();
    const [buttonText, setButtonText] = useState();
    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('Token')) {
            console.log('True');
        } else {
            alert('You are not logged In. Please LogIn');
            history.push('/');
        }
    }, []);

    function handelChangeRedirectUrl(e) {
        setRedirectUrl(e.target.value);
    }

    function handleChangeTitle(e) {
        setTitles(e.target.value);
    }

    function handelChangeSubTitle(e) {
        setSubTitle(e.target.value);
    }

    function handelChangeButtonText(e) {
        setButtonText(e.target.value);
    }

    function handleChangeSlideImage(e) {
        setCourtImage(e.target.files[0]);
        console.log(e.target.files[0]);
        S3FileUpload.uploadFile(e.target.files[0], config)
            .then((data) => {
                setCourtImageUrl(data.location);
            })
            .catch((err) => {
                console.log('Error Info: ', err);
            });
    }
    const padelCourtUrl = `${environment.baseUrl}addslides-v2`;
    const handleSubmit = () => {
        console.log('URL: ', courtImage);

        const SlideData = {
            image_url: courtImageUrl,
            title: titles,
            sub_title: subTitle,
            redirect_url: redirectUrl,
            button_text: buttonText,
        };

        axios.post(padelCourtUrl, SlideData).then((response) => {
            if (response.data.msg == "slide Data is added sucessfully") {
                swal({
                    title: "Success!",
                    text: "slide Data is added sucessfully!",
                    icon: "success",
                    button: "Ok",
                }).then(() => {
                    reset();
                });
            }
        })
            .catch((err) => {
                if (err) {
                    swal({
                        title: "Error!",
                        text: err,
                        icon: "error",
                        button: "Ok",
                    });
                }
            });
    };

    const reset = () => {
        setRedirectUrl("");
        setTitles("");
        setSubTitle("");
        setButtonText("");
        history.push("/list_slides-v2");
    };

    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text" style={{ color: "#acacac" }}>Slide&apos;s</h5>
                    </div>
                    <form className="form product-edit" encType="multipart/form-data">
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 15 }}>Redirect Url</span>
                                <div className="form__form-group-field">
                                    <input type="text" value={redirectUrl} onChange={handelChangeRedirectUrl} style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} />
                                </div>
                            </div>
                        </div>
                        <div className="form__half">
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 15 }}>Title</span>
                                <div className="form__form-group-field">
                                    <input type="text" value={titles} onChange={handleChangeTitle} style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} />
                                </div>
                            </div>
                        </div>
                        <div className="form__half" style={{ paddingTop: 20 }}>
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 15 }}>Sub Title</span>
                                <div className="form__form-group-field">
                                    <input type="text" value={subTitle} onChange={handelChangeSubTitle} style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} />
                                </div>
                            </div>
                        </div>
                        <div className="form__half" style={{ marginLeft: 30, paddingTop: 20 }}>
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 15 }}>Button Text</span>
                                <div className="form__form-group-field">
                                    <input type="text" value={buttonText} onChange={handelChangeButtonText} style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} />
                                </div>
                            </div>
                        </div>
                        <div className="form__half" style={{ paddingTop: 20 }}>
                            <div className="form__form-group">
                                <span className="form__form-group-label" style={{ fontSize: 15 }}>Select Slide Image</span>
                                <div className="form__form-group-field">
                                    <input type="file" onChange={handleChangeSlideImage} style={{ borderRadius: 5, border: "1px solid #9C9C9C" }} />
                                </div>
                            </div>
                        </div>

                        <Col md={12} lg={12}>
                            <Card style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", marginTop: 30, }}>
                                <div className="form__half" style={{ marginRight: -13 }}>
                                    <ButtonToolbar className="form__button-toolbar" >
                                        <div className='justpadel-buttons-save' onClick={handleSubmit}>
                                            Save
                                        </div>
                                        <div className='justpadel-buttons-cancel' onClick={reset}>
                                            Cancel
                                        </div>
                                    </ButtonToolbar>
                                </div>
                            </Card>
                        </Col>
                    </form>
                </CardBody>
            </Card>
        </Col>
    )
}

export default AddSlideV2