import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Card, CardBody, Spinner, Nav, NavItem, NavLink, TabContent, TabPane, Table, } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { EventTournamentRegistration } from './EventTournamentRegistration';
import classnames from "classnames";
import { ExpiredEvents } from './ExpiredEvents';

export const EventRegistrationTabs = () => {
    const history = useHistory()
    const [activeTab, setActiveTab] = useState("1");
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const handleAddEvents = () => {
        history.push('/add-event-tournament')
    }
    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Events Lists</h3>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }} >
                    <div className="justpadel-buttons btn-position" onClick={() => handleAddEvents()}> Add Events</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <div className="tabs__wrap">
                                <Nav tabs className="all-tabs" style={{ width: "100%" }}>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                "active-tab": activeTab === "1",
                                            })}
                                            onClick={() => toggle("1")}
                                        >
                                            Current Events
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                                "active-tab": activeTab === "2",
                                            })}
                                            onClick={() => {
                                                toggle("2");
                                            }}
                                        >
                                            Expired Events
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <>
                                            {activeTab === "1" && (
                                                <EventTournamentRegistration />
                                            )}
                                        </>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <>
                                            {activeTab === "2" && (
                                                <ExpiredEvents />
                                            )}
                                        </>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
