/* eslint-disable no-alert */
import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import BookingListCard from './components/BookingDetails';


const BookingIndex = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Booking Details</h3>
      </Col>
    </Row>
    <Row>
      <BookingListCard />
    </Row>
  </Container>
);
export default BookingIndex;
