/* eslint-disable no-const-assign */
/* eslint-disable no-eval */
/* eslint-disable object-curly-newline */
/* eslint-disable object-curly-spacing */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { useState, useEffect, useCallback, useReducer } from 'react';
import {
  Col, Button, Card, CardBody, ButtonToolbar, Row,
} from 'reactstrap';
// import axios from 'axios';
import axios from '../../../customAxios';
import { useHistory, useParams, Link } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment';
import { environment } from '../../env';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import "./CourtBookingSingle.css";
const addSubMinutesReducer = (state, action) => {
  switch (action) {
      case "add":
          return state + 45;
      case "sub":
          return state - 45;
      case "reset":
          return 0;
      default:
          throw new Error("unexpected actions");
  }
}
const selectedSlotReducer = (state, action) => {
  switch (action.type) {
      case "set":
          return action.itemDisplayVal;
      case "reset":
          return "06:00 AM";
      default:
          throw new Error("unexpected actions");
  }
}

const inititalSlotData = [];
const updateSlotsReducers = (state = inititalSlotData, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
      case "set":
          console.log("action.slotsData",action.slotsData)
          return action.slotsData;
  }
}

const UsersListCard = () => {
  const [mainCourtData, setMainCourtData] = useState([]);
  const [value, setValue] = useState();
  const [filteredCourts, setFilteredCourts] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const [selectedSlot, setSelectedSlot] = useState("12:00 AM");
  const [selectedSlotRange, setSelectedSlotRange] = useState("12:00 AM-12:45 AM");
  const [selectedDate, setSelectedDate] = useState();
  const [slotsArr, setSlotsArr] = useState([]);
  const [timeCounter, setTimeCounter] = useState(45);
  const [slotData, setslotData] = useState([]);
  const [selectedCourtId, setSelectedCourtId] = useState("");
  const [selectedSlotPrice, setSelectedSlotPrice] = useState();
  const [userCartDetails, setUserCartDetails] = useState([]);
  const [mainCourt, setMainCourt] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState("");
  const [courtSelected, setCourtSelected] = useState(true);
  //-----------------------------------------------------------------
  const [timeSlotMinutes, dispatchAddSubMinutes] = useReducer(addSubMinutesReducer, 0);
  const [rSelectedSlot, dispatchSelectedSlot] = useReducer(selectedSlotReducer, "06:00 AM");
  const [rSlotData, dispatchSlotData] = useReducer(updateSlotsReducers, []);
  const [promotions, setPromotions] = useState(false);
  
  const getMainCourt = async () => {
    const mainCourtsUrl = `${environment.baseUrl}maincourts/${id}`;
    const result = await axios(mainCourtsUrl);
    console.log(result.data);
    setMainCourt(id);
    setMainCourtData(result.data);
  };

  const getUserCartDetails = async () => {
    const userId =  localStorage.getItem('UserId');
    const userCartUrl = `${environment.baseUrl}get_cart_user/${userId}`;
    console.log(userCartUrl);
    const result = await axios(userCartUrl)
      .then((response) => {
        const cartTimeslots = JSON.parse(response?.data?.data[0]?.timeslot.replace(/'/g, '"'));
        console.log("Response of Data", cartTimeslots);
        setUserCartDetails(cartTimeslots);
      });
  };

  useEffect(() => {
    getUserCartDetails();
    getMainCourt();
    if (localStorage.getItem('Token')) {
      console.log('True');
    } else {
      alert('You are not logged In. Please LogIn');
      history.push('/');
    }
    console.log(value);
  }, []);

const resetSelection = () => {
  setSelectedSlot("12:00 AM");
  setSelectedSlotRange("12:00 AM-12:45 AM");
  setslotData([]);
  setSelectedCourtId("");
  setSelectedCourt("");
  setSelectedSlotPrice();
  setTimeCounter(0);
}

  const handleDateSelection = (dt) => {
    resetSelection();
    const date = moment(dt).format('YYYY-MM-DD');
    setSelectedDate(date);
    const availableSlotsPayload = {
      "date": date,
      "maincourtId" : parseInt(id),
    };
    
    const availableslotUrl = `${environment.baseUrl}new-availableslots/`;
    // const availableslotUrl = `${environment.baseUrl}new-availableslots/`;
    // const updateSlotUrl = `${environment.baseUrl}updateSlot`;
      var result = axios.post(availableslotUrl, availableSlotsPayload).then((response) => {
        if(response.status === 200 || response.status === 201){
      //  console.log("Available slots: ", response?.data);
       const availableSlotByDate = response.data.filter(slot => {
        return slot.date === date;
      });
      console.log("slotArr list before availableSlotByDate : ", availableSlotByDate);
      let modifiedSlots = [];
      availableSlotByDate.forEach(slot => {
        let newSlot = {
          "date": slot.date,
          "id": slot.id,
          "time": slot.time
        }
       const availableSlots = JSON.parse(slot.availableslot.replace(/'/g, '"'));
       let newAvailableSlots = availableSlots.filter((aslot) => {
          return aslot.main_court === parseInt(id);
        });
        newSlot["availableslot"] = newAvailableSlots;
        newSlot["available"] = newAvailableSlots.length ? true : false;
        modifiedSlots.push(newSlot);
      }); 
      // console.log("modifiedSlots: ", modifiedSlots);
    let modifiedSlotArray = modifiedSlots.map((slot) => (
      { ...slot, 
        displayVal: slot.time.split("-")[0].trim(),
        timestamp: moment(date + " " + slot.time.split("-")[0].trim(), 'YYYY-MM-DD, hh:mm A').format('X'),
      }
    ));

    modifiedSlotArray = modifiedSlotArray.sort((a, b) => b.timestamp - a.timestamp).reverse();
    console.log("Sorted Aarray slotArr: ", modifiedSlotArray);
    setSlotsArr(modifiedSlotArray);
      } else{
          alert('Server error');
        }
    });
  };

  function handleTimeSelection(e) {
    setslotData([]);
    setSelectedCourtId("");
    setSelectedCourt("");
    setSelectedSlotPrice();
    setTimeCounter(0);
    const time = e.target.value;
    setSelectedSlot(time.split("-")[0].trim());
    setSelectedSlotRange(time);
    dispatchSelectedSlot({ type: "set", itemDisplayVal: time.split("-")[0] });
    const filteredSlotsByTime = slotsArr.filter((slot) => slot.time === time);
    console.log('availableSlotByTime', filteredSlotsByTime[0]);
    if (filteredSlotsByTime?.length > 0) {
      const availableCourts =  eval(filteredSlotsByTime[0]?.availableslot);
      // !important uncomment after main cort field added
      // eslint-disable-next-line radix
      console.log("availableCourts",availableCourts);
      const availableCourtsUnique = [];
      const map = new Map();
      for (const item of availableCourts) {
        if (!map.has(item.courtname)) {
          map.set(item.courtname, true); // set any value to Map
          availableCourtsUnique.push({
            courtname: item.courtname,
            Price: item.Price,
            courttype: item.court_type,
            ladiesOnly: item.ladiesOnly,
            id: item.id,
          });
        }
      }
      setFilteredCourts(availableCourtsUnique.sort((a, b) => (a.courtname > b.courtname) ? 1 : -1) ?? []);
      console.log('available: ', availableCourtsUnique);
      console.log('filteredCourts', filteredCourts);
  }
  };

  const handleCourtSelection = (item) => {
    console.log(item);
    const newCourtId = item?.id;
        setCourtSelected(false);
        if (selectedCourtId !== newCourtId) {
          console.log("slotsArr[0]?.time",slotsArr[0]?.time);
            setslotData([]);            
            dispatchSelectedSlot({ type: "set", itemDisplayVal: slotsArr[0]?.time });
            dispatchSlotData({ type: "set", slotsData: [] });
            dispatchAddSubMinutes("reset");
            getMainCourt();
            setSelectedCourtId(item?.id);
            setSelectedCourt(item?.courtname);
            setSelectedSlotPrice(item?.Price);
            setPromotions(item?.promotion);
            addMin(item, "onCourtSelection");
        }
    // setslotData([]);
    // setTimeCounter(0)
    // setSelectedCourtId(item.id);
    // setSelectedCourt(item.courtname)
    // setSelectedSlotPrice(item?.Price);
    // console.log("Item court: ", item);
  };

  const addMin = async (item, param2) => {
    let slotsArray = [];
    console.log("item addMin", item);
    const nonStateSelectedCourt = item?.courtname ? item?.courtname : selectedCourt;
    const nonStateSelectedCourtId = item?.id ? item?.id : selectedCourtId;
    const nonStateSelectedSlotPrice = item?.Price ? item?.Price : selectedSlotPrice;
    console.log("rSlotData", rSlotData);
    slotsArray = (param2 === "onCourtSelection") ? [] : rSlotData;
    console.log("slotsArray", slotsArray);
    if (!nonStateSelectedCourt) {
        alert("Please Select Court")
        return;
    }

    console.log("rSelectedSlot",rSelectedSlot);
    let dateObj1 = moment(selectedDate + ', ' + rSelectedSlot, 'YYYY-MM-DD, hh:mm A').add(45, 'minutes').format('hh:mm A')
    let prevdateObj1 = moment(selectedDate + ', ' + rSelectedSlot, 'YYYY-MM-DD, hh:mm A').add(-45, 'minutes').format('hh:mm A')
    console.log("dateObj1",dateObj1);
    const timeslot = rSelectedSlot + "-" + dateObj1;
    const prevtimeslot = prevdateObj1 + "-" + rSelectedSlot;
    const booking_date = selectedDate;
    const court = parseInt(nonStateSelectedCourtId);
    const maincourt = parseInt(mainCourt);
    const promotion = item?.promotion?item.promotion:promotions;
    // console.log("timeslot",timeslot);
    const slotsArrNew = slotsArr.filter(i => timeslot.includes(i.time));
    if (slotsArrNew.length == 0) {
        alert("There is no available slots for booking");
        //   console.log("prevtimeslot",prevtimeslot);
        //   dispatchSelectedSlot({ type: "set", itemDisplayVal: prevtimeslot });
        return;
    } else {
        dispatchAddSubMinutes("add");
        // setTimeCounter(prev => timeCounter + 45)
        let secondSlotPrice = true;
        let dbprice;
        if (timeSlotMinutes >= 45 && param2 !== "onCourtSelection") {
            secondSlotPrice = true;
            // console.log("booking_date",booking_date)
            const padelcourtUrl = `${environment.baseUrl}padelcourt-promotion/${court}/${booking_date}`;
            await axios.get(padelcourtUrl)
                .then((response) => {
                    dbprice = response.data.additional_slot_price;
                });
        }
        else {
            secondSlotPrice = false;
        }
        const price = (secondSlotPrice ? dbprice : nonStateSelectedSlotPrice);
        const court_type = (secondSlotPrice ? "child" : "parent");
        const maincourt_name = mainCourtData.courtName;
        dispatchSelectedSlot({ type: "set", itemDisplayVal: dateObj1 });
        console.log("slotsArray-1",slotsArray);
        slotsArray.push({
            timeslot,
            booking_date,
            court,
            court_name: nonStateSelectedCourt,
            maincourt,
            price,
            maincourt_name,
            promotion,
            court_type,
        });
        console.log("slotsArray-2",slotsArray);
        dispatchSlotData({ type: "set", slotsData: slotsArray });
        setslotData(slotsArray);
    }
}

  // const addMin = async() => {
  //   let slotsArray = [];
  //   slotsArray = slotData;
  //   console.log(slotsArray);
  //   if(!selectedCourt){
  //     alert("Please Select Court")
  //     return;
  //   }
  //   // console.log("selectedDate:", selectedDate);
  //   // console.log("selectedSlot:", selectedSlot);
    
  //   let dateObj1 = moment(selectedDate + ', ' + selectedSlot, 'YYYY-MM-DD, hh:mm A').add(45,'minutes').format('hh:mm A');
  //   let prevdateObj1 = moment(selectedDate + ', ' + selectedSlot, 'YYYY-MM-DD, hh:mm A').add(-45,'minutes').format('hh:mm A');
  //   const timeslot= selectedSlot+"-"+dateObj1;
  //   const prevtimeslot = prevdateObj1+"-"+selectedSlot;
  //   // console.log("/////////////////////////////////////  TIMESLOTS ////////////////////////////////l",timeslot);
  //   // console.log("///////////////////////////////////// PREVIOUS TIMESLOTS ////////////////////////////////l",prevtimeslot);
  //   const booking_date = selectedDate;
  //   const court = parseInt(selectedCourtId);
  //   const maincourt = parseInt(mainCourt);
  //   const price = selectedSlotPrice;
  //   const maincourt_name = mainCourtData.courtName;
  //   // const prevtimeslot = prevdateObj1+"-"+selectedSlot;

  //   const slotsArrNew = slotsArr.filter( i => timeslot.includes( i.time ) );
  //   if(slotsArrNew.length === 0) {
  //     alert("There is no available slots for booking");
  //     // selectedSlot = prevtimeslot;
  //     setSelectedSlot(timeslot)
  //     return;
  //   } else {

  //     setTimeCounter(prev=>timeCounter+45)
  //     let pp = true;
  //     let dbprice;
  //     if(timeCounter>=45){
  //       pp = true;
  //       // const padelcourtUrl =`${config.API_URL}padelcourt/${court}`;
  //       const padelcourtUrl = `${environment.baseUrl}padelcourt-promotion/${court}/${booking_date}`;
  //       await axios.get(padelcourtUrl)
  //     .then((response)=>{
  //       console.log("Padel court Data",response.data.additional_slot_price);
  //       dbprice = response.data.additional_slot_price;
  //     });
  //     console.log("dbprice",dbprice)
  //     }
  //     else {
  //       pp = false;
  //     }
  //     console.log("PP",pp);
  //     const price = ( pp? dbprice : selectedSlotPrice);
  //     const maincourt_name = mainCourtData.courtName;
    
  //   setTimeCounter(prev=> timeCounter+45);
  //   setSelectedSlot(dateObj1);    
  //   console.log("Slot1", timeslot)
  //   slotsArray.push({
  //     timeslot, 
  //     booking_date,
  //     court,
  //     maincourt,
  //     price,
  //     maincourt_name,
  //   });
  //   setslotData(slotsArray);
  //   console.log("slot array: ", slotData);
  // }
  // }

  const subMin = () =>{
    // if(timeCounter) {
      if(!selectedCourt){
        alert("Please Select Court")
        return;
      }
      slotData.splice(-1);
      let dateObj1 = moment(selectedDate + ', ' + selectedSlot, 'YYYY-MM-DD, hh:mm A').add(-45,'minutes').format('hh:mm A');
      dispatchSelectedSlot({ type: "set", itemDisplayVal: dateObj1 });
    //----setSelectedSlot(dateObj1);
    dispatchAddSubMinutes("sub");
      // setTimeCounter(prev=>timeCounter-45)
    // }
  }
  

  const handleSubmit = () => {
    try {
      let existingWithNewCartSlotData = userCartDetails;
      console.log("existingWithNewCartSlotData".slotData);
      slotData.forEach((s) => {
        existingWithNewCartSlotData.push(s);
      });
      console.log("existingWithNewCartSlotData: ", existingWithNewCartSlotData);
      const userId =  localStorage.getItem('UserId');
      const addToCartPayload = {
        "main_court_id": mainCourt,
        "court_id": selectedCourtId,
        "court_name": selectedCourt,
        "timeslot": selectedSlotRange,
        "date": selectedDate,
        "price": selectedSlotPrice,
        "selected_slot": JSON.stringify(existingWithNewCartSlotData),
        "user": userId,
      } 
      console.log("addToCartPayload", addToCartPayload);
      const addToCartUrl = `${environment.baseUrl}addtocart`;
      const result = axios.post(addToCartUrl, addToCartPayload)
        .then((response) => {
          console.log("Response: ",response);
         // localStorage.setItem('selectionDetails', JSON.stringify(slotData));
         history.push({pathname: '/addon_booking'});
         
        }, (error) => {
          console.log("Promise error: ", error);
        })
    } catch (error) {
      console.log("add to cart error: ", error);
    }
  };

  const goBack = () => {
    history.push({ pathname:'/courtbooking'});
  };

  return (
    <Col md={12} lg={12}>
      <h3 className="page-title book-court">Book Padel Court - {mainCourtData.courtName}</h3>
      <Card>
        <CardBody>

        <div>
            <div className="calender-Container">
                <div className="inner-calender-booking">
                    <div className="inner-lhs">
                        <Calendar
                            minDate={new Date()}
                            value={value}
                            onChange={handleDateSelection}
                        />
                    </div>
                    <div className="inner-rhs">
                    <div className="sel-and-time">
                    <div className="select-time" >
                            <h2>Select Time</h2>
                            <select className="timeSlot" value={selectedSlotRange} onChange={handleTimeSelection}>
                                <option key="0">
                                    Select Time Slot
                                </option>
                                {slotsArr && slotsArr.map((item, index) => (
                                    <option key={index} value={item.time} disabled={!item.available} 
                                    style={{ "color": item.available ? "#000" : "#767676" }}>
                                        {item.time}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="select-inc-dec-container">
                            {filteredCourts.length ?
                                <div className="select-inc-dec" >
                                    <h2>Select Mins</h2>
                                    <div className="time-inc-dec">
                                        <div className="bg-fa-minuts">
                                          <FontAwesomeIcon icon={faMinusCircle} className="faMinus" onClick={timeSlotMinutes === 0 ? '' : () => subMin()} />
                                        </div>
                                        {/* <div className="timeline__icon file" style={{ margin: '0px 5px', "color": timeCounter ? "#000" : "#767676" }} onClick={() => subMin()}><span className="lnr lnr-circle-minus" /></div> */}
                                        {/* <h6>{timeCounter}Min</h6> */}
                                        <small>{timeSlotMinutes}Min</small>
                                        <div className="bg-fa-minuts">
                                          <FontAwesomeIcon icon={faPlusCircle} className="faPlus" onClick={() => addMin()}/>
                                        </div>
                                        {/* <div className="timeline__icon file" style={{ margin: '0px 5px' }} onClick={() => addMin()}><span className="lnr lnr-plus-circle" /></div> */}
                                    </div>
                                    
                                </div>
                                :
                                ""
                            }
                        </div>
                        </div>
                        <div className="cart-container">
                            <h2>Choose Available Courts</h2>
                            <div className="cart">
                            {filteredCourts.length ?
                                filteredCourts.map((court) => (
                                    <div className="clild-cart" onClick={() => handleCourtSelection(court)} style={{ 'backgroundColor': court.id == selectedCourtId ? '#ffc107' : '#efeeee' }}>
                                        <h5 style={{ 'color': court.id == selectedCourtId ? '#fff' : '#000',fontSize:17, fontWeight:500 }} className="number">{court.courtname}</h5>
                                        <h5 style={{ 'color': court.id == selectedCourtId ? '#fff' : '#000', textTransform: 'uppercase' ,fontSize:14,}} className="doors">{court.courttype}</h5>
                                        <div>{court.ladiesOnly === 'True' ? <h5 className="ladies-only" style={{ textTransform: 'uppercase', color: '#ff0000', textAlign: 'center', fontSize: 12, fontWeight: 'bold' }}>Ladies Only</h5> : ''}</div>
                                    </div>
                                ))
                                : <div>
                                    <h3>Select the Date to view the time slots.</h3>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="court-submit-btns">                            
                    <button className="add" onClick={handleSubmit} disabled={!selectedCourt}>Add to Cart</button>
                    <button className="cancel" onClick={goBack}>Cancel</button>
                </div>
                </div>
        </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default UsersListCard;
