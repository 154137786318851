import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import classnames from "classnames";
import TransactionDetails from "./TransactionDetails/TransactionDetails";
import ClientDetails from "./ClientDetails/ClientDetails";
import BookingDetails from "./BookingDetails/BookingDetails";

const UnsuccessTab = ({ bookingId, clientDetails, transactionLog }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Nav tabs className="all-tabs" style={{ width: 420 }}>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ width: 175 }}
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Client
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ width: 175 }}
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggle("3")}
          >
            Transaction Logs
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <BookingDetails bookingId={bookingId} />
        </TabPane>
        <TabPane tabId="2">
          <ClientDetails clientDetails={clientDetails} />
        </TabPane>
        <TabPane tabId="3">
          <TransactionDetails transactionLog={JSON.parse(transactionLog)[0]} />
        </TabPane>
      </TabContent>
    </>
  );
};

export default UnsuccessTab;
