import React, { useState, useEffect, useReducer } from "react";
import { Row, Card, CardBody, Col, Spinner, Button } from "reactstrap";

import ReactTableBase from "../../../shared/components/table/ReactTableBase";
import ReactTableCustomizer from "../../../shared/components/table/components/ReactTableCustomizer";
import { AiFillEye, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import CouponModal from "../../../shared/components/CouponModal";
import CouponModalEdit from "../../../shared/components/CouponModalEdit";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";

import moment from "moment";
import axios from "../../../customAxios";
import { environment } from "../../env";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import classnames from "classnames";

import _ from "lodash";
import { CouponModalView } from "../../../shared/components/CouponModalView";

const initialState = {
  pageLimits: 10,
};

function reducer(state, action) {
  switch (action.type) {
    case "pageLimits":
      return {
        ...state,
        pageLimits: parseInt(action.payload),
      };
    default:
      return {
        ...state,
      };
  }
}

const Coupons = () => {
  const [coupon, setCoupon] = useState([]);
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);
  const [couponPageCount, setCouponPageCount] = useState(0);
  const [couponInitialPage, setCouponInitialPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteing, setDeleteing] = useState(true);
  const [editing, setEditing] = useState(true);
  const [userRole, setUserRole] = useState("admin");
  const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeTab, setActiveTab] = useState("1");
  const [url, setUrl] = useState("onetime");
  const toggle = (tab) => {
    tab === "1"
      ? setUrl("onetime")
      : tab === "2"
      ? setUrl("multiple")
      : setUrl("expired");

    if (activeTab !== tab) setActiveTab(tab);
  };

  let history = useHistory();

  const GetCoupon = (pageNo, pageLimit) => {
    dispatch({ type: "pageLimits", payload: pageLimit });
    setLoading(true);
    const notifyMeURL = `${environment.baseUrl}coupon-list-new/${url}?page=${pageNo}&page_size=${pageLimit}`;
    const result = axios
      .get(notifyMeURL)
      .then((response) => {
        setCoupon(response.data.results);
        setCouponPageCount(Math.ceil(response.data.count / pageLimit));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  var jsonObj = [];

  for (let i = 0; i < coupon?.length; i++) {
    var item = {};
    item["id"] = i + 1;
    item["name"] = <div className="right-space"> {coupon[i].name}</div>;
    item["percentage_of_discount"] = (
      <div className="right-space">{coupon[i].percentage_of_discount}</div>
    );
    item["coupons_alloted"] = (
      <div className="right-space">
        {coupon[i].coupons_alloted} / {coupon[i].coupons_user_limit}
      </div>
    );

    item["apply_for_addons"] = (
      <div className="right-space">
        {coupon[i].apply_for_addons ? "YES" : "NO"}
      </div>
    );
    item["coupon_type"] = (
      <div className="right-space">
        {coupon[i].coupon_type} / {coupon[i].coupon_category}
      </div>
    );
    item["coupon_remaining"] = (
      <div className="right-space">{coupon[i].coupon_remaining}</div>
    );
    const currentDate = moment(new Date(), "YYYY-MM-DD");
    const expiryDate = moment(coupon[i].expiry_date, "YYYY-MM-DD");
    item["expiry_date"] = (
      <div className="right-space">
        {coupon[i].expiry_date}
        <br />
        <div className="Booking-details-td-right-space">
          <div className="active-color-bg canceled-color-bg">
            <span
              className={
                currentDate <= expiryDate
                  ? "active-color-bg"
                  : "canceled-color-bg"
              }
            >
              {currentDate <= expiryDate ? "active" : "inactive"}
            </span>
          </div>
        </div>
      </div>
    );

    item["view"] = (
      <div className="right-space">
        <div>
          <CouponModalView
            btn="View Coupon"
            color="warning"
            title="View Coupon Details"
            header
            couponId={coupon[i]?.id}
          />
        </div>
      </div>
    );

    if (deleteing === true) {
      item["link"] = (
        <div className="right-space">
          <div>
            <CouponModalEdit
              btn="Add Coupon"
              color="warning"
              title="Edit Coupon"
              header
              couponId={coupon[i]?.id}
            />
          </div>
        </div>
      );
      item["delete"] = (
        <div className="right-space">
          <div
            className="modal-delete-btn"
            onClick={() => handleDelete(coupon[i].id)}
          >
            <AiOutlineDelete style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    } else if (editing === true) {
      item["link"] = (
        <div className="right-space">
          <div
            className="modal-edit-btn"
            onClick={() => handleEdit(coupon[i].id)}
          >
            <AiOutlineEdit style={{ fontSize: 20 }} />
          </div>
        </div>
      );
    }
    jsonObj.push(item);
  }

  const handleEdit = (id) => {
    history.push(`/edit_coupons/${id}`);
  };

  const data = {
    columns: [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Name",
        accessor: "name",
        disableGlobalFilter: true,
        width: 75,
      },
      {
        Header: "Percentage Of Discount",
        accessor: "percentage_of_discount",
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: "Coupon Type / Category",
        accessor: "coupon_type",
        disableGlobalFilter: true,
        width: 90,
      },
      {
        Header: "Expiry Date / Active",
        accessor: "expiry_date",
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: "View",
        accessor: "view",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 20,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        width: 20,
      },
      {
        Header: "Delete",
        accessor: "delete",
        disableGlobalFilter: true,
        width: 20,
      },
    ],
    rows: jsonObj,
  };

  const handleDelete = (id) => {
    if (userRole === "admin") {
      const deleteCouponUrl = `${environment.baseUrl}editcoupan/${id}`;
      axios
        .delete(deleteCouponUrl)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      setCoupon(() => coupon.filter((couponid) => couponid.id !== id));
    } else {
      alert("You don't have the permissions to delete data");
    }
  };

  const handleAddCoupon = () => {
    history.push("/add_coupons");
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10000, 20000, 30000, 40000],
    placeholder: "Search by Coupon...",
  };

  const handleCouponPageClick = (data) => {
    setLoading(true);
    setCouponInitialPage(data.selected);
    const notifyMeURL = `${environment.baseUrl}coupon-list-new/${url}?page=${
      data.selected + 1
    }&page_size=${state.pageLimits}`;
    const result = axios
      .get(notifyMeURL)
      .then((response) => {
        setCoupon(response.data.results);
        setCouponPageCount(Math.ceil(response.data.count / state.pageLimits));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleLimitChange = (e) => {
    dispatch({ type: "pageLimits", payload: e.target.value });
    setCouponInitialPage(0);
    GetCoupon(1, e.target.value);
  };

  let couponSearchValue;
  const couponSearch = (searchValue) => {
    couponSearchValue = searchValue;
    if (searchValue.length <= 0) {
      debouncedThing();
    }
  };

  const filterCoupon = () => {
    const GetCouponDetails = `${environment.baseUrl}getcoupan/${couponSearchValue}/`;
    const couponResult = axios.get(GetCouponDetails).then((couponResponse) => {
      if (couponResponse.data.Message != "Coupon does not exists") {
        setCoupon([couponResponse.data]);
        setCouponPageCount(1);
      } else {
        setCoupon([]);
        setCouponPageCount(0);
      }
    });
  };

  const debouncedThing = _.debounce(() => {
    GetCoupon(1, state.pageLimits);
  }, 3000);

  useEffect(() => {
    GetCoupon(1, state.pageLimits);
    setCouponInitialPage(0);
  }, [url]);

  return (
    <Row>
      <Col sm={12} md={12} lg={12}>
        <Row className="d-flex justify-content-between">
          <Col>
            <h3 className="page-title">Coupons List</h3>
          </Col>
          <Col className="d-flex justify-content-end align-items-end">
            {deleteing && (
              <CouponModal
                btn="Add Coupon"
                color="warning"
                title="Create Coupon"
                header
                getCoupon={() => GetCoupon(1, state.pageLimits)}
              />
            )}
          </Col>
        </Row>
      </Col>

      <Col>
        <Card>
          <CardBody>
            <div className="tabs__wrap">
              <Nav tabs className="all-tabs" style={{ width: "100%" }}>
                <NavItem>
                  <NavLink
                    className={classnames({
                      "active-tab": activeTab === "1",
                    })}
                    onClick={() => toggle("1")}
                  >
                    One Time
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      "active-tab": activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Multiple Time
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      "active-tab": activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Expired
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <>
                    {activeTab === "1" && (
                      <>
                        <Row className="mt-4">
                          <Col sm={4} md={4} lg={4}>
                            <div className="form form__form-group">
                              <div className="form__form-group-field">
                                <input
                                  placeholder="Search coupon here ..."
                                  onChange={(e) => couponSearch(e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col sm={4} md={4} lg={4}>
                            <Button
                              color="warning"
                              onClick={() => filterCoupon()}
                            >
                              search
                            </Button>
                          </Col>
                        </Row>
                        <div className="react-table__wrapper hide-table-costomizer">
                          <ReactTableCustomizer
                            handleClickIsSortable={handleClickIsSortable}
                            handleClickWithPagination={
                              handleClickWithPagination
                            }
                            handleClickWithSearchEngine={
                              handleClickWithSearchEngine
                            }
                            handleClickIsResizable={handleClickIsResizable}
                            isResizable={isResizable}
                            isSortable={isSortable}
                            withPagination={withPagination}
                            withSearchEngine={withSearchEngine}
                          />
                        </div>
                        <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                          <ReactTableBase
                            id="table-to-xls"
                            key={withSearchEngine ? "searchable" : "common"}
                            columns={data.columns}
                            data={data.rows}
                            tableConfig={tableConfig}
                          />
                        </div>
                      </>
                    )}
                  </>
                </TabPane>
                <TabPane tabId="2">
                  <>
                    {activeTab === "2" && (
                      <>
                        <Row className="mt-4">
                          <Col sm={4} md={4} lg={4}>
                            <div className="form form__form-group">
                              <div className="form__form-group-field">
                                <input
                                  placeholder="Search coupon here ..."
                                  onChange={(e) => couponSearch(e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col sm={4} md={4} lg={4}>
                            <Button
                              color="warning"
                              onClick={() => filterCoupon()}
                            >
                              search
                            </Button>
                          </Col>
                        </Row>
                        <div className="react-table__wrapper hide-table-costomizer">
                          <ReactTableCustomizer
                            handleClickIsSortable={handleClickIsSortable}
                            handleClickWithPagination={
                              handleClickWithPagination
                            }
                            handleClickWithSearchEngine={
                              handleClickWithSearchEngine
                            }
                            handleClickIsResizable={handleClickIsResizable}
                            isResizable={isResizable}
                            isSortable={isSortable}
                            withPagination={withPagination}
                            withSearchEngine={withSearchEngine}
                          />
                        </div>
                        <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                          <ReactTableBase
                            id="table-to-xls"
                            key={withSearchEngine ? "searchable" : "common"}
                            columns={data.columns}
                            data={data.rows}
                            tableConfig={tableConfig}
                          />
                        </div>
                      </>
                    )}
                  </>
                </TabPane>
                <TabPane tabId="3">
                  <>
                    {activeTab === "3" && (
                      <>
                        <Row className="mt-4">
                          <Col sm={4} md={4} lg={4}>
                            <div className="form form__form-group">
                              <div className="form__form-group-field">
                                <input
                                  placeholder="Search coupon here ..."
                                  onChange={(e) => couponSearch(e.target.value)}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col sm={4} md={4} lg={4}>
                            <Button
                              color="warning"
                              onClick={() => filterCoupon()}
                            >
                              search
                            </Button>
                          </Col>
                        </Row>
                        <div className="react-table__wrapper hide-table-costomizer">
                          <ReactTableCustomizer
                            handleClickIsSortable={handleClickIsSortable}
                            handleClickWithPagination={
                              handleClickWithPagination
                            }
                            handleClickWithSearchEngine={
                              handleClickWithSearchEngine
                            }
                            handleClickIsResizable={handleClickIsResizable}
                            isResizable={isResizable}
                            isSortable={isSortable}
                            withPagination={withPagination}
                            withSearchEngine={withSearchEngine}
                          />
                        </div>
                        <div className="table-id-desabled pagination-hide hover-effect hide-filtername">
                          <ReactTableBase
                            id="table-to-xls"
                            key={withSearchEngine ? "searchable" : "common"}
                            columns={data.columns}
                            data={data.rows}
                            tableConfig={tableConfig}
                          />
                        </div>
                      </>
                    )}
                  </>
                </TabPane>
              </TabContent>

              <div className="custom-pagination">
                <div className="records-per-page">
                  <select
                    value={state.pageLimits}
                    onChange={handleLimitChange}
                    name="limit"
                    id="select-pagination"
                  >
                    {mannualLimits?.map((mannualLimits) => (
                      <option value={mannualLimits}>{mannualLimits}</option>
                    ))}
                  </select>
                  &nbsp;&nbsp;&nbsp;records per page
                </div>
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={couponPageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handleCouponPageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  forcePage={couponInitialPage}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Coupons;
