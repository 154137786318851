import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Modal, ModalBody, Row, Spinner } from "reactstrap";
import classNames from "classnames";
import { RTLProps } from "../prop-types/ReducerProps";
import { useForm } from "react-hook-form";
import axios from "../../customAxios";
import { environment } from "../../containers/env";
import swal from "@sweetalert/with-react";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";

export const OfferCardsModal = ({
  color,
  btn,
  colored,
  header,
  title,
  id,
  cardname,
  required,
  GetOfferCards,
  onGetOfferCards,
}) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requiredCard, setRequiredCard] = useState(required);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const toggle = () => {
    setModal((prevState) => !prevState);
  };
  let Icon;

  switch (color) {
    case "primary":
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case "success":
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case "warning":
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case "danger":
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }

  const modalClass = classNames({
    "modal-dialog--colored": colored,
    "modal-dialog--header": header,
  });

  const onSubmit = async (e) => {
    setLoading(true);

    const UpdateOfferCardsPayload = {
      name: cardname,
      required: requiredCard,
    };

    
    const UpdateOfferCardsUrl = `${environment.baseUrl}coupon-card/${id}`;
    const UpdateOfferCardsData = await axios
      .put(UpdateOfferCardsUrl, UpdateOfferCardsPayload)
      .then((res) => {
        if (res.data.status === "success") {
          swal({
            icon: "success",
            content: (
              <div>
                <h1 style={{ color: "#28a745" }}>Offer Card is Updated</h1>
              </div>
            ),
          }).then(() => {
            // toggle();
            GetOfferCards(1, 10);
            setLoading(false);
          });
          setLoading(false);
        } else {
          swal({
            icon: "error",
            content: (
              <div>
                <h1 style={{ color: "darkred" }}>Error</h1>
                <p>Error, While updating offer card</p>
              </div>
            ),
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err---->", err);
        setLoading(false);
      });
    setModal(false);
  };

  const handleDropdownChange = (event) => {
    setRequiredCard(event.target.value);
  };

  return (
    <>
      {loading && (
        <div class="loader-div">
          <span class="loader">
            <div className="center-spinner">
              <Spinner className="table-fetch-spinner" />
            </div>
          </span>
        </div>
      )}

      <div className="modal-edit-btn" onClick={toggle}>
        <EditOutlineIcon style={{ fontSize: 20 }} />{" "}
        <Modal
          isOpen={modal}
          toggle={toggle}
          modalClassName={`modal ltr-support`}
          className={`modal-dialog--${color} ${modalClass}`}
        >
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              aria-label="modal__close-btn"
              type="button"
              onClick={toggle}
            />
            <h4 className="text-modal  modal__title">{title}</h4>
          </div>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg="12">
                  <lable
                    className=""
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    Name:
                  </lable>
                  <span style={{ fontSize: "15px" }}>{cardname}</span>
                </Col>

                <Col md="6" sm="9" lg="9" className="mt-2">
                  <label
                    htmlFor="availability-dropdown"
                    style={{
                      margin: "0 20px 0 0 ",
                      fontWeight: "bold",
                      fontSize: "15px",
                    }}
                  >
                    Required:
                  </label>
                  <select
                    id="availability-dropdown"
                    value={requiredCard}
                    onChange={handleDropdownChange}
                    style={{ padding: "2px", borderRadius: "5px" }}
                  >
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </Col>
                <Col md="3" sm="3" lg="3" className="mt-1">
                  <button className="download-booking-table-xls-button center">
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

OfferCardsModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
  colored: PropTypes.bool,
  header: PropTypes.bool,
  // btn: PropTypes.string.isRequired,
  // rtl: RTLProps.isRequired,
};

OfferCardsModal.defaultProps = {
  title: "",
  message: "",
  colored: false,
  header: false,
};
