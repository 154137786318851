import React, { useEffect, useState } from 'react'
import { Col, Row, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Table, Container, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import { environment } from '../env'
import axios from '../../customAxios'
import ReactTableBase from '../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../shared/components/table/components/ReactTableCustomizer';
import Select from "react-select";


import { useForm, Controller } from "react-hook-form";
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';


export const RegisteredUserSkillLevel = () => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm()

    const {
        control: control1,
        register: register1,
        formState: { errors: errors1 },
        handleSubmit: handleSubmit1,
        reset: reset1,
    } = useForm();


    const [registeredUsersData, setRegisteredUsersData] = useState()
    const [isSortable, setIsSortable] = useState(true);
    const [isResizable, setIsResizable] = useState(true);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(true);
    const [mannualLimits, setMannualLimits] = useState([10, 20, 30, 40, 50]);
    const [pageCount, setPageCount] = useState(0);
    const [pageLimits, setPageLimits] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [initialPage, setInitialPage] = useState(0);
    const [searchInput, setSearchInput] = useState();

    const [userSkillData, setUserSkillData] = useState()
    const [modalVerifySkill, setModalVerifySkill] = useState(false)
    const toggleVerifySkill = () => setModalVerifySkill(!modalVerifySkill)

    const skillLevelOptions = [
        { label: 'D', value: 'D' },
        { label: 'C-', value: 'C-' },
        { label: 'C', value: 'C' },
        { label: 'C+', value: 'C+' },
        { label: 'B', value: 'B' },
        { label: 'A', value: 'A' },
    ]




    const GetRegisteredUserData = async (page = 1, pagesize = 10) => {
        const GetRegisteredUserUrl = `${environment.baseUrl}list-alluser-skill?page=${page}&page_size=${pagesize}`
        const result = await axios.get(GetRegisteredUserUrl).then((res) => {
            setRegisteredUsersData(res.data?.results)
        })
    }


    const onSubmit = (data) => {
        const GetRegisteredUserUrl = `${environment.baseUrl}list-alluser-skill?query=${data.search}`;
        axios.get(GetRegisteredUserUrl).then((response) => {
            setRegisteredUsersData(response?.data?.results);
            setPageCount(response?.data?.count / pageLimits)
        })
    };


    const onSubmitVerify = async (data) => {
        const updateSkillPayload = {
            user: userSkillData?.id,
            skilllevel: data?.user_skill?.value
        }
        const updateSkillUrl = `${environment.baseUrl}upgrade-user-skill`
        await axios.post(updateSkillUrl, updateSkillPayload).then((res) => {
            if (res.data.status === 'success') {
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    buttons: true,
                }).then(() => {
                    toggleVerifySkill()
                    reset1();
                    GetRegisteredUserData()
                })
            }
        }).catch((err) => {
            console.log("🚀 ~ file: RegisteredUserSkillLevel.jsx:105 ~ awaitaxios.post ~ err:", err)
            swal(`This User is not verified`, {
                icon: "error",
            });
        })
    };

    var jsonObj2 = [];

    for (let i = 0; i < registeredUsersData?.length; i++) {
        var item = {}
        item["id"] = i + 1;
        item["username"] = registeredUsersData[i].first_name.toString();
        item["email"] = registeredUsersData[i].email.toString();
        item["phone_no"] = registeredUsersData[i].phone_no !== "null" ? registeredUsersData[i].phone_no.toString() : "";
        item["gender"] = registeredUsersData[i].gender !== "null" ? registeredUsersData[i].gender.toString() : "";

        item["skillname"] = registeredUsersData[i].skilllevel.toString();

        item["actions"] = (
            <div className="right-space">
                <div style={{ margin: '10px 0 -10px 0' }}>
                    <Button
                        type="button"
                        color="warning"
                        className="custom_coupon_btn"
                        onClick={() => {
                            setUserSkillData(registeredUsersData[i])
                            toggleVerifySkill()
                        }}

                    >
                        Update Skill
                    </Button>
                </div>
            </div>
        );
        jsonObj2.push(item);
    }


    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };

    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };

    const handleClickIsResizable = () => {
        setIsResizable(!isResizable);
    };

    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const tableConfig = {
        isEditable: false,
        isResizable,
        isSortable,
        withPagination: true,
        withSearchEngine: false,
        manualPageSize: [100000, 200000, 300000, 400000],
        placeholder: 'Search by Name...',
    };

    const newdata = {
        columns: [
            {
                Header: '#',
                accessor: 'id',
                disableGlobalFilter: true,
                width: 10,
            },
            {
                Header: 'Name',
                accessor: 'username',
                disableGlobalFilter: false,
                width: 70,
            },
            {
                Header: 'Email',
                accessor: 'email',
                disableGlobalFilter: false,
                width: 100,
            },
            {
                Header: 'Phone Number',
                accessor: 'phone_no',
                disableGlobalFilter: false,
                width: 80,
            },
            {
                Header: 'Gender',
                accessor: 'gender',
                disableGlobalFilter: false,
                width: 50,
            },
            {
                Header: 'Skill Level',
                accessor: 'skillname',
                disableGlobalFilter: true,
                width: 70,
            },
            // {
            //     Header: 'Applied Date',
            //     accessor: 'applied_date',
            //     disableGlobalFilter: true,
            //     width: 50,
            // },
            // {
            //     Header: 'View',
            //     accessor: 'view',
            //     disableGlobalFilter: true,
            //     width: 50,
            // },
            {
                Header: "Actions",
                accessor: "actions",
                disableGlobalFilter: true,
                width: 70,
            }
        ],
        data: jsonObj2
    };

    const handleLimitChange = (e) => {
        setInitialPage(0);
        setCurrentPage(1);
        setPageLimits(parseInt(e.target.value));
        GetRegisteredUserData(1, parseInt(e.target.value));
    };

    const handlePageClick = (page) => {
        setCurrentPage(page.selected + 1);
        GetRegisteredUserData(page.selected + 1, pageLimits);
    };

    const handleClear = () =>{
        reset()
        GetRegisteredUserData(1, pageLimits)
    }

    useEffect(() => {
        GetRegisteredUserData(1, pageLimits);
    }, [])


    return (
        <>

            <Modal
                isOpen={modalVerifySkill}
                toggle={toggleVerifySkill}
                centered={true}
                className="custom_skill_verify_modal"
                style={{ maxWidth: "500px" }}

                size='lg'
            >
                <ModalHeader toggle={toggleVerifySkill} style={{ alignItems: 'center' }}>Verify Skill Level</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit1(onSubmitVerify)}>

                        <div>
                            <h2 className=''>Are you Sure ?</h2>
                            <span className='' style={{ fontSize: 16 }}>{`This user Current Skill Level : ${userSkillData?.skilllevel} `} </span>
                        </div>

                        <Row className='d-flex justify-content-center mt-3'>
                            <Col lg={6}>
                                <div className="form__form-group mt-2">
                                    <div className="form__form-group-field">
                                        <Controller
                                            control={control1}
                                            name="user_skill"
                                            rules={{ required: true }}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref },
                                                fieldState: { invalid, isTouched, isDirty, error },
                                                formState,
                                            }) => (
                                                <Select
                                                    value={value}
                                                    options={skillLevelOptions}
                                                    className="text-uppercase w-100"
                                                    placeholder="Select a Skill"
                                                    onChange={onChange}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                    </div>
                                    {errors1.user_skill && (
                                        <span className="error-msg">
                                            Please Choose Skill Level
                                        </span>
                                    )}
                                </div>
                            </Col>

                            <Col lg={3}>
                                <div style={{ margin: '8px 0 0px 0' }}>
                                    <Button type="submit" color="warning" className="custom_coupon_btn">
                                        Submit
                                    </Button>
                                </div>
                            </Col>


                        </Row>


                    </form>
                </ModalBody>
            </Modal>

            <Row>
                <Col md={12} lg={12}>
                    <Card >
                        <CardBody className='mb-4'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div class="table__search">
                                    <input placeholder="Search by Name, Email, Phone No..." type="text" class={`table__search table__search-input form-control ${errors.search && 'is-invalid'}`} value={searchInput} {...register("search", { required: true })} />
                                    <button className='btn btn-sm btn-warning' type='submit'>Search</button>
                                    <button className='btn btn-sm btn-danger' type='button' onClick={() => handleClear()}>Clear</button>
                                </div>
                            </form>
                            <div className="react-table__wrapper hide-table-costomizer">
                                <ReactTableCustomizer
                                    handleClickIsSortable={handleClickIsSortable}
                                    handleClickWithPagination={handleClickWithPagination}
                                    handleClickWithSearchEngine={handleClickWithSearchEngine}
                                    handleClickIsResizable={handleClickIsResizable}
                                    isResizable={isResizable}
                                    isSortable={isSortable}
                                    withPagination={withPagination}
                                    withSearchEngine={withSearchEngine}
                                />
                            </div>

                            <div className="table-id-desabled pagination-hide hover-effect hide-filtername table-id-width">
                                <ReactTableBase
                                    key={withSearchEngine ? 'searchable' : 'common'}
                                    columns={newdata.columns}
                                    data={newdata.data}
                                    tableConfig={tableConfig}
                                />
                            </div>
                            <div className="custom-pagination">
                                <div className="records-per-page">
                                    <select
                                        value={pageLimits}
                                        onChange={handleLimitChange}
                                        name="limit"
                                        id="select-pagination"
                                    >
                                        {mannualLimits?.map((mannualLimits) => (
                                            <option value={mannualLimits}>
                                                {mannualLimits}
                                            </option>
                                        ))}
                                    </select>
                                    &nbsp;&nbsp;&nbsp;records per page
                                </div>
                                <ReactPaginate
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={
                                        "pagination justify-content-center"
                                    }
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                    forcePage={initialPage}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
