import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { CouponAccepted } from "./CouponAccepted";
import { CouponApplied } from "./CouponApplied";
import { CouponRejected } from "./CouponRejected";
import { CouponExpired } from "./CouponExpired";

export const CouponDocsStatus = () => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <Container>
      <Row>
        <Col>
          <h3 className="page-title">Offer's Docs</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="tabs__wrap">
                <Nav tabs className="all-tabs" style={{ width: "100%" }}>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        "active-tab": activeTab === "1",
                      })}
                      onClick={() => toggle("1")}
                    >
                      Applied
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        "active-tab": activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Accepted
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        "active-tab": activeTab === "3",
                      })}
                      onClick={() => toggle("3")}
                    >
                      Rejected
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        "active-tab": activeTab === "4",
                      })}
                      onClick={() => toggle("4")}
                    >
                      Expired
                    </NavLink>
                  </NavItem>
                  
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <>
                    {activeTab === "1" && <CouponApplied />}
                      
                    </>
                  </TabPane>
                  <TabPane tabId="2">
                    <>
                    {activeTab === "2" && <CouponAccepted />}
                      
                    </>
                  </TabPane>
                  <TabPane tabId="3">
                    <>
                    {activeTab === "3" && <CouponRejected />}
                      
                    </>
                  </TabPane>
                  <TabPane tabId="4">
                    <>
                    {activeTab === "4" && <CouponExpired />}
                      
                    </>
                  </TabPane>
                </TabContent>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
