import moment from 'moment'
import React from 'react'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  CardBody,
  Card,
  Col,
  Spinner,
  Input,
  Row,
} from "reactstrap";
import AdminLoyaltyDeatils from "./AdminLoyaltyDeatils";
import SplitPointComponentPlayer1 from "./SplitPointComponentPlayer1";


import SplitPointComponent from "./SplitPointComponent";


const BookingDetailsV2SplitShareTab = ({ transactionId ,bookedUsing, bookingDetails,promotionUsed,sharedCompleted, bookedSlots, updateAdminBookings, loyaltypoint,pointSplitable, splitError, submitSplitPoint, submitSplitPointAdmin, adminBookingUserDetails}) => {
  return (
    <>

      <div className="detail-modal-container">
        <div className="inner-modal-container">
          <div className="container-lhs">
            <div className="time-date-container">
              <div className="calender-time-date">
                <div className="calender-icon">
                  {/* <h4>{"Booking ID: #86774"}</h4> */}
                  <h4>{`Booking ID: #${transactionId}`}</h4>
                </div>
              </div>

              <div className="calender-time-date">
                <div className="calender-icon">
                  <h4>
                    {`Rewards Earned: ${(bookedUsing == "app-personal-coach" ? 0 : bookingDetails?.boooked_using == "admin" ? (!promotionUsed && bookingDetails?.packages == 0 && (bookingDetails?.coupan_applied == "" || bookingDetails?.coupan_applied == null) && !sharedCompleted?.eligibility)
                      :
                      (!promotionUsed && bookingDetails?.packages == 0 && (bookingDetails?.coupan_applied == "" || bookingDetails?.coupan_applied == null)))
                      ? bookedSlots?.length * 12
                      : 0
                      } Points`}
                  </h4>
                </div>
              </div>

              <div className="calender-time-date">
                <div className="calender-icon">
                  <h4>{`No. of Session: ${bookedSlots?.length} Sessions`}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="container-rhs">
            <div className="payment-details">
              <label className="payment-details-text">
                Invoice date:{" "}
              </label>{" "}
              {"  "}
              <label className="payment-details-result">
                {moment(
                  bookingDetails?.created_at.split("T")[0]
                ).format("DD-MM-YYYY")}{" "}
                {moment(
                  bookingDetails?.created_at
                    .split("T")[1]
                    .split(".")[0],
                  "HH:mm:ss"
                ).format("hh:mm A")}
              </label>
            </div>
            <div className="payment-details">
              <label className="payment-details-text">Due date: </label>{" "}
              {"  "}
              <label className="payment-details-result">
                {" "}
                {moment(bookingDetails?.booking_date).format(
                  "DD-MM-YYYY"
                )}{" "}
                {bookingDetails?.timeslot.split("-")[0]}
              </label>
            </div>
            <div className="payment-details">
              <label className="payment-details-text">Status: </label>{" "}
              {"  "}
              {bookingDetails?.cancelled ? (
                <label className="payment-details-result">
                  Cancelled
                </label>
              ) : (
                <label className="payment-details-result">
                  {bookingDetails?.packages == 0
                    ? localStorage.getItem("AdminPaymentType") != null
                      ? localStorage.getItem("AdminPaymentType")
                      : bookingDetails?.amount_percentage === 1 ||
                        bookingDetails?.amount_percentage === 100
                        ? "Paid 100%"
                        : bookingDetails?.amount_percentage == 2 ? "Pay Later" : "Paid 50%"
                    : "Paid by Packages"}
                </label>
              )}
            </div>
            <div className="payment-details">
              <label className="payment-details-text">
                Payment received:{" "}
              </label>{" "}
              {"  "}
              <label className="payment-details-result">
                {bookingDetails?.payment_status === "Failed"
                  ? "0.00"
                  : bookingDetails?.amount_paid.toFixed(2)}{" "}
                AED
              </label>
            </div>
            <div className="payment-details">
              <label className="payment-details-text">
                Booking made via:
              </label>{" "}
              {"  "}
              <label
                className="payment-details-result"
                style={{ textTransform: "capitalize" }}
              >
                {" "}
                {bookingDetails?.boooked_using}
              </label>
            </div>
          </div>
        </div>
        <Col md={12} lg={12}>
          <AdminLoyaltyDeatils transactionId={transactionId} updateAdminBookings={updateAdminBookings} />
        </Col>

        {
          !promotionUsed && bookingDetails?.packages == 0 && (bookingDetails?.coupan_applied == null || bookingDetails?.coupan_applied == "") && bookingDetails?.booking_category == "Bookings" &&
          (<span className="border-bottom border-2 mt-3 mr-3 mb-3"></span>)
        }
        {
          !promotionUsed && bookingDetails?.packages == 0 && (bookingDetails?.coupan_applied == null || bookingDetails?.coupan_applied == "") && pointSplitable && bookingDetails?.booking_category == "Bookings" &&
          bookedUsing !== "app-personal-coach" &&
          (
            <Col md={12} lg={12}>
              <div className="d-flex flex-row align-items-baseline">
                <p className="font-weight-bold pr-2">
                  Share points with Friends:
                </p>{" "}
                <p className="font-weight-bold Eligible pr-2">{`(Eligible to Split: ${bookedSlots?.length * 12
                  })`}</p>
                <div>
                  <p className="edit">Edit</p>
                </div>
              </div>
            </Col>
          )
        }
      </div>

      {
        !promotionUsed && bookingDetails?.packages == 0 && (bookingDetails?.coupan_applied == null || bookingDetails?.coupan_applied == '') && bookingDetails?.booking_category == "Bookings" && bookedUsing !== "app-personal-coach" &&

        (
          (bookingDetails?.boooked_using == "admin" ? (loyaltypoint?.loyaltyavailable > 0 && pointSplitable && !sharedCompleted.eligibility) : (loyaltypoint?.loyaltyavailable > 0 && pointSplitable)) ? (

            <>
              {bookingDetails?.boooked_using !== "admin" ? (
                <Col md={12} lg={12} className="mt-2">
                  {bookingDetails && (
                    <SplitPointComponentPlayer1
                      playerId={1}
                      userId={bookingDetails?.user?.id}
                      name={bookingDetails?.user?.first_name}
                      email={bookingDetails?.user?.email}
                      phone={bookingDetails?.user?.phone_no}
                      totalPoints={bookedSlots?.length * 12}
                    />
                  )}
                </Col>
              ) : (
                <Col md={12} lg={12} className="mt-2">
                  {/* <SplitPointComponent
                        playerId={1}
                        totalPoints={bookedSlots?.length * 12}
                        userData = {adminBookingUserDetails}
                      /> */}
                  <SplitPointComponentPlayer1
                    playerId={1}
                    userId={adminBookingUserDetails?.id}
                    name={adminBookingUserDetails?.first_name}
                    email={adminBookingUserDetails?.email}
                    phone={adminBookingUserDetails?.phone_no}
                    totalPoints={bookedSlots?.length * 12}
                  />
                </Col>
              )}
              <Col md={12} lg={12} className="mt-2">
                <SplitPointComponent
                  playerId={2}
                  totalPoints={bookedSlots?.length * 12}
                />
              </Col>
              <Col md={12} lg={12} className="mt-2">
                <SplitPointComponent
                  playerId={3}
                  totalPoints={bookedSlots?.length * 12}
                />
              </Col>
              <Col md={12} lg={12} className="mt-2">
                <SplitPointComponent
                  playerId={4}
                  totalPoints={bookedSlots?.length * 12}
                />
              </Col>
              <Col md={12} lg={12} className="mt-2 d-flex ">
                {/* <Row> */}
                <Col md={8} lg={8}>
                  <p className="text-danger">
                    {splitError &&
                      "You can't split point, point is exceed the earned points"}
                  </p>
                </Col>
                <Col
                  md={4}
                  lg={4}
                  className="mt-2 d-flex justify-content-end"
                >
                  {bookingDetails?.boooked_using !== "admin" ? (
                    <button
                      className="btn btn-warning "
                      onClick={() =>
                        submitSplitPoint(bookedSlots?.length * 12)
                      }
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning "
                      onClick={() =>
                        submitSplitPointAdmin(bookedSlots?.length * 12)
                      }
                    >
                      Submit
                    </button>
                  )}
                </Col>
                {/* </Row> */}
              </Col>
            </>
          ) : (
            <Col md={12} lg={12} className="mt-2">
              <h3 className="text-danger w-100">
                {/* {bookingDetails?.boooked_using == "admin" && viewSplitPoints ? (loyaltypoint?.loyaltyavailable <= 0 ? "You're current point is less, you can't share points." : "The Points are already splited/shared.") : "Point is not provided to user"} */}

                {
                  bookingDetails?.boooked_using == "admin" ?

                    (!sharedCompleted?.eligibility ? loyaltypoint?.loyaltyavailable <= 0 ? "You're current point is less, you can't share points." : "The Points are already splited/shared." : "Point is not provided to user")
                    :
                    (loyaltypoint?.loyaltyavailable <= 0 ? "You're current point is less, you can't share points." : "The Points are already splited/shared.")

                }

              </h3>
            </Col>
          ))
      }
    </>
  )
}

export default BookingDetailsV2SplitShareTab