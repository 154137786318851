/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar, Row } from "reactstrap";
import EditOutlineIcon from "mdi-react/EditOutlineIcon";
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon";
// import axios from "axios";
import axios from '../../../customAxios';
import { useHistory } from "react-router-dom";
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import ReactTableCustomizer from '../../../shared/components/table/components/ReactTableCustomizer';
import { environment } from "../../env";
import  { AiFillEye,AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import './CourtCard.css'

const ListCourtCard = () => {
  const [mainCourtList, setMainCourtList] = useState([]);
  const [roleId, setRoleId] = useState();
  const [editing, setEditing] = useState(false);
  const [deleteing, setDeleteing] = useState(false);
  const [reload, setReload] = useState(false);
  const [role, setRole] = useState();
  const [userRole, setUserRole] = useState();
  const [isSortable, setIsSortable] = useState(true);
  const [isResizable, setIsResizable] = useState(true);
  const [withPagination, setWithPaginationTable] = useState(true);
  const [withSearchEngine, setWithSearchEngine] = useState(true);

  const history = useHistory();
  // const mainCourtUrl = `${environment.baseUrl}maincourts-admin/`;
  const mainCourtUrl = `${environment.baseUrl}maincourts-admin-new/`;

  const GetMainCourt = async () => {
    const result = await axios(mainCourtUrl);
    setMainCourtList(result.data);
    console.log(result.data);
  };

  function handleEdit(courtId) {
    history.push(`/edit_main_court/${courtId}`);
  }

  const getRole = () => {
    const localrole = localStorage.getItem("Role");
    const getRoleUrl = `${environment.baseUrl}role_by_rolename/${localrole}`;
    const result = axios.get(getRoleUrl).then((response) => {
      setRole(response.data);
      setPermissions(response.data);
    });
  };

  useEffect(() => {
    getRole();
    GetMainCourt();
    setUserRole(localStorage.getItem("Role"));
    setReload(!reload);
    if (localStorage.getItem("Token")) {
      console.log("True");
    } else {
      alert("You are not logged In. Please LogIn");
      history.push("/");
    }
  }, []);

  const handleViewDetails = (id) => {
    history.push(`/main_courts_view_details/${id}`);
  };

  const setPermissions = (roledata) => {
    // const localrole = "admin";
    const localrole = localStorage.getItem("Role");
    let newRoleId;

    if (localrole === roledata.role_name) {
      let permission = JSON.parse(roledata.permissions);
      const rl = Object.values(permission).forEach((a) => {
        Object.keys(a).forEach((e) => {
          var page_name = "main_court"
          if (e === "main_court") {
            // eslint-disable-next-line no-unused-expressions
            newRoleId = a[e];
          }
        });
      });
    } else {
      console.log("false");
    }
    if (
      newRoleId === 2 ||
      newRoleId === 3 ||
      newRoleId === "2" ||
      newRoleId === "3"
    ) {
      setEditing(true);
      setReload(!reload);
    }
    if (newRoleId === 3 || newRoleId === "3") {
      setDeleteing(true);
      setReload(!reload);
    }
    console.log(editing);
  };
  const handleAddMainCourt = () => {
    history.push("/main_court");
  };

  var jsonObj = [];

  for (let i = 0; i < mainCourtList.length; i++) {
    var item = {};
    item["image"] =<div style={{paddingLeft:10}}><img src={mainCourtList[i].maincourtimage}  alt={mainCourtList[i].maincourtimage}
    style={{ width: 130, borderRadius: 5 }}/>
    </div>;
    // {courtData?.features?.split(",").map((feature, key) => (
    //   <Text key={key} style={styles.featuredTextDesign}>{feature}</Text>
    //  ))}
    item["courtname"] = <div className="right-space">{mainCourtList[i].courtName}</div>;
    item["timeslot"] = <div className="right-space">{mainCourtList[i]?.timeslot.split(",").map((timeslot, key)=>{
      return(
        <p>{timeslot}</p>
      )
    })}</div>;
    item["features"] = <div className="right-space">{mainCourtList[i].features}</div>;
    item["view"] = <div className="right-space"><div className="modal-view-btn" size="sm"  onClick={() => handleView(mainCourtList[i].id)}>
       <AiFillEye style={{fontSize:20}}/>
    </div>
    </div>;
    if(deleteing === true){
    item["link"] = <div className="right-space">
    <div className="modal-edit-btn" size="sm"
    onClick={() => handleEdits(mainCourtList[i].id)}
  >
    <AiOutlineEdit style={{fontSize:20}}/>
  </div></div> ;
    // eslint-disable-next-line no-loop-func
  //   item["delete"] =<div className="right-space">
  //   <div className="modal-delete-btn" size="sm"
  //   onClick={() => handleDeletes(mainCourtList[i].id)}>
  //   <AiOutlineDelete style={{fontSize:20}}/>
  // </div></div>;
  }
  else if(editing === true){
    // eslint-disable-next-line no-loop-func
    item["link"] = <div className="right-space">
    <div className="modal-edit-btn" size="sm"
    onClick={() => handleEdits(mainCourtList[i].id)}>
    <EditOutlineIcon style={{fontSize:20}}/>
  </div></div>;
  }
    jsonObj.push(item);
  }

  const handleDeletes = (id) => {
    if (userRole === "admin") {
      const mainCourtDeleteUrl = `${environment.baseUrl}maincourts/${id}`;
      axios.delete(mainCourtDeleteUrl).then((response) => {
        console.log(response);
      });
      setMainCourtList(() => mainCourtList.filter((court) => court.id !== id));
    } else {
      alert("You don't have the permissions to delete data");
    }
  };

  const handleEdits = (id) => {
    if (userRole === "admin") {
      const mainCourtEditUrl = `/edit_main_court/${id}`;
      history.push(mainCourtEditUrl);
    } else {
      alert("You don't have the permissions to Edit data");
    }
  };

  const handleView = (id) => {
    if (userRole === "admin") {
      const mainCourtEditUrl = `/main_courts_view_details/${id}`;
      history.push(mainCourtEditUrl);
    } else {
      alert("You don't have the permissions to View data");
    }
  };



  const data = {
    columns: [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: false,
        width: 65,
      },
      {
        Header: "Image",
        accessor: "image",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 60,
      },
      {
        Header: "court Name",
        accessor: "courtname",
        disableGlobalFilter: true,
        width: 40,
      },
      {
        Header: "Timeslot",
        accessor: "timeslot",
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: "Features",
        accessor: "features",
        disableGlobalFilter: true,
        width: 70,
      },
      {
        Header: "View",
        accessor: "view",
        disableGlobalFilter: true,
        disableSortBy: true,
        width:20,
      },
      {
        Header: "Edit",
        accessor: "link",
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 20,
      },
      // {
      //   Header: "Delete",
      //   accessor: "delete",
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      //   width: 20,
      // },
    ],
    rows: jsonObj,
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickIsResizable = () => {
    setIsResizable(!isResizable);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };

  const tableConfig = {
    isEditable: false,
    isResizable,
    isSortable,
    withPagination,
    withSearchEngine,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search by First name...',
  };

  return (
    <>
    <Col md={12} lg={12}
          style={{ 
            display:'flex',
            justifyContent:'flex-end',
            alignItems:'flex-end',
              }} >
          {deleteing ? (
            <div className="justpadel-buttons btn-position" onClick={() => handleAddMainCourt()}> Add Main Court</div>
          ) : (
            ""
          )}
        </Col>
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper hide-table-costomizer ">
          
            <div className="pagination-dropdown ">
            <ReactTableCustomizer
              handleClickIsSortable={handleClickIsSortable}
              handleClickWithPagination={handleClickWithPagination}
              handleClickWithSearchEngine={handleClickWithSearchEngine}
              handleClickIsResizable={handleClickIsResizable}
              isResizable={isResizable}
              isSortable={isSortable}
              withPagination={withPagination}
              withSearchEngine={withSearchEngine}
            />
            </div>
          </div>
          <div className="hover-effect table-id-desabled second-child-space">
          <ReactTableBase
            key={withSearchEngine ? 'searchable' : 'common'}
            columns={data.columns}
            data={data.rows}
            tableConfig={tableConfig}
          />
          </div>
        </CardBody>
      </Card>
    </Col>
</>
  );
};

export default ListCourtCard;
