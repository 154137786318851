import React from 'react';
import {
  Col, Container, Row
} from 'reactstrap';
import SlideList from './components/SlideList';

const Slides = () => {
  return (
    <Container>
      <Row>
        <Col md={10}>
          <h3 className="page-title">Slides</h3>
        </Col>
      </Row>
      <Row>
        <SlideList />
      </Row>
    </Container>
  )
};
export default Slides;
