import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import EditMainCourtCard from './components/CourtCard';

const EditMainCourtIndex = () => {
  const [courtName, setCourtName] = useState("");
  const GetMainCourtName = (courtName) => {
    setCourtName(courtName);
  };
  return(
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">{courtName}</h3>
        <h3 className="page-subhead subhead">Edit Main Court {courtName}</h3>
      </Col>
    </Row>
    <Row>
      <EditMainCourtCard GetMainCourtName={GetMainCourtName} />
    </Row>
  </Container>
)};

export default EditMainCourtIndex;
