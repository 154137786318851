import React, { useEffect, useState } from "react";
import axios from "../../../customAxios";
import { environment } from "../../env";
const Promotion = ({ bookedCourt, slots }) => {
  const [promotionUsed, setPromotionUsed] = useState(false);

  const GetPromotionBooking = async (bookingId) => {
    const GetPromotionBookingUrl = `${environment.baseUrl}promotion-v2-used/${bookingId}/`;
    await axios.get(GetPromotionBookingUrl).then((response) => {
      setPromotionUsed(response?.data);
    });
  };

  useEffect(() => {
    GetPromotionBooking(bookedCourt);
  }, []);

  return (
    <>
      {(promotionUsed) && (
        <img
          src="https://padelgram-media.s3.ap-south-1.amazonaws.com/promotion-verified.svg"
          height="20"
          width="20"
        />
      )}
    </>
  );
};

export default Promotion;
