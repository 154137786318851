import React from "react";
import { Col, Container, Row } from "reactstrap";
import Coupons from "./Coupons";
import CoponUsedList from "./CoponUsedList";

export const CouponOffers = () => {
  return (
    <Container>
      {/* <Col md={12}>
        <Row>
          <h3 className="page-title">Coupon Offers</h3>
        </Row>
      </Col> */}

      <Col>
        <Row>
            <Coupons />
        </Row>
        <Row>
          <CoponUsedList />
        </Row>
      </Col>
    </Container>
  );
};
