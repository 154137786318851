import React from 'react'
import { Col, Container, Row } from "reactstrap";
import { GetDoubleBookingsCard } from './GetDoubleBookingsCard'

export const GetDoubleBookings = () => {
  return (
    <Container>
      <Row md={12}>
        <Col>
          <h3 className="page-title">Get DoubleBookings</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <GetDoubleBookingsCard />
        </Col>
      </Row>
    </Container>
  )
}
