import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Button, ButtonToolbar } from "reactstrap";
import axios from "../../../customAxios";
import { Formik } from "formik";
import { environment } from "../../env";
import { useForm, Controller } from "react-hook-form";
import swal from "sweetalert";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const options = [{ value: 10, label: "All Courts" }];

const AddCoupon = ({ AddCoupanReload }) => {
  const { register, handleSubmit, control, reset, formState, watch } = useForm({
    mode: "onChange",
  });
  const { touchedFields, errors } = formState;
  const [errorMessage, setErrorMessage] = useState();
  const [mainCourtList, setMainCourtList] = useState([]);
  const [times, setTimes] = useState([
    "06:00 AM",
    "06:45 AM",
    "07:30 AM",
    "08:15 AM",
    "09:00 AM",
    "09:45 AM",
    "10:30 AM",
    "11:15 AM",
    "12:00 PM",
    "12:45 PM",
    "01:30 PM",
    "02:15 PM",
    "03:00 PM",
    "03:45 PM",
    "04:30 PM",
    "05:15 PM",
    "06:00 PM",
    "06:45 PM",
    "07:30 PM",
    "08:15 PM",
    "09:00 PM",
    "09:45 PM",
    "10:30 PM",
    "11:15 PM",
    "12:00 AM",
    "12:45 AM",
    "01:30 AM",
    "02:15 AM",
  ]);

  const timesAsOptions = times.map((time) => ({
    value: time,
    label: time,
  }));

  const onSubmit = (value) => {
    let selectMainCourt = value.maincourt_availability.map(
      (court) => court.value
    );
    value.maincourt_availability = selectMainCourt;
    if (value.scratch_card === 'true') {
      value.percentage_of_discount = '0'
    }
    if (value?.start_time && value?.end_time) {
      value.start_time = value?.start_time?.value
      value.end_time = value?.end_time?.value
    }

    if (value.coupons_user_limit <= value.coupons_alloted) {
      const couponUrl = `${environment.baseUrl}getcoupan`;

      axios
        .post(couponUrl, value)
        .then((response) => {
          if (response.data.msg === "Coupan added Successfully") {
            swal({
              title: "Success",
              text: "Coupan added Successfully",
              icon: "success",
              button: "Ok",
            }).then(() => {
              AddCoupanReload();
            });
          } else if (response.data.msg == "Coupon is already exists") {
            swal({
              title: "Warning",
              text: "Coupon is already exists",
              icon: "warning",
              button: "Ok",
            }).then(() => {
              AddCoupanReload();
            });
          }
        })
        .catch((err) => {
          swal({
            title: "Error",
            text: JSON.stringify(err),
            icon: "error",
            button: "Ok",
          }).then(() => {
            AddCoupanReload();
          });
        });
    } else {
      setErrorMessage("User limit exceeded the coupon Alloted limit.");
    }
  };

  const GetMainCourt = async () => {
    const mainCourtUrl = `${environment.baseUrl}maincourts-list`;
    await axios.get(mainCourtUrl).then((response) => {
      setMainCourtList(
        response?.data.map((court) => ({
          label: court.courtName,
          value: court.id,
        }))
      );
    });
  };

  useEffect(() => {
    GetMainCourt();
    reset({
      maincourt_availability: [options[0]],
      apply_for_addons: "false",
      scratch_card: "false",
    });
  }, []);

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <form className="form product-edit" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__half w-100 m-0">
              <div className="form__form-group ">
                <span className="form__form-group-label">
                  Select Main Court
                </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="maincourt_availability"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Select
                        value={value}
                        isMulti
                        options={mainCourtList}
                        className="basic-multi-select w-100"
                        components={animatedComponents}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                {errors.maincourt_availability && (
                  <span className="error-msg">Please Select Main Courts</span>
                )}
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Name</span>
                <div className="form__form-group-field">
                  <input
                    type="text"
                    placeholder="Coupon Name"
                    name="name"
                    {...register("name", { required: true })}
                  />
                </div>
                {errors.name && (
                  <span className="error-msg">Please Enter Name Of Coupon</span>
                )}
              </div>
            </div>
            <div className="form__half" style={{ marginLeft: 30 }}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Percentage of Discount
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Percentage of Discount"
                    name="percentage_of_discount"
                    {...register("percentage_of_discount", {
                      required: true,
                      min: 0,
                      max: 100,
                    })}
                  />
                </div>
                {errors.percentage_of_discount?.type === "max" && (
                  <div className="error-msg">
                    {" "}
                    <BsFillExclamationTriangleFill className="error-icon" />
                    Please give discount between 0-100
                  </div>
                )}
                {errors.percentage_of_discount?.type === "required" && (
                  <span className="error-msg">
                    Please Enter Percentage of Discount
                  </span>
                )}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">User Limit</span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Coupon User Limit"
                    name="coupons_user_limit"
                    {...register("coupons_user_limit", { required: true })}
                  />
                </div>
                {errors.coupons_user_limit && (
                  <span className="error-msg">Please Enter User Limit</span>
                )}
                {errorMessage ? (
                  <div className="error-msg">{errorMessage}</div>
                ) : (
                  " "
                )}
              </div>
            </div>
            <div className="form__half" style={{ marginLeft: 30 }}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Coupons Allocated
                </span>
                <div className="form__form-group-field">
                  <input
                    type="number"
                    placeholder="Coupon Allocated"
                    name="coupons_alloted"
                    {...register("coupons_alloted", { required: true })}
                  />
                </div>
                {errors.coupons_alloted && (
                  <span className="error-msg">
                    Please Enter Coupons Allocated
                  </span>
                )}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group">
                <span className="form__form-group-label">Apply For Addons</span>
                <div className="form__form-group-field">
                  <div
                    className="form__form-group-field"
                    style={{ justifyContent: "center" }}
                  >
                    <label htmlFor="Yes" style={{ marginBottom: "0" }}>
                      Yes
                    </label>

                    <input
                      {...register("apply_for_addons")}
                      type="radio"
                      name="apply_for_addons"
                      value="true"
                      id="apply_for_addons"
                      style={{ marginRight: "40px", height: "20px" }}
                    />
                  </div>
                  <div
                    className="form__form-group-field"
                    style={{ justifyContent: "center" }}
                  >
                    <label htmlFor="No" style={{ marginBottom: "0" }}>
                      No
                    </label>

                    <input
                      {...register("apply_for_addons")}
                      type="radio"
                      name="apply_for_addons"
                      value="false"
                      id="apply_for_addons"
                      style={{ marginRight: "40px", height: "20px" }}
                    />
                  </div>
                </div>
              </div>
              {errors.apply_for_addons && <span>Please check the box</span>}
            </div>



            <div className="form__half">
              <div className="form__form-group" style={{ marginLeft: "30px" }}>
                <span className="form__form-group-label">Buy One Get One Coupon</span>
                <div className="form__form-group-field">
                  <div
                    className="form__form-group-field"
                    style={{ justifyContent: "center" }}
                  >
                    <label htmlFor="Yes" style={{ marginBottom: "0" }}>
                      Yes
                    </label>

                    <input
                      {...register("scratch_card")}
                      type="radio"
                      name="scratch_card"
                      value="true"
                      id="scratch_card"
                      style={{ marginRight: "40px", height: "20px" }}
                    />
                  </div>
                  <div
                    className="form__form-group-field"
                    style={{ justifyContent: "center" }}
                  >
                    <label htmlFor="No" style={{ marginBottom: "0" }}>
                      No
                    </label>

                    <input
                      {...register("scratch_card")}
                      type="radio"
                      name="scratch_card"
                      value="false"
                      id="scratch_card"
                      style={{ marginRight: "40px", height: "20px" }}
                    />
                  </div>
                </div>
              </div>
              {errors.scratch_card && <span>Please check the box</span>}
            </div>






            <div className="form__half">
              <div className="form__form-group" style={{ marginLeft: "0px" }}>
                <span className="form__form-group-label">
                  Select Coupon Type
                </span>
                <div className="form__form-group-field">
                  <select
                    className="select-coupon"
                    style={{
                      fontSize: "13px",
                      fontWeight: "200",
                      width: "200px",
                      borderColor: "white",
                      outline: "none",
                    }}
                    name="coupon_type"
                    id="coupon_type"
                    {...register("coupon_type")}
                  >
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value=""
                    >
                      Select Court Type
                    </option>
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value="persession"
                    >
                      Per Session
                    </option>
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value="perbooking"
                    >
                      Per Booking
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group" style={{ marginLeft: "30px" }}>
                <span className="form__form-group-label">
                  Select Coupon Type
                </span>
                <div className="form__form-group-field">
                  <select
                    className="select-coupon"
                    style={{
                      fontSize: "13px",
                      fontWeight: "200",
                      width: "200px",
                      borderColor: "white",
                    }}
                    name="coupon_category"
                    id="coupon_category"
                    {...register("coupon_category")}
                  >
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value=""
                    >
                      Select Court Type
                    </option>
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value="public"
                    >
                      Public
                    </option>
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value="private"
                    >
                      Private
                    </option>
                  </select>
                </div>
                {/* {errors.coupons_type && <span className="error-msg">Please Select Coupon Type</span>}
                {errorMessage ? <div className="error-msg">{errorMessage}</div> : " "} */}
              </div>
            </div>
            <div className="form__half">
              <div className="form__form-group" style={{ marginLeft: "0px" }}>
                <span className="form__form-group-label">Expiry Date</span>
                <div className="form__form-group-field">
                  <input
                    type="date"
                    name="expiry_date"
                    {...register("expiry_date", { required: true })}
                  />
                </div>
                {errors.expiry_date && (
                  <span className="error-msg">
                    Please Enter Coupons Expiry Date
                  </span>
                )}
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group" style={{ marginLeft: "30px" }}>
                <span className="form__form-group-label">Applicable For</span>
                <div className="form__form-group-field">
                  <select
                    className="select-coupon"
                    style={{
                      fontSize: "13px",
                      fontWeight: "200",
                      width: "200px",
                      borderColor: "white",
                    }}
                    name="applicablefor"
                    id="applicablefor"
                    {...register("applicablefor", { required: true })}
                  >
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value=""
                    >
                      Select Coupon Applicable For
                    </option>
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value="app"
                    >
                      App
                    </option>
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value="all"
                    >
                      All
                    </option>
                  </select>
                </div>
                {errors.applicablefor && (
                  <span className="error-msg">
                    Please Select Coupon Apply For
                  </span>
                )}
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group" style={{ marginLeft: "0px" }}>
                <span className="form__form-group-label">
                  Start Time
                </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="start_time"
                    rules={{ required: false }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      const defaultValueOption = timesAsOptions.find(
                        (option) => option.value === value
                      );
                      return (
                        <Select
                          options={timesAsOptions}
                          className=" w-100"
                          placeholder="Start Time"
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      );
                    }}
                  />
                </div>
                {errors.start_time && (
                  <span className="error-msg">
                    Please Select Start Time
                  </span>
                )}
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group" style={{ marginLeft: "30px" }}>
                <span className="form__form-group-label">
                  End Time
                </span>
                <div className="form__form-group-field">
                  <Controller
                    control={control}
                    name="end_time"
                    rules={{ required: false }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      const defaultValueOption = timesAsOptions.find(
                        (option) => option.value === value
                      );
                      return (
                        <Select
                          options={timesAsOptions}
                          className=" w-100"
                          placeholder="End Time"
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      );
                    }}
                  />
                </div>
                {errors.end_time && (
                  <span className="error-msg">
                    Please Select End Time
                  </span>
                )}
              </div>
            </div>

            <div className="form__half">
              <div className="form__form-group" style={{ marginLeft: "0px" }}>
                <span className="form__form-group-label">Required Card</span>
                <div className="form__form-group-field" style={{ border: '1px solid #ccc', padding: 4, borderRadius: 4 }}>
                  <select
                    className="select-coupon"
                    style={{
                      fontSize: "13px",
                      fontWeight: "200",
                      width: "200px",
                      borderColor: "white",
                    }}
                    name="require_card"
                    id="require_card"
                    {...register("require_card", { required: true })}
                  >
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value=""
                    >
                      Require card
                    </option>
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      style={{
                        fontSize: "13px",
                        borderColor: "rgb(132, 132, 132)",
                      }}
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                {errors.require_card && (
                  <span className="error-msg">
                    Please Select required card value
                  </span>
                )}
              </div>
            </div>

            <ButtonToolbar
              className="form__button-toolbar float_right"
              style={{
                marginLeft: "30px",
                margin: "auto",
                marginBottom: "5px",
                marginTop: "25px !important",
              }}
            >
              <button
                className="justpadel-buttons-save"
                type="submit"
                style={{ border: "none", backgroundColor: "#1bb4f0" }}
              >
                Save
              </button>
              <button
                className="justpadel-buttons-cancel"
                style={{ backgroundColor: "#FDAE00", border: "none" }}
                onClick={() => {
                  reset({
                    name: "",
                    percentage_of_discount: "",
                    coupons_user_limit: "",
                    coupons_alloted: "",
                  });
                }}
              >
                Clear
              </button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};
export default AddCoupon;